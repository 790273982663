import React, {useContext} from "react";
import AppContext, {IAppContext, IAppV2Context} from "../../App/context/AppContext";
import axios from "axios";
import {Simulate} from "react-dom/test-utils";
import {devLog} from "../../../utils/devLog/devLog";

export default function(props: any) {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<section className={"cookies"}>

    Nous utilisons des cookies pour des opérations techniques de persistance de session. En poursuivant la navigation
    vous acceptez leur utilisation.

    <button className={"cookies__btn"} onClick={async () => {
      try {
        const answer = await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/session/s/close/cookies`, {}, {withCredentials: true});
      } catch (error) {
        devLog(error)
      }
      appContextV2.dispatchSession({
        type: "CLOSE_COOKIES",
        value: null
      });
    }}
    >J'accepte</button>

  </section>);

}