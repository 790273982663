import React, {useState} from "react";
import Spaces from "../../../Spaces";
import {IColumnData} from "../WindowColumnOptionsV2";
import MailComponent from "../../../MailComponent";
import {WCOIsCandidateInterestedOptions} from "./dependenciesWCO2/WCOIsCandidateInterestedOptions";

export {WCOIsCandidateInterested};

const WCOIsCandidateInterested = (props: {
    type: "kanban" | "preset",
    missionId: string,
    numberOfCandidates: number,
    columnData: IColumnData,
    updateColumnData: Function,
    saveColumnData: Function,
    switchColumnAction: Function,
    isWindowLoading: boolean,
    deleteColumn: Function
}) => {

    return (<>

        <article className={`white-container white-container--grey`}>
            <div className={"space-flex--space-between"}>
                <span className={"text--bold text--magnified11x"}>Activer l'action "Demander au candidat s'il est interessé" ?</span>
                <div>
                    <label className={"switch-V2"}>
                        <input type={"checkbox"} className={"checkbox-on-off"} checked={!!props.columnData.activateInterested}
                               onChange={() => {
                                   if(!!props.columnData.activateInterested) {
                                       props.updateColumnData({activateInterested: false});
                                       props.saveColumnData({activateInterested: false});
                                   }
                                   if(!props.columnData.activateInterested) {
                                       props.switchColumnAction("activateInterested");
                                   }
                               }}/>
                        <span className="slider"> </span>
                    </label>
                </div>
            </div>

            <Spaces n={0}/>
            <div className={"text--center text--grey"}>
                Les candidats déplacés dans cette colonne recevront un email personnalisé avec les choix "Je suis interessé/e, "Je ne suis pas interessé/e pour ce poste", "Je ne souhaite plus être contacté/e pour aucun poste".
            </div>

            {/*------------------- Content*/}
            {!!props.columnData.activateInterested && <WCOIsCandidateInterestedOptions {...props}/>}

        </article>

    </>);
}
