import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import WindowWrapper from "../../../../layouts/WindowLayout";
import Spaces from "../../Spaces";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const [teams, setTeams] = useState([]);
  const [mission, setMission] = useState({...appContextV2.appPanels.window.windowOptions.data?.item});
  
  useEffect(() => {
    (async() => {
      const teamsAnswer = await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/t/teams`, {withCredentials: true});
      setTeams(teamsAnswer.data.data.teams);
    })();
  }, []);
  
  const changeMissionOfTeam = async(toTeamId: any) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/move/team`, {
        missionId: mission?.missionId,
        toTeamId: toTeamId
      }, {
        withCredentials: true
      })
      appContextV2.appPanels.window.windowOptions.data?.deleteFront(mission?.missionId, true)
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      notification(appContextV2.dispatchPanels, "Succès du changement", "success");
    } catch(error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "warning");
      throw (error);
    }
  }
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    <>
      <article className={"editor-v2__section__title"}>Options de partage</article>
      <div className={"text--center"}>
        Changez le poste d'équipe
        <Spaces n={0}/>
        <select className={"filters__select"} disabled={appContextV2.appSession.session.userRole !== "admin"}
                value={appContextV2.appSession.sessionBusiness.teamId}
                onChange={(e) => changeMissionOfTeam(e.target.value)}>
          {teams?.map((item: any, key: number) => (<option value={item._id}>{item.name}</option>))}
        </select>
      </div>
    </>
  </WindowWrapper>)
  
}