import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import {useWizard} from "use-wizard";
import {useForm} from "use-formidable";
import {notification} from "../../../../utils/notification";
import axios from "axios";
import {devLog} from "../../../../utils/devLog/devLog";
import WindowWrapper from "../../../../layouts/WindowLayout";
import Spaces from "../../Spaces";

export {WindowAddMember};

const WindowAddMember = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const teamId: string = appContextV2.appPanels.window.windowOptions.data?.teamId;
  const teamName: string = appContextV2.appPanels.window.windowOptions.data?.teamName;
  
  const [step, wizard] = useWizard(["email", "role", "validation", "pending"]);
  
  const [form, updateForm, formidable] = useForm({
    email: "",
    role: "",
  });
  
  const inviteMember = () => {
    (async() => {
      try {

        notification(appContextV2.dispatchPanels, "Invitation en cours", "information");
        wizard.goToStep('pending');

        const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/t/invite`, {
          teamId: teamId,
          email: form.email,
          role: form.role,
        }, {withCredentials: true});
        
        if(fetched.data.status === "alreadyInvited" || fetched.data.status === "alreadyMember") {
          appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
          return notification(appContextV2.dispatchPanels, "Cet utilisateur est déjà dans l'équipe", "error");
        }

        if(fetched.data.status !== "ok") {
          appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }

        appContextV2.appPanels.window?.windowOptions?.data?.inviteTeamMemberFront({
          inviteId: fetched.data?.data?.inviteId,
          teamId: teamId,
          email: form.email,
          role: form.role,
          firstName: "",
          lastName: "",
        });
        notification(appContextV2.dispatchPanels, "Invitation lancée", "success");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});

      } catch(error) {
        devLog(error);
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      }
    })();
  }
  
  if(step === "pending") {
    return (<WindowWrapper windowTitle={""}>
    </WindowWrapper>);
  }
  
  if(step === "validation") {
    return (<WindowWrapper windowTitle={""} status={"ok"}>
      <div className={"text--center text--magnified2x"}>
        Lancer l'invitation ?
        <br/>
        <br/>
      </div>
      <div className={"text--center"}>
        Email: {form.email}
        <br/>
        <br/>
        Rôle: {form.role === "admin" && "Admin"}{form.role === "manager" && "Manager"}{form.role === "user" && "Utilisateur"}
        <br/>
        <br/>
        <br/>
        <button className={"btn-main"} onClick={() => {
          wizard.goToStep("email");
        }}>
          Recommencer
        </button>
        <button className={"btn-main"} onClick={() => inviteMember()}>
          Inviter
        </button>
      </div>
    </WindowWrapper>);
  }
  
  if(step === "role") {
    return (<WindowWrapper windowTitle={""} status={"ok"}>
      <div className={"text--center"}>
        Quel est le rôle de cette personne ?
        <br/>
        <br/>
        <br/>
        <button className={"btn-main"} onClick={() => {
          updateForm()({target: {type: "custom", name: "role", value: "user"}})
          wizard.goToStep("validation");
        }}>
          Utilisateur
        </button>
        <button className={"btn-main"} onClick={() => {
          updateForm()({target: {type: "custom", name: "role", value: "manager"}})
          wizard.goToStep("validation");
        }}>
          Manager
        </button>
        <button className={"btn-main"} onClick={() => {
          updateForm()({target: {type: "custom", name: "role", value: "admin"}})
          wizard.goToStep("validation");
        }}>
          Admin
        </button>
      </div>
    </WindowWrapper>);
  }
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    <div className={"text--center"}>
      Email du membre à ajouter à l'équipe {teamName}
      <br/>
      <br/>
      <input type={"text"} name={"email"} value={form.email} onChange={updateForm({maximumLength: 80})}/>
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button disabled={!formidable.isEmail(form.email)} className={"btn-main"} onClick={() => {
        wizard.goToStep("role");
      }} title={`Lance une invitation à ${form.email}`}>
        Définir son rôle
      </button>
    </div>
  </WindowWrapper>);
}
