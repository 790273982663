import React from 'react'
import {IAppState} from "../state/IAppState";
import {IAppV2Navigation, IAppV2Notifications, IAppV2Panels, IAppV2Session} from "../state/IAppV2";
import {TNaviationReducers} from "../reducers/navigationReducer";

export interface IAppContext {
  getSession: Function,
  appState: IAppState,
  setPage: Function,
  setSessionParameter: Function,
  toggleWindowVisibility: Function,
  initializeState: Function,
  switchWindow: Function,
  toggleNewItemVisibility: Function,
  increaseMissionsNumber: Function,
  toggleMenuFromBottomVisibility: Function,
  toggleCookies: Function,
  setMissionId: Function,
  logout: Function,
  showUpdate: Function,
  saveEditor: Function,
  setPoolId: Function,
}

export interface IAppV2Context {

  appSession: IAppV2Session;
  appNavigation: IAppV2Navigation;
  appPanels: IAppV2Panels;
  appNotifications: IAppV2Notifications;
  dispatchNotifications: Function;
  globalResearch: string,
  setGlobalResearch: Function,
  dispatchSession: (action: { type: string, value: any }) => void;
  dispatchNavigation: (action: { type: TNaviationReducers, value: any }) => void;
  dispatchPanels: (action: { type: string, value: any }) => void;
  fetchSession: Function,
  displayWelcome: boolean,
  setDisplayWelcome: Function
}

const AppContext = React.createContext({});
export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;
export default AppContext
