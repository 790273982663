import React from "react";

export default (props: {
  globalMark: number,
}) => {
  
  const mark: number = props.globalMark < 50 ? 50 : props.globalMark;
  
  const percentageStyle = ((mark: number) => {
    if(mark <= 50) {
      return "apply-V2__result__bar__content--50";
    }
    if(mark <= 55) {
      return "apply-V2__result__bar__content--55";
    }
    if(mark <= 60) {
      return "apply-V2__result__bar__content--60";
    }
    if(mark <= 65) {
      return "apply-V2__result__bar__content--65";
    }
    if(mark <= 70) {
      return "apply-V2__result__bar__content--70";
    }
    if(mark <= 75) {
      return "apply-V2__result__bar__content--75";
    }
    if(mark <= 80) {
      return "apply-V2__result__bar__content--80";
    }
    if(mark <= 85) {
      return "apply-V2__result__bar__content--85";
    }
    if(mark <= 90) {
      return "apply-V2__result__bar__content--90";
    }
    if(mark <= 95) {
      return "apply-V2__result__bar__content--95";
    }
    return "apply-V2__result__bar__content--100";
  })(mark)
  
  return(<>
    <div className={"text--grey text--center text--magnified2x"}>Résultat en direct pour ce poste:</div>
    <div className={"apply-V2__result"}>{mark}%</div>
    <div className={"apply-V2__result__bar"}>
      <div className={`apply-V2__result__bar__content ${percentageStyle}`}>
      </div>
    </div>
  </>)
  
}