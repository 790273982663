import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../components/sharedComponents/Buttons/ButtonBack";
import axios from "axios";
import {devLog} from "../../../../utils/devLog/devLog";
import LoadingStyle2 from "../../../../components/sharedComponents/Loading/LoadingStyle2";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [loading, setLoading] = useState(true);
  
  /** fetch skills for later use */
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3500);
    (async() => {
      try {
        if(!props.skills || props.skills.length === 0) {
          const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/data/dictionary/skills`, {
            timeout: 3500,
            withCredentials: true,
            headers: {
              language: appContextV2.appSession.app.language,
            }
          });
          setLoading(false);
          if(fetched.data?.status === "ok") {
            props.setSkills([...(fetched?.data?.data?.private || []), ...(fetched.data?.data?.public || [])]);
          }
          devLog(fetched);
        }
      } catch(error){
        setLoading(false);
        devLog(error);
      }
    })();
    return () => {
      clearTimeout(timer);
    }
  }, []);
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Quel type de contrat ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container"}>
  
      {
        appContextV2.appSession.sessionBusiness.isRecruitingAgency && <button disabled={loading} className={"btn-assistant-main"}
                onClick={() => {
                  props.updateForm()({target: {type: "custom", name: "contract", value: "INTERIM"}});
                  props.wizard.goToStep("jobDesc");
                }}>
          Intérim
        </button>
      }
      
      <button disabled={loading} className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "contract", value: "CDD"}});
                props.wizard.goToStep("jobDesc");
              }}>
        CDD
      </button>
  
      <button disabled={loading} className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "contract", value: "CDI"}});
                props.wizard.goToStep("jobDesc");
              }}>
        CDI
      </button>
  
      <button disabled={loading} className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "contract", value: "STAGE"}});
                props.wizard.goToStep("jobDesc");
              }}>
        Stage
      </button>
  
      <button disabled={loading} className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "contract", value: "PRO"}});
                props.wizard.goToStep("jobDesc");
              }}>
        Contrat pro
      </button>
  
      <button disabled={loading} className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "contract", value: "ALTERNANCE"}});
                props.wizard.goToStep("jobDesc");
              }}>
        Alternance
      </button>
  
      <button disabled={loading} className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "contract", value: "APPRENTISSAGE"}});
                props.wizard.goToStep("jobDesc");
              }}>
        Apprentissage
      </button>
  
      <button disabled={loading} className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "contract", value: "FREELANCE"}});
                props.wizard.goToStep("jobDesc");
              }}>
        Freelance
      </button>
      
      <button disabled={loading} className={"btn-assistant-main"}
              onClick={() => props.wizard.nextStep()}>
        Autre
      </button>
    
    </div>
  
    <Spaces n={1}/>
  
    <div className={"text--center text--grey"}>
      Pour sélectionner plusieurs types de contrats, choisis "Autre".
      {
        loading && <>
          <br/>
          <LoadingStyle2/>
        </>
      }
    </div>
  
  </section>);
}