import React, {useContext} from "react";
import CW2Cv from "./subWindows/CW2Cv";
import CW2Results from "./subWindows/CW2Results";
import CW2Trigram from "./subWindows/CW2Trigram";
import CW2Mail from "./subWindows/CW2Mail";
import CW2Finder from "./subWindows/CW2Finder";
import CW2Share from "./subWindows/CW2Share";
import CW2BlackBelt from "./subWindows/CW2BlackBelt";
import CW2Railenium from "./subWindows/CW2Railenium";
import CW2ParsingCv from "./subWindows/CW2ParsingCv";
import CW2Profile2 from "./subWindows/CW2Profile2";
import CW2Whatsapp from "./subWindows/CW2Whatsapp";
import CW2AI from "./subWindows/CW2AI";

export default (props: {
  page: any,
  setPage:any,
  dictionaries: any,
  inherited: any,
  updateCandidateFrontInWindow: any,
  updateCandidacyFrontInWindow: any,
  candidate: any,
  setCandidate: any,
  addShareFrontInWindow:any,
  deleteShareFrontInWindow:any,
  updateCandidateExtendedProfileFrontInWindow:any,
  updateCandidateFrontTokenName:any,
  setReload: any


}) => {
  
  switch(props.page) {
  
    case "cv":
      return (<CW2Cv {...props}/>);

    //  Flashcard
    case "profile":
      return (<CW2Profile2 {...props}/>);
      // return (<CW2Profile {...props}/>);

    case "results":
      return (<CW2Results {...props}/>);
      
    case "trigram":
      return (<CW2Trigram {...props}/>);
      
    case "mail" :
      return (<CW2Mail {...props}/>);

    case "whatsapp" :
      return (<CW2Whatsapp {...props}/>);

    case "ai" :
      return <CW2AI {...props}/>
  
    case "finder":
      return (<CW2Finder {...props}/>);

    case "blackBelt":
      return(<CW2BlackBelt {...props}/>);

    case "Railenium":
      return(<CW2Railenium {...props}/>);

    case "parsingCv" :
      return(<CW2ParsingCv {...props}/>)

    case "share":
      return(<CW2Share {...props}/>);
      
    // case "history":
    //   return (<CW2History {...props}/>);
    
    default:
      return (<div>
        La page demandée est vide
      </div>);
  }
  
}
