export default (props: any) => (formId: any, index: number, keyValues: any) => {
  if(formId === "form") {
    let newForm1 = [...props.form.form];
    newForm1[index] = {...newForm1[index], ...keyValues};
    props.setForm()({target: {type: "custom", name: "form", value: newForm1}});
  }
  if(formId === "form2") {
    let newForm2 = [...props.form.form2];
    newForm2[index] = {...newForm2[index], ...keyValues};
    props.setForm()({target: {type: "custom", name: "form2", value: newForm2}});
  }
}