import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import {isPhoneNumberValid} from "../../../../../utils/typeGuards";
import {ButtonBack} from "../../../Buttons/ButtonBack";
import Spaces from "../../../Spaces";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  const language = appContextV2.appSession.app.language;
  
  const next = props.assistantContext.wizard.nextStep;
  const updateForm = props.assistantContext.updateForm;
  const assistantState = props.assistantContext.form;
  
  const isBusinessNameEmpty = !assistantState.business.businessName || assistantState.business.businessName === "Koala Cola";
  const isSectorEmpty = !assistantState.business.sector;
  
  const isButtonOk = !isBusinessNameEmpty;
  
  return (<>
    <ButtonBack back={props.assistantContext.wizard.previousStep}/>
  
    <Spaces n={0}/>
    
    <div className={"text--center text--magnified1x title--bold"}>
      {language === 'FR' && <>À propos de votre entreprise</>}
      {language !== 'FR' && <>About your business</>}
    </div>
    
    <p><br/><br/></p>
    
    <form onSubmit={(e) => {
      e.preventDefault();
      if(isButtonOk) {
        next();
      }
    }}>
      
      <p className={"text--center"}>
        <input placeholder={"Nom de l'entreprise *"} value={assistantState.business.businessName !== "Koala Cola" ? assistantState.business.businessName : ""} type={"text"} name={"business.businessName"}
               className={`animation-assistant-appear ${(!isBusinessNameEmpty) ? "input--ok" : ""}`} onChange={updateForm({maximumLength: 60})}/>
      </p>
      
      <p className={"text--center"}>
        <input placeholder={"Secteur d'activité"} value={assistantState.business.sector} type={"text"} name={"business.sector"}
               className={`animation-assistant-appear ${(!isSectorEmpty) ? "input--ok" : ""}`} onChange={updateForm({maximumLength: 60})}/>
      </p>
  
  
      <p><br/><br/></p>
  
      <p className={"text--center"}>
        <button disabled={!isButtonOk} className={"btn-main"} onClick={() => {
          next();
        }}>
          {language === 'FR' && <>Étape suivante</>}
          {language !== 'FR' && <>Next step</>}
        </button>
      </p>
    
    </form>
    
  </>);
  
}
