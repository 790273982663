import React, {useContext} from "react";

export default function DisplayTutorialSteps(props: any) {

  return (
    <div>
      <button className={`tutorial-step-button ${props.step === 1 ? "tutorial-step-button--selected" : ""}`}
              onClick={() => props.setStep(1)}>
      </button>
      <button className={`tutorial-step-button ${props.step === 2 ? "tutorial-step-button--selected" : ""}`}
              onClick={() => props.setStep(2)}>
      </button>
      <button className={`tutorial-step-button ${props.step === 3 ? "tutorial-step-button--selected" : ""}`}
              onClick={() => props.setStep(3)}>
      </button>
      <button className={`tutorial-step-button ${props.step === 4 ? "tutorial-step-button--selected" : ""}`}
              onClick={() => props.setStep(4)}>
      </button>
      <button className={`tutorial-step-button ${props.step === 5 ? "tutorial-step-button--selected" : ""}`}
              onClick={() => props.setStep(5)}>
      </button>
        <button className={`tutorial-step-button ${props.step === 6? "tutorial-step-button--selected" : ""}`}
                onClick={() => props.setStep(6)}>
        </button>
    </div>)

}
