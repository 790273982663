import React from "react";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {format} from "date-fns";

export default (props:any) => {

  return (<>
    <section className={"editor-v2__section"}>

      <article className={"editor-v2__section__title"}>Questions préliminaires</article>
      <div className={"text--center text--grey "}>Choisissez des questions les questions types que vous
        souhaitez
        poser au candidat.
        <br/><br/>
        Elles vous permettrons d'effectuer plus facilement des recherches.
      </div>

      <Spaces n={1}/>

      <div>
        <input type={"checkbox"} className={"editor-v2__default-questions__checkbox"}
               name={"defaultQuestions.isAvailable"}
               onChange={props.setForm()}
               checked={props.form.defaultQuestions?.isAvailable}/>
        Demander au candidat s'il est disponible actuellement. Si non, lui demander à quelle date.
      </div>
      <div>
        {!props.form.defaultQuestions?.isAvailabilityBlocking &&
        <button className={"editor-v2__default-questions__btn"}
                onClick={() => {
                  props.setForm()({
                    target: {
                      type: "custom",
                      name: "defaultQuestions.isAvailabilityBlocking",
                      value: true
                    }
                  });
                }}>
            Critère bloquant
        </button>}
        {props.form.defaultQuestions?.isAvailabilityBlocking && <div className={"editor-v2__default-questions__div"}>
            <button className={"editor-v2__default-questions__btn"}
                    onClick={() => {
                      props.setForm()({
                        target: {
                          type: "custom",
                          name: "defaultQuestions.isAvailabilityBlocking",
                          value: false
                        }
                      });
                    }}>
                Annuler critère bloquant
            </button>
            <div className={"text--grey"}> Bloquer le candidat s'il n'est pas disponible avant le :</div>
            <input type={"Date"} className={"editor-v2__default-questions__input"}
                   name={"defaultQuestions.blockingAvailabilityData"}
                   onChange={props.setForm()}
                   value={props.form.defaultQuestions?.blockingAvailabilityData ? format(new Date(props.form.defaultQuestions?.blockingAvailabilityData), "yyyy-MM-dd") : ''}
            />
        </div>}
      </div>

      <Spaces n={0}/>

      <div>
        <input type={"checkbox"} className={"editor-v2__default-questions__checkbox"}
               name={"defaultQuestions.currentSalary"}
               onChange={props.setForm()}
               checked={props.form.defaultQuestions?.currentSalary}/>
        Demander au candidat quel est son niveau de rémunération brut annuelle actuelle.
      </div>

      <Spaces n={0}/>

      <div>
        <input type={"checkbox"} className={"editor-v2__default-questions__checkbox"}
               name={"defaultQuestions.salaryMin"}
               onChange={props.setForm()}
               checked={props.form.defaultQuestions?.salaryMin}/>
        Demander au candidat quelles sont ses prétentions salariales brutes annuelles.
      </div>
      <div>
        {!props.form.defaultQuestions?.isSalaryBlocking &&
        <button className={"editor-v2__default-questions__btn"}
                onClick={() => {
                  props.setForm()({
                    target: {
                      type: "custom",
                      name: "defaultQuestions.isSalaryBlocking",
                      value: true
                    }
                  });
                }}>
            Critère bloquant
        </button>}
        {props.form.defaultQuestions?.isSalaryBlocking && <div className={"editor-v2__default-questions__div"}>
            <button className={"editor-v2__default-questions__btn"}
                    name={"defaultQuestions.isSalaryBlocking"}
                    onClick={() => {
                      props.setForm()({
                        target: {
                          type: "custom",
                          name: "defaultQuestions.isSalaryBlocking",
                          value: false
                        }
                      });
                    }}>
                Annuler critère bloquant
            </button>
            <div className={"text--grey"}>Bloquer le candidat si le salaire demandé est supérieur à :</div>
            <input type={"number"} className={"editor-v2__default-questions__input"}
                   name={"defaultQuestions.blockingSalaryData"}
                   onChange={props.setForm()}
                   value={props.form.defaultQuestions?.blockingSalaryData}
            /></div>}
      </div>


      <Spaces n={0}/>

      <div><input type={"checkbox"} className={"editor-v2__default-questions__checkbox"}
                  name={"defaultQuestions.yearsOfExperience"}
                  onChange={props.setForm()}
                  checked={props.form.defaultQuestions?.yearsOfExperience}/>
        Demander au candidat quelle est son expérience à ce poste.
      </div>
      <div>
        {!props.form.defaultQuestions?.isExperienceBlocking &&
        <button className={"editor-v2__default-questions__btn"}
                onClick={() => {
                  props.setForm()({
                    target: {
                      type: "custom",
                      name: "defaultQuestions.isExperienceBlocking",
                      value: true
                    }
                  });
                }}>
            Critère bloquant
        </button>}
        {props.form.defaultQuestions?.isExperienceBlocking && <div className={"editor-v2__default-questions__div"}>
            <button className={"editor-v2__default-questions__btn"}
                    onClick={() => {
                      props.setForm()({
                        target: {
                          type: "custom",
                          name: "defaultQuestions.isExperienceBlocking",
                          value: false
                        }
                      });
                    }}>
                Annuler critère bloquant
            </button>
            <div className={"text--grey"}> Bloquer le candidat si le nombre d'années expérience est inférieure à :</div>
            <input type={"number"} className={"editor-v2__default-questions__input"}
                   name={"defaultQuestions.blockingExperienceData"}
                   onChange={props.setForm()}
                   value={props.form.defaultQuestions?.blockingExperienceData}
            /></div>}
      </div>


      <Spaces n={0}/>

      <div>
        <input type={"checkbox"} className={"editor-v2__default-questions__checkbox"}
               name={"defaultQuestions.training"}
               onChange={props.setForm()}
               checked={props.form.defaultQuestions?.training}/>
        Demander au candidat quel est son niveau de formation.
      </div>

      <Spaces n={0}/>

    </section>

  <Spaces n={0}/>

  </>

  )
}