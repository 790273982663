import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../../components/App/context/AppContext";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import Spaces from "../../../../../components/sharedComponents/Spaces";
import {shortenText} from "../../../../../utils/shortenText";
import {capitalizeMulti} from "../../../../../utils/converters";

export default (props: {
  
  columnId: string,
  columnData: any,
  rank: number,
  title: string,
  draggedFromColumnId: any,
  setIsLoading: Function,
  keywords: string,
  
  shortlist: any,
  shortlistUnsubscribed: any,
  
  drag: Function,
  drop: Function,
  
  missionId: string,
  customKanbanView: any,
  
  areFiltersActive: boolean,
  
  frontUpdates: any,

  selectionMode:boolean,
  setSelectionMode:Function,
  selected:any,
  dispatchSelected:any,
  
}) => {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const displayMoveButtons: boolean = !["1", "10"].includes(props.columnId);
  
  const isThereMailAction: boolean = props.columnData?.activateMail && props.columnData?.mailSubject?.length !== 0 && props.columnData?.mailContent?.length !== 0;
  const isThereInterestedAction: boolean = props.columnData?.activateInterested && props.columnData?.mailInterestedSubject?.length !== 0 && props.columnData?.mailInterestedContent?.length !== 0;
  const isTherePoolAction: boolean = props.columnData?.activatePool && props.columnData?.poolId?.length !== 0;
  const isThereSMSAction: boolean = props.columnData?.activateSms && props.columnData?.smsText?.length !== 0;
  const isThereAskCvAction: boolean = props.columnData?.activateAskCv;
  const isThereNotifyAction: boolean = props.columnData?.activateNotify && props.columnData?.notifyEmails?.length !== 0;
  const doesColumnHaveAnAction: boolean = isThereMailAction || isThereSMSAction || isTherePoolAction || isThereAskCvAction || isThereNotifyAction || isThereInterestedAction;


  const moveColumnHandler = async(columnId: string, direction: number) => {
    try {
      // update front
      props.frontUpdates.moveColumnFront(columnId, direction);
      notification(appContextV2.dispatchPanels, "Réindexation de la colonne", "information");
      props.setIsLoading(true);
      // Update back
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/mat/com/move/column`, {
        missionId: appContextV2.appNavigation.missionId,
        columnId: columnId,
        direction: direction,
      }, {withCredentials: true});
      if(fetched.data.status !== "ok") {
        props.setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      props.setIsLoading(false);
      notification(appContextV2.dispatchPanels, "Colonne déplacée avec succès", "success");
    } catch(error) {
      props.setIsLoading(false);
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }

  return (<section className={"kanban__column animation-fade-in"}
                   onDragOver={(e) => {
                     e.preventDefault();
                   }}
                   onDrop={() => {
                     props.drop(props.columnId)
                   }}>
    
    {doesColumnHaveAnAction &&
    <div title={"Une action est définie sur cette colonne"} className={"kanban__column__action"}><img
      src={"https://static.myshortlist.co/icon-lightning.svg"}/></div>}
    
    {/*--------------------------------------------------------- Header*/}
    <article className={"kanban__column__header"}>
      <article className={"kanban__column__header__title"}>
        {props.title}
      </article>
      <article className={"kanban__column__header__number"}>
        {props.shortlist?.length}
      </article>
    </article>
    
    <article className={"kanban__column__header__buttons"}>
      {displayMoveButtons && <>
        {/*<button disabled={props.rank === 2} title={"Déplace cette colonne à gauche"}*/}
        {/*        onClick={() => moveColumnHandler(props.columnId, -1)}*/}
        {/*        className={"kanban__column__header__buttons__btn"}>*/}
        {/*  <img src={"https://static.myshortlist.co/btn-arrow--left.svg"}/>*/}
        {/*</button>*/}
        <button title={"Ouvre les paramètres de cette colonne"}
                onClick={() => {
                  appContextV2.dispatchPanels({
                    type: "OPEN_WINDOW", value: {
                      type: "columnOptionsV2",
                      data: {
                        columnData: props.columnData,
                        updateColumnFront: props.frontUpdates.updateColumnFront,
                        deleteColumnFront: props.frontUpdates.deleteColumnFront,
                        numberOfCandidates: props.shortlist.length,
                        setIsLoading: props.setIsLoading,
                        type: "kanban",
                
                      }
                    }
                  })
                }}
                className={"kanban__column__header__buttons__btn"}>
          <img src={"https://static.myshortlist.co/btn-parameters.svg"}/>
        </button>
        {/*<button disabled={props.rank === props.customKanbanView.length + 1} title={"Déplace cette colonne à droite"}*/}
        {/*        onClick={() => moveColumnHandler(props.columnId, 1)}*/}
        {/*        className={"kanban__column__header__buttons__btn"}>*/}
        {/*  <img src={"https://static.myshortlist.co/btn-arrow--right.svg"}/>*/}
        {/*</button>*/}
      </>}
    </article>

    {/*------------------------------------------------------------ Candidates to display */}



    {props.shortlist.map((item: any, key: number) => {
      return(
          <>
      <article
        className={`kanban__candidacy--v2 ${["withdrawed", "rejected"].includes(item.status) ? "kanban__candidacy--v2__hollow" : ""} ${getCandidacyColor(item)} ${props.areFiltersActive ? "kanban__candidacy--v2__filters-active" : ""}`}
        key={key}
        onClick={() => {
          appContextV2.dispatchPanels({
            type: "OPEN_WINDOW", value: {
              type: "candidateWindowV2",
              data: {
                updateCandidacyFront: props.frontUpdates.updateCandidacyFront,
                moveCandidacyFront:props.frontUpdates.moveCandidacyFront,
                keywords: props.keywords,
                category: "mission",
                candidateId: item.candidateId,
                candidateShortlistId: item._id,
                missionId: appContextV2.appNavigation.missionId,
                columnId:props.columnId,
                basicData: {
                  firstName: item.firstName,
                  lastName: item.lastName,
                  avatarUrl: "https://s3.eu-west-3.amazonaws.com/myshortlist.co-public/koala.png",
                  shortlist: props.shortlist, // todo: déplacer de basicData
                  customKanbanView:props.customKanbanView,
                },
                frontUpdatePool: {
                  updateCandidatesBulk: () => {
                  },
                  reloadCandidates: () => {
                  },
                  addCandidateFront: () => {
                  }
                },
                frontUpdateMission: {
                  updateCandidaciesBulk: props.frontUpdates.updateCandidaciesBulk,
                },
              }
            }
          })
        }}
        draggable={true}
        onDragStart={(e) => {
          e.dataTransfer.setData('text/plain', '');
          props.drag(item._id, 'candidacy');
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        <div style={{
          position: "relative",
        }} className={"kanban__candidacy--v2__avatar"}>
          {!!item.avatarUrl && <img src={item.avatarUrl}/>}
          {!item.avatarUrl && <>{(item.firstName || "N")[0] ? (item.firstName || "N")[0].toUpperCase() : "N"}{(item.lastName || "N")[0] ? (item.lastName || "N")[0].toUpperCase() : "N"}</>}
        </div>
        {/*PQD*/}
        <div title={"Has a PQD for resume"} style={{
          position: "absolute",
          width: "10px",
          height: "10px",
          bottom: "0",
          left: "0px",
          borderRadius: "50%",
          backgroundColor: item.hasResumePQD ? "#00cc22" : (item.hasResume ? "#f57048" : "#888"),
          boxShadow: "0 0 0 1.5px #444",
        }}></div>
        <div title={"Has a PQD for linkedin"} style={{
          position: "absolute",
          width: "10px",
          height: "10px",
          bottom: "0",
          left: "13px",
          borderRadius: "50%",
          backgroundColor: item.hasLinkedinPQD ? "#00cc22" : (item.hasLinkedin ? "#f57048" : "#888"),
          boxShadow: "0 0 0 1.5px #444",
        }}></div>
        <div title={"Is parsed AI"} style={{
          position: "absolute",
          width: "10px",
          height: "10px",
          bottom: "0",
          left: "26px",
          borderRadius: "50%",
          backgroundColor: (typeof item.aiNote !== "undefined") ? "#00cc22" : "#888",
          boxShadow: "0 0 0 1.5px #444",
        }}></div>
        <div title={"Has a phone"} style={{
          position: "absolute",
          width: "10px",
          height: "10px",
          bottom: "0",
          left: "39px",
          borderRadius: "50%",
          backgroundColor: (item.hasPhone) ? "#00cc22" : "#888",
          boxShadow: "0 0 0 1.5px #444",
        }}></div>
        <div title={"Has an email"} style={{
          position: "absolute",
          width: "10px",
          height: "10px",
          bottom: "0",
          left: "52px",
          borderRadius: "50%",
          backgroundColor: (item.hasEmail) ? "#00cc22" : "#888",
          boxShadow: "0 0 0 1.5px #444",
        }}></div>
        <div className={"kanban__candidacy--v2__name"}>

          {shortenText(capitalizeMulti(`${item.firstName} ${item.lastName}`), 13)}
          {typeof item.aiNote !== "undefined" && <>({item.aiNote})</>}

        </div>

        {item.actionInterested && <div className={"candidacy-interested-status"}>
          {/*{item.actionInterested === "pending" && <>❓</>}*/}
          {/*{item.actionInterested === "interested" && <>✅</>}*/}
          {/*{item.actionInterested === "notInterested" && <>❌</>}*/}
          {item.actionInterested === "pending" &&  <img className={"kanban__phone-label"} src={"https://static.myshortlist.co/question-mark-white.svg"}/>}
          {item.actionInterested === "interested" &&   <img className={"kanban__phone-label"} src={"https://static.myshortlist.co/check-white.svg"}/>}
          {item.actionInterested === "notInterested" &&   <img className={"kanban__phone-label"} src={"https://static.myshortlist.co/cross-white.svg"}/>}
        </div>}

        <div className={"kanban__candidacy--v2__checkbox"}>
          {props.selectionMode && <>
            <input className={"animation-fade-in"} type={"checkbox"} checked={props.selected?.includes(item.candidateId)} onClick={(e) => {
              e.stopPropagation();
              if(!props.selected?.includes(item.candidateId)){
                props.dispatchSelected({
                  type: "SELECT_DATA",
                  value: item.candidateId,
                });
              }
              if(props.selected?.includes(item.candidateId)){
                props.dispatchSelected({
                  type: "DESELECT_DATA",
                  value: item.candidateId,
                });

            }
            }}/>
          </>
          }
        </div>

        <div className={"kanban__new-label"}>
          {appContextV2.appNotifications.recruiter?.notifications?.filter((notification: any) =>
              (notification.actionType === "newCandidacy" && notification.seenByMe=== false && notification.onCandidateId===item.candidateId)).length!==0
          && <div className={"kanban__new-candidate"}> New </div>}
          {appContextV2.appNotifications.recruiter?.notifications?.filter((notification: any) =>
          (notification.actionType === "newMessage" && notification.seenByMe=== false && notification.onCandidateId===item.candidateId)).length!==0
          && <div className={"kanban__new-candidate"}> Message </div>}
          {appContextV2.appNotifications.recruiter?.notifications?.filter((notification: any) =>
              (notification.actionType === "newTrigram" && notification.seenByMe=== false && notification.onCandidateId===item.candidateId)).length!==0
          && <div className={"kanban__new-candidate"}> Note </div>}
          {appContextV2.appNotifications.recruiter?.notifications?.filter((notification: any) =>
              (notification.actionType === "newEvaluation" && notification.seenByMe=== false && notification.onCandidateId===item.candidateId)).length!==0
          && <div className={"kanban__new-candidate"}> Evaluation </div>}
        </div >

        {item.phoneInMessage &&
        <img className={"kanban__phone-label"} src={"https://static.myshortlist.co/phone.svg"} alt={"phoneInMessage"} />
        }

        {item.cooptation && !item.cooptationAndApply &&
        <img className={"kanban__phone-label"} src={"https://static.myshortlist.co/smiley-white-not-smiling.svg"} alt={"cooptation"} />}

        {item.cooptation && item.cooptationAndApply &&
            <img className={"kanban__phone-label"} src={"https://static.myshortlist.co/smiley-white-smiling.svg"} alt={"cooptation"} />}


      </article>
          </>);})}
    
    {/* --------------------------- Candidates that didn't come back on myshortlist */}
    
    <div className={"kanban__unsorted__separator"}>
    </div>
    
    {
      props.shortlistUnsubscribed.length !== 0 && !props.areFiltersActive && <>

        <div className={"text--center text--minified2x text--grey"}>
          Les candidats ci-dessous n'ont pas postulé via Myshortlist.
        </div>

        <Spaces n={0}/>
        
        {props.shortlistUnsubscribed?.map((item: any, key: any) => (
          <article className={`kanban__candidacy--v2 kanban__candidacy--v2--white`}
                   key={key}
                   draggable={true}
                   onDragStart={(e) => {
                     e.dataTransfer.setData('text/plain', '');
                     props.drag(item._id, 'potential');
                   }}
                   onDragOver={(e) => {
                     e.preventDefault();
                   }}
                   onClick={() => {
                     appContextV2.dispatchPanels({
                       type: "OPEN_WINDOW", value: {
                         type: "candidateNotRegisteredWindow",
                         data: {
                           column: props.columnData,
                           candidacy: item,
                           removeUnregisteredCandidacyFront: props.frontUpdates.removeUnregisteredCandidacyFront,
                           updateUnregisteredCandidacyFront: props.frontUpdates.updateUnregisteredCandidacyFront
                         }
                       }
                     })
                   }}>
            {
              getWebOrigin(item.mailUsedAsId) && <>
                <div className={"kanban__candidacy--v2--white__web-origin"}>
                  {getWebOrigin(item.mailUsedAsId)}
                </div>
              </>
            }
            {shortenText(item.mailUsedAsId?.replace('%40', '@'), 20)}
          </article>))}

      </>
    }

  </section>);
}

function getCandidacyColor(item: any) {

  if(item.importedGo === true) {
    return "kanban__candidacy--v2--imported2";
  }
  
  if(item.imported === true) {
    return "kanban__candidacy--v2--imported";
  }
  
  if(item.blockedByAlgorithm === true || item.status === "blocked") {
    return "kanban__candidacy--v2--blocked";
  }

  if(item.webOrigin==="linkedin" || item.webOrigin==="LinkedIn"){
    return "kanban__candidacy--v2--color6";
  }

  if(item.webOrigin==="indeed" || item.webOrigin==="Indeed"){
    return "kanban__candidacy--v2--color1";
  }

  if(item.cooptation===true){
    return "kanban__candidacy--v2--color5"
  }

  
  const average: number = Math.floor((2 * item.markForm + item.markSkills) / 3);
  
  if(average < 50) {
    return "kanban__candidacy--v2--color4";
  }
  
  if(average < 70) {
    return "kanban__candidacy--v2--color3";
  }
  
  if(average < 90) {
    return "kanban__candidacy--v2--color2";
  }
  
  if(average <= 100) {
    return "kanban__candidacy--v2--color1";
  }
  
  return "";
}

export function getWebOrigin(token: string) {
  
  if(!token) return "";
  
  if(token.toLowerCase().includes("indeed")) return "Indeed";
  
  if(token.toLowerCase().includes("pole")) return "Pôle emploi";
  
  if(token.toLowerCase().includes("region")) return "RégionJob";
  
  return "";
  
}