import React, {useContext, useEffect, useState} from "react";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {notification} from "../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";


export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    return(<>

        <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>

            <div>
                <span className={"text--bold text--magnified11x"}>Copiez le mail automatique de relance.</span> Sur certaines plateformes il n'est pas possible de
                mettre un lien pour le candidat. Utilise cet email à la place ou en plus du tiens pour recevoir les notifications de nouveaux candidats. ils seront
                automatiquement relancés. Son fonctionnement a été validé sur les plateformes suivantes: Indeed, Apec, RégionJob.
            </div>

            <Spaces n={1}/>

            <div className={"text--center"}>
                <div className={`link-box2 ${!props.white ? "link-box2--white" : ""}`}>
                    {`${props.missionTokens.mailHandler}@follow.myshortlist.co`}
                </div>
            </div>

            <Spaces n={1}/>

            <div className={"text--center"}>
                <button className={"btn-secondary"} onClick={() => {
                    notification(appContextV2.dispatchPanels, "Lien copié", "success");
                    navigator.clipboard.writeText(`${props.missionTokens.mailHandler}@follow.myshortlist.co`);
                }}>
                    Copier l'email de relance
                </button>
            </div>

        </article>

    </>);
}