import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import MissionFormEditorV2 from "../../recruiterSharedFormEditor/MissionFormEditorV2";
import publishMissionFirstTime from "../dependencies/publishMissionFirstTime";
import LoadingStyle2 from "../../../../components/sharedComponents/Loading/LoadingStyle2";
import getEditorPresets from "../dependencies/getEditorPresets";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Handles presets */
  const [presetStatus, setPresetStatus] = useState("asleep");
  const [presets, setPresets] = useState({
    default: [],
    private: [],
    classical: [],
    technical: [],
  });
  
  /** Displays a loader for user actions (save) */
  const [isPageLoading, setPageLoading] = useState(false);
  
  /** Fetch the templates from the 3 categories */
  useEffect(() => {
    (async() => {
      const presets: any = await getEditorPresets(setPresetStatus, setPresets, props.form?.positionWanted);
      // Sets default
      if(!!presets?.default) {
        props.updateForm()({target: {type: "custom", name: "form", value: presets.default}});
      }
    })();
  }, []);
  
  /** Strategy to publish a mission for the first time  */
  const publishMission = publishMissionFirstTime(setPageLoading, props, appContextV2);
  
  if(presetStatus !== "fetched") {
    return (<><Spaces n={5}/>
      <div className={"text--center"}><LoadingStyle2/></div>
      <Spaces n={10}/></>);
  }
  
  return (<section>
    <div className={"hide-on-smartphones"}>
      <MissionFormEditorV2 editorMode={"creation"}
                           isPageLoading={isPageLoading}
                           presets={presets}
                           form={props.form}
                           setForm={props.updateForm}
                           timeStamps={props.timeStamps}
                           wizard={props.wizard}
                           setMissionTokens={props.setMissionTokens}
                           saveCallback={publishMission}
                           setPresets={setPresets}
      />
    </div>
    
  </section>);
}