import React, {useContext, useEffect, useState} from "react";
import PageLayout from "../../layouts/PageLayout";
import Spaces from "../../components/sharedComponents/Spaces";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import LoadingStyle2 from "../../components/sharedComponents/Loading/LoadingStyle2";
import axios from "axios";
import {notification} from "../../utils/notification";
import {devLog} from "../../utils/devLog/devLog";
import {capitalizeMulti, cleanPhoneNumber} from "../../utils/converters";
import {format} from "date-fns";
import {PagesComponent} from "../../components/sharedComponents/PagesComponent";
import getNumberOfPages from "../../utils/getNumberOfPages";

export {RecruiterResearch};

interface IExtendedResearch {
  
  isListeningToMarket: "true" | "false" | "all",
  dateListeningFrom: Date | null,
  dateListeningTo: Date | null,
  positionsWanted: string[],
  sectors: string[],
  mobility: string[],
  skills: [],
  languages: {
    value: string,
    level: number
  }[],
  softwares: {
    value: string,
    level: number
  }[],
  salaryMin: number,
  salaryMax: number,
  salaryBy: string,
  pools: string[],
  tags: string[],
  gradeBiggerThan: number,
  gradeSmallerThan: number,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  comment: string,
  recruiter: string,
}

interface IResearch extends Partial<IExtendedResearch> {
  
  researchType: 'global' | 'extended';
  globalResearch: string;
}

const initialExtendedResearch: IExtendedResearch = {
  
  isListeningToMarket: "all",
  dateListeningFrom: null,
  dateListeningTo: null,
  positionsWanted: [],
  sectors: [],
  mobility: [],
  skills: [],
  languages: [],
  softwares: [],
  salaryMin: 0,
  salaryMax: 0,
  salaryBy: "",
  pools: [],
  tags: [],
  gradeBiggerThan: 0,
  gradeSmallerThan: 0,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  comment: "",
  recruiter: "",
}

/**
 * --------------------------------------
 * Displays research options
 * Primary-level page
 * --------------------------------------
 */
const RecruiterResearch = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Note the research string is set globally in AppV2.tsx */
  const [wasResearchDone, setWasResearchDone] = useState(false);
  const [extended, setExtended] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [result, setResult] = useState([]);
  
  /** Handle pages */
  const [page, setPage] = useState(1);
  const resultsPerPage = 30;
  const numberOfPages: number = getNumberOfPages(result.length, resultsPerPage);
  
  /** On page loading, throw a research if a research is set */
  useEffect(() => {
    (async() => {
      const isResearhActive: boolean = !!appContextV2.globalResearch;
      if(isResearhActive) {
        setExtended(false);
        setIsLoading(true);
        setWasResearchDone(true);
        throwResearch({
          research: {
            researchType: 'global',
            globalResearch: appContextV2.globalResearch,
          },
          setIsLoading, setTimer, setResult, setWasResearchDone, dispatchPanel: appContextV2.dispatchPanels,
        });
      }
    })()
  }, []);
  
  return (<PageLayout title={""} page={props.page} status={"ok"} small={true} fullWidth={true}>
    
    <Spaces n={2}/>
    
    <div className={"text--center"}>
      <h1 className={"page-title"}>
        Recherche globale
      </h1>
    </div>
    
    <Spaces n={0}/>
    
    <div className={"text--center text--grey"}>Effectue une recherche de candidat dans l'ensemble des communautés et des postes
      ouverts {appContextV2.appSession.session.userRole === "admin" ? "de ton entreprise" : "de ton équipe"}</div>
    
    <Spaces n={3}/>
    
    {/*--------------------------------------------------- Research bar */}
    <form className={"research-form"} onSubmit={(e) => {
      e.preventDefault();
      if(appContextV2.globalResearch) {
        throwResearch({
          research: {
            researchType: 'global',
            globalResearch: appContextV2.globalResearch,
          },
          setIsLoading, setResult, setTimer, setWasResearchDone, dispatchPanel: appContextV2.dispatchPanels,
        });
      }
    }}>
      <div className={"research-input-v2"}>
        <input type={"text"} className={"research-input-v2__research"} disabled={extended}
               value={appContextV2.globalResearch}
               onChange={(e) => {
                 if(e.target.value.length < 300) {
                   appContextV2.setGlobalResearch(e.target.value);
                 }
               }}
               placeholder={"Nom, Prénom, Mail, Téléphone, Poste, Mobilité, Compétences ..."}/>
        <div className={"research-input-v2__buttons"}>
          <button className={"research-input-v2__buttons__button"}
                  title={"Lance une recherche globale"}
                  disabled={!appContextV2.globalResearch || extended}
          >
            Lancer
          </button>

        </div>
      </div>
      {extended && <div className={"text--center text--minified2x text--grey"}>Ce champ est désactivé en mode "Recherche Avancée"</div>}
    </form>
    
    {/*----------------------------------------------------- Display search time*/}
    {!!timer && <div className={"text--center text--grey text--minified4x"}>{timer} ms - {result.length < 200 ? result.length : "200 +"} résultats</div>}
    
    <Spaces n={2}/>
    
    {/*--------------------------------------------------- Loading result */}
    {isLoading && <><Spaces n={2}/><LoadingStyle2/><Spaces n={1}/></>}
    
    {/*--------------------------------------------------- Extended research */}
    {extended && <>EXTEEEENDED</>}
    
    {/*--------------------------------------------------- No Results */}
    {(!isLoading && wasResearchDone && result?.length === 0) && <div className={"text--center"}>Aucun résultat</div>}
    
    {/*--------------------------------------------------- Results */}
    {(!isLoading && wasResearchDone && result && result?.length !== 0) && <>
      {result.slice((page * resultsPerPage - resultsPerPage), (page * resultsPerPage)).map((item: any, key: number) => (<div className={"research__results__result cursor--pointer"} key={key} onClick={() => {
        appContextV2.dispatchPanels({
          type: "OPEN_WINDOW", value: {
            type: "candidateWindowV2",
            data: {
              keywords: appContextV2.globalResearch,
              category: "pool",
              candidateId: item.candidateId,
              missionId: "",
              basicData: {
                firstName: item.firstName,
                lastName: item.lastName,
                avatarUrl: item.avatarUrl,
              },
              frontUpdatePool: {
                updateCandidatesBulk: () => {},
                reloadCandidates: () => {},
                addCandidateFront: () => {}
              },
              frontUpdateMission: {
                updateCandidaciesBulk: () => {},
              },
            }
          }
        })
      }}>
        <div className={"research__results__result__left"}>
          <div className={"research__results__result__avatar"}>
            {item.avatarUrl && <img src={item.avatarUrl} />}
            {!item.avatarUrl && <>{(item.firstName || "N")[0] ? (item.firstName || "N")[0].toUpperCase() : "N"}{(item.lastName || "N")[0] ? (item.lastName || "N")[0].toUpperCase() : "N"}</>}
          </div>
          <div>{capitalizeMulti(item.firstName)} {capitalizeMulti(item.lastName)}</div> <div className={"research__results__result__small"}> {cleanPhoneNumber(item.phone)}</div>
        </div>
        <div className={"research__results__result__right"}>
          <div className={"research__results__result__small"}>Dernier échange: {item.dateOfLastAction ? format(new Date(item.dateOfLastAction), "dd-MM-yyyy") : "--"}</div>
          <div className={"research__results__result__listening"}>{item.isListeningToMarket ? "À l'écoute" : "Pas à l'écoute"}</div>
        </div>
      </div>))}
    </>}
  
    <Spaces n={2}/>
  
    {/*------------------------------------------------------ Pages*/}
    <div className={"text--center"}>
      <PagesComponent page={page} pages={numberOfPages} setPage={setPage}/>
    </div>
    
    <Spaces n={5}/>
  
  </PageLayout>);
}

async function throwResearch(args: { research: IResearch, setIsLoading: Function, setTimer: Function, setWasResearchDone: Function, setResult: Function, dispatchPanel: Function, },) {
  try {
    
    args.setIsLoading(true);
    
    const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/communities/research/r/main`, {
      headers: {
        researchtype: args.research.researchType,
        globalresearch: args.research.globalResearch,
      },
      timeout: 30_000,
      withCredentials: true,
    });
    
    if(fetched.data.status !== "ok") {
      args.setIsLoading(false);
      notification(args.dispatchPanel, "Une erreur est survenue", "error");
      return;
    }
    
    args.setTimer(fetched.data.serverTime);
    args.setIsLoading(false);
    args.setWasResearchDone(true);
    args.setResult(fetched.data.data);
    
  } catch (error) {
    devLog(error);
    args.setIsLoading(false);
    notification(args.dispatchPanel, "Une erreur est survenue", "error");
    return;
  }
}