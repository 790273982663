import React from "react";
import {Switch} from "react-router-dom";
import AppSwitch from "../components/sharedComponents/AppSwitch";
import Spaces from "../components/sharedComponents/Spaces";
import {IAppContent} from "../components/App/SingleApp";

export default (props: {
  app: IAppContent;
  description: any;
  price: any;
  children: any;
}) => {
  
  return (<div className={'app-window'}>
    
    <div className={'app-window__app-name'}>
      {!props.app.isActive && <div className={"app-window__app-name__slider"}>
      </div>}
      <img src={props.app.iconUrl}/>
      <Spaces n={0}/>
      <h1>{props.app.name}</h1>
      <div className={"space-flex--center"}>
        <div className={"space-flex--left"}>
        <AppSwitch active={props.app.isActive} size={'big'}/>
        {props.app.isActive && <span className={'text--minified2xx'}>Activée</span>}
        {!props.app.isActive && <span className={'text--minified2x'}>Non activée</span>}
        </div>
      </div>
    
    
    <section className={'app-window__app-name__content'}>
      <title> Description:
        <article> {props.description}</article>
      </title>
      <title> Prix:
        <article> {props.price}</article>
      </title>
      <title> Details:
        <article> {props.children}</article>
      </title>
    </section>

    </div>
  </div>);
}
