import React from "react";
import WindowWrapper from "../../../../layouts/WindowLayout";
import Spaces from "../../Spaces";

export default (props: any) => {
  
  return(<WindowWrapper windowTitle={""} status={"ok"}>
    
    <div className={"text--bold text--center text--magnified2x"}>Où trouver mon profil LinkedIn ?</div>
    
    <Spaces n={1}/>
  
    <img src={"https://static.myshortlist.co/linkedin_url.jpg"} />
  
    <Spaces n={1}/>
    
    Une fois sur votre espace LinkedIn :
  
    <Spaces n={0}/>
    
    - Cliquez en haut à droite sur "Vous", en dessous de votre avatar,
  
    <Spaces n={0}/>
    
    - Cliquez sur "Voir le profil",
  
    <Spaces n={0}/>
    
    - Copiez l'adresse de la page qui s'ouvre.
  
  </WindowWrapper>);
}