import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import BackToLandingPage from "./shared/BackToLandingPage";
import SubNavLinkGenerator from "./shared/SubNavLinkGenerator";

export function SubMenuRecruiterParametersContent(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const currentPage: string = appContextV2.appNavigation.page;
  const currentSubPage: string = appContextV2.appNavigation.subPage;
  const userRole: string = appContextV2.appSession.session.userRole;
  
  const showAdmminMenu: boolean = (userRole === 'admin' || userRole === 'superAdmin');
  
  return (<>
    <BackToLandingPage/>
    <SubNavLinkGenerator fr={"Paramètres"} en={"Parameters"} uri={"/business/parameters"}
                         active={currentPage === "RecruiterParameters" && currentSubPage === "RecruiterParametersParameters"}
                         alt={"parameters"} icon={"https://static.myshortlist.co/btn-parameters.svg"} smaller={false}/>
    {
      showAdmminMenu && <>
        <SubNavLinkGenerator fr={"Apps"} en={"Apps"} uri={"/business/parameters/apps"}
                             active={currentPage === "RecruiterParameters" && currentSubPage === "RecruiterParametersSubscription"}
                             alt={"subscription"} icon={"https://static.myshortlist.co/btn-credit.svg"} smaller={false}/>
        <SubNavLinkGenerator fr={"Équipes"} en={"Team"} uri={"/business/parameters/team"}
                             active={currentPage === "RecruiterParameters" && currentSubPage === "RecruiterParametersTeam"}
                             alt={"team"} icon={"https://static.myshortlist.co/btn-team-v2.svg"} smaller={false}/>
        {/*<SubNavLinkGenerator fr={"Dictionnaires"} en={"Dictionaries"} uri={"/business/parameters/dictionaries"}*/}
        {/*                     active={currentPage === "RecruiterParameters" && currentSubPage === "RecruiterParametersDictionaries"}*/}
        {/*                     alt={"team"} icon={"https://static.myshortlist.co/book.svg"} smaller={false}/>*/}
      </>
    }
  </>);
}