import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../App/context/AppContext";
import FirstLoginTutorialRecruiter from "./TutorialRecruiter/FirstLoginTutorialRecruiter";

export default function FirstLoginTutorialContent(props: any) {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

  const space = appContextV2.appSession.app.space;

  switch(space) {

    case "recruiter":
      return(<FirstLoginTutorialRecruiter {...props} />);

    case "candidate":
      return(<></>);

    default:
      return(<></>);
  }

}