import AppContext, {IAppContext, IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext, useEffect} from "react";
import WindowWrapper from "../../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {abstractPostFetch, abstractPostFileFetch} from "../../../../utils/abstractFetch";
import {useWizard} from "use-wizard";
import {useForm} from "use-formidable";
import {WAMTBPChoice} from "./componentsWAMTBP/WAMTBPChoice";
import {WAMTBPImage} from "./componentsWAMTBP/WAMTBPImage";
import {WAMTBPImagePortrait} from "./componentsWAMTBP/WAMTBPImagePortrait";
import {WAMTBPVideo} from "./componentsWAMTBP/WAMTBPVideo";
import {WAMTBPText} from "./componentsWAMTBP/WAMTBPText";
import {WAMTBPPending} from "./componentsWAMTBP/WAMTBPPending";
import {WAMTBPSuccess} from "./componentsWAMTBP/WAMTBPSuccess";
import {WAMTBPError} from "./componentsWAMTBP/WAMTBPError";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [step, wizard] = useWizard(["choice", "image", "imagePortrait", "video", "freeText", "pending", "error", "success"]);
  
  const [form, updateForm, formidable] = useForm({
    key: "", // media1 media2 media3 column1 column2 column3
    type: "", // image imagePortrait video freeText
  });
  
  /** Set the the key and go to first step if it can jump the first steps */
  useEffect(() => {
    (async() => {
      
      const positionKey = appContextV2.appPanels.window.windowOptions.data.positionKey;
      
      await updateForm()({target: {value: positionKey, name: "key", type: "custom"}});
      
      switch(positionKey) {
        
        case 'media1':
          await updateForm()({target: {value: "image", name: "type", type: "custom"}});
          wizard.goToStep("image");
          break;
        
        case 'media2':
          await updateForm()({target: {value: "image", name: "type", type: "custom"}});
          wizard.goToStep("image");
          break;
        
        case 'media3':
          await updateForm()({target: {value: "image", name: "type", type: "custom"}});
          wizard.goToStep("image");
          break;
      }
    })();
  }, []);
  
  const windowContext = {
    
    form: form,
    updateForm: updateForm,
    formidable: formidable,
    wizard: wizard,
    step: step,
  };
  
  switch(step) {
    
    case 'choice':
      return (<WindowWrapper windowTitle={""} size={"small"} status={"ok"}>
        <WAMTBPChoice windowContext={windowContext}/>
      </WindowWrapper>);
    
    case 'image':
      return (<WindowWrapper windowTitle={""} size={"small"} status={"ok"}>
        <WAMTBPImage windowContext={windowContext}/>
      </WindowWrapper>);
    
    case 'imagePortrait':
      return (<WindowWrapper windowTitle={""} size={"small"} status={"ok"}>
        <WAMTBPImagePortrait windowContext={windowContext}/>
      </WindowWrapper>);
    
    case 'video':
      return (<WindowWrapper windowTitle={""} size={"small"} status={"ok"}>
        <WAMTBPVideo windowContext={windowContext}/>
      </WindowWrapper>);
    
    case 'freeText':
      return (<WindowWrapper windowTitle={""} size={"small"} status={"ok"}>
        <WAMTBPText windowContext={windowContext}/>
      </WindowWrapper>);
    
    case 'pending':
      return (<WindowWrapper windowTitle={""} size={"small"} status={"pending"}>
        <WAMTBPPending windowContext={windowContext}/>
      </WindowWrapper>);
    
    /**
     * @deprecated
     */
    case 'success':
      return (<WindowWrapper windowTitle={""} size={"small"} status={"ok"}>
        <WAMTBPSuccess windowContext={windowContext}/>
      </WindowWrapper>);
    
    default:
    /**
     * @deprecated
     */
    case 'error':
      return (<WindowWrapper windowTitle={""} size={"small"} status={"ok"}>
        <WAMTBPError windowContext={windowContext}/>
      </WindowWrapper>);
  }
}
