
export default (input: unknown) => {
  
  if(!input || typeof input !== "string") return "";
  
  let answer: string = "";
  let numberOfSpaces = 0;
  
  for(let i = 0; i < input.length; i++) {
    
    answer = input[input.length - 1 - i] + answer;
  
    if(i !== 0 && i !== input.length - 1 && numberOfSpaces < 4 && i % 2 !== 0) {
      answer = " " + answer;
      numberOfSpaces ++;
    }
  }
  
  return answer;
  
}