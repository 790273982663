import React from "react";
import DisplayTutorialSteps from "../../DisplayTutorialSteps";

export default (props: any) => {

    return (<div className={"window-tutorial__content"}>

        <div className={"window-tutorial__main-content animation-fade-in"}>
            <h1 className={"window-tutorial__title1"}> Gérez vos communautés </h1>

            {/*<img className={"window-tutorial__picture"}/>*/}

            <p className={"window-tutorial__container"}><br/>
              Il est parfois frustrant de devoir mettre de côté des bons candidats qu'on aurait aimé recruter.<br/><br/>
                Les communautés vous permettent de <b className={"window-tutorial__b"}>conserver</b> des candidats sur le long terme pour vos prochains recrutements,
                mais également d'<b className={"window-tutorial__b"}>animer</b> ces communautés au travers de diverses <b className={"window-tutorial__b"}>actions de masse</b>:<br/><br/>
              <ul className={"window-tutorial__ul"} >
                <li>Envoi de mails ou de NewsLetters</li>
                <li>Envoi de SMS</li>
                <li>Envoi d'offres d'emploi</li>
                <li>Envoi de nouveau liens pour postuler</li>
                <li>Demande de disponibilités</li>
              </ul>
            </p>
        </div>

        <div className={"window-tutorial__secondary-content"}>
            <DisplayTutorialSteps {...props}/>

            <div>
                <button className={"btn-secondary"} onClick={async (e) => props.hideTutorialForEver()}>
                    Ne plus afficher
                </button>

                <button className={"btn-secondary"} onClick={() => props.setStep((ps: number) => ps + 1)}>Suivant</button>
            </div>
        </div>

    </div>);
}




