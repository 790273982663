import React, {useEffect, useState} from "react";
import Spaces from "../../../Spaces";
import LoadingStyle2 from "../../../Loading/LoadingStyle2";
import {fetchPools} from "../../../../../pages/privateRecruiter/componentsRecruiterPools/dependenciesRecruiterPools/fetch/fetchPools";
import {devLog} from "../../../../../utils/devLog/devLog";
import {IColumnData} from "../WindowColumnOptionsV2";

export {WCOCommunityV2};

const WCOCommunityV2 = (props: {
    type: "kanban" | "preset",
    missionId: string,
    numberOfCandidates: number,
    columnData: IColumnData,
    updateColumnData: Function,
    saveColumnData: Function,
    switchColumnAction: Function,
    isWindowLoading: boolean,
    deleteColumn: Function
}) => {

    /** Downloaded pools */
    const [fetchingCommunities, setFetchingCommunities] = useState("asleep");
    const [communities, setCommunities]: [any, any] = useState([]);
    useEffect(() => {
        fetchPools(setFetchingCommunities, setCommunities);
    }, []);

    return(<>

        <article className={`white-container white-container--grey`}>
            <div className={"space-flex--space-between"}>
                <span className={"text--bold text--magnified11x"}>Activer l'action "Déplacer dans une communauté" ?</span>
                <div>
                    <label className={"switch-V2"}>
                        <input type={"checkbox"} className={"checkbox-on-off"} checked={!!props.columnData.activatePool} onChange={() => {
                            if(!!props.columnData.activatePool) {
                                props.updateColumnData({activatePool: false});
                                props.saveColumnData({activatePool: false});
                            }
                            if(!props.columnData.activatePool) {
                                props.switchColumnAction("activatePool");
                            }
                        }}/>
                        <span className="slider"> </span>
                    </label>
                </div>
            </div>

            <Spaces n={0}/>
            <div className={"text--center text--grey"}>
                Les candidats déplacés dans cette colonne seront automatiquement conservés dans une communauté de votre choix.
            </div>

            {/*------------------- Content*/}
            {
                !!props.columnData.activatePool && <div className={"text--center"}>
                    {
                        (fetchingCommunities === "fetched" && communities.length !== 0) && <>
                            <Spaces n={1}/>
                            <select className={"input--v2"} value={props.columnData.poolId} name={"poolId"}
                                    onChange={(e) => {
                                        props.updateColumnData({poolId: e.target.value});
                                    }}>
                                <option value={""} disabled={true}>-- Sélectionne une communauté --</option>
                                {communities.map((item: any, key: number) => (<option key={key} value={item._id}>
                                    {item.name} ({item.number})
                                </option>))}
                            </select>
                            <button
                                className={"btn-secondary"} onClick={() => {
                                props.saveColumnData({poolId: props.columnData.poolId});
                            }}>
                                Enregistrer
                            </button>
                        </>
                    }
                    {
                        (fetchingCommunities === "fetched" && communities.length === 0) && <>
                            <Spaces n={1}/>
                            Aucune communauté disponible
                        </>
                    }
                    {
                        (fetchingCommunities === "fetching") && <>
                            <Spaces n={1}/>
                            <LoadingStyle2 />
                        </>
                    }
                </div>
            }

        </article>

    </>);
}