
interface IState {
    blackBeltCulture:number,
    blackBeltHardSkills:number,
    blackBeltSoftSkills:number,
    blackBeltImportant:string[],
    blackBeltAvailability:string,
    blackBeltInformations:string[],
    blackBeltVideo:string,
    blackBeltLoomLink:string,
    blackBeltNextStep:string,
    blackBeltTchat:{
        dateOfCreation:Date,
        from:string,
        message:string,
    }[],
    blackBeltFeedbackInterview:string,
    missionDateOfCreation:Date,
    candidacyDateOfCreation:Date,
    sentToClient:Date,
    clientAnswer:Date,
    blackBeltInterview:Date,
    clientInterview:Date,
    jobOffer:Date,
    onboarding:Date,
    interview2: string[],
    entryDate:Date,
    endOfInitialTrialPeriod:Date,
    endOfTrialPeriod:Date,
    internalCommentary:string,

}

export default (previousState: IState, action: { type: string, value: any }): any => {

    switch(action.type) {

        case 'INITIALIZE_DATA':
            return ({
                ...previousState,
                ...action.value
            });


        case 'UPDATE_CANDIDACY':
            return ({
                ...previousState,
                ...action.value,
            });

        case 'ADD_IMPORTANT':
                return ({
                    ...previousState,
                    blackBeltImportant: [...(previousState?.blackBeltImportant ?? []),
                        action.value
                    ]
                });

        case 'DELETE_IMPORTANT':
        return ({
            ...previousState,
            blackBeltImportant: previousState?.blackBeltImportant?.filter((item: any) => item !== action.value)
        });

        case 'ADD_INFORMATIONS':
            return ({
                ...previousState,
                blackBeltInformations: [...(previousState?.blackBeltInformations ?? []),
                    action.value
                ]
            });

        case 'DELETE_INFORMATIONS':
            return ({
                ...previousState,
                blackBeltInformations: previousState?.blackBeltInformations?.filter((item: any) => item !== action.value)
            });


        case 'NEW_MESSAGE':
            return ({
                ...previousState,
                blackBeltTchat: [
                ...previousState?.blackBeltTchat,
                action.value,
            ]
            });
    
        case 'ADD_INTERVIEW2':
            return ({
                ...previousState,
                interview2: [...(previousState?.interview2 ?? []),
                    action.value
                ]
            });
    
        case 'DELETE_INTERVIEW2':
            return ({
                ...previousState,
                interview2: previousState?.interview2?.filter((item: any) => item !== action.value)
            });
    
            

        default:
            return previousState;
    }

}