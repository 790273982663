import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";
import Spaces from "../../../Spaces";
import {isEmailValid} from "../../../../../utils/typeGuards";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const [email, setEmail] = useState("");
  
  return (<>
      
      <p className={"assistant-layout__title"}>
        Le mot de passe ne semble pas valide. Vous pouvez le réinitialiser avec votre mail.
      </p>
      
      <Spaces n={0}/>
      
      
      <div className={"assistant-layout__min-height"}>
        <article className={`assistant-layout--column assistant-layout--animated`}>
          <div className={"div-center"}>
            <input value={email} placeholder={"Email"}
                   className={`input--v2`}
                   onChange={(e) => {
                     setEmail(e.target.value)
                   }}/>
          </div>
        </article>
      </div>
      
      <Spaces n={0}/>
      
      <span className={"text--grey"}>Attention, certaines entreprises bloquent nos emails. Contactez nos services si vous souhaitez modifier votre mot de passe et que vous ne recevez pas d'email.</span>
      
      <Spaces n={0}/>
      
      <div className={"div-center"}>
        <button className={" modern-link"} disabled={!isEmailValid(email)} onClick={async() => {
          try {
            const {
              status,
              data
            } = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/session/new/password`, {
              email: email,
            }, {
              withCredentials: true,
            })).data;
            
            if(status === "ok") {
              return props.windowContext.wizard.goToStep("successNewPassword");
            }
            
          } catch(error) {
            devLog(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            return props.windowContext.wizard.goToStep("error");
          }
        }}>
          Envoyer un mail
        </button>
      </div>
      
      <Spaces n={0}/>
      <div className={"div-center"}>
        <button className={" modern-link"} onClick={() => {
          return props.windowContext.wizard.goToStep("enterEmail")
        }}>
          Connexion par mail
        </button>
      </div>
    </>
  );
}
