import React, {useEffect} from "react";
import {useWizard} from "use-wizard";
import {FileDrop} from "../../../../components/sharedComponents/Buttons/FileDrop";

export default (props: any) => {

    const [step, wizard] = useWizard(["none", "fileExist", "noFile", "selectFile", "fileSelected",]);

    const doesFileExist: boolean = !!props.mission.documentUrl;
    const isFileSelected: boolean = !!props.jobDesc.file;

    useEffect(() => {
        if(doesFileExist) {
            return wizard.goToStep("fileExist");
        }
        wizard.goToStep("noFile")
    }, [props.mission.documentUrl]);


    if(step === "fileExist") {
        return (<>
            - <a className={"modern-link"} href={props.mission.documentUrl}>Télecharger la fiche de poste</a>.
            <button className={"btn--grey-art"} onClick={() => wizard.goToStep("selectFile")}>
            Modifier</button>
            <button className={"btn--grey-art"} onClick={() => {
                props.dispatchMission({
                    type: "UPDATE_INFOS",
                    value: {documentUrl: ""}
                })
            }}>
                Supprimer
            </button>
        </>);
    }

    if(step === "noFile") {
        return (<>
            - Aucune fiche de poste n'a été détectée. <button className={"btn--grey-art"} onClick={() => wizard.goToStep("selectFile")}>Ajouter</button>
        </>);
    }

    if(step === "selectFile") {
        return (<>
            <div className={"space-flex--center full-width"}>
                {!isFileSelected && <FileDrop square={false} name={"file"} cb={props.setJobDesc}/>}
                {isFileSelected && <div>Le fichier est prêt à être ajouté, n'oubliez pas de sauvegarder <button className={"btn--grey-art"} onClick={() => {
                    props.setJobDesc()({target: {type: "custom", name: "file", value: null}});
                }}>Modifier</button>
                </div>}
            </div>
        </>);
    }

    if(step === "fileSelected") {
        return (<>Le fichier est prêt à être ajouté, n'oubliez pas de sauvegarder <button className={"btn--grey-art"} onClick={() => wizard.goToStep("selectFile")}>
            Modifier</button></>);
    }

    return (<>
    </>);

}