import AppContext, {IAppContext, IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext, useState} from "react";
import WindowWrapper from "../../../../layouts/WindowLayout";
import LoadingStyle2 from "../../Loading/LoadingStyle2";
import Spaces from "../../Spaces";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import {devLog} from "../../../../utils/devLog/devLog";


export default function (props: any) {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const [actionType, setActionType] = useState("");
    const [columnId, setColumnId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [buttonSelected, setButtonSelected]=useState("");

    const moveCandidacyFront = appContextV2.appPanels.window.windowOptions.data.moveCandidacyFront;
    const missionId = appContextV2.appPanels.window.windowOptions.data.missionId;
    const selected = appContextV2.appPanels.window.windowOptions.data.selected;
    const customKanbanView = appContextV2.appPanels.window.windowOptions.data.customKanbanView;
    const dispatchDataV2 = appContextV2.appPanels.window.windowOptions.data.dispatchDataV2;




    const doAction = async () => {
        try {
            setIsLoading(true);
            // update front
            dispatchDataV2({
                type: "MOVE_CANDIDACIES",
                value: {
                    selected:selected,
                    columnId
                }
            });
            // Update back
            const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/jobs/mass/action`, {
                missionId:missionId,
                toColumnId:columnId,
                selected:selected,
                actionType:actionType,
            }, {withCredentials: true});

            appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
            if (fetched.data.status !== "ok") {
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
            notification(appContextV2.dispatchPanels, "Candidatures deplacees", "success");


        } catch (error) {
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            devLog(error);
        }
    }


return (<WindowWrapper size={"big"} status={"ok"} windowTitle={""}>
    <div className={"container"}>
        <h1 className={"text--center"}>
            Choisir une action groupée
        </h1>
        <div className={"text--center text--grey"}>
            Une action groupée s'appliquera aux {selected?.length ?? 0} candidats sélectionnés
        </div>
        <div className={"mass-action__center"}>
        <select className={"filters__select text--center "} value={actionType} onChange={(e) => {
            setActionType(e.target.value);
        }}>
            <option value={""} disabled>Choisir une action</option>
            <option value={"moveCandidacies"}> Déplacer les candidats</option>
        </select>
        </div>

        {actionType === "moveCandidacies" &&
        <article className={"white-container white-container--grey"}>
            <div className={"text--center"}>
                Dans quelle colonne voulez vous déplacer les candidats ?
            </div>
            <div className={"text--center"}>
            <button className={`mass-action__btn ${buttonSelected === "1"? "mass-action__btn--selected":""}`}
                    onClick={() => {
                setColumnId("1");
                setButtonSelected("1");
            }}>Candidatures reçues
            </button>
            {customKanbanView?.map((item: any, key: number) => (
                <button className={`mass-action__btn ${buttonSelected === item._id ? "mass-action__btn--selected":""}`}
                        onClick={() => {
                    setColumnId(item._id);
                    setButtonSelected(item._id);
                }}> {item.name} </button>
            ))}
            <button className={`mass-action__btn ${buttonSelected === "10"? "mass-action__btn--selected":""}`}
                    onClick={() => {
                setColumnId("10");
                setButtonSelected("10");
            }}>Recrutements
            </button>
            </div>
            <Spaces n={1}/>

            <div className={"mass-action__center"}>
            <button className={"btn-secondary "} disabled={isLoading || buttonSelected===""} onClick={() => doAction()}>Effectuer l’action</button>
            {isLoading && <><LoadingStyle2/><Spaces n={1}/></>}
            </div>
        </article>}


    </div>
</WindowWrapper>);
}
