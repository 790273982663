import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";

export default async(pageInfos: any, setBusinessFetchStatus: any, setBusiness: any) => {
  try {
    setBusinessFetchStatus("fetching");
    const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/b/get/business/profile`, {
      headers: {
        slugname: pageInfos.slugname,
        slugnumber: pageInfos.slugnumber,
      },
      withCredentials: true,
    });
    if(fetched.data.status !== "ok") {
      return setBusinessFetchStatus("error");
    }
    document.title = `Postulez chez ${fetched.data.data.businessName}`;
    setBusiness(fetched.data.data);
    setBusinessFetchStatus("fetched");
  } catch (error) {
    devLog(error);
    setBusinessFetchStatus("error");
  }
}