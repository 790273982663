import axios from "axios";
import {devLog} from "../../../../utils/devLog/devLog";

export {fetchMissions};

const fetchMissions = async(setFetchStatusMissions: Function, setMissions: Function, allTeams = false) => {
  try {
    setFetchStatusMissions("fetching");
    const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/missions?allTeams=${allTeams}`, {withCredentials: true});
    if(fetched.data.status !== "ok") {
      return setFetchStatusMissions("error");
    }
    setMissions(fetched.data.data);
    setFetchStatusMissions("fetched");
  } catch (error) {
    devLog(error);
    setFetchStatusMissions("error");
  }
}