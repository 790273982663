import React, {useEffect, useState} from "react";
import PageLayoutWithHeader from "../../layouts/PageLayoutWithHeader";
import {useWizard} from "use-wizard";
import {NotOnSmartphones} from "../../components/sharedComponents/NotOnSmartphones";
import {useForm} from "use-formidable";
import initialForm from "./componentsRecruiterNewMission/dependencies/initialValues/initialForm";
import initialTimeStamps, {ITimeStamp} from "./componentsRecruiterNewMission/dependencies/initialValues/initialTimeStamps";
import initialSavedEditor from "./componentsRecruiterNewMission/dependencies/initialValues/initialSavedEditor";
import {initializeNewMissionWizard} from "./componentsRecruiterNewMission/dependencies/initialValues/initializeNewMissionWizard";
import {NewMissionRouter} from "./componentsRecruiterNewMission/NewMissionRouter";
import {IAssistantContext} from "./componentsRecruiterNewMission/dependencies/interfaces/IAssistantContext";
import {getNewMissionTitle} from "./componentsRecruiterNewMission/dependencies/getNewMissionTitle";
import initialJobs from "./componentsRecruiterNewMission/dependencies/initialValues/initialJobs";
import {devLog} from "../../utils/devLog/devLog";
import initialMissionTokens from "./componentsRecruiterNewMission/dependencies/initialValues/initialMissionTokens";
import Spaces from "../../components/sharedComponents/Spaces";

/**
 * --------------------------------------
 * Process to add a new mission
 * Primary-level page
 * --------------------------------------
 */
export default (props: any) => {
  
  /** Sets up the wizard */
  const [step, wizard] = useWizard(initializeNewMissionWizard);
  
  /** mail token and url when the mission was posted */
  const [missionTokens, setMissionTokens] = useState(initialMissionTokens);
  
  /** Downloaded private and public dictionaries for a specific team and fetch status */
  const [defaultLocation, setDefaultLocation]: [any, Function] = useState({});
  const [advantages, setAdvantages]: [any, Function] = useState([]);
  const [skills, setSkills]: [any, Function] = useState([]);
  const [jobs, setJobs]: [any, Function] = useState(initialJobs);
  devLog("skills", skills);
  devLog("advantages", advantages);
  
  /** Timer in seconds */
  const [timeStamps, setTimeStamps]: [ITimeStamp, Function] = useState(initialTimeStamps);
  
  /** Form  */
  const [form, updateForm, formidable] = useForm(initialForm);
  devLog("form", form);
  
  /** Initial fetch (to check if a current saved version exists) */
  useEffect(() => {
    // fetchSavedEditor(setFetchSavedEditor, setSavedEditor);
    // Display a window if a saved editor exists
  }, []);
  
  const pageTitle: string = getNewMissionTitle(step);
  
  const assistantContext: IAssistantContext = {
    mode: "assistant",
    step, wizard,
    form, updateForm, formidable,
    timeStamps, setTimeStamps,
    defaultLocation, setDefaultLocation,
    advantages, setAdvantages,
    skills, setSkills,
    jobs, setJobs,
    missionTokens, setMissionTokens,
  }
  
  return (<PageLayoutWithHeader grey={false} hideHeader={false} title={pageTitle} small={true} fullWidth={true} page={props.page} status={"ok"}>
    
    <NewMissionRouter {...assistantContext} />
    
    <NotOnSmartphones/>
    
    <Spaces n={8}/>
  
  </PageLayoutWithHeader>);
};
