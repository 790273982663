import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import PageLayoutWithHeader from "../../layouts/PageLayoutWithHeader";
import axios from "axios";
import {Link} from "react-router-dom";
import {notification} from "../../utils/notification";

function cleanLinkedinUrl(url: string) {
    if (!url) return url;
    // in case an url is like "https://www.linkedin.comhttps://www.linkedin.com/in/ACoAACRXhJUBcNXxj5AsxSqWQx_OXNByuJvv33k", remove the first "https://www.linkedin.com"
    if (url.includes("https://www.linkedin.comhttps://www.linkedin.com")) {
        console.log('==== Cleaned Linkedin Url ====');
        url = url.replace("https://www.linkedin.comhttps://www.linkedin.com", "https://www.linkedin.com");
    }
    return url
}

export default (props: any) => {
    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    const [candidate, setCandidate] = useState<any>(null)
    const [status, setStatus] = useState<'loading' | 'loaded'>('loading')

    useEffect(() => {
        (async() => {
            try {
                const id = props.match.params.id;
                const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/candidate/v2`, {
                    timeout: 20_000,
                    headers: {
                        candidateid: id,
                        missionid: null,
                        category: 'search',
                    },
                    withCredentials: true
                });
                console.log("====", fetched.data.data)
                if (fetched.data.status !== "ok") {
                    return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                }
                setCandidate(fetched.data.data);
                setStatus('loaded')
            } catch(error) {
                console.error(error)
            }
        })()
    }, [])

    return <PageLayoutWithHeader grey={false} title={"Contrôle des sources"} small={true} fullWidth={true} page={props.page}
                                 status={"ok"} hideHeader={false}>

        <div style={{textAlign: "center"}}>
            <h2>Contrôle des sources d'un(e) candidat(e)</h2>
        </div>

        <br/><br/>

        {status === 'loading' && <div style={{textAlign: "center"}}>Chargement...</div>}

        {!!candidate && <div className={"sources"}>

           <div>
               <h1>Source BB</h1>

               <div>id: {candidate.candidate._id}</div>
                <div>tas: {candidate.candidate.tas}</div>
                <div>availability: {candidate.candidate.availability}</div>
                <div>dateOfLastAction: {candidate.candidate.dateOfLastAction}</div>
               <div>isWorking: {candidate.candidate.isWorking}</div>
               <div>isListeningToMarket: {candidate.candidate.isListeningToMarket}</div>
               <div>dateOfLastActionPause: {candidate.candidate.dateOfLastActionPause}</div>
               <div>gradeByTeam: {candidate.candidate.gradeByTeam}</div>
               <div>firstName: {candidate.candidate.firstName}</div>
               <div>lastName: {candidate.candidate.lastName}</div>
               <div>email: {candidate.candidate.email}</div>
               <div>phone: {candidate.candidate.phone}</div>
               <div>phone2: {candidate.candidate.phone2}</div>
               <div>linkedin: {candidate.candidate.linkedin ?
                   <a className={"link"} href={cleanLinkedinUrl(candidate.candidate.linkedin)}
                      target={"_blank"}>{candidate.candidate.linkedin}</a> : ""}</div>
               <div>linkedinToken: {candidate.candidate.linkedinToken}</div>
               <div>linkedinFingerPrint: {candidate.candidate.linkedinFingerPrint}</div>
               <div>linkedinFingerPrint2: {candidate.candidate.linkedinFingerPrint2}</div>
               <div>location: TODO</div>
               <div>positionsWanted: {candidate.candidate.positionsWanted}</div>
               <div>keyExperience: {candidate.candidate.keyExperience}</div>
               <div>yearsOfExperience: {candidate.candidate.yearsOfExperience}</div>
               <div>sectors: {candidate.candidate.sectors?.map((s: string) => s)}</div>
               <div>mobility: {candidate.candidate.mobility?.map((m: string) => m)}</div>
               <div>languages: {candidate.candidate.languages?.map((l: any) => l?.value)}</div>
               <div>softwares: {candidate.candidate.softwares?.map((l: any) => l?.softwares)}</div>
               <div>currentSalary: {candidate.candidate.currentSalary}</div>
               <div>currentVariableSalary: {candidate.candidate.currentVariableSalary}</div>
               <div>salaryMin: {candidate.candidate.salaryMin}</div>
               <div>salaryVariableMin: {candidate.candidate.salaryVariableMin}</div>
               <div>salaryMax: {candidate.candidate.salaryMax}</div>
               <div>salaryBy: {candidate.candidate.salaryBy}</div>
               <div>recruiter: {candidate.candidate.recruiter}</div>
               <div>cvUrl: {candidate.candidate.cvUrl}</div>
               <div>cvUrls: {candidate.candidate.cvUrls?.map((cv: string) => cv)}</div>
               <div>cvFingerPrint: {candidate.candidate.cvFingerPrint}</div>
               <div>lastScrapped: {candidate.candidate.lastScrapped}</div>
               <div>lobstrId: {candidate.candidate.lobstrId}</div>
               <div>lobstrTaskId: {candidate.candidate.lobstrTaskId}</div>
               <div>sentToLobstr: {candidate.candidate.sentToLobstr}</div>
               <div>parsedByWrongCluster: {candidate.candidate.parsedByWrongCluster}</div>
               <div>skills: {candidate.candidate.skills?.map((s: string) => s)}</div>
               <div>locationString: {candidate.candidate.locationString}</div>
               <div>positionWantedString: {candidate.candidate.positionWantedString}</div>

               <br/>
               <div>--- extendedProfile</div>

               <div>dateOfUpdate: {candidate.candidate.extendedProfile?.dateOfUpdate}</div>
               <div>workedIn: {candidate.candidate.extendedProfile?.workedIn?.map((w: string) => w)}</div>
               <div>flashcard: {candidate.candidate.extendedProfile?.flashcard}</div>
               <div>videoUrl: {candidate.candidate.extendedProfile?.videoUrl}</div>
               <div>experience: {candidate.extendedProfile?.experience?.map((x: any) => {
                   return <div>- {x.position} / {x.company}</div>
               })}</div>
           </div>

            <div>
                <h1>Source LinkedIn</h1>
                <DisplayPQD pqd={candidate.parsedIn} />
            </div>

            <div>
                <h1>Source CV</h1>
                <DisplayPQD pqd={candidate.parsedResume} />
            </div>

        </div>}

    </PageLayoutWithHeader>
}

function DisplayPQD(args: any) {

    const pqd = args.pqd

    if (!pqd || !pqd.parsed) return <>No PDQ</>

    return <>
        <div>createdAt: {pqd?.createdAt}</div>
        <div>candidateId: {pqd?.candidateId}</div>
        <div>fingerprint: {pqd?.fingerprint}</div>
        <div>type: {pqd?.type}</div>
        <div>timeToParse: {pqd?.timeToParse}</div>
        <div>source: {pqd?.source}</div>
        <div>url: {pqd?.parsed?.url}</div>
        <div>summary: {pqd?.parsed?.summary}</div>

        <div>firstName: {pqd?.parsed?.information.firstName}</div>
        <div>lastName: {pqd?.parsed?.information.lastName}</div>
        <div>fullName: {pqd?.parsed?.information.fullName}</div>
        <div>dateOfBirth: {pqd?.parsed?.information.dateOfBirth}</div>
        <div>gender: {pqd?.parsed?.information.gender}</div>

        <div>phones:</div>
        <div style={{ paddingLeft: '20px' }}>
            {pqd?.parsed?.contact.phones.map((phone:any, index: any) => (
                <div key={index}>
                    <div>raw: {phone.raw}</div>
                    <div>normalized: {phone.normalized}</div>
                    <div>internationalPrefix: {phone.internationalPrefix}</div>
                    <div>nationalNumber: {phone.nationalNumber}</div>
                </div>
            ))}
        </div>
        <div>emails:</div>
        <div style={{ paddingLeft: '20px' }}>
            {pqd?.parsed?.contact.emails.map((email: any, index: any) => (
                <div key={index}>{email?.email}</div>
            ))}
        </div>
        <div>locations:</div>
        <div style={{ paddingLeft: '20px' }}>
            {pqd?.parsed?.contact.locations.map((location: any, index: number) => (
                <div key={index}>
                    <div>street: {location.street}</div>
                    <div>city: {location.city}</div>
                    <div>postalCode: {location.postalCode}</div>
                    <div>countryCode: {location.countryCode}</div>
                    <div>location:</div>
                    <div style={{ paddingLeft: '20px' }}>
                        <div>type: {location.location.type}</div>
                        <div>coordinates: {location.location.coordinates.join(', ')}</div>
                    </div>
                </div>
            ))}
        </div>
        {/*<div>socials:</div>*/}
        {/*<div style={{ paddingLeft: '20px' }}>*/}
        {/*    {pqd.parsed?.contact.socials.map((social: any, index: number) => (*/}
        {/*        <div key={index}>{social}</div>*/}
        {/*    ))}*/}
        {/*</div>*/}

        <div>skills:</div>
        <div style={{ paddingLeft: '20px' }}>
            {pqd?.parsed?.skills?.history?.map((skill: any, index: number) => (
                <div key={index}>
                    {skill.titleRaw}
                </div>
            ))}
        </div>

        <div>workExperiences:</div>
        <div>monthsOfExperience {pqd.parsed.experience.monthsOfExperience}</div>
        <div>averageMonthsPerEmployer {pqd.parsed.experience.averageMonthsPerEmployer}</div>
        <div>monthsOfManagementExperience {pqd.parsed.experience.monthsOfManagementExperience}</div>
        <div>currentPosition {pqd.parsed.experience.currentPosition?.position?.title}</div>
        <div>currentSector {pqd.parsed.experience.currentSector}</div>
        <div>mainSector {pqd.parsed.experience.mainSector}</div>
        <div style={{ paddingLeft: '20px' }}>
            {pqd.parsed.experience?.history?.map((work: any, index: number) => (
                <div key={index}>
                    <div>position: {work?.position?.title}</div>
                    <div>company: {work.employer?.company}</div>
                    <div>sector: {work.employer?.company}</div>
                    <div>-------------------------------</div>
                </div>
            ))}
        </div>
    </>
}