import React, {useContext} from "react";
import getContract from "../../../utils/getContract";
import getExperience from "../../../utils/getExperience";
import {capitalizeSentence} from "../../../utils/converters";
import getMissionStatus from "../../../utils/getMissionStatus";
import getMissionStatusColor from "../../../utils/getMissionStatusColor";
import {Link} from "react-router-dom";
import axios from "axios";
import {notification} from "../../../utils/notification";
import {devLog} from "../../../utils/devLog/devLog";
import AppContext, {IAppV2Context} from "../../../components/App/context/AppContext";
import AvatarsBloc from "./AvatarsBloc";

export {RecruiterMissionsMissionV2};

const RecruiterMissionsMissionV2 = (props: {
    item: any;
    isResearchActive: boolean,
    pinFront: any,
    deleteFront: any,
    updateFront: any,
}) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

    const pin = async () => {
        try {
            // Update front
            props.pinFront(props.item.missionId, !props.item.pinned);
            // Update back
            const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/pin`, {
                missionId: props.item.missionId,
                pinned: !props.item.pinned,
            }, {withCredentials: true});
            if (fetched.data.status !== "ok") {
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
            notification(appContextV2.dispatchPanels, "Registre des épingles mis à jour", "success");
        } catch (error) {
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            devLog(error);
        }
    }

    const openWindowMissionOptions = async () => {
        appContextV2.dispatchPanels({
            type: "OPEN_WINDOW",
            value: {
                type: "missionOptions",
                data: {
                    item: props.item,
                    deleteFront: props.deleteFront,
                    updateFront: props.updateFront,
                }
            }
        });
    }

    const openWindowMissionKubo = async () => {
        appContextV2.dispatchPanels({
            type: "OPEN_WINDOW",
            value: {
                type: "missionKubo",
                data: {
                    item: props.item,
                    updateFront: props.updateFront
                }
            }
        });
    }

    const newCandidates = appContextV2.appNotifications.recruiter?.newCandidaciesForMissions?.filter((item: any) => (item.actionType === "newCandidacy" &&
        item.seenByMe === false && item.toMissionId === props.item.missionId)).length;

    const isClientBlackBelt = appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io";

    return (
        <article className={`mission--v2 ${props.isResearchActive && "mission--v2--active-research"}`}>

            <div className={'mission--V2--left hide-on-smartphones'}>
                <div className={"mission--V2__candidacies__number"}>
                    {props.item.numberOfCandidacies}
                </div>
                <div className={"mission--V2__candidacies__title hide-on-smartphones"}>
                    Candidats
                </div>
                {newCandidates !== 0 && <div className={"mission__new-candidate"}>+ {newCandidates}</div>}
            </div>

            <div className={'mission--V2--right'}>
                <div className={"mission--V2--right__line"}>
                    <div>
            <span className={"mission-reference"}>
              ref#{props.item.reference}</span> {isClientBlackBelt && <span className={"mission-reference"}>
            client: {props.item.client}</span>} <span className={"mission-reference"}>
              tas: {props.item.tas}</span>
                        <Link to={`/business/mission/${props.item.missionId}`}>
                            <div className={"mission--V2__title"}>
                                {capitalizeSentence(props.item.positionWanted)}
                            </div>
                        </Link>
                    </div>
                    <div className={"mission--V2__buttons"}>
                        <button className={"btn--hollow"} onClick={() => {
                            appContextV2.dispatchPanels({
                                type: "OPEN_MENU_FROM_BOTTOM", value: {
                                    type: "missionStatus",
                                    data: {
                                        missionId: props.item.missionId,
                                        status: props.item.status,
                                        updateFront: props.updateFront,
                                        deleteFront: props.deleteFront,
                                        isMissionMine: props.item?.isMissionMine,
                                    }
                                }
                            });
                        }}>
                            <div className={`mission--V2__status ${getMissionStatusColor(props.item.status)}`}>
                                <img className={"mission--V2__status__arrow"}
                                     src={"https://static.myshortlist.co/btn-arrow-list.svg"}/>{getMissionStatus(props.item.status)}
                            </div>
                        </button>
                        <button
                            className={`pool--v2__buttons__pinned ${props.item.pinned ? "pool--v2__buttons__pinned--active" : ""}`}
                            onClick={() => pin()}>
                            <img src={"https://static.myshortlist.co/btn-pin.svg"}/>
                        </button>
                    </div>
                </div>
                <div className={"mission--V2__comment"}>
                    {getContract(props.item.contract)}, {getExperience(props.item.yearsOfExperience)}
                </div>
                <div className={"mission--V2--right__line"}>
                    <div>
                        <AvatarsBloc avatars={props.item.shortlistAvatars}/>
                    </div>
                    <div className={"mission--V2__buttons"}>

                        {isClientBlackBelt &&
                            <Link to={`/business/mission/${props.item.missionId}/kubo`}>
                                <button
                                    className={`mission--V2__buttons__parameters paw2 ${props.item.kuboStatus === 'todo' ? 'btn--orange' : ''} ${props.item.kuboStatus === 'done' ? 'btn--green' : ''} ${props.item.kuboStatus === 'canceled' ? 'btn--red' : ''}`}
                                    // onClick={() => openWindowMissionKubo()}
                                >
                                    <img src={"https://static.myshortlist.co/paw.svg"}/>
                                </button>
                            </Link>}
                        {appContextV2.appSession.session.userRole === "admin" &&
                            <button className={"mission--V2__buttons__parameters"}
                                    onClick={() => openWindowMissionOptions()}>
                                <img src={"https://static.myshortlist.co/btn-parameters.svg"}/>
                            </button>}
                        <Link to={`/business/mission/${props.item.missionId}/editor`}>
                            <button
                                className={"mission--V2__buttons__parameters mission--V2__buttons__parameters--small-pic"}>
                                <img src={"https://static.myshortlist.co/btn-pen.svg"}/>
                            </button>
                        </Link>
                        <button className={"mission--V2__buttons__parameters"}
                                onClick={() => {
                                    appContextV2.dispatchPanels({
                                        type: "OPEN_WINDOW", value: {
                                            type: "magicMissionLink",
                                            data: props.item,
                                        }
                                    })
                                }}
                        >
                            <img src={"https://static.myshortlist.co/btn-linkink.svg"}/>
                        </button>
                        <Link to={`/business/mission/${props.item.missionId}`}>
                            <button className={"mission--V2__buttons__main"}>
                                Kanban
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

        </article>)
}