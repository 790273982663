import React from "react";

export {NavBarWrapper};

function NavBarWrapper(props: any) {

  // The scrolled color is deprecated
  const isNavBarWhite: boolean = true;
  
  return (<nav className={`navbar ${isNavBarWhite ? "navbar--scrolled navbar--white" : ""}`}>
    
    {props.children}
  
  </nav>);
}