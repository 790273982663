import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {QuestionPreset} from "../../../../../pages/privateRecruiter/recruiterSharedFormEditor/componentsMissionFormEditorV2/QuestionPreset";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import {devLog} from "../../../../../utils/devLog/devLog";
import WindowWrapper from "../../../../../layouts/WindowLayout";
import Spaces from "../../../Spaces";

export {WindowOpenQuestionsPresets};

const WindowOpenQuestionsPresets = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [presets, setPresets] = useState([]);
  
  const [displayedPresets, setDisplayedPresets] = useState([]);
  
  const [windowStatus, setWindowStatus] = useState("asleep");
  
  const [filter, setFilter] = useState("");
  
  useEffect(() => {
    (async() => {
      try {
        if(filter.length === 0) {
          setDisplayedPresets(presets);
        } else {
          const result = presets.filter((item: any) => (item?.question?.question.toLowerCase().includes(filter.toLowerCase())));
          setDisplayedPresets(result);
        }
        
      } catch(error) {
        throw(error);
      }
    })();
  }, [filter]);
  
  const removePreset = (presetId: string) => {
    setPresets((ps: any) => ps.filter((item: any) => (item._id !== presetId)));
    setDisplayedPresets((ps: any) => ps.filter((item: any) => (item._id !== presetId)));
  }
  
  useEffect(() => {
    (async() => {
      try {
        setWindowStatus("fetching")
        const answer = await axios.get(`${process.env.REACT_APP_API_URL}/v3/data/dictionary/preset/all`, {
          withCredentials: true,
        });
        setWindowStatus("fetched")
        devLog("answer:", answer?.data);
        const questions = (answer?.data?.data ?? []).map((item: any) => ({
          ...item,
          points: presetPoints(item, appContextV2.appPanels.window.windowOptions.data.positionWanted)
        })).sort((a: any, b: any) => {
          if(a.points < b.points) return 1;
          return -1;
        })
        setPresets(questions ?? []);
        setDisplayedPresets(questions ?? []);
      } catch(error) {
        console.log(error);
        setWindowStatus("error");
      }
    })();
  }, []);
  
  const presetPoints = (preset: any, positionWanted: any) => {
    let points = preset?.tick ?? 0;
    for(let job of (preset?.usedForJobs ?? [])) {
      if(job?.toLowerCase() === positionWanted.toLowerCase()) {
        points += 20;
        continue;
      }
      for(let splitPosition of positionWanted.split(" ")) {
        if(splitPosition.length > 2) {
          if(job?.toLowerCase().includes(splitPosition)) {
            points += 3;
          }
        }
      }
    }
    return points;
  }
  
  return (<WindowWrapper size={"big"} windowTitle={""}
                         status={windowStatus as "asleep" | "fetching" | "fetched" | "error"}>
    
    <article className={"editor-v2__section__title"}>Questions sauvegardées</article>
    <Spaces n={1}/>

    <input disabled={false} type={"text"} placeholder={"Recherchez une question par mot clé..."} className={"input-shortlist-filter"}
           value={filter} onChange={(e) => setFilter(e.target.value)}/>
    <Spaces n={1}/>
    
    
    {presets?.length === 0 && <>
      <div className={"text--center text--magnified"}> Vous n'avez pas de questions sauvegardées</div>
      <Spaces n={0}/>
    </>}
    
    {displayedPresets?.map((item: any, key: any) => (<QuestionPreset item={item}
                                                                     key={key}
                                                                     index={key}
                                                                     addQuestion={appContextV2?.appPanels?.window?.windowOptions?.data?.addQuestion}
                                                                     formId={appContextV2?.appPanels?.window?.windowOptions?.data?.formId}
                                                                     positionWanted={appContextV2?.appPanels?.window?.windowOptions?.data?.positionWanted}
                                                                     presetPoints={presetPoints}
                                                                     removePreset={removePreset}
    />))}
    
    <Spaces n={1}/>
  
  </WindowWrapper>);
}



