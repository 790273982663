import {capitalizeSentence} from "./converters";


export default function getContract(contract: string) {
  
  if(!contract) {return ""}
  
  switch(contract.toLowerCase()) {
    case 'cdi':
      return "CDI";
    case 'cdd':
      return "CDD";
    case 'interim':
      return "Intérim";
    case "pro":
      return "Contrat pro";
    case "stage":
      return "Stage";
    case "alternance":
      return "Alternance";
    case "apprentissage":
      return "Apprentissage";
    case "freelance":
      return "Freelance";
    default:
      return contract;
    
  }
}

