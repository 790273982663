import React from "react";
import WindowAppLayout from "../../../../../layouts/WindowAppLayout";
import {IAppContent} from "../../../../App/SingleApp";

export default (props: {
  app: IAppContent;
  dispatcher: Function;
}) => {
  
  const description = <>Ceci est la descirption de l'app</>
  
  const price = <>Cette app coute 2000 €</>
  
  const responseFacebook = (response: any) => {
    console.log(response);
  }
  
  // @ts-ignore
  /*window.FB.getLoginStatus(function(response:any) {
   console.log(response);
   });*/
  return (<WindowAppLayout app={props.app} description={description} price={price}>
  
  
  </WindowAppLayout>);
}
/*onclick appeler la route /business/apps/generate/token qui renvoi token*/