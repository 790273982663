import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import PageLayoutWithHeader from "../../layouts/PageLayoutWithHeader";
import {Route, Switch} from "react-router-dom";
import FourHundredFour from "../errors/FourHundredFour";
import RecruiterParametersParameters from "./componentsRecruiterParameters/RecruiterParametersParameters";
import RecruiterParametersTeam from "./componentsRecruiterParameters/RecruiterParametersTeam";
import RecruiterParametersDictionaries from "./componentsRecruiterParameters/RecruiterParametersDictionaries";
import RecruiterParametersSubscription from "./componentsRecruiterParameters/RecruiterParametersSubscription";

/**
 * --------------------------------------
 * Displays private and business parameters
 * Primary-level page
 * --------------------------------------
 */
export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const title = ((subPage: any) => {
    switch(subPage) {
      case 'RecruiterParametersParameters':
        return "Paramètres";
      case 'RecruiterParametersSubscription':
        return "Apps";
      case 'RecruiterParametersDictionaries':
        return "Dictionnaires de l'entreprise";
      case 'RecruiterParametersTeam':
        return "Équipes";
      default:
        return "";
    }
  })(appContextV2.appNavigation.subPage);
  
  return(<PageLayoutWithHeader small={true} grey={true} page={props.page} title={title} fullWidth={true} status={"ok"}>
    
    <Switch>
      <Route exact path="/business/parameters" render={(componentProps) => <RecruiterParametersParameters {...componentProps} subPage={"RecruiterParametersParameters"} />}/>
      <Route exact path="/business/parameters/team" render={(componentProps) => <RecruiterParametersTeam {...componentProps} subPage={"RecruiterParametersTeam"} />}/>
      <Route exact path="/business/parameters/dictionaries" render={(componentProps) => <RecruiterParametersDictionaries {...componentProps} subPage={"RecruiterParametersDictionaries"} />}/>
      <Route exact path="/business/parameters/parameters" render={(componentProps) => <RecruiterParametersParameters {...componentProps} subPage={"RecruiterParametersParameters"} />}/>
      <Route exact path="/business/parameters/apps" render={(componentProps) => <RecruiterParametersSubscription {...componentProps} subPage={"RecruiterParametersSubscription"} />}/>
  
      {/* 404 ------------------------------------------------- */}
      <Route render={(componentProps) => <FourHundredFour {...componentProps} page="FourHundredFour" fullPage={false} setPage={false}/>}/>
    </Switch>
  
  </PageLayoutWithHeader>);
}