import React, {useContext, useState} from "react";
import axios from "axios";
import AppContext, {IAppV2Context} from "../../../../../components/App/context/AppContext";
import {notification} from "../../../../../utils/notification";
import {devLog} from "../../../../../utils/devLog/devLog";
import {shortenText} from "../../../../../utils/shortenText";
import {format} from "date-fns";


interface IEditableComponentMission {
    missionId: any,
    value: any,
    displayedKey: any,
    keyToUpdate: string,
    editable?: boolean,
    dispatchMission?:any,
}

export default (props: IEditableComponentMission) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

    const [isLoading, setIsLoading] = useState(false);
    const [editorMode, setEditorMode] = useState(false);
    const [value, setValue] = useState(props.value ?? "");

    const isTypeNumber: boolean = props.keyToUpdate === "salaryMin" || props.keyToUpdate === "salaryMax"
        || props.keyToUpdate === "yearsOfExperience";
    const isTypeDate: boolean = props.keyToUpdate === "from";

    const updateMissionInWindow = (keyValuePairs: any) => {
        if (props.keyToUpdate==="city"){
            props.dispatchMission({type: "UPDATE_LOCATION", value: keyValuePairs});
        }
        else {
            props.dispatchMission({type: "UPDATE_INFOS", value: keyValuePairs});
        }

    }

    const updateValue = async(keyToUpdate:any,value:any) => {
        try {

            setIsLoading(true);
            updateMissionInWindow({[props.keyToUpdate]: value});
            setValue("");
            setIsLoading(false);
            setEditorMode(false);

        } catch(error) {
            devLog(error);
            setEditorMode(false)
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    return (<div className={'all-text--small'}>
        <div className={`text-left space-flex--left `}>
            {
                props.editable && <button className={"btn-picto--minimalist2_without-background btn-picto--minimalist2_without-background--small"} onClick={() => {
                    setEditorMode((ps: boolean) => !ps);
                }}>
                    <img src={"https://static.myshortlist.co/btn-pen.svg"}/>
                </button>
            }

            {!isTypeDate && <><span className={"text--bold"}>{props.displayedKey}</span>: {shortenText((props.value), 50)}</>}
            { props.keyToUpdate === "global" ? "%" :""}
            {isTypeDate && <><span className={"text--bold"}>{props.displayedKey}</span>:
                {props?.value ? format(new Date(props?.value), "dd/MM/yyyy") : ""}</>}
        </div>
        {
            editorMode &&
            <div>

                {!isTypeNumber && !isTypeDate && <input className={"input--left profile2-date"} type={"text"} value={value ? value : ""} onChange={(e) => {setValue(e.target?.value)}}/>}
                {isTypeNumber && <input className={"input--left profile2-date"} type={"number"} value={value ? value : ""} onChange={(e) => {setValue(e.target.value)}}/>}
                {isTypeDate && <input className={"input--left profile2-date"} type={"date"} value={value ? value : ""} onChange={(e) => {setValue(e.target.value)}}/>}


                <button disabled={ isLoading} className={"btn--hollow modern-link--grey space-light"} onClick={() => updateValue(props.keyToUpdate, value)}>
                    Valider
                </button>

                <button disabled={isLoading} className={"btn--hollow modern-link--grey space-light"} onClick={() => {
                    setEditorMode(false);
                }}>
                    Annuler
                </button>
            </div>
        }
    </div>);

}
