import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import {Link, withRouter} from 'react-router-dom'
import PageLayout from "../../layouts/PageLayout";
import {useAssistant} from "../../utils/useAssistant/useAssistant";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import {InviteExpired} from "./componentsInvite/InviteExpired";
import {notification} from "../../utils/notification";
import Spaces from "../../components/sharedComponents/Spaces";
import {useForm} from "use-formidable";
import {Loading} from "../../components/sharedComponents/Loading/Loading";

function Invite(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const {token} = props.match.params || "";
  
  const [invite, statusGet, updateDataGet, assistantGet] = useAssistant([]);
  
  const [statusSendingAnswer, setStatusSendingAnswer] = useState("asleep");
  
  const [formTerms, updateTerms, formidable] = useForm({
    areTermsAccepted: false,
    isPolicyAccepted: false,
  });
  
  // Load invite
  useEffect(() => {
    (async() => {
      try {
        assistantGet.fetching();
        const fetched = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/t/get/invite`, {
          withCredentials: true,
          headers: {
            'token': token,
          }
        }));
        if(fetched.data.status === "ok") {
          assistantGet.fetched();
          console.log(fetched.data.data)
          assistantGet.setForm(fetched.data.data);
        } else {
          assistantGet.error();
        }
      } catch (error) {
        devLog(error);
        assistantGet.error();
      }
    })();
  }, []);
  
  const answerInvite = async(status: 'accepted' | 'refused') => {
    try {
      
      notification(appContextV2.dispatchPanels, "Envoi de la réponse", "information");
  
      setStatusSendingAnswer("fetching");
      
      const fetched = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/t/answer/invite/${status}`, {
        token: token,
        acceptedTerms: formTerms.areTermsAccepted,
        acceptedPolicy: formTerms.isPolicyAccepted,
      },{
        withCredentials: true,
      }));
      
      if(fetched.data.status !== "ok") {
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        setStatusSendingAnswer("error");
        return;
      }
  
      setStatusSendingAnswer("fetched");
  
      await appContextV2.fetchSession(`${process.env.REACT_APP_API_URL}/v3/accounts/session/s/whoami`, {
        method: "GET",
        credentials: "include",
      });
      props.history.push('/');
      notification(appContextV2.dispatchPanels, "Succès de l'envoi de la réponse", "success");
  
    } catch (error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  if(invite.status === "tokenExpired") {
    return (<InviteExpired page={props.page} loadingStatus={statusGet}/>);
  }
  
  if(invite.status === "noBusinessForToken") {
    return (<InviteExpired page={props.page} loadingStatus={statusGet}/>);
  }
  
  return (<PageLayout page={props.page} status={statusGet}>
    
    <Spaces n={4}/>
    
    {/*------------------------------------------- Avatar */}
    <div className={"text--center"}>
      {
        !!invite.businessAvatar && <>
          <img alt={"avatar"} src={invite.businessAvatar} className={"invite__avatar"}/>
        </>
      }
    </div>
    
    <Spaces n={1}/>
  
    {/*------------------------------------------- Business Name */}
    <div className={"text--center invite__title"}>
      {invite.businessName}
    </div>
    <Spaces n={1}/>
    <div className={"text--center"}>
      {invite.businessName} vous invite à rejoindre l'équipe !
    </div>
    
    <Spaces n={2}/>
  
    {/*------------------------------------------- Buttons */}
    {
      statusSendingAnswer === "asleep" && <div className={"text--center"}>
        <button className={"btn-main"} disabled={!(formTerms.areTermsAccepted && formTerms.isPolicyAccepted)}
                onClick={() => answerInvite('accepted')}>
          Accepter
        </button>
        <button className={"btn-main"} disabled={!(formTerms.areTermsAccepted && formTerms.isPolicyAccepted)}
                onClick={() => answerInvite('refused')}>
          Refuser
        </button>
      </div>
    }
    {
      statusSendingAnswer === "fetching" && <div className={"text--center"}>
        <Loading fullPage={false}/>
      </div>
    }
    {
      (statusSendingAnswer !== "asleep" && statusSendingAnswer !== "fetching") && <div className={"text--center"}>
        Si la page ne s'actualise pas, vous pouvez la recharger.
      </div>
    }
    
    <Spaces n={1}/>
  
    {/*------------------------------------------- Terms & Policy */}
    <div className={"space-flex--center"}>
      <div>
        <input type={"checkbox"} name={"areTermsAccepted"} value={formTerms.areTermsAccepted} onChange={updateTerms()}/> J'accepte les <Link to={"/terms"}><span className={"modern-link"}>conditions générales</span></Link>
        <Spaces n={0}/>
        <input type={"checkbox"} name={"isPolicyAccepted"} value={formTerms.isPolicyAccepted} onChange={updateTerms()}/> J'ai pris connaissance de la <Link to={"/policy"}><span className={"modern-link"}>charte de confidentialité</span></Link>
      </div>
    </div>
    
    
    <Spaces n={5}/>
  
  </PageLayout>);
}

export default withRouter(Invite);