import React from "react";
import Spaces from "../../../../components/sharedComponents/Spaces";

export default (props: any) => {
  
  return(<div>
  
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      Une erreur est survenue
    </div>
    
    <Spaces n={1}/>
  
    <div className={"text--center"}>
    
      <button className={"btn-assistant-main"} onClick={() => {
        props.wizard.goToStep("assistantStart");
      }}>
        Relancer l'assistant
      </button>
    </div>
  </div>);
}