import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import NavBarLogoContent from "../componentsNavBar/NavBarLogoContent";
import BackToLandingPage from "./shared/BackToLandingPage";
import SubNavLinkGenerator from "./shared/SubNavLinkGenerator";

export function SubMenuRecruiterMission(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const currentSubPage: string = appContextV2.appNavigation.subPage;
  
  return (<>
    <div className={'hide-on-computers'}>
      <NavBarLogoContent/>
    </div>
    
    <ul className={"subnavbar-new hide-on-smartphones animation-straight-fade-in"}>
      
      <BackToLandingPage/>

      {appContextV2.appSession.sessionBusiness.businessName !== "Black-belt.io" &&
      <SubNavLinkGenerator fr={"Éditeur"} en={"Editor"} active={currentSubPage === "RecruiterMissionEditor"}
                           uri={`/business/mission/${appContextV2.appNavigation.missionId}/editor`}
                           alt={"editor"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={true}/>}

      {appContextV2.appSession.sessionBusiness.businessName !== "Black-belt.io" &&
      <SubNavLinkGenerator fr={"Partage"} en={"Links"} active={currentSubPage === "RecruiterMissionLinks"}
                           uri={`/business/mission/${appContextV2.appNavigation.missionId}/links`}
                           alt={"form"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={true}/>}


      {appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" &&
          <SubNavLinkGenerator fr={"Informations"} en={"Informations"} active={currentSubPage === "RecruiterMissionInfos"}
                           uri={`/business/mission/${appContextV2.appNavigation.missionId}/infos`}
                           alt={"statistics"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={true}/>}

      <SubNavLinkGenerator fr={"Kanban"} en={"Kanban"} active={currentSubPage === "RecruiterMissionKanban"}
                           uri={`/business/mission/${appContextV2.appNavigation.missionId}/kanban`}
                           alt={"kanban"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={true}/>

      {appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" &&
      <SubNavLinkGenerator fr={"Proposition"} en={"Proposition"}
                           active={currentSubPage === "RecruiterMissionProposition"}
                           uri={`/business/mission/${appContextV2.appNavigation.missionId}/proposition`}
                           alt={"proposition"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={true}/>
      }

      {appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" &&
          <SubNavLinkGenerator fr={"Kubo"} en={"Kubo"}
                               active={currentSubPage === "RecruiterMissionKubo"}
                               uri={`/business/mission/${appContextV2.appNavigation.missionId}/kubo`}
                               alt={"Kubo"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={true}/>
      }

      {appContextV2.appSession.sessionBusiness.businessName !== "Black-belt.io" &&
          <SubNavLinkGenerator fr={"Statistiques"} en={"Statistiques"}
                               active={currentSubPage === "RecruiterMissionStats"}
                               uri={`/business/mission/${appContextV2.appNavigation.missionId}/statistics`}
                               alt={"statistics"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={true}/>
      }

                           
    </ul>
  </>);
}