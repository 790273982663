
export default (status: string) => {
  switch(status) {
    case 'published':
      return "mission--V2__status--published";
    case 'draft':
      return "mission--V2__status--draft";
    case 'collaboration':
      return "mission--V2__status--collaboration"
    case 'success':
    case 'fail':
    case 'closed':
      return "mission--V2__status--history";
    default:
      return "";
  }
}