import React, {useContext, useState} from "react";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import {devLog} from "../../../../utils/devLog/devLog";
import WindowWrapper from "../../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import WUMF2PositionsWanted from "./componentsWUMF2/WUMF2PositionsWanted";
import WUMF2Experience from "./componentsWUMF2/WUMF2Experience";
import WUMF2Description from "./componentsWUMF2/WUMF2Description";
import WUMF2Contract from "./componentsWUMF2/WUMF2Contract";
import WUMF2Skills from "./componentsWUMF2/WUMF2Skills";
import WUMF2Advantages from "./componentsWUMF2/WUMF2Advantages";
import WUMF2Starting from "./componentsWUMF2/WUMF2Starting";
import WUMF2Salary from "./componentsWUMF2/WUMF2Salary";
import WUMF2Location from "./componentsWUMF2/WUMF2Location";
import WUMF2Studies from "./componentsWUMF2/WUMF2Studies";
import WUMF2CandidateProfile from "./componentsWUMF2/WUMF2CandidateProfile";

export {WindowUpdateMissionField};

const WindowUpdateMissionField = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const fieldToUpdate: string = appContextV2.appPanels.window.windowOptions.data?.fieldToUpdate;
  const modifier: string = appContextV2.appPanels.window.windowOptions.data?.modifier;
  const mission: string = appContextV2.appPanels.window.windowOptions.data?.mission;
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    <div className={"text--center"}>
      {getWindowUpdateTitle(fieldToUpdate)}
    </div>
  
    <Spaces n={0} />
    
    <WindowUpdateMissionFieldContent fieldToUpdate={fieldToUpdate} modifier={modifier} mission={mission} />
    
  </WindowWrapper>);
  
}

function getWindowUpdateTitle(fieldToUpdate: any) {
  
  switch(fieldToUpdate) {
    
    case 'positionWanted':
      return "Modifie le titre du poste";
      
    case 'contract':
      return "Modifie le contrat";
      
    case 'yearsOfExperience':
      return "Modifie l'expérience minimum requise (en années)";
      
    case 'starting':
      return 'Modifie la date de prise de poste';
      
    case 'salary':
      return "Modifie le salaire";
      
    case 'location':
      return 'Modifie le lieu du poste';
      
    case 'description':
      return 'Modifie la description du poste';
      
    case 'skills':
      return 'Modifie les compétences requises';
      
    case 'advantages':
      return 'Modifie les avantages du poste';
      
    default:
      return "Champ non identifié";
  }
}

function WindowUpdateMissionFieldContent(props: any) {
  
  switch(props.fieldToUpdate) {
    
    case 'positionWanted':
      return(<WUMF2PositionsWanted {...props} />); // ok
      
    case 'contract':
      return(<WUMF2Contract {...props} />); // ok
      
    case 'yearsOfExperience':
      return(<WUMF2Experience {...props} />); // ok

    case 'yearsOfStudies':
      return(<WUMF2Studies {...props} />);

    case 'starting':
      return(<WUMF2Starting {...props} />); // ok
      
    case 'salary':
      return(<WUMF2Salary {...props} />); // ok
      
    case 'location':
      return(<WUMF2Location {...props} />); // ok
      
    case 'description':
      return(<WUMF2Description {...props} />); // ok

    case 'candidateProfile':
      return(<WUMF2CandidateProfile {...props} />); // ok
      
    case 'skills':
      return(<WUMF2Skills {...props} />); // ok
      
    case 'advantages':
      return(<WUMF2Advantages {...props} />); // ok
      
    default:
      return(<>Champ non identifié</>);
  }
  
}