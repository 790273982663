import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import SubNavLinkGenerator from "./shared/SubNavLinkGenerator";
import {withRouter} from "react-router-dom";

function SubMenuRecruiterMainContent(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const currentPage: string = appContextV2.appNavigation.page;
  
  return (<>
    <div className={"hide-on-smartphones"}>
      <SubNavLinkGenerator fr={"Prise de poste"} en={"New research"} uri={"/business/new/job"} active={currentPage === "RecruiterNewMission"}
                           alt={"new research"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={true}/>
    </div>
    <SubNavLinkGenerator fr={"Postes ouverts"} en={"Researchs"} uri={"/business/jobs"} active={currentPage === "RecruiterMissions"}
                         alt={"shortlists"} icon={"https://static.myshortlist.co/btn-list.svg"} smaller={false}/>
                         
    <SubNavLinkGenerator fr={"Communautés"} en={"Community"} uri={"/business/communities"} active={currentPage === "RecruiterPools"}
                         alt={"pool"} icon={"https://static.myshortlist.co/btn-team-v2.svg"} smaller={false}/>

    {appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" &&  <SubNavLinkGenerator fr={"Dashboard"} en={"Dashboard"} uri={"/business/dashboard"} active={currentPage === "RecruiterDashboard"}
                         alt={"dashboard"} icon={"https://static.myshortlist.co/btn-team-v2.svg"} smaller={false}/>}

    {appContextV2.appSession.sessionBusiness.businessName !== "Black-belt.io" &&  <SubNavLinkGenerator fr={"Ambassadeurs"} en={"Ambassadors"} uri={"/business/ambassadors"} active={currentPage === "RecruiterAmbassadors"}
                         alt={"pool"} icon={"https://static.myshortlist.co/btn-star.svg"} smaller={true}/>}
    
    {/*--------------------------------- Research bar*/}
    {appContextV2.appNavigation.page !== "RecruiterResearch" && <div className={"hide-on-smartphones space-flex"}>
      <form className={"navbar-research"} onSubmit={(e) => {
        e.preventDefault();
        props.history.push('/business/research');
      }}>
        <input type={"text"} placeholder={"Cherche un candidat"} className={"navbar-research__research"}
               value={appContextV2.globalResearch}
               onChange={(e) => {
                 if(e.target.value.length < 250) {
                   appContextV2.setGlobalResearch(e.target.value);
                 }
               }}/>
        <div className={"navbar-research__buttons"}>
          <button className={"navbar-research__buttons__button"} onClick={() => {
            props.history.push('/business/research');
          }}>
            Go
          </button>
        </div>
      </form>
      {appContextV2.appSession.sessionBusiness.parsing && <button className={"navbar-research__buttons__button space-left--small"} onClick={() => {
        props.history.push('/business/research/advanced');
      }}>
        Go+
      </button>}
    </div>}
  
  </>);
}

export default withRouter(SubMenuRecruiterMainContent);
