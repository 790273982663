import React, {useContext, useEffect, useReducer, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {devLog} from "../../../../utils/devLog/devLog";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import dataReducerMission from "./dependenciesRecruiterMissionInfos/dataReducerMission";
import EditableComponentMission from "./dependenciesRecruiterMissionInfos/EditableComponentMission";
import businessesReducer from "../../../../components/BlackBeltComponents/businessesReducer";
import {useForm} from "use-formidable";
import EditorJobDescV2 from "../../recruiterSharedFormEditor/componentsMissionFormEditorV2/EditorJobDescV2";
import pause from "../../../../utils/pause";
import cleanLinkedinUrl from "../../../../utils/cleanLinkedinUrl";

export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
    const missionId = props.match.params.missionid;
    const [fetchMissionStatus, setFetchMissionStatus]: ["asleep" | "fetching" | "fetched" | "error", Function] = useState("asleep");
    const [businesses, dispatchBusinesses] = useReducer(businessesReducer, []);
    const [newMission, setNewMission] = useState("");
    const [newProfile, setNewProfile] = useState("");
    const [newLanguage, setNewLanguage] = useState("");
    const [newLevel, setNewLevel]:any = useState(0);
    const [mission, dispatchMission]: [any, Function] = useReducer(dataReducerMission, {});
    console.log("======", mission)
    const[isSubmit, setIsSubmit]=useState(false);
    const isButtonStatusGood = mission.positionWanted && mission.contract && mission.yearsOfExperience
        && mission.category && mission.remote && mission.profile?.length>1 && mission.missions?.length>1
        && mission.location.city && mission.description && mission.imageUrl
        && mission.tas && mission.kam;

    const [aiStatus, setAiStatus] = useState<any>(null);
    const [modifiedL33zId, setModifiedL33zId] = useState<any>(false);

    const urlToApply=`https://black-belt.typeform.com/to/tCq2McRK#poste=${mission?.positionWanted?.split(" ").join("+")}&missionid=${missionId}`;
    const urlForJobPage=`https://www.black-belt.io/offres-emploi/${mission?.reference}`;
    const [jobDesc, setJobDesc, formidableJobDesc] = useForm({file: null});
    console.log("jobDesc.file",jobDesc.file, (jobDesc?.file?? [])[0]);

    console.log("mission", mission);
    console.log("businesses", businesses);

    useEffect(() => {
        (async () => {
            try {
                setFetchMissionStatus("fetching");
                const missionId = props.match.params.missionid;
                const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/mission`, {
                    headers: {
                        missionid: missionId
                    },
                    withCredentials: true
                })).data;


                if (status !== "ok") {
                    return setFetchMissionStatus("error");
                }

                dispatchMission({type: "INITIALIZE_DATA", value: data});
                setFetchMissionStatus("fetched");

            } catch (error) {
                devLog(error);
                setFetchMissionStatus("error");
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/clients`, {
                    withCredentials: true
                })).data;
                console.log("data", data);
                if (status === "ok") {
                    dispatchBusinesses({type: "INITIALIZE", value: data});
                }
            } catch (error) {
                console.log(error)
            }
        })()
    }, [])


    useEffect(() => {
        (async () => {
            try {
                const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/ai/parse-candidates/status?missionId=${props.match.params.missionid}`, {
                    withCredentials: true
                })).data;

                if (status === "ok") {
                    console.log("ai status", data);
                    setAiStatus(data);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, []);

    const saveJobDesc = async () => {
        try {
            const dataToSend: any = new FormData();
            dataToSend.append("jobdesc", jobDesc.file[0], jobDesc.file[0].name);
            const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/upload/data/jobdesc`, dataToSend, {
                withCredentials: true, headers: {
                    'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
                    // >>>>>>> newMain
                }
            });
            if (fetched.data.status !== "ok") {
                notification(appContextV2.dispatchPanels, "Le fichier n'a pas été ajouté", "warning");
                return "";
            }
            dispatchMission({
                type: "UPDATE_INFOS",
                value: {documentUrl: fetched.data.data}
            })
            console.log("FETCHED FILE ---", fetched.data.data);
            return (fetched.data.data);
        } catch (error) {
            notification(appContextV2.dispatchPanels, "Le fichier n'a pas été ajouté", "warning");
            return "";
        }
    }

    const updateMission = async(documentUrl: string) => {
        try {
            const {
                status,
                data
            } = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/update/mission`,
                {...mission, missionId: mission._id, documentUrl:documentUrl},
                {withCredentials: true})).data;
            if (status === "ok") {
                notification(appContextV2.dispatchPanels, "Sauvegarde crée avec succès", "success");
            }
        } catch (error) {
            devLog(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    const sendAllCandidatesToAI = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/mission/l33z/unmatched`,
                { missionId: mission._id },
                {withCredentials: true})
        } catch(error) {
            console.error(error)
        }
    }

    const trigrams = ["DHA", "MLE", "TBE", "JFR", "AEL"]

    return (<SubPageLayout status={"ok"} subPage={props.subPage}>

        <div className={"container"}>
            <Spaces n={2}/>

            <h1 className={"page-title page-title--smaller"}> Informations de la mission </h1>
            <Spaces n={0}/>

            <div className={"info__section-color"}>

                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/job.svg"}/>
                    <span>Le poste</span>
                </div>

                <div className={"space-flex--left"}>
                <EditableComponentMission value={mission.positionWanted}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"Titre du poste"}
                                          keyToUpdate={"positionWanted"}
                                          dispatchMission={dispatchMission}

                />
                { isSubmit && !mission.positionWanted && <div className={"warning"}>Merci d'indiquer le titre du poste </div>}
                </div>


                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>Contrat</span>
                    <select className={"profile2-date"} value={mission.contract} onChange={async (e) => {
                        dispatchMission({type: "UPDATE_INFOS", value: {contract: e.target.value}})
                    }}>

                        <option value={"cdi"}>CDI</option>
                        <option value={"cdd"}>CDD</option>
                        <option value={"intérim"}>Intérim</option>
                        <option value={"stage"}>Stage</option>
                        <option value={"alternance"}>Alternance</option>
                        <option value={"freelance"}>Freelance</option>
                    </select>

                </div>
                { isSubmit && !mission.contract && <div className={"warning"}>Merci d'indiquer le contrat </div>}



                <EditableComponentMission value={mission.duration}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"Durée en mois"}
                                          keyToUpdate={"duration"}
                                          dispatchMission={dispatchMission}

                />


                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>Localisation globale</span>
                    <select className={"profile2-date"} value={mission.locationGlobal} onChange={async (e) => {
                        dispatchMission({type: "UPDATE_INFOS", value: {locationGlobal: e.target.value}})
                    }}>
                        <option value={""}>-- Localisation globale --</option>
                        <option value={"Paris et alentours"}>Paris et alentours</option>
                        <option value={"Strasbourg"}>Strasbourg</option>
                        <option value={"Toulouse"}>Toulouse</option>
                        <option value={"Lyon"}>Lyon</option>
                        <option value={"Bordeaux"}>Bordeaux</option>
                        <option value={"Marseille"}>Marseille</option>
                        <option value={"Lille"}>Lille</option>
                        <option value={"Centre-Val de Loire"}>Centre-Val de Loire</option>
                        <option value={"Rouen (76099)"}>Rouen (76099)</option>
                        <option value={"Normandie, Bretagne et Pays de la Loire"}>Normandie, Bretagne et Pays de la Loire</option>
                        <option value={"Auvergne, St Jean de Maurienne, 73300"}>Auvergne, St Jean de Maurienne, 73300</option>
                    </select>


                </div>
                { isSubmit && !mission.locationGlobal && <div className={"warning"}>Merci d'indiquer la localisation globale</div>}


                <EditableComponentMission value={mission.location?.city}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"Ville"}
                                          keyToUpdate={"city"}
                                          dispatchMission={dispatchMission}

                />
                { isSubmit && !mission.location?.city && <div className={"warning"}>Merci d'indiquer la ville</div>}


                <EditableComponentMission value={mission.description}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"Description"}
                                          keyToUpdate={"description"}
                                          dispatchMission={dispatchMission}
                />
                { isSubmit && !mission.description && <div className={"warning"}>Merci d'indiquer la description</div>}

                <br/>

                {appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" && <div style={{
                    backgroundColor: "#666",
                    borderRadius: "20px",
                    color: "white",
                    padding: "20px 30px"
                }}>
                    <h1 style={{
                        color: "white"
                    }}>Block AI (Exclusif Black-Belt)</h1>

                    Ce block vise à faciliter le parsing et l'analyse des candidatures par l'AI.
                    {/*<br/>*/}
                    {/*Avant de pouvoir être analysé par l'AI ou d'être trouvable dans Go+, un profil doit être parsé.*/}
                    {/*<br/>*/}
                    {/*Pour analyser un candidat, l'AI utilise les donnés suivantes:*/}
                    {/*<ul style={{*/}
                    {/*    color: "white",*/}
                    {/*}}>*/}
                    {/*    <li style={{color: "white", marginLeft: "20px", fontSize: "13px"}}>- Le titre du poste</li>*/}
                    {/*    <li style={{color: "white", marginLeft: "20px", fontSize: "13px"}}>- Les annés d'experience minimum requise</li>*/}
                    {/*    <li style={{color: "white", marginLeft: "20px", fontSize: "13px"}}>- La description interne du poste (voir bloc ci dessous)</li>*/}
                    {/*    <li style={{color: "white", marginLeft: "20px", fontSize: "13px"}}>- La liste des missions que le candidat devra effectuer pour le poste</li>*/}
                    {/*    <li style={{color: "white", marginLeft: "20px", fontSize: "13px"}}>- La liste des critères recherchés chez le candidat</li>*/}
                    {/*    <li style={{color: "white", marginLeft: "20px", fontSize: "13px"}}>- Le CV ou le LinkedIn du candidat suivant ce qui est disponible</li>*/}
                    {/*</ul>*/}

                    {/*<br/><br/>*/}

                    {/*Instructions pour l'AI (non visible par le candidat)*/}
                    {/*<br/>*/}
                    {/*<textarea style={{width: "600px", height: "200px", textAlign: "left", border: "1px solid blue"}}*/}
                    {/*          placeholder={"Décrit à l'AI comment juger de la pertinence d'un candidat. Décris lui exactement comme tu décrirais à un assistant. Sois precis."}*/}
                    {/*          value={mission.descriptionAi}*/}
                    {/*          onChange={(e) => {*/}
                    {/*              if (e.target.value?.split(" ").length >= 200) {*/}
                    {/*                  return*/}
                    {/*              }*/}
                    {/*              dispatchMission({type: "UPDATE_INFOS", value: {descriptionAi: e.target.value}})*/}
                    {/*          }}/>*/}
                    {/*<br/>*/}
                    {/*<div style={{*/}
                    {/*    fontSize: "12px",*/}
                    {/*    color: "white"*/}
                    {/*}}>mots: {mission.descriptionAi?.split(" ").length} / 200</div>*/}

                    {/*<br/>*/}

                    {/*<div style={{*/}
                    {/*    color: "white",*/}
                    {/*}}>*/}
                    {/*    Nombre de candidats: {mission.ai?.nCandidates || -1}, dont {mission.ai?.nCandidatesWithPQD || -1} parsés et {mission.ai?.nCandidatesWithAI} analysés par l'AI*/}
                    {/*</div>*/}

                    {/*<br/>*/}

                    {/*<button disabled={mission.ai?.linkedin?.length === 0} style={{*/}
                    {/*    padding: "10px 20px",*/}
                    {/*}} onClick={async() => {*/}
                    {/*    const duplicatedLinkedinArray = [...(mission.ai?.linkedin ?? [])]*/}
                    {/*    const tenCandidatesLinkedin = duplicatedLinkedinArray.splice(0, 10)*/}
                    {/*    for (let obj of tenCandidatesLinkedin) {*/}
                    {/*        window.open(cleanLinkedinUrl(obj.linkedin), "_blank" + Math.random());*/}
                    {/*        await pause(2200)*/}
                    {/*    }*/}
                    {/*    dispatchMission({type: "UPDATE_INFOS", value: {ai: {...(mission.ai || {}), linkedin:duplicatedLinkedinArray}}})*/}
                    {/*}}>*/}
                    {/*    Ouvrir 10 candidats non parsés (reste {mission.ai?.linkedin?.length})*/}
                    {/*</button>*/}

                    {/*<button title={"Analyser uniquement les candidats prets qui n'ont jamais ete analyses par l'AI"} style={{*/}
                    {/*    padding: "10px 20px",*/}
                    {/*}} onClick={async () => {*/}
                    {/*    try {*/}
                    {/*        const answer = await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/ai/parse-candidates`, {*/}
                    {/*            missionId: mission._id,*/}
                    {/*            candidatesIds: mission.ai?.candidatesWithPQDNotParsedAI*/}
                    {/*        }, {*/}
                    {/*            withCredentials: true*/}
                    {/*        });*/}
                    {/*        if (answer.data.status !== "ok") {*/}
                    {/*            alert(answer.data.status)*/}
                    {/*        } else {*/}
                    {/*            const estimate = Math.round(mission.ai?.candidatesWithPQDNotParsedAI?.length * 0.33)*/}
                    {/*            alert(`Reviens dans environ ${estimate} minutes`)*/}
                    {/*        }*/}
                    {/*    } catch(error) {*/}
                    {/*        console.log(error)*/}
                    {/*    }*/}
                    {/*}}>*/}
                    {/*    Analyser les candidats restants ({mission.ai?.candidatesWithPQDNotParsedAI?.length})*/}
                    {/*</button>*/}

                    {/*<button title={"Analyser tous les candidats qui peuvent etre analyses par l'AI, meme si ils ont deja ete analyses"} style={{*/}
                    {/*    padding: "10px 20px",*/}
                    {/*}} onClick={async () => {*/}
                    {/*    try {*/}
                    {/*        const answer = await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/ai/parse-candidates`, {*/}
                    {/*            missionId: mission._id,*/}
                    {/*            candidatesIds: mission.ai?.candidatesWithPQDAll*/}
                    {/*        }, {*/}
                    {/*            withCredentials: true*/}
                    {/*        });*/}
                    {/*        if (answer.data.status !== "ok") {*/}
                    {/*            alert(answer.data.status)*/}
                    {/*        } else {*/}
                    {/*            const estimate = Math.round(mission.ai?.candidatesWithPQDNotParsedAI?.length * 0.33)*/}
                    {/*            alert(`Reviens dans environ ${estimate} minutes`)*/}
                    {/*        }*/}
                    {/*    } catch(error) {*/}
                    {/*        console.log(error)*/}
                    {/*    }*/}
                    {/*}}>*/}
                    {/*    Analyser tous candidats possibles ({mission.ai?.candidatesWithPQDAll?.length})*/}
                    {/*</button>*/}
                    {/*<br/>*/}
                    {/*<div style={{*/}
                    {/*    fontSize: "12px",*/}
                    {/*    color: "white"*/}
                    {/*}}>Il faudra autoriser l'ouverture de popups depuis le site</div>*/}

                    {/*<br/><br/>*/}

                    {/*Information sur le status de l'AI:*/}
                    {/*<br/>*/}
                    {/*Status de l'AI: {aiStatus?.status ? "occupée" : "libre"}*/}
                    {/*<br/>*/}
                    {/*Nombre de missions en attente: {aiStatus?.nTasks ?? 0}*/}
                    {/*<br/>*/}
                    {/*Status de cette mission: {(() => {*/}
                    {/*    switch (aiStatus?.missionStatus) {*/}
                    {/*        case "processing":*/}
                    {/*            return "En cours d'analyse"*/}
                    {/*        case "queued":*/}
                    {/*            return "En attente d'analyse"*/}
                    {/*        default:*/}
                    {/*            return "Aucune tache AI en cours pour cette mission"*/}
                    {/*    }*/}
                    {/*})()}*/}

                    <br/><br/>

                    Entrez la référence de la mission sur L33Z:

                    <input type={"text"} value={mission.l33zId} onChange={(e) => {
                        dispatchMission({type: "UPDATE_INFOS", value: {l33zId: e.target.value}})
                        setModifiedL33zId(true)
                    }} />

                </div>}
                <br/>

                <br/>

                <EditableComponentMission value={mission.imageUrl}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"Url image pour le poste"}
                                          keyToUpdate={"imageUrl"}
                                          dispatchMission={dispatchMission}

                />
                { isSubmit && !mission.imageUrl && <div className={"warning"}>Merci d'indiquer l'url d'une image pour le poste</div>}




                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>Télétravail</span>
                    <select className={"profile2-date"} value={mission.remote} onChange={async (e) => {
                        dispatchMission({type: "UPDATE_INFOS", value: {remote: e.target.value}})
                    }}>
                        <option value={""}>-- Télétravail --</option>
                        <option value={"yes"}>oui</option>
                        <option value={"no"}>non</option>
                        <option value={"might"}>hybride</option>
                    </select>

                </div>
                { isSubmit && !mission.remote && <div className={"warning"}>Merci d'indiquer un champs pour le télétravail</div>}



                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>Catégorie</span>
                    <select className={"profile2-date"} value={mission.category} onChange={async (e) => {
                        dispatchMission({type: "UPDATE_INFOS", value: {category: e.target.value}})
                    }}>
                        <option value={""}>-- Catégorie --</option>
                        <option value={"Commercial"}>Commercial</option>
                        <option value={"Comptabilité & Finance"}>Comptabilité & Finance</option>
                        <option value={"Immobilier"}>Immobilier</option>
                        <option value={"Juridique"}>Juridique</option>
                        <option value={"Marketing, Digital & Communication"}>Marketing, Digital & Communication</option>
                        <option value={"Relation Clients"}>Relation Clients</option>
                        <option value={"Ressources Humaines"}>Ressources Humaines</option>
                        <option value={"Santé"}>Santé</option>
                        <option value={"Gestion Locative"}>Gestion Locative</option>

                    </select>


                </div>
                { isSubmit && !mission.category && <div className={"warning"}> Merci d'indiquer la catégorie </div>}


                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>Temps plein</span>

                    <label className={"switch-V2"}>
                        <input disabled={false} type={"checkbox"} className={"checkbox-on-off"}
                               checked={mission.isJobFullTime} onChange={async () => {
                            dispatchMission({
                                type: 'SWITCH',
                                value: 'isJobFullTime'
                            });
                        }}/>
                        <span className="slider"> </span>
                    </label>

                </div>

                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>Cadre</span>

                    <label className={"switch-V2"}>
                        <input disabled={false} type={"checkbox"} className={"checkbox-on-off"}
                               checked={mission.executive} onChange={async () => {
                            dispatchMission({
                                type: 'SWITCH',
                                value: 'executive'
                            });
                        }}/>
                        <span className="slider"> </span>
                    </label>

                </div>

                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/experience.svg"}/>
                    <span>Années d'expérience</span>
                </div>


                <EditableComponentMission value={mission.yearsOfExperience}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"Nombre d'années d'experience min"}
                                          keyToUpdate={"yearsOfExperience"}
                                          dispatchMission={dispatchMission}

                />
                { isSubmit && !mission.yearsOfExperience && <div className={"warning"}> Merci d'indiquer le nombre d'années d'experience min </div>}


                <EditableComponentMission value={mission.maxYearsOfExperience}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"Nombre d'années d'experience max"}
                                          keyToUpdate={"maxYearsOfExperience"}
                                          dispatchMission={dispatchMission}

                />


                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/salary-mail.svg"}/>
                    <span>Salaire</span>
                </div>

                <EditableComponentMission value={mission.salaryMin}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"Salaire minimum"}
                                          keyToUpdate={"salaryMin"}
                                          dispatchMission={dispatchMission}

                />


                <EditableComponentMission value={mission.salaryMax}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"salaire maximum"}
                                          keyToUpdate={"salaryMax"}
                                          dispatchMission={dispatchMission}

                />

                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/calendar-color.svg"}/>
                    <span>Date de prise de poste </span>
                </div>

                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>ASAP</span>

                    <label className={"switch-V2"}>
                        <input disabled={false} type={"checkbox"} className={"checkbox-on-off"}
                               checked={mission.asSoonAsPossible} onChange={async () => {
                            dispatchMission({
                                type: 'SWITCH',
                                value: 'asSoonAsPossible'
                            });
                        }}/>
                        <span className="slider"> </span>
                    </label>

                </div>

                {!mission.asSoonAsPossible &&  <EditableComponentMission value={mission.from}
                                                                         editable={true}
                                                                         missionId={missionId}
                                                                         displayedKey={"Date de début du poste"}
                                                                         keyToUpdate={"from"}
                                                                         dispatchMission={dispatchMission}

                />}



                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/languages.svg"}/>
                    <span>Langues</span>
                </div>

                <article className={"profile2__newJob__line space-left "}>
                    <ul>
                        {
                            mission.languages?.map((item: any, key: any) => <li>
                                <img className={"img--very--small margin3"} src={"https://static.myshortlist.co/point.svg"}/>
                                {item.value} - Niveau {item.level===0 && <>débutant</>}
                                {item.level===1 && <>confirmé</>}
                                {item.level===2 && <>expert</>}
                            </li>)
                        }
                    </ul>
                    <div>
                        <input className={"profile2-date"} value={newLanguage} onChange={(e) => {
                            setNewLanguage(e.target.value);
                        }}/>

                        <select className={"profile2-date"} value={newLevel} onChange={(e) => {
                            setNewLevel(e.target.value);
                        }}>
                            <option value={-1} >-- Niveau --</option>
                            <option value={0}>Débutant</option>
                            <option value={1}>Confirmé</option>
                            <option value={2}>Expert</option>
                        </select>
                    </div>
                    <div>
                        <button disabled={!newLanguage} className={"profile2-btn-add profile2-btn-add--small"}
                                onClick={(e) => {
                                    dispatchMission({
                                        type: 'ADD_TO_LANGUAGES',
                                        value: {value:newLanguage,
                                                 level:newLevel} ,
                                    });
                                    setNewLanguage("");
                                    setNewLevel("");
                                }}>+ Ajouter la langue
                        </button>
                    </div>
                </article>

                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/business-manager.svg"}/>
                    <span>TAS & KAM</span>
                </div>

                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>TAS</span>
                    <select className={"profile2-date"} value={mission.tas} onChange={async (e) => {
                        dispatchMission({type: "UPDATE_INFOS", value: {tas: e.target.value}})
                    }}>
                        <option value={""} >-- TAS --</option>
                        {trigrams.map((t, i) => <option key={i} value={t.toLowerCase()}>{t}</option>)}
                    </select>
                </div>
                { isSubmit && !mission.tas && <div className={"warning"}> Merci d'indiquer le tas </div>}


                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>KAM</span>
                    <select className={"profile2-date"} value={mission.kam} onChange={async (e) => {
                        dispatchMission({type: "UPDATE_INFOS", value: {kam: e.target.value}})
                    }}>
                        <option value={""} >-- KAM --</option>
                        {trigrams.map((t, i) => <option key={i} value={t.toLowerCase()}>{t}</option>)}
                    </select>
                </div>
                { isSubmit && !mission.kam && <div className={"warning"}> Merci d'indiquer le kam </div>}


                {/*<EditableComponentMission value={mission.tas}*/}
                {/*                          editable={true}*/}
                {/*                          missionId={missionId}*/}
                {/*                          displayedKey={"TAS"}*/}
                {/*                          keyToUpdate={"tas"}*/}
                {/*                          dispatchMission={dispatchMission}*/}

                {/*/>*/}

                {/*<EditableComponentMission value={mission.kam}*/}
                {/*                          editable={true}*/}
                {/*                          missionId={missionId}*/}
                {/*                          displayedKey={"KAM"}*/}
                {/*                          keyToUpdate={"kam"}*/}
                {/*                          dispatchMission={dispatchMission}*/}

                {/*/>*/}


                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/contact.svg"}/>
                    <span>Client</span>
                </div>


                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>Client</span>
                    <select className={"profile2-date"} value={mission.blackBeltBusinessClientId}
                            onChange={async (e) => {
                                dispatchMission({
                                    type: "UPDATE_INFOS",
                                    value: {blackBeltBusinessClientId: e.target.value}
                                })
                                const {
                                    status,
                                    data
                                } = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/mission/client`,
                                    {
                                        missionId: appContextV2.appNavigation.missionId,
                                        businessName: e.target.value
                                    },
                                    {
                                        withCredentials: true
                                    })).data;
                            }}>
                        <option value={""} disabled={true}>-- Client --</option>
                        {businesses.map((item: any, key: number) => (<option value={item?._id}>
                            {item?.name}
                        </option>))}
                    </select>
                </div>

                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>Poste Standard</span>

                    <label className={"switch-V2"}>
                        <input disabled={false} type={"checkbox"} className={"checkbox-on-off"}
                               checked={mission.standard} onChange={async () => {
                            dispatchMission({
                                type: 'SWITCH',
                                value: 'standard'
                            });
                        }}/>
                        <span className="slider"> </span>
                    </label>

                </div>

                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>Priorité</span>
                    <select className={"profile2-date"} value={mission.priority} onChange={async (e) => {
                        dispatchMission({type: "UPDATE_INFOS", value: {priority: e.target.value}})
                    }}>
                        <option value={""}>-- Priorité --</option>
                        <option value={"a"}>A</option>
                        <option value={"b"}>B</option>
                        <option value={"c"}>C</option>
                    </select>
                </div>

                <div className={"space-flex--left"}>
                    <span className={'space-left space-right all-text--small text--bold'}>Statut</span>
                    <select className={"profile2-date"} value={mission.blackBeltStatus} onChange={async (e) => {
                        dispatchMission({type: "UPDATE_INFOS", value: {blackBeltStatus: e.target.value}})
                    }}>
                <option value={""} disabled={true}>-- Statut --</option>
                <option value={"inProgress"}>En cours</option>
                <option value={"recruited"}>Recruté</option>
                <option value={"canceled"}>Annulé</option>
            </select>
                </div>



                <div className={"space-flex--left "}>
                    <span className={'space-left space-right all-text--small text--bold'}>Affichage du salaire sur webflow</span>

                    <label className={"switch-V2"}>
                        <input disabled={false} type={"checkbox"} className={"checkbox-on-off"}
                               checked={mission.showSalary} onChange={async () => {
                            dispatchMission({
                                type: 'SWITCH',
                                value: 'showSalary'
                            });
                        }}/>
                        <span className="slider"> </span>
                    </label>

                </div>

                <EditableComponentMission value={mission.recruitmentProcess}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"Process de recrutement"}
                                          keyToUpdate={"recruitmentProcess"}
                                          dispatchMission={dispatchMission}

                />

                <EditableComponentMission value={mission.fromSectorOrBusiness}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"Secteur ou entreprise souhaité"}
                                          keyToUpdate={"fromSectorOrBusiness"}
                                          dispatchMission={dispatchMission}

                />

                <EditableComponentMission value={mission.contractDescription}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"Grandes lignes du contrat"}
                                          keyToUpdate={"contractDescription"}
                                          dispatchMission={dispatchMission}

                />

                <EditableComponentMission value={mission.bonus}
                                          editable={true}
                                          missionId={missionId}
                                          displayedKey={"Bonus pour le candidat"}
                                          keyToUpdate={"bonus"}
                                          dispatchMission={dispatchMission}

                />

                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/list.svg"}/>
                    <span>Missions & Profil</span>
                </div>

                <article className={"profile2__newJob__line space-left "}>
                    <div className={'profile2__mini-title'}>
                        Missions du poste
                    </div>
                    <ul>
                        {

                            mission.missions?.map((item: any, key: any) => <li>
                                <img className={"img--very--small margin3"} src={"https://static.myshortlist.co/point.svg"}/>
                                {item}

                                <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                    setNewMission(item);
                                    dispatchMission({
                                        type: 'REMOVE_FROM_MISSIONS',
                                        value: item,
                                    });
                                }}>
                                    <img className={"img--small"} src={"https://static.myshortlist.co/edit.svg"}/>
                                </button>

                                <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                    dispatchMission({
                                        type: 'REMOVE_FROM_MISSIONS',
                                        value: item,
                                    });
                                }}>
                                    <img className={"img--small"} src={"https://static.myshortlist.co/delete.svg"}/>
                                </button>

                                <button className={"profile2-btn-add profile2-btn-add--small"} disabled={key===0} onClick={() => {
                                    const missionToCopy = mission.missions[key];
                                    const previousMissionToCopy = mission.missions[key - 1];
                                    dispatchMission({
                                        type: 'UP_MISSION',
                                        value: {missionToCopy:missionToCopy,
                                            previousMissionToCopy:previousMissionToCopy,
                                            key:key},
                                    });
                                }}>
                                    <img className={"img--small"} src={"https://static.myshortlist.co/up.svg"}/>
                                </button>

                                <button className={"profile2-btn-add profile2-btn-add--small"}
                                        disabled={key===mission.missions?.length-1} onClick={() => {
                                    const missionToCopy = mission.missions[key];
                                    const nextMissionToCopy = mission.missions[key + 1];
                                    dispatchMission({
                                        type: 'DOWN_MISSION',
                                        value: {missionToCopy:missionToCopy,
                                            nextMissionToCopy:nextMissionToCopy,
                                            key:key},
                                    });
                                }}>
                                    <img className={"img--small"} src={"https://static.myshortlist.co/down.svg"}/>
                                </button>


                            </li>)
                        }
                    </ul>
                    <div>
                        <input className={"profile2__input--full-mission"} value={newMission} onChange={(e) => {
                            setNewMission(e.target.value);
                        }}/>
                    </div>
                    <div>
                        <button disabled={!newMission} className={"profile2-btn-add profile2-btn-add--small"}
                                onClick={(e) => {
                                    dispatchMission({
                                        type: 'ADD_TO_MISSIONS',
                                        value: newMission,
                                    });
                                    setNewMission("")
                                }}>+ Ajouter la mission
                        </button>
                    </div>
                </article>

                { isSubmit && (mission.missions?.length ?? 0)<2 && <div className={"warning"}> Merci d'ajouter plus de points aux missions</div>}



                <article className={"profile2__newJob__line space-left "}>
                    <div className={'profile2__mini-title'}>
                        Profil recherché
                    </div>
                    <ul>
                        {
                            mission.profile?.map((item: any, key: any) => <li>
                                <img className={"img--very--small margin3"} src={"https://static.myshortlist.co/point.svg"}/>
                                {item}

                                <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                    setNewProfile(item);
                                    dispatchMission({
                                        type: 'REMOVE_FROM_PROFILE',
                                        value: item,
                                    });
                                }}>
                                    <img className={"img--small"} src={"https://static.myshortlist.co/edit.svg"}/>
                                </button>

                                <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                    dispatchMission({
                                        type: 'REMOVE_FROM_PROFILE',
                                        value: item,
                                    });
                                }}>
                                   <img className={"img--small"} src={"https://static.myshortlist.co/delete.svg"}/>
                                </button>

                                <button className={"profile2-btn-add profile2-btn-add--small"} disabled={key===0} onClick={() => {
                                    const profileToCopy = mission.profile[key];
                                    const previousProfileToCopy = mission.profile[key - 1];
                                    dispatchMission({
                                        type: 'UP_PROFILE',
                                        value: {profileToCopy:profileToCopy,
                                            previousProfileToCopy:previousProfileToCopy,
                                            key:key},
                                    });
                                }}>
                                    <img className={"img--small"} src={"https://static.myshortlist.co/up.svg"}/>
                                </button>

                                <button className={"profile2-btn-add profile2-btn-add--small"}  disabled={key===mission.profile?.length-1}
                                        onClick={() => {
                                    const profileToCopy = mission.profile[key];
                                    const nextProfileToCopy = mission.profile[key + 1];
                                    dispatchMission({
                                        type: 'DOWN_PROFILE',
                                        value: {profileToCopy:profileToCopy,
                                            nextProfileToCopy:nextProfileToCopy,
                                            key:key},
                                    });
                                }}>
                                    <img className={"img--small"} src={"https://static.myshortlist.co/down.svg"}/>
                                </button>


                            </li>)
                        }
                    </ul>
                    <div>
                        <input className={"profile2__input--full-mission"} value={newProfile} onChange={(e) => {
                            setNewProfile(e.target.value);
                        }}/>
                    </div>
                    <div>
                        <button disabled={!newProfile} className={"profile2-btn-add profile2-btn-add--small"}
                                onClick={(e) => {
                                    dispatchMission({
                                        type: 'ADD_TO_PROFILE',
                                        value: newProfile,
                                    });
                                    setNewProfile("")
                                }}>+ Ajouter le point
                        </button>
                    </div>
                </article>

                { isSubmit && (mission.profile?.length ?? 0)<2 && <div className={"warning"}> Merci d'ajouter plus de points au profil</div>}

                <div className={"profile2__block__title"}>
                    ID (WebFlow): <input type={"text"}
                                         className={"profile2-date"}
                                         onChange={(e) => {
                                             dispatchMission({type: "UPDATE_INFOS", value: {webflowItemId: e.target.value}})
                                         }}
                                         value={mission.webflowItemId} />
                </div>

                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/link.svg"}/>
                    <span>Liens</span>
                </div>

                <div className={' all-text--small'}>

                    <div className={`text-left space-flex--left `}>
                        <span className={"text--bold space-right"}> Lien à poster sur linkedin / Fiche de poste: </span>
                        <a href={urlForJobPage} target={"_blank"}>
                            <span className={"modern-link"}>{urlForJobPage}</span>
                        </a>
                        <button className={"btn--transparent"} onClick={() => {
                            navigator.clipboard.writeText(urlForJobPage);
                            notification(appContextV2.dispatchPanels, "Lien copié", "success");
                        }}>
                            <img className={"img--small"} src={"https://static.myshortlist.co/copy.svg"}/>
                        </button>
                    </div>
                </div>



                <br/>
                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/body-description.svg"}/>
                    <span>Fiche de poste</span>
                </div>

                <div className={"profile-element__container animation-fade-in"}>
                    <div className={"full-width"}>

                        <EditorJobDescV2 setJobDesc={setJobDesc}
                                         jobDesc={jobDesc}
                                         mission={mission}
                                         dispatchMission={dispatchMission}/>
                    </div>
                </div>

                </div>

                    <div className={"text--center"}>
                        <button className={"btn-secondary"} onClick={async() =>{
                            if(!!jobDesc.file && !!jobDesc.file[0]) {
                                let fileUrl = await saveJobDesc();
                                await updateMission(fileUrl)
                            } else {
                                await updateMission(mission.documentUrl ? mission.documentUrl : '')
                            }

                            if (modifiedL33zId) {
                                sendAllCandidatesToAI()
                            }
                        }}>
                            Sauvegarder
                        </button>

                        {!mission.webflowItemId && <button className={"btn-secondary"} onClick={async () => {
                            try {

                                if(!isButtonStatusGood){
                                    setIsSubmit(true);
                                    return notification(appContextV2.dispatchPanels, "Informations manquantes", "error");

                                }
                                else {

                                    const {
                                        status,
                                        data
                                    } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/publish/new/mission`,
                                        {mission: mission},
                                        {
                                            withCredentials: true
                                        })).data;
                                    console.log("status", status, "data", data);
                                    if (status === "ok") {
                                        dispatchMission({
                                            type: "UPDATE_INFOS",
                                            value: {webflowItemId: data.webflowItemId}
                                        })
                                        return notification(appContextV2.dispatchPanels, "L'annonce a bien été publiée", "success");
                                    }
                                    return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");

                                }
                            } catch {
                                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                            }


                        }}>Publier sur le site </button>
                        }

                        {!!mission.webflowItemId && <button className={"btn-secondary"} onClick={async () => {
                            try {
                                const {
                                    status,
                                    data
                                } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/delete/mission/webflow`,
                                    {missionId: missionId},
                                    {
                                        withCredentials: true
                                    })).data;

                                if (status === "ok") {
                                    dispatchMission({
                                        type: "UPDATE_INFOS",
                                        value: {webflowItemId: ""}
                                    })
                                    return notification(appContextV2.dispatchPanels, "L'annonce a bien été supprimée", "success");
                                }
                                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");

                            } catch {
                                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                            }


                        }}>Supprimer du site </button>
                        }

                        <button className={"btn-secondary"} disabled={!!mission.showOnDashboard} onClick={async()=>{
                            try{
                                // todo: save before
                                const {status,data} = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/publish/mission/on/dashboard`,
                                    {missionId:mission._id},
                                    {
                                        withCredentials: true
                                    })).data;
                                if(status==="ok"){
                                    dispatchMission({
                                        type: "UPDATE_INFOS",
                                        value: {showOnDashboard:true}
                                    })
                                    return notification(appContextV2.dispatchPanels, "L'annonce a bien été publiée", "success");
                                }
                                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");

                            }
                            catch{
                                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                            }



                        }}>Publier sur le dashboard </button>
                    </div>



                </div>


    </SubPageLayout>
);
}

