import React from "react";
import DisplayTutorialSteps from "../../DisplayTutorialSteps";

export default (props: any) => {

    return (<div className={"window-tutorial__content"}>

        <div className={"window-tutorial__main-content animation-fade-in"}>
            <h1 className={"window-tutorial__title1"}>Prenez la main !</h1>

            {/*<img className={"window-tutorial__picture"}/>*/}

            <p className={"window-tutorial__container"}><br/>
            Beaucoup d'autres fonctionalités existent et n'ont pas été abordées dans cette courte présentation.<br/><br/>
            Savez-vous que vous pouvez faire de la cooptation via les <b className={"window-tutorial__b"}>ambassadeurs</b> en leur attribuant des liens uniques ?<br/><br/>
            Savez-vous que vous pouvez activer des <b className={"window-tutorial__b"}>signatures de mail</b> ?<br/><br/>
            Savez-vous que vous pouvez nourrir et <b className={"window-tutorial__b"}>gérer le feed de votre page entreprise</b> ?<br/><br/>
            Savez-vous que nous avons une <b className={"window-tutorial__b"}>extension LinkedIn</b> pour directement intégrer des candidats à une communauté ?<br/><br/>
                Avez-vous des questions, des demandes d'amélioration ? N'hésitez pas à contacter le <b className={"window-tutorial__b"}>support</b>, il n'est pour l'instant pas en temps réel mais nous vous répondrons !<br/><br/>
            </p>
        </div>

        <div className={"window-tutorial__secondary-content"}>
            <DisplayTutorialSteps {...props}/>

            <div>
                <button className={"btn-secondary"} onClick={async (e) => props.hideTutorialForEver()}>
                    Terminer l'assistant
                </button>

            </div>
        </div>

    </div>);
}