import Spaces from "../../../../../../components/sharedComponents/Spaces";
import ButtonInformation from "../../../../../../components/sharedComponents/Buttons/ButtonInformation";
import LoadingStyle2 from "../../../../../../components/sharedComponents/Loading/LoadingStyle2";
import SubPageLayout from "../../../../../../layouts/SubPageLayout";
import React, {useContext} from "react";
import axios from "axios";
import {notification} from "../../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../../../components/App/context/AppContext";

export default (props: {
  answer: any,
  updateAnswer: any,
  sending: boolean,
  formidableAnswer: any,
  subPage: any,
  setSending: any,
  pageInfos: any,
  missionId: any,
  wizard2: any,
  setCandidacy: any,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const sendCandidacyWithLinkedin = async() => {
    try {
      
      props.setSending(true);
      
      // Save current application
      const dataToSend: any = {
        missionId: props.missionId,
        ...props.answer,
        ...props.pageInfos
      }
      
      // Post candidacy
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/jobs/mat/ca/candidacy/temp`, dataToSend, {
        withCredentials: true,
        timeout: 10000,
      });
      
      if(fetched.data.status !== "ok") {
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      
      const prefix = appContextV2.appSession.app.mode === "demo" ? "demo." : "";
      
      // go to LinkedIn page
      const type = "code";
      const clientId = "78l73hrsk4u1uv";
      const redirectUri = `https%3A%2F%2F${prefix}myshortlist.co%2FoAuth%2Fv2%2Flinkedin%2Fapply`;
      const state = fetched.data.data ?? "";
      const scope = "r_liteprofile%20r_emailaddress";
      
      window.location.assign(`https://www.linkedin.com/oauth/v2/authorization?response_type=${type}&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`);
      
    } catch(error) {
      props.setSending(false);
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const isPhoneOk: boolean = !!props.answer.phone;
  const isLinkedinOk: boolean = props.answer.linkedin?.includes("/in/");
  const isButtonLinkedinActive = isPhoneOk && isLinkedinOk;
  
  return (<>
    <SubPageLayout status={"ok"} subPage={props.subPage}>
      
      <Spaces n={2}/>
      
      <div className={"assistant--v2-title"}>
        Vous avez presque terminé !
      </div>
      
      <Spaces n={0}/>
      
      <div className={"text--grey text--center"}>
        Entrer vos informations de contact est nécessaire pour être contacté par le recruteur
      </div>
      
      <Spaces n={2}/>
      
      <section className={"container text--center"}>
        
        <article className={"white-container white-container--grey"}>
          
          <label className={"text--minified2x"}>Téléphone *<br/>
            <input type={"text"} className={"assistant--v2-input assistant--v2-input--full-width"} name={"phone"}
                   placeholder={"Téléphone*"}
                   value={props.answer.phone}
                   onChange={props.updateAnswer({maximumLength: 20, toKeepNumbers: true})}/>
          </label>
          
          <Spaces n={0}/>
          
          <label className={"text--minified2x"}>Lien du profil LinkedIn *
            <br/>
            <input type={"text"} className={"assistant--v2-input assistant--v2-input--full-width"} name={"linkedin"}
                   placeholder={"Lien LinkedIn"}
                   value={props.answer.linkedin} onChange={props.updateAnswer({maximumLength: 200})}/>
          </label>
          <div className={"space-flex"}>
            <ButtonInformation
              type={"findLinkedinProfile"}
              title={"Sur votre page LinkedIn, cliquez sur 'Vous' sous votre photo, puis sur 'Voir le profil'.\n Copiez l'URL de cette page pour la partager avec le recruteur."}
              cb={() => {
                appContextV2.dispatchPanels({
                  type: "OPEN_WINDOW", value: {
                    type: "findLinkedinProfile",
                    data: {}
                  }
                });
              }}/>
          </div>
          
          <Spaces n={0}/>
          
          {/*<label className={"text--minified2x"}>Commentaire pour le recruteur<br/>*/}
          {/*  <input type={"text"} className={"assistant--v2-input assistant--v2-input--full-width"} name={"comment"}*/}
          {/*         placeholder={"Commentaire libre"}*/}
          {/*         value={props.answer.comment} onChange={props.updateAnswer({maximumLength: 200})}/>*/}
          {/*</label>*/}
          <Spaces n={0}/>
        
        </article>
        
        <Spaces n={0}/>
        
        <div className={"text--center text--minified2x text--red"}>
          {(!isPhoneOk) && <>Téléphone non valide<br/></>}
          {(!isLinkedinOk) && <>Lien du profil LikedIn non valide<br/></>}
        </div>
        
        <Spaces n={0}/>
        
        <div className={"text--center"}>
          <button className={"btn-main"} onClick={() => props.wizard2.goToStep("cv")}>
            Je n'ai pas LinkedIn
          </button>
          <button className={"btn-main btn-main--linkedin"} disabled={!isButtonLinkedinActive}
                  onClick={() => sendCandidacyWithLinkedin()}>
            Postuler avec LinkedIn
          </button>
        </div>
        
        {props.sending && <>
          <br/>
          <LoadingStyle2/>
        </>}
        
        <Spaces n={0}/>
      
      </section>
    
    </SubPageLayout>
  </>);
}