import React from "react";

export default (props: {
  active: boolean;
  size: 'small' | 'big';
}) => {
  return (<div className={"section-apps__app__slider"}>
      <label className="switch">
        <input type="checkbox" checked={props.active}/>
        <span className="slider round"></span>
      </label>
    </div>);
}