
export default (props: any) => (formId: any, index: number) => {
  
  if(formId === "form") {
    let newForm1 = [...props.form.form];
    newForm1[index]["extended"] = !newForm1[index]["extended"];
    props.setForm()({target: {type: "custom", name: "form", value: newForm1}});
  }
  if(formId === "form2") {
    let newForm2 = [...props.form.form2];
    newForm2[index]["extended"] = !newForm2[index]["extended"];
    props.setForm()({target: {type: "custom", name: "form2", value: newForm2}});
  }

}