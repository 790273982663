import {IColumnData} from "../../WindowColumnOptionsV2";
import Spaces from "../../../../Spaces";
import MailComponent from "../../../../MailComponent";
import React from "react";

export function WCOIsCandidateInterestedOptions(props: {
    type: "kanban" | "preset",
    missionId: string,
    numberOfCandidates: number,
    columnData: IColumnData,
    updateColumnData: Function,
    saveColumnData: Function,
    switchColumnAction: Function,
    isWindowLoading: boolean,
    deleteColumn: Function
}) {
    return (<>
        <div>
            <Spaces n={0}/>
            <MailComponent mailContent={props.columnData.mailInterestedContent ?? ""}
                           mailSubject={props.columnData.mailInterestedSubject?? ""}
                           updateMailContent={ (value:any)=>{props.updateColumnData({mailInterestedContent: value});}}
                           updateMailSubject={ (value:any)=>{props.updateColumnData({mailInterestedSubject: value});}}/>

            <div className={"text--center"}>
                <button className={"btn-secondary"}
                        onClick={() => {
                            props.saveColumnData({
                                mailInterestedContent: props.columnData.mailInterestedContent,
                                mailInterestedSubject: props.columnData.mailInterestedSubject,
                            });
                        }}>
                    Enregistrer
                </button>
            </div>
        </div>
    </>);
}