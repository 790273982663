import axios from "axios";
import {notification} from "../../../utils/notification";
import {devLog} from "../../../utils/devLog/devLog";

export {fetchSession};

const fetchSession = (args: {dispatchPanels: Function, setSessionFetchedStatus: Function, setFetchedSession: Function}) => async() => {
  try {
    
    args.setSessionFetchedStatus("fetching");
    
    const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/session/s/whoami`, {withCredentials: true});
    
    if(fetched.data.status !== "ok") {
      
      args.setSessionFetchedStatus("error");
      
      await args.setFetchedSession(fetched.data);
      
      notification(args.dispatchPanels, "Une erreur est survenue", "error");
      return;
    }
    
    devLog("Fetched session", fetched.data);
    
    await args.setFetchedSession(fetched.data);
    
    args.setSessionFetchedStatus("fetched");
    
    return;
    
  } catch(error) {
    
    devLog(error);
    notification(args.dispatchPanels, "Une erreur est survenue", "error");
    
    args.setSessionFetchedStatus("error");
    
    return;
  }
}