export default (previousState: any, action: {
    type: string;
    value: any;
}) => {

    switch(action.type) {

        case 'INITIALIZE':
            return [...action.value];

        case 'ADD':
            return [...previousState, action.value];

        case 'UPDATE':
            return previousState.map((item: any) => {
                if(item._id !== action.value.missionId) {
                    return {...item};
                }
                return {
                    ...item,
                    ...action.value.keyValues
                }
            });

        case 'REMOVE_FROM_TO_DO':
            return previousState.map((item: any) => {
                if(item._id !== action.value.missionId) {
                    return {...item};
                }
                return {
                ...item,
                toDo: item.toDo.filter((toDo: any) => toDo!== action.value.toDo)
               }
            });

        case'ADD_TO_TO_DO':
            return previousState.map((item: any) => {
                if(item._id !== action.value.missionId) {
                    return {...item};
                }
            return {
                ...item,
                toDo: [...item.toDo ?? [],
                    action.value.toDo]
            }
            });

        case 'DELETE':
            return previousState.filter((item: any) => item._id !== action.value);
    }

}