import Spaces from "./Spaces";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {notification} from "../../utils/notification";
import AppContext, {IAppV2Context} from "../App/context/AppContext";
import {devLog} from "../../utils/devLog/devLog";
import {RecruiterMissionsMissionV2} from "../../pages/privateRecruiter/componentsRecruiterMissions/RecruiterMissionsMissionV2";


export default (props: {
    mailSubject: string,
    updateMailSubject: Function,
    mailContent: string,
    updateMailContent: Function,
}) => {
    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const [save, setSave] = useState(false);
    const [name, setName] = useState("");
    const [allPreset, setAllPreset] = useState<any[]>([]);
    const [mailSubject, setMailSubject] = useState(props.mailSubject);
    const [mailContent, setMailContent] = useState(props.mailContent);


    useEffect(() => {
        (async function() {
            try {
                const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/data/dictionary/business/all/mail/preset`, {withCredentials: true});
                setAllPreset(fetched.data?.data);

            } catch (error) {
                console.log(error);
            }
        })()
    }, []);


    console.log("allPreset",allPreset);

    return (<>
        <Spaces n={0}/>
        <input className={"input--v2 input--v2--full-width"}
               value={mailSubject}
               placeholder={"Sujet"}
               name={"mailSubject"}
               onChange={(e) => {
                   if (e.target.value.length < 300) {
                       setMailSubject(e.target.value);
                       props.updateMailSubject(e.target.value);
                   }
               }}/>
        <br/><br/>

        <textarea className={"textarea--v2 textarea--v2--full-width"} value={mailContent}
                  placeholder={"Contenu du mail (celui ci commencera automatiquement par \"Bonjour \"nom_du_candidat\"\" et se terminera par le prénom de l'opérateur qui a effectué l'action)"}
                  name={"mailContent"}
                  style={{whiteSpace: "pre-wrap"}}
                  onChange={(e) => {
                      if (e.target.value.length < 1500) {
                          setMailContent(e.target.value);
                          props.updateMailContent(e.target.value);
                          //props.updateMailContent(e.target.value.replace(/\n\r?/g, '<br />'));
                      }
                  }}>
            </textarea>

        <div className={"text--center text--grey"}>
            {(props.mailContent || "").length} caractères sur 1500
        </div>

        <div className={"mail-component__btns"}>
            <div className={" mail-component__import-btn"}>

                <select className={"mail-component__select"} defaultValue={"none"} onChange={(e) => {
                    for (let preset of allPreset as any){
                        if (preset._id===e.target.value){
                            setMailSubject(preset.subject);
                            setMailContent(preset.content);
                        }
                    }

                }}>
                    <option value={"none"} disabled={true} >Importer un modele</option>
                    {allPreset.map((item: any, key: number) => (
                    <option value={item._id}>{item.name}</option>))}
                </select>

            </div>
            <div className={"q-p__header__right mail-component__save-btn"}>
            { !save && <button className={"mission--V2__buttons__parameters "} title={"Sauvegarder le mail en tant que modele"} onClick={() => {
                setSave(true)
            }}>
                <img src={"https://static.myshortlist.co/btn-save.svg"}/>
            </button>}
            {save && <> <input type={"text"} className={"mail-component__input"}
                               value={name}
                               onChange={(e) => {
                                   if (e.target.value.length < 50) {
                                       setName(e.target.value);
                                   }
                               }}
                               placeholder={"Donner un nom au modele"}/>
                <button disabled={!name} className={"mission--V2__buttons__parameters"} onClick={async () => {
                    try {
                        setSave(false);
                        const fetched = await axios.post(`${process.env.REACT_APP_API_URL}/v3/data/dictionary/business/new/mail/preset`, {
                          name:name,
                            subject:props.mailSubject,
                            content:props.mailContent,
                        }, {withCredentials: true});
                        
                        if (fetched.data?.status !== "ok") {
                            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                        }

                        notification(appContextV2.dispatchPanels, "Succès", "success");
                        setAllPreset((ps: any) => ([
                            ...ps,
                            fetched.data.data,
                    ]));

                    } catch (error) {
                        console.log(error);
                        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                    }
                }}>
                    <img src={"https://static.myshortlist.co/btn-check.svg"}/>
                </button>
                <button className={"mission--V2__buttons__parameters"} onClick={ () => {
                    setSave(false);
                }}>
                    <img src={"https://static.myshortlist.co/btn-cross.svg"}/>
                </button>
            </>}
            </div>
        </div>
        <Spaces n={0}/> </>);
}

