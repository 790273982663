import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../components/sharedComponents/Buttons/ButtonBack";
import {notification} from "../../../../utils/notification";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  useEffect(() => {
    const elapsedTimeForAssistant: number = Math.floor(new Date().getTime() / 1000) - props.timeStamps.startTime;
    props.setTimeStamps((ps: any) => ({
      ...ps,
      endTimeAssistantInSeconds: elapsedTimeForAssistant,
    }))
    props.wizard.goToStep("editor");
  }, []);
  
  return (<section>
  </section>);
}