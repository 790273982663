import React, {useContext, useState} from "react";
import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import WindowWrapper from "../../../../layouts/WindowLayout";
import {notification} from "../../../../utils/notification";
import axios from "axios";
import {devLog} from "../../../../utils/devLog/devLog";
import Spaces from "../../Spaces";
import LoadingStyle2 from "../../Loading/LoadingStyle2";

export {WindowAskForCandidatesAvailability};

const WindowAskForCandidatesAvailability = () => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [fetchedStatus, setFetchedStatus] = useState("asleep");
  const [message, setEmail] = useState("");
  
  const poolId: string [] = appContextV2.appPanels.window.windowOptions.data.poolId;
  const candidatesIds: string [] = appContextV2.appPanels.window.windowOptions.data.candidatesIds;
  const isButtonActive: boolean = fetchedStatus === "asleep";
  
  const sendMassMailAvailability = async() => {
    try {
      
      setFetchedStatus("fetching");
      notification(appContextV2.dispatchPanels, "Envoie de la demande", "information");
      
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cma/mass/mail/availability`, {
        poolId: poolId,
        candidatesIds: candidatesIds,
      }, {withCredentials: true});
      
      if(fetched.data.status !== "ok") {
        setFetchedStatus("error");
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        return;
      }
      
      setFetchedStatus("fetched");
      
      notification(appContextV2.dispatchPanels, "Email envoyé avec succès", "success");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      
    } catch (error) {
      
      devLog(error);
      setFetchedStatus("error");
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      
    }
  }
  
  return (<WindowWrapper size={"small"} status={"ok"} windowTitle={""}>
    <div className={"text--center"}>
      Tu es sur le point d'envoyer un email à {candidatesIds.length} personne{candidatesIds.length === 1 ? "" : "s"}.
    </div>
    <br/><br/>
    <div className={"text--grey text--center"}>
      Les candidats vont recevoir un email dans lequel ils pourront sélectionner leurs disponibilités.
    </div>
    <Spaces n={1}/>
    <div className={"text--center"}>
      <button disabled={!isButtonActive} className={"btn-secondary"} onClick={() => {sendMassMailAvailability()}}>
        Envoyer
      </button>
    </div>
    {
      fetchedStatus === "fetching" && <div className={"space-flex--center"}>
        <LoadingStyle2/>
      </div>
    }
  </WindowWrapper>);
}

// export default class extends React.Component<any, any> {
//
//   static contextType = AppContext;
//
//   constructor(props: any) {
//     super(props);
//
//     this.state = {
//       windowStatus: "ok",
//       buttonStatus: 'ready',
//       poolId: "",
//       selected: [],
//     };
//   }
//
//   componentDidMount(): void {
//     const windowOptions: any = this.context.appState.windowOptions?.data || {};
//     this.setState({
//       poolId: windowOptions.poolId,
//       selected: windowOptions.selected,
//     });
//   }
//
//   processSending = async() => {
//     try {
//       const windowOptions: any = this.context.appState.windowOptions?.data || {};
//
//       this.setState({
//         windowStatus: "sending",
//       });
//
//       const fetched: any = await abstractPostFetch('/pool/mass/mail', {
//         poolId: this.state.poolId,
//         selected: this.state.selected,
//         subject: this.state.subject,
//         object: this.state.object,
//       });
//
//       if(fetched.status === "ok") {
//         windowOptions.getCandidates();
//         this.setState({
//           windowStatus: "success",
//           answer: fetched.data,
//         });
//       }
//
//     } catch (error) {
//       console.log(error);
//     }
//   }
//
//   render() {
//
//     if(this.state.windowStatus === "pending") {
//       return (<WindowWrapper windowTitle={""} size={"small"}>
//         <Spaces n={0}/>
//         <p className={"text--magnified15x text--center"}>Chargement ...</p>
//         <Spaces n={0}/>
//       </WindowWrapper>);
//     }
//
//     if(this.state.windowStatus === "sending") {
//       return (<WindowWrapper windowTitle={""} size={"small"}>
//         <Spaces n={0}/>
//         <p className={"text--magnified15x text--center"}>Envoi des mails ... (Cette étape peut prendre quelques minutes)</p>
//         <Spaces n={0}/>
//       </WindowWrapper>);
//     }
//
//     if(this.state.windowStatus === "success") {
//       return (<WindowWrapper windowTitle={""} size={"small"}>
//         <Spaces n={0}/>
//         <p className={"text--magnified15x text--center"}>
//           Succès
//           <br/><br/>
//         </p>
//         <Spaces n={0}/>
//       </WindowWrapper>);
//     }
//
//     const isButtonStatusOk: boolean = (this.state.buttonStatus === "ready") && !!this.state.subject && !!this.state.object;
//
//     return (<WindowWrapper windowTitle={""} size={"big"}>
//
//       <Spaces n={0}/>
//
//       <p className={"text--magnified15x text--center"}>Envoi d'un mail collectif</p>
//       <p className={"text--center text--grey"}>
//         Les email commencent tous par "Bonjour (prénom)" et se terminent par la signature de l'opérateur qui envoie le mail.
//       </p>
//       <Spaces n={0}/>
//
//       <p className={"text--center"}>
//         <input className={"full-width"} type={"text"} value={this.state.subject} onChange={(e) => {
//           this.setState({
//             subject: e.target.value,
//           });
//         }}/>
//         <br/>
//         <textarea className={"textarea--wide--2"} value={this.state.object} onChange={(e) => {
//           this.setState({
//             object: e.target.value,
//           })
//         }}>
//
//             </textarea>
//       </p>
//
//       <Spaces n={0}/>
//
//       <p className={"text--magnified15x text--center"}>
//         <button disabled={!isButtonStatusOk} className={"btn-main"} onClick={() => {this.processSending()}}>
//           Envoyer
//         </button>
//       </p>
//
//       <Spaces n={0}/>
//
//     </WindowWrapper>);
//
//   }
// }
