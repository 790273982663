import React, {useContext, useState} from "react";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import {devLog} from "../../../../utils/devLog/devLog";
import WindowWrapper from "../../../../layouts/WindowLayout";
import Spaces from "../../Spaces";

export {WindowDeleteMission};

const WindowDeleteMission = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [validation, setValidation]: [any, any] = useState(false);
  
  const [pending, setPending] = useState(false);
  
  const missionId: string = appContextV2.appPanels.window.windowOptions.data?.missionId;
  
  const deleteMission = () => {
    (async() => {
      try {
  
        setPending(true);
        notification(appContextV2.dispatchPanels, "Suppression du poste en cours", "information");
        
        const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/delete`, {
          missionId: missionId,
        }, {withCredentials: true});
  
        setPending(false);
        
        if(fetched.data.status !== "ok") {
          appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        
        appContextV2.appPanels.window?.windowOptions?.data?.deleteFront(missionId);
        notification(appContextV2.dispatchPanels, "Succès de la suppression", "success");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        
      } catch (error) {
        setPending(false);
        devLog(error);
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      }
    })();
  }
  
  if(pending) {
    return (<WindowWrapper windowTitle={""}>
    </WindowWrapper>);
  }
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    <div className={"text--center"}>
      Attention, supprimer un poste est une action définitive. Veille à bien déplacer les candidats dans une communauté pour les conserver.
    </div>
    
    <Spaces n={1}/>
    
    <div>
      <input type={"checkbox"} value={validation} onChange={() => setValidation((ps: boolean) => !ps)} /> Je suis sûr·e de vouloir supprimer ce poste
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button className={"btn-main"} disabled={!validation} onClick={() => deleteMission()}>
        Supprimer
      </button>
    </div>
  </WindowWrapper>);
  
}