import React, {useContext, useState} from "react";
import AppContext, {IAppV2Context} from "../App/context/AppContext";
import WindowWrapper from "../../layouts/WindowLayout";
import axios from "axios";
import {notification} from "../../utils/notification";
import Spaces from "../sharedComponents/Spaces";

export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const business = appContextV2.appPanels.window.windowOptions.data.business || {};
    const businessManagers=business.managers;

    const [managers,setManagers]=useState(businessManagers);
    const [managerName,setManagerName]=useState('');
    const [managerMail,setManagerMail]=useState('');


    return(<WindowWrapper size={"big"} status={"ok"} windowTitle={""}>

        {managers?.map((item:any)=>(<div className={"business--blackbelt__manager"}>
            <div className={"business--blackbelt__manager-fullName"}>{item.fullName}</div>
            <div className={"business--blackbelt__manager-email"}>{item.email}</div>
            <div> <button className={"mission--V2__buttons__parameters"} onClick={
                async()=>{
                    try {
                        const {status, data} = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/delete/manager`,
                            {
                                businessId:business._id,
                                managerId:item._id,
                            },{
                                withCredentials: true
                            })).data;
                        if(status==="ok"){
                            setManagers((previousState: any) => (
                                previousState.filter((manager:any)=>(manager._id !== item._id))));
                        }
                    } catch(error) {
                        console.log(error);
                    }
                }}>
            <img src={"https://static.myshortlist.co/btn-bin.svg"}/>
            </button></div>
        </div>))}

        <Spaces n={1}/>
        <div className={""}>
        <h1 className={"business--blackbelt__add-manager--title"}>Ajouter un manager</h1>
        <div className={"business--blackbelt__add-manager"}>

        <input type={"text"} placeholder={"Nom Complet"} className={""}
               value={managerName} onChange={(e)=>setManagerName(e.target.value)}/>
        <input type={"text"} placeholder={"Email"} className={""}
               value={managerMail} onChange={(e)=>setManagerMail(e.target.value)}/>


        <button className={""} onClick={async()=>{
            try {
                const {status, data} = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/add/manager`,
                    {
                        businessId:business._id,
                        name:managerName.trim(),
                        mail:managerMail.trim(),
                    },{
                        withCredentials: true
                    })).data;
                if(status==="ok"){
                    setManagers((previousState: any) => ([
                        ...previousState,
                          {fullName:managerName,
                              email:managerMail}
                    ]));
                    return notification(appContextV2.dispatchPanels, "Manager ajoute", "success");
                }
            } catch(error) {
                console.log(error);
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        }}> Save </button>
        </div>
        </div>

    </WindowWrapper>);
}