import moveFromIndexToIndex from "../../../../../../utils/moveFromIndexToIndex";

export default (args: {dispatchDataV2: any}) => (columnId: string, direction: number) => {
  
  return args.dispatchDataV2({
    type: "MOVE_COLUMN",
    value: {
      columnId,
      direction
    }
  });
}