import React, {useContext, useEffect} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import {useForm} from "use-formidable";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";
import {FileDrop} from "../../../Buttons/FileDrop";
import Spaces from "../../../Spaces";

export {WAMTBPImagePortrait};

const WAMTBPImagePortrait = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [form, updateForm, formidable] = useForm({});
  
  useEffect(() => {
    (async() => {
      
      try {
        
        if(!!form.fileInput) {
          
          if(form.fileInput.length > 1) {
            return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");
          }
          
          if(!formidable.isFileMime(form.fileInput[0], ["jpeg", "png", "gif"])) {
            return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge", "error");
          }
          
          if(!formidable.isFileSmallerThan(form.fileInput[0], 820000)) {
            return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd (maximum 800ko)", "error");
          }
          
          notification(appContextV2.dispatchPanels, "Traitement et analyse du fichier", "information");
          
          props.windowContext.wizard.goToStep("pending");
          
          const dataToSend: any = new FormData();
          
          dataToSend.append("media", form.fileInput[0], form.fileInput[0].name);
          
          const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/upload/data/business/page/media`, dataToSend, {
            withCredentials: true, headers: {
              'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
              'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
            }
          });
          
          if(fetched.data.status !== 'ok') {
            throw new Error('Une erreur est survenue');
          }
          
          notification(appContextV2.dispatchPanels, "Succès de l'ajout d'un nouveau media", "success")
          
          const dataToSend2: any = {
            key: props.windowContext.form.key,
            type: props.windowContext.form.type,
            fileUrl: fetched.data.data,
          };
          
          const fetched2 = await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/bm/business/media/v2/add`, dataToSend2, {withCredentials: true});

          if(fetched2.data.status !== "ok") {
            throw new Error('Une erreur est survenue');
          }

          // update front
          const updateProfileFront = appContextV2.appPanels.window.windowOptions.data.updateProfileFront;
          updateProfileFront(props.windowContext.form.key, {
            type: props.windowContext.form.type,
            url: fetched.data.data,
          });
          
          notification(appContextV2.dispatchPanels, "Media ajouté avec succès", "success");
  
          appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
          
        }
      } catch (error) {
        
        devLog(error);
        props.windowContext.wizard.goToStep("error");
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error")
      }
    })();
  }, [form.fileInput]);
  
  return (<>
    <div className={"space-flex--center"}>
      <FileDrop square={true} name={"fileInput"} cb={updateForm}/>
    </div>
    <Spaces n={0}/>
  </>);
};