import React from "react";
import {ProfileElementBoolean} from "../../../../layouts/ProfileElementBoolean";

export {FormEditorOptions}

const FormEditorOptions = (props: any) => {
  
  const updateData = (nameToUpdateFront: string, nameToUpdateBack: string, value: any) => {
    props.setForm()({target: {type: "custom", name: nameToUpdateFront, value: value}});
  }

  
  return(<div className={"container white-container"}>
    
    <h1 className={"profile-edit__title"}>Options</h1>
    
    <ProfileElementBoolean text={"Masquer son score au candidat lorsqu'elle ou il postule ?"}
                           nameToUpdateBack={"hideResult"}
                           nameToUpdateFront={"hideResult"}
                           description={``}
                           value={props.form?.hideResult}
                           updateData={updateData} />
  
    <ProfileElementBoolean text={"Publier l'annonce auprès de la communauté ?"}
                           nameToUpdateBack={"showJobToCommunity"}
                           nameToUpdateFront={"showJobToCommunity"}
                           description={``}
                           value={props.form?.showJobToCommunity}
                           updateData={updateData} />
  
    <ProfileElementBoolean text={"Publier l'annonce sur la page entreprise ?"}
                           nameToUpdateBack={"showJobOnProfile"}
                           nameToUpdateFront={"showJobOnProfile"}
                           description={``}
                           value={props.form?.showJobOnProfile}
                           updateData={updateData} />

    <ProfileElementBoolean text={"Tutoyer le candidat ?"}
                           nameToUpdateBack={"modernTone"}
                           nameToUpdateFront={"modernTone"}
                           description={``}
                           value={props.form?.modernTone}
                           updateData={updateData} />


  </div>);
}