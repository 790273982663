import React, {useContext, useEffect, useReducer, useState} from "react";
import Spaces from "../../../../Spaces";
import LoadingStyle2 from "../../../../Loading/LoadingStyle2";
import AppContext, {IAppV2Context} from "../../../../../App/context/AppContext";
import axios from "axios";
import {notification} from "../../../../../../utils/notification";
import {format} from "date-fns";

export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const [blackBeltTchat, setBlackBeltTchat] = useState("");
    
    return (<div>
        <Spaces n={1}/>

        <div className={"black-belt-window__tchat-container"}>
            {(props.blackBeltTchat ?? []).map((item: any) => (<div className={"black-belt-window__tchat-item"}>
                <div className={"black-belt-window__tchat-avatar"}>{appContextV2.appSession.session.trigram}</div>
                <div>
                    <div
                        className={"black-belt-window__tchat-title"}> {item.from} - {item.dateOfCreation ? format(new Date(item.dateOfCreation), "dd/MM/yyyy") : ""}</div>
                    <div>{item.message}</div>
                </div>
            </div>))}
        </div>
        <input className={"black-belt-window__tchat-input"} value={blackBeltTchat}
               onChange={(e) => {
                   setBlackBeltTchat(e.target.value);
               }}/>

        <button className={"black-belt-window__tchat-btn"} disabled={!blackBeltTchat} onClick={async () => {
            const {
                status,
                data
            } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/update/candidacy/tchat`,
                {
                    candidacyId: props.candidacyId,
                    message: blackBeltTchat,
                    firstName: appContextV2.appSession.session.trigram,
                    type:"blackBelt",
                }
                , {
                    timeout: 5000,
                    withCredentials: true,
                })).data;

            if (status !== "ok") {
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
            props.dispatchCandidacy({
                type: "NEW_MESSAGE", value: {
                    dateOfCreation: new Date(),
                    from: appContextV2.appSession.session.trigram,
                    message: blackBeltTchat
                }
            });

            setBlackBeltTchat("");
            notification(appContextV2.dispatchPanels, "Informations sauvegardés", "success");
        }}>Envoyer
        </button>
    </div>);
}