export default (previousState: any, action: { type: string, value: any }): any => {
  
  switch(action.type) {
    
    case "UPDATE_VALUE":
      return {
        ...previousState,
        [action.value.key]: action.value.value
      }
    
    case 'UPDATE_ARRAY_ADD':
      return ({
        ...previousState,
        [action.value.key]: [...previousState[action.value.key], action.value.value]
      });
      
    case 'UPDATE_ARRAY_REMOVE':
      return ({
        ...previousState,
        [action.value.key]: previousState[action.value.key].filter((item: any) => item !== action.value.value)
      });

    case 'RESET':
      return ({
        'fullName': "",
        'phone': "",
        'email': "",
        'salary_max':0,
        'minimumExperience': 0,
        'minimumSpecificExperience': 0,
        'minimumManagement': 0,
        'minimumStudies': 0,
        'diploma': "",
        'school': "",
        'functions': [],
        'exactPositions': [],
        'exactPositionsSpecifier': "both",
        'skills': [],
        'languages': [],
        'workedFor': [],
        'workedForSpecifier': "both",
        'didNotWorkFor': [],
        'sectors': [],
        'distance': 50,
        'keywords': []
      })
    
    default:
      return previousState;
  }
  
}