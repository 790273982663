import React, {useContext} from "react";
import Spaces from "../../../Spaces";
import {useForm} from "use-formidable";
import {devLog} from "../../../../../utils/devLog/devLog";
import {notification} from "../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import axios from "axios";

export {WAMTBPVideo};

const WAMTBPVideo = (props: any) => {
  
  const [videoCode, updateVideoCode, formidable] = useForm({videoCode: ""});
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const addVideo = async() => {
    try {
  
      notification(appContextV2.dispatchPanels, "Traitement et analyse de la vidéo", "information");
  
      props.windowContext.wizard.goToStep("pending");
      
      const dataToSend2: any = {
        key: props.windowContext.form.key,
        type: props.windowContext.form.type,
        fileUrl: videoCode.videoCode,
      };
  
      const fetched = await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/bm/business/media/v2/add`, dataToSend2, {withCredentials: true});
  
      if(fetched.data.status !== "ok") {
        throw new Error('Une erreur est survenue');
      }
  
      // update front
      const updateProfileFront = appContextV2.appPanels.window.windowOptions.data.updateProfileFront;
      updateProfileFront(props.windowContext.form.key, {
        type: props.windowContext.form.type,
        url: videoCode.videoCode,
      });
  
      notification(appContextV2.dispatchPanels, "Vidéo ajoutée avec succès", "success");
  
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      
    } catch(error) {
      devLog(error);
      props.windowContext.wizard.goToStep("error");
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error")
    }
  }
  
  return (<>
    
    <div className={"text--center"}>
      Ajoute une vidéo hébergée<br/>sur YouTube en entrant son code.
      <br/>
      <span className={"text--grey"}>Il se trouve dans l'url de la vidéo. <br/>Exemple: LHOtME2DL4g</span>
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <input type={"text"} name={"videoCode"} value={videoCode.videoCode} onChange={updateVideoCode({
        maximumLength: 17,
      })}/>
  
      <Spaces n={0}/>
      
      <button className={"btn-main"} disabled={!videoCode.videoCode} onClick={()=>addVideo()}>
        Ajouter
      </button>
    
    </div>
    
    <Spaces n={1}/>
  
  </>);
};