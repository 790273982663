import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import axios from "axios";
import getFormattedFields
  from "../../componentsRecruiterMissions/componentsRMissionPageRouter/dependenciesRecruiterMissionKanban/helpers/getFormattedFields";
import {devLog} from "../../../../utils/devLog/devLog";
import {Loading} from "../../../../components/sharedComponents/Loading/Loading";

interface IRobopostData {
  isAppActive: boolean,
  postedOnRobopost: boolean,
  incompatibleFields: any[],
  vacancyId: string
}

export default (props: any) => {
  
  const [dataState, setDataState] = useState<IRobopostData>({
    isAppActive: false,
    postedOnRobopost: false,
    incompatibleFields: [],
    vacancyId: ''
  });
  
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [postedSuccess, setPostedSuccess] = useState(false);
  
  useEffect(() => {
    (async() => {
      try {
        const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/get/missionData/robopost`, {
          withCredentials: true,
          headers: {
            rank: props.missionTokens.rank,
          },
        })).data;
        
        setDataState({
          isAppActive: data.isRobopostActive ?? false,
          postedOnRobopost: data.postedOnRobopost ?? false,
          incompatibleFields: data.incompatibleFields?.filter((item: any) => item) ?? [],
          vacancyId: data.vacancyId ?? ''
        })
        
      } catch(error) {
        setError(true);
        devLog(error);
      }
    })();
  }, []);
  
  const formattedErrors = getFormattedFields({incompatibleFields: dataState.incompatibleFields});
  
  const newPostOnRobopost = (async() => {
    try {
      setIsLoading(true);
      
      const {status, data} = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/multipost/robopost`, {}, {
        withCredentials: true,
        headers: {
          rank: props.missionTokens.rank,
        },
      })).data;
      
      if(status === "ok") {
        setPostedSuccess(true);
        setIsLoading(false);
        setDataState({
          ...dataState,
          vacancyId: data.vacancyId,
          postedOnRobopost: true
        });
        const url = `https://myshortlist.robopost.com/admin/vacancy/edit/id/${data.vacancyId}`;
        return await document.open(url, 'robopost', '', true);
      } else {
        setIsLoading(false);
        setError(true);
      }
    } catch(error) {
      setIsLoading(false);
      setError(true);
      devLog(error);
    }
  });
  
  return (<>
    
    <article
      className={`white-container ${!props.white ? dataState.isAppActive ? "white-container--grey" : "white-container--grey__inactive" : dataState.isAppActive ? "" : "__inactive"}`}>
      
      <div>
        <img src={"https://static.myshortlist.co/robopost.png"}/>
        <span className={"text--bold text--magnified11x"}> Multiposting via Robopost.</span>
      </div>
      
      <Spaces n={1}/>
      
      <div className={"icons-box"}>
        <img src={"https://static.myshortlist.co/rp-facebook.jpg"}/>
        <img src={"https://static.myshortlist.co/rp-indeed.jpg"}/>
        <img src={"https://static.myshortlist.co/rp-linkedin.jpg"}/>
        <img src={"https://static.myshortlist.co/rp-leboncoin.jpg"}/>
        <img src={"https://static.myshortlist.co/rp-monster.jpg"}/>
        <img src={"https://static.myshortlist.co/rp-poleemploi.jpg"}/>
        <img src={"https://static.myshortlist.co/rp-regionsjob.jpg"}/>
      </div>
      
      <Spaces n={1}/>
      
      {isLoading && <Loading fullPage={false}/>}
      
      <div className={"text--center"}>
        {
          dataState.isAppActive && dataState.incompatibleFields.length > 0 && <div className={'page-message'}>
            Effectuez les modifications suivantes afin de multiposter
            <div className={'page-message__black'}>{formattedErrors.map((item: any) => <li>{item}</li>)}</div>
          </div>
        }
        {
          error && setTimeout(() => setError(false), 6000) &&
          <div className={'page-message'}>
            Une erreur est survenue veuillez réessayer ou contacter le service.
          </div>
        }
        
        <button className={"btn-secondary"} disabled={!dataState.isAppActive || formattedErrors.length > 0 || isLoading}
                onClick={async() => {
                  if(!dataState.postedOnRobopost) {
                    newPostOnRobopost();
                  } else {
                    const url = `https://myshortlist.robopost.com/admin/vacancy/edit/id/${dataState.vacancyId}`;
                    await document.open(`${url}`, 'robopost', '', true);
                  }
                }}>
          {!dataState.postedOnRobopost && !postedSuccess && <>Multiposter</>}
          {!dataState.postedOnRobopost && postedSuccess && <>Posté !</>}
          {dataState.postedOnRobopost && <>Accéder au poste</>}
        </button>
      </div>
    
    </article>
    {!dataState.isAppActive &&
    <div className={"text--center"} style={{marginTop: '5px'}}>Activez cette application pour l'utiliser</div>}
  
  </>);
}