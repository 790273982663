export default (previousState: any, action: {
  type: string;
  value: any;
}) => {
  
  switch(action.type) {
    
    case 'INITIALIZE':
      return [...action.value];
      
    case 'ADD':
      return [...previousState, action.value];
      
    case 'UPDATE':
      return previousState.map((item: any) => {
        if(item._id !== action.value.ambassadorId) {
          return {...item};
        }
        return {
          ...item,
          ...action.value.keyValues
        }
      });
  
    case 'DELETE':
      return previousState.filter((item: any) => item._id !== action.value);
  }
  
}