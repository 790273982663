import React from "react";
import WindowAppLayout from "../../../../../layouts/WindowAppLayout";
import {IAppContent} from "../../../../App/SingleApp";

export default (props: {
  app: IAppContent;
  dispatcher: Function;
}) => {

  const description = <> Ceci est la descirption de l'app</>

  const price = <>Cette app coute 2000 €</>

  return (<WindowAppLayout app={props.app} description={description} price={price}>

    Ceci est la description pour parsing

  </WindowAppLayout>);
}