import React, {useContext} from "react";
import WindowWrapper from "../../../../layouts/WindowLayout";
import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import {useWizard} from "use-wizard";
import {useForm} from "use-formidable";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import Spaces from "../../Spaces";
import WindowAppLayout from "../../../../layouts/WindowAppLayout";
import WAPPSms from "./componentsWAPP/WAPPSms";
import {IAppContent} from "../../../App/SingleApp";
import WAPPPersonnalFacebook from "./componentsWAPP/WAPPPersonnalFacebook";
import WAPPParsing from "./componentsWAPP/WAPPParsing";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

  const app = appContextV2.appPanels.window.windowOptions.data.app;
  const dispatcher = appContextV2.appPanels.window.windowOptions.data.dispatcher;
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    <WindowAppSwitch app={app} dispatcher={dispatcher} />
  </WindowWrapper>);

}

function WindowAppSwitch(props: {
  app: IAppContent;
  dispatcher: Function;
}){
  
  switch(props.app?.name){
    
    case "sms":
      return (<WAPPSms {...props} />);
      
    case "parsing":
      return (<WAPPParsing {...props} />);
    
    case "personalFacebook":
      return (<WAPPPersonnalFacebook {...props} />);
      
    default:
      return <>Error</>;
  }
}