import React from "react";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../components/sharedComponents/Buttons/ButtonBack";

export default (props: any) => {
  
  return (<section className={""}>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Description du poste
    </div>
    <div className={"text--grey text--center"}>Cette description sera obligatoire pour multiposter</div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <textarea className={"textarea-assistant"} value={props.form.description ?? ""} name={"description"} onChange={props.updateForm({maximumLength: 3000})}> </textarea>
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button className={"btn-assistant-main"}
              onClick={async() => {
                props.wizard.nextStep();
              }}>
        Suivant
      </button>
    </div>
  </section>);
}