import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import NavBarLogoContent from "./NavBarLogoContent";
import {SubMenuRecruiterMain} from "./SubNav/SubMenuRecruiterMain";
import {SubMenuRecruiterProfiles} from "./SubNav/SubMenuRecruiterProfiles";
import {SubMenuRecruiterParameters} from "./SubNav/SubMenuRecruiterParameters";
import {SubMenuRecruiterMission} from "../SubNavbarComponents/SubMenuRecruiterMission";
import {SubMenuRecruiterPool} from "../SubNavbarComponents/SubMenuRecruiterPool";
import {SubMenuCandidateMainContent} from "../SubNavbarComponents/SubMenuCandidateMainContent";

export function SubMenuRecruiterPc(props: any) {
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  console.log("teamId",appContextV2.appSession.sessionBusiness.teamId);
  /** List pages with a specific secondary navbar */
  const PagesWithSubMenuMain: string[] = ["RecruiterNewMissionV1", "RecruiterNewMissionV2","RecruiterMissions", "RecruiterPools", "RecruiterAmbassadors", "RecruiterResearch", "PageLink", "RecruiterDashboard"];
  const PagesWithSubMenuProfiles: string[] = ["RecruiterProfiles",];
  const PagesWithSubMenuParameters: string[] = ["RecruiterParameters",];
  
  const PagesWithSubMenuMission: string[] = ["RecruiterMissionPageRouter"];
  const PagesWithSubMenuPool: string[] = ["RecruiterPoolPageRouter"];
  
  const currentPage: string = appContextV2.appNavigation.page;
  
  /** Use LogoContent as a default fallback submenu */
  let subMenu: any = <NavBarLogoContent/>;

  const teamIdsWithoutNavBar = [
    "62795122df7950847dba2d6c",
    "6058bb0b155da2cbee753608",
    "643c4b3f17868d370f1f02e1",
    "65134abf223ce13ac6aed71c"
  ];
  
  if(PagesWithSubMenuMain.includes(currentPage) && !teamIdsWithoutNavBar.includes(appContextV2.appSession.sessionBusiness.teamId)) {
    subMenu = <SubMenuRecruiterMain/>;
  }
  
  if(PagesWithSubMenuProfiles.includes(currentPage)) {
    subMenu = <SubMenuRecruiterProfiles/>;
  }
  
  if(PagesWithSubMenuParameters.includes(currentPage)) {
    subMenu = <SubMenuRecruiterParameters/>;
  }
  
  if(PagesWithSubMenuMission.includes(currentPage)) {
    subMenu = <SubMenuRecruiterMission/>;
  }
  
  if(PagesWithSubMenuPool.includes(currentPage)) {
    subMenu = <SubMenuRecruiterPool/>;
  }
  
  return subMenu;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export function SubMenuCandidatePc(props: any) {

  return(<>
      <div className={"space-flex--left"}>
        <NavBarLogoContent/>
        <ul className={"subnavbar-new hide-on-smartphones animation-straight-fade-in"}>
          <SubMenuCandidateMainContent/>
        </ul>
      </div>
  </>);

}