export default (args: {dispatchDataV2: Function}) => (id: string) => {
  
  return args.dispatchDataV2({type: "SET_COLUMN_ID", value: {
      activateForm: false,
      activateMail: false,
      activatePool: false,
      activateAskCv: false,
      activateNotify: false,
      description: "",
      mailContent: "",
      mailDelay: 0,
      mailSubject: "",
      name: "Nouvelle colonne",
      poolId: "",
      _id: id,
    }});
}

// const newColumns = [...args.fetchedDataV2.missionProfile.customKanbanView, {
//   activateForm: false,
//   activateMail: false,
//   activatePool: false,
//   description: "",
//   mailContent: "",
//   mailDelay: 0,
//   mailSubject: "",
//   name: "Nouvelle colonne",
//   poolId: "",
//   _id: id,
// }];
// args.updateFetchedData()({
//   target: {
//     type: "custom", name: "missionProfile", value: {
//       ...args.fetchedData.missionProfile,
//       customKanbanView: newColumns,
//     }
//   }
// });