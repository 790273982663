import React, {useEffect} from "react";
import SubPageLayout from "../../../../../layouts/SubPageLayout";
import {useHistory} from "react-router";
import Spaces from "../../../../../components/sharedComponents/Spaces";

export default (props: any) => {
  
  const history = useHistory();
  
  useEffect(() => {
    const email = (props.answer.email ?? "").replace('@', "%40");
    const hider = (props.business.hideButtonSubscribeToCandidates ?? false).toString();
    const mark = (props.candidacy.globalMark ?? 0 ).toString();
    const bn = props.business.businessName ?? "";
    const hides = (props.mission?.hideResult ?? true).toString();
    history.push(`/apply/${props.pageInfos.slugname}/${props.pageInfos.slugnumber}/${props.pageInfos.rank}/apply/success?email=${email}&hider=${hider}&mark=${mark}&bn=${bn}&hides=${hides}`);
    setTimeout(() => history.go(0), 10);
  }, []);
  
  return (<SubPageLayout status={"ok"} subPage={props.subPage}>
    
    <Spaces n={10}/>
    
  </SubPageLayout>);
}