import React, {useContext} from "react";
import SubPageLayout from "../../../../../layouts/SubPageLayout";
import Spaces from "../../../../../components/sharedComponents/Spaces";
import {Link} from "react-router-dom";
import {useForm} from "use-formidable";
import {notification} from "../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../../components/App/context/AppContext";
import BPJobDesc from "./componentsBPAV3Splash/BPJobDesc";
import ButtonInformation from "../../../../../components/sharedComponents/Buttons/ButtonInformation";
import BPAmbassadorLink from "./componentsBPAV3Splash/BPAmbassadorLink";
import {devLog} from "../../../../../utils/devLog/devLog";

export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    /** Terms */
    const [formTerms, updateTerms, formidableTerms] = useForm({
        areTermsAccepted: false,
        isPolicyAccepted: false,
    });


    const areTermsAccepted: boolean = formTerms.areTermsAccepted && formTerms.isPolicyAccepted;
    const isCvMandatory: boolean = props.mission?.cvMandatory;
    const isLinkedinMandatory: boolean = props.mission?.linkedinMandatory;
    const isButtonActive: boolean = areTermsAccepted;

    const nextStep = async () => {
        try {
            props.setTimeStart(Math.floor((new Date().getTime()) / 1000));
            props.wizard.nextStep();
        } catch (error) {
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            devLog(error);
        }
    }


    return (<SubPageLayout status={"ok"} subPage={props.subPage}>

        <Spaces n={2}/>

        <section className={"container"}>

            <div className={"apply-V2__title__main"}>
                {props.mission?.positionWanted} H/F
            </div>

            <Spaces n={0}/>

            {
                props.mission?.status === "published" && <div className={"apply-V2__title"}>
                    Cliquez sur "Répondre aux questions" pour postuler
                </div>
            }
            {
                props.mission?.status !== "published" && <div className={"apply-V2__title"}>
                    Désolé, le poste a été fermé par le recruteur !
                </div>
            }

            <Spaces n={0}/>

            {(isCvMandatory) && <>
                <div className={"text--linkedin"}>
                    Un CV est obligatoire pour postuler. Il vous sera demandé à la fin du questionnaire.
                </div>
                <Spaces n={0}/>
            </>}

            {(isLinkedinMandatory && !isCvMandatory) && <>
                <div className={"text--linkedin"}>
                    Un profil LinkedIn ou un CV sont obligatoires pour postuler
                </div>
                <div className={"space-flex--right"}>
                    <ButtonInformation type={"findLinkedinProfile"}
                                       title={"Sur votre page LinkedIn, cliquez sur 'Vous' sous votre photo, puis sur 'Voir le profil'.\n Copiez l'URL de cette page pour la partager avec le recruteur."}
                                       cb={() => {
                                           appContextV2.dispatchPanels({
                                               type: "OPEN_WINDOW", value: {
                                                   type: "findLinkedinProfile",
                                                   data: {}
                                               }
                                           });
                                       }}/>
                </div>
                <Spaces n={0}/>
            </>}

            {
                props.mission?.status === "published" && <div className={"text--center text--grey text--magnified11x"}>
                    Temps indicatif: <span className={"text--magnified11x"}>3 minutes</span>
                </div>
            }

            <Spaces n={0}/>


            {
                props.mission?.status === "published" && <>
                    <div className={"text--center"}>
                        <button className={"btn-assistant-main"} disabled={!isButtonActive} onClick={() => nextStep()}>
                            Répondre aux questions pour postuler
                        </button>
                    </div>

                    {/*{*/}
                    {/*  ((isFileMandatory && !(isFileSet))) && <>*/}
                    {/*    <Spaces n={0}/>*/}
                    {/*    <div className={"text--red text--minified text--center"}>Vous devez ajouter un CV au format: docx, pdf,*/}
                    {/*      odt ou jpg*/}
                    {/*    </div>*/}
                    {/*    <Spaces n={0}/>*/}
                    {/*  </>*/}
                    {/*}*/}

                    <Spaces n={0}/>

                    {
                        !areTermsAccepted && <>
                            <div className={"text--red text--minified text--center"}>Vous devez accepter les termes pour
                                continuer
                            </div>
                            <Spaces n={0}/>
                        </>
                    }
                    
                    {/*------------------------------------------- Terms & Policy */}
                    {props.mission?.status === "published" && <>
                        <div className={"space-flex--center"}>
                            <div>
                                <input type={"checkbox"} name={"areTermsAccepted"} value={formTerms.areTermsAccepted}
                                       onChange={updateTerms()}/> J'accepte les <Link to={"/terms"}><span
                              className={"modern-link"}>conditions générales</span></Link>
                                <Spaces n={0}/>
                                <input type={"checkbox"} name={"isPolicyAccepted"} value={formTerms.isPolicyAccepted}
                                       onChange={updateTerms()}/> J'ai pris connaissance de la <Link to={"/policy"}><span
                              className={"modern-link"}>charte de confidentialité</span></Link>
                            </div>
                        </div>
                    </>
                    }
                </>}
    
            <Spaces n={0}/>
    
    
            {/*--------------------------------- Description */}
            {!props.mission.documentUrl && <BPJobDesc mission={props.mission}/>}
            {props.mission.documentUrl &&
            <div className={"div-center white-container white-container--grey"}>
                <a target={"_blank"} href={props.mission.documentUrl}>
                    <button className={"btn--hollow modern-link"}> Télécharger la fiche de poste</button>
                </a>
            </div>
            }
            
            <Spaces n={0}/>

            <BPAmbassadorLink {...props}/>

            <Spaces n={0}/>

            {/*--------------------------------- CV */}
            {/*<article className={"white-container white-container--grey"}>*/}
            {/*  {props.mission.withoutCv && <div className={"text--center apply-V2__title"}>Recrutement sans CV</div>}*/}
            {/*  {!props.mission.withoutCv && !props.mission.cvMandatory && !cv.accepted &&*/}
            {/*  <div className={"text--center apply-V2__title"}>CV facultatif</div>}*/}
            {/*  {!props.mission.withoutCv && props.mission.cvMandatory && !cv.accepted &&*/}
            {/*  <div className={"text--center apply-V2__title"}>CV nécéssaire pour continuer</div>}*/}
            {/*  {!props.mission.withoutCv && cv.accepted && <div className={"text--center apply-V2__title"}>CV accepté</div>}*/}
            {/*  {*/}
            {/*    (!props.mission.withoutCv && !isFileSet) && <>*/}
            {/*      <br/>*/}
            {/*      <div className={"space-flex--center"}>*/}
            {/*        <FileDrop square={false} name={"file"} cb={updateCv}/>*/}
            {/*      </div>*/}
            {/*    </>*/}
            {/*  }*/}
            {/*</article>*/}

            {/*<Spaces n={0}/>*/}

        </section>

    </SubPageLayout>);
}