import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import {useWizard} from "use-wizard";
import {useForm} from "use-formidable";
import BPAV3Splash from "./componentsBusinessPageApplyV3/BPAV3Splash";
import BPAV3Custom from "./componentsBusinessPageApplyV3/BPAV3Custom";
import BPAV3Skills from "./componentsBusinessPageApplyV3/BPAV3Skills";
import BPAV3Send from "./componentsBusinessPageApplyV3/BPAV3Send";
import BPAV3Success from "./componentsBusinessPageApplyV3/BPAV3Success";
import BPAV3Error from "./componentsBusinessPageApplyV3/BPAV3Error";
import {devLog} from "../../../../utils/devLog/devLog";
import BPAV3DefaultQuestions from "./componentsBusinessPageApplyV3/BPAV3DefaultQuestions";
import axios from "axios";

export default (props: any) => {
  
  /** Describes the steps to apply */
  const [step, wizard] = useWizard(["splash", "defaultQuestions", "formCustom", "formSkills", "sendCandidacy", "error", "success"]);
  
  /** Gets data for answers */
  const [answer, updateAnswer, formidableAnswer] = useForm({
    cvUrl: "",
    avatarUrl: '',
    elapsedTime: 0,
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    linkedin: '',
    comment: '',
    skills: [],
    answers: [],
    defaultAnswers: {}
  });
  
  devLog("answer", answer);
  
  /** Answer from the server */
  const [candidacy, setCandidacy] = useState({
    candidacyId: "",
    mark: "",
  });
  
  devLog("candidacy", candidacy);
  
  /** Sets a global timer */
  const [timeStart, setTimeStart] = useState(0);
  
  /**
   * Update mission and ambassador views
   */
  useEffect(() => {
    (async function() {
      try {
        const {status, data} = await axios.put(`${process.env.REACT_APP_API_URL}/v3/data/stats/add/views`, {
          missionId: props.mission._id,
          webAmbassador: props.pageInfos.webAmbassador,
        }, {withCredentials: true});
      } catch(error) {
        devLog(error);
      }
    })();
  }, []);
  
  /**
   * Saves the global answering time
   */
  const applyAssistantContext = {
    ...props, step, wizard, answer, updateAnswer, formidableAnswer, candidacy, setCandidacy, timeStart, setTimeStart,
  }
  
  switch(step) {
    
    case 'splash':
      return (<BPAV3Splash {...applyAssistantContext} />);
    
    case 'defaultQuestions':
      return (<BPAV3DefaultQuestions {...applyAssistantContext} />);
    
    case 'formCustom':
      return (<BPAV3Custom {...applyAssistantContext} />);
    
    case 'formSkills':
      return (<BPAV3Skills {...applyAssistantContext} />);
    
    case 'sendCandidacy':
      return (<BPAV3Send {...applyAssistantContext} />);
    
    case 'success':
      return (<BPAV3Success {...applyAssistantContext} />);
    
    default:
    case 'error':
      return (<BPAV3Error {...applyAssistantContext} />);
  }
  
}
