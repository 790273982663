export default (setDisplayedUnsubscribedShortlist: Function) => (candidacyId: string, toColumnId: string) => {

  return setDisplayedUnsubscribedShortlist((ps: any) => [...ps.map((item: any) => {
    if (item._id?.toString() !== candidacyId) {
      return {...item};
    } else {
      return {
        ...item,
        column: toColumnId,
      }
    }
  })]);
}