import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import {notification} from "../../utils/notification";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import Spaces from "../../components/sharedComponents/Spaces";
import PageLayout from "../../layouts/PageLayout";
import {format} from "date-fns";
import ButtonShare from "../../components/sharedComponents/ButtonShare";
import {Link} from "react-router-dom";

export default (props: any) => {
  
  const token: string = props.match.params.token || "";
  const [data, setData]: any = useState({});
  const [status, setStatus]: any = useState({});
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  useEffect(() => {
    (async function() {
      try {
        const {
          status,
          data
        } = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/get/ambassador/information`,
          {
            headers: {
              token: token,
            }, withCredentials: true
          })).data;
        
        setStatus(status);
        setData(data);
      } catch(error) {
        devLog(error);
      }
    })();
  }, []);
  
  // todo: ajouter des conditions pour afficher des messages d'erreur en cas de tokenNotValid et autre
  
  return (<PageLayout fullWidth={true} grey={true} page={props.page} status={"ok"}>
      
      {status === "no ambassador" && <>
        <Spaces n={8}/>
        <div className={"text--magnified15x text--center"}>
          L'ambassadeur ne semble pas exister
        </div>
        <Spaces n={5}/>
      </>}
      {status === "ok" && <><Spaces n={2}/>
        <div className={"ambassador-dashboard--header"}>
          <div>{data?.ambassadorAvatar &&
          <img className={"avatar-display--middle"} src={data?.ambassadorAvatar}/>}</div>
          <Spaces n={0}/>
          <div
            className={"ambassador-dashboard--title"}> {data?.ambassadorFirstName} {data?.ambassadorLastName?.toUpperCase()}</div>
          <div className={"ambassador-dashboard--title"}> {data?.business?.name?.value?.toUpperCase()} </div>
        </div>

        <Spaces n={1}/>
        <div className={"ambassador-dashboard--solicitations"}>
          <h1 className={"ambassador-dashboard--title"}> Sollicitations </h1>
          {data?.solicitations?.length !== 0 && <div>{data?.solicitations?.map((item: any) => (
            <>
              <div className={"ambassador-dashboard--solicitation"}>
                <div className={"ambassador-dashboard--solicitation-left"}>
                  <div
                    className={"ambassador-dashboard--solicitation-item"}> {item.dateOfCreation ? format(new Date(item.dateOfCreation), "dd-MM-yyyy") : ""} -
                  </div>
                  <div className={"ambassador-dashboard--solicitation-item"}> {item.positionWanted} -</div>
                  <div
                    className={"ambassador-dashboard--solicitation-item"}> Statut: {item.status === "published" ? "ouvert" : "ferme"}</div>
                </div>
                
                <div className={"ambassador-dashboard--solicitation-right"}>
                  <ButtonShare onClick={() => {
                    notification(appContextV2.dispatchPanels, "Lien copié", "success");
                    navigator.clipboard.writeText(
                      `${window.location.origin}/apply/${data.business?.name?.slugName}/${data.business?.name?.slugNumber}/${item.rank}/apply?a=${data.ambassadorId}`
                    );
                  }}/>
                </div>
              </div>
              <div className={"ambassador-dashboard--stats"}>
                <div className={"ambassador-dashboard--stats-item"}>Vue(s):{item.nViews ?? 0}</div>
                <div className={"ambassador-dashboard--stats-item"}>Candidature(s):{item.nCandidacies ?? 0}</div>
                <div className={"ambassador-dashboard--stats-item"}>Recrutement(s):{item.nRecruitments ?? 0}</div>
              </div>
            </>))}
          </div>}
          {data?.solicitations?.length === 0 && <div> Vous n'avez pas encore été sollicité.</div>}
        </div>

        <Spaces n={1}/>
        <div className={"ambassador-dashboard--solicitations"}>
          <h1 className={"ambassador-dashboard--title"}>Mes entreprises</h1>
          {data?.ambassadorBusinesses?.filter((item: any) => item._id !== data?.business?._id).length !== 0 &&
          <div> {data?.ambassadorBusinesses?.filter((item: any) => item._id !== data?.business?._id).map((item: any) => (<>
            <Link to={`/ambassador/${item.token}`}>
              <div className={"ambassador-dashboard--business"}>
                <div>{item.avatar?.url &&
                <img className={"ambassador-dashboard--business-avatar"} src={item.avatar?.url}/>}</div>
                <div>{item.name?.value}</div>
                <div>
                  <button className={"btn-secondary"} onClick={() => {
                  }}>Accéder
                  </button>
                </div>
              </div>
            </Link>
          </>))}</div>}
          {data?.ambassadorBusinesses?.filter((item: any) => item._id !== data?.business?._id).length === 0 &&
          <div> Vous n'êtes pas ambassadeur auprès d'autres entreprises. </div>}
        </div>
      </>}
    </PageLayout>
  );
}