import React, {useState} from "react";
import Spaces from "../../../Spaces";
import {IColumnData} from "../WindowColumnOptionsV2";
import MailComponent from "../../../MailComponent";

export {WCOEmailV2};

const WCOEmailV2 = (props: {
  type: "kanban" | "preset",
  missionId: string,
  numberOfCandidates: number,
  columnData: IColumnData,
  updateColumnData: Function,
  saveColumnData: Function,
  switchColumnAction: Function,
  isWindowLoading: boolean,
  deleteColumn: Function
}) => {
  
  return (<>
    
    <article className={`white-container white-container--grey`}>
      <div className={"space-flex--space-between"}>
        <span className={"text--bold text--magnified11x"}>Activer l'action "Envoyer un email" ?</span>
        <div>
          <label className={"switch-V2"}>
            <input type={"checkbox"} className={"checkbox-on-off"} checked={!!props.columnData.activateMail}
                   onChange={() => {
                     if(!!props.columnData.activateMail) {
                       props.updateColumnData({activateMail: false});
                       props.saveColumnData({activateMail: false});
                     }
                     if(!props.columnData.activateMail) {
                       props.switchColumnAction("activateMail");
                     }
                   }}/>
            <span className="slider"> </span>
          </label>
        </div>
      </div>
      
      <Spaces n={0}/>
      <div className={"text--center text--grey"}>
        Les candidats déplacés dans cette colonne recevront un email de votre choix.
      </div>
      
      {/*------------------- Content*/}
      {
        !!props.columnData.activateMail && <WCOEmailV2Options {...props}/>
        
      }
    
    </article>
  
  </>);
}

function WCOEmailV2Options(props: {
  type: "kanban" | "preset",
  missionId: string,
  numberOfCandidates: number,
  columnData: IColumnData,
  updateColumnData: Function,
  saveColumnData: Function,
  switchColumnAction: Function,
  isWindowLoading: boolean,
  deleteColumn: Function
}) {
  
  return (<>
    <div>
      <Spaces n={0}/>
      <MailComponent mailContent={props.columnData.mailContent ?? ""}
                     mailSubject={props.columnData.mailSubject?? ""}
                     updateMailContent={ (value:any)=>{props.updateColumnData({mailContent: value});}}
                      updateMailSubject={ (value:any)=>{props.updateColumnData({mailSubject: value});}}/>

      <Spaces n={0}/>
      <div className={"space-flex--space-between"}>
        <div>
          Envoyer l'email avec un délai de 2 jours ?
        </div>
        <div>
          <label className={"switch-V2"}>
            <input type={"checkbox"} className={"checkbox-on-off"} checked={props.columnData.mailDelay === 2}
                   onChange={() => {
                     if(props.columnData.mailDelay === 2) {
                       props.updateColumnData({mailDelay: 0});
                     }
                     if(props.columnData.mailDelay === 0) {
                       props.updateColumnData({mailDelay: 2});
                     }
                   }}/>
            <span className="slider"> </span>
          </label>
        </div>
      </div>
      <div className={"text--center"}>
        <button className={"btn-secondary"}
                onClick={() => {
                  props.saveColumnData({
                    mailContent: props.columnData.mailContent,
                    mailSubject: props.columnData.mailSubject,
                    mailDelay: props.columnData.mailDelay,
                  });
                }}>
          Enregistrer
        </button>
      </div>
    </div>
  </>);
}
