import React, {useContext, useEffect, useReducer, useState} from "react";
import {displayDate} from "../../../../../../utils/displayDate";
import Spaces from "../../../../Spaces";
import axios from "axios";
import {notification} from "../../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../../App/context/AppContext";
import {devLog} from "../../../../../../utils/devLog/devLog";
import EditableCanddiatePoolProfileComponent from "../../componentsWCPP/EditableCanddiatePoolProfileComponent";
import {useForm} from "use-formidable";
import LoadingStyle2 from "../../../../Loading/LoadingStyle2";
import {FileDrop} from "../../../../Buttons/FileDrop";
import TextAreaCustom from "./TextAreaCustom";
import ListCustomChoice from "./ListCustomChoice";
import ExperienceEditor from "./ExperienceEditor";
import ListCustomText from "./ListCustomText";
import {format} from "date-fns";
import getFullUrl from "../../../../../../utils/getFullUrl";
import {isHighlighted} from "../dependencies/isHighlighted";
import {shortenText} from "../../../../../../utils/shortenText";
import BlackBeltTchat from "../dependencies/BlackBeltTchat";
import dataReducerCandidacy from "./dataReducerCandidacy";

function reducerExtendedProfile(state: any, action: { type: string, value: any }) {

    switch (action.type) {

        case 'UPDATE':
            return {
                ...state,
                ...action.value
            }

        case 'SWITCH':
            return {
                ...state,
                [action.value]: !state[action.value]
            }

        case 'ADD_TO_ARRAY':
            if (!state[action.value.keyName].includes(action.value.value)) {
                return {
                    ...state,
                    [action.value.keyName]: [...state[action.value.keyName], action.value.value]
                };
            } else {
                return state;
            }

        case 'REMOVE_FROM_ARRAY':
            return {
                ...state,
                [action.value.keyName]: state[action.value.keyName].filter((item: string) => item !== action.value.value)
            };

        case 'ADD_EXPERIENCE':
            return {
                ...state,
                'experience': [...state.experience, action.value]
            };

        case 'SWITCH_EXPERIENCE':
            const newExp = [...state.experience]
            newExp[action.value.index] = {...action.value.experience}
            return {
                ...state,
                experience: newExp
            }

        case 'DELETE_EXPERIENCE':
            return {
                ...state,
                'experience': state.experience.filter((exp: any) => (
                    exp.company != action.value.company ||
                    exp.position != action.value.position ||
                    exp.companyDescription != action.value.companyDescription ||
                    exp.candidateMissions.length != action.value.candidateMissions.length ||
                    exp.keys.length != action.value.keys.length
                ))
            }

        case 'MOVE_EXP_UP':
            if (action.value === 0) {
                return state;
            }
            const expToCopy = {...state.experience[action.value]};
            const previousExpToCopy = {...state.experience[action.value - 1]};
            const length1 = state.experience.length;

            return {
                ...state,
                experience: [
                    ...([...state.experience].slice(0, action.value - 1)),
                    expToCopy,
                    previousExpToCopy,
                    ...([...state.experience].slice(action.value + 1, length1))
                ]
            }


        case 'MOVE_EXP_DOWN':
            const length2 = state.experience.length;
            if (action.value === length2 - 1) {
                return state;
            }
            const expToCopy2 = {...state.experience[action.value]};
            const nextExpToCopy = {...state.experience[action.value + 1]};

            return {
                ...state,
                experience: [
                    ...([...state.experience].slice(0, action.value)),
                    nextExpToCopy,
                    expToCopy2,
                    ...([...state.experience].slice(action.value + 2, length2))]
            }


    }

    return state;
}

const defaultExtendedProfile = {
    feeling: "",
    accomplished: "",
    experience: [],
    product: [],
    clients: [],
    management: [],
    workedIn: [],
    wants: "",
    personal: "",
    flashcard: false,
    women: false,
}

export default function (props: any) {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    const [extendedProfile, dispatchExtendedProfile] = useReducer(reducerExtendedProfile, props.candidate?.candidate?.extendedProfile || defaultExtendedProfile);
    console.log("extendedProfile",extendedProfile);
    const [candidacy, dispatchCandidacy]: [any, Function] = useReducer(dataReducerCandidacy, {
        blackBeltCulture: 0,
        blackBeltHardSkills: 0,
        blackBeltSoftSkills: 0,
        blackBeltImportant: [],
        blackBeltAvailability: "",
        blackBeltInformations: [],
        blackBeltVideo: "",
        blackBeltLoomLink:"",
        blackBeltNextStep: "",
        blackBeltTchat: [],
        interview2: [],
        blackBeltFeedbackInterview:"",
        missionDateOfCreation:"",
        candidacyDateOfCreation:"",
        sentToClient:"",
        clientAnswer:"",
        blackBeltInterview:"",
        clientInterview:"",
        jobOffer:"",
        onboarding:"",
        entryDate:"",
        endOfInitialTrialPeriod:"",
        endOfTrialPeriod:"",
        internalCommentary:"",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isLiParsing, setIsLiParsing] = useState(false);
    const [isLiParsingOver, setIsLiParsingOver] = useState(false);
    const [nextStep, setNextStep] = useState(props.candidate?.candidacy?.blackBeltNextStep);
    const [nextStepEditorMode, setNextStepEditorMode] = useState(false);

    const isClientNotRaileniumOrRaileniumAdmin = appContextV2.appSession.sessionBusiness.businessName !== "Railenium" ||
        (appContextV2.appSession.session.userRole === "admin" && appContextV2.appSession.sessionBusiness.businessName === "Railenium");


    const [video, updateVideo, formidableVideo] = useForm({
        file: null,
        accepted: false,
        sending: false,
    });




    /**
     * Upload de la video
     */
    useEffect(() => {
        try {
            if (video.file) {
                if (!formidableVideo.isFileSmallerThan(video.file[0], 62_000_000)) {
                    notification(appContextV2.dispatchPanels, "La taille du fichier est trop importante (maximum: 25 mo).", "warning");
                    return;
                }
                (async () => {
                    try {
                        setIsSaving(true);
                        updateVideo()({target: {type: "custom", name: "accepted", value: true}});
                        // Send video
                        const dataToSend: any = new FormData();
                        dataToSend.append("video", video.file[0], video.file[0].name);
                        const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/upload/data/bb/video`, dataToSend, {
                            withCredentials: true,
                            timeout: 1200000,
                            'maxContentLength': Infinity,
                            'maxBodyLength': Infinity,
                            headers: {
                                'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
                                'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
                            }
                        });
                        if (fetched.data.status !== "ok") {
                            setIsSaving(false);
                            setIsUploaded(false);
                            updateVideo()({target: {type: "custom", name: "accepted", value: false}});
                            return alert("Problème vidéo");
                        }
                        console.log("!!!!!!!!!!!!!!!!!!!!!!", fetched.data.data);
                        // alert("Vidéo ajoutée, n'oubliez pas de sauvegarder !'");
                        setIsUploaded(true);
                        dispatchExtendedProfile({
                            type: "UPDATE",
                            value: {
                                'videoUrl': fetched.data.data
                            }
                        })
                        setIsSaving(false);
                    } catch (error) {
                        alert("Une erreur est survenue");
                    }
                })();
            }
        } catch (error) {
            console.log(error);
            alert("Une erreur est survenue, la vidéo n'est pas ajoutée, " + error.message);
            updateVideo()({target: {type: "custom", name: "accepted", value: false}});
        }
    }, [video.file]);


    useEffect(() => {
        (async() => {
            try {
                setIsLoading(true);

                const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/candidacy/infos`, {
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        candidacyid: props.candidate?.candidacy?._id,
                    }
                })).data;

                if(status !== "ok") {
                    setIsLoading(false);
                    return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                }

                dispatchCandidacy({type: "INITIALIZE_DATA", value: data});
                setIsLoading(false);
            } catch(error) {
                setIsLoading(false);
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        })()
    }, []);

    const importLinkedinExp = async () => {
        try {
            setIsLoading(true);
            const {
                status,
                data
            } = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/communities/candidate/exp/in`, {
                headers: {
                    cid: props.candidate?.candidate?._id
                }
            })).data;

            setIsLoading(false);

            if (status !== 'ok') {
                throw new Error(status);
            }

            dispatchExtendedProfile({
                type: 'UPDATE',
                value: {
                    'experience': data ?? []
                }
            });

            notification(appContextV2.dispatchPanels, "Import avec succes", "success");

        } catch (error) {
            setIsLoading(false);
            alert(error.message);
        }
    }

    const getParsed = (candidate: any) => {

        const isLinkedinParsed = candidate.linkedinFingerPrint2 && !candidate.linkedinFingerPrint2?.includes('error');

        const isCvParsed = candidate.cvFingerPrint && !candidate.linkedinFingerPrint2?.includes('error');

        if (isLinkedinParsed && isCvParsed) {
            return `cv+in(${displayDate({original: candidate.lastScrapped})})`
        }

        if (isLinkedinParsed) {
            return `in(${displayDate({original: candidate.lastScrapped})})`
        }

        if (isCvParsed) {
            return "cv";
        }

        return '--'

    }

    const saveExtendedProfile = async () => {
        try {
            setIsLoading(true)
            const {
                status,
                data
            } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/communities/extended/profile`, {
                cid: props.candidate?.candidate?._id,
                extendedProfile: extendedProfile
            }, {withCredentials: true})).data;
            if (status !== 'ok') {
                throw new Error(status);
            }
            setIsLoading(false)
            props.updateCandidateFrontInWindow({[extendedProfile]: extendedProfile});
            notification(appContextV2.dispatchPanels, "Sauvegarde avec succes", "success");
        } catch (error) {
            setIsLoading(false)
            alert(error.message);
        }
    }

    return <div className={"profile2"}>

        {
            appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" &&
            <div className={"space-flex--left"}>
                <label className={"switch-V2"}>
                    <input disabled={false} type={"checkbox"} className={"checkbox-on-off"}
                           checked={extendedProfile?.flashcard ?? false} onChange={async () => {
                        dispatchExtendedProfile({
                            type: 'SWITCH',
                            value: 'flashcard'
                        });
                    }}/>
                    <span className="slider"> </span>
                </label>
                <span className={'space-left'}>Activer la Flashcard</span>

            </div>
        }


        <Spaces n={0}/>

        <div className={"text--grey text--minified2x"}>
            Parsé: {getParsed(props.candidate?.candidate)}
            <br/>
            Sauvegardé
            le {props.candidate?.candidate?.extendedProfile?.dateOfUpdate ? displayDate({original: props.candidate?.candidate?.extendedProfile?.dateOfUpdate}) : "---"} par {props.candidate?.candidate?.extendedProfile?.updatedBy ? props.candidate?.candidate?.extendedProfile?.updatedBy : "---"}
            {
                appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" && <>
                    <br/>
                    <a href={props.candidate?.candidate?.linkedin ? getFullUrl(`${props.candidate?.candidate?.linkedin}?cid=${props.candidate.candidate._id}&&mid=${props.inherited.missionId}`) : `#`}
                       target={'_blank'}>
                        <button disabled={!props.candidate?.candidate?.linkedin || isLiParsing}
                                className={'btn-main btn-main--linkedin'} onClick={() => {
                            setIsLiParsing(true);
                            setIsLiParsingOver(false);
                            setTimeout(() => {
                                setIsLiParsing(false);
                                setIsLiParsingOver(true);
                                // reload profile
                                props.setReload((ps: number) => ps + 1)
                            }, 15_000)
                        }}>
                            Parser In
                        </button>
                        {isLiParsing && <span className={"text--grey"}>Chargement ...</span>} {isLiParsingOver &&
                        <span className={"text--grey"}>Fin du parsing du profil LinkedIn 👍</span>}
                    </a>
                </>
            }
        </div>

        <section className={"profile2__block"}>

            <div className={"profile2__block__title"}>
                <img src={"https://static.myshortlist.co/contact.svg"}/>
                <span>Identité & Contact</span>
            </div>

            <div className={"space-flex--left"}>
                <label className={"switch-V2"}>
                    <input disabled={false} type={"checkbox"} className={"checkbox-on-off"}
                           checked={extendedProfile?.women ?? false} onChange={async () => {
                        dispatchExtendedProfile({
                            type: 'SWITCH',
                            value: 'women'
                        });
                    }}/>
                    <span className="slider"> </span>
                </label>
                <span className={'space-left'}>Femme</span>

            </div>

            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.firstName}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Prénom"}
                                                   keyToUpdate={"firstName"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />

            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.lastName}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Nom"}
                                                   keyToUpdate={"lastName"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />


            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.email}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Email"}
                                                   keyToUpdate={"email"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />

            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.phone}
                                                   editable={true}
                                                   candidateId={props.inherited?.candidateId}
                                                   keywords={props.inherited.keywords || []}
                                                   displayedKey={"Téléphone"}
                                                   keyToUpdate={"phone"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />

            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.linkedin}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Linkedin"}
                                                   keyToUpdate={"linkedin"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />

            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.locationString}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Localisation"}
                                                   keyToUpdate={"locationString"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />

            <div className={"profile2__block__title"}>
                <img src={"https://static.myshortlist.co/contact.svg"}/>
                <span>Informations</span>
            </div>

            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.positionWantedString}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Poste souhaité"}
                                                   keyToUpdate={"positionWantedString"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />

            <div className={'all-text--small'}>
                <div className={`text-left space-flex--left`}>
                    <button className={"btn-picto--minimalist2 btn-picto--minimalist2--small"} onClick={() => {
                        setNextStepEditorMode((ps: boolean) => !ps);
                    }}>
                        <img src={"https://static.myshortlist.co/btn-pen.svg"}/>
                    </button>

                    Next Step pour le candidat: {shortenText((nextStep), 50)}   </div>
                {
                    nextStepEditorMode &&
                    <div>
                        <input className={"input--left profile2-date"} type={"text"} value={nextStep ? nextStep : ""}
                               onChange={(e) => {
                                   setNextStep(e.target?.value)
                               }}/>


                        <button disabled={isLoading}
                                className={"btn--hollow modern-link--grey space-light"} onClick={
                            async () => {
                                try {
                                    props.updateCandidateFrontInWindow({nextStep: nextStep});
                                    const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/update/candidacy/nextStep`, {
                                        candidacyId: props.candidate.candidacy._id,
                                        nextStep: nextStep,
                                    }, {
                                        timeout: 20_000,
                                        withCredentials: true
                                    });
                                    if (fetched.data.status !== "ok") {
                                        setIsLoading(false);
                                        setNextStepEditorMode(false);
                                        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                                    }
                                    setNextStepEditorMode(false);
                                    notification(appContextV2.dispatchPanels, "Mise à jour avec succès", "success");
                                } catch (error) {
                                    setNextStepEditorMode(false);
                                    notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                                }
                            }}
                        >Valider
                        </button>

                        <button disabled={isLoading} className={"btn--hollow modern-link--grey space-light"}
                                onClick={() => {
                                    setNextStepEditorMode(false);
                                }}>
                            Annuler
                        </button>
                    </div>
                }
            </div>


            <div className={"profile2__block__title"}>
                <img src={"https://static.myshortlist.co/calendar.svg"}/>
                <span>Disponibilité</span>
            </div>
            <select className={'profile2-date'} value={props.candidate?.candidate?.availability}
                    onChange={async (e) => {
                        try {
                            props.updateCandidateFrontInWindow({availability: e.target.value});
                            const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/candidate/singleton`, {
                                candidateId: props.candidate.candidate._id,
                                key: 'availability',
                                value: e.target.value,
                            }, {
                                timeout: 20_000,
                                withCredentials: true
                            });
                            if (fetched.data.status !== "ok") {
                                setIsLoading(false);
                                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                            }
                            notification(appContextV2.dispatchPanels, "Mise à jour avec succès", "success");
                        } catch (error) {
                            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                        }
                    }}>
                <option value={""}>-- Choisir --</option>
                <option value={"0"}>-- Immédiatement --</option>
                <option value={"1"}>-- Préavis 1 mois --</option>
                <option value={"2"}>-- Préavis 2 mois --</option>
                <option value={"3"}>-- Préavis 3 mois --</option>
                <option value={"4"}>-- Date --</option>
            </select>

            {isClientNotRaileniumOrRaileniumAdmin && <div>
                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/salary-mail.svg"}/>
                    <span>Salaire actuel (annuel brut)</span>
                </div>

                <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.currentSalary}
                                                       editable={true}
                                                       candidateId={props.inherited.candidateId}
                                                       keywords={props.inherited.keywords}
                                                       displayedKey={"Fixe"}
                                                       keyToUpdate={"currentSalary"}
                                                       updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                />


                <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.currentVariableSalary}
                                                       editable={true}
                                                       candidateId={props.inherited.candidateId}
                                                       keywords={props.inherited.keywords}
                                                       displayedKey={"Variable"}
                                                       keyToUpdate={"currentVariableSalary"}
                                                       updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                />
            </div>
            }

            {isClientNotRaileniumOrRaileniumAdmin && <div>
                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/employee-salary.svg"}/>
                    <span>Salaire souhaité (annuel brut)</span>
                </div>

                <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.salaryMin}
                                                       editable={true}
                                                       candidateId={props.inherited.candidateId}
                                                       keywords={props.inherited.keywords}
                                                       displayedKey={"Fixe"}
                                                       keyToUpdate={"salaryMin"}
                                                       updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                />


                <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.salaryVariableMin}
                                                       editable={true}
                                                       candidateId={props.inherited.candidateId}
                                                       keywords={props.inherited.keywords}
                                                       displayedKey={"Variable"}
                                                       keyToUpdate={"salaryVariableMin"}
                                                       updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                />
            </div>
            }

                <div className={"profile2__block__title"}>
                    <img src={"https://static.myshortlist.co/experience.svg"}/>
                    <span>Années d'expérience</span>
                </div>


            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.yearsOfExperience}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Annees totales d'expérience"}
                                                   keyToUpdate={"yearsOfExperience"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />

            <div className={"profile2__block__title"}>
                <img src={"https://static.myshortlist.co/calendar.svg"}/>

                <span>Interview</span>
            </div>
            Date du dernier entretien:
            <input type={"date"} className={'profile2-date'}
                   value={props.candidate?.candidate?.lastInterview ? format(new Date(props.candidate?.candidate?.lastInterview), "yyyy-MM-dd") : ""}
                   onChange={async (e) => {
                       try {
                           props.updateCandidateFrontInWindow({lastInterview: e.target.value});
                           const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/candidate/singleton`, {
                               candidateId: props.candidate.candidate._id,
                               key: 'lastInterview',
                               value: e.target.value,
                           }, {
                               timeout: 20_000,
                               withCredentials: true
                           });
                           if (fetched.data.status !== "ok") {
                               setIsLoading(false);
                               return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                           }
                           notification(appContextV2.dispatchPanels, "Mise à jour avec succès", "success");
                       } catch (error) {
                           notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                       }
                   }}/>


            <div className={"profile2__block__title"}>
                <img src={"https://static.myshortlist.co/business-manager.svg"}/>
                <span>TAS</span>
            </div>

            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.tas}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"TAS créateur de la flashcard"}
                                                   keyToUpdate={"tas"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />

        </section>

        {/*//////////////////////////////////////////////////////////////*/}

        <section className={"profile2__block"}>

            <div className={"profile2__block__title"}>
                <span>Matching</span>
            </div>

            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.global}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Global"}
                                                   keyToUpdate={"global"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />

            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.culture}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Culture"}
                                                   keyToUpdate={"culture"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />

            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.hardSkills}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Hard skills"}
                                                   keyToUpdate={"hardSkills"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />
            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.softSkills}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Soft skills"}
                                                   keyToUpdate={"softSkills"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />
            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.creativity}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Créativité"}
                                                   keyToUpdate={"creativity"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />

            <EditableCanddiatePoolProfileComponent value={props.candidate?.candidate?.autonomy}
                                                   editable={true}
                                                   candidateId={props.inherited.candidateId}
                                                   keywords={props.inherited.keywords}
                                                   displayedKey={"Autonomie"}
                                                   keyToUpdate={"autonomy"}
                                                   updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
            />


        </section>


        {/*//////////////////////////////////////////////////////////////*/}


        <section className={"profile2__block"}>


            <div className={"profile2__block__title"}>
                <img src={"https://static.myshortlist.co/fire-torch.svg"}/>
                <span>A propos de ce candidat</span>
            </div>
            <TextAreaCustom value={extendedProfile.personal} keyName={'personal'} update={dispatchExtendedProfile}
                            updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                            updateCandidateExtendedProfileFrontInWindow={props.updateCandidateExtendedProfileFrontInWindow}
            />

            <div className={"profile2__block__title"}>
                <img src={"https://static.myshortlist.co/heart.svg"}/>
                <span>Notre feeling</span>
            </div>
            <TextAreaCustom value={extendedProfile.feeling} keyName={'feeling'} update={dispatchExtendedProfile}
                            updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                            updateCandidateExtendedProfileFrontInWindow={props.updateCandidateExtendedProfileFrontInWindow}
            />


            <div className={"profile2__block__title"}>
                <img src={"https://static.myshortlist.co/target.svg"}/>
                <span>Ses envies</span>
            </div>
            <TextAreaCustom value={extendedProfile.wants} keyName={'wants'} update={dispatchExtendedProfile}
                            updateCandidateFrontInWindow={props.updateCandidateFrontInWindow}
                            updateCandidateExtendedProfileFrontInWindow={props.updateCandidateExtendedProfileFrontInWindow}
            />

            {/*<div className={"profile2__block__title"}>*/}
            {/*    <img src={"https://static.myshortlist.co/cup.svg"}/>*/}
            {/*    <span>Accomplissements</span>*/}
            {/*</div>*/}
            {/*<TextAreaCustom value={extendedProfile.accomplished} keyName={'accomplished'}*/}
            {/*                update={dispatchExtendedProfile}/>*/}


        </section>

        <section className={"profile2__block"}>

            <div className={"profile2__block__title"}>
                <img src={"https://static.myshortlist.co/labor-work.svg"}/>
                <span>Expérience</span>
                <button disabled={!props.candidate?.candidate?.linkedinFingerPrint2 || isLoading}
                        className={'btn-main btn-main--linkedin'} onClick={() => importLinkedinExp()}>
                    Import in
                </button>
            </div>
            <ExperienceEditor value={extendedProfile.experience} update={dispatchExtendedProfile}/>

            {/*<div className={"profile2__block__title"}>*/}
            {/*    <img src={"https://static.myshortlist.co/box.svg"}/>*/}
            {/*    <span>Type de produit vendu</span>*/}
            {/*</div>*/}
            {/*<ListCustomText update={dispatchExtendedProfile} keyName={"product"} value={extendedProfile.product}/>*/}

            {/*<div className={"profile2__block__title"}>*/}
            {/*    <img src={"https://static.myshortlist.co/business-manager.svg"}/>*/}
            {/*    <span>Types de clients gérés</span>*/}
            {/*</div>*/}
            {/*<ListCustomChoice update={dispatchExtendedProfile} keyName={"clients"} value={extendedProfile.clients}*/}
            {/*                  choices={["PME/Start-ups", "ETI", "Grands groupes", "Commercants/independants", "Particuliers"]}/>*/}

            {/*<div className={"profile2__block__title"}>*/}
            {/*    <img src={"https://static.myshortlist.co/experience.svg"}/>*/}
            {/*    <span>Expérience en management</span>*/}
            {/*</div>*/}
            {/*<ListCustomChoice update={dispatchExtendedProfile} keyName={"management"} value={extendedProfile.management}*/}
            {/*                  choices={["Encadrement - CDI", "Encadrement - Stages alternance"]}/>*/}

            {/*<div className={"profile2__block__title"}>*/}
            {/*    <img src={"https://static.myshortlist.co/box.svg"}/>*/}
            {/*    <span>Le candidat a travaillé en: </span>*/}
            {/*</div>*/}
            {/*<ListCustomChoice update={dispatchExtendedProfile} keyName={"workedIn"} value={extendedProfile.workedIn}*/}
            {/*                  choices={["PME/Start-ups", "ETI", "Grands groupes", "Commercant/independant", "A son compte"]}/>*/}

        </section>

        <section className={"profile2__block"}>

                <div className={"profile2__block__title"}>
                    <span>Tchat</span>
                </div>

            <BlackBeltTchat blackBeltTchat={candidacy?.blackBeltTchat}
                            candidacyId={props.candidate?.candidacy?._id}
                            dispatchCandidacy={dispatchCandidacy}/>

        </section>

        {
            appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" &&
            <div className={'profile2__block'}>
                {props.candidate?.candidate?.videoUrl && <div>
                    <br/>
                    <br/>
                    <a href={props.candidate?.candidate?.videoUrl}>
                        <button>Télécharger la vidéo</button>
                    </a>
                    <button onClick={() => {
                        dispatchExtendedProfile({
                            type: "UPDATE",
                            value: {videoUrl: ""}
                        })
                    }}>
                        Supprimer la video
                    </button>
                    <br/>
                </div>}

                {!video.accepted &&
                    <div className={"text--center apply-V2__title"}>Insérer la vidéo de présentation</div>}
                {video.accepted && isSaving &&
                    <div className={"text--center apply-V2__title"}>Vidéo en cours d'envoi</div>}
                {video.accepted && isUploaded &&
                    <div className={"text--center apply-V2__title"}>Vidéo envoyée ! N'oublie pas de sauvegarder</div>}

                {isSaving && <LoadingStyle2/>}

                {
                    (!video.accepted) && <>
                        <br/>
                        <div className={"space-flex--center"}>
                            <FileDrop square={false} name={"file"} cb={updateVideo}/>
                        </div>
                    </>
                }

            </div>
        }

        <Spaces n={0}/>

        <div className={"text--center"}>
            <button className={'btn-secondary'} disabled={isLoading} onClick={() => saveExtendedProfile()}>
                Sauvegarder
            </button>
        </div>

        <Spaces n={2}/>

        <div className={"text--grey"}>
            Historique des MAJ:
            <br/>
            {props.candidate?.candidate?.extendedProfile?.updatesHist?.map((item: string) => <div
                className={"text--grey"}>
                {item}
            </div>)}
        </div>

        <Spaces n={2}/>

    </div>
}


