import {IAppContent} from "../../../../components/App/SingleApp";

const personalAppsList: IAppContent[] = [
  {
    name: 'personalFacebook',
    title: 'Facebook',
    iconUrl: "https://static.myshortlist.co/apps/facebook.svg",
    description: "Postez vos offres sur le facebook de l'entreprise",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'personalInstagram',
    title: 'Instagram',
    iconUrl: "https://static.myshortlist.co/apps/instagram.svg",
    description: "Postez vos offres sur l'instagram de l'entreprise",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'personalLinkedin',
    title: 'Linkedin',
    iconUrl: "https://static.myshortlist.co/apps/linkedin.svg",
    description: "Postez vos offres sur le linkedin de l'entreprise",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:true,

  }, {
    name: 'personalTwitter',
    title: 'Twitter',
    iconUrl: "https://static.myshortlist.co/apps/twitter.svg",
    description: "Postez vos offres sur le twitter de l'entreprise",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'personalCalendar',
    title: 'Google Calendar',
    iconUrl: "https://static.myshortlist.co/apps/calendar.svg",
    description: "Organisez vos meeting",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'personalCalendly',
    title: 'Calendly',
    iconUrl: "https://static.myshortlist.co/apps/calendly.png",
    description: "Organisez vos meeting",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'personalTeams',
    title: 'Teams',
    iconUrl: "https://static.myshortlist.co/apps/teams.svg",
    description: "Organisez vos meeting",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }
]

export {personalAppsList};