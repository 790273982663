import AppContext, {IAppContext, IAppV2Context} from "../../components/App/context/AppContext";
import React, {useContext, useEffect} from "react";
import {Link} from "react-router-dom";


export default function FourHundredFour(props: { page: string, fullPage: boolean, setPage: boolean }) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  useEffect(() => {
    if(props.setPage) {
      appContextV2.dispatchNavigation({type: "SET_PAGE", value: props.page});
    }
  }, []);
  
  return (
    <>
      <section className={`access-fail-40X__bg-1 ${props.fullPage ? "full-page" : "empty-page"}`}>
        <section className={"access-fail-40X"}>
          <article className={"access-fail-40X__number"}>
            404
          </article>
          <article className={"access-fail-40X__message"}>
            {language === "FR" && <>La page cherchée semble ne pas exister.<br/>N'hésitez pas à <Link to={"/contact"}>
              <button className={"btn--hollow modern-link"}>nous prévenir</button>
            </Link> si elle le devrait !</>}
            {language === "EN" && <>The page seems not to exist.<br/>Don't hesitate to <Link to={"/contact"}>
              <button className={"btn--hollow modern-link"}>tell us</button>
            </Link> if it should !</>}
          </article>
        </section>
      </section>
    </>
  );
}