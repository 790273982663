import React from "react";

export {ProfileElementBoolean};

const ProfileElementBoolean = (props: {
  text: string,
  value: any,
  description: string,
  updateData: Function,
  nameToUpdateFront: string,
  nameToUpdateBack: string,
}) => {
  
  const value = !!props.value;
  
  return(<div className={"profile-element__container"}>
    <div>
      {props.text}
      {!!props.description && <>
        <br/>
        <span className={"text--dark-grey text--minified1x"}>{props.description}</span>
      </>}
    </div>
    <div>
      <label className={"switch-V2"}>
        <input type={"checkbox"} className={"checkbox-on-off"} checked={value} onChange={() => {
          props.updateData(props.nameToUpdateFront, props.nameToUpdateBack, !value);
        }} />
        <span className="slider"> </span>
      </label>
    </div>
  </div>);
}