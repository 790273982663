import {Simulate} from "react-dom/test-utils";

export {isHighlighted};

const isHighlighted = (keyWords: unknown, inputValue: unknown) => {
  
  if(!keyWords) return false;
  if(!inputValue) return false;
  if(typeof inputValue !== "string") {
    return false;
  }
  if(typeof keyWords !== "string") {
    return false;
  }
  
  const bagOfKeywords = keyWords?.trim().toLowerCase().split(" ");
  
  for(let word of bagOfKeywords) {
    
    if(inputValue?.trim().toLowerCase().includes(word)) {
      return true;
    }
  }
  return false;
};