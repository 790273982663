import React, {useContext, useEffect, useReducer, useState} from "react";
import Spaces from "../../../../Spaces";
import LoadingStyle2 from "../../../../Loading/LoadingStyle2";
import AppContext, {IAppV2Context} from "../../../../../App/context/AppContext";
import axios from "axios";
import {notification} from "../../../../../../utils/notification";
import dataReducerCandidate
  from "../../../../FirstLoginAssistants/componentsFirstLoginAssistant/componentsFirstLoginCandidate/dataReducerCandidate";
import dataReducerCandidacy from "./dataReducerCandidacy";
import BlackBeltTchat from "../dependencies/BlackBeltTchat";
import {useForm} from "use-formidable";
import {FileDrop} from "../../../../Buttons/FileDrop";
import {format} from "date-fns";
import ButtonDeleteNew from "../../../../Buttons/ButtonDeleteNew";
import keepNumbers from "../../../../../../utils/keepNumbers";
import SaveButtonComponent from "../../../../SaveButtonComponent";
import {devLog} from "../../../../../../utils/devLog/devLog";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const saveCurrentSalary = async(salary: number) => {
    try {
      
      const answer = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/candidate/singleton`, {
        candidateId: props.inherited.candidateId,
        poolId: appContextV2.appNavigation.poolId,
        key: "currentSalary",
        value: salary,
      }, {
        timeout: 5000,
        withCredentials: true
      });
      if(answer.data.status !== "ok") {
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      notification(appContextV2.dispatchPanels, "Donnée mise à jour avec succès", "success");
    } catch(error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  const saveMinSalary = async(salary: number) => {
    try {
      (props.inherited.frontUpdateCandidacy ?? (() => {}))(props.candidate?.candidacy?._id, {
        defaultAnswers: {
          isWorking: props.candidate.candidacy.defaultAnswers?.isWorking,
          isAvailable: props.candidate.candidacy.defaultAnswers?.isAvailable,
          dateOfAvailability: props.candidate.candidacy.defaultAnswers?.dateOfAvailability,
          currentSalary: props.candidate.candidacy.defaultAnswers?.currentSalary,
          salaryMin: salary,
          yearsOfExperience: props.candidate.candidacy.defaultAnswers?.yearsOfExperience,
          training: props.candidate.candidacy.defaultAnswers?.training,
        }
      });
      const answer = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/candidate/singleton`, {
        candidateId: props.inherited.candidateId,
        poolId: appContextV2.appNavigation.poolId,
        key: "salaryMin",
        value: salary,
      }, {
        timeout: 5000,
        withCredentials: true
      });
      if(answer.data.status !== "ok") {
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      notification(appContextV2.dispatchPanels, "Donnée mise à jour avec succès", "success");
    } catch(error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      
    }
  }
  
  const saveAdminComment = async(adminComment: number) => {
    try {
      
      const answer = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/candidate/singleton`, {
        candidateId: props.inherited.candidateId,
        poolId: appContextV2.appNavigation.poolId,
        key: "adminComment",
        value: adminComment,
      }, {
        timeout: 5000,
        withCredentials: true
      });
      if(answer.data.status !== "ok") {
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      notification(appContextV2.dispatchPanels, "Donnée mise à jour avec succès", "success");
    } catch(error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      
    }
  }
  
  return (<>
    
    <div className={"gdpr-warning gdpr-warning--blue"}
         style={{maxWidth: "450px", margin: "auto", textAlign: "center"}}> Seul les admins de votre équipe ont accès à
      ces informations.
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center text--minified2x"}>Rémunération actuelle brute par an</div>
    <div className={"window-candidate-v2__salary--railenium"}>
      <input type={"text"} className={"window-candidate-v2__input"}
             value={/*displayNumber*/(props.candidate?.candidate?.currentSalary)} onChange={(e) => {
        if(e.target.value.length < 15) {
          props.updateCandidateFrontInWindow({currentSalary: keepNumbers(e.target.value)});
        }
      }}/> €
      <SaveButtonComponent onClick={() => saveCurrentSalary(props.candidate?.candidate?.currentSalary)}/>
    </div>
    
    <Spaces n={1}/>
    <div className={"text--center text--minified2x"}>Prétentions salariales brutes par an</div>
    <div className={"window-candidate-v2__salary--railenium"}>
      <input type={"text"} className={"window-candidate-v2__input"}
             value={/*displayNumber*/(props.candidate?.candidate?.salaryMin)} onChange={(e) => {
        if(e.target.value.length < 15) {
          props.updateCandidateFrontInWindow({salaryMin: keepNumbers(e.target.value)});
        }
      }}/> €
      <SaveButtonComponent onClick={() => saveMinSalary(props.candidate?.candidate?.salaryMin)}/>
    </div>
    
    <Spaces n={1}/>
    <div className={"text--center text--minified2x"}>Commentaire interne (visible par les admins)</div>
    <div className={"window-candidate-v2__salary--railenium"}>
            <textarea className={"comment__railenium"}
                      value={(props.candidate?.candidate?.adminComment)} onChange={(e) => {
              props.updateCandidateFrontInWindow({adminComment: e.target.value});
            }}/>
      <SaveButtonComponent onClick={() => saveAdminComment(props.candidate?.candidate?.adminComment)}/>
    </div>
  
  </>);
}
