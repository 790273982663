
export default (status: string) => {
  switch(status) {
    case 'published':
      return "Publiée";
    case 'draft':
      return "Brouillon";
    case 'collaboration':
      return "En collaboration"
    case 'success':
    case 'fail':
    case 'closed':
      return "Archivée";
    default:
      return status;
  }
}