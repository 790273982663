export default (args: {dragged: any, draggedToFormId: string, props: any}) => {
  
  let movedQuestion = {};
  
  if(args.dragged.draggedFromFormId === "form") {
    movedQuestion = [...args.props.form.form][args.dragged.draggedFromIndex];
  }
  if(args.dragged.draggedFromFormId === "form2") {
    movedQuestion = [...args.props.form.form2][args.dragged.draggedFromIndex];
  }
  
  if(args.draggedToFormId === "form2") {
    let newForm1 = [
      ...[...args.props.form.form].splice(0, args.dragged.draggedFromIndex),
      ...[...args.props.form.form].splice(args.dragged.draggedFromIndex + 1, args.props.form.form.length - 1 - args.dragged.draggedFromIndex)
    ];
    let newForm2 = [movedQuestion];
    args.props.setForm()({target: {type: "custom", name: "form", value: newForm1}});
    args.props.setForm()({target: {type: "custom", name: "form2", value: newForm2}});
  }
  if(args.draggedToFormId === "form") {
    let newForm2 = [
      ...[...args.props.form.form2].splice(0, args.dragged.draggedFromIndex),
      ...[...args.props.form.form2].splice(args.dragged.draggedFromIndex + 1, args.props.form.form2.length - 1 - args.dragged.draggedFromIndex)
    ];
    let newForm1 = [movedQuestion];
    args.props.setForm()({target: {type: "custom", name: "form", value: newForm1}});
    args.props.setForm()({target: {type: "custom", name: "form2", value: newForm2}});
  }
}