import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import FirstLoginRecruiterWelcome from "./componentsFirstLoginRecruiter/FirstLoginRecruiterWelcome";
import FirstLoginRecruiterPersonnal from "./componentsFirstLoginRecruiter/FirstLoginRecruiterPersonnal";
import FirstLoginRecruiterDictionaries from "./componentsFirstLoginRecruiter/FirstLoginRecruiterDictionaries";
import FirstLoginRecruiterFinal from "./componentsFirstLoginRecruiter/FirstLoginRecruiterFinal";
import FirstLoginLocation from "./sharedFirstLogin/FirstLoginLocation";
import {useWizard} from "use-wizard";
import {useForm} from "use-formidable";
import FirstLoginRecruiterBusinessAgency from "./componentsFirstLoginRecruiter/FirstLoginRecruiterBusinessAgency";
import FirstLoginRecruiterBusinessSize from "./componentsFirstLoginRecruiter/FirstLoginRecruiterBusinessSize";
import FirstLoginRecruiterBusinessInfos from "./componentsFirstLoginRecruiter/FirstLoginRecruiterBusinessInfos";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  /** Sets wizard */
  const [step, wizard] = useWizard(["welcome", "personalInfos", {
    "1": ["businessIsAgency", "businessSize", "businessInfos"],
  }, "dictionaries", "location", "post"]);
  
  /** Sets form */
  const [form, updateForm, formHandler] = useForm({
    user: {
      firstName: appContextV2.appSession.session.firstName || "",
      lastName: appContextV2.appSession.session.lastName || "",
      phone: "",
      phoneCountryCode:"",
      recruiterPosition: "",
    },
    business: {
      businessName: appContextV2.appSession.sessionBusiness.businessName || "Koala Cola",
      businessSize: "",
      isRecruitingAgency: false,
      sector: "",
      favouriteJobs: ["",],
      favouriteQualities: ["",],
      location: {
        number: "",
        street: "",
        city: "",
        state: "",
        postalCode: "",
        sup: "",
        country: "France",
        countryCode: "",
        longitude: "",
        latitude: "",
      },
    },
  });
  
  const assistantContext: any = {
    form: form,
    updateForm: updateForm,
    formHandler: formHandler,
    step: step,
    wizard: wizard,
  };
  
  switch(step) {
    
    case "welcome":
      return (<FirstLoginRecruiterWelcome assistantContext={assistantContext}/>);
    
    case "personalInfos":
      return (<FirstLoginRecruiterPersonnal assistantContext={assistantContext}/>);
    
    case "businessIsAgency":
      return (<FirstLoginRecruiterBusinessAgency assistantContext={assistantContext}/>);
    
    case "businessSize":
      return (<FirstLoginRecruiterBusinessSize assistantContext={assistantContext}/>);
    
    case "businessInfos":
      return (<FirstLoginRecruiterBusinessInfos assistantContext={assistantContext}/>);
    
    case "dictionaries":
      return (<FirstLoginRecruiterDictionaries assistantContext={assistantContext}/>);
    
    case 'location':
      return (<FirstLoginLocation assistantContext={assistantContext}/>);
    
    case 'post':
      return (<FirstLoginRecruiterFinal assistantContext={assistantContext}/>);
    
    default:
      return (<>Une erreur est survenue</>);
  }
  
}
