interface IState {
    positionWanted: string;
    contract: any;
    duration: number;
    isJobFullTime: boolean;
    yearsOfExperience: number;
    salaryMin: number;
    salaryMax: number;
    showSalary: boolean;
    remote: any;
    location: {
        city: string;
    };
    tas: string;
    kam: string;
    client: string;
    priority: string;
    description: string;
    category: string;
    missions:string[];
    profile:string[];
}

export default (previousState: any, action: { type: string, value: any }): any => {

    switch (action.type) {

        case 'INITIALIZE_DATA':
            return {
                ...action.value
            }
        case 'UPDATE_INFOS':
            return ({
                ...previousState,
                ...action.value,
            });

        case 'SWITCH':
            return {
                ...previousState,
                [action.value]: !previousState[action.value]
            }

        case 'UPDATE_LOCATION':
            return ({
                ...previousState,
                location: {
                    ...previousState.location,
                    ...action.value,
                }
            });

        case 'ADD_TO_MISSIONS':
            return {
                ...previousState,
                missions: [...previousState?.missions ?? [],
                    action.value]
            };




        case 'REMOVE_FROM_MISSIONS':
            return {
                ...previousState,
                missions: previousState.missions.filter((item: any) => item!== action.value)
            };

        case 'ADD_TO_PROFILE':
            return {
                ...previousState,
                profile: [...previousState.profile ?? [],
                    action.value]
            };


        case 'REMOVE_FROM_PROFILE':
            return {
                ...previousState,
                profile: previousState.profile.filter((item: any) => item!== action.value)
            };

        case 'ADD_TO_LANGUAGES':
            return {
                ...previousState,
                languages: [...previousState?.languages ?? [],
                    {value:action.value.value, level:action.value.level}]
            };

        case 'UP_MISSION' :
            return{
                ...previousState,
                missions: [
                    ...([...previousState.missions].slice(0, (action.value.key - 1))),
                    action.value.missionToCopy,
                    action.value.previousMissionToCopy,
                    ...([...previousState.missions].slice(action.value.key + 1, action.value.length))
                    ]
            }

        case 'DOWN_MISSION' :
        return{
        ...previousState,
            missions: [
            ...([...previousState.missions].slice(0, action.value.key)),
            action.value.nextMissionToCopy,
            action.value.missionToCopy,
            ...([...previousState.missions].slice(action.value.key + 2, action.value.length))
        ]
        }

        case 'UP_PROFILE' :
            return{
                ...previousState,
                profile: [
                    ...([...previousState.profile].slice(0, (action.value.key - 1))),
                    action.value.profileToCopy,
                    action.value.previousProfileToCopy,
                    ...([...previousState.profile].slice(action.value.key + 1, action.value.length))
                ]
            }

        case 'DOWN_PROFILE' :
            return{
                ...previousState,
                profile: [
                    ...([...previousState.profile].slice(0, action.value.key)),
                    action.value.nextProfileToCopy,
                    action.value.profileToCopy,
                    ...([...previousState.profile].slice(action.value.key + 2, action.value.length))
                ]
            }


        default:
            return previousState;

    }

}
