import React, {useContext} from "react";
import CloseButton from "../../../../../components/sharedComponents/Buttons/CloseButton";
import {devLog} from "../../../../../utils/devLog/devLog";
import AppContext, {IAppV2Context} from "../../../../../components/App/context/AppContext";
import {notification} from "../../../../../utils/notification";
import axios from "axios";

export {ProfileEditorColumnElementV2};

const ProfileEditorColumnElementV2 = (props: {
  item: any,
  key: number,
  positionKey: "column1" | "column2" | "column3",
  updateProfileFront: Function,
  drop: Function,
  drag: Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const deleteElement = (mediaId: string, column: string) => {
    (async() => {
      try {
        
        notification(appContextV2.dispatchPanels, "Suppression en cours", "information");
        
        const dataToSend: any = {
          key: props.positionKey,
          itemId: props.item?._id
        };
        
        const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/bm/business/media/v2/remove`,
          dataToSend, {withCredentials: true});
        
        if(fetched.data.status !== "ok") {
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        
        notification(appContextV2.dispatchPanels, "Elément supprimé", "success");
        
        // Update front
        props.updateProfileFront(`delete${props.positionKey[props.positionKey.length - 1]}`, props.item?._id);
        
      } catch (error) {
        devLog(error);
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    })();
  }
  
  switch(props.item?.type) {
    
    case 'image':
      return (<article className={"profile-editor-V2__columns__element"}
                       draggable={true}
                       onDragStart={(e) => {
                         e.dataTransfer.setData('text/plain', '');
                         props.drag(props.positionKey, props.item?._id);
                       }}
                       onDragOver={(e) => {e.preventDefault();}}
                       onDrop={() => {props.drop(props.positionKey, props.item._id)}}>
        <img className={"element-content--image"} src={props.item.url}/>
        <div className={"element-content--delete"}><CloseButton eventOnClick={() => {
          deleteElement(props.item._id, props.positionKey);
        }}/></div>
      </article>);
    
    case 'imagePortrait':
      return (<article className={"profile-editor-V2__columns__element--tall"}
                       draggable={true}
                       onDragStart={(e) => {
                         e.dataTransfer.setData('text/plain', '');
                         props.drag(props.positionKey, props.item?._id);
                       }}
                       onDragOver={(e) => {e.preventDefault();}}
                       onDrop={() => {props.drop(props.positionKey, props.item._id)}}>
        <img className={"element-content--image"} src={props.item.url}/>
        <div className={"element-content--delete"}><CloseButton eventOnClick={() => {
          deleteElement(props.item._id, props.positionKey);
        }}/></div>
      </article>);
    
    case 'video':
      return (<article className={"profile-editor-V2__columns__element"}
                       draggable={true}
                       onDragStart={(e) => {
                         e.dataTransfer.setData('text/plain', '');
                         props.drag(props.positionKey, props.item?._id);
                       }}
                       onDragOver={(e) => {e.preventDefault();}}
                       onDrop={() => {props.drop(props.positionKey, props.item._id)}}>
        <div className={"element-content--video"}>
          <iframe width={"100%"} height={"100%"} src={`https://www.youtube.com/embed/${props.item.url}`} frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div className={"element-content--delete"}><CloseButton eventOnClick={() => {
          deleteElement(props.item._id, props.positionKey);
        }}/></div>
      </article>);
    
    case 'freeText':
      return (<article className={"profile-editor-V2__columns__element--flexible"}
                       draggable={true}
                       onDragStart={(e) => {
                         e.dataTransfer.setData('text/plain', '');
                         props.drag(props.positionKey, props.item?._id);
                       }}
                       onDragOver={(e) => {e.preventDefault();}}
                       onDrop={() => {props.drop(props.positionKey, props.item._id)}}
      >
        <div className={"element-content--text"}>
          {!!props.item.textContent.title && <div className={"element-content--text__title"}>{props.item.textContent.title}</div>}
          {!!props.item.textContent.content && <div className={"element-content--text__content"}>{props.item.textContent.content}</div>}
        </div>
        <div className={"element-content--delete"}><CloseButton eventOnClick={() => {
          deleteElement(props.item._id, props.positionKey);
        }}/></div>
      </article>);
    
    case 'table':
    case 'map':
    case 'link':
    case 'vote':
    default:
      return (<></>);
  }
  
}
