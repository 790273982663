import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../../components/App/context/AppContext";
import Spaces from "../../../../../../components/sharedComponents/Spaces";
import BPAV3DQIsWorking from "./BPAV3DQIsWorking";

export default (props: any) => {
  
  const [showQuestion, setShowQuestion] = useState(false);
  const [subStep, setSubStep] = useState(1);
  
  useEffect(() => {
    (() => {
      setShowQuestion(props.mission.defaultQuestions?.isAvailable);
      if(!props.mission.defaultQuestions?.isAvailable) {
        props.nextStep();
      }
    })();
  }, []);
  
  if(!showQuestion) {
    return (<><Spaces n={15}/></>)
  }
  
  switch(subStep) {
    
    case 1:
      return (
          <>
            <Spaces n={2}/>
          <div className={"apply-container"}>
        


        {!props.mission.modernTone && <div className={"assistant--v2-title"}>
          Êtes-vous disponible actuellement pour un nouveau challenge ?
        </div>}
        {props.mission.modernTone && <div className={"assistant--v2-title"}>
          Es-tu disponible actuellement pour un nouveau challenge ?
        </div>}
        <Spaces n={0}/>

        {!props.mission.modernTone && <div className={"text--grey text--center"}>
          Si vous êtes en CDI, sélectionnez "Non" et entrez votre date de disponibilité potentielle.
        </div>}
        {props.mission.modernTone && <div className={"text--grey text--center"}>
          Si tu es en CDI, sélectionne "Non" et entre ta date de disponibilité potentielle.
        </div>}
        <Spaces n={2}/>
        
        <section className={"assistant--v2-container"}>
          <button className={"btn-assistant-main"} onClick={() => {
            props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.isAvailable", value: true}});
            props.nextStep();
          }}> Oui
          </button>
          <button className={"btn-assistant-main"} onClick={() => {
            props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.isAvailable", value: false}});
            setSubStep(2);
          }}> Non
          </button>
        </section>
      </div>
            </>);
    
    case 2:
      return (<>
        <Spaces n={2}/>
        <div className={"apply-container"}>

        {!props.mission.modernTone && <div className={"assistant--v2-title"}>À quelle date serez-vous approximativement disponible ?</div>}
        {props.mission.modernTone && <div className={"assistant--v2-title"}>À quelle date seras-tu approximativement disponible ?</div>}
        <Spaces n={0}/>
        <section className={"assistant--v2-container--no-flex text--center assistant--v2--appear"}>
          <input type={"date"} className={"assistant--v2-input assistant--v2-input--full-width"}
                 value={props.answer.defaultAnswers.dateOfAvailability}
                 onChange={(e) => {
                   props.updateAnswer()({
                     target: {
                       type: "custom",
                       name: "defaultAnswers.dateOfAvailability",
                       value: e.target.value
                     }
                   });
            
                 }}/>
          <Spaces n={2}/>
          <button disabled={!props.answer.defaultAnswers.dateOfAvailability} className={"btn-main"}
                  onClick={() => { props.nextStep();}}>
            Valider ma réponse
          </button>
        </section>
      
      </div>
        </>);
    
    default:
      return (<></>);
  }
  
}