import React from "react";
import Spaces from "../../../../components/sharedComponents/Spaces";
import LoadingStyle2 from "../../../../components/sharedComponents/Loading/LoadingStyle2";

export default (props: {
  evaluation: {
    comment: string,
    appreciation: number,
  },
  setEvaluation: any,
  evaluationStep: any,
  setEvaluationStep: any,
  sendStatus: any,
  sendEvaluation: any

}) => {
  
  const isButtonNotActive = props.evaluation?.comment?.length === 0 || props.evaluation?.appreciation === 0;
  
  return (<>
    
    <div className={"text--center"}>Cliquez sur l'emoji qui représente le mieux votre sentient
      sur l'adéquation du candidat avec le poste:</div>
    
    <Spaces n={1}/>
    
    <div className={"evaluation"}>
      <button className={`evaluation--button ${props.evaluation.appreciation === 1 ? "evaluation--button--selected" : ""}`} title={'Négatif'} onClick={() => {
        // props.setEvaluationStep(2);
        props.setEvaluation((ps: any) => ({
          ...ps,
          appreciation: 1
        }));
      }}>
        <img className={"evaluation--btn--img"}
             src={"https://static.myshortlist.co/img-rating-1.png"}/>
      </button>

        <button className={`evaluation--button ${props.evaluation.appreciation === 2 ? "evaluation--button--selected" : ""}`} title={'Plutôt négatif'} onClick={() => {
          // props.setEvaluationStep(2);
          props.setEvaluation((ps: any) => ({
            ...ps,
            appreciation: 2
          }));
        }}>
          <img className={"evaluation--btn--img"}
               src={"https://static.myshortlist.co/img-rating-2.png"}/>
        </button>

      <button className={`evaluation--button ${props.evaluation.appreciation === 3 ? "evaluation--button--selected" : ""}`} title={'Je ne sais pas'} onClick={() => {
        // props.setEvaluationStep(2);
        props.setEvaluation((ps: any) => ({
          ...ps,
          appreciation: 3
        }));
      }}>
        <img className={"evaluation--btn--img"}
             src={"https://static.myshortlist.co/img-rating-3.png"}/>
      </button>
      
      <button className={`evaluation--button ${props.evaluation.appreciation === 4 ? "evaluation--button--selected" : ""}`} title={'Plutôt positif'} onClick={() => {
        // props.setEvaluationStep(2);
        props.setEvaluation((ps: any) => ({
          ...ps,
          appreciation: 4
        }));
      }}>
        <img className={"evaluation--btn--img"}
             src={"https://static.myshortlist.co/img-rating-4.png"}/>
      </button>


      <button className={`evaluation--button ${props.evaluation.appreciation === 5 ? "evaluation--button--selected" : ""}`} title={'Positif'} onClick={() => {
        // props.setEvaluationStep(2);
        props.setEvaluation((ps: any) => ({
          ...ps,
          appreciation: 5
        }));
      }}>
        <img className={"evaluation--btn--img"}
             src={"https://static.myshortlist.co/img-rating-5.png"}/>
      </button>
    </div>
    
    {/*//////////////////////////////// V2*/}
    
    <Spaces n={1}/>
  
    <div className={"text--center"}>Laissez un commentaire pour le recruteur</div>
  
    <Spaces n={0}/>
    <div className={"text--center"}>
    
    <textarea className={"evaluation__comment"} placeholder={""}
              value={props.evaluation?.comment}
              onChange={(e) => {
                if(e.target.value.length < 700) {
                  const newValue = e.target.value;
                  props.setEvaluation((ps: any) => ({
                    ...ps,
                    comment: newValue,
                  }))
                }
              }}>
        </textarea>
      <div className={"text--grey text--magnified11x"}>{props.evaluation?.comment?.length}/700</div>
    
      <Spaces n={0}/>
    
      <button className={"btn-secondary"} onClick={() => props.sendEvaluation()}
              disabled={isButtonNotActive}> Envoyer
      </button>
  
    </div>
  
    {props.evaluationStep === 2 && props.sendStatus === "fetching" && <LoadingStyle2/>}
    
  </>);
}