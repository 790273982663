import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import {Link} from "react-router-dom";


export default function MenuPublicDropDownContent(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  return (<>
      
      {/*<li>*/}
      {/*  <Link to={"/prices"}>*/}
      {/*    <button className={`btn--hollow ${appContextV2.appNavigation.page === "Prices" ? "selected" : ""}`} onClick={()=>{*/}
      {/*      appContextV2.dispatchPanels({type: "CLOSE_MENU_FROM_BOTTOM", value: {type: "", data: {},}});*/}
      {/*    }}>*/}
      {/*      {language === "FR" && <>Tarifs</>}*/}
      {/*      {language === "EN" && <>Prices</>}*/}
      {/*    </button>*/}
      {/*  </Link>*/}
      {/*</li>*/}
      
      {/*<li>*/}
      {/*  <Link to={"/presentation"}>*/}
      {/*    <button className={`btn--hollow ${appContext.appState.page === "Presentation" ? "selected" : ""}`} onClick={()=>appContext.toggleMenuFromBottomVisibility("close")}>*/}
      {/*      {language === "FR" && <>Présentation</>}*/}
      {/*      {language === "EN" && <>Presentation</>}*/}
      {/*    </button>*/}
      {/*  </Link>*/}
      {/*</li>*/}

      <li>
        <button className={"btn--hollow "} onClick={()=>{
          appContextV2.dispatchPanels({type: "CLOSE_MENU_FROM_BOTTOM", value: {type: "", data: {},}});
          appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {type: "login", data: {},}});
        }}>
          {language === "FR" && <>Connexion</>}
          {language === "EN" && <>Login</>}
        </button>
      </li>
    
    </>
  );
}