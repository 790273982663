export default [
  {
    name: "facebook",
    title: "Facebook entreprise",
    iconUrl: "https://static.myshortlist.co/apps/facebook.svg",
    isAvailable: false,
    isActive: false,
    isPosted: false,
  },{
    name: "twitter",
    title: "Twitter entreprise",
    iconUrl: 'https://static.myshortlist.co/apps/twitter.svg',
    isAvailable: false,
    isActive: false,
    isPosted: false,
  },{
    name: "linkedin",
    title: "LinkedIn entreprise",
    iconUrl: "https://static.myshortlist.co/apps/linkedin.svg",
    isAvailable: false,
    isActive: false,
    isPosted: false,
  },{
    name: "instagram",
    title: "Instagram entreprise",
    iconUrl: 'https://static.myshortlist.co/apps/instagram.svg',
    isAvailable: false,
    isActive: false,
    isPosted: false,
  },{
    name: "welcome",
    title: "Welcome to the jungle",
    iconUrl: 'https://static.myshortlist.co/apps/welcome.jpg',
    isAvailable: false,
    isActive: false,
    isPosted: false,
  },
]