export default(state:string)=>{
  switch(state){
    case'FR.BNO':
      return 'Basse Normandie';
    case 'FR.ALS':
      return 'Alsace';
    case 'FR.AQU':
      return 'Aquitaine';
    case 'FR.BRE':
      return 'Bretagne';
    case 'FR.AUV':
      return 'Auvergne';
    case 'FR.BOU':
      return 'Bourgogne';
    case 'FR.CHA':
      return 'Champagne-Ardennes';
    case 'FR.CEN':
      return 'Centre';
    case 'FR.COR':
      return 'Corse';
    case 'FR.FCO':
      return 'Franche-Comté';
    case 'FR.HNO':
      return 'Haute Normandie';
    case 'FR.IDF':
      return 'Ile-de-France';
    case 'FR.LAN':
      return 'Languedoc-Roussillon';
    case 'FR.LIM':
      return 'Limousin';
    case 'FR.PLO':
      return 'Pays de la Loire';
    case 'FR.LOR':
      return 'Lorraine';
    case 'FR.MIP':
      return 'Midi-Pyrénées';
    case 'FR.NPC':
      return 'Nord-Pas-de-Calais';
    case 'FR.PIC':
      return 'Picardie';
    case 'FR.PCH':
      return 'Poitou-Charentes';
    case 'FR.PACA':
      return 'Provence-Alpes-Côte-d\'Azur';
    case 'FR.RHA':
      return 'Rhône-Alpes';
    case 'FR.DT.GP':
      return 'Guadeloupe';
    case 'FR.DT.MQ':
      return 'Martinique';
    case 'FR.DT.RE':
      return 'La Réunion';
    case 'FR.DT.NC':
      return 'Nouvelle-Calédonie';
    case 'FR.DT.GF':
      return 'Guyane';
    case 'FR.DT.YT':
      return 'Mayotte';
    case 'FR.DT.PF':
      return 'Polynésie française';
    default:
      return'region non valide'


  }
}