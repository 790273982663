import AppContext, {IAppV2Context} from "../../../../../../App/context/AppContext";
import React, {useContext, useState} from "react";
import Spaces from "../../../../../Spaces";

export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const [skill, setSkill]: any = useState();


    return (<>
        <div className={"black-belt-window__container--mini"}>
            Competences:
            <br/><br/>
            {props.infos.skills?.map((item: any,key:number) => (<>
                <div><span className={"black-belt-window__title"}>Competence:</span>
                    <input className={"black-belt-window__input"} value={item.skill}
                           onChange={(e) => {
                               props.dispatchInfos({
                                   type: "UPDATE_ARRAY",
                                   value: {field:"skills",index:key, subfield:"skill" ,value: e.target.value}
                               })
                           }}/>
                </div>
                {item.subSkills.map((subItem: any,subKey:number) => (<>  <div><span className={"black-belt-window__title"}>Sous-Competence:</span>
                    <input className={"black-belt-window__input"} value={subItem.subSkill}
                           onChange={(e) => {
                               props.dispatchInfos({
                                   type: "UPDATE_SUB_ARRAY",
                                   value: {field:"skills",index:key, subfield:"skill" , subIndex:subKey,value: e.target.value}
                               })
                           }}/>


                </div> </>))}

                <Spaces n={1}/>
                <button  className={"message--v2__chat__button"} onClick={()=>{
                    props.dispatchInfos({
                        type: "REMOVE_ELEMENT_FROM_ARRAY",
                        value: {field:"languages",index:key}
                    })
                }}> Supprimer </button>
                <Spaces n={1}/>
            </>))}


            <div><span className={"black-belt-window__title"}>Competence:</span>
                <input className={"black-belt-window__input"} value={skill}
                       onChange={(e) => {
                          setSkill(e.target.value)
                       }}/>
            </div>

            <Spaces n={1}/>

            <button  className={"message--v2__chat__button"} onClick={()=>{
                props.dispatchInfos({
                    type: "ADD_ELEMENT_TO_ARRAY",
                    value: {field:"skills",
                        value:{
                            skill:skill,
                        }}
                })
                setSkill("");
            }}> Ajouter </button>
            <Spaces n={1}/>
        </div>


    </>);
}