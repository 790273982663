
import AppContext, {IAppContext, IAppV2Context} from "../../../../App/context/AppContext";
import React, {useContext} from "react";
import {Link} from "react-router-dom";


export default function SubNavLinkGenerator(props: {
  active: boolean,
  uri: string,
  fr: string,
  en: string,
  alt: string,
  icon: string,
  smaller: boolean,
}) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  return (
    <Link to={props.uri}>
      <li className={`subnavbar-new__item subnavbar-new__item--decorated ${props.active ? "subnavbar-new__item--active selected" : ""}`}>
        <span className={`hide-on-smartphones`}>
          {language === 'FR' && <>{props.fr}</>}
          {language !== 'FR' && <>{props.en}</>}
        </span>
        <span className={"hide-on-computers"}>
          <img className={`navbar-secondary-btn-image ${props.smaller ? "navbar-secondary-btn-image--smaller" : ""}`} src={props.icon} alt={props.alt}/>
        </span>
      </li>
    </Link>
  );
}
