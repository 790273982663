import AppContext, {IAppContext, IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import {Link} from "react-router-dom";


export default function NavBarLogoContent(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  const displayGreyLogo = appContextV2.appNavigation.page === 'LandingPage' || appContextV2.appSession.app.space !== 'public';
  
  return (
    <>
      <span className={"navbar__logo"}>
        <Link to={"/"}>
          <button className={"btn--hollow"}>
            <span className={`navbar__logo__image ${displayGreyLogo ? "navbar__logo__image--grey" : ""}`}>
              <span
                className={`navbar__logo__image__content ${displayGreyLogo ? "navbar__logo__image__content--red" : ""}`}>ms</span>
            </span>
          </button>
        </Link>
        {appContextV2.appSession.app.space === "public" &&
        <span className={`navbar__logo__text hide-on-smartphones ${appContextV2.appNavigation.page === 'LandingPage' ? "navbar__logo__text--hidden" : ""}`}>
          myshortlist.co
          </span>
        }
        {/*{*/}
        {/*  appContextV2.appSession.app.space === "recruiter" && <p className={"text--hollow navbar__logo__text--grey hide-on-smartphones"}>*/}
        {/*    {language === "FR" && <>Espace entreprise</>}*/}
        {/*    {language === "EN" && <>Business space</>}*/}
        {/*  </p>*/}
        {/*}*/}
        {/*{*/}
        {/*  appContextV2.appSession.app.space === "candidate" && <p className={"text--hollow navbar__logo__text--grey hide-on-smartphones"}>*/}
        {/*    {language === "FR" && <>Espace candidat</>}*/}
        {/*    {language === "EN" && <>Candidate space</>}*/}
        {/*  </p>*/}
        {/*}*/}
      </span>
    </>
  );
}
