import React from "react";
export {ButtonBack};

const ButtonBack = (props: {back: Function}) => {
  
  return(
  <button className={"btn--hollow"} onClick={()=>props.back()}>
    <img className={"navbar-secondary-btn-image--smaller text--hollow"} src={"https://static.myshortlist.co/btn-back.svg"}
         alt={"research"}/>
  </button>);
}