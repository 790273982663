import React, {useContext, useState} from "react";
import AppContext, {IAppV2Context} from "../App/context/AppContext";
import WindowWrapper from "../../layouts/WindowLayout";
import axios from "axios";
import {notification} from "../../utils/notification";

export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const [businessToAdd,setBusinessToAdd]=useState("");

    return(<WindowWrapper size={"big"} status={"ok"} windowTitle={""}>

        <h1>Ajouter un business</h1>
        <input type={"text"} className={"assistant--v2-input assistant--v2-input--center"}
               value={businessToAdd} onChange={(e)=>setBusinessToAdd(e.target.value)}/>
        <button className={"btn-main"} onClick={async()=>{
            try {
                const {status, data} = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/client`,
                    {
                        name:businessToAdd.trim(),
                    },{
                    withCredentials: true
                })).data;
                if(status==="ok"){
                    appContextV2.appPanels?.window?.windowOptions?.data?.dispatchBusinesses({type: "ADD", value: data});
                    return notification(appContextV2.dispatchPanels, "BusinessAjoute", "success");
                }
            } catch(error) {
                console.log(error);
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        }}> Save </button>


    </WindowWrapper>);
}