import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../components/sharedComponents/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Quelle est l'expérience minimum requise pour le poste ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container"}>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "yearsOfExperience", value: 0}});
                props.wizard.nextStep();
              }}>
        Aucune condition
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "yearsOfExperience", value: 1}});
                props.wizard.nextStep();
              }}>
        + de 1 an
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "yearsOfExperience", value: 3}});
                props.wizard.nextStep();
              }}>
        + de 3 ans
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "yearsOfExperience", value: 6}});
                props.wizard.nextStep();
              }}>
        + de 6 ans
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "yearsOfExperience", value: 10}});
                props.wizard.nextStep();
              }}>
        + de 10 ans
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "yearsOfExperience", value: 15}});
                props.wizard.nextStep();
              }}>
        + de 15 ans
      </button>
    
    </div>
  
    <Spaces n={1}/>
  
    <div className={"text--center text--grey assistant--v2-container--no-flex"}>
      Barème indicatif: "Aucune condition": sans expérience ou jeune diplomé·e, "Junior": plus de 1 an d'expérience, "Confirmé": plus de trois ans d'expérience, "Confirmé +": plus de 6 ans d'expérience, "Senior": plus de 10 ans d'expérience, "Senior +": plus de 15 ans d'expérience.
    </div>
  
  </section>);
}