type TState = {
  [key: string]: any,
};

export default (previousState: TState, action: { type: string, value: any }): any => {
  
  switch(action.type) {
    
    case 'INITIALIZE_DATA':
      return ({...action.value});
    
    case 'UPDATE_AMBASSADOR':
      return ({
        ...previousState,
        ...action.value,
      });
    
    case 'UPDATE_AMBASSADOR_PHONE_COUNTRY_CODE':
      return ({
        ...previousState,
        phone: {
          ...previousState.phone,
          countryCode: action.value,
        }
      });
    
    case 'UPDATE_AMBASSADOR_PHONE_VALUE':
      return ({
        ...previousState,
        phone: {
          ...previousState.phone,
          value: action.value,
        }
      });
    
    case  'ADD_AMBASSADOR_TAGS':
      return ({
        ...previousState,
        tags: [...(previousState.tags ?? []),
          action.value]
      });
    
    case  'DELETE_AMBASSADOR_TAGS':
      return ({
        ...previousState,
        tags: [...previousState.tags.filter((item: any) => (item !== action.value))]
      });
    
    default:
      return previousState;
  }
  
}