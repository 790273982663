import {devLog} from "../../utils/devLog/devLog";
import React, {useEffect, useState} from "react";
import PageLayout from "../../layouts/PageLayout";
import Spaces from "../../components/sharedComponents/Spaces";
import axios from "axios";
import ECCandidateDetails from "./componentsEvaluateCandidate/ECCandidateDetails";
import ECHeader from "./componentsEvaluateCandidate/ECHeader";
import ECEvaluation from "./componentsEvaluateCandidate/ECEvaluation";
import ECNavBar from "./componentsEvaluateCandidate/ECNavBar";
import ECSwitchCandidate from "./componentsEvaluateCandidate/ECSwitchCandidate";
import {Link} from "react-router-dom";
import ECEvaluationDone from "./componentsEvaluateCandidate/ECEvaluationDone";

export {EvaluateCandidate};

const EvaluateCandidate = (props: any) => {
  
  const token: string = props.match.params.token || "";
  
  const [candidatesToEvaluate, setCandidatesToEvaluate]: any = useState({});

  const [fetchedStatus, setFetchedStatus]: ["asleep" | "fetching" | "fetched" | "forbidden" | "error", Function] = useState("asleep");
  const [candidateIndex, setCandidateIndex] = useState(0);
  const currentCandidate = (candidatesToEvaluate?.data?.candidatesToEvaluate|| [])[candidateIndex] ?? {};
  const [evaluationStep, setEvaluationStep] = useState(1);
  const [evaluation, setEvaluation] = useState<{
    appreciation: number,
    comment: string
  }>({
    appreciation:0,
    comment: ""
  });
  
  const [tab, setTab] = useState<"cv" | "profile" | "candidacy" | "trigram">(currentCandidate.candidacy?.imported ? "cv" : "cv");
  
  
  const resetEvaluation = () => {
    setEvaluationStep(1);
    setEvaluation({appreciation: 0, comment: ""});
  }
  
  useEffect(() => {
    (async() => {
      try {
        setFetchedStatus("fetching");
        const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/jobs/mat/cam/evaluate/candidate`, {
          headers: {
            token: token
          },
          withCredentials: true
        });
        setCandidatesToEvaluate(fetched?.data);
        setFetchedStatus("fetched");
        
      } catch(error) {
        setFetchedStatus("error");
        devLog(error);
      }
    })();
  }, []);
  
  useEffect(() => {
    resetEvaluation();
    setTab(currentCandidate.candidacy?.imported ? "cv" : "cv");
  }, [candidateIndex]);
  
  if(candidatesToEvaluate?.status === "noCandidate" || candidatesToEvaluate?.status === "noToken") {
    return (<PageLayout page={props.page} status={"ok"}>
      <Spaces n={14}/>
      <div className={"text--magnified11x text--center"}>Le lien ne semble pas valide</div>
      <Spaces n={8}/>
    </PageLayout>);
  }
  
  if(candidatesToEvaluate?.data?.candidatesToEvaluate?.length === 0) {
    return (<PageLayout page={props.page} status={"ok"}>
      <Spaces n={14}/>
      <div className={"text--magnified11x text--center"}>Vous n'avez pas d'autre évaluation en attente, vous pouvez
        fermer cette page.
      </div>
      <Spaces n={8}/>
    </PageLayout>);
  }
  
  return (<PageLayout fullWidth={true} grey={true} page={props.page} status={fetchedStatus}>
    
    <Spaces n={3}/>
    
    {candidatesToEvaluate.data?.candidatesToEvaluate[candidateIndex].status === "pending" && <ECSwitchCandidate candidateIndex={candidateIndex}
                       numberOfCandidates={candidatesToEvaluate.data?.candidatesToEvaluate?.length ?? 0}
                       setCandidateIndex={setCandidateIndex}/>}

    { candidatesToEvaluate.data?.candidatesToEvaluate[candidateIndex].status === "done" && <div className={"space-flex--center"}>
      <h1 className={"profile-edit__title"}>Candidat déjà évalué</h1>
    </div>}
  
    { candidatesToEvaluate.data?.candidatesToEvaluate[candidateIndex].status === "pending" && <div className={"text--center candidates-evaluations-names"}>
      {candidatesToEvaluate.data?.candidatesToEvaluate.map((item: any, key: number) => (<div key={key} className={"candidates-evaluations-name"}>
        {item.candidate.firstName}
      </div>))}
    </div>}
    
    <Spaces n={5}/>

    <div className={"space-flex--center"}>
    <Link to={`/recruiter/action/allEvaluations/${token}`}>
    <button className={"btn-secondary btn--upper"}>
      Mes évaluations
    </button>
    </Link>
    </div>


    <Spaces n={0}/>
    
    <ECHeader candidate={currentCandidate}
              candidateIndex={candidateIndex}
              positionWanted={currentCandidate?.mission?.positionWanted ?? ""}/>
    
    {/*<ECJobDetails answer={candidatesToEvaluate}*/}
    {/*              candidateIndex={candidateIndex}/>*/}
    
    <Spaces n={0}/>
    
    <ECNavBar tab={tab}
              setTab={setTab}
              candidacyImported={currentCandidate?.candidacy?.imported}
    />
    
    <ECCandidateDetails candidateToEvaluate={currentCandidate}
                        tab={tab}/>
    
    <Spaces n={0}/>

    {candidatesToEvaluate.data?.candidatesToEvaluate[candidateIndex].status === "pending" &&  <ECEvaluation evaluation={evaluation}
                  evaluationStep={evaluationStep}
                  setEvaluation={setEvaluation}
                  setEvaluationStep={setEvaluationStep}
                  candidateToEvaluate={currentCandidate}
                  setCandidatesToEvaluate={setCandidatesToEvaluate}
                  resetEvaluation={resetEvaluation}
                  setCandidateIndex={setCandidateIndex}
    />}

    {candidatesToEvaluate.data?.candidatesToEvaluate[candidateIndex].status === "done" &&  <ECEvaluationDone
        candidateToEvaluate={currentCandidate}/>}
    
    <Spaces n={0}/>
  
  </PageLayout>);
  
}

