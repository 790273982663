import React from "react";

export default function(props: {
    item: any,
    index: any,
    update: any,
    setCurrentExperience: any,
    setEditKey: any
    editKey: any
}) {

    return <div className={'profile2-experience'}>
        <div className={"profile2-experience__position-bloc"}>
            <div className={'profile2-experience__position'}>
                {props.item.position}
            </div>

            <div>
                <button disabled={props.editKey !== -1} className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                    props.update({
                        type: 'MOVE_EXP_UP',
                        value: props.index
                    })
                }}>up
                </button>
                <button disabled={props.editKey !== -1} className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                    props.update({
                        type: 'MOVE_EXP_DOWN',
                        value: props.index
                    })
                }}>down
                </button>
                <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                    props.setCurrentExperience(props.item);
                    // props.update({
                    //     type: "DELETE_EXPERIENCE",
                    //     value: props.item
                    // })
                    // props.setEditor(true)
                    props.setEditKey(props.index);
                }}>editer
                </button>
                <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                    props.update({
                        type: "DELETE_EXPERIENCE",
                        value: props.item
                    });
                }}>suppr
                </button>
            </div>
        </div>
        {props.item.company && <div className={"profile2-experience__company"}>Chez {props.item.company}</div>}
        {props.item.companyDescription &&
        <div className={"profile2-experience__description"}>{props.item.companyDescription}</div>}
        {props.item.candidateMissions && props.item.candidateMissions.length !== 0 &&
        <div className={"profile2-experience__missions"}>
            <div className={'profile2-experience__missions__title'}>
                Missions de la candidate / du candidat
            </div>
            <ul>
                {props.item.candidateMissions.map((m: string) => (<li>
                    {m}
                </li>))}
            </ul>
        </div>}
        {props.item.keys && props.item.keys.length !== 0 && <div className={"profile2-experience__missions"}>
            <div className={'profile2-experience__missions__title'}>
                Éléments clés & Résultats
            </div>
            <ul>
                {props.item.keys.map((m: string) => (<li>
                    {m}
                </li>))}
            </ul>
        </div>}
    </div>
}