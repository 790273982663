import React, {useState} from "react";
import Spaces from "../../../Spaces";

export default (props: {
  fieldToUpdate: string,
  modifier: Function,
  mission: any,
}) => {
  
  const [remote, setRemote] = useState<"yes" | "no">(props.mission?.remote ? props.mission?.remote : "no");
  const [roaming, setRoaming] = useState(!!props.mission?.roaming);
  const [roamingPlace, setRoamingPlace] = useState(props.mission?.roamingPlace);
  const [country, setCountry] = useState(props.mission?.location?.country || "");
  const [state, setState] = useState(props.mission?.location?.state || "");
  const [postalCode, setPostalCode] = useState(props.mission?.location?.postalCode || "");
  const [city, setCity] = useState(props.mission?.location?.city || "");
  const [street, setStreet] = useState(props.mission?.location?.street || "");
  
  return (<>
    
    <div className={"white-container white-container--grey"}>
      
      <input type={"checkbox"} checked={remote === "yes"} onClick={() => {
        if(remote === "yes") props.modifier("remote", "no");
        if(remote === "no") props.modifier("remote", "yes");
        setRemote((ps: "yes" | "no") => {
          if(ps === "yes") return "no";
          return "yes";
        });
      }}/> Le poste est en télétravail
      
      {
        remote === "no" && <>
          <Spaces n={0}/>
          <input type={"checkbox"} checked={roaming} onClick={() => {
            props.modifier("roaming", !roaming);
            setRoaming((ps: boolean) => !ps);
          }}/> Le poste est en itinérance ou sur plusieurs sites
        </>
      }
      
      {
        remote === "no" && roaming && <>
          <Spaces n={0}/>
          <label>
            <span className={"text--minified2x"}>Préciser le lieu du poste</span><br/>
            <input type={"text"} className={"input--v2"} value={roamingPlace} onChange={(e) => {
              if(e.target.value.length < 200) {
                props.modifier("roamingPlace", e.target.value);
                setRoamingPlace(e.target.value);
              }
            }}/>
          </label>
        </>
      }
      
      {
        remote === "no" && !roaming && <>
          
          {/* ------------------------------------ Location -- */}
          
          <Spaces n={0}/>

          <label>
            <span className={"text--minified2x"}>Pays</span><br/>
            <input type={"text"} className={"input--v2"} value={country} onChange={(e) => {
              if(e.target.value.length < 200) {
                props.modifier("location.country", e.target.value);
                setCountry(e.target?.value);
              }
            }}/>
          </label>

          <Spaces n={0}/>

            <label className={"text--minified3x"}>Région<br/>
                <select aria-hidden={true} className={"assistant--v2-input assistant--v2-input--center"} value={state}
                        onChange={(e)=> { props.modifier("location.state",e.target.value);
                        setState(e.target.value)
                         }}>
                    <option value="" className="" data-select2-id="0"></option>
                    <option value="FR.BNO" className="" data-select2-id="230">Basse Normandie</option>
                    <option value="FR.ALS" className="" data-select2-id="214">Alsace</option>
                    <option value="FR.AQU" className="" data-select2-id="216">Aquitaine</option>
                    <option value="FR.BRE" className="" data-select2-id="218">Bretagne</option>
                    <option value="FR.AUV" className="" data-select2-id="217">Auvergne</option>
                    <option value="FR.BOU" className="" data-select2-id="219">Bourgogne</option>
                    <option value="FR.CHA" className="" data-select2-id="221">Champagne-Ardennes</option>
                    <option value="FR.CEN" className="" data-select2-id="220">Centre</option>
                    <option value="FR.COR" className="" data-select2-id="222">Corse</option>
                    <option value="FR.FCO" className="" data-select2-id="223">Franche-Comté</option>
                    <option value="FR.HNO" className="" data-select2-id="224">Haute Normandie</option>
                    <option value="FR.IDF" className="" data-select2-id="225">Ile-de-France</option>
                    <option value="FR.LAN" className="" data-select2-id="226">Languedoc-Roussillon</option>
                    <option value="FR.LIM" className="" data-select2-id="227">Limousin</option>
                    <option value="FR.PLO" className="" data-select2-id="228">Pays de la Loire</option>
                    <option value="FR.LOR" className="" data-select2-id="229">Lorraine</option>
                    <option value="FR.MIP" className="" data-select2-id="231">Midi-Pyrénées</option>
                    <option value="FR.NPC" className="" data-select2-id="232">Nord-Pas-de-Calais</option>
                    <option value="FR.PIC" className="" data-select2-id="233">Picardie</option>
                    <option value="FR.PCH" className="" data-select2-id="234">Poitou-Charentes</option>
                    <option value="FR.PACA" className="" data-select2-id="235">Provence-Alpes-Côte-d'Azur</option>
                    <option value="FR.RHA" className="" data-select2-id="236">Rhône-Alpes</option>
                    <option value="FR.DT.GP" className="" data-select2-id="237">Guadeloupe</option>
                    <option value="FR.DT.MQ" className="" data-select2-id="238">Martinique</option>
                    <option value="FR.DT.RE" className="" data-select2-id="240">La Réunion</option>
                    <option value="FR.DT.NC" className="" data-select2-id="239">Nouvelle-Calédonie</option>
                    <option value="FR.DT.GF" className="" data-select2-id="241">Guyane</option>
                    <option value="FR.DT.YT" className="" data-select2-id="242">Mayotte</option>
                    <option value="FR.DT.PF" className="" data-select2-id="243">Polynésie française</option>
                </select>
            </label>

            <Spaces n={0}/>

          <label>
            <span className={"text--minified2x"}>Code postal</span><br/>
            <input type={"number"} className={"input--v2"} value={postalCode} onChange={(e) => {
              if(e.target.value.length < 9) {
                props.modifier("location.postalCode", e.target.value);
                setPostalCode(e.target?.value);
              }
            }}/>
          </label>

          <Spaces n={0}/>

          <label>
            <span className={"text--minified2x"}>Ville</span><br/>
            <input type={"text"} className={"input--v2"} value={city} onChange={(e) => {
              if(e.target.value.length < 100) {
                props.modifier("location.city", e.target.value);
                setCity(e.target?.value);
              }
            }}/>
          </label>

          <Spaces n={0}/>

          <label>
            <span className={"text--minified2x"}>Rue</span><br/>
            <input type={"text"} className={"input--v2"} value={street} onChange={(e) => {
              if(e.target.value.length < 100) {
                props.modifier("location.street", e.target.value);
                setStreet(e.target?.value);
              }
            }}/>
          </label>
          
        </>
      }
    
    </div>
  
  </>);
  
}