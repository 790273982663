import React, {useEffect, useState} from "react";
import Spaces from "../../components/sharedComponents/Spaces";
import LoadingStyle2 from "../../components/sharedComponents/Loading/LoadingStyle2";
import {notification} from "../../utils/notification";
import {useForm} from "use-formidable";
import axios from "axios";
import {FileDrop} from "../../components/sharedComponents/Buttons/FileDrop";

export default (props: any) => {
  
  const kid: string = props.match.params.kid || "";
  
  const [subscriptionStatus, setSubscriptionStatus] = useState<"loaded" | "loading" | "success" | "error">("loaded");
  
  /** Cv file */
  const [cv, updateCv, formidableCv] = useForm({
    file: null,
    accepted: false,
    sending: false,
  });
  
  const [candidate, setCandidate] = useState({
    firstName: "",
    lastName: "",
    positionWanted: "",
    isWorking: "",
    whenAvailable: "",
    yearsOfExperience: "",
    sectors: "",
    salaryMin: "",
    cvUrl: "",
    email: "",
    linkedin: "",
    phone: ""
  });
  
  /** When CV Updates **/
  useEffect((): any => {
    (() => {
      if(!!cv.file) {
        
        if(cv.file.length > 1) {
          updateCv()({target: {type: "custom", name: "file", value: null}});
          updateCv()({target: {type: "custom", name: "accepted", value: false}});
          alert("Un seul fichier peut être envoyé à la fois");
        }
        
        if(!formidableCv.isFileMime(cv.file[0], ["pdf", "docx", "doc", "xslx", "xsl", "odt", "jpeg", "png"])) {
          updateCv()({target: {type: "custom", name: "file", value: null}});
          updateCv()({target: {type: "custom", name: "accepted", value: false}});
          alert("Le type du fichier n'est pas pris en charge");
        }
        
        if(!formidableCv.isFileSmallerThan(cv.file[0], 10_000_000)) {
          updateCv()({target: {type: "custom", name: "file", value: null}});
          updateCv()({target: {type: "custom", name: "accepted", value: false}});
          alert("Le fichier est trop lourd (maximum 10mo)");
        }
        
        updateCv()({target: {type: "custom", name: "accepted", value: true}});
        
        console.log("cv chargé avec succès");
      }
    })();
  }, [cv.file]);
  
  const sendFile = async() => {
    try {
      const dataToSend: any = new FormData();
      dataToSend.append("cv", cv.file[0], cv.file[0].name);
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/upload/data/candidate/cv`, dataToSend, {
        withCredentials: true, timeout: 15000,
        headers: {
          'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
        }
      });
      if(fetched.data.status !== "ok") {
        console.log("error")
        setSubscriptionStatus("error");
      }
      return fetched.data.data;
    } catch(error) {
      console.log("error")
      setSubscriptionStatus("error");
    }
  }
  
  async function subscribe() {
    try {
      if(!cv.accepted) return alert("Un CV est obligatoire");
      setSubscriptionStatus("loading");
      const cvUrl = await sendFile();
      if(!cvUrl) return alert("Erreur dans l'envoi du CV");
      const dataToSend = {
        ...candidate,
        cvUrl,
        kid
      };
      console.log("dataToSend", dataToSend);
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/communities/general/subscribe`, dataToSend, {
        withCredentials: true,
        timeout: 10000,
      });
      setSubscriptionStatus("success");
    } catch(error) {
      setSubscriptionStatus("error")
    }
  }
  
  function setParameter(key: string, value: any) {
    setCandidate((ps: any) => ({
      ...ps,
      [key]: value
    }))
  }
  
  if(subscriptionStatus === "loading") {
    return (<>
      <Spaces n={8}/>
      <LoadingStyle2/>
      <Spaces n={8}/>
    </>);
  }
  
  if(subscriptionStatus === "error") {
    return (<>
      <Spaces n={8}/>
      <div className={"text--center"}>
        Une erreur est survenue, veuillez contacter le support
      </div>
      <Spaces n={8}/>
    </>);
  }
  
  if(subscriptionStatus === "success") {
    return (<>
      <Spaces n={8}/>
      <div className={"text--center"}>
        Vous avez bien été enregistré ! Vous pouvez maintenant fermer cette fenêtre, un T.A.S. vous contactera pour vous
        permettre de recevoir des offres pertinentes.
      </div>
      <Spaces n={8}/>
    </>);
  }
  
  const isButtonActive = candidate.firstName && candidate.firstName && candidate.email && cv.accepted && subscriptionStatus === "loaded";
  
  return (<>
    
    <Spaces n={4}/>
    
    <h1 className={"subscribe-title text--center"}>Black-belt.io</h1>
    <h1 className={"text--center"}>Recevez des offres qui vous correspondent.</h1>
    
    <Spaces n={1}/>
    
    <div className={"subscribe-section"}>
      
      <div>
        <div className={"space-flex--space-between"}>
          <label>Nom *:</label>
          <input type={"text"} value={candidate.firstName ?? ""} className={"input--v2"} placeholder={"Nom"}
                 onChange={(e) => setParameter("firstName", e.target.value)}/>
        </div>
        
        <div className={"space-flex--space-between"}>
          <label>Prénom *:</label>
          <input type={"text"} value={candidate.lastName ?? ""} className={"input--v2"} placeholder={"Prénom"}
                 onChange={(e) => setParameter("lastName", e.target.value)}/>
        </div>
  
        <div className={"space-flex--space-between"}>
          <label>Email *:</label><br/>
          <input type={"text"} value={candidate.email ?? ""} className={"input--v2"}
                 placeholder={"email"} onChange={(e) => setParameter("email", e.target.value)}/>
        </div>
        
        <div className={"space-flex--space-between"}>
          <label>Poste recherché *:</label><br/>
          <input type={"text"} value={candidate.positionWanted ?? ""} className={"input--v2"}
                 placeholder={"Poste recherché"} onChange={(e) => setParameter("positionWanted", e.target.value)}/>
        </div>
        
        <div className={"space-flex--space-between"}>
          <label>Années d'expérience:</label><br/>
          <input type={"number"} value={candidate.yearsOfExperience ?? ""} className={"input--v2"}
                 placeholder={"Années d'expérience"}
                 onChange={(e) => setParameter("yearsOfExperience", e.target.value)}/>
        </div>
        
        <div className={"space-flex--space-between"}>
          <label>LinkedIn:</label><br/>
          <input type={"text"} value={candidate.linkedin ?? ""} className={"input--v2"}
                 placeholder={"LinkedIn"} onChange={(e) => setParameter("linkedin", e.target.value)}/>
        </div>
      
      </div>
      
      <Spaces n={1}/>
  
      {/*--------------------------------- CV */}
      <article className={"white-container white-container--grey"}>
        {!cv.accepted && <div className={"text--center apply-V2__title"}>CV nécéssaire pour continuer</div>}
        {cv.accepted && <div className={"text--center apply-V2__title"}>CV accepté</div>}
        {
          (!cv.accepted) && <>
            <br/>
            <div className={"space-flex--center"}>
              <FileDrop square={false} name={"file"} cb={updateCv}/>
            </div>
          </>
        }
      </article>
      
      <Spaces n={2}/>
      
      <button className={"btn-main btn-main--v2"} disabled={!isButtonActive} onClick={() => subscribe()}>Inscription</button>
    </div>
    
    <Spaces n={5}/>
  
  </>);
}