import React from "react";
import {DisplayDictionary} from "../RecruiterParametersDictionaries";

export {RecruiterParametersDictionaryMobilities}

const RecruiterParametersDictionaryMobilities = (props: any) => {
  
  return(<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Mobilités</h1>
  
    <DisplayDictionary keyValue={"mobility"} data={props.dictionary || []} updateDictionaryEntry={()=>{}} deleteDictionaryEntry={()=>{}} />
    
  </div>);
}