import React from "react";

export {RecruiterAmbassadorsEmpty};

const RecruiterAmbassadorsEmpty = () => {
  
  return(<section className={"container white-container text--center"}>
      <span className={"text--magnified11x"}>
        Ajoute un ambassadeur pour lui confier une mission de cooptation.
      </span>
  </section>)
}