
export default (pools: any, setDisplayedPools: any, createdBy: any, name: any, olderThan: any, earlierThan: any) => {
  
  const poolsCopy: any = [...pools];
  
  /** Defines which filters are active */
  const isCreatedByActive: boolean = !!createdBy;
  const isNameByActive: boolean = !!name;
  const isOlderThanActive: boolean = !!olderThan;
  const isEarlierThanActive: boolean = !!earlierThan;
  
  /** Defines results for each filter */
  let idFilteredByName: string[] = [];
  let idFilteredByOlderThan: string[] = [];
  let idFilteredByEarlierThan: string[] = [];
  let idFilteredByCreator: string[] = [];
  
  /** Defines the result, both with ID and pools */
  let resultPools: any = [];
  
  if(!isCreatedByActive && !isNameByActive && !isOlderThanActive && !isEarlierThanActive) {
    resultPools = poolsCopy;
  } else {
    
    /** Get IDs of result for name filter */
    (isNameByActive && (idFilteredByName = poolsCopy
      .filter((item: any) => item.name?.toLowerCase().includes(name.toLowerCase()))
      .map((item: any) => (item._id))
    ));
  
    /** Get IDs of result for older than filter */
    (isOlderThanActive && (idFilteredByOlderThan = poolsCopy
      .filter((item: any) => new Date(item.dateOfLastAction).getTime() < (new Date().getTime() - olderThan * 24 * 60 * 60 * 1000))
      .map((item: any) => (item._id))
    ));

    /** Get IDs of result for earlier than filter */
    (isEarlierThanActive && (idFilteredByEarlierThan = poolsCopy
      .filter((item: any) => new Date(item.dateOfLastAction).getTime() > (new Date().getTime() - earlierThan * 24 * 60 * 60 * 1000))
      .map((item: any) => (item._id))
    ));
    
    /** Add IDs to the result  */
    // For each ID, add it only if it matches active filters
    for(let pool of poolsCopy) {
      
      if(isCreatedByActive && !idFilteredByCreator.includes(pool._id)) {
        continue;
      }
      if(isNameByActive && !idFilteredByName.includes(pool._id)) {
        continue;
      }
      if(isOlderThanActive && !idFilteredByOlderThan.includes(pool._id)) {
        continue;
      }
      if(isEarlierThanActive && !idFilteredByEarlierThan.includes(pool._id)) {
        continue;
      }
      
      resultPools.push(pool);
      
    }
    
  }
  
  /** Organize and display the result */
  const organizedPools: any = [
    ...resultPools.filter((item: any) => item.pinned === true),
    ...resultPools.filter((item: any) => item.pinned !== true),
  ];
  setDisplayedPools(organizedPools);
  
}