import React, {useState} from "react";

export default (props: {
  type?: string,
  cb: Function,
  title?: string,
}) => {
  
  // const [opened, setOpened] = useState(false);
  
  return (<button className={"btn--info"} title={props.title} onClick={() => (props.cb)()}>
      <span>i</span>
    </button>
  );
}