import React, {useContext, useState} from "react";
import Spaces from "../../../components/sharedComponents/Spaces";
import AppContext, {IAppV2Context} from "../../../components/App/context/AppContext";
import {useForm} from "use-formidable";
import {notification} from "../../../utils/notification";

export {RecruiterLinksMailLink}

const RecruiterLinksMailLink = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [form, updateForm, formidable] = useForm({text: "Rejoins nous !"});
  
  const getCode = (text: string): string => {
    const url: string = `${window.location.origin}/corpo/${appContextV2.appSession.sessionBusiness.profileLink.name}/${appContextV2.appSession.sessionBusiness.profileLink.number}`;
    return `<a href="${url}/jobs?o=emails" style={"decoration:none;"}><button style="background-color:#3a76ac;color:white;padding:4px 8px;border-radius:5px;border:none;">${text}</button></a>`
  }
  
  return (<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Utilise ta signature de mail</h1>
    
    <div className={"text--center text--grey"}>
      Copie/colle ce code dans ta signature de mail pour communiquer en temps réel sur le nombre de postes ouverts dans ton entreprise (actuellement validé avec
      des comptes gmail et google suite).
    </div>
    
    <Spaces n={2}/>
    
    <div className={"text--center"}>
      <input className={"input--v2"} type={"text"} value={form.text} name={"text"} onChange={updateForm({maximumLength: 15})}/>
      <div className={"link-box__link invisible height-fixed"} id={"signature"} contentEditable={"true"}>Texte</div>
    </div>
    
    <div className={"text--center"}>
      <button className={"btn-secondary"} onClick={() => {
        let copyArea = document.getElementById("signature");
        copyArea!.innerHTML = getCode(form.text);
        copyArea!.focus();
        document.execCommand("selectAll");
        document.execCommand("copy");
        // copyArea!.innerHTML = "<span>Copié !</span>";
        notification(appContextV2.dispatchPanels, "Lien copié", "success");
      }}>
        Copier
      </button>
    </div>
  
  </div>);
}