import axios from "axios";
import {devLog} from "../../../../utils/devLog/devLog";

export default async(setPresetStatus: any, setPresets: any, positionWanted: any) => {
  try {
    
    setPresetStatus("fetching");
    
    const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/data/dictionary/presets`, {
      headers: {
        language: "FR",
        positionwanted: positionWanted,
      },
      timeout: 4000,
      withCredentials: true
    });

    if(fetched.data.status !== "ok") {
      return setPresetStatus("fetched");
    }
    
    const presets = {
      default: fetched.data.data.default,
      private: fetched.data.data.private,
      classical: fetched.data.data.classical,
      technical: fetched.data.data.technical,
    };
    
    setPresets(presets);
    
    setPresetStatus("fetched");
  
    return presets;
  
  } catch (error) {
    devLog(error);
    setPresetStatus("fetched");
  }
}