import React, {useContext, useEffect, useState} from "react";
import Spaces from "../../../components/sharedComponents/Spaces";
import RSMLMissionLink from "./componentsRSML/RSMLMissionLink";
import RSMLNavigation from "./componentsRSML/RSMLNavigation";
import RSMLContent from "./componentsRSML/RSMLContent";
import AppContext, {IAppV2Context} from "../../../components/App/context/AppContext";

export default (props: {
  missionId?:string,
  mission?:any,
  missionTokens: {
    rank: number,
    mailHandler: string,
  },
  white?: boolean
  grey?:boolean
}) => {

  const [category,setCategory] = useState<"ambassador" | "multiposting" | "tracking" | "email">('ambassador');

  return (<>
    
    <RSMLMissionLink {...props} />
    
    <Spaces n={0}/>

    <RSMLNavigation category={category} setCategory={setCategory} {...props}/>

    <Spaces n={0}/>

    <RSMLContent category={category} {...props} />

    <Spaces n={0}/>
    
  </>);
}