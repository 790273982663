import React from "react";
import DisplayTutorialSteps from "../../DisplayTutorialSteps";

export default (props: any) => {

  return (<div className={"window-tutorial__content"}>

    <div className={"window-tutorial__main-content animation-fade-in"}>
      <h1 className={"window-tutorial__title1"}> Bienvenue sur Myshortlist.co ! </h1>


      <p className={"window-tutorial__container"}>
        <br/>
        Myshortlist.co vous aide à recruter plus efficacement en vous libérant l'esprit des tâches simples et répétitives. <br/><br/>
        La plateforme s'articule autour de deux entités: <b className={"window-tutorial__b"}>les Postes</b> et <b className={"window-tutorial__b"}>les Communautés</b>:<br/><br/>
        <ul className={"window-tutorial__ul"} >
          <li>Ouvrez un <b className={"window-tutorial__b"}>Poste</b>  quand vous avez un besoin en recrutement, pour recevoir des candidatures.</li>
          <li>Conservez les candidats qui vous intéressent sur le long terme dans une <b className={"window-tutorial__b"}>communauté</b>.</li>
        </ul>
      </p>
    </div>

    <div className={"window-tutorial__secondary-content"}>
      <DisplayTutorialSteps {...props}/>

      <div>
        <button className={"btn-secondary"} onClick={async (e) => props.hideTutorialForEver()}>
          Ne plus afficher
        </button>

        <button className={"btn-secondary"} onClick={() => props.setStep((ps: number) => ps + 1)}>Suivant</button>
      </div>
    </div>

  </div>);
}

