import React from "react";
import {SecondaryNavBarWrapper} from "./shared/SecondaryNavBarWrapper";
import {SubMenuCandidateMainContent} from "../SubNavbarComponents/SubMenuCandidateMainContent";

export {SecondaryNavBarCandidateMain};

function SecondaryNavBarCandidateMain(props: any) {
  
  return (
    <div className={"hide-on-computers"}>
      <SecondaryNavBarWrapper content={<SubMenuCandidateMainContent/>}/>
    </div>);
}
