import React, { useContext, useEffect, useReducer, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import AppContext, {IAppV2Context} from "../App/context/AppContext";
import axios from "axios";
import Spaces from "../sharedComponents/Spaces";
import {ButtonBigPlusV2} from "../sharedComponents/Buttons/ButtonBigPlus";
import businessesReducer from "./businessesReducer";
import {RecruiterAmbassador} from "../../pages/privateRecruiter/componentsRecruiterAmbassadors/RecruiterAmbassador";
import {notification} from "../../utils/notification";
import {Link} from "react-router-dom";

/**
 * --------------------------------------
 * Displays personal and business profiles
 * Primary-level page
 * --------------------------------------
 */
export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const [businesses, dispatchBusinesses] = useReducer(businessesReducer, []);
  
  useEffect(() => {
    (async() => {
      try {
        const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/clients`, {
          withCredentials: true
        })).data;
        console.log("data",data);
        if(status === "ok") {
          dispatchBusinesses({type: "INITIALIZE", value: data});
        }
      } catch(error) {
      
      }
    })();
  }, []);

  const [statsSent, setStatsSent] = useState(false)

  async function sendStats() {
    try {
      setStatsSent(true)
      await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/statsEmail`, {}, {
        withCredentials: true,
        timeout: 20000,
      });
      alert("Email envoye")
    } catch(error) {
      console.error(error)
      alert(error.message)
    }
  }
  
  return (<PageLayout small={true} page={props.page} grey={true} fullWidth={true} status={"ok"}>

    <Spaces n={2}/>
    <h1 className={"text--center title--bold"}>Actions</h1>
    <div style={{textAlign: "center"}}>
      Recevoir un email de statistiques: <button disabled={statsSent} onClick={() => sendStats()}>Envoyer</button>
    </div>

    <Spaces n={2}/>
    <h1 className={"text--center title--bold"}>Page de contrôle du parsing</h1>
    <div style={{textAlign: "center"}}>
      <Link to={"/business/controlParsing"}><button>Contrôle</button></Link>
    </div>
    
    <Spaces n={2}/>
    <h1 className={"text--center title--bold"}>Les entreprises</h1>
    <Spaces n={0}/>
    
    {businesses?.map((item: any, key: number) => <div className={"ambassadors--v2__ambassador"}>
      <div className={"ambassadors--v2__ambassador__element"}>Nom:{item.name}</div>
      <div className={"ambassadors--v2__ambassador__element"}>Vues:{item.nViews} </div>
      <div className={"ambassadors--v2__ambassador__element"} onClick={() => {
        navigator.clipboard.writeText(`https://black-belt.myshortlist.co/entreprise/${item.token}`);
        notification(appContextV2.dispatchPanels, 'Succès', 'success');
      }}>
        <button>
          Copier le lien
        </button>
        </div>
      <div> <button onClick={()=>{
        appContextV2.dispatchPanels({
          type: "OPEN_WINDOW",
          value: {
            type: "businessBlackBeltManagers",
            data: {
              business:item,
            }
          }
        });
      }}> Managers </button> </div>
    </div>)}
    
    <Spaces n={2}/>
    <p className={"space-flex--center"}>
      <ButtonBigPlusV2 cb={() => {
        appContextV2.dispatchPanels({
          type: "OPEN_WINDOW",
          value: {
            type: "addBusinessBlackBelt",
            data: {
              dispatchBusinesses: dispatchBusinesses,
            }
          }
        });
      }} title={"Ajoute un business"}/>
    </p>
  
  </PageLayout>);
  
}
