import Spaces from "../../../../components/sharedComponents/Spaces";
import React, {useEffect, useReducer} from "react";
import axios from "axios";
import multipostingAppsList from "./apps/multipostingAppsList";
import multipostingAppsReducer from "./apps/multipostingAppsReducer";
import multipostingPersonnalAppsList from "./apps/multipostingPersonnalAppsList";
import {IAppContentMultiposting, MultipostingSingleApp} from "./MultipostingSingleApp";

export default (props: any) => {
  
  const [businessApps, dispatchBusinessApps] = useReducer(multipostingAppsReducer, multipostingAppsList);
  const [userApps, dispatchUserApps] = useReducer(multipostingAppsReducer, multipostingPersonnalAppsList);
  
  const orderedBusinessApps = [
    ...businessApps.filter((app: any) => (app.isAvailable)),
    ...businessApps.filter((app: any) => (!app.isAvailable))
  ];
  
  const orderedUserApps = [
    ...userApps.filter((app: any) => (app.isAvailable)),
    ...userApps.filter((app: any) => (!app.isAvailable))
  ];
  
  useEffect(() => {
    (async() => {
      try {
        const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/get/missionData/multiposting`, {
          headers: {
            rank: props.missionTokens.rank,
          },
          withCredentials: true,
        })).data;
  
        dispatchBusinessApps({type: 'FIRST_UPDATE', value: data?.businessApps ?? []});
        dispatchUserApps({type: 'FIRST_UPDATE', value: data?.userApps ?? []});

      } catch(error) {
        throw error;
      }
    })();
  }, []);
  
  return (<>
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
      <span className={"text--bold text--magnified11x"}>Postez l'annonce sur les réseaux sociaux</span>
      <Spaces n={0}/>
      <div className={"multiposting-section"}>
        {orderedBusinessApps.map((app: IAppContentMultiposting) => (<MultipostingSingleApp app={app} rank={props.missionTokens.rank} dispatcher={dispatchBusinessApps}/>))}
        {orderedUserApps.map((app: IAppContentMultiposting) => (<MultipostingSingleApp app={app} rank={props.missionTokens.rank} dispatcher={dispatchUserApps}/>))}
      </div>
    </article>
  </>)
}