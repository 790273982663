import {devLog} from "./devLog/devLog";

export default (salaryMin: number, salaryMax: number, salaryBy: string, salaryCurrency: string) => {
  
  let period: string = "";
  
  if(!salaryMin && !salaryMax) {
    return "Non renseigné";
  }
  
  switch(salaryBy) {
    case 'hourly': period = "horaires brut"; break;
    case 'monthly': period = "mensuels brut"; break;
    case 'annualy': period = "annuels brut"; break;
    case 'budget': period = "(budget)"; break;
  }
  
  if(salaryMin === salaryMax) {
    return `${getFormatedNumber(salaryMin)} € ${period}`;
  }
  
  return `de ${getFormatedNumber(salaryMin)} € à ${getFormatedNumber(salaryMax)} € ${period}`;
  
}

const getFormatedNumber = (input: string|number): string|number => {
  
  try {
  
    let leftToProcess: string = input.toString();
    let n: number = 0;
    let result: string = "";
    while(leftToProcess.length > 0){
      result = leftToProcess[leftToProcess.length-1] + result;
      leftToProcess = leftToProcess.substring(0, leftToProcess.length-1);
      n++
      if(n % 3 === 0) {
        result = " " + result;
      }
    }
  
    return result;
  } catch (error) {
    devLog(error);
    return input;
  }
  
};