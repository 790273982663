import AppContext, {IAppContext} from "../../../components/App/context/AppContext";
import React, {useContext} from "react";
import getText from "../../../utils/getText";
import {abstractPostFetch} from "../../../utils/abstractFetch";


interface IFormItem {
  item: any,
  index: number,
  sectionNumber: number,
  editorContext: any,
  updateItem: Function,
  toggleExpanded: Function,
  drag: Function,
  drop: Function,
  removeItem: Function,
}

export default class FormItem extends React.Component<IFormItem, any> {
  
  static contextType = AppContext;
  
  constructor(props: IFormItem) {
    super(props);
    this.state = {};
  }
  
  addAnswer = (questionIndex: number, sectionNumber: number, blocking: 'true' | 'false') => {
    switch(sectionNumber) {
      case 1:
        const form: any = [...this.props.editorContext.editorState.form];
        form[questionIndex].answers.push({
          answer: '',
          answerData: `${blocking === 'true' ? "keep" : "0"}`,
        });
        this.props.editorContext.updateState({form: form,});
        break;
      case 2:
        const form2: any = [...this.props.editorContext.editorState.form2];
        form2[questionIndex].answers.push({
          answer: '',
          answerData: `${blocking === 'true' ? "keep" : "0"}`,
        });
        this.props.editorContext.updateState({form2: form2,});
    }
  };
  
  removeAnswer = (questionIndex: number, sectionNumber: number, answerIndex: number) => {
    switch(sectionNumber) {
      case 1:
        let form: any = [...this.props.editorContext.editorState.form];
        let newAnswers: any = form[questionIndex].answers.filter((item: any, index: number) => index !== answerIndex);
        form[questionIndex].answers = newAnswers;
        this.props.editorContext.updateState({form: form,});
        break;
      case 2:
        let form2: any = [...this.props.editorContext.editorState.form2];
        let newAnswers2: any = form2[questionIndex].answers.filter((item: any, index: number) => index !== answerIndex);
        form2[questionIndex].answers = newAnswers2;
        this.props.editorContext.updateState({form2: form2,});
    }
  };
  
  updateAnswer = (questionIndex: number, sectionNumber: number, answerIndex: number, key: string, value: string) => {
    switch(sectionNumber) {
      case 1:
        let form: any = [...this.props.editorContext.editorState.form];
        form[questionIndex].answers[answerIndex][key] = value;
        this.props.editorContext.updateState({form: form,});
        break;
      case 2:
        let form2: any = [...this.props.editorContext.editorState.form2];
        form2[questionIndex].answers[answerIndex][key] = value;
        this.props.editorContext.updateState({form2: form2,});
    }
  };
  
  saveQuestionServer = async (item: any) => {
    try {
      await abstractPostFetch("/data/save/preset/question", {
        value: item,
        positionWanted: "",
        positionWantedCode: "",
      });
      this.context.showUpdate("La question a été ajoutée à tes favoris");
    } catch(error) {
      console.log(error);
    }
  }
  
  render() {
    
    const language: string = this.context.appState.language;
    
    switch(this.props.item.type as 'freeText' | 'singleChoice' | 'multipleChoice') {
      case 'freeText':
        return (
          <>
            <FormItemWrapper {...this.props} expanded={this.state.expanded}>
              
              <div className={"editor__form__item__header"}>
                <div>
                  <button className={"btn--hollow space-flex--left"} onClick={() => {
                    this.props.toggleExpanded(this.props.index, this.props.sectionNumber);
                  }}>
                    <img className={`text--hollow arrow-btn ${this.props.item.expanded ? "arrow-btn--activated" : ""} space-right`}
                         src={"https://static.myshortlist.co/btn-arrow-list.svg"}
                         alt={"toggle"}/>
                    <img className={"img-type"} title={'Réponses sous forme de texte libre'} src={getImgKind(this.props.item.type)}/>
                    <span className={"text--hollow"}>(n° {this.props.index + 1})</span>
                    {!this.props.item.expanded && <span className={"text--bold text--hollow"}> {getQuestionShortenned(this.props.item.question)}</span>}
                  </button>
                </div>
                <div>
                  <button className={"btn--hollow space-light modern-link--grey text--minified1x"} onClick={() => {
                    this.saveQuestionServer(this.props.item);
                  }}>
                    {language === 'FR' && <>Garder</>}
                    {language === 'EN' && <>Save</>}
                  </button>
                  <DeleteItem {...this.props} />
                </div>
              </div>
              
              {this.props.item.expanded && <>
                <article>

                  <input type={"text"} className={"input-question"} value={this.props.item.question} placeholder={"Écrivez la question à poser au candidat"}
                         onChange={(e) => {
                           this.props.updateItem(this.props.index, this.props.sectionNumber, {key: 'question', value: e.target.value});
                         }}/><br/><br/>
                </article>
              </>}
            </FormItemWrapper>
          </>
        );
      case 'multipleChoice':
      case 'singleChoice':
        return (
          <>
            <FormItemWrapper {...this.props} expanded={this.state.expanded}>
              
              <div className={"editor__form__item__header"}>
                <button className={"btn--hollow space-flex--left"} onClick={() => {
                  this.props.toggleExpanded(this.props.index, this.props.sectionNumber);
                }}>
                  <img className={`text--hollow arrow-btn ${this.props.item.expanded ? "arrow-btn--activated" : ""} space-right`}
                       src={"https://static.myshortlist.co/btn-arrow-list.svg"}
                       alt={"toggle"}/>
                  <img className={"img-type"} title={"Question à choix unique"} src={getImgKind(this.props.item.type)}/>
                  <span className={"text--hollow"}>(n° {this.props.index + 1})</span>
                  {!this.props.item.expanded && <span className={"text--bold text--hollow"}> {getQuestionShortenned(this.props.item.question)}</span>}
                </button>
                
                <div>
                  {this.props.item.expanded && <>
                    <select value={this.props.item.type} onChange={(e) => {
                      this.props.updateItem(this.props.index, this.props.sectionNumber, {key: "type", value: e.target.value})
                    }}>
                      <option value={"singleChoice"}>Question à choix unique</option>
                      <option value={"multipleChoice"}>Question à choix multiple</option>
                    </select>
                    <select value={this.props.item.blocking} onChange={(e) => {
                      this.props.updateItem(this.props.index, this.props.sectionNumber, {key: "blocking", value: e.target.value})
                    }}>
                      <option value={"false"}>Non éliminatoire</option>
                      <option value={"true"}>Éliminatoire</option>
                    </select>
                  </>
                  }
                  <button className={"btn--hollow space-light modern-link--grey text--minified1x"} onClick={() => {
                    this.saveQuestionServer(this.props.item);
                  }}>
                    {language === 'FR' && <>Garder</>}
                    {language === 'EN' && <>Save</>}
                  </button>
                  <DeleteItem {...this.props} />
                </div>
              </div>
              
              {this.props.item.expanded && <>
                <p><br/></p>
                <div>
                  <input type={"text"} className={"input-question"} value={this.props.item.question} placeholder={"Écrivez la question à poser au candidat"}
                         onChange={(e) => {
                           this.props.updateItem(this.props.index, this.props.sectionNumber, {key: 'question', value: e.target.value});
                         }}/><br/><br/>
                </div>
                <p><br/></p>
                <article className={"editor__form__item__answers"}>
                  <p className={"text--hollow text--left text--bold text--magnified15x"}>
                    {language === 'FR' && <>Créez les réponses à votre question :</>}
                    {language !== 'FR' && <>Answers :</>}
                  </p>
                  <p><br/></p>
                  <ul>
                    {this.props.item.answers.map((item: any, key: any) => (<>
                      <li className={"editor__form__item__answers__answer"}>
                        <img className={"img-drag"} src={"https://static.myshortlist.co/icon-drag.svg"}/>
                        <input type={"text"} className={"input-answer"} value={item.answer} placeholder={"Réponse"} onChange={(e) => {
                          this.updateAnswer(this.props.index, this.props.sectionNumber, key, "answer", e.target.value);
                        }}/>
                        <ControlBlockingAndImportance {...this.props} answerItem={item} rank={key} updateAnswer={this.updateAnswer}/>
                        <RemoveAnswer onClick={() => {this.removeAnswer(this.props.index, this.props.sectionNumber, key)}}/>
                        {(key === this.props.item.answers.length - 1 && this.props.item.answers.length < 25) &&
                        <AddAnswer onClick={() => {this.addAnswer(this.props.index, this.props.sectionNumber, this.props.item.blocking)}}/>}
                      </li>
                    </>))}
                  </ul>
                  {
                    this.props.item.answers.length === 0 && <p className={"text--center"}>
                      <AddAnswer onClick={() => {this.addAnswer(this.props.index, this.props.sectionNumber, this.props.item.blocking)}}/>
                    </p>
                  }
                </article>
                <p><br/></p>
              </>}
            </FormItemWrapper>
          </>
        );
    }
  }
}

function ControlBlockingAndImportance(props: any) {
  
  switch(props.item.blocking) {
    case 'true':
      return(<>
        {/*<button disabled={true} className={"btn-importance-item btn-importance-item--text"}>Éliminatoire ? </button>*/}
  
        <button className={`btn-importance-item btn-importance-item--smaller ${props.answerItem.answerData === "block" ? "btn-importance-item--selected":""}`} onClick={()=>{
          props.updateAnswer(props.index, props.sectionNumber, props.rank, "answerData", "block");
        }} disabled={props.answerItem.answerData === "block"}>Mauvaise réponse</button>
  
        <button className={`btn-importance-item btn-importance-item--smaller ${props.answerItem.answerData === "keep" ? "btn-importance-item--selected":""}`} onClick={()=>{
          props.updateAnswer(props.index, props.sectionNumber, props.rank, "answerData", "keep");
        }} disabled={props.answerItem.answerData === "keep"}>Bonne réponse</button>
        
        {/*<button className={`btn-importance-item ${props.answerItem.answerData === "block" ? "btn-importance-item--selected":""}`} onClick={()=>{*/}
        {/*  props.updateAnswer(props.index, props.sectionNumber, props.rank, "answerData", "block");*/}
        {/*}} disabled={props.answerItem?.answerData === "block"}>*/}
        {/*  {props.answerItem.answerData === "block" && <img src={"https://static.myshortlist.co/icon-q-block--selected.svg"} title={"Mauvaise réponse"} />}*/}
        {/*  {props.answerItem.answerData !== "block" && <img src={"https://static.myshortlist.co/icon-q-block.svg"} title={"Mauvaise réponse"} />}*/}
        {/*</button>*/}
        {/*<button className={`btn-importance-item ${props.answerItem.answerData === "keep" ? "btn-importance-item--selected":""}`} onClick={()=>{*/}
        {/*  props.updateAnswer(props.index, props.sectionNumber, props.rank, "answerData", "keep");*/}
        {/*}} disabled={props.answerItem?.answerData === "keep"}>*/}
        {/*  {props.answerItem.answerData === "keep" && <img src={"https://static.myshortlist.co/btn-check--selected.svg"} title={"Bonne réponse"} />}*/}
        {/*  {props.answerItem.answerData !== "keep" && <img src={"https://static.myshortlist.co/btn-check.svg"} title={"Bonne réponse"} />}*/}
        {/*</button>*/}
      </>);
    case 'false':
      return(<>
        <button disabled={true} className={"btn-importance-item btn-importance-item--text"}>points: </button>
        <button className={`btn-importance-item ${props.answerItem.answerData === "0" ? "btn-importance-item--selected":""}`} onClick={()=>{
          props.updateAnswer(props.index, props.sectionNumber, props.rank, "answerData", "0");
        }} disabled={props.answerItem?.answerData === "0"}>0</button>
        <button className={`btn-importance-item ${props.answerItem.answerData === "2" ? "btn-importance-item--selected":""}`} onClick={()=>{
          props.updateAnswer(props.index, props.sectionNumber, props.rank, "answerData", "2");
        }} disabled={props.answerItem?.answerData === "2"}>1</button>
        <button className={`btn-importance-item ${props.answerItem.answerData === "5" ? "btn-importance-item--selected":""}`} onClick={()=>{
          props.updateAnswer(props.index, props.sectionNumber, props.rank, "answerData", "5");
        }} disabled={props.answerItem?.answerData === "5"}>2</button>
      </>);
    default:
      return(<>E</>)
  }
  
}

function FormItemWrapper(props: any) {
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  return (<>
    {
      props.expanded
    }
    <section key={props.index}
             className={`editor__form__item ${isQuestionReady(props.item) ? "editor__form__item--ready" : "editor__form__item--not-ready"} editor__form__item--draggable animation-straight-fade-in`}
             draggable={true}
             onDragStart={(e) => {
               e.dataTransfer.setData('text/plain', '');
               props.drag(props.index, props.sectionNumber);
             }}
             onDragOver={(e) => {e.preventDefault();}}
             onDrop={() => {props.drop(props.index, props.sectionNumber)}}>
      {props.children}
    </section>
  </>);
}

function getImgKind(kind: 'freeText' | 'singleChoice' | 'multipleChoice') {
  switch(kind) {
    case 'freeText':
      return "https://static.myshortlist.co/btn-text.svg";
    case 'singleChoice':
      return "https://static.myshortlist.co/btn-radio.svg";
    case 'multipleChoice':
      return "https://static.myshortlist.co/btn-checkbox.svg";
  }
}

function doesAllAnswersHaveTitles(item: any) {
  for(let answer of item.answers) {
    if(!answer.answer) {
      return false;
    }
  }
  return true;
}

export function isQuestionReady(item: any) {
  switch(item.type) {
    case 'freeText':
      if(!!item.question && item.question.length > 4) {
        return true;
      } else {
        return false;
      }
    case 'singleChoice':
    case 'multipleChoice':
      if(
        !!item.question &&
        item.question.length > 4 &&
        item.answers.length >= 2 &&
        doesAllAnswersHaveTitles(item)
      ) {
        return true;
      } else {
        return false;
      }
  }
}

function DeleteItem(props: any) {
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  const language: string = appContext.appState.language;
  return (<>
    <button className={"btn--hollow space-light modern-link--grey text--minified1x"} onClick={() => {
      props.removeItem(props.index, props.sectionNumber);
    }}>
      {language === 'FR' && <>Supprimer</>}
      {language === 'EN' && <>Delete</>}
    </button>
  </>);
}

function RemoveAnswer(props: any) {
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  const language: string = appContext.appState.language;
  const title: string = getText({
    fr: 'Supprimer cette réponse',
    en: 'Delete this answer',
    language: language,
  });
  return (<button className={"btn--hollow text--hollow"} onClick={() => {props.onClick()}}>
    <img className={"btn-close space-light"} title={title} alt={"remove answer"} src={"https://static.myshortlist.co/btn-close.svg"}/>
  </button>);
}

function AddAnswer(props: any) {
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  const language: string = appContext.appState.language;
  const title: string = getText({
    fr: 'Ajouter une réponse',
    en: 'Ass an answer',
    language: language,
  });
  return (<button className={"btn--hollow"} onClick={() => {props.onClick()}}>
    <img className={"btn-close space-light"} title={title} alt={"add answer"} src={"https://static.myshortlist.co/btn-plus.svg"}/>
  </button>);
}

function getQuestionShortenned(text: string) {
  if(text.length < 60) {
    return text;
  }
  return `${text.substring(0,60)} ...`
}