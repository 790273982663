import React, {useContext, useEffect, useRef, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../App/context/AppContext";
import {notification} from "../../../../../../utils/notification";
import LoadingStyle2 from "../../../../Loading/LoadingStyle2";
import Spaces from "../../../../Spaces";
import axios from "axios";
import {devLog} from "../../../../../../utils/devLog/devLog";
import {CW2WhatsappMessage} from "./componentsCW2Whatsapp/CW2WhatsapMessage";
import {ButtonBack} from "../../../../Buttons/ButtonBack";
import CW2FirstMesssage from "./componentsCW2Whatsapp/CW2FirstMesssage";
import CW2SecondMessage from "./componentsCW2Whatsapp/CW2SecondMessage";
import CW2ThirdMessage from "./componentsCW2Whatsapp/CW2ThirdMessage";
import CW2Message from "./componentsCW2Whatsapp/CW2Message";

export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const candidateId = props.candidate?.candidate?._id;
    const missionId = props.inherited.missionId;
    const [messages, setMessages]: [any, any] = useState([]);
    const [step, setStep]: [any, any] = useState("choice");
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage]: [any, any] = useState("");

    const messagesEndRef: any = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"})
    }
    useEffect(scrollToBottom, [messages]);

    const addMessage = (content: string) => {
        setMessages((ps: any) => ([...ps, {
            dateOfCreation: new Date().toString(),
            type: "black-belt",
            from: appContextV2.appSession.session.trigram,
            message: content,
            status: "sent",
        }]));
    }

    /** Load messages */
    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const {
                    status,
                    data
                } = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/jobs/whatsapp/discussion`, {
                    timeout: 20_000,
                    headers: {
                        candidateid: candidateId,
                    },
                    withCredentials: true
                })).data;

                if (status !== "ok") {
                    setIsLoading(false);
                    return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                }
                setIsLoading(false);
                setMessages(data.whatsapp || []);
            } catch (error) {
                devLog(error);
                setIsLoading(false);
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        })();
    }, []);

    const sendMsg = async () => {
        try {

            setIsLoading(true);

            const {status, data} = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/jobs/new/message/whatsapp`, {
                candidateId: candidateId,
                message: message,
                dateOfCreation: new Date().toString(),
                type: "black-belt",
                from: appContextV2.appSession.session.trigram,
                status: "sent",
            }, {withCredentials: true})).data;

            if (status !== "ok") {
                setIsLoading(false);
                setStep("choice");
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                return;
            }

            addMessage(message);
            setMessage("");
            setIsLoading(false);
            setStep("choice");
            notification(appContextV2.dispatchPanels, "Message envoyé", "success");

        } catch (error) {
            setIsLoading(false);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    const sendFirstMsg = async (template: string, message: string) => {
        try {

            setIsLoading(true);

            const {
                status,
                data
            } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/jobs/first/message/whatsapp`, {
                candidateId: candidateId,
                missionId: missionId,
                message: message,
                dateOfCreation: new Date().toString(),
                type: "black-belt",
                from: appContextV2.appSession.session.trigram,
                status: "sent",
                template: template,
            }, {withCredentials: true})).data;

            if (status !== "ok") {
                setIsLoading(false);
                setStep("choice");
                notification(appContextV2.dispatchPanels, "Une erreur est survenue (le num doit commencer par 0033)", "error");
                return;
            }

            addMessage(data.message);
            setStep("choice");
            setMessage("");
            setIsLoading(false);
            notification(appContextV2.dispatchPanels, "Message envoyé", "success");

        } catch (error) {
            setIsLoading(false);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue (le num doit commencer par 0033)", "error");
        }
    }

    return (<div className={"text--grey container"}>

        {
            isLoading && <div className={"space-flex--center"}>
                <Spaces n={0}/>
                <LoadingStyle2/>
                <Spaces n={0}/>
            </div>
        }

        {
            (!isLoading && (messages.length === 0)) && <div className={"text--center"}>
                <Spaces n={4}/>
                Aucun message n'est enregistré pour ce candidat
            </div>
        }


        <section className={"container-whatsapp"}>
            {messages?.map((item: any, index: number) => (
                <CW2WhatsappMessage index={index} item={item} messages={messages}/>))}
        </section>
        <div ref={messagesEndRef}/>


        {!isLoading && step === "choice" && <section className={`messages--v2__chat`}>
            <div className={"center"}>
                <Spaces n={0}/>
                {messages.length !== 0 && <button className={"message--v2__chat__button"} onClick={() => {
                    setStep("message")
                }}>Message perso</button>}
                <button className={"message--v2__chat__button"} onClick={() => {
                    setStep("firstMessage")
                }}>1er message
                </button>
                <button className={"message--v2__chat__button"} onClick={() => {
                    setStep("secondMessage")
                }}>Relance
                </button>
                <button className={"message--v2__chat__button"} onClick={() => {
                    setStep("thirdMessage")
                }}>Reponse apres message candidat
                </button>
            </div>
        </section>}

        {(!isLoading && step === "message") && <CW2Message setStep={setStep}
                                                           message={message}
                                                           setMessage={setMessage}
                                                           sendMsg={sendMsg}
        />}

        {(!isLoading && step === "firstMessage") && <CW2FirstMesssage setStep={setStep}
                                                                      sendFirstMsg={sendFirstMsg}
        />}

        {(!isLoading && step === "secondMessage") && <CW2SecondMessage setStep={setStep}
                                                                       sendFirstMsg={sendFirstMsg}
        />}

        {(!isLoading && step === "thirdMessage") && <CW2ThirdMessage setStep={setStep}
                                                                     sendFirstMsg={sendFirstMsg}
        />}


        <Spaces n={5}/>

    </div>);
}