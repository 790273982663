import NavBarLogoContent from "../NavBarLogoContent";
import React from "react";
import {SubMenuRecruiterParametersContent} from "../../SubNavbarComponents/SubMenuRecruiterParametersContent";

export function SubMenuRecruiterParameters(props: any) {
  return (<>
    <div className={'hide-on-computers'}>
      <NavBarLogoContent/>
    </div>
    <ul className={"subnavbar-new hide-on-smartphones animation-straight-fade-in"}>
      <SubMenuRecruiterParametersContent/>
    </ul>
  </>);
}