import React from "react";

export default( args:{incompatibleFields:any[]}) => {
  const array = args.incompatibleFields;
  let formattedArray:any[] = array.map((item:any) => {
    switch(item){
      case 'state--doesntExist':
        return 'Vous devez renseigner une région pour le poste.';
      case 'yearsOfStudies--doesntExist':
        return "Vous devez définir un nombre d'années d'études minimum pour le poste.";
      case 'postalCode--doesntExist':
        return "Vous devez renseigner le code postale du lieu du poste.";
      case 'city--doesntExist':
        return "Vous devez renseigner la ville du poste. ";
      case 'contract--notValid':
        return "Le contrat entré pour ce poste n'est pas valide.";
      case 'description--doesntExist':
        return 'Vous devez rentrer la description du poste.';
      case 'candidateProfile--doesntExist':
        return "Vous devez renseigner le profil du candiat recherché pour ce poste.";
      case "yearsOfStudies--tooLong":
        return "Veuillez modifier les années d'études requises pour ce poste, la valeur entrée est trop grande."
      case "yearsOfExperience--doesntExist":
        return "Veuillez préciser un nombre d'années d'expérience requises pour ce poste."
      case "city--tooLong":
        return "La ville entrée pour ce poste est trop longue."
      case "postalCode--tooLong":
        return "Le code postale entré pour cette ville est trop long."
      case "state--tooLong":
        return "La région du poste comporte un nombre de caractère supérieur au nombre autorisé."
      case "location--doesntExist_remote":
        return "Vous ne pouvez pas multiposter un poste qui est en télétravail, veuillez entrer un lieu fixe."
      case "presentation--doesntExist":
        return "Vous devez entrer une description de l'entrepris du poste."
      case "location--doesntExist_roaming":
        return "Vous ne pouvez pas multiposter un poste qui est en itinérance, veuillez entrer un lieu fixe."
      default:
        return `Erreur pour le champ ${item}`;
    }
  });
  return formattedArray;
}