import {devLog} from "../../utils/devLog/devLog";
import React, {useEffect, useState} from "react";
import PageLayout from "../../layouts/PageLayout";
import Spaces from "../../components/sharedComponents/Spaces";
import axios from "axios";


export {CandidateInterestedUpdate};

const CandidateInterestedUpdate = (props: any) => {

    const cid: string = props.match.params.cid || "";
    const status: string = props.match.params.status || "";

    useEffect(() => {
        (async() => {
            try {
                const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/interested/${cid}/${status}`, {
                } ,{
                    withCredentials: true
                });
            } catch(error) {
                devLog(error);
            }
        })();
    }, []);

    return (<PageLayout fullWidth={true} grey={true} page={props.page} status={"ok"}>

        <Spaces n={6}/>

        <div className={"text--center"}>
            Votre choix a bien été enregistré. Vous pouvez fermer cette page.
            <br/><br/>
            Vous avez selectionné: {status === "interested" && <span>Je suis intéressé(e)</span>} {status !== "interested" && <span>Je ne suis pas intéressé(e)</span>}
            <br/><br/>
            Si vous souhaitez changer de choix, vous pouvez sélectionner une autre action dans le même email.
        </div>

        <Spaces n={5}/>


    </PageLayout>);

}

