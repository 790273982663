import React, {useState} from "react";
import ButtonCircle from "./ButtonCircle";

export default (props: {
    onClick: Function;
    validation: boolean;
}) => {

    const [activeDeletion, setActiveDeletion] = useState(false);

    return(<>
        {
            !activeDeletion && <>
                <ButtonCircle title={"Supprimer"} alt={"delete"} imgUrl={"https://static.myshortlist.co/btn-bin.svg"} onClick={() => {
                    if(props.validation) {
                        setActiveDeletion(true);
                    } else {
                        props.onClick();
                    }
                }} />
            </>
        }
        {
            activeDeletion && <>
                <ButtonCircle title={"Ne pas supprimer"} alt={"cancel"} imgUrl={"https://static.myshortlist.co/btn-cross.svg"} onClick={() => setActiveDeletion(false)} />
                <ButtonCircle title={"Confirmer la suppression"} alt={"confirm"} imgUrl={"https://static.myshortlist.co/btn-check.svg"} onClick={() => {
                    props.onClick();
                    setActiveDeletion(false);
                }} />
            </>
        }
    </>);
}