export default (args: {dispatchDataV2: Function}) => (columnId: string, keyValuePairs: any) => {
  
  args.dispatchDataV2({
    type: "UPDATE_COLUMN",
    value: {
      columnId: columnId,
      keyValuePairs: keyValuePairs
    }
  });
  
}