import React from "react";
import Spaces from "../../../Spaces";

export {WCOName};

const WCOName = (props: {
  white: boolean,
  data: any,
  updateData: Function,
  isPageLoading: boolean,
  updateColumnFront: Function,
  saveColumnData: Function,
}) => {
  
  return(<>
  
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
      <div>
        <span className={"text--bold text--magnified11x"}>Nom de la colonne:</span> Donne un nom significatif à la colonne
      </div>
      <Spaces n={0}/>
      <div className={"text--center"}>
        <input type={"text"} className={"input--v2"} name={"name"} value={props.data.name} onChange={props.updateData({maximumLength: 25})}/>
      </div>
      <Spaces n={0}/>
      <div className={"text--center"}>
        <button disabled={props.data.name.length === 0 || props.isPageLoading} className={"btn-secondary"} onClick={async() => {
          // Update front
          props.updateColumnFront(props.data._id, {name: props.data.name});
          // Update back
          props.saveColumnData(props.data._id, props.data);
        }}>
          Enregistrer
        </button>
      </div>
    </article>
  
  </>);
}