import {capitalizeSentence} from "../../../../../../utils/converters";
import Spaces from "../../../../../../components/sharedComponents/Spaces";
import getContract from "../../../../../../utils/getContract";
import getExperience from "../../../../../../utils/getExperience";
import getStarting from "../../../../../../utils/getStarting";
import getSalary from "../../../../../../utils/getSalary";
import {getLocation} from "../../../../../../utils/getLocation";
import React, {useState} from "react";

export default (props: {
  mission: any
}) => {

  const [expanded, setExpanded] = useState(false);

  return (<>

    {/*<article className={`white-container ${expanded ? "white-container--grey":"white-container--white"}`}>*/}
    <article className={`white-container ${expanded ? "white-container--grey":"white-container--grey"}`}>

        <div className={"text--magnified11x cursor--pointer"} onClick={() => {
          setExpanded((ps: boolean) => !ps);
        }}>
          <img className={`text--hollow arrow-btn ${expanded ? "arrow-btn--activated" : ""}`}
               src={"https://static.myshortlist.co/btn-arrow-list.svg"}
               alt={"toggle"}/>
          Voir la description du poste</div>
      {
        expanded && <>

          <div className={"animation-fade-in"}>
          <Spaces n={1}/>

          <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"}>
            <div>Titre du poste: <span
              className={"text--new-blue"}> {capitalizeSentence(props.mission?.positionWanted)}</span></div>
          </div>

          <Spaces n={0}/>

          {props.mission?.description && <>
            <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"}>
              <div>Description: <span className={"text--new-blue"}> {props.mission?.description}</span></div>
            </div>
            <Spaces n={0}/></>}

          {props.mission?.documentUrl && <>
            <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"}>
              <div>Fiche de poste: <a href={props.mission?.documentUrl}>Lien vers la fiche de poste</a></div>
            </div>
            <Spaces n={0}/></>}

          <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"}>
            <div>Contrat: <span className={"text--new-blue"}> {getContract(props.mission?.contract)}</span></div>
          </div>

          <Spaces n={0}/>

          <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"}>
            <div>Expérience requise pour le poste: <span
              className={"text--new-blue"}> {getExperience(props.mission?.yearsOfExperience)}</span></div>
          </div>

          <Spaces n={0}/>

          <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"}>
            <div>Dates: <span
              className={"text--new-blue"}> {getStarting(props.mission.from, props.mission.asSoonAsPossible, props.mission.duration, props.mission.permanent)}</span>
            </div>
          </div>

          <Spaces n={0}/>

          <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"}>
            <div>Salaire: <span
              className={"text--new-blue"}> {getSalary(props.mission.salaryMin, props.mission.salaryMax, props.mission.salaryBy, 'eur')}</span>
            </div>
          </div>

          <Spaces n={0}/>

          <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"}>
            <div>Lieu: <span
              className={"text--new-blue"}> {getLocation(props.mission.remote, props.mission.roaming, props.mission.roamingPlace, props.mission.location)}</span>
            </div>
          </div>

          <Spaces n={0}/>

          {
            !!props.mission.advantages && props.mission.advantages.length !== 0 && <>
              <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"}>
                <div>
                  Avantages:
                  <br/>
                  {props.mission.advantages?.map((item: any, key: number) => (
                    <div key={key}>- <span className={"text--new-blue"}>{item}</span></div>))}</div>
              </div>
              <Spaces n={0}/></>
          }

          {
            !!props.mission.documentUrl &&
            <div className={"link-box2 link-box2--white link-box2--left animation-fade-in"}>
              <div>Fiche de poste: <a className={"modern-link"} href={props.mission.documentUrl}>Télécharger la fiche de
                poste</a></div>
            </div>
          }
          </div>
        </>
      }
      
      

    </article>
  </>);
}