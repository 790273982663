import {notification} from "../../../../../../utils/notification";

export default (appContextV2: any, props: any) => (formId: any, index: number) => {
  if(formId === "form") {
    const newForm1 = [...[...props.form.form].splice(0, index), ...[...props.form.form].splice(index + 1, props.form.form.length - 1 - index)];
    props.setForm()({target: {type: "custom", name: "form", value: newForm1}});
  }
  if(formId === "form2") {
    const newForm2 = [...[...props.form.form2].splice(0, index), ...[...props.form.form2].splice(index + 1, props.form.form2.length - 1 - index)];
    props.setForm()({target: {type: "custom", name: "form2", value: newForm2}});
  }
  notification(appContextV2.dispatchPanels, "Question retirée", "success");
}