import React, {useState} from "react";
import FLTRPage1 from "./componentsFirstLoginTutorialRecruiter/FLTRPage1";
import FLTRPage2 from "./componentsFirstLoginTutorialRecruiter/FLTRPage2";
import FLTRPage3 from "./componentsFirstLoginTutorialRecruiter/FLTRPage3";
import FLTRPage4 from "./componentsFirstLoginTutorialRecruiter/FLTRPage4";
import FLTRPage5 from "./componentsFirstLoginTutorialRecruiter/FLTRPage5";
import FLTRPage6 from "./componentsFirstLoginTutorialRecruiter/FLTRPage6";

export default (props: any) => {

  const [step, setStep] = useState(1);

  switch (step.toString()) {

    case '1':
      return(<FLTRPage1 step={step} setStep={setStep} {...props} />);

    case '2':
      return(<FLTRPage2 step={step} setStep={setStep} {...props} />);

    case '3':
      return(<FLTRPage3 step={step} setStep={setStep} {...props} />);

    case '4':
      return(<FLTRPage4 step={step} setStep={setStep} {...props} />);

    case '5':
      return(<FLTRPage5 step={step} setStep={setStep} {...props} />);

    case '6':
      return(<FLTRPage6 step={step} setStep={setStep} {...props} />);

    default:
      return (<></>);
  }
}