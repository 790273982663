import React, {useContext, useEffect, useState} from "react";
import WindowWrapper from "../../../../layouts/WindowLayout";
import {notification} from "../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import axios from "axios";
import {displayDate} from "../../../../utils/displayDate";

export default (props: {}) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

    const [fetchedStatus, setFetchedStatus] = useState('asleep');

    const [fetchedMissions, setFetchedMissions] = useState([]);

    const [success, setSuccess] = useState(false);

    const [filter, setFilter] = useState('');

    console.log("fetchedMissions", fetchedMissions);

    const selected = appContextV2.appPanels.window.windowOptions.data.selected || [];

    /**
     * Load all Kanbans
     */
    useEffect(() => {
        try {
            (async () => {
                setFetchedStatus("fetching");
                const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/missions2`, {withCredentials: true});
                if (fetched.data.status !== "ok") {
                    return setFetchedStatus("error");
                }
                setFetchedMissions(fetched.data.data);
                setFetchedStatus("fetched");
            })()
        } catch (error) {
            console.error(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }, []);

    /**
     * Import candidates
     */
    const importCandidates = async(missionId: string) => {
        try {
            setFetchedStatus("fetching");

            const fetched = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/communities/pool/ci/export/to/mission`, {
                selected: selected,
                toMissionId: missionId,
                fromResearch: true
            }, {
                withCredentials: true,
            }));

            if (fetched.data.status !== "ok") {
                setFetchedStatus("error");
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }

            notification(appContextV2.dispatchPanels, "Import reussi", "success");

            setSuccess(true);
            setFetchedStatus("fetched");
        } catch (error) {
            console.error(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    if (success) {
        return (<WindowWrapper size={"big"} status={fetchedStatus as 'asleep' | 'fetching' | 'fetched'}
                               windowTitle={"Choisis un Kanban dans lequel importer les candidats."}>
            <div className={'text--center'}>Bravo !</div>
        </WindowWrapper>)
    }

    if (fetchedStatus === "error") {
        return (<WindowWrapper size={"big"} status={fetchedStatus as 'asleep' | 'fetching' | 'fetched'}
                               windowTitle={"Choisis un Kanban dans lequel importer les candidats."}>
            <div className={'text--center'}>Erreur</div>
        </WindowWrapper>)
    }

    return <>
        <WindowWrapper size={"big"} status={fetchedStatus as 'asleep' | 'fetching' | 'fetched'}
                       windowTitle={"Choisis un Kanban dans lequel importer les candidats."}>

            <input placeholder={"Recherche d'un Kanban"} className={""} value={filter} onChange={(e) => {
                setFilter(e.target.value)
            }}/>

            {fetchedMissions.filter((item: any) => {
                if(!filter) return true;
                if(item.positionWanted?.toLowerCase().includes(filter.toLowerCase())) return true;
                return false;
            }).map((mission: any) => (<div style={{
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "space-between",
                alignItems: "center",
                margin: '12px 0'
            }}>
                <div>{displayDate({original: mission.dateOfCreation})} {mission.positionWanted} ({mission.numberOfCandidacies})</div> <button onClick={() => {
                importCandidates(mission.missionId);
                }}>Importer</button>
            </div>))}

        </WindowWrapper>
    </>
}