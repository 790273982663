import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../components/App/context/AppContext";
import axios from "axios";
import SubPageLayout from "../../../layouts/SubPageLayout";
import Spaces from "../../../components/sharedComponents/Spaces";
import {useAssistant} from "../../../utils/useAssistant/useAssistant";
import {devLog} from "../../../utils/devLog/devLog";
import ProfileElementText from "../../../layouts/ProfileElementText";
import {notification} from "../../../utils/notification";
import {ProfileElementBooleanAutorisation} from "../../../layouts/ProfileElementBooleanAutorisation";
import {RecruiterProfilesUserAccount} from "./componentsRPUser/RecruiterProfilesUserAccount";
import {RecruiterProfilesUserPublicity} from "./componentsRPUser/RecruiterProfilesUserPublicity";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [profile, statusGet, updateDataGet, assistantGet] = useAssistant();
  
  useEffect(() => {
    (async() => {
      try {
        assistantGet.fetching();
        const fetched = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/u/my/profile`, {withCredentials: true})).data;
        if(fetched.status === "ok") {
  
          assistantGet.fetched();
          assistantGet.setForm(fetched.data);
        } else {
          assistantGet.error();
        }
      } catch (error) {
        devLog(error);
        assistantGet.error();
      }
    })();
  }, []);
  
  devLog("get", profile);
  
  const updateSingleton = async(nameToUpdateFront: string, nameToUpdateBack: string, newValue: any) => {
    try {
      notification(appContextV2.dispatchPanels, "Envoi de la mise à jour...", "information");
      updateDataGet()({target: {type: "custom", name: nameToUpdateFront, value: newValue}});
      const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/u/my/profile`, {
        [nameToUpdateBack]: newValue,
      }, {withCredentials: true})).data;
      if(fetched.status === "ok") {
        notification(appContextV2.dispatchPanels, "Donnée mise à jour avec succès", "success");
      } else {
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    } catch(error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  return (<SubPageLayout status={statusGet} subPage={props.subPage}>
    
    {/*<Spaces n={2}/>*/}
    
    <RecruiterProfilesUserAccount updateDataGet={updateDataGet} assistantGet={assistantGet} profile={profile} updateSingleton={updateSingleton} />
    
    <Spaces n={1}/>
  
    <RecruiterProfilesUserPublicity assistantGet={assistantGet} profile={profile} updateSingleton={updateSingleton} />
  
  </SubPageLayout>);
}
