import React, {useContext, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {format} from "date-fns";
import LoadingStyle2 from "../../../../components/sharedComponents/Loading/LoadingStyle2";
import {notification} from "../../../../utils/notification";
import axios from "axios";
import {CASError} from "./CASError";
import {CASSuccess} from "./CASSuccess";

export {CASAvailable};

function CASAvailable(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [dataFetchingStatus, setDataFetchingStatus] = useState("asleep");
  const [dateOfAvailability, setDateOfAvailability] = useState(format(new Date(), "yyyy-MM-dd"));
  
  const sendRequest = async() => {
    try {
      
      setDataFetchingStatus("fetching");
      
      const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/ca/available`, {
        token: props.token,
        dateOfAvailability: dateOfAvailability
      },{
        withCredentials: true,
      });
      
      if(fetched.data.status !== "ok") {
        setDataFetchingStatus("error");
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        return;
      }
  
      setDataFetchingStatus("fetched");
  
      notification(appContextV2.dispatchPanels, "Action enregistrée", "success");
      
      return;
    
    } catch(error) {
      setDataFetchingStatus("error");
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  if(dataFetchingStatus === "error") return(<CASError />);
  
  if(dataFetchingStatus === "fetched") return(<CASSuccess />);
  
  return(<>
    <div className={"text--center"}>
      
      À partir de quand serez-vous disponible ?
      
      <Spaces n={1}/>
  
      <span className={"text--grey"}>
        Cette information ne sera distribuée qu'à l'entreprise qui vous a posé la question
      </span>
      
      <Spaces n={2}/>
      
      <input className={"input--v2"} type={"date"} value={dateOfAvailability} onChange={(e) => setDateOfAvailability(e.target.value)}/>
  
      <Spaces n={1}/>
      
      <button disabled={dataFetchingStatus !== "asleep"}
              onClick={() => sendRequest()}
              className={"btn-secondary"}>
        Envoyer
      </button>
      
      {
        dataFetchingStatus === "fetching" && <div className={"space-flex--center"}>
          <LoadingStyle2/>
        </div>
      }
      
    </div>
  </>);
}