import React from "react";
import DisplayTutorialSteps from "../../DisplayTutorialSteps";

export default (props: any) => {
  
  return (<div className={"window-tutorial__content"}>
    
    <div className={"window-tutorial__main-content animation-fade-in"}>
      <h1 className={"window-tutorial__title1"}> Visualisez les candidatures </h1>
      
      {/*<img className={"window-tutorial__picture"}/>*/}
      
      <p className={"window-tutorial__container"}><br/>
        Gérer les candidatures pour un poste n'a jamais été aussi simple.<br/><br/>
        Pour chaque poste, vous avez un "Kanban" ou une vue en colonnes, qui vous permet de visualiser tout le process
        de recrutement.<br/><br/>
        Les candidatures que vous recevez apparaissent toujours dans la première colonne. Déplacez-les en simple
        drag-and-drop pour les changer de colonne.<br/><br/>
        Sur chaque colonne vous pouvez appliquer une <b className={"window-tutorial__b"}>action de colonne</b> pour
        automatiquement activer une action à tous les candidats qui seront déplacés pour la première fois dans cette
        colonne.<br/><br/>
        Ces actions sont:<br/><br/>
        
        <ul className={"window-tutorial__ul"}>
          <li>Envoi d'email</li>
          <li>Envoi de SMS</li>
          <li>Demander le CV</li>
          <li>Enregistrer dans une communauté</li>
        </ul>
      </p>
    </div>
    
    <div className={"window-tutorial__secondary-content"}>
      <DisplayTutorialSteps {...props}/>
      
      <div>
        <button className={"btn-secondary"} onClick={async(e) => props.hideTutorialForEver()}>
          Ne plus afficher
        </button>
        
        <button className={"btn-secondary"} onClick={() => props.setStep((ps: number) => ps + 1)}>Suivant</button>
      </div>
    </div>
  
  </div>);
}

