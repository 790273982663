export default (args: {
  dragged: any,
  draggedToFormId: string,
  draggedToIndex: number,
  props: any,
}) => {
  
  const movedToSameForm: boolean = args.dragged.draggedFromFormId === args.draggedToFormId;
  const movedToSameIndex: boolean = args.dragged.draggedFromIndex === args.draggedToIndex;
  const movedDown: boolean = args.dragged.draggedFromIndex < args.draggedToIndex && !movedToSameIndex;
  
  if(movedToSameForm && movedToSameIndex) {
    return;
  }
  
  let movedQuestion = {};
  
  if(args.dragged.draggedFromFormId === "form") {
    movedQuestion = [...args.props.form.form][args.dragged.draggedFromIndex];
  }
  if(args.dragged.draggedFromFormId === "form2") {
    movedQuestion = [...args.props.form.form2][args.dragged.draggedFromIndex];
  }
  
  // --------------------------------- Moved to another form
  if(!movedToSameForm) {
    // From form 1
    if(args.dragged.draggedFromFormId === "form") {
      let newForm1 = [
        ...[...args.props.form.form].splice(0, args.dragged.draggedFromIndex),
        ...[...args.props.form.form].splice(args.dragged.draggedFromIndex + 1, args.props.form.form.length - 1 - args.dragged.draggedFromIndex)
      ];
      let newForm2 = [...args.props.form.form2];
      newForm2.splice(args.draggedToIndex + 1, 0, movedQuestion);
      args.props.setForm()({target: {type: "custom", name: "form", value: newForm1}});
      args.props.setForm()({target: {type: "custom", name: "form2", value: newForm2}});
    } else {
      // From form 2
      let newForm2 = [
        ...[...args.props.form.form2].splice(0, args.dragged.draggedFromIndex),
        ...[...args.props.form.form2].splice(args.dragged.draggedFromIndex + 1, args.props.form.form2.length - 1 - args.dragged.draggedFromIndex)
      ];
      let newForm1 = [...args.props.form.form];
      newForm1.splice(args.draggedToIndex + 1, 0, movedQuestion);
      args.props.setForm()({target: {type: "custom", name: "form", value: newForm1}});
      args.props.setForm()({target: {type: "custom", name: "form2", value: newForm2}});
    }
  }
  
  // Moved to the same form, down
  if(movedToSameForm && movedDown) {
    // From form 1
    if(args.dragged.draggedFromFormId === "form") {
      let newForm1 = [...args.props.form.form];
      newForm1.splice(args.draggedToIndex + 1, 0, movedQuestion);
      newForm1 = [
        ...[...newForm1].splice(0, args.dragged.draggedFromIndex),
        ...[...newForm1].splice(args.dragged.draggedFromIndex + 1, newForm1.length - 1 - args.dragged.draggedFromIndex),
      ]
      args.props.setForm()({target: {type: "custom", name: "form", value: newForm1}});
    }
    // From form 2
    if(args.dragged.draggedFromFormId === "form2") {
      let newForm2 = [...args.props.form.form2];
      newForm2.splice(args.draggedToIndex + 1, 0, movedQuestion);
      newForm2 = [
        ...[...newForm2].splice(0, args.dragged.draggedFromIndex),
        ...[...newForm2].splice(args.dragged.draggedFromIndex + 1, newForm2.length - 1 - args.dragged.draggedFromIndex),
      ]
      args.props.setForm()({target: {type: "custom", name: "form2", value: newForm2}});
    }
  }
  
  // Moved to the same form, up
  if(movedToSameForm && !movedDown) {
    // From form 1
    if(args.dragged.draggedFromFormId === "form") {
      let newForm1 = [...args.props.form.form];
      newForm1.splice(args.draggedToIndex, 0, movedQuestion);
      newForm1 = [
        ...[...newForm1].splice(0, args.dragged.draggedFromIndex + 1),
        ...[...newForm1].splice(args.dragged.draggedFromIndex + 2, newForm1.length - 1 + 1 - args.dragged.draggedFromIndex),
      ]
      args.props.setForm()({target: {type: "custom", name: "form", value: newForm1}});
    }
    // From form 2
    if(args.dragged.draggedFromFormId === "form2") {
      let newForm2 = [...args.props.form.form2];
      newForm2.splice(args.draggedToIndex, 0, movedQuestion);
      newForm2 = [
        ...[...newForm2].splice(0, args.dragged.draggedFromIndex + 1),
        ...[...newForm2].splice(args.dragged.draggedFromIndex + 2, newForm2.length - 1 + 1 - args.dragged.draggedFromIndex),
      ]
      args.props.setForm()({target: {type: "custom", name: "form2", value: newForm2}});
    }
  }
  
}