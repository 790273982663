import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import Spaces from "../../../../components/sharedComponents/Spaces";
import BusinessPageJobsHideOpennedJobs from "./componentsBusinessPageJobsV3/BusinessPageJobsHideOpennedJobs";
import BusinessPageJobsNoJobs from "./componentsBusinessPageJobsV3/BusinessPageJobsNoJobs";
import {Link} from "react-router-dom";
import {displayDate} from "../../../../utils/displayDate";
import {getLocation} from "../../../../utils/getLocation";
import {shortenText} from "../../../../utils/shortenText";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<SubPageLayout status={"ok"} subPage={props.subPage}>
    
    <Spaces n={4}/>
    
    {!props.business?.showOtherJobsInBusiness && <BusinessPageJobsHideOpennedJobs/>}
    
    {(props.business?.showOtherJobsInBusiness && props.business?.opennedPositions?.length === 0) && <BusinessPageJobsNoJobs/>}
    
    {(props.business?.showOtherJobsInBusiness && props.business?.opennedPositions?.length !== 0) && <section className={"business-page-jobs"}>
      <div className={"text--center text--magnified2x"}>
        Postes ouverts chez {props.business?.businessName}
      </div>
      
      <Spaces n={2}/>
      
      <div className={"business-page-jobs__container"}>
        {props.business?.opennedPositions?.map((item: any, key: number) => (
          
          // <button key={key} className={""} onClick={() => {
          // }}>
          //   <a href={`${window.location.origin}/apply/${props.pageInfos.slugname}/${props.pageInfos.slugnumber}/${item.rank}/apply`}>
          //   {item.positionWanted}
          //   </a>
          // </button>
          <div className={"business-page-jobs__container__job"}>
            
            <div className={"business-page-jobs__container__job__left"}>
              <div className={"job__position"}><span className={"job__small-title"}>Titre du poste</span> {shortenText(item.positionWanted, 32)} <span className={"job__contract"}>{item.contract?.toUpperCase()}</span></div>
              <div className={"job__date"}><span className={"job__small-title"}>Posté le</span> {displayDate({original: item.dateOfCreation})}</div>
              <div className={"job__description"}>
                <span className={"job__small-title"}>Description du poste</span>
                {shortenText(item.description, 400)}
              </div>
              <div><span className={"job__small-title"}>Lieu du poste</span> {getLocation(item.remote, item.roaming, item.roamingPlace, item.location)}</div>
              
            </div>
  
            <div className={"business-page-jobs__container__job__right"}>
              <button key={key} className={""} onClick={() => {
              }}>
                <a href={`${window.location.origin}/apply/${props.pageInfos.slugname}/${props.pageInfos.slugnumber}/${item.rank}/apply`} target={"_blank"}>
                Postuler
                </a>
              </button>
            </div>
            
          </div>
        
        ))}
      </div>
      <Spaces n={2}/>
    </section>}
  </SubPageLayout>);
}