import React from "react";

export {PagesComponent};

const PagesComponent = (props: {
  page: number,
  pages: number,
  setPage: Function,
}) => {
  
  if(props.pages === 1) {
    return (<>
    </>);
  }
  
  const pages: number[] = [];
  
  for(let i = 1; i <= props.pages; i++) {
    pages.push(i);
  }
  
  return (<>
    <button className={"previous-btn"} disabled={props.page===1} onClick={() => {props.setPage((ps:any) => (ps-1))}}>
      <img  src={"https://static.myshortlist.co/previous.svg"}/>
    </button>
    {pages.map((item: number, key: number) => (<button className={`btn-page ${item === props.page ? "btn-page--selected" : ""}`}
                                                       key={key} onClick={() => {props.setPage(item, false);}}>
      {item}
    </button>))}
    <button className={"previous-btn"} disabled={props.page===props.pages} onClick={() => {props.setPage((ps:any) => (ps+1))}}>
      <img src={"https://static.myshortlist.co/next.svg"}/>
    </button>
  </>);
}
