import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../../components/App/context/AppContext";
import Spaces from "../../../../../../components/sharedComponents/Spaces";

export default (props: any) => {
    
    const [showQuestion, setShowQuestion] = useState(false);

    useEffect(() => {
        (() => {
            setShowQuestion (props.mission.defaultQuestions?.yearsOfExperience);
            if(!props.mission.defaultQuestions?.yearsOfExperience){
                props.nextStep();
            }

        })();
    }, []);

    if(!showQuestion) {
        return (<><Spaces n={15}/></>)
    }

    return (
        <>
            <Spaces n={2}/>
            <div className={"apply-container"}>



        {!props.mission.modernTone && <div className={"assistant--v2-title"}>
            Quelle est votre expérience à ce poste ?
        </div>}
        {props.mission.modernTone && <div className={"assistant--v2-title"}>
            Quelle est ton expérience à ce poste ?
        </div>}
        <Spaces n={2}/>

        <section className={"assistant--v2-container"}>
            <button className={"btn-assistant-main"} onClick={()=>{props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.yearsOfExperience", value: 0}});
                props.nextStep();}}>Je n'ai pas d'experience à ce poste</button>
            <button className={"btn-assistant-main"}  onClick={()=>{  props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.yearsOfExperience", value: 0}});
                props.nextStep();}} >de 0 à 1 an</button>
            <button className={"btn-assistant-main"}  onClick={()=>{ props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.yearsOfExperience", value:1}});
                props.nextStep();}} >de 1 à 3 ans</button>
            <button className={"btn-assistant-main"}  onClick={()=>{  props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.yearsOfExperience", value: 3}});
                props.nextStep();}} >de 3 à 6 ans</button>
            <button className={"btn-assistant-main"}  onClick={()=>{  props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.yearsOfExperience", value:  6}});
                props.nextStep();}} >de 6 à 10 ans</button>
            <button className={"btn-assistant-main"}  onClick={()=>{  props.updateAnswer()({target: {type: "custom", name: "defaultAnswers.yearsOfExperience", value: 10}});
                props.nextStep();}} >+ de 15 ans</button>
        </section>


        <Spaces n={2}/>

    </div>
            </>);
}