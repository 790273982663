import AppContext, {IAppContext, IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext, useEffect, useState} from "react";
import XLSX from 'xlsx';
import WindowWrapper from "../../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {useWizard} from "use-wizard";
import {useForm} from "use-formidable";
import {notification} from "../../../../utils/notification";
import {FileDrop} from "../../Buttons/FileDrop";
import {devLog} from "../../../../utils/devLog/devLog";
import axios from "axios";
import {format} from "date-fns";
import * as FileSaver from "file-saver";
import {ProfileElementBoolean} from "../../../../layouts/ProfileElementBoolean";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [step, wizard] = useWizard(["addFile", "mapFile", "pending", "result"]);
  const [file, updateFile, formidableFile] = useForm({
    file: null,
  });
  const [salaryBy, setSalaryBy]: [any, Function] = useState("");
  const [saveWithoutEmails, setSaveWithoutEmails] = useState(false);
  const [erasePreviousValues, setErasePreviousValues]: [any, Function] = useState(false);
  const [result, setResult]: [any, Function] = useState({});
  const [parsedFile, setParsedFile]: [any, Function] = useState({
    listOfTitles: [],
    dataRaw: [],
    listOfTitlesWithExtract: [],
  });
  const [mappedValues, setMappedValues] = useState({
    firstName: "",
    lastName: "",
    positionWanted: "",
    salaryMin: "",
    salaryMax: "",
    email: "",
    phone: "",
    mobility: "",
    available: "",
    gradeByTeam: "",
    comment: "",
    trigram: ""
  });
  devLog("parsedFile", parsedFile);
  devLog("mappedValues", mappedValues);
  
  /** Called when a file is added */
  useEffect(() => {
    (async() => {
      if(!!file.file) {
        if(file.file.length > 1) {
          formidableFile.setForm({file: null});
          return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");
        }
        if(!formidableFile.isFileMime(file.file[0], ["xlsx"])) {
          formidableFile.setForm({file: null});
          return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge (xlsx)", "error");
        }
        if(!formidableFile.isFileSmallerThan(file.file[0], 7_000_000)) {
          formidableFile.setForm({file: null});
          return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd", "error");
        }
        
        notification(appContextV2.dispatchPanels, "Mapping du fichier en cours ...", "information");
        
        await parseFile();
        
        wizard.goToStep('mapFile');
      }
    })();
  }, [file.file]);
  
  async function parseFile() {
    try {
      wizard.goToStep('pending');
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = async(e) => {
        /* Parse data */
        // @ts-ignore
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {type: rABS ? 'binary' : 'array', bookVBA: true});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const dataRaw: any = XLSX.utils.sheet_to_json(ws);
        
        /*
          dataRaw looks like this:
          [{
            column_name: value,
            column_name: value,
            column_name: value,
            column_name: value,
          }, {
            column_name: value,
            column_name: value,
          }, {
            column_name: value,
            column_name: value,
            column_name: value,
            column_name: value,
            column_name: value,
          }, {
            column_name: value,
            column_name: value,
          }, {
            column_name: value,
            column_name: value,
          }]
        */
        
        let listOfTitles: string[] = [];
        
        for(let entry of dataRaw) {
          const listOfKeys = Object.keys(entry);
          for(let k of listOfKeys) {
            if(!listOfTitles.includes(k)) {
              listOfTitles.push(k);
            }
          }
        }
        
        let listOfTitlesWithExtract: any = [];
        
        for(let title of listOfTitles) {
          const extract: string[] = [];
          let n: number = 0;
          for(let entry of dataRaw) {
            if(!!entry[title]) {
              extract.push(entry[title]);
              n++;
            }
            if(n === 5) {
              break;
            }
          }
          listOfTitlesWithExtract.push({
            title: title,
            extract: extract,
          });
        }
        
        /*
          listOfTitlesWithExtract looks like this:
          
          [{
            title: "column_name",
            extract: [
              "value1",
              "value2",
              "value3"
            ]
          }, {
            title: "column_name",
            extract: [
              "value1",
              "value2",
              "value3"
            ]
          }, {
            title: "column_name",
            extract: [
              "value1",
              "value2",
              "value3"
            ]
          }]
        */
        
        if(dataRaw.length <= 500) {
          setParsedFile({dataRaw, listOfTitles, listOfTitlesWithExtract});
        } else {
          notification(appContextV2.dispatchPanels, "Le fichier contient trop de candidats (500)", "error");
        }
        
      };
      
      // wizard.goToStep('mapFile')
      
      if(rABS) {
        reader.readAsBinaryString(file.file[0]);
      } else {
        reader.readAsArrayBuffer(file.file[0]);
      }
      
    } catch (error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      devLog(error);
    }
  }
  
  const mapSetter = (dataKey: string, dataValue: string) => {
    setMappedValues((ps: any) => ({
      ...ps,
      [dataKey]: dataValue,
    }));
  }
  
  const startImport = async() => {
    try {
      
      const windowOptions: any = appContextV2.appPanels.window.windowOptions?.data || {};
      wizard.goToStep('pending');
      
      const formatedDataWithMap: any = [];
      
      for(let candidate of parsedFile.dataRaw) {
        
        let candidateToAdd: any = {};
        
        if(!!mappedValues.firstName) {
          if(!!candidate[mappedValues.firstName]) {
            candidateToAdd["firstName"] = candidate[mappedValues.firstName];
          }
        }
        if(!!mappedValues.lastName) {
          if(!!candidate[mappedValues.lastName]) {
            candidateToAdd["lastName"] = candidate[mappedValues.lastName];
          }
        }
        if(!!mappedValues.positionWanted) {
          if(!!candidate[mappedValues.positionWanted]) {
            candidateToAdd["positionWanted"] = candidate[mappedValues.positionWanted];
          }
        }
        if(!!mappedValues.salaryMin) {
          if(!!candidate[mappedValues.salaryMin]) {
            candidateToAdd["salaryMin"] = candidate[mappedValues.salaryMin];
          }
        }
        if(!!mappedValues.salaryMax) {
          if(!!candidate[mappedValues.salaryMax]) {
            candidateToAdd["salaryMax"] = candidate[mappedValues.salaryMax];
          }
        }
        if(!!salaryBy) {
          candidateToAdd["salaryBy"] = salaryBy;
        }
        if(!!mappedValues.email) {
          if(!!candidate[mappedValues.email]) {
            candidateToAdd["email"] = candidate[mappedValues.email].trim().toLowerCase();
          }
        }
        if(!!mappedValues.phone) {
          if(!!candidate[mappedValues.phone]) {
            candidateToAdd["phone"] = candidate[mappedValues.phone];
          }
        }
        if(!!mappedValues.mobility) {
          if(!!candidate[mappedValues.mobility]) {
            candidateToAdd["mobility"] = candidate[mappedValues.mobility];
          }
        }
        if(!!mappedValues.available) {
          if(!!candidate[mappedValues.available]) {
            candidateToAdd["available"] = candidate[mappedValues.available];
          }
        }
        if(!!mappedValues.comment) {
          if(!!candidate[mappedValues.comment]) {
            candidateToAdd["comment"] = candidate[mappedValues.comment];
          }
        }
        if(!!mappedValues.trigram) {
          if(!!candidate[mappedValues.trigram]) {
            candidateToAdd["trigram"] = candidate[mappedValues.trigram];
          }
        }
        if(!!mappedValues.gradeByTeam) {
          if(!!candidate[mappedValues.gradeByTeam]) {
            candidateToAdd["gradeByTeam"] = candidate[mappedValues.gradeByTeam];
          }
        }
        
        formatedDataWithMap.push(candidateToAdd);
        
      }
      
      const dataToSend: any = {
        data: formatedDataWithMap,
        poolId: appContextV2.appPanels.window.windowOptions.data.poolId,
        erasePreviousValues: erasePreviousValues,
        saveWithoutEmails: saveWithoutEmails
      }
      
      notification(appContextV2.dispatchPanels, "L'envoi peu prendre quelques minutes", "information");
      
      const fetched = await axios.post(`${process.env.REACT_APP_API_URL}/v3/communities/pool/ci/import`, dataToSend, {
        withCredentials: true, timeout: 420_000,
      });
      
      if(fetched.data.status !== "ok") {
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      
      setResult(fetched.data.data);
      
      // Update front
      appContextV2.appPanels.window.windowOptions.data.reloadCandidates();
      notification(appContextV2.dispatchPanels, "Import effectué", "success");
      wizard.goToStep('result');
      
    } catch (error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  /** Save xlsx */
  const saveRepport = async() => {
    
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    
    const csvData: any = [];
    
    const arrayOfSelectedCandidates = [];
    if(result.candidatesWithNoMail) {
      for (let c of result.candidatesWithNoMail) {
        arrayOfSelectedCandidates.push({
          rapport: "no_email",
          ...c,
        });
      }
    }
    if(result.candidatesWithNoFirstName) {
      for(let c of result.candidatesWithNoFirstName) {
        arrayOfSelectedCandidates.push({
          rapport: "no_first_name",
          ...c,
        });
      }
    }
    if(result.candidatesWithErrors) {
      for(let c of result.candidatesWithErrors) {
        arrayOfSelectedCandidates.push({
          rapport: "unidentified",
          ...c,
        });
      }
    }
    
    for(let candidate of arrayOfSelectedCandidates) {
      
      const lineToAdd = {
        "rapport": candidate.rapport,
        "first_name": candidate.firstName || "",
        "last_name": candidate.lastName || "",
        "email": candidate.email || "",
        "phone": candidate.phone || "",
        "position": candidate.position || "",
      }
      
      csvData.push(lineToAdd);
    }
    
    const fileName: string = "rapport-erreurs-myshortlist";
    
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  
  switch(step) {
    
    case 'addFile':
      return (<WindowWrapper size={"small"} status={"ok"} windowTitle={""}>
        <div className={"text--center"}>Règles de formatage:</div>
        <Spaces n={0}/>
        <ul className={""}>
          <li>* La première ligne doit comporter les titres des colonnes</li>
          <li>* Un candidat par ligne</li>
          <li>* Un candidat doit avoir un email et un prénom</li>
        </ul>
        <Spaces n={0}/>
        <div className={"space-flex--center"}>
          <FileDrop square={false} cb={updateFile} name={"file"}/>
        </div>
        <Spaces n={0}/>
      </WindowWrapper>);
    
    case 'mapFile':
      return (<WindowWrapper size={"small"} status={"ok"} windowTitle={"Attribue les données"}>
        <form onSubmit={(e) => e.preventDefault()}>
          
          <MapEntry text={"Prénom *:"} dataKey={"firstName"} dataValue={mappedValues.firstName || ""}
                    listOfTitles={parsedFile.listOfTitles} listOfTitlesWithExtract={parsedFile.listOfTitlesWithExtract}
                    mapSetter={mapSetter}/>
          
          <MapEntry text={"Nom de famille:"} dataKey={"lastName"} dataValue={mappedValues.lastName || ""}
                    listOfTitles={parsedFile.listOfTitles} listOfTitlesWithExtract={parsedFile.listOfTitlesWithExtract}
                    mapSetter={mapSetter}/>
          
          <MapEntry text={"Fonction principale:"} dataKey={"positionWanted"} dataValue={mappedValues.positionWanted || ""}
                    listOfTitles={parsedFile.listOfTitles} listOfTitlesWithExtract={parsedFile.listOfTitlesWithExtract}
                    mapSetter={mapSetter}/>
          
          <MapEntry text={"Salaire tranche basse:"} dataKey={"salaryMin"} dataValue={mappedValues.salaryMin || ""}
                    listOfTitles={parsedFile.listOfTitles} listOfTitlesWithExtract={parsedFile.listOfTitlesWithExtract}
                    mapSetter={mapSetter}/>
          
          <MapEntry text={"Salaire tranche haute:"} dataKey={"salaryMax"} dataValue={mappedValues.salaryMax || ""}
                    listOfTitles={parsedFile.listOfTitles} listOfTitlesWithExtract={parsedFile.listOfTitlesWithExtract}
                    mapSetter={mapSetter}/>
          
          {(!!mappedValues.salaryMin || !!mappedValues.salaryMax) && <>
            <p className={"space-flex--space-between"}>
              <span>Le salaire est:</span>
              <select className={"animation-straight-fade-in"} defaultValue={''} value={parsedFile.salaryBy} onChange={(e) => {
                setSalaryBy(e.target.value);
              }}>
                <option disabled value={''}>Choisir</option>
                <option value={"hourly"}>horaire</option>
                <option value={"daily"}>journalier</option>
                <option value={"monthly"}>mensuel</option>
                <option value={"annually"}>annuel</option>
                <option value={"budget"}>budget</option>
                <option value={""}>Aucun</option>
              </select>
            </p>
            <Spaces n={0}/>
          </>}
          
          <MapEntry text={"Email *:"} dataKey={"email"} dataValue={mappedValues.email || ""}
                    listOfTitles={parsedFile.listOfTitles} listOfTitlesWithExtract={parsedFile.listOfTitlesWithExtract}
                    mapSetter={mapSetter}/>
          
          <MapEntry text={"Téléphone:"} dataKey={"phone"} dataValue={mappedValues.phone || ""}
                    listOfTitles={parsedFile.listOfTitles} listOfTitlesWithExtract={parsedFile.listOfTitlesWithExtract}
                    mapSetter={mapSetter}/>
          
          <MapEntry text={"Mobilité:"} dataKey={"mobility"} dataValue={mappedValues.mobility || ""}
                    listOfTitles={parsedFile.listOfTitles} listOfTitlesWithExtract={parsedFile.listOfTitlesWithExtract}
                    mapSetter={mapSetter}/>
          
          <MapEntry text={"Disponibilité:"} dataKey={"available"} dataValue={mappedValues.available || ""}
                    listOfTitles={parsedFile.listOfTitles} listOfTitlesWithExtract={parsedFile.listOfTitlesWithExtract}
                    mapSetter={mapSetter}/>
          
          <MapEntry text={"Note de l'équipe:"} dataKey={"gradeByTeam"} dataValue={mappedValues.gradeByTeam || ""}
                    listOfTitles={parsedFile.listOfTitles} listOfTitlesWithExtract={parsedFile.listOfTitlesWithExtract}
                    mapSetter={mapSetter}/>
          
          <MapEntry text={"Commentaire:"} dataKey={"comment"} dataValue={mappedValues.comment || ""}
                    listOfTitles={parsedFile.listOfTitles} listOfTitlesWithExtract={parsedFile.listOfTitlesWithExtract}
                    mapSetter={mapSetter}/>
          
          <MapEntry text={"Trigram:"} dataKey={"trigram"} dataValue={mappedValues.trigram || ""}
                    listOfTitles={parsedFile.listOfTitles} listOfTitlesWithExtract={parsedFile.listOfTitlesWithExtract}
                    mapSetter={mapSetter}/>
          
          {/*<Spaces n={0}/>*/}
          
          {/*<input type={"checkbox"} checked={erasePreviousValues} onChange={()=>setErasePreviousValues((ps: any)=>!ps)}/> En cas de données déjà présentes, effacer les anciennes ? (Attention, nous conseillons plutôt d'effectuer des changements au cas par cas pour éviter les erreurs. Cette action est irréversible)*/}
        
        </form>
  
        <div className={"profile-element__container"}>
          <div>
            Importer les candidats sans email ?
              <br/>
              <span className={"text--dark-grey text--minified1x"}>Valeur actuelle: {saveWithoutEmails ? "Oui" : "Non"}</span>
          </div>
          <div>
            <label className={"switch-V2"}>
              <input type={"checkbox"} className={"checkbox-on-off"} checked={saveWithoutEmails} onChange={() => {
                setSaveWithoutEmails((ps: boolean) => !ps);
              }} />
              <span className="slider">
              </span>
            </label>
          </div>
        </div>
        
        <Spaces n={0}/>
        
        <p className={'text--center'}>
          <button className={'btn-secondary'} disabled={!(mappedValues.email && mappedValues.firstName)} onClick={() => {
            startImport();
          }}>
            Importer
          </button>
        </p>
        
        <Spaces n={0}/>
      </WindowWrapper>);
    
    case 'result':
      return (<WindowWrapper size={"small"} status={"ok"} windowTitle={""}>
        Import effectué avec succès.
        <Spaces n={1}/>
        - {result.nNewlySaved} nouveaux candidats<br/>
        - {result.nImportedFromOtherPool} candidats importés d'une autre communauté<br/>
        - {result.nAlreadyInThisPool} candidats déjà dans cete communauté<br/>
        - {result.candidatesWithNoMail?.length +
      result.candidatesWithNoFirstName?.length +
      result.candidatesWithErrors?.length} erreurs
        
        {
          (result.candidatesWithNoMail?.length !== 0 || result.candidatesWithNoFirstName?.length !== 0 || result.candidatesWithErrors?.length !== 0) &&
          <div className={"text--center"}>
            <Spaces n={1}/>
            <button className={"btn-secondary"} onClick={() => saveRepport()}>
              Télécharger le rapport d'erreurs
            </button>
          </div>
        }
      
      </WindowWrapper>);
    
    case 'pending':
    default:
      return (<WindowWrapper size={"small"} status={"pending"} windowTitle={""}>
      </WindowWrapper>);
  }
  
}

function MapEntry(props: {
  text: string,
  dataKey: string,
  dataValue: string,
  listOfTitles: any[],
  listOfTitlesWithExtract: any[],
  mapSetter: Function,
}) {
  
  let extract: string[] = props.listOfTitlesWithExtract?.find((item: any) => item.title === props.dataValue)?.extract || [];
  
  return (
    <>
      <p className={"space-flex--space-between"}>
        <span>{props.text}</span>
        <select className={"animation-straight-fade-in"} value={props.dataValue} onChange={(e) => {
          props.mapSetter(props.dataKey, e.target.value)
        }}>
          <option disabled value={""}>Choisir une colonne</option>
          {props.listOfTitles?.map((item: any) => (
            <option value={item}>{item}</option>
          ))}
          <option value={""}>Aucune</option>
        </select>
      </p>
      {
        !!props.dataValue && <>
          <div className={"export-extract"}>
            <p className={"text--center"}>Extrait de valeurs:</p>

            <table>
              <tbody>
              {extract?.map((item: string) => (
                <tr>
                  <td>{item}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        </>
      }
      
      <Spaces n={0}/>
    </>);
  
}