import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";

export default (props: {
  page: "" | "cv" | "doc" | "profile" | "results" | "trigram" | "mail" | "history" | "finder" | "blackBelt" | "Railenium" |"ai",
  setPage: Function,
  inherited: any
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const isClientBlackBelt = appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io";
  const isClientAdminRailenium = appContextV2.appSession.sessionBusiness.businessName === "Railenium"
      && appContextV2.appSession.session.userRole=== "admin";
  const isPageKanban = props.inherited.category === "mission";

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (<div className={"window-candidate-v2__navbar"}>

    <button className={`window-candidate-v2__navbar__item ${props.page === "cv" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
      props.setPage("cv");
    }}>
      CV/DOCS
    </button>

    {/*<button className={`window-candidate-v2__navbar__item ${props.page === "doc" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{*/}
    {/*  props.setPage("doc");*/}
    {/*}}>*/}
    {/*  DOCS/CR*/}
    {/*</button>*/}

    {
      props.inherited.category === "mission" && <button className={`window-candidate-v2__navbar__item ${props.page === "results" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
        props.setPage("results");
      }}>
        Candidature
      </button>
    }

    <button className={`window-candidate-v2__navbar__item ${props.page === "profile" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
      props.setPage("profile");
    }}>
      Flashcard
    </button>

    <button className={`window-candidate-v2__navbar__item ${props.page === "trigram" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
      props.setPage("trigram");
    }}>
      Notes
    </button>

    <button disabled={false} className={`window-candidate-v2__navbar__item ${props.page === "mail" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
      props.setPage("mail");
    }}>
      Messages
    </button>


    <button disabled={false} className={`window-candidate-v2__navbar__item ${props.page === "ai" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
      props.setPage("ai");
    }}>
      IA
    </button>

    <button disabled={false} className={`window-candidate-v2__navbar__item ${props.page === "finder" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
      props.setPage("finder");
    }}>
      Trouver
    </button>

    {
      isClientBlackBelt && isPageKanban && <button disabled={false} className={`window-candidate-v2__navbar__item ${props.page === "blackBelt" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
      props.setPage("blackBelt");
    }}>
      BB
    </button>
    }

    {
      isClientAdminRailenium && <button disabled={false} className={`window-candidate-v2__navbar__item ${props.page === "Railenium" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{
        props.setPage("Railenium");
      }}>
        Railenium
      </button>
    }

    {/*<button className={`window-candidate-v2__navbar__item ${props.page === "history" ? "window-candidate-v2__navbar__item--selected" : ""}`} onClick={()=>{*/}
    {/*  props.setPage("history");*/}
    {/*}}>*/}
    {/*  Historique*/}
    {/*</button>*/}

  </div>);
  
}
