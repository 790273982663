import React from "react";
import {DisplayDictionary} from "../RecruiterParametersDictionaries";

export {RecruiterParametersDictionaryPositions}

const RecruiterParametersDictionaryPositions = (props: any) => {
  
  return(<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Dictionnaire des fonctions</h1>
  
    <DisplayDictionary keyValue={"position"} data={props.dictionary || []} updateDictionaryEntry={()=>{}} deleteDictionaryEntry={()=>{}} />
  
  </div>);
}