import React from "react";
import {ProfileElementBooleanAutorisation} from "../../../../layouts/ProfileElementBooleanAutorisation";

export {RecruiterProfilesUserPublicity};

const RecruiterProfilesUserPublicity = (props: {
  profile: any,
  updateSingleton: Function,
  assistantGet: any,
}) => {
  
  return(<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Publicité du compte</h1>
  
    <ProfileElementBooleanAutorisation text={"Visibilité de mon avatar"}
                                       nameToUpdateBack={"avatar"}
                                       nameToUpdateFront={"avatar"}
                                       description={"On = public, Off = collègues et entreprises après avoir postulé"}
                                       value={props.profile.avatar?.authorisation}
                                       updateData={props.updateSingleton} />

  </div>)
}