import React from "react";
import Spaces from "../../../Spaces";

export {WAMTBPChoice};

const WAMTBPChoice = (props: any) => {
  
  return (<>
    
    <div className={"text--center text--magnified2x"}>Quel type de média ajouter ?</div>
    
    <Spaces n={1}/>
    
    <div className={"regular-media-choice"}>
      
      <button className={"btn-main"} onClick={() => {
        props.windowContext.updateForm()({target: {value: "image", name: "type", type: "custom"}});
        props.windowContext.wizard.goToStep("image");
      }}>
        Image (paysage)
      </button>
      
      <button className={"btn-main"} onClick={() => {
        props.windowContext.updateForm()({target: {value: "imagePortrait", name: "type", type: "custom"}});
        props.windowContext.wizard.goToStep("imagePortrait");
      }}>
        Image (portrait)
      </button>
      
      <button className={"btn-main"} onClick={() => {
        props.windowContext.updateForm()({target: {value: "video", name: "type", type: "custom"}});
        props.windowContext.wizard.goToStep("video");
      }}>
        Vidéo
      </button>
      
      <button className={"btn-main"} onClick={() => {
        props.windowContext.updateForm()({target: {value: "freeText", name: "type", type: "custom"}});
        props.windowContext.wizard.goToStep("freeText");
      }}>
        Texte libre
      </button>
    
    </div>
  </>);
}