import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../components/sharedComponents/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Delay page showing if this step must be jumped */
  const [display, setDisplay] = useState(false);
  
  useEffect(() => {
    const contractsWithoutThisStep: string[] = ["FREELANCE"];
    if(contractsWithoutThisStep.includes(props.form.contract)) {
      props.wizard.nextStep();
    } else {
      setDisplay(true);
    }
  }, []);
  
  if(!display) {
    return(<></>);
  }
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Le poste est-il en temps plein ou en tremps partiel ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container"}>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "isJobFullTime", value: true}});
                props.wizard.nextStep();
              }}>
        Temps plein
      </button>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "isJobFullTime", value: false}});
                props.wizard.nextStep();
              }}>
        Temps partiel
      </button>
      
    </div>
  
  </section>);
}