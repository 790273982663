import Spaces from "../../../../components/sharedComponents/Spaces";
import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";

export default (props: any) => {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

  return (
    <div className={"container white-container"}>
      <h1 className={"profile-edit__title"}>Abonnement actuel</h1>
      <Spaces n={0}/>
      <div className={"text--grey text--center"}>
        Contactez l'équipe pour changer d'abonnement
      </div>
      <Spaces n={0}/>
      <div className={"space-flex--center"}>
        <div className={`business-parameter-plan ${appContextV2.appSession.sessionBusiness.plan !== 3 ? "business-parameter-plan--disabled" : ""}`}>Essai</div>
        <div className={`business-parameter-plan ${(appContextV2.appSession.sessionBusiness.plan !== 2 && appContextV2.appSession.sessionBusiness.plan !== 1) ? "business-parameter-plan--disabled" : ""}`}>Entreprise</div>
        {
          appContextV2.appSession.sessionBusiness.plan === 4 && <div className={`business-parameter-plan ${appContextV2.appSession.sessionBusiness.plan !== 4 ? "business-parameter-plan--disabled" : ""}`}>Pilote</div>
        }

      </div>
    </div>
  )
}