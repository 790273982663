
import React from "react";

export default (props: {n: number}) => {
  
  const spaces: string[] = [];
  
  for(let i=0; i<=props.n; i++) {
    spaces.push("");
  }
  
  return (
    <>
      <div>
        {spaces.map((item: any, key: number)=>(<br key={key}/>))}
      </div>
    </>
  );
  
  
  
}