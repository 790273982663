import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../App/context/AppContext";
import {SecondaryNavBarRecruiterMain} from "./componentsNavBarSmartphones/SecondaryNavBarRecruiterMain";
import {SecondaryNavBarRecruiterParameters} from "./componentsNavBarSmartphones/SecondaryNavBarRecruiterParameters";
import {SecondaryNavBarRecruiterProfiles} from "./componentsNavBarSmartphones/SecondaryNavBarRecruiterProfiles";
import {SecondaryNavBarCandidateMain} from "./componentsNavBarSmartphones/SecondaryNavBarCandidateMain";

export {NavBarSmartphones};

/**
* This navbar shows only on smartphones, on the bottom of the screen.
 * It's content is the SubNavbar content (left part of the desktop navbar)
*/
const NavBarSmartphones = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const page = appContextV2.appNavigation.page;
  const space = appContextV2.appSession.app.space;
  
  /**
   * Defines on which pages each navbar should be displayed
   */
  const pagesWithRecruiterMainNavbar = ["Terms", "Policy", "AboutUs", "ContactUs", "HelpCenter", "Invite",
    "RecruiterNewMission", "RecruiterMissions", "RecruiterCalendar",
    "RecruiterPools", "RecruiterAmbassadors", "RecruiterLinks"];
  const pagesWithRecruiterParametersNavbar = ["RecruiterParameters"];
  const pagesWithRecruiterProfilesNavbar = ["RecruiterProfiles"];
  
  if(space === "recruiter" && pagesWithRecruiterMainNavbar.includes(page)) {
    return(<SecondaryNavBarRecruiterMain/>);
  }
  
  if(space === "recruiter" && pagesWithRecruiterParametersNavbar.includes(page)) {
    return(<SecondaryNavBarRecruiterParameters/>);
  }
  
  if(space === "recruiter" && pagesWithRecruiterProfilesNavbar.includes(page)) {
    return(<SecondaryNavBarRecruiterProfiles />);
  }
  
  if(space === "candidate") {
    return(<SecondaryNavBarCandidateMain />);
  }
  
  /** Fallback */
  return(<></>);
}