import React from "react";
import {SecondaryNavBarWrapper} from "./shared/SecondaryNavBarWrapper";
import {SubMenuRecruiterProfilesContent} from "../SubNavbarComponents/SubMenuRecruiterProfilesContent";

export {SecondaryNavBarRecruiterProfiles};

function SecondaryNavBarRecruiterProfiles(props: any) {
  
  return (<div className={"hide-on-computers"}>
    <SecondaryNavBarWrapper content={<SubMenuRecruiterProfilesContent/>}/>
  </div>);
}
