import Spaces from "../../../Spaces";
import React, {useState} from "react";
import axios from "axios";
import {RecruiterAmbassador} from "../../../../../pages/privateRecruiter/componentsRecruiterAmbassadors/RecruiterAmbassador";
import {isEmailValid} from "../../../../../utils/typeGuards";

export {WCONotify};

const WCONotify = (props: {
  white: boolean,
  data: any,
  updateData: Function,
  isPageLoading: boolean,
  updateColumnFront: Function,
  saveColumnData: Function,
  switchAction: Function,
  missionId: any,
}) => {
  
  const [emails, setEmails] = useState(props.data.notifyEmails ?? []);
  
  const isEmail1Wrong = emails[0] && !isEmailValid(emails[0]);
  const isEmail2Wrong = emails[1] && !isEmailValid(emails[1]);
  const isEmail3Wrong = emails[2] && !isEmailValid(emails[2]);
  const isEmail4Wrong = emails[3] && !isEmailValid(emails[3]);
  
  const isButtonSaveOk = !isEmail1Wrong && !isEmail2Wrong && !isEmail3Wrong &&!isEmail4Wrong;
  
  return (<>
    
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
      <div className={"space-flex--space-between"}>
        <span className={"text--bold text--magnified11x"}>Activer l'action "Demander une évaluation" ?</span>
        <div>
          <label className={"switch-V2"}>
            <input type={"checkbox"} className={"checkbox-on-off"} checked={!!props.data.activateNotify}
                   onChange={() => {
                     if(!!props.data.activateNotify) {
                       // Turn off
                       // updateFronts
                       props.updateColumnFront(props.data._id, {activateNotify: false});
                       props.updateData()({
                         target: {
                           type: "custom",
                           name: "activateNotify",
                           value: false
                         }
                       });
                       // .. update data
                       props.saveColumnData(props.data._id, {...props.data, activateNotify: false});
                     }
                     if(!props.data.activateNotify) {
                       props.switchAction("activateNotify");
                
                     }
                   }}/>
            <span className="slider"> </span>
          </label>
        </div>
      </div>
  
      <Spaces n={0}/>
      <div className={"text--center text--grey"}>
        Les personnes dont vous entrerez les email recevront une notification pour tout candidat entrant dans cette colonne. Cette notification les invitera à évaluer les candidats.
      </div>
      
      {/*------------------- Content*/}
      {
        !!props.data.activateNotify && <>

          <Spaces n={0}/>

          <input className={`input--v2 input--v2--full-width ${isEmail1Wrong ? "input--wrong" : ""}`} value={emails[0] ?? ""}
                 onChange={(e) => {
                   const newValue = e.target.value;
                   setEmails((ps: any) => ([newValue, ps[1], ps[2]]))
                 }}/>
          <Spaces n={0}/>

          <input className={`input--v2 input--v2--full-width ${isEmail2Wrong ? "input--wrong" : ""}`} value={emails[1] ?? ""}
                 disabled={!emails[0] || (emails[0]?.length === 0)}
                 onChange={(e) => {
                   const newValue = e.target.value;
                   setEmails((ps: any) => ([ps[0], newValue, ps[2]]))
                 }}/>
          <Spaces n={0}/>

          <input className={`input--v2 input--v2--full-width ${isEmail3Wrong ? "input--wrong" : ""}`} value={emails[2] ?? ""}
                 disabled={(!emails[0] || !emails[1]) || ((emails[0]?.length === 0) && (emails[1]?.length === 0))}
                 onChange={(e) => {
                   const newValue = e.target.value;
                   setEmails((ps: any) => ([ps[0], ps[1], newValue]))
                 }}/>

          <input className={`input--v2 input--v2--full-width ${isEmail3Wrong ? "input--wrong" : ""}`} value={emails[3] ?? ""}
                 disabled={(!emails[0] || !emails[1] || !emails[2]) || ((emails[0]?.length === 0) || (emails[1]?.length === 0) || (emails[2]?.length === 0))}
                 onChange={(e) => {
                   const newValue = e.target.value;
                   setEmails((ps: any) => ([ps[0], ps[1], newValue]))
                 }}/>

          <Spaces n={1}/>

          <div className={"text--center"}>
            <button disabled={props.data.name.length === 0 || props.isPageLoading || !isButtonSaveOk} className={"btn-secondary"}
                    onClick={() => {
                      props.data.notifyEmails = emails;
                      // Update front
                      props.updateColumnFront(props.data._id, {
                        activateNotify: props.data.activateNotify,
                        notifyEmails: props.data.notifyEmails,
                      });
                      // Update back
                      props.saveColumnData(props.data._id, props.data);
              
                    }}>
              Enregistrer
            </button>
          </div>
        </>
      }
    
    </article>
  
  </>);
  
}



