export default {
  
  startTime: 0,
  endTimeAssistantInSeconds: 0,
  endTimeEditorInSeconds: 0,
}

export interface ITimeStamp {
  
  startTime: number,
  endTimeAssistantInSeconds: number,
  endTimeEditorInSeconds: number,
}