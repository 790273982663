import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";

export default async(setPageStatus: Function, dispatchAmbassadors: Function) => {
  try {
    setPageStatus("fetching");
    const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/amb/m/get`,
        {withCredentials: true})).data;
    if(status !== "ok") {
      return setPageStatus("error");
    }
    dispatchAmbassadors({type: "INITIALIZE", value: data});
    setPageStatus("fetched");
  } catch (error) {
    devLog(error);
    setPageStatus("error");
  }
}