import React from "react";
import FormEditorFirstQuestions from "./FormEditorFirstQuestions";
import FormEditorHRQuestions from "./FormEditorHRQuestions";
import EditorSumUpV2 from "./EditorSumUpV2";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {FormEditorOptions} from "./FormEditorOptions";

export default (props: any) => {

  switch(props.category){

    case 'remuneration':
      return <FormEditorFirstQuestions {...props}/>

    case 'metier':
      return <FormEditorHRQuestions {...props}/>

    case 'details':
      return(
      <>
        <EditorSumUpV2 {...props} jobDesc={props.jobDesc} setJobDesc={props.setJobDesc}/>

        <Spaces n={0}/>
      </> )

    case 'options':
      return (<>
        <FormEditorOptions {...props} />

        <Spaces n={1}/>
      </>)

    default:
      return <></>
  }

}