import React from "react";
import PageLayoutWithHeader from "../../layouts/PageLayoutWithHeader";
import Spaces from "../../components/sharedComponents/Spaces";
import {RecruiterLinksMailLink} from "./componentsRecruiterLinks/RecruiterLinksMailLink";
import {RecruiterLinksBusinessPage} from "./componentsRecruiterLinks/RecruiterLinksBusinessPage";

export {RecruiterLinks};

/**
 * --------------------------------------
 * Displays and manage links and mail signature
 * Primary-level page
 * --------------------------------------
 */
const RecruiterLinks = (props: any) => {
  
  return (<PageLayoutWithHeader grey={true} title={"Utilise ton lien magique"} small={true} fullWidth={true} page={props.page} status={"ok"}>
    
    <RecruiterLinksBusinessPage />
  
    <Spaces n={1}/>
  
    <RecruiterLinksMailLink />
  
    <Spaces n={1}/>
  
    {/*<RecruiterLinksAmbassador />*/}
    {/*<Spaces n={1}/>*/}
    
  </PageLayoutWithHeader>);
}