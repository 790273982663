import {IAppV2Notifications} from "../state/IAppV2";

export {notificationsReducer};

export type TNotificationsReducers =
  "SET_NOTIFICATIONS_RECRUITER" |
  "UPDATE_SEEN_ALL" |
  "UPDATE_SEEN_CANDIDACIES_FOR_MISSION" |
  "UPDATE_SEEN_FOR_CANDIDATE";

const notificationsReducer = (previousState: IAppV2Notifications, action: {
  type: TNotificationsReducers,
  value: any,
}): IAppV2Notifications => {
  
  switch(action.type) {
    
    case 'SET_NOTIFICATIONS_RECRUITER':
      return ({
        ...previousState,
        recruiter: {
          notifications: [...action.value ?? []],
          newCandidaciesForMissions: action.value?.filter((item: any) => (item.actionType === "newCandidacy")) ?? []
        }
      });
    
    case 'UPDATE_SEEN_ALL':
      return ({
        ...previousState,
        recruiter: {
          ...previousState.recruiter,
          notifications: previousState.recruiter?.notifications?.map((item: any) => ({
            ...item,
            seenByMe: true
          })) ?? []
        }
      });
    
    case 'UPDATE_SEEN_CANDIDACIES_FOR_MISSION':
      return ({
        ...previousState,
        recruiter: {
          ...previousState.recruiter,
          newCandidaciesForMissions: previousState.recruiter?.newCandidaciesForMissions?.map((item: any) => ({
            ...item,
            seenByMe: item.toMissionId === action.value ? true : item.seenByMe
          })) ?? []
        }
      })
    
    case 'UPDATE_SEEN_FOR_CANDIDATE':
      return ({
        ...previousState,
        recruiter: {
          ...previousState.recruiter,
          notifications: previousState.recruiter?.notifications?.map((notification: any) => {
            const shouldUpdate = ["newCandidacy", "newTrigram", "newEvaluation", "newMessage"].includes(notification.actionType) &&
              notification.onCandidateId === action.value;
            if(!shouldUpdate) return ({...notification});
            return ({
              ...notification,
              seenByMe: true
            })
          }) ?? []
        }
      });
    
    default:
      throw new Error(`Type ${action.type} is not defined in notificationsReducer`);
  }
  
}