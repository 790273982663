import React, {useContext, useEffect, useReducer, useState} from "react";
import PageLayout from "../../layouts/PageLayout";
import Spaces from "../../components/sharedComponents/Spaces";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import axios from "axios";
import {notification} from "../../utils/notification";
import {devLog} from "../../utils/devLog/devLog";
import dataReducerResearch from "./dataReducerResearch";
import LoadingStyle2 from "../../components/sharedComponents/Loading/LoadingStyle2";
import AdvancedResearchResearch from "./componentsRecruiterResearchAdvanced/AdvancedResearchResearch";

export {RecruiterResearchAdvanced};

const RecruiterResearchAdvanced = (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    const [search, dispatchSearch]: [any, Function] = useReducer(dataReducerResearch, {
        'name': "",
        'phone': "",
        'email': "",
        'salary_max':0,
        'minimumExperience': 0,
        'minimumSpecificExperience': 0,
        'minimumManagement': 0,
        'minimumStudies': 0,
        'diploma': "",
        'school': "",
        'functions': [],
        'exactPositions': [],
        'exactPositionsSpecifier': "both",
        'skills': [],
        'languages': [],
        'workedFor': [],
        'workedForSpecifier': "both",
        'didNotWorkFor': [],
        'sectors': [],
        'locationString': '',
        'distance': 50,
        'keywords': [],
        'operatorExactPositions': 'OR',
        'operatorKeywords': 'AND',
        'operatorSkills': 'OR',
        'operatorWorkedFor': 'OR',
        'operatorLanguages': 'AND'
    });

    const [functions, setFunctions] = useState("");
    const [exactPositions, setExactPositions] = useState("");
    const [skills, setSkills] = useState("");
    const [languages, setLanguages] = useState("");
    const [workedFor, setWorkedFor] = useState("");
    const [didNotWorkFor, setDidNotWorkFor] = useState("");
    const [sectors, setSectors] = useState("");
    const [locationLat, setLocationLat] = useState<number>(0);
    const [locationLon, setLocationLon] = useState(0);
    const [keywords, setKeywords] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [elapsed, setElapsed] = useState(0);
    const [selected, setSelected] = useState<string[]>([]);

    const [candidates, setCandidates] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);

    const toggleSelected = (cid: string) => {
        setSelected((ps: string[]) => {
            if (ps.includes(cid)) {
                return ps.filter((item) => item !== cid);
            } else {
                return [...ps, cid];
            }
        })
    }

    const isSearchEmpty = (() => {

        if (functions) return false;
        if (exactPositions) return false;
        if (skills) return false;
        if (languages) return false;
        if (workedFor) return false;
        if (didNotWorkFor) return false;
        if (sectors) return false;

        if (search.name) return false;
        if (search.phone) return false;
        if (search.email) return false;
        if (search.minimumExperience) return false;
        if (search.minimumSpecificExperience) return false;
        if (search.minimumManagement) return false;
        if (search.minimumStudies) return false;
        if (search.diploma) return false;
        if (search.school) return false;
        if (search.locationString) return false;

        if (search.functions.length !== 0) return false;
        if (search.exactPositions.length !== 0) return false;
        if (search.skills.length !== 0) return false;
        if (search.languages.length !== 0) return false;
        if (search.workedFor.length !== 0) return false;
        if (search.didNotWorkFor.length !== 0) return false;
        if (search.sectors.length !== 0) return false;
        if (search.keywords.length !== 0) return false;

        return true;
    })();

    async function sendSearch(toPage: number) {
        try {
            setPage(toPage);
            window.scrollTo(0, 0);
            setIsLoading(true);
            const location = (() => {
                if (locationLon && locationLat) {
                    return [locationLat, locationLon]
                }
                return null
            })()
            const {data} =
                (await axios.post(`${process.env.REACT_APP_API_URL}/v3/communities/research/r/main/advanced`,
                    {
                        page: toPage,
                        search: {
                            'name': search.name,
                            'phone': search.phone,
                            'email': search.email,
                            'salary_max':search.salary_max,
                            'minimumExperience': search.minimumExperience,
                            'minimumSpecificExperience': search.minimumSpecificExperience,
                            'minimumManagement': search.minimumManagement,
                            'minimumStudies': search.minimumStudies,
                            'diploma': search.diploma,
                            'school': search.school,
                            'functions': functions ? [...search.functions, functions] : search.functions,
                            'exactPositions': exactPositions ? [...search.exactPositions, exactPositions] : search.exactPositions,
                            'skills': skills ? [...search.skills, skills] : search.skills,
                            'languages': languages ? [...search.languages, languages] : search.languages,
                            'workedFor': workedFor ? [...search.workedFor, workedFor] : search.workedFor,
                            'didNotWorkFor': didNotWorkFor ? [...search.didNotWorkFor, didNotWorkFor] : search.didNotWorkFor,
                            'sectors': sectors ? [...search.sectors, sectors] : search.sectors,
                            'distance': search.distance,
                            'location': location,
                            'keywords': keywords ? [...search.keywords, keywords] : search.keywords,
                            'exactPositionsSpecifier': search.exactPositionsSpecifier,
                            'workedForSpecifier': search.workedForSpecifier,
                            'locationString': search.locationString,
                            'operatorExactPositions': search.operatorExactPositions,
                            'operatorKeywords': search.operatorKeywords,
                            'operatorSkills': search.operatorSkills,
                            'operatorWorkedFor': search.operatorWorkedFor,
                            'operatorLanguages': search.operatorLanguages,
                        },
                    },
                    {withCredentials: true})
                ).data;

            setCandidates(data.candidates ?? []);
            setPage(data.page || 1);
            setTotalPages(data.totalPages || 1);
            setElapsed(data.delay ?? 0);
            setTotalResults(data.total ?? 0);

            setIsLoading(false);
            setSelected([]);

        } catch (error) {
            setIsLoading(false);
            devLog(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    function resetResearch() {
        dispatchSearch({type: 'RESET', value: null});
        setFunctions('');
        setExactPositions('');
        setSkills('');
        setLanguages('');
        setWorkedFor('');
        setDidNotWorkFor('');
        setSectors('');
        setLocationLat(0);
        setLocationLon(0);
        setKeywords('');
    }

    return (<PageLayout title={""} page={props.page} status={"ok"} small={true} fullWidth={true}>

        <div className={'advanced-search'}>
            <div className={'advanced-search__navbar'}>

                <Spaces n={1}/>

                {/*---------------------------- exactPositions -----------------------------------*/}
                <div className={"advanced-research-filter"}>
                    <h1 className={"advanced-research-title"}>Titres de postes
                        <select className={'advanced-research__operator'} value={search.operatorExactPositions}  onChange={(e) => {
                            dispatchSearch({
                                type: 'UPDATE_VALUE',
                                value: {key: 'operatorExactPositions', value: e.target.value}
                            })
                        }}>
                            <option value={'AND'}>
                                AND
                            </option>
                            <option value={'OR'}>
                                OR
                            </option>
                        </select>
                    </h1>
                    <div className={"advanced-research-filters__items"}>{search?.exactPositions?.map((item: any) => (
                        <div className={"advanced-research-filters__item"}>{item}
                            <button className={"advanced-research__button"} onClick={() => {
                                dispatchSearch({
                                    type: "UPDATE_ARRAY_REMOVE",
                                    value: {key: "exactPositions", value: item}
                                });
                            }}>x
                            </button>
                        </div>))}</div>
                    <input
                        className={`advanced-research-filters__input ${exactPositions ? 'advanced-research-filters__input--selected' : ''}`}
                        value={exactPositions}
                        onChange={(e) => {
                            setExactPositions(e.target.value);
                        }}/>
                    <button disabled={!exactPositions} className={"advanced-research__button-plus"} onClick={() => {
                        dispatchSearch({
                            type: "UPDATE_ARRAY_ADD",
                            value: {key: 'exactPositions', value: exactPositions}
                        });
                        setExactPositions("");
                    }}> +
                    </button>
                </div>

                {/*<div className={'search-specifier'}>*/}
                {/*    Fonction experimentale*/}
                {/*    <br/>*/}
                {/*    <input type={'radio'} disabled={false} id={'eps-c'} name={'exactPositionsSpecifier'} value={'current'}*/}
                {/*           checked={search.exactPositionsSpecifier === 'current'} onClick={(e) => dispatchSearch({*/}
                {/*        type: 'UPDATE_VALUE',*/}
                {/*        value: {key: 'exactPositionsSpecifier', value: 'current'}*/}
                {/*    })}/> <label>Actuel</label><br/>*/}
                {/*    <input type={'radio'} disabled={false} id={'eps-p'} name={'exactPositionsSpecifier'} value={'past'}*/}
                {/*           checked={search.exactPositionsSpecifier === 'past'} onClick={(e) => dispatchSearch({*/}
                {/*        type: 'UPDATE_VALUE',*/}
                {/*        value: {key: 'exactPositionsSpecifier', value: 'past'}*/}
                {/*    })}/> <label>Passé</label><br/>*/}
                {/*    <input type={'radio'} id={'eps-b'} name={'exactPositionsSpecifier'} value={'both'}*/}
                {/*           checked={search.exactPositionsSpecifier === 'both'} onClick={(e) => dispatchSearch({*/}
                {/*        type: 'UPDATE_VALUE',*/}
                {/*        value: {key: 'exactPositionsSpecifier', value: 'both'}*/}
                {/*    })}/> <label>Sans importance</label><br/>*/}
                {/*</div>*/}

                {/*---------------------------- functions -----------------------------------*/}
                {/*<div className={"advanced-research-filter hollow"}>*/}
                {/*  <h1 className={"advanced-research-title"}>Fonctions</h1>*/}
                {/*  <div className={"advanced-research-filters__items"}>{search?.functions?.map((item: any) => (*/}
                {/*    <div className={"advanced-research-filters__item"}>{item}*/}
                {/*      <button className={"advanced-research__button"} onClick={() => {*/}
                {/*        dispatchSearch({type: "UPDATE_ARRAY_REMOVE", value: {key: "functions", value: item}});*/}
                {/*      }}>x*/}
                {/*      </button>*/}
                {/*    </div>))}</div>*/}
                {/*  <input className={"advanced-research-filters__input"} value={functions}*/}
                {/*         onChange={(e) => {*/}
                {/*           setFunctions(e.target.value);*/}
                {/*         }}/>*/}
                {/*  <button className={"advanced-research__button-plus"} onClick={() => {*/}
                {/*    dispatchSearch({type: "UPDATE_ARRAY_ADD", value: {key: 'functions', value: functions}});*/}
                {/*    setFunctions("");*/}
                {/*  }}> +*/}
                {/*  </button>*/}
                {/*</div>*/}

                {/*---------------------------- Location + distance -----------------------------------*/}
                {/*<InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.locationString}*/}
                {/*         oKey={'locationString'}*/}
                {/*         title={'Lieu (ex: Paris, France)'} type={'text'}/>*/}

                {/*<InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.distance} oKey={'distance'}*/}
                {/*         title={'Rayon de la recherche (km)'} type={'number'}/>*/}
                {/*<div className={"advanced-research-filter"}>*/}
                {/*    <h1 className={"advanced-research-title"}>Distance du poste</h1>*/}
                {/*    <select className={''} value={search.distance} onChange={(e) => dispatchSearch({*/}
                {/*        type: 'UPDATE_VALUE',*/}
                {/*        value: {key: 'distance', value: e.target.value}*/}
                {/*    })}>*/}
                {/*        <option value={30}>Proche (30 km)</option>*/}
                {/*        <option value={50}>Moyenne (50 km)</option>*/}
                {/*        <option value={100}>Lointaine (100 km)</option>*/}
                {/*    </select>*/}
                {/*</div>*/}

                {/*---------------------------- Rem max-----------------------------------*/}

                {/*<InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.salary_max}*/}
                {/*         oKey={'salary_max'}*/}
                {/*         title={'Rénumération actuelle max (K)'} type={'number'}/>*/}

                {/*---------------------------- Mots cles -----------------------------------*/}
                <div className={"advanced-research-filter"}>
                    <h1 className={"advanced-research-title"}>Keywords
                        <select className={'advanced-research__operator'} value={search.operatorKeywords}  onChange={(e) => {
                            dispatchSearch({
                                type: 'UPDATE_VALUE',
                                value: {key: 'operatorKeywords', value: e.target.value}
                            })
                        }}>
                            <option value={'AND'}>
                                AND
                            </option>
                            <option value={'OR'}>
                                OR
                            </option>
                        </select>
                    </h1>
                    <div className={"advanced-research-filters__items"}>{search?.keywords?.map((item: any) => (
                        <div className={"advanced-research-filters__item"}>{item}
                            <button className={"advanced-research__button"} onClick={() => {
                                dispatchSearch({type: "UPDATE_ARRAY_REMOVE", value: {key: "keywords", value: item}});
                            }}>x
                            </button>
                        </div>))}</div>
                    <input
                        className={`advanced-research-filters__input ${keywords ? 'advanced-research-filters__input--selected' : ''}`}
                        value={keywords}
                        onChange={(e) => {
                            // @ts-ignore
                            setKeywords(e.target.value);
                        }}/>
                    <button disabled={!keywords} className={"advanced-research__button-plus"} onClick={() => {
                        dispatchSearch({type: "UPDATE_ARRAY_ADD", value: {key: 'keywords', value: keywords}});
                        // @ts-ignore
                        setKeywords("");
                    }}> +
                    </button>
                </div>

                {/*---------------------------- skills -----------------------------------*/}
                {/*<div className={"advanced-research-filter"}>*/}
                {/*    <h1 className={"advanced-research-title"}>Compétences*/}
                {/*        <select disabled className={'advanced-research__operator'} value={search.operatorSkills}  onChange={(e) => {*/}
                {/*            dispatchSearch({*/}
                {/*                type: 'UPDATE_VALUE',*/}
                {/*                value: {key: 'operatorSkills', value: e.target.value}*/}
                {/*            })*/}
                {/*        }}>*/}
                {/*            <option value={'AND'}>*/}
                {/*                AND*/}
                {/*            </option>*/}
                {/*            <option value={'OR'}>*/}
                {/*                OR*/}
                {/*            </option>*/}
                {/*        </select>*/}
                {/*    </h1>*/}
                {/*    <div className={"advanced-research-filters__items"}>{search?.skills?.map((item: any) => (*/}
                {/*        <div className={"advanced-research-filters__item"}>{item}*/}
                {/*            <button className={"advanced-research__button"} onClick={() => {*/}
                {/*                dispatchSearch({type: "UPDATE_ARRAY_REMOVE", value: {key: "skills", value: item}});*/}
                {/*            }}>x*/}
                {/*            </button>*/}
                {/*        </div>))}</div>*/}
                {/*    <input*/}
                {/*        className={`advanced-research-filters__input ${skills ? 'advanced-research-filters__input--selected' : ''}`}*/}
                {/*        value={skills}*/}
                {/*        onChange={(e) => {*/}
                {/*            setSkills(e.target.value);*/}
                {/*        }}/>*/}
                {/*    <button disabled={!skills} className={"advanced-research__button-plus"} onClick={() => {*/}
                {/*        dispatchSearch({type: "UPDATE_ARRAY_ADD", value: {key: 'skills', value: skills}});*/}
                {/*        setSkills("");*/}
                {/*    }}> +*/}
                {/*    </button>*/}
                {/*</div>*/}

                {/*---------------------------- workedFor -----------------------------------*/}
                <div className={"advanced-research-filter"}>
                    <h1 className={"advanced-research-title"}>Entreprises
                        <select className={'advanced-research__operator'} value={search.operatorWorkedFor}  onChange={(e) => {
                            dispatchSearch({
                                type: 'UPDATE_VALUE',
                                value: {key: 'operatorWorkedFor', value: e.target.value}
                            })
                        }}>
                            <option value={'AND'}>
                                AND
                            </option>
                            <option value={'OR'}>
                                OR
                            </option>
                        </select>
                    </h1>
                    <div className={"advanced-research-filters__items"}>{search?.workedFor?.map((item: any) => (
                        <div className={"advanced-research-filters__item"}>{item}
                            <button className={"advanced-research__button"} onClick={() => {
                                dispatchSearch({type: "UPDATE_ARRAY_REMOVE", value: {key: "workedFor", value: item}});
                            }}>x
                            </button>
                        </div>))}</div>
                    <input
                        className={`advanced-research-filters__input ${workedFor ? 'advanced-research-filters__input--selected' : ''}`}
                        value={workedFor}
                        onChange={(e) => {
                            setWorkedFor(e.target.value);
                        }}/>
                    <button disabled={!workedFor} className={"advanced-research__button-plus"} onClick={() => {
                        dispatchSearch({type: "UPDATE_ARRAY_ADD", value: {key: 'workedFor', value: workedFor}});
                        setWorkedFor("");
                    }}> +
                    </button>
                </div>

                {/*<div className={'search-specifier'}>*/}
                {/*    <input type={'radio'} disabled={true} id={'wfs-c'} name={'workedForSpecifier'} value={'current'}*/}
                {/*           checked={search.workedForSpecifier === 'current'} onClick={(e) => dispatchSearch({*/}
                {/*        type: 'UPDATE_VALUE',*/}
                {/*        value: {key: 'workedForSpecifier', value: 'current'}*/}
                {/*    })}/> <label>Actuelle</label><br/>*/}
                {/*    <input type={'radio'} disabled={true} id={'wfs-p'} name={'workedForSpecifier'} value={'past'}*/}
                {/*           checked={search.workedForSpecifier === 'past'} onClick={(e) => dispatchSearch({*/}
                {/*        type: 'UPDATE_VALUE',*/}
                {/*        value: {key: 'workedForSpecifier', value: 'past'}*/}
                {/*    })}/> <label>Passée</label><br/>*/}
                {/*    <input type={'radio'} id={'wfs-b'} name={'workedForSpecifier'} value={'both'}*/}
                {/*           checked={search.workedForSpecifier === 'both'} onClick={(e) => dispatchSearch({*/}
                {/*        type: 'UPDATE_VALUE',*/}
                {/*        value: {key: 'workedForSpecifier', value: 'both'}*/}
                {/*    })}/> <label>Sans importance</label><br/>*/}
                {/*</div>*/}

                {/*---------------------------- didNotWorkFor -----------------------------------*/}
                {/*<div className={"advanced-research-filter"}>*/}
                {/*    <h1 className={"advanced-research-title"}>N'ayant PAS travaillé pour</h1>*/}
                {/*    <div className={"advanced-research-filters__items"}>{search?.didNotWorkFor?.map((item: any) => (*/}
                {/*        <div className={"advanced-research-filters__item advanced-research-filters__item--red"}>{item}*/}
                {/*            <button className={"advanced-research__button"} onClick={() => {*/}
                {/*                dispatchSearch({*/}
                {/*                    type: "UPDATE_ARRAY_REMOVE",*/}
                {/*                    value: {key: "didNotWorkFor", value: item}*/}
                {/*                });*/}
                {/*            }}>x*/}
                {/*            </button>*/}
                {/*        </div>))}</div>*/}
                {/*    <input*/}
                {/*        className={`advanced-research-filters__input ${didNotWorkFor ? 'advanced-research-filters__input--selected' : ''}`}*/}
                {/*        value={didNotWorkFor}*/}
                {/*        onChange={(e) => {*/}
                {/*            setDidNotWorkFor(e.target.value);*/}
                {/*        }}/>*/}
                {/*    <button disabled={!didNotWorkFor} className={"advanced-research__button-plus"} onClick={() => {*/}
                {/*        dispatchSearch({type: "UPDATE_ARRAY_ADD", value: {key: 'didNotWorkFor', value: didNotWorkFor}});*/}
                {/*        setDidNotWorkFor("");*/}
                {/*    }}> +*/}
                {/*    </button>*/}
                {/*</div>*/}

                {/*---------------------------- School -----------------------------------*/}
                {/*<InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.school} oKey={'school'}*/}
                {/*         title={'École'} type={'text'}/>*/}
                {/*<InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.diploma} oKey={'diploma'}*/}
                {/*         title={'Diplôme'} type={'text'}/>*/}
                {/*<InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.minimumStudies}*/}
                {/*         oKey={'minimumStudies'} title={'Experience minimum d\'études (années)'} type={'number'}/>*/}

                {/*---------------------------- sectors -----------------------------------*/}
                {/*<div className={"advanced-research-filter"}>*/}
                {/*    <h1 className={"advanced-research-title"}>Secteurs</h1>*/}
                {/*    <div className={"advanced-research-filters__items"}>{search?.sectors?.map((item: any) => (*/}
                {/*        <div className={"advanced-research-filters__item"}>{item}*/}
                {/*            <button className={"advanced-research__button"} onClick={() => {*/}
                {/*                dispatchSearch({type: "UPDATE_ARRAY_REMOVE", value: {key: "sectors", value: item}});*/}
                {/*            }}>x*/}
                {/*            </button>*/}
                {/*        </div>))}</div>*/}
                {/*    <input*/}
                {/*        className={`advanced-research-filters__input ${sectors ? 'advanced-research-filters__input--selected' : ''}`}*/}
                {/*        value={sectors}*/}
                {/*        onChange={(e) => {*/}
                {/*            setSectors(e.target.value);*/}
                {/*        }}/>*/}
                {/*    <button disabled={!sectors} className={"advanced-research__button-plus"} onClick={() => {*/}
                {/*        dispatchSearch({type: "UPDATE_ARRAY_ADD", value: {key: 'sectors', value: sectors}});*/}
                {/*        setSectors("");*/}
                {/*    }}> +*/}
                {/*    </button>*/}
                {/*</div>*/}

                {/*---------------------------- languages -----------------------------------*/}
                {/*<div className={"advanced-research-filter"}>*/}
                {/*    <h1 className={"advanced-research-title"}>Langues*/}
                {/*        <select className={'advanced-research__operator'} value={search.operatorLanguages}  onChange={(e) => {*/}
                {/*            dispatchSearch({*/}
                {/*                type: 'UPDATE_VALUE',*/}
                {/*                value: {key: 'operatorLanguages', value: e.target.value}*/}
                {/*            })*/}
                {/*        }}>*/}
                {/*            <option value={'AND'}>*/}
                {/*                AND*/}
                {/*            </option>*/}
                {/*            <option value={'OR'}>*/}
                {/*                OR*/}
                {/*            </option>*/}
                {/*        </select>*/}
                {/*    </h1>*/}
                {/*    <div className={"advanced-research-filters__items"}>{search?.languages?.map((item: any) => (*/}
                {/*        <div className={"advanced-research-filters__item"}>{item}*/}
                {/*            <button className={"advanced-research__button"} onClick={() => {*/}
                {/*                dispatchSearch({type: "UPDATE_ARRAY_REMOVE", value: {key: "languages", value: item}});*/}
                {/*            }}>x*/}
                {/*            </button>*/}
                {/*        </div>))}</div>*/}
                {/*    <select className={`${languages ? "filters-input--selected" : ""}`} value={languages}*/}
                {/*            onChange={(e) => (setLanguages(e.target.value))}>*/}
                {/*        <option value={''}>Choisir une langue</option>*/}
                {/*        <option value={'af'}>Afrikaans</option>*/}
                {/*        <option value={'sq'}>Albanais</option>*/}
                {/*        <option value={'de'}>Allemand</option>*/}
                {/*        <option value={'en'}>Anglais</option>*/}
                {/*        <option value={'ar'}>Arabe</option>*/}
                {/*        <option value={'hy'}>Arménien</option>*/}
                {/*        <option value={'be'}>Biélorusse</option>*/}
                {/*        <option value={'zh'}>Chinois</option>*/}
                {/*        <option value={'eo'}>Esperanto</option>*/}
                {/*        <option value={'fi'}>Finnois</option>*/}
                {/*        <option value={'fr'}>Français</option>*/}
                {/*        <option value={'he'}>Hébreu</option>*/}
                {/*        <option value={'hi'}>Hindi</option>*/}
                {/*        <option value={'hu'}>Hongrois</option>*/}
                {/*        <option value={'it'}>Italien</option>*/}
                {/*        <option value={'ja'}>Japonais</option>*/}
                {/*        <option value={'km'}>Khmer</option>*/}
                {/*        <option value={'sgn'}>Langue des signes</option>*/}
                {/*        <option value={'nl'}>Néerlandais</option>*/}
                {/*        <option value={'pl'}>Polonais</option>*/}
                {/*        <option value={'pt'}>Portugais</option>*/}
                {/*        <option value={'ru'}>Russe</option>*/}
                {/*        <option value={'cs'}>Tchèque</option>*/}
                {/*        <option value={'th'}>Thaï</option>*/}
                {/*        <option value={'tr'}>Turc</option>*/}
                {/*        <option value={'uk'}>Ukrainien</option>*/}
                {/*        <option value={'wa'}>Wallon</option>*/}
                {/*    </select>*/}
                {/*    <button disabled={!languages} className={"advanced-research__button-plus"} onClick={() => {*/}
                {/*        dispatchSearch({type: "UPDATE_ARRAY_ADD", value: {key: 'languages', value: languages}});*/}
                {/*        setLanguages("");*/}
                {/*    }}> +*/}
                {/*    </button>*/}
                {/*</div>*/}

                {/*---------------------------- minimumExperience -----------------------------------*/}
                <InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.minimumExperience}
                         oKey={'minimumExperience'} title={'Experience minimum (années))'} type={'number'}/>
                {/*<InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.minimumManagement}*/}
                {/*         oKey={'minimumManagement'} title={'Experience minimum en management (années)'}*/}
                {/*         type={'number'}/>*/}
                {/*<InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.minimumSpecificExperience}*/}
                {/*         oKey={'minimumSpecificExperience'} title={'Experience minimum à ce poste (années)'}*/}
                {/*         type={'number'}/>*/}

                {/*---------------------------- FullName Phone / Email -----------------------------------*/}
                <InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.name} oKey={'name'}
                         title={'Nom'} type={'text'}/>
                <InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.phone} oKey={'phone'}
                         title={'Téléphone'} type={'text'}/>
                <InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.email} oKey={'email'}
                         title={'Email'} type={'text'}/>

                <Spaces n={0}/>

                <div className={"text--center"}>
                    <button className={"advanced-research__button-search"} disabled={isSearchEmpty || isLoading}
                            onClick={() => sendSearch(1)}>
                        Lancer la recherche
                    </button>

                    <br/>

                    <button className={"btn--hollow"} onClick={() => {
                        resetResearch();
                        setSelected([]);
                    }}>Reset
                    </button>
                </div>

                {/*Champs temporaires :*/}
                {/*<InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.locationLat}*/}
                {/*         oKey={'locationLat'} title={'Latitude'} type={'number'}/>*/}
                {/*<InputAS dispatchSearch={(args: any) => dispatchSearch(args)} value={search.locationLon}*/}
                {/*         oKey={'locationLon'} title={'Longitude'} type={'number'}/>*/}

                <Spaces n={3}/>
            </div>

            {/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className={'advanced-search__results'}>
                <Spaces n={1}/>

                <div style={{
                    padding: "5px 15px",
                    color: "#999",
                    fontWeight: "bolder",
                    fontSize: "0.95em",
                }}>v0.1.0</div>

                <Spaces n={1}/>

                <div className={'advanced-search__results__header'}>

                    {(elapsed !== 0 && candidates.length !== 0 && !isLoading) &&
                    <div className={"text--grey text--minified2x"}>
                        <br/>
                        Trouvé {totalResults} candidats en {elapsed}ms. Page {page} sur {totalPages}
                    </div>}

                </div>

                <Spaces n={1}/>

                <div className={'advanced-search__offset'}>

                    <div className={"text--center"}>

                        {!isLoading && candidates?.map((item: any, key: number) => (
                            <AdvancedResearchResearch item={item} key={key}
                                                      toggleSelected={(cid: string) => toggleSelected(cid)}
                                                      selected={selected} shortlist={candidates}
                                                      keywords={[...search.exactPositions, exactPositions, ...search.keywords, keywords]}
                            />
                        ))}

                        {(candidates.length === 0 && !isLoading) && <div className={"text--center"}>
                            Aucun candidat
                        </div>}

                        {isLoading && <LoadingStyle2/>}

                        <Spaces n={2}/>

                        <PagesSelector totalPages={totalPages} page={page} onClick={(p: number) => {sendSearch(p)}}/>

                        <Spaces n={10}/>

                    </div>

                </div>
            </div>

            {/*/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className={'advanced-search__actions'}>

                <Spaces n={5}/>

                <div className={"advanced-search__actions__sticky"}>

                    Selection: {selected.length}

                    <br/><br/>

                    <button className={"advanced-research__button-search"} disabled={selected.length === 0} onClick={() => {
                        appContextV2.dispatchPanels({
                            type: "OPEN_WINDOW", value: {
                                type: "exportCandidatesFromGlobalSearchToKanban",
                                data: {
                                    selected: selected,
                                }
                            }
                        });
                    }}>
                        Importer dans un Kanban
                    </button>
                </div>

                <Spaces n={2}/>

            </div>

        </div>

    </PageLayout>);
}


function InputAS(props: {
    title: string;
    value: any;
    oKey: string;
    type: 'text' | 'number';
    dispatchSearch: Function;
}) {

    return (<>
        <div className={"advanced-research-filter"}>
            <h1 className={"advanced-research-title"}>{props.title}</h1>
            <input className={`filters-input ${props.value ? "filters-input--selected" : ""}`} type={props.type}
                   value={props.value ? props.value : ""} onChange={(e) => props.dispatchSearch({
                type: "UPDATE_VALUE",
                value: {key: props.oKey, value: e.target.value}
            })}/>
        </div>
    </>);
}

function PagesSelector(props: {
    page: number
    totalPages: number
    onClick: Function
}) {

    if (props.totalPages <= 1) {
        return <></>
    }

    const listPages = []

    for (let i = 1; i <= props.totalPages; i++) {
        listPages.push(i)
    }

    return (<>
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            padding: "0 20px"
        }}>
            {listPages.map((item: number, key: number) => (
                <button style={{
                    margin: "5px 5px",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    border: "1px solid #e0e0e0",
                    backgroundColor: props.page === item ? "#e0e0e0" : "white",
                }}
                      disabled={props.page === item}
                      onClick={() => {
                          props.onClick(item);
                      }}>
                    {item}
                </button>
            ))}
        </div>
    </>);






}