import React, {useContext, useState} from "react";
import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import WindowWrapper from "../../../../layouts/WindowLayout";
import {format} from "date-fns";
import Spaces from "../../Spaces";
import {getWebOrigin} from "../../../../pages/privateRecruiter/componentsRecruiterMissions/componentsRMissionPageRouter/componentsRecruiterMissionKanban/AbstractKanbanColumnV2";
import {devLog} from "../../../../utils/devLog/devLog";
import {notification} from "../../../../utils/notification";
import axios from "axios";
import LoadingStyle2 from "../../Loading/LoadingStyle2";

export {WindowCandidateNotRegisteredWindow};

const WindowCandidateNotRegisteredWindow = (props: any) => {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

  const column: any = appContextV2.appPanels.window.windowOptions.data.column || {};
  const candidacy: any = appContextV2.appPanels.window.windowOptions.data.candidacy || {};
  const removeUnregisteredCandidacyFront: any = appContextV2.appPanels.window.windowOptions.data.removeUnregisteredCandidacyFront || (() => {});
  const updateUnregisteredCandidacyFront: any = appContextV2.appPanels.window.windowOptions.data.updateUnregisteredCandidacyFront || (() => {});

  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState(candidacy.comment || "");
  const [firstName, setFirstName] = useState(candidacy.firstName || "");
  const [lastName, setLastName] = useState(candidacy.lastName || "");
  const [deleteMode, setDeleteMode] = useState(false);

  const isButtonDeleteActive = !isLoading && deleteMode;
  const isButtonSaveActive = !isLoading && (comment !== candidacy.comment || firstName !== candidacy.firstName || lastName !== candidacy.lastName);

  const saveCandidacy = async () => {
    try {
      setIsLoading(true);
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/mat/cam/save/unsubscribed/candidacy`, {
        unregisteredCandidacyId: candidacy._id,
        comment: comment,
        firstName: firstName,
        lastName: lastName,
      }, {
        timeout: 20_000,
        withCredentials: true
      });
      if(fetched.data.status !== "ok") {
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      setIsLoading(false);
      updateUnregisteredCandidacyFront(candidacy._id, firstName, lastName, comment);
      return notification(appContextV2.dispatchPanels, "Succès de l'opération", "success");
    } catch (error) {
      devLog(error);
      setIsLoading(false);
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }

  const deleteCandidacy = async () => {
    try {
      setIsLoading(true);
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/mat/cam/delete/unsubscribed/candidacy`, {
        unregisteredCandidacyId: candidacy._id,
      }, {
        timeout: 20_000,
        withCredentials: true
      });
      if(fetched.data.status !== "ok") {
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      setIsLoading(false);
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      removeUnregisteredCandidacyFront(candidacy._id);
      return notification(appContextV2.dispatchPanels, "Succès de l'opération", "success");
    } catch (error) {
      devLog(error);
      setIsLoading(false);
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }

  return (<WindowWrapper size={"big"} status={"ok"} windowTitle={""}>

    Date de la candidature: {format(new Date(candidacy.dateOfCreation), "dd-MM-yyyy")},
    via {getWebOrigin(candidacy.mailUsedAsId) ? getWebOrigin(candidacy.mailUsedAsId) : "Inconnu"}

    <Spaces n={0}/>

    Le candidat a été relancé {candidacy.numberOfMailsSent} fois par email pour répondre au questionnaire.

    <Spaces n={0}/>

    <div className={"white-container white-container--grey"}>

      <div className={"link-box2 link-box2--white"}>
        {candidacy.mailUsedAsId?.replace('%40', '@')}
      </div>

      <Spaces n={0}/>

      <div className={"text--center"}>
        <input type={"text"} className={"input--v2"} placeholder={"Prénom"} value={firstName} onChange={(e) => {
          if (e.target.value.length < 60) {
            setFirstName(e.target.value);
          }
        }}/>
        <input type={"text"} className={"input--v2"} placeholder={"Nom"} value={lastName} onChange={(e) => {
          if (e.target.value.length < 60) {
            setLastName(e.target.value);
          }
        }}/>
      </div>

      <span className={"text--minified1x"}>Notes internes</span>
      <br/>
      <textarea className={"textarea--v2 textarea--v2--full-width"} value={comment} onChange={(e) => {
        if (e.target.value.length < 800) {
          setComment(e.target.value);
        }
      }}>
      </textarea>

    </div>

    <Spaces n={0}/>

    <input type={"checkbox"} checked={deleteMode} onClick={() => {
      setDeleteMode((ps: boolean) => !ps)
    }}/> Autoriser la suppression du candidat

    <Spaces n={0}/>

    <div className={"text--center"}>
      {
        deleteMode &&
        <button className={"btn--grey-art"} disabled={!isButtonDeleteActive} onClick={() => deleteCandidacy()}>
            Supprimer
        </button>
      }
      <button className={"btn--grey-art"} disabled={!isButtonSaveActive} onClick={() => saveCandidacy()}>
        Enregistrer
      </button>
    </div>

    {
      isLoading &&
      <div className={"space-flex--center"}>
        <LoadingStyle2/>
      </div>
    }

  </WindowWrapper>);
}