import {IAppV2Panels} from "../state/IAppV2";
import {initialPanels} from "../state/initialPanels";

export {panelsReducer};

const panelsReducer = (state: IAppV2Panels, action: {
  type: string,
  value: any,
}) => {
  
  switch(action.type) {
    
    case 'CLOSE_ALL':
      return ({
        ...state,
        ...initialPanels,
      });
    
    case 'OPEN_WINDOW':
      return ({
        ...state,
        window: {
          isWindowVisible: true,
          isWindowClosing: false,
          windowOptions: {
            type: action.value.type,
            data: action.value.data,
          },
        },
      });
    
    case 'CLOSING_WINDOW':
      return ({
        ...state,
        window: {
          isWindowVisible: true,
          isWindowClosing: true,
          windowOptions: {
            ...state.window.windowOptions,
          },
        },
      });
    
    case 'CLOSE_WINDOW':
      return ({
        ...state,
        window: {
          isWindowVisible: false,
          isWindowClosing: false,
          windowOptions: {
            type: "",
            data: {},
          },
        },
      });
    
    case 'OPEN_MENU_FROM_BOTTOM':
      return ({
        ...state,
        menuFromBottom: {
          isMenuFromBottomVisible: true,
          isMenuFromBottomClosing: false,
          menuFromBottomOptions: {
            type: action.value.type,
            data: action.value.data,
          },
        },
      });
    
    case 'CLOSING_MENU_FROM_BOTTOM':
      return ({
        ...state,
        menuFromBottom: {
          isMenuFromBottomVisible: true,
          isMenuFromBottomClosing: true,
          menuFromBottomOptions: {
            ...state.menuFromBottom.menuFromBottomOptions,
          },
        },
      });
    
    case 'CLOSE_MENU_FROM_BOTTOM':
      return ({
        ...state,
        menuFromBottom: {
          isMenuFromBottomVisible: false,
          isMenuFromBottomClosing: false,
          menuFromBottomOptions: {
            type: "menu",
            data: {},
          },
        },
      });
    
    case 'OPEN_NOTIFICATIONS':
      return ({
        ...state,
        notifications: {
          isWindowNotificationVisible: true,
          notificationText: action.value.text,
          notificationType: action.value.type,
          notificationTimer: null,
        }
      });
  
    case 'CLOSE_NOTIFICATIONS':
      return ({
        ...state,
        notifications: {
          isWindowNotificationVisible: false,
          notificationText: "",
          notificationType: "information",
          notificationTimer: null,
        }
      });
  
    case 'OPEN_EDITOR_PANEL':
      return ({
        ...state,
        editorPanel: {
          isEditorPanelVisible: true,
          editorPanelOptions: {
            type: action.value.type,
            data: action.value.data,
          },
        }
      });
  
    case 'CLOSE_EDITOR_PANEL':
      return ({
        ...state,
        editorPanel: {
          isEditorPanelVisible: false,
          editorPanelOptions: {
            type: "",
            data: "",
          },
        }
      });


    
    default:
      throw new Error(`Type ${action.type} is not defined in panelsReducer`);
  }
  
}