import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import NavBarLogoContent from "../componentsNavBar/NavBarLogoContent";
import SubNavLinkGenerator from "./shared/SubNavLinkGenerator";
import {Link} from "react-router-dom";

export function SubMenuRecruiterPool(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const currentSubPage: string = appContextV2.appNavigation.subPage;
  
  return (<>
    <div className={'hide-on-computers'}>
      <NavBarLogoContent/>
    </div>
    <ul className={"subnavbar-new hide-on-smartphones animation-straight-fade-in"}>
      <BackToLandingPagePool/>
      <SubNavLinkGenerator fr={"Vue principale"} en={"Main view"} uri={`/business/community/${appContextV2.appNavigation?.poolId}`} active={currentSubPage === "RecruiterPoolPeople"}
                           alt={"kanban"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={true}/>
    </ul>
  </>);
}

function BackToLandingPagePool(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const currentPage: string = appContextV2.appNavigation.page;
  
  return (
    <Link to={"/business/communities"}>
      <li className={"hide-on-smartphones subnavbar-new__item"}>
        <button disabled={currentPage === "RecruiterResearch"}
                className={`btn--hollow text--hollow--hover ${currentPage === "RecruiterResearch" ? "selected" : ""}`}>
            <span>
              <img className={"navbar-secondary-btn-image--smaller"} src={"https://static.myshortlist.co/btn-back.svg"}
                   alt={"research"}/>
            </span>
        </button>
      </li>
    </Link>
  );
}