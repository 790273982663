import React from "react";

export default (props: any) => {
  
  return (
    <>
      <section className={'section-navigation'}>
        
        <div
          className={`section-navigation__button ${props.white ? "section-navigation__button--white " : " " }${props.category === 'ambassador' ? " section-navigation__button--selected" : ""}`}

          onClick={() => {
            props.setCategory('ambassador');
            
          }}
        >Ambassadeurs
        </div>
        <div
          className={`section-navigation__button ${props.white ? "section-navigation__button--white " : " " }${props.category === 'multiposting' ? " section-navigation__button--selected" : ""}`}

          onClick={() => {
            props.setCategory('multiposting');
            
          }}>Multiposting
        </div>
        <div
          className={`section-navigation__button ${props.white ? "section-navigation__button--white " : " " }${props.category === 'tracking' ? " section-navigation__button--selected" : ""}`}

          onClick={() => {
            props.setCategory('tracking');
          }}>Tracking
        </div>
        <div
          className={`section-navigation__button ${props.white ? "section-navigation__button--white " : " " }${props.category === 'email' ? "section-navigation__button--selected" : ""}`}

          onClick={() => {
            props.setCategory('email');
          }}>Email de relance
        </div>
      
      </section>
    
    </>
  )
}