import React, {useContext, useEffect, useReducer, useState} from "react";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import {Link, withRouter} from 'react-router-dom'
import PageLayout from "../../layouts/PageLayout";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import {notification} from "../../utils/notification";
import Spaces from "../../components/sharedComponents/Spaces";
import {useForm} from "use-formidable";
import dataReducerAmbassador from "../../components/sharedComponents/Window/componentsWindow/componentsWA/dataReducerAmbassador";

function AmbassadorSubscription(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const {token} = props.match.params || "";
  
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [businessAvatar, setBusinessAvatar] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [avatar, updateAvatar, formidable] = useForm({
    file: null,
    temporary: "https://static.myshortlist.co/koala.png",
    accepted: false,
  });
  const [ambassador, dispatchAmbassador] = useReducer(dataReducerAmbassador, {});
  const [pageStatus, setPageStatus] = useState<"asleep" | "fetching" | "fetched" | "error">("asleep");
  const [actionStatus, setActionStatus] = useState<"ok" | "tokenNotValid" | "alreadyExists" | "done">("ok");
  
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isEmailValid = re.test(String(ambassador.email).toLowerCase());
  const isEmailEmpty = (ambassador.email ?? "").length === 0;
  const isEmailRed = !isEmailEmpty && !isEmailValid;
  const isEmailGreen = isEmailValid;
  const isFirstNameEmpty = (ambassador.firstName ?? "").length === 0;
  
  const isButtonActive = !isLoading && isEmailGreen && !isFirstNameEmpty && acceptedTerms && acceptedPolicy;
  
  useEffect(() => {
    (async() => {
      try {
        const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/b/get/business`, {
          withCredentials: true,
          headers: {
            token: token,
          }
        })).data;
  
        if(status === "tokenNotValid") {
          setActionStatus("tokenNotValid");
        }
        
        if(status === "ok") {
          setBusinessAvatar(data.avatar);
          setBusinessName(data.name);
          setPageStatus('fetched');
        }
        
        setPageStatus('fetched');
        
      } catch(error) {
        setPageStatus('error');
        devLog(error);
      }
    })();
  }, []);
  
  useEffect((): any => {
    (() => {
      
      if(avatar.file) {
        
        if(avatar.file.length > 1) {
          updateAvatar()({target: {type: "custom", name: "file", value: null}});
          updateAvatar()({target: {type: "custom", name: "accepted", value: false}});
          return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");
        }
        
        if(!formidable.isFileMime(avatar.file[0], ["jpeg", "png"])) {
          updateAvatar()({target: {type: "custom", name: "file", value: null}});
          updateAvatar()({target: {type: "custom", name: "accepted", value: false}});
          return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge", "error");
        }
        
        if(!formidable.isFileSmallerThan(avatar.file[0], 600_000)) {
          updateAvatar()({target: {type: "custom", name: "file", value: null}});
          updateAvatar()({target: {type: "custom", name: "accepted", value: false}});
          return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd (maximum 600ko)", "error");
        }
        
        updateAvatar()({target: {type: "custom", name: "accepted", value: true}});
        updateAvatar()({
          target: {
            type: "custom",
            name: "temporary",
            value: URL.createObjectURL(avatar.file[0])
          }
        });
      }
    })();
  }, [avatar.file]);
  
  const sendAvatar = async() => {
    try {
      const dataToSend: any = new FormData();
      dataToSend.append("avatar", avatar.file[0], avatar.file[0].name);
      const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/upload/data/unique/candidate/avatar`, dataToSend, {
        withCredentials: true, timeout: 15000, headers: {
          'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`
        }
      });
      if(fetched.data.status !== "ok") {
        return "";
      }
      devLog("avatar", fetched.data.data);
      return fetched.data.data;
    } catch(error) {
      devLog(error);
      return "";
    }
  }
  
  const addAmbassador = async() => {
    try {
      setIsLoading(true);
      let avatarUrl = "";
      if(avatar.accepted && !!avatar.file && avatar.file.length !== 0) {
        avatarUrl = await sendAvatar();
      }
      
      const {status} = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/amb/subscription/add`, {
        ...ambassador,
        avatarUrl: avatarUrl ? avatarUrl : "https://static.myshortlist.co/koala.png",
        phoneCountryCode: ambassador.phone?.countryCode ?? "33",
        phoneValue: ambassador.phone?.value,
        token
      }, {withCredentials: true})).data;
      
      if(status !== "ok") {
        setActionStatus(status);
        setIsLoading(false);
      }
  
      setActionStatus("done");
      notification(appContextV2.dispatchPanels, "Ambassadeur ajouté avec succès", "success");
      setIsLoading(false);
      
    } catch(error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      devLog(error);
    }
  }
  
  if(actionStatus === "tokenNotValid") {
    return(<div className={"text--magnified11x text--center"}>
      <Spaces n={15}/>
      Le lien n'est pas valide
      <Spaces n={10}/>
    </div>)
  }
  
  if(actionStatus === "done") {
    return(<div className={"text--magnified11x text--center"}>
      <Spaces n={15}/>
        Vous avez bien été enregistré ! Vous pouvez fermer cette fenêtre.
      <Spaces n={10}/>
    </div>)
  }
  
  if(actionStatus === "alreadyExists") {
    return(<div className={"text--magnified11x text--center"}>
      <Spaces n={15}/>
      Vous êtes déjà ambassadeur pour cette entreprise. Vous pouvez fermer cette fenêtre.
      <Spaces n={10}/>
    </div>)
  }
  
  return (<PageLayout page={props.page} status={pageStatus}>
    
    <Spaces n={2}/>
    
    <div className={"text--center"}>
      {businessAvatar && <img className={"ambassador__business-avatar"} alt={"avatar"} src={businessAvatar}/>}
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center text--magnified2x"}>
      Rejoignez les ambassadeurs de {businessName}
    </div>
    <Spaces n={0}/>
    <div className={"text--center text--grey"}>
      Rejoindre les ambassadeurs vous permet de partager les liens des postes ouverts et d'être récompensé par
      l’entreprise pour vos candidats recrutés.
    </div>
    
    <Spaces n={2}/>
    
    
    <div className={"text--center"}>
      <img src={avatar.temporary} className={"avatar-display--middle"}/>
      <Spaces n={0}/>
      
      <div className={"space-flex--center"}>
        <div className={"header__upload-image__button"}>
          <input type={"file"} name={"file"} id={"candidateAvatar"} className="input-camera"
                 onChange={updateAvatar()}/>
          <label htmlFor={"candidateAvatar"}
                 className={`btn-camera2 animation-straight-fade-in space-medium`}>
            <img src={"https://static.myshortlist.co/btn-camera.svg"} alt={"modify avatar"}/>
          </label>
        </div>
      </div>
    </div>
    
    <Spaces n={1}/>
  
    <div className={"ambassador-form__line"}>
      <div className={"ambassador__div"}>Prénom: *</div>
      <input className={"ambassador__input"} type={"text"} value={ambassador.firstName} onChange={(e) => {
        dispatchAmbassador({
          type: "UPDATE_AMBASSADOR", value: {firstName: e.target.value}
        });
      }}/>
    </div>
    
    <div className={"ambassador-form__line"}>
      <div className={"ambassador__div"}>Nom:</div>
      <input className={"ambassador__input"} type={"text"} value={ambassador.lastName} onChange={(e) => {
        dispatchAmbassador({
          type: "UPDATE_AMBASSADOR", value: {lastName: e.target.value}
        });
      }}/>
    </div>
    
    <div className={"ambassador-form__line"}>
      <div className={"ambassador__div"}> Email: *</div>
      <input
        className={`ambassador__input ${isEmailRed ? "ambassador__input-red" : ""} ${isEmailGreen ? "ambassador__input-green" : ""}`}
        type={"text"} value={ambassador.email} onChange={(e) => {
        dispatchAmbassador({
          type: "UPDATE_AMBASSADOR", value: {email: e.target.value}
        });
      }}/>
    </div>
    
    <div className={"ambassador-form__line"}>
      <div className={"ambassador__div"}> Téléphone:</div>
      
      <select className={"ambassador__input"} value={ambassador.phone?.countryCode}
              onChange={(e) => {
                dispatchAmbassador({
                  type: "UPDATE_AMBASSADOR_PHONE_COUNTRY_CODE", value: e.target.value
                });
              }}>
        <option value={"33"}> +33 </option>
      </select>
      <input className={"ambassador__tel-value ambassador__input"} type={"text"} value={ambassador.phone?.value}
             onChange={(e) => {
               dispatchAmbassador({
                 type: "UPDATE_AMBASSADOR_PHONE_VALUE", value: e.target.value
               });
             }}/>
    </div>
    
    
    <div className={"ambassador-form__line"}>
      <div className={"ambassador__div"}>Poste:</div>
      <input className={"ambassador__input"} type={"text"} value={ambassador.position} onChange={(e) => {
        dispatchAmbassador({
          type: "UPDATE_AMBASSADOR", value: {position: e.target.value}
        });
      }}/>
    </div>
    
    <div className={"ambassador-form__line"}>
      <div className={"ambassador__div"}> Entreprise:</div>
      <input className={"ambassador__input"} type={"text"} value={ambassador.businessName} onChange={(e) => {
        dispatchAmbassador({
          type: "UPDATE_AMBASSADOR", value: {businessName: e.target.value}
        });
      }}/>
    </div>
    
    
    <Spaces n={2}/>
    
    <div className={"space-flex--center"}>
      <div>
        <input type={"checkbox"} checked={acceptedTerms}
               onChange={() => {
                 if(!acceptedTerms) setAcceptedTerms(true);
                 if(acceptedTerms) setAcceptedTerms(false);
               }}/> J'accepte les <Link to={"/terms"}><span
        className={"modern-link"}>conditions générales</span></Link>
        <Spaces n={0}/>
        <input type={"checkbox"} checked={acceptedPolicy}
               onChange={() => {
                 if(!acceptedPolicy) setAcceptedPolicy(true);
                 if(acceptedPolicy) setAcceptedPolicy(false);
               }}/> J'ai pris connaissance de la <Link to={"/policy"}><span
        className={"modern-link"}>charte de confidentialité</span></Link>
      </div>
    </div>
    
    <Spaces n={0}/>
    
    <div className={"text--center"}>
      <button className={"btn-main"} disabled={!isButtonActive} onClick={() => addAmbassador()}>
        Rejoindre
      </button>
    </div>
  
  
  </PageLayout>);
}

export default withRouter(AmbassadorSubscription);