import React, {useEffect, useState} from "react";
import {useWizard} from "use-wizard";
import BPAV3SendChoice from "./componentsBPAV3Send/BPAV3SendChoice";
import BPAV3SendDirect from "./componentsBPAV3Send/BPAV3SendDirect";
import BPAV3SendLinkedIn from "./componentsBPAV3Send/BPAV3SendLinkedIn";
import BPAV3SendCV from "./componentsBPAV3Send/BPAV3SendCV";

export default (props: any) => {
  
  const [sending, setSending] = useState(false);
  
  const [applyStep, wizard2] = useWizard(["", "chooseMethod", "direct", "linkedin", "cv"]);
  
  useEffect(() => {
    const isCvMandatory: boolean = props.mission?.cvMandatory;
    const isLinkedinMandatory: boolean = props.mission?.linkedinMandatory;
    
    if(isLinkedinMandatory && !isCvMandatory) {
      wizard2.goToStep("linkedin");
    } else if(isCvMandatory) {
      wizard2.goToStep("cv");
    } else {
      wizard2.goToStep("chooseMethod");
    }
  }, []);
  
  switch(applyStep) {
    
    case "chooseMethod":
      return (<BPAV3SendChoice wizard2={wizard2} subPage={props.subPage}/>);
    
    case "direct":
      return (<BPAV3SendDirect missionId={props.mission._id}
                               subPage={props.subPage}
                               wizard={props.wizard}
                               answer={props.answer}
                               updateAnswer={props.updateAnswer}
                               formidableAnswer={props.formidableAnswer}
                               pageInfos={props.pageInfos}
                               sending={sending}
                               setSending={setSending}
                               setCandidacy={props.setCandidacy}/>);
    
    case "linkedin":
      return (<BPAV3SendLinkedIn missionId={props.mission._id}
                                 subPage={props.subPage}
                                 wizard2={wizard2}
                                 answer={props.answer}
                                 updateAnswer={props.updateAnswer}
                                 formidableAnswer={props.formidableAnswer}
                                 pageInfos={props.pageInfos}
                                 sending={sending}
                                 setSending={setSending}
                                 setCandidacy={props.setCandidacy}/>);
    
    case "cv":
      return (<BPAV3SendCV missionId={props.mission._id}
                           subPage={props.subPage}
                           wizard={props.wizard}
                           answer={props.answer}
                           updateAnswer={props.updateAnswer}
                           formidableAnswer={props.formidableAnswer}
                           pageInfos={props.pageInfos}
                           sending={sending}
                           setSending={setSending}
                           setCandidacy={props.setCandidacy}/>);
    
    default:
      return (<></>);
  }
  
}
