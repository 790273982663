import {devLog} from "../../../../utils/devLog/devLog";
import axios from "axios";

export default async(advantage: string, language: string) => {
  try {
    
    await axios.post(`${process.env.REACT_APP_API_URL}/v3/data/dictionary/advantage`, {advantage, language
    }, {withCredentials: true});
  
  } catch(error) {
    
    devLog(error);
  }
}