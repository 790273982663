import React, {useContext, useEffect} from "react";
import AppContext, {IAppV2Context} from "../components/App/context/AppContext";
import FourHundredThree from "../pages/errors/FourHundredThree";
import ErrorPage from "../pages/errors/ErrorPage";
import {Loading} from "../components/sharedComponents/Loading/Loading";

interface ISubPageLayoutProps {
  subPage: string,
  // status: 'pending' | 'error' | 'forbidden' | 'ok',
  status: 'asleep' | 'fetching' | 'fetched' | 'ok' | 'error' | 'forbidden',
  children: any,
  title?: string,
  small?: boolean,
}

export default function(props: ISubPageLayoutProps) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  useEffect(()=>{
    appContextV2.dispatchNavigation({type: "SET_SUB_PAGE", value: props.subPage});
  }, []);
  
  return (<>
    {/*<section className={`${!!props.small ? "page-layout--small" : "page-layout"} animation-fade-in`}>*/}
    {/*  */}
    {/*  {*/}
    {/*    !!props.title && <h1 className={"page-title"}>*/}
    {/*      {props.title}*/}
    {/*    </h1>*/}
    {/*  }*/}
      
      <div>
        
        <SubPageLayoutContent {...props} >
          {props.children}
        </SubPageLayoutContent>
      
      </div>
    
    {/*</section>*/}
  </>);
  
}

function SubPageLayoutContent(props: ISubPageLayoutProps) {
  
  switch(props.status) {
    
    case 'asleep':
    case 'fetching':
      return (<Loading fullPage={false}/>);
      
    case 'forbidden':
      return (<FourHundredThree page={"FourHundredThree"} fullPage={false} setPage={false}/>);
      
    case 'ok':
    case 'fetched':
      return (
        <section className={"animation-fade-in"}>
          {props.children}
        </section>
      );
      
    default:
    case 'error':
      return (<ErrorPage page={"ErrorPage"} code={0} fullPage={false} setPage={false}/>);
  }
  
}