import React, {useContext} from "react";
import WindowWrapper from "../../../layouts/WindowLayout";
import Spaces from "../Spaces";
import {useWizard} from "use-wizard";
import AppContext, {IAppV2Context} from "../../App/context/AppContext";
import {devLog} from "../../../utils/devLog/devLog";
import {notification} from "../../../utils/notification";
import axios from "axios";
import {shortenText} from "../../../utils/shortenText";

export {WindowManageInvite};

const WindowManageInvite = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [step, wizard] = useWizard(["email", "role", "validation", "pending"]);
  
  const teamId: string = appContextV2.appPanels.window.windowOptions.data?.teamId;
  const inviteId: string = appContextV2.appPanels.window.windowOptions.data?.inviteId;
  const email: string = appContextV2.appPanels.window.windowOptions.data?.email;
  const token: string = appContextV2.appPanels.window.windowOptions.data?.token;
  const role: string = appContextV2.appPanels.window.windowOptions.data?.role;
  const linkValid: string = appContextV2.appPanels.window.windowOptions.data?.linkValid;
  
  const deleteInvite = () => {
    (async() => {
      try {
        
        notification(appContextV2.dispatchPanels, "Suppression de l'invitation", "information");
        wizard.goToStep('pending');
        
        const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/t/invite/delete`, {
          teamId: teamId,
          inviteId: inviteId,
        }, {withCredentials: true});
        
        if(fetched.data.status !== "ok") {
          appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        
        appContextV2.appPanels.window?.windowOptions?.data?.deleteInviteFront(inviteId);
        notification(appContextV2.dispatchPanels, "Succès de la suppression", "success");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        
      } catch(error) {
        devLog(error);
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      }
    })();
  }
  
  if(step === "pending") {
    return (<WindowWrapper windowTitle={""}>
    </WindowWrapper>);
  }
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    <div className={"text--center"}>
      L'invitation n'a pas encore été acceptée. Tu peux la supprimer et en lancer une nouvelle si le lien n'est plus
      valide ou si des informations sont à
      modifier.
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <span className={"text--magnified2x"}>{email}</span>
      <br/><br/>
      Rôle: {role === "admin" && "Admin"}
      {role === "manager" && "Manager"}
      {role === "user" && "Utilisateur"}
    </div>
    
    <Spaces n={1}/>
    <p className={"text--center"}>Partagez ce lien d'invitation:</p>
    <div id={"link"} style={{
      width: "90%",
      backgroundColor: "#eee",
      padding: "10px",
      margin: "auto",
      overflow: "hidden",
      lineBreak: "normal",
      wordBreak: "break-all",
      fontSize: "0.9rem",
      borderRadius: "15px",
      cursor: "pointer",
      textAlign: "center"
    }} onClick={() => {
      navigator.clipboard.writeText(`https://myshortlist.co/invite/${token}`);
      notification(appContextV2.dispatchPanels, "Lien copié avec succès", "success")
    }}>
      {shortenText(`https://myshortlist.co/invite/${token}`, 50)}
    </div>
    <p className={"text--smaller text--grey text--center"}>Cliquer sur le lien pour le copier</p>
    {
      !linkValid && <div className={"text--red text--center"}>
        Ce lien n'est plus valide. Supprimez cette invitation et créez-en une nouvelle.
      </div>
    }
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button className={"btn-main"} onClick={() => deleteInvite()}>
        Supprimer
      </button>
    </div>
  </WindowWrapper>);
  
}