import React, {useContext, useEffect, useState} from "react";
import FormEditorNav from "./componentsMissionFormEditorV2/FormEditorNav";
import AppContext, {IAppV2Context} from "../../../components/App/context/AppContext";
import {notification} from "../../../utils/notification";
import LoadingStyle2 from "../../../components/sharedComponents/Loading/LoadingStyle2";
import axios from "axios";
import Spaces from "../../../components/sharedComponents/Spaces";
import FormEditorNavUpdate from "./componentsMissionFormEditorV2/FormEditorNavUpdate";
import dragQuestion from "./componentsMissionFormEditorV2/dependencies/dragAndDrop/dragQuestion";
import dropQuestion from "./componentsMissionFormEditorV2/dependencies/dragAndDrop/dropQuestion";
import dropQuestionOnEmptyFront
    from "./componentsMissionFormEditorV2/dependencies/dragAndDrop/dropQuestionOnEmptyFront";
import functionDeleteQuestionFront
    from "./componentsMissionFormEditorV2/dependencies/updateDirectory/functionDeleteQuestionFront";
import functionToggleExtendedFront
    from "./componentsMissionFormEditorV2/dependencies/updateDirectory/functionToggleExtendedFront";
import functionUpdateQuestionFront
    from "./componentsMissionFormEditorV2/dependencies/updateDirectory/functionUpdateQuestionFront";
import functionCloseAllFront from "./componentsMissionFormEditorV2/dependencies/updateDirectory/functionCloseAllFront";
import {useForm} from "use-formidable";
import {devLog} from "../../../utils/devLog/devLog";
import {withRouter} from "react-router-dom";
import FormEditorButtons from "./componentsMissionFormEditorV2/FormEditorButtons";
import FormEditorContent from "./componentsMissionFormEditorV2/FormEditorContent";

function MissionFormEditorV2(props: {
    editorMode: 'creation' | 'edition',
    isPageLoading: boolean,
    presets: any,
    form: any,
    setForm: Function,
    timeStamps: any,
    wizard: any,
    setMissionTokens: any,
    saveCallback: Function,
    setPresets: Function,
}) {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    console.log("form",props.form);

    /** Informations about dragged questions */
    const [dragged, setDragged] = useState({
        draggedType: "", // "cloud" / "question"
        draggedFromFormId: "",
        draggedFromIndex: 0,
    });


    /** Jobdesc */
    const [jobDesc, setJobDesc, formidableJobDesc] = useForm({file: null});
    console.log("jobDesc.file",jobDesc.file);
    const [fetchingFile, setFetchingFile] = useState(false);

    const [isLocallyLoading, setIsLocallyLoading] = useState(false);

    const areButtonsActive: boolean = !props.isPageLoading && !fetchingFile && !isLocallyLoading;
    // const areButtonsActive: boolean = !props.isPageLoading && !fetchingFile;

    /** Handles file dropping */
    useEffect(() => {
        (() => {
            if (!!jobDesc.file) {
                if (jobDesc.file.length > 1) {
                    setJobDesc()({target: {type: "custom", name: "file", value: null}});
                    return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");

                }
                if (!formidableJobDesc.isFileMime(jobDesc.file[0], ["pdf", "docx"])) {
                    setJobDesc()({target: {type: "custom", name: "file", value: null}});
                    return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge", "error");
                }
            }
        })();
    }, [jobDesc.file]);

    /** Drag and drop questions */
    const drag = (draggedFromFormId: string, draggedFromIndex: number) => dragQuestion(setDragged, draggedFromFormId, draggedFromIndex,);
    const drop = (draggedToFormId: string, draggedToIndex: number) => dropQuestion({
        dragged,
        draggedToFormId,
        draggedToIndex,
        props,
    });
    const dropOnEmptyFront = (draggedToFormId: string) => dropQuestionOnEmptyFront({dragged, draggedToFormId, props,});

    /** Update front */
    const deleteQuestionFront = functionDeleteQuestionFront(appContextV2, props);
    const toggleExtendedFront = functionToggleExtendedFront(props);
    const updateQuestionFront = functionUpdateQuestionFront(props);
    const closeAllFront = functionCloseAllFront(props);

    const addQuestion = (formId: string, question: any) => {
        if (formId === 'form') {
            const newForm1 = [...props.form.form, question];
            props.setForm()({target: {type: "custom", name: "form", value: newForm1}});
            notification(appContextV2.dispatchPanels, "Succès de la sauvegarde", "success");
        }
        if (formId === 'form2') {
            const newForm2 = [...props.form.form2, question];
            props.setForm()({target: {type: "custom", name: "form2", value: newForm2}});
            notification(appContextV2.dispatchPanels, "Succès de la sauvegarde", "success");

            if (!formidableJobDesc.isFileSmallerThan(jobDesc.file[0], 9_000_000)) {
                setJobDesc()({target: {type: "custom", name: "file", value: null}});
                return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd (maximum 8mo)", "error");
            }
            return notification(appContextV2.dispatchPanels, "Fichier accepté", "success");
        }
    }

    /** Function to save the jobDesc, always call it before saving document */
    const saveJobDesc = async () => {
        try {
            setFetchingFile(true);
            const dataToSend: any = new FormData();
            dataToSend.append("jobdesc", jobDesc.file[0], jobDesc.file[0].name);
            const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/upload/data/jobdesc`, dataToSend, {
                withCredentials: true, headers: {
                    'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
                    // >>>>>>> newMain
                }
            });
            if (fetched.data.status !== "ok") {
                setFetchingFile(false);
                notification(appContextV2.dispatchPanels, "Le fichier n'a pas été ajouté", "warning");
                return "";
            }
            setFetchingFile(false);
            props.setForm()({target: {type: 'custom', name: 'documentUrl', value: fetched.data.data}});
            console.log("FETCHED FILE ---", fetched.data.data);
            return (fetched.data.data);
        } catch (error) {
            setFetchingFile(false);
            notification(appContextV2.dispatchPanels, "Le fichier n'a pas été ajouté", "warning");
            return "";
        }
    }

    const openPreset = (formId: string) => {
        try {
            appContextV2.dispatchPanels({
                type: 'OPEN_WINDOW', value: {
                    type: 'openPresets',
                    data: {
                        addQuestion: addQuestion,
                        formId: formId,
                        positionWanted: props.form.positionWanted,
                    }
                }
            })
        } catch (error) {
            throw (error)

        }
    }

    const duplicate = async () => {
        try {
            const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/duplicate`, {
                missionId: appContextV2.appNavigation.missionId,
            }, {
                withCredentials: true,
            });
            if (fetched.data.status !== "ok") {
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "warning");
                return;
            }
            //@ts-ignore
            props.history.push('/');
            notification(appContextV2.dispatchPanels, "Succès de la duplication", "success");
        } catch (error) {
            devLog(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "warning");
        }
    }

    const [category,setCategory] = useState <'remuneration' | 'metier' | 'details' | 'options'> ('remuneration');

    return (<main>

        {/*--------------------------- information */}

        {/*--------------------------- actions */}
        {props.editorMode === 'creation' && <FormEditorNav reduce={closeAllFront}
                                                           currentStatus={""}
                                                           jobDesc={jobDesc}
                                                           saveJobDesc={saveJobDesc}
                                                           areButtonsActive={areButtonsActive}
                                                           publish={props.saveCallback}
                                                           form={props.form}
                                                           />}
        {props.editorMode === 'edition' && <FormEditorNavUpdate reduce={closeAllFront}
                                                                currentStatus={""}
                                                                form={props.form}
                                                                jobDesc={jobDesc}
                                                                saveJobDesc={saveJobDesc}
                                                                areButtonsActive={areButtonsActive}
                                                                duplicate={duplicate}
                                                                publish={props.saveCallback}
                                                                />}

        <div>{props.isPageLoading && <LoadingStyle2/>}</div>

        <Spaces n={1}/>

        {/*REFACTORING IN PROGRESS*/}

        <FormEditorButtons category={category} setCategory={setCategory} {...props}/>

        <Spaces n={1}/>

        <FormEditorContent category={category}
                           {...props}
                           drag={drag}
                           drop={drop}
                           toggleExtendedFront={toggleExtendedFront}
                           updateQuestionFront={updateQuestionFront}
                           deleteQuestionFront={deleteQuestionFront}
                           addQuestion={addQuestion}
                           openPreset={openPreset}
                           dropOnEmptyFront={dropOnEmptyFront}
                           jobDesc={jobDesc}
                           setJobDesc={setJobDesc}
        />




        {/*--------------------------- sumup / options */}


        {/*--------------------------- options */}


        {/*--------------------------- actions */}
        {props.editorMode === 'creation' && <FormEditorNav reduce={closeAllFront}
                                                           currentStatus={""}
                                                           jobDesc={jobDesc}
                                                           saveJobDesc={saveJobDesc}
                                                           areButtonsActive={areButtonsActive}
                                                           publish={props.saveCallback}
                                                           form={props.form}
                                                           />}
        {props.editorMode === 'edition' && <FormEditorNavUpdate reduce={closeAllFront}
                                                                currentStatus={""}
                                                                jobDesc={jobDesc}
                                                                form={props.form}
                                                                saveJobDesc={saveJobDesc}
                                                                duplicate={duplicate}
                                                                areButtonsActive={areButtonsActive}
                                                                publish={props.saveCallback}
                                                                />}

        <div>{props.isPageLoading && <LoadingStyle2/>}</div>

        <Spaces n={1}/>

    </main>);

}

export default withRouter((props: any) => MissionFormEditorV2(props));