import getGlobalMark from "./helpers/getGlobalMark";

export default (args: {
  fetchedDataV2: any,
  filterByWords: any,
  filterByMark: any,
  filterByYearsOfExperience: any,
  filterBySalaryMin: any,
  filterByDateOfAvailability: any,
  filterByTraining: any,
  setDisplayedShortlist: Function,
}) => {

  const shortlistCopy = [...args.fetchedDataV2.shortlist];

  /** Defines which filters are active */
  const isFilterByWordsActive: boolean = !!args.filterByWords;
  const isFilterByMarkActive: boolean = !!args.filterByMark;
  const isFilterByYearsOfExperienceActive: boolean = !!args.filterByYearsOfExperience;
  const isFilterBySalaryMinActive: boolean = !!args.filterBySalaryMin;
  const isFilterByDateOfAvailabilityActive: boolean = !!args.filterByDateOfAvailability;
  const isFilterByTrainingActive: boolean = !!args.filterByTraining;


  /** Defines results for each filter */
  let idFilteredByWords: string[] = [];
  let idFilteredByMark: string[] = [];
  let idFilteredByYearsOfExperience: string[] = [];
  let idFilteredBySalaryMin: string[] = [];
  let idFilteredByDateOfAvailability: string[] = [];
  let idFilteredByTraining: string[] = [];


  /** Defines the result */
  let resultShortlist: any = [];

  if (!isFilterByWordsActive && !idFilteredByMark &&!isFilterByYearsOfExperienceActive &&!isFilterBySalaryMinActive
      &&!isFilterByDateOfAvailabilityActive &&!isFilterByTrainingActive) {
    resultShortlist = shortlistCopy;
  } else {

    /** Get IDs of result for words filter */
    (isFilterByWordsActive && (idFilteredByWords = shortlistCopy
        .filter((item: any) => (
          item?.firstName?.toLowerCase().includes(args.filterByWords?.toLowerCase()) ||
          item?.lastName?.toLowerCase().includes(args.filterByWords?.toLowerCase())
        ))
        .map((item: any) => (item?._id))
    ));

    /** Get IDs of result for yearsOfExperience filter */
    (isFilterByYearsOfExperienceActive && (idFilteredByYearsOfExperience = shortlistCopy
            .filter((item: any) => (
                item?.defaultAnswers?.yearsOfExperience >= args.filterByYearsOfExperience)
            ).map((item: any) => (item?._id))
    ));

    /** Get IDs of result for salaryMin filter */
    (isFilterBySalaryMinActive && (idFilteredBySalaryMin = shortlistCopy
            .filter((item: any) => (
                item?.defaultAnswers?.salaryMin <= args.filterBySalaryMin)
            ).map((item: any) => (item?._id))
    ));

    /** Get IDs of result for dateOfAvailability filter */
    (isFilterByDateOfAvailabilityActive && (idFilteredByDateOfAvailability = shortlistCopy
            .filter((item: any) =>
                (new Date(item?.defaultAnswers?.dateOfAvailability).getTime() <= new Date(args.filterByDateOfAvailability).getTime()
            )).map((item: any) => (item?._id))
    ));

    /** Get IDs of result for dateOfAvailability filter */
    (isFilterByTrainingActive && (idFilteredByTraining = shortlistCopy
            .filter((item: any) => (
                item?.defaultAnswers?.training?.toLowerCase().includes(args.filterByTraining?.toLowerCase())
            )).map((item: any) => (item?._id))
    ));
    
    /** Get IDs of result for words filter */
    if (isFilterByMarkActive) {
      switch (args.filterByMark) {

        case '100':
          idFilteredByMark = shortlistCopy
            .filter((item: any) => (getGlobalMark(item?.markSkills, item?.markForm) <= 100 && getGlobalMark(item?.markSkills, item?.markForm) >= 90 && !item?.imported))
            .map((item: any) => (item?._id));
          break;

        case '90':
          idFilteredByMark = shortlistCopy
            .filter((item: any) => (getGlobalMark(item?.markSkills, item?.markForm) < 90 && getGlobalMark(item?.markSkills, item?.markForm) >= 70 && !item?.imported))
            .map((item: any) => (item?._id));
          break;

        case '70':
          idFilteredByMark = shortlistCopy
            .filter((item: any) => (getGlobalMark(item?.markSkills, item?.markForm) < 70 && getGlobalMark(item?.markSkills, item?.markForm) >= 50 && !item?.imported))
            .map((item: any) => (item?._id));
          break;

        case '50':
          idFilteredByMark = shortlistCopy
            .filter((item: any) => (getGlobalMark(item?.markSkills, item?.markForm) < 50 && !item?.imported))
            .map((item: any) => (item?._id));
          break;

        case 'blocked':
          idFilteredByMark = shortlistCopy
            .filter((item: any) => (item?.blockedByAlgorithm || item?.status === "blocked"))
            .map((item: any) => (item?._id));
          break;

        case 'imported':
          idFilteredByMark = shortlistCopy
            .filter((item: any) => item?.imported)
            .map((item: any) => (item?._id));
          break;
      }
    }

    /** Add IDs to the result  */
    // For each ID, add it only if it matches active filters
    for (let candidacy of shortlistCopy) {
      if (isFilterByWordsActive && !idFilteredByWords.includes(candidacy?._id)) {
        continue;
      }
      if (isFilterByMarkActive && !idFilteredByMark.includes(candidacy?._id)) {
        continue;
      }
      if (isFilterByYearsOfExperienceActive && !idFilteredByYearsOfExperience.includes(candidacy?._id)) {
        continue;
      }
      if (isFilterBySalaryMinActive && !idFilteredBySalaryMin.includes(candidacy?._id)) {
        continue;
      }
      if (isFilterByDateOfAvailabilityActive && !idFilteredByDateOfAvailability.includes(candidacy?._id)) {
        continue;
      }
      if (isFilterByTrainingActive && !idFilteredByTraining.includes(candidacy?._id)) {
        continue;
      }
      resultShortlist.push(candidacy);
    }
  }

 // const sortedShortlist: any = [...resultShortlist].sort((a: any, b: any) => (getGlobalMark(b.markSkills, b.markForm) - getGlobalMark(a.markSkills, a.markForm)));

  const sortedShortlist: any = [...resultShortlist].sort((a: any, b: any) => (b.gradeByTeam - a.gradeByTeam));
  console.log("sortedShortlist",sortedShortlist);

  let organizedShortlist =[];
  for(let i=5; i>-1; i--){
    organizedShortlist.push(...resultShortlist.filter((item:any)=>((item.gradeByTeam===i) && (!!item.phoneInMessage))));
    organizedShortlist.push(...resultShortlist.filter((item:any)=>((item.gradeByTeam===i) &&
        (!item.phoneInMessage && item?.status !== "withdrawed" && item?.status !== "rejected" && !item?.blockedByAlgorithm))));
    organizedShortlist.push(...resultShortlist.filter((item:any)=>((item.gradeByTeam===i) &&
        (!item.phoneInMessage && item?.status !== "withdrawed" && item?.status !== "rejected" && !!item?.blockedByAlgorithm))));
    organizedShortlist.push(...resultShortlist.filter((item:any)=>((item.gradeByTeam===i) && (item?.status === "withdrawed"))));
    organizedShortlist.push(...resultShortlist.filter((item:any)=>((item.gradeByTeam===i) && (item?.status === "rejected"))));

  }

  // let organizedShortlist = [
  //     ...sortedShortlist.filter((item:any)=>(!!item.phoneInMessage)),
  //   ...sortedShortlist.filter((item: any) => (!item.phoneInMessage && item?.status !== "withdrawed" && item?.status !== "rejected" && !item?.blockedByAlgorithm)),
  //   ...sortedShortlist.filter((item: any) => (!item.phoneInMessage && item?.status !== "withdrawed" && item?.status !== "rejected" && !!item?.blockedByAlgorithm)),
  //   ...sortedShortlist.filter((item: any) => (!item.phoneInMessage && item?.status === "withdrawed")),
  //   ...sortedShortlist.filter((item: any) => (!item.phoneInMessage && item?.status === "rejected")),
  // ];

  args.setDisplayedShortlist(organizedShortlist);

}