import React from "react";
import {DisplayDictionary} from "../RecruiterParametersDictionaries";

export {RecruiterParametersDictionarySoftwares}

const RecruiterParametersDictionarySoftwares = (props: any) => {
  
  return(<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Dictionnaire des logiciels</h1>
  
    <DisplayDictionary keyValue={"software"} data={props.dictionary || []} updateDictionaryEntry={()=>{}} deleteDictionaryEntry={()=>{}} />
  
  </div>);
}