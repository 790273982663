
export default (args: {sessionFetchedStatus: string, fetchedSession: any, dispatchSession: Function}) => {
  
  switch(args.sessionFetchedStatus) {
    
    case 'fetched':
      return args.dispatchSession({type: "SET_SESSION", value: args.fetchedSession});
      
    case 'error':
      return args.dispatchSession({type: "SET_SESSION_ERROR", value: args.fetchedSession});
      
    case 'fetching':
    case 'asleep':
    default:
      return;
  }
  
}