
export {applyFiltersPoolPeople};

const applyFiltersPoolPeople = (args: {
  
  filterByWords: string,
  filterByAvailableFrom: string,
  filterByLastExchange: string,
  showRemovedCandidates: boolean,
  candidates: any[],
  setDisplayedCandidates: Function,
}) => {
  
  /** Defines which filters are active */
  const isFilterByWordsActive: boolean = !!args.filterByWords;
  const isFilterByAvailableFromActive: boolean = !!args.filterByAvailableFrom;
  const isFilterByLastExchangeActive: boolean = !!args.filterByLastExchange;
  const isShowRemovedCandidatesActive: boolean = args.showRemovedCandidates;
  
  /** Defines results for each filter */
  let idFilteredByWords: string[] = [];
  let idFilteredByAvailability: string[] = [];
  let idFilteredByLastExchange: string[] = [];
  let idFilteredByRemovedCandidates: string[] = [];
  
  /** Defines the result */
  let resultCandidates: any = [];
  
  /** Get IDs of result for words filter */
  (isFilterByWordsActive && (idFilteredByWords = args.candidates
  .filter((item: any) => {
    const bagOfWords: string[] = args.filterByWords.split(' ').map((item: string) => item.toLowerCase());
    for(let word of bagOfWords) {
      if(item.firstName?.toLowerCase().includes(word)) return true;
      if(item.lastName?.toLowerCase().includes(word)) return true;
      if(item.email?.toLowerCase().includes(word)) return true;
      if(item.phone?.toLowerCase().includes(word)) return true;
      if(item.comment?.toLowerCase().includes(word)) return true;
      if(item.tags && item.tags.length !== 0) {
        for(let tag of item.tags) {
          if(!!tag && tag?.toLowerCase().includes(word)) return true;
        }
      }
      if(item.positionsWanted && item.positionsWanted.length !== 0) {
        for(let position of item.positionsWanted) {
          if(!!position && position?.toLowerCase().includes(word)) return true;
        }
      }
      if(item.sectors && item.sectors.length !== 0) {
        for(let sector of item.sectors) {
          if(!!sector && sector?.toLowerCase().includes(word)) return true;
        }
      }
      if(item.mobility && item.mobility.length !== 0) {
        for(let l of item.mobility) {
          if(typeof l === "string" && l?.toLowerCase().includes(word)) return true;
        }
      }
      if(item.skills && item.skills.length !== 0) {
        for(let skill of item.skills) {
          if(!!skill && skill?.toLowerCase().includes(word)) return true;
        }
      }
      if(item.languages && item.languages.length !== 0) {
        for(let language of item.languages) {
          if(!!language.value && language.value?.toLowerCase().includes(word)) return true;
        }
      }
      if(item.softwares && item.softwares.length !== 0) {
        for(let software of item.softwares) {
          if(!!software.value && software.value?.toLowerCase().includes(word)) return true;
        }
      }
    }
    return false;
  })
  .map((item: any) => (item._id))));
  
  /** Get IDs of result for last exchange */
  if(isFilterByLastExchangeActive) {
    switch(args.filterByLastExchange) {
      case "12":
        idFilteredByLastExchange = args.candidates
        .filter((item: any) => {
          if(!item.dateOfLastAction) return false;
          const dateOfLastRealAction = new Date(item.dateOfLastAction).getTime();
          const dateOfWantedMinimumLastExchange = new Date().getTime() - 12 * 30 * 24 * 60 * 60 * 1000;
          return dateOfLastRealAction < dateOfWantedMinimumLastExchange;
        })
        .map((item: any) => (item._id));
        break;
      case "6":
        idFilteredByLastExchange = args.candidates
        .filter((item: any) => {
          if(!item.dateOfLastAction) return false;
          const dateOfLastRealAction = new Date(item.dateOfLastAction).getTime();
          const dateOfWantedMaximumLastExchange = new Date().getTime() - 6 * 30 * 24 * 60 * 60 * 1000;
          const dateOfWantedMinimumLastExchange = new Date().getTime() - 12 * 30 * 24 * 60 * 60 * 1000;
          return dateOfLastRealAction > dateOfWantedMinimumLastExchange && dateOfLastRealAction < dateOfWantedMaximumLastExchange;
        })
        .map((item: any) => (item._id));
        break;
      case "3":
        idFilteredByLastExchange = args.candidates
        .filter((item: any) => {
          if(!item.dateOfLastAction) return false;
          const dateOfLastRealAction = new Date(item.dateOfLastAction).getTime();
          const dateOfWantedMaximumLastExchange = new Date().getTime() - 3 * 30 * 24 * 60 * 60 * 1000;
          const dateOfWantedMinimumLastExchange = new Date().getTime() - 6 * 30 * 24 * 60 * 60 * 1000;
          return dateOfLastRealAction > dateOfWantedMinimumLastExchange && dateOfLastRealAction < dateOfWantedMaximumLastExchange;
        })
        .map((item: any) => (item._id));
        break;
      case "1":
        idFilteredByLastExchange = args.candidates
        .filter((item: any) => {
          if(!item.dateOfLastAction) return false;
          const dateOfLastRealAction = new Date(item.dateOfLastAction).getTime();
          const dateOfWantedMaximumLastExchange = new Date().getTime() - 1 * 30 * 24 * 60 * 60 * 1000;
          const dateOfWantedMinimumLastExchange = new Date().getTime() - 3 * 30 * 24 * 60 * 60 * 1000;
          return dateOfLastRealAction > dateOfWantedMinimumLastExchange && dateOfLastRealAction < dateOfWantedMaximumLastExchange;
        })
        .map((item: any) => (item._id));
        break;
      case "0":
        idFilteredByLastExchange = args.candidates
        .filter((item: any) => {
          if(!item.dateOfLastAction) return false;
          const dateOfLastRealAction = new Date(item.dateOfLastAction).getTime();
          const dateOfWantedMaximumLastExchange = new Date().getTime() - 0 * 30 * 24 * 60 * 60 * 1000;
          const dateOfWantedMinimumLastExchange = new Date().getTime() - 1 * 30 * 24 * 60 * 60 * 1000;
          return dateOfLastRealAction > dateOfWantedMinimumLastExchange && dateOfLastRealAction < dateOfWantedMaximumLastExchange;
        })
        .map((item: any) => (item._id));
        break;
    }
  }
  
  /** Get IDs of result for availability */
  (isFilterByAvailableFromActive && (idFilteredByAvailability = args.candidates
    .filter((item: any) => {
      if(!item.dateOfListeningToMarket) return false;
      const dateOfDeclaredAvailability = new Date(item.dateOfListeningToMarket).getTime();
      const dateOfAvailabilityRequested = new Date(args.filterByAvailableFrom).getTime();
      return (dateOfDeclaredAvailability > dateOfAvailabilityRequested - 15 * 24 * 60 * 60 * 1000) &&
        (dateOfDeclaredAvailability < dateOfAvailabilityRequested + 15 * 24 * 60 * 60 * 1000);
    })
    .map((item: any) => (item._id))
  ));
  
  /** Get IDs of result for removed args.candidates */
  if(!isShowRemovedCandidatesActive) {
    // See not-removed args.candidates
    idFilteredByRemovedCandidates = args.candidates?.filter((item: any) => (item.deleted === false || item.deleted !== true || typeof item.deleted === "undefined"))
    .map((item: any) => (item._id));
  } else {
    idFilteredByRemovedCandidates = args.candidates?.filter((item: any) => (item.deleted === true))
    .map((item: any) => (item._id));
  }
  
  /** Add IDs to the result  */
  // For each ID, add it only if it matches active filters
  for(let candidate of args.candidates) {
    if(isFilterByWordsActive && !idFilteredByWords.includes(candidate._id)) {
      continue;
    }
    if(isFilterByLastExchangeActive && !idFilteredByLastExchange.includes(candidate._id)) {
      continue;
    }
    if(isFilterByAvailableFromActive && !idFilteredByAvailability.includes(candidate._id)) {
      continue;
    }
    // Specific for history, filter always active
    if(!idFilteredByRemovedCandidates.includes(candidate._id)) {
      continue;
    }
    resultCandidates.push(candidate);
  }
  
  const organizedCandidates = resultCandidates.sort((a: any, b: any) => (new Date(b.dateOfLastAction).getTime() - new Date(a.dateOfLastAction).getTime()));
  
  args.setDisplayedCandidates(organizedCandidates);
  
}