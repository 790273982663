import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import RecruiterNewMissionV2 from "./RecruiterNewMissionV2";
import RecruiterNewMissionV1 from "./RecruiterNewMissionV1";
import SubNavLinkGenerator
    from "../../components/sharedComponents/Navigation/SubNavbarComponents/shared/SubNavLinkGenerator";

export default (props: any) => {
    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    return (<>
        {appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" && <RecruiterNewMissionV2/>}
        {appContextV2.appSession.sessionBusiness.businessName !== "Black-belt.io" &&
            <RecruiterNewMissionV1/>
         }

    </>);
}