import React from "react";

export default (props: {
  avatars: string[];
}) => {
  
  if(!props.avatars) {
    return(<></>);
  }
  
  if(props.avatars.length === 0) {
    return(<></>);
  }
  
  const avatars = props.avatars.length < 8 ? props.avatars : props.avatars.slice(0,8);
  
  return(<>
    {avatars.map((item: string, key: number) => <img className={"new-avatar--small"} src={item} alt={"avatar"} key={key}/>)}
  </>);
  
}
