import React from "react";
import WindowWrapper from "../../../../layouts/WindowLayout";

export default (props: any) => {
  
  return (<>
  
    <WindowWrapper windowTitle={"Le lien a bien été copié !"} status={"ok"}>
      Vous pouvez maintenant le partager à votre réseau.
    </WindowWrapper>
  </>);
}