import React from "react";
import LoadingStyle2 from "../../../../components/sharedComponents/Loading/LoadingStyle2";
import Spaces from "../../../../components/sharedComponents/Spaces";

export default (props: any) => {
  
  const isButtonNotActive = props.evaluation.comment.length === 0;
  
  return (<div className={"animation-fade-in"}>
    
    <div className={"text--center"}>Laissez un commentaire pour le recruteur</div>
    
    <Spaces n={0}/>
    <div className={"text--center"}>
    
    <textarea className={"evaluation__comment"} placeholder={""}
              value={props.evaluation?.comment}
              onChange={(e) => {
                if(e.target.value.length < 700) {
                  const newValue = e.target.value;
                  props.setEvaluation((ps: any) => ({
                    ...ps,
                    comment: newValue,
                  }))
                }
              }}>
        </textarea>
      <div className={"text--grey text--magnified11x"}>{props.evaluation?.comment?.length}/700</div>
      
      <Spaces n={0}/>
      
      <button className={"btn-secondary"} onClick={() => props.sendEvaluation()}
              disabled={isButtonNotActive}> Envoyer
      </button>
    
    </div>
    
    {props.evaluationStep === 2 && props.sendStatus === "fetching" && <LoadingStyle2/>}
  
  </div>);
}