import React from "react";
import PageLayout from "../../layouts/PageLayout";
import Spaces from "../../components/sharedComponents/Spaces";
import DisplayFeedbacksOnPlatform from "../../components/sharedComponents/DisplayFeedbacksOnPlatform";

export default(props: any) => {
  
  return(<PageLayout fullWidth={true} grey={true} page={props.page} status={"ok"}>
  
    <Spaces n={6}/>

    <DisplayFeedbacksOnPlatform/>
  
    <Spaces n={6}/>
  
  </PageLayout>)
}