import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import BackToLandingPage from "./shared/BackToLandingPage";
import SubNavLinkGenerator from "./shared/SubNavLinkGenerator";

export function SubMenuRecruiterProfilesContent(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const currentPage: string = appContextV2.appNavigation.page;
  const currentSubPage: string = appContextV2.appNavigation.subPage;
  const userRole: string = appContextV2.appSession.session.userRole;
  
  const showBusinessProfile: boolean = (userRole === 'admin' || userRole === 'superAdmin');
  
  return (<>
    <BackToLandingPage/>
    <SubNavLinkGenerator fr={"Personnel"} en={"Personnal"} uri={"/business/profiles/user"}
                         active={currentPage === "RecruiterProfiles" && currentSubPage === "RecruiterProfilesUser"}
                         alt={"personnal profile"} icon={"https://static.myshortlist.co/btn-profile.svg"} smaller={true}/>
    {
      showBusinessProfile && <>
        <SubNavLinkGenerator fr={"Entreprise"} en={"Business"} uri={"/business/profiles/business"}
                             active={currentPage === "RecruiterProfiles" && currentSubPage === "RecruiterProfilesBusiness"}
                             alt={"business profile"} icon={"https://static.myshortlist.co/btn-industry.svg"} smaller={false}/>
      </>
    }
    {/*<SubNavLinkGenerator fr={"Références"} en={"Feedback"} uri={"/business/profiles/feedback"}*/}
    {/*                     active={currentPage === "RecruiterProfiles" && currentSubPage === "RecruiterProfilesFeedback"}*/}
    {/*                     alt={"feedback"} icon={"https://static.myshortlist.co/btn-star.svg"} smaller={true}/>*/}
  </>);
}