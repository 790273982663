import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import AppSwitch from "../../../../components/sharedComponents/AppSwitch";
import axios from "axios";

export interface IAppContentMultiposting {
  name: string,
  title: string,
  iconUrl: string,
  isAvailable: boolean,
  isActive: boolean,
  isPosted: boolean,
}

export {MultipostingSingleApp};

const MultipostingSingleApp = (props: {
  app: IAppContentMultiposting;
  rank: number | string;
  dispatcher: Function;
}) => {
  
  const updateAppMultiposting = async(name: string, from: 'business' | 'user') => {
    try {
      
      /*const {status} = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/new/multiposting/social`, {
       headers: {
       appname: name,
       rank: props.rank,
       from: from
       },
       withCredentials: true,
       })).data;
       
       status === "ok" &&*/
      props.dispatcher({type: 'POST', value: name});
      
    } catch(error) {
      throw error
    }
  }
  
  return (<div className={"multiposting-section__app"}>
    
    <div className={"multiposting-section__app__left"}>
      <img src={props.app?.iconUrl ?? ""}/>
      <span className={"text--bold"}>{props.app.title}</span>
    </div>
    
    {
      (props.app.isActive && props.app.isAvailable) &&
      <button className={"btn-secondary"} disabled={props.app.isPosted} onClick={() => {
        updateAppMultiposting(props.app.name, props.app.name.includes('personal') ? 'user' : 'business');
      }}>
        {props.app.isPosted ? 'Mission postée' : 'Poster'}
      </button>
    }
    
    {!props.app.isAvailable && <div className={"multiposting-section__app__text-right"}>Bientôt disponible</div>}
    {(props.app.isAvailable && !props.app.isActive) &&
    <div className={"multiposting-section__app__text-right"}>Non souscrit</div>}
  
  </div>);
}


