import AppContext, {IAppContext} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import {Link, withRouter} from "react-router-dom";
import DropDownLinkGenerator from "./shared/DropDownLinkGenerator";
import MenuDropDownLogout from "./MenuDropDownLogout";


function MenuCandidateDropDownContent(props: any) {

  return (<>
      {/*<DropDownLinkGenerator uri={"/candidate/profile"} active={true} fr={"Profil"} en={"Profile"} />*/}
      {/*<DropDownLinkGenerator uri={"/candidate/parameters"} active={true} fr={"Paramètres"} en={"Parameters"} />*/}
      <MenuDropDownLogout />
    </>
  );
}

export default withRouter(MenuCandidateDropDownContent);