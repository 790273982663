import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import WindowWrapper from "../../../../layouts/WindowLayout";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import {devLog} from "../../../../utils/devLog/devLog";
import Spaces from "../../Spaces";

export default function(props: any) {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

    const candidateData = appContextV2.appPanels.window.windowOptions.data?.candidateData;
    const frontUpdates = appContextV2.appPanels.window.windowOptions.data?.frontUpdates;
    const toColumnId = appContextV2.appPanels.window.windowOptions.data?.toColumnId;
    const draggedFromColumnId = appContextV2.appPanels.window.windowOptions.data?.draggedFromColumnId;

    const [ambassador, setAmbassador]:any=useState({});

    useEffect(() => {
        (async() => {
            try {
                const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/get/web/ambassador/and/reward`, {
                    timeout: 20_000,
                    headers: {
                        candidacyid:candidateData.candidacyId,
                        missionid: appContextV2.appNavigation.missionId,
                    },
                    withCredentials: true
                })).data;

                if(status==="ok") setAmbassador(data);

            } catch(error) {
                devLog(error);
            }
        })();
    }, []);

    return (<WindowWrapper windowTitle={""} size={"small"} status={"ok"}>

        <div>Ce candidat est arrivé en suivant le lien de {ambassador.firstName} {ambassador.lastName}.
            <br/> <br/>
            N’oubliez pas de le/la  remercier ! (une récompense de {ambassador.reward} euros à été définie).</div>

        <Spaces n={1}/>
        <div className={"text--center"}>
        <button className={"btn-secondary"} onClick={()=>{
            return appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        }}>Annuler</button>

        <button className={"btn-secondary"} onClick={async ()=>{
            // Update front
            frontUpdates.moveCandidacyFront(candidateData._id, toColumnId);
            // Update back
            const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/mat/cam/move/candidacy`, {
                missionId: appContextV2.appNavigation.missionId,
                toColumnId: toColumnId,
                shortCandidacyId: candidateData._id,
                fromColumnId: draggedFromColumnId,
                candidacyId: candidateData.candidacyId,
                candidateId: candidateData.candidateId,
                bypassActions: true,
                mailData: {},
                smsData: {},
            }, {
                withCredentials: true
            }));
            if (fetched.data.status !== "ok") {
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                return appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
            }

            notification(appContextV2.dispatchPanels, "Candidature déplacée dans la pile", "success");
            return appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        }}>Confirmer</button>
        </div>
    </WindowWrapper>);
}
