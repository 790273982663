import {format} from "date-fns";


export default function getStarting(from: string, asSoonAsPossible: boolean, duration: number, permanent?: boolean) {
  
  let answer: string = "";
  
  if(!!permanent) {
    return "Recrutement régulier";
  }
  
  if(asSoonAsPossible) {
    answer = 'Démarrage dès que possible';
  } else {
    if(!from) {return ""}
    answer = `Démarage le ${format(new Date(from), 'dd-MM')}`;
  }
  
  if(!!duration) {
    answer = answer + " pour " + duration + " mois";
  }
  
  return answer;
  
}