import {format} from "date-fns";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

export {saveXlsx};

const saveXlsx = (args: {candidates: any[], idsSelectedCandidates: string[]}) => () => {
  
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  
  const csvData: any = [];
  
  const arrayOfSelectedCandidates = [];
  
  for(let c of args.candidates) {
    
    if(args.idsSelectedCandidates.includes(c._id)) {
      arrayOfSelectedCandidates.push(c);
    }
  }
  
  for(let candidate of arrayOfSelectedCandidates) {
    
    let positions: string = "";
    let sectors: string = "";
    let mobilities: string = "";
    let skills: string = "";
    let languages: string = "";
    let softwares: string = "";
    
    for(let s of candidate.positionsWanted) {
      positions = positions + s + ", ";
    }
    
    if(!!candidate.sectors) {
      for(let s of candidate.sectors) {
        sectors = sectors + s + ", ";
      }
    }
    
    if(!!candidate.mobility) {
      for(let s of candidate.mobility) {
        mobilities = mobilities + s + ", ";
      }
    }
    
    if(!!candidate.skills) {
      for(let s of candidate.skills) {
        skills = skills + s + ", ";
      }
    }
    
    if(!!candidate.languages) {
      for(let s of candidate.languages) {
        languages = languages + s.value + ", ";
      }
    }
    
    if(!!candidate.softwares) {
      for(let s of candidate.softwares) {
        softwares = softwares + s.value + ", ";
      }
    }
    
    const lineToAdd = {
      "first_name": candidate.firstName || "",
      "last_name": candidate.lastName || "",
      "email": candidate.email || "",
      "phone": candidate.phone || "",
      "position": positions || "",
      "sectors": sectors || "",
      "in charge": candidate.recruiter || "",
      "added_on": candidate.dateOfCreation ? format(new Date(candidate.dateOfCreation), "dd-MM-yyyy") : "",
      "mobilities": mobilities || "",
      "salary_min": candidate.salaryMin || "",
      "salary_max": candidate.salaryMax || "",
      "skills": skills || "",
      "available": candidate.dateOfListeningToMarket ? format(new Date(candidate.dateOfListeningToMarket), "dd-MM-yyyy") : "" || "",
      "last_exchange": candidate.dateOfLastCommunication ? format(new Date(candidate.dateOfLastCommunication), "dd-MM-yyyy") : "" || "",
      "languages": languages || "",
      "softwares": softwares || "",
      "CV": (!!candidate.cvUrls && candidate.cvUrls.length > 0) ? "Oui" : "Non",
      "CR": (!!candidate.docs && candidate.docs?.length !== 0) ? "Oui" : "Non",
      "ranking": candidate.gradeByTeam ? candidate.gradeByTeam : "",
    }
    
    csvData.push(lineToAdd);
  }
  
  const fileName: string = "export-myshortlist";
  
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
  const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
}