import React from "react";
import Spaces from "../../../Spaces";

export {WCOEmail};

const WCOEmail = (props: {
  white: boolean,
  data: any,
  updateData: Function,
  isPageLoading: boolean,
  updateColumnFront: Function,
  saveColumnData: Function,
  switchAction:Function,
}) => {
  
  return(<>
  
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
      <div className={"space-flex--space-between"}>
        <span className={"text--bold text--magnified11x"}>Activer l'action "Envoyer un email" ?</span>
        <div>
          <label className={"switch-V2"}>
            <input type={"checkbox"} className={"checkbox-on-off"} checked={!!props.data.activateMail} onChange={() => {
              if(!!props.data.activateMail) {
                // Turn off
                // updateFronts
                props.updateColumnFront(props.data._id, {activateMail: false});
                props.updateData()({target: {type: "custom", name: "activateMail", value: false}});
                // .. update data
                props.saveColumnData(props.data._id, {...props.data, activateMail: false});
              }
              if(!props.data.activateMail) {
                props.switchAction("activateMail");


              }
            }}/>
            <span className="slider"> </span>
          </label>
        </div>
      </div>
  
      <Spaces n={0}/>
      <div className={"text--center text--grey"}>
        Les candidats déplacés dans cette colonne recevront un email de votre choix.
      </div>
    
      {/*------------------- Content*/}
      {
        !!props.data.activateMail &&
        <div>
          <Spaces n={0}/>
          <input className={"input--v2 input--v2--full-width"}
                 value={props.data.mailSubject}
                 placeholder={"Sujet"}
                 name={"mailSubject"}
                 onChange={props.updateData({maximumLength: 100})} />
          <br/><br/>
          <textarea className={"textarea--v2 textarea--v2--full-width"} value={props.data.mailContent}
                    placeholder={"Contenu du mail (celui ci commencera automatiquement par \"Bonjour \"nom_du_candidat\"\" et se terminera par le prénom de l'opérateur qui a effectué l'action)"}
                    name={"mailContent"}
                    onChange={props.updateData({maximumLength: 1500})}>
            </textarea>
          <div className={"text--center text--grey"}>
            {(props.data.mailContent || "").length} caractères sur 1500
          </div>
          <Spaces n={0}/>
          <div className={"space-flex--space-between"}>
            <div>
              Envoyer l'email avec un délai de 2 jours ?
            </div>
            <div>
              <label className={"switch-V2"}>
                <input type={"checkbox"} className={"checkbox-on-off"} checked={props.data.mailDelay === 2} onChange={() => {
                  if(props.data.mailDelay === 2) {
                    props.updateData()({target: {type: "custom", name: "mailDelay", value: 0}});
                  }
                  if(props.data.mailDelay === 0) {
                    props.updateData()({target: {type: "custom", name: "mailDelay", value: 2}});
                  }
                }}/>
                <span className="slider"> </span>
              </label>
            </div>
          </div>
          <div className={"text--center"}>
            <button disabled={props.data.name.length === 0 || props.isPageLoading} className={"btn-secondary"} onClick={() => {
              // Update front
              props.updateColumnFront(props.data._id, {
                mailDelay: props.data.mailDelay,
                mailSubject: props.data.mailSubject,
                mailContent: props.data.mailContent,
              });
              // Update back
              props.saveColumnData(props.data._id, props.data);
            }}>
              Enregistrer
            </button>
          </div>
        </div>
      }
  
  
    </article>
  
  </>);
}