import {IAppContent} from "../../../../components/App/SingleApp";

const appsList: IAppContent[] = [
  {
    name: 'sms',
    title: 'SMS',
    iconUrl: "https://static.myshortlist.co/apps/sms.svg",
    description: "Envoyez des SMS via la plateforme",
    credit: 0,
    isActive: false,
    isAvailable: true,
    isClickable:false,
    displayCredit: true,
  }, {
    name: "parsing",
    title: 'CV Parsing',
    iconUrl: "https://static.myshortlist.co/apps/cv.svg",
    description: "Faites des recherches avancées dans vos CV",
    credit: 0,
    isActive: false,
    isAvailable: true,
    displayCredit: true,
    isClickable:false,
  }, {
    name: 'robopost',
    title: 'Robopost',
    iconUrl: "https://static.myshortlist.co/apps/robopost.jpg",
    description: "Multiposting sur indeed, linkedin ...",
    credit: 0,
    isActive: false,
    isAvailable: true,
    displayCredit: false,
    isClickable:true,

  }, {
    name: 'msCommunity',
    title: 'Communauté Myshortlist',
    iconUrl: "https://static.myshortlist.co/apps/myshortlist.png",
    description: "Accédez à la communauté de myshortlist",
    credit: 0,
    isActive: false,
    isAvailable: true,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'msHideSubscription',
    title: "Masquer l'inscription",
    iconUrl: "https://static.myshortlist.co/apps/myshortlist.png",
    description: "Masquez le bouton d'inscription candidat sur votre page",
    credit: 0,
    isActive: false,
    isAvailable: true,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'facebook',
    title: 'Facebook',
    iconUrl: "https://static.myshortlist.co/apps/facebook.svg",
    description: "Postez vos offres sur le facebook de l'entreprise",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'instagram',
    title: 'Instagram',
    iconUrl: "https://static.myshortlist.co/apps/instagram.svg",
    description: "Postez vos offres sur l'instagram de l'entreprise",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'linkedin',
    title: 'Linkedin',
    iconUrl: "https://static.myshortlist.co/apps/linkedin.svg",
    description: "Postez vos offres sur le linkedin de l'entreprise",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:true,

  }, {
    name: 'twitter',
    title: 'Twitter',
    iconUrl: "https://static.myshortlist.co/apps/twitter.svg",
    description: "Postez vos offres sur le twitter de l'entreprise",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: "welcome",
    title: 'Welcome to the jungle',
    iconUrl: "https://static.myshortlist.co/apps/welcome.jpg",
    description: "Transférez ou recevez des candidats sur welcome",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'slack',
    title: 'Slack',
    iconUrl: "https://static.myshortlist.co/apps/slack.svg",
    description: "Recevez vos notifications sur slack",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'calendar',
    title: 'Google Calendar',
    iconUrl: "https://static.myshortlist.co/apps/calendar.svg",
    description: "Organisez vos meeting",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'calendly',
    title: 'Calendly',
    iconUrl: "https://static.myshortlist.co/apps/calendly.png",
    description: "Organisez vos meeting",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'teams',
    title: 'Teams',
    iconUrl: "https://static.myshortlist.co/apps/teams.svg",
    description: "Organisez vos meeting",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  }, {
    name: 'zappier',
    title: 'Zappier',
    iconUrl: "https://static.myshortlist.co/apps/zappier.png",
    description: "Transférez vos données ou recevez les où vous souhaitez !",
    credit: 0,
    isActive: false,
    isAvailable: false,
    displayCredit: false,
    isClickable:false,

  },
]

export {appsList};