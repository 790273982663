import React, {useContext, useEffect, useRef, useState} from "react";
import Spaces from "../../../../../Spaces";
import {ButtonBack} from "../../../../../Buttons/ButtonBack";


export default (props: any) => {
    const secondMessage="Hello {{firstName}}\n" +
        "Avez vous bien reçu mon message précèdent?\n" +
        "Votre profil m'intéresse beaucoup ! J'ai une opportunité pour vous!\n" +
        "A quel moment êtes vous disponible pour en discuter ?\n" +
        "Vous pouvez également réserver un créneau dans mon agenda en cliquant sur le lien suivant {{calendlyLink}}\n" +
        "{{recruiterName}}";

    return (<div className={"text--grey container"}>

        <section className={`messages--v2__chat`}>
            <ButtonBack back={() => {props.setStep("choice")}}/>
            <br/>
            <div>
                <Spaces n={0}/>
                <textarea className={"messages--v2__chat__message"} value={secondMessage}>
      </textarea>
                <button  className={"message--v2__chat__button"} onClick={() => {
                    props.sendFirstMsg("second_message",secondMessage);
                }}>
                    Envoyer
                </button>

            </div>
        </section>
    </div>);

}