import React, {useContext} from "react";
import Spaces from "../../../Spaces";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";

export {WCOSms};

const WCOSms = (props: {
  white: boolean,
  data: any,
  updateData: Function,
  isPageLoading: boolean,
  updateColumnFront: Function,
  saveColumnData: Function,
  switchAction:Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  return (<>
    
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
      
      <div className={"space-flex--space-between"}>
        <span className={"text--bold text--magnified11x"}>Activer l'action "Envoyer un SMS" ?</span>
        <div>
          <label className={"switch-V2"}>
            <input type={"checkbox"} className={"checkbox-on-off"} checked={!!props.data.activateSms} onChange={() => {
              if(!!props.data.activateSms) {
                // Turn off
                // updateFronts
                props.updateColumnFront(props.data._id, {activateSms: false});
                props.updateData()({target: {type: "custom", name: "activateSms", value: false}});
                // .. update data
                props.saveColumnData(props.data._id, {...props.data, activateSms: false});
              }
              if(!props.data.activateSms) {
                props.switchAction("activateSms");
              }
            }}/>
            <span className="slider"> </span>
          </label>
        </div>
      </div>
  
      <Spaces n={0}/>
      <div className={"text--center text--grey"}>
        Les candidats déplacés dans cette colonne recevront un SMS de votre choix.
      </div>
      
      {/*------------------- Content*/}
      {
        !!props.data.activateSms &&
        <div>

          <Spaces n={0}/>
          
          {appContextV2.appSession.sessionBusiness.creditForSms < 0.05 && <div className={"text--red text--center"}>
            Attention, votre crédit sms ne vous permet pas d'envoyer de textos.
            <Spaces n={0}/>
          </div>}

          <textarea className={"textarea--v2 textarea--v2--full-width"} value={props.data.smsText}
                    placeholder={"Contenu du SMS (celui ci commencera automatiquement par \"Bonjour \"nom_du_candidat\"\" et se terminera par le prénom de l'opérateur qui a effectué l'action)"}
                    name={"smsText"} onChange={props.updateData({maximumLength: 120})}>
            </textarea>
          <div className={"text--center text--grey"}>
            {(props.data.smsText || "").length} caractères sur 120
          </div>

          <Spaces n={0}/>

          <div className={"text--center"}>
            <button disabled={props.data.name.length === 0 || props.isPageLoading} className={"btn-secondary"} onClick={() => {
              // Update front
              props.updateColumnFront(props.data._id, {
                activateSms: props.data.activateSms,
                smsText: props.data.smsText,
                mailContent: props.data.mailContent,
              });
              // Update back
              props.saveColumnData(props.data._id, props.data);
            }}>
              Enregistrer
            </button>
          </div>
        </div>
      }
      
    </article>
  
  </>);
}