interface IState {
  fingerprint: string,
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  location: {
    street: string,
    city: string,
    postalCode: number,
    countryCode: string,
  },
  description: string,
  monthsOfExperience: number,
  averageMonthsPerEmployer: number,
  monthsOfManagementExperience: number,
  experience: any[],
  education: any[],
  languages: any[],
  skills: any[],
}

export default (previousState: any, action: { type: string, value: any }): any => {
  
  switch(action.type) {
    
    case 'INITIALIZE_DATA':
      return ({
        ...previousState,
        ...action.value
      });
    
    case 'UPDATE_INFOS':
      return ({
        ...previousState,
        ...action.value,
      });
    
    case 'UPDATE_LOCATION':
      return ({
        ...previousState,
        location: {
          ...previousState.location,
          ...action.value,
        }
      });
    
    case 'UPDATE_ARRAY':
      // action.value contient :
      // nom du champ (field) (experience, educations, languages) /
      // index /
      // (subfield) /
      // valeur
      
      const newArray: any[] = [...((previousState ?? {})[(action.value?.field ?? 0)] ?? [])];
      
      (newArray[(action.value.index ?? 0)])[action.value.subfield] = action.value.value;
      
      return {
        ...previousState,
        [action.value.field]: newArray
      }

      case  'UPDATE_SUB_ARRAY':
          const newSubArray: any[] = [...((previousState ?? {})[(action.value?.field ?? 0)] ?? [])];

          (newSubArray[(action.value.index ?? 0)])[action.value.subfield][action.value.subIndex] = action.value.value;

          return {
              ...previousState,
              [action.value.field]: newSubArray
          }
      
    case 'ADD_ELEMENT_TO_ARRAY':
        let arrayToAdd: any[] = [...((previousState ?? {})[(action.value?.field ?? 0)] ?? [])];
        arrayToAdd= [...arrayToAdd, action.value.value]
        return {
            ...previousState,
            [action.value.field]: arrayToAdd
        }
      
    case 'REMOVE_ELEMENT_FROM_ARRAY':
        let arrayToDelete: any[] = [...((previousState ?? {})[(action.value?.field ?? 0)] ?? [])];
        arrayToDelete= [...arrayToDelete.filter((item: any, key:number) => (key !== action.value.index))]
        return {
            ...previousState,
            [action.value.field]: arrayToDelete
        }

    default:
      return previousState;
  }
  
}