import React, {useContext} from "react";
import PageLayout from "../../layouts/PageLayout";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import Spaces from "../../components/sharedComponents/Spaces";

export default function Prices (props: {page: string,}) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  return(
    <>
      <PageLayout page={props.page} status={"ok"} grey={true} fullWidth={true}>
        
        <section className={"landing-page__section--2"}>
  
          <Spaces n={4}/>
  
          <p className={"text--center landing-page__section--2__text"}>
            {language === 'FR' && <>La plateforme est gratuite pour les candidats.</>}
            {language !== 'FR' && <>The platform is free for candidates.</>}
          </p>
  
          <Spaces n={4}/>
    
          <h1 className={"landing-page__section--2__title text--center"}>
            {language === 'FR' && <>Tarifs</>}
            {language !== 'FR' && <>Prices</>}
          </h1>
    
          {/*<h2 className={"landing-page__section--2__subTitle landing-page__section--2__subTitle--offset text--center"}>*/}
          {/*  /!*{language === 'FR' && <>Diffusez, Centralisez, Organisez</>}*!/*/}
          {/*  /!*{language !== 'FR' && <>Diffuse, Centralize, Organize</>}*!/*/}
          {/*  Un système de matching unique*/}
          {/*</h2>*/}
          
          <Spaces n={4}/>
  
          <p className={"text--center landing-page__section--2__text"}>
            {language === 'FR' && <>Tarification à venir.</>}
            {language !== 'FR' && <>Prices are yet to come.</>}
          </p>
          
        </section>
        
      </PageLayout>
    </>
  );
  
}