import React from "react";
import {DisplayDictionary} from "../RecruiterParametersDictionaries";

export {RecruiterParametersDictionarySectors}

const RecruiterParametersDictionarySectors = (props: any) => {
  
  return(<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Dictionnaire des secteurs d'activité</h1>
  
    <DisplayDictionary keyValue={"sector"} data={props.dictionary || []} updateDictionaryEntry={()=>{}} deleteDictionaryEntry={()=>{}} />

  </div>);
}