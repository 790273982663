import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import PageLayoutWithHeader from "../../layouts/PageLayoutWithHeader";
import axios from "axios";
import {Link} from "react-router-dom";

function cleanLinkedinUrl(url: string) {
    // in case an url is like "https://www.linkedin.comhttps://www.linkedin.com/in/ACoAACRXhJUBcNXxj5AsxSqWQx_OXNByuJvv33k", remove the first "https://www.linkedin.com"
    if (url.includes("https://www.linkedin.comhttps://www.linkedin.com")) {
        console.log('==== Cleaned Linkedin Url ====');
        url = url.replace("https://www.linkedin.comhttps://www.linkedin.com", "https://www.linkedin.com");
    }
    return url
}

export default (props: any) => {
    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    const [candidates, setCandidates] = useState<any>(null)
    const [status, setStatus] = useState<'loading' | 'loaded'>('loading')

    useEffect(() => {
        (async() => {
            try {
                const {
                    status,
                    data
                } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/communities/control/parsing`,
                        {}, {withCredentials: true})
                ).data;
                if (status === 'ok') {
                    setCandidates(data)
                }
                setStatus('loaded')
            } catch(error) {
                console.error(error)
            }
        })()
    }, [])

    return <PageLayoutWithHeader grey={false} title={"Contrôle des 7 derniers jours"} small={true} fullWidth={true} page={props.page}
                                 status={"ok"} hideHeader={false}>

        <div style={{textAlign: "center"}}>
            <h2>Liste des candidats ajoutes les 7 derniers jours sur la plateforme.</h2>
        </div>

        <br/><br/>

        {status === 'loading' && <div style={{textAlign: "center"}}>Chargement...</div>}

        {status === 'loaded' && !!candidates && candidates.length === 0 && <div style={{textAlign: "center"}}>RAS</div>}

        {status === 'loaded' && !!candidates && candidates.length !== 0 && <div style={{textAlign: "center"}}>({candidates.length})</div>}

        {!!candidates && <table>

            <tr>
                <td>Name</td>
                <td>LinkedIn</td>
                <td>Resume</td>
                <td>Email</td>
                <td>LinkedIn parsé</td>
                <td>Parsing en cours</td>
                <td>Ami avec</td>
                <td>Parsé par le bon compte</td>
                <td>Resume parsé</td>
            </tr>

            {candidates?.filter((candidate: any) => candidate.sentToLobstr || (candidate.lastScrapped && new Date(candidate.lastScrapped).getTime() > new Date().getTime() - 7 * 24 * 60 * 60 * 1000)).map((candidate: any, k: number) => <tr key={k}>
                <td>{candidate.firstName} {candidate.lastName} <a href={`/business/controlCandidateData/${candidate._id}`} target={"_blank"}><button>
                    Check
                </button></a></td>
                <td>{candidate.linkedin ? <a href={cleanLinkedinUrl(candidate.linkedin)} target="_blank">oui</a> : ''}</td>
                <td>{candidate.cvUrl || candidate.cvUrls.length ? 'oui' : ''}</td>
                <td>{candidate.email?.includes('unknown') ? '' : candidate.email}</td>
                <td>{(candidate.lastScrapped && new Date(candidate.lastScrapped).getTime() > new Date().getTime() - 7 * 24 * 60 * 60 * 1000) ? 'oui 🦞' : ''}</td>
                <td>{candidate.sentToLobstr ? 'oui 🦞' : ''}</td>
                <td>{candidate.importedFromAccount}</td>
                <td>{candidate.importedFromAccount && candidate.parsedByWrongCluster ? '' : 'oui'}</td>
                <td>{candidate.cvFingerPrint ? 'oui': ''}</td>
            </tr>)}

            {candidates?.filter((candidate: any) => !(candidate.sentToLobstr || (candidate.lastScrapped && new Date(candidate.lastScrapped).getTime() > new Date().getTime() - 7 * 24 * 60 * 60 * 1000))).map((candidate: any, k: number) => <tr key={k}>
                <td>{candidate.firstName} {candidate.lastName} <a href={`/business/controlCandidateData/${candidate._id}`} target={"_blank"}><button>
                    Check
                </button></a></td>
                <td>{candidate.linkedin ? <a href={cleanLinkedinUrl(candidate.linkedin)} target="_blank">oui</a> : ''}</td>
                <td>{candidate.cvUrl || candidate.cvUrls.length ? 'oui' : ''}</td>
                <td>{candidate.email?.includes('unknown') ? '' : candidate.email}</td>
                <td>{(candidate.lastScrapped && new Date(candidate.lastScrapped).getTime() > new Date().getTime() - 7 * 24 * 60 * 60 * 1000) ? 'oui 🦞' : ''}</td>
                <td>{candidate.sentToLobstr ? 'oui 🦞' : ''}</td>
                <td>{candidate.importedFromAccount}</td>
                <td>{candidate.importedFromAccount && candidate.parsedByWrongCluster ? '' : 'oui'}</td>
                <td>{candidate.cvFingerPrint ? 'oui': ''}</td>
            </tr>)}

        </table>}

    </PageLayoutWithHeader>
}