import {notification} from "../../../../../utils/notification";
import axios from "axios";

export {fetchAddPool};

const fetchAddPool = (pools: any, setPools: Function, setAddingPool: Function, dispatchPanels: Function) => async() => {
  try {
    const poolsDuplicate = [...pools];
    setAddingPool(true);
    notification(dispatchPanels, "Demande de l'ajout d'une communauté", "information");
    // Update front
    setPools([...poolsDuplicate, {
      _id: "toFetch",
      businessId: "toFetch",
      teamId: "toFetch",
      creatorId: "toFetch",
      dateOfCreation: new Date(),
      dateOfLastAction: new Date(),
      status: "published",
      name: "Nouvelle Communauté",
      IAmOwner: true,
      number: 0,
      pinnedBy: [],
    }]);
    // Update back
    const fetched = await axios.post(`${process.env.REACT_APP_API_URL}/v3/communities/pool/m/add/pool`, {}, {withCredentials: true});
    if(fetched.data.status !== "ok") {
      setPools(poolsDuplicate);
      return notification(dispatchPanels, "Une erreur est survenue", "error");
    }
    // Re-Update front
    setPools([...poolsDuplicate, {
      _id: fetched.data.data.poolId,
      businessId: "toFetch",
      teamId: "toFetch",
      creatorId: "toFetch",
      dateOfCreation: new Date(),
      dateOfLastAction: new Date(),
      status: "published",
      name: "Nouvelle Communauté",
      IAmOwner: true,
      number: 0,
      pinnedBy: [],
    }]);
    notification(dispatchPanels, "Succès de l'ajout d'une communauté", "success");
    setAddingPool(false);
  } catch (error) {
    notification(dispatchPanels, "Une erreur est survenue", "error");
  }
}