import React, {useContext, useState} from "react";
import {notification} from "../../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../../../components/App/context/AppContext";
import ButtonShare from "../../../../../../components/sharedComponents/ButtonShare";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const [ambassadorId, setAmbassadorId] = useState("");
  
  const displayAmbassadorSubscription: boolean = props.business?.acceptAmbassadorSubscription && props.mission?.rewardActivated && props.mission?.reward !== 0 && !props.pageInfos?.webAmbassador;
  
  const url: string = `${window.location.origin}/apply/${props.business?.businessSlugName}/${props.business?.businessSlugNumber}/${props.mission?.rank}/apply${ambassadorId ? `?a=${ambassadorId}` : ""}${props.pageInfos?.webAmbassador ? `?a=${props.pageInfos?.webAmbassador}` : ""}`;
  
  const didUserSubscribedAsAmbassador = !!ambassadorId;
  
  return (<>
    {displayAmbassadorSubscription && <>
      <div className={`ambassador-subscription-from-mission ${didUserSubscribedAsAmbassador ? "ambassador-subscription-from-mission--green" : ""}`}>
        <div className={"ambassador-subscription-from-mission__left"}>
          {
            !didUserSubscribedAsAmbassador && <>
              <div className={"ambassador-subscription-from-mission__text"}>
                Le poste n'est pas pour vous ? Devenez ambassadeur et partagez le lien à votre communauté !
              </div>
              <div className={"text--grey ambassador-subscription-from-mission__text"}>
                {props.mission?.reward && <>L'entreprise offre {props.mission?.reward} euros
                  pour toute personne recrutée.</>}
                {!props.mission?.reward && <>La plateforme identifiera automatiquement toute personne qui aura postulé
                  avec votre lien.</>}
              </div>
            </>
          }
          {
            didUserSubscribedAsAmbassador && <>
              Bravo, vous êtes maintenant ambassadeur ! Partagez votre lien à votre réseau et la plateforme identifiera automatiquement les candidats qui postulent en votre nom.
            </>
          }
        </div>
        <div className={"ambassador-subscription-from-mission__right"}>
          {!ambassadorId && <button className={"btn-assistant-main"} onClick={() => {
            appContextV2.dispatchPanels({
              type: "OPEN_WINDOW",
              value: {
                type: "ambassador",
                data: {
                  dispatchAmbassadors: () => {
                  },
                  setAmbassadorId: setAmbassadorId,
                  type: "subscription",
                  missionId: props.mission?._id,
                  businessSlugName: props.business?.businessSlugName,
                  businessSlugNumber: props.business?.businessSlugNumber,
                  missionRank: props.mission.rank,
                }
              }
            });
          }}> Devenir ambassadeur </button>}
        </div>
        <div className={"ambassador-subscription-from-mission__right"}>
          {didUserSubscribedAsAmbassador && <ButtonShare onClick={() => {
            appContextV2.dispatchPanels({
              type: "OPEN_WINDOW",
              value: {
                type: "ambassadorLinkCopied",
                data: null
              }
            });
            notification(appContextV2.dispatchPanels, "Lien copié", "success");
            navigator.clipboard.writeText(url);
          }}/>}
        </div>
      </div>
    </>}
    
    {!displayAmbassadorSubscription && <div className={"space-flex--center"}>
      {/*<div className={"ambassador-subscription-from-mission"}>*/}
      {/*  <div className={"ambassador-subscription-from-mission__left"}>*/}
      <div className={"ambassador-subscription-from-mission__text"}>
        Le poste n'est pas pour vous ? Partagez le lien à votre communauté !
      </div>
      {/*</div>*/}
      {/*<div className={"ambassador-subscription-from-mission__right"}>*/}
      <ButtonShare onClick={() => {
        appContextV2.dispatchPanels({
          type: "OPEN_WINDOW",
          value: {
            type: "ambassadorLinkCopied",
            data: null
          }
        });
        notification(appContextV2.dispatchPanels, "Lien copié", "success");
        navigator.clipboard.writeText(url);
      }}/>
      {/*</div>*/}
      {/*</div>*/}
    </div>}
  
  </>);
}