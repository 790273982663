import React, {useContext, useEffect, useReducer, useState} from "react";
import Spaces from "../../../../Spaces";
import LoadingStyle2 from "../../../../Loading/LoadingStyle2";
import AppContext, {IAppV2Context} from "../../../../../App/context/AppContext";
import axios from "axios";
import {notification} from "../../../../../../utils/notification";
import dataReducerCandidate
  from "../../../../FirstLoginAssistants/componentsFirstLoginAssistant/componentsFirstLoginCandidate/dataReducerCandidate";
import dataReducerCandidacy from "./dataReducerCandidacy";
import BlackBeltTchat from "../dependencies/BlackBeltTchat";
import {useForm} from "use-formidable";
import {FileDrop} from "../../../../Buttons/FileDrop";
import {format} from "date-fns";
import ButtonDeleteNew from "../../../../Buttons/ButtonDeleteNew";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isUplodaed, setIsUploaded] = useState(false);
  const [candidacy, dispatchCandidacy]: [any, Function] = useReducer(dataReducerCandidacy, {
    blackBeltCulture: 0,
    blackBeltHardSkills: 0,
    blackBeltSoftSkills: 0,
    blackBeltImportant: [],
    blackBeltAvailability: "",
    blackBeltInformations: [],
    blackBeltVideo: "",
    blackBeltLoomLink:"",
    blackBeltNextStep: "",
    blackBeltTchat: [],
    interview2: [],
    blackBeltFeedbackInterview:"",
    missionDateOfCreation:"",
    candidacyDateOfCreation:"",
    sentToClient:"",
    clientAnswer:"",
    blackBeltInterview:"",
    clientInterview:"",
    jobOffer:"",
    onboarding:"",
    entryDate:"",
    endOfInitialTrialPeriod:"",
    endOfTrialPeriod:"",
    internalCommentary:"",
  });
  const [blackBeltImportant, setBlackBeltImportant] = useState("");
  const [blackBeltInformations, setBlackBeltInformations] = useState("");
  const [interview2, setInterview2] = useState("");
  
  console.log("candidacy", candidacy);
  
  /** Vidéo file */
  const [video, updateVideo, formidableVideo] = useForm({
    file: null,
    accepted: false,
    sending: false,
  });
  
  /**
   * Upload de la video
   */
  useEffect(() => {
    try {
      if(video.file) {
        if(!formidableVideo.isFileSmallerThan(video.file[0], 62_000_000)) {
          notification(appContextV2.dispatchPanels, "La taille du fichier est trop importante (maximum: 25 mo).", "warning");
          return;
        }
        (async() => {
          try {
            setIsSaving(true);
            updateVideo()({target: {type: "custom", name: "accepted", value: true}});
            // Send video
            const dataToSend: any = new FormData();
            dataToSend.append("video", video.file[0], video.file[0].name);
            const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/upload/data/bb/video`, dataToSend, {
              withCredentials: true,
              timeout: 1200000,
              'maxContentLength': Infinity,
              'maxBodyLength': Infinity,
              headers: {
                'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
              }
            });
            if(fetched.data.status !== "ok") {
              setIsSaving(false);
              setIsUploaded(false);
              updateVideo()({target: {type: "custom", name: "accepted", value: false}});
              return alert("Problème vidéo");
            }
            console.log("!!!!!!!!!!!!!!!!!!!!!!", fetched.data.data);
            // alert("Vidéo ajoutée, n'oubliez pas de sauvegarder !'");
            setIsUploaded(true);
            dispatchCandidacy({
              type: "UPDATE_CANDIDACY",
              value: {blackBeltVideo: fetched.data.data}
            })
            setIsSaving(false);
          } catch(error) {
            alert("Une erreur est survenue");
          }
        })();
      }
    } catch(error) {
      console.log(error);
      alert("Une erreur est survenue, la vidéo n'est pas ajoutée, " + error.message);
      updateVideo()({target: {type: "custom", name: "accepted", value: false}});
    }
  }, [video.file]);
  
  /**
   * Get infos
   */
  useEffect(() => {
    (async() => {
      try {
        setIsLoading(true);
        
        const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/candidacy/infos`, {
          timeout: 5000,
          withCredentials: true,
          headers: {
            candidacyid: props.candidate?.candidacy?._id,
          }
        })).data;
        
        if(status !== "ok") {
          setIsLoading(false);
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        
        dispatchCandidacy({type: "INITIALIZE_DATA", value: data});
        setIsLoading(false);
      } catch(error) {
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    })()
  }, []);
  
  /**
   * Save datas
   */
  async function saveBb() {
    try {
      setIsSaving(true);
      const {
        status,
        data
      } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/update/candidacy/infos`,
        {
          candidacyId: props.candidate?.candidacy?._id,
          candidacy: candidacy,
        }
        , {
          timeout: 5000,
          withCredentials: true,
        })).data;
  
      if(status !== "ok") {
        setIsSaving(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      setIsSaving(false);
      return notification(appContextV2.dispatchPanels, "Informations sauvegardés", "success");
  
    } catch(error) {
      console.log(error);
    }
  }
  
  return (<>
    
    <div className={"black-belt-window__container"}>
      
      Réponse du recruteur:
      {candidacy.blackBeltKeep === "yes" && <span className={"black-belt-window__keep"}>Je veux rencontrer le candidat</span>}
      {candidacy.blackBeltKeep === "no"  && <span className={"black-belt-window__not-keep"}>Je ne veux pas rencontrer le candidat</span>}
      {!candidacy.blackBeltKeep && <span>--- </span>}
      {candidacy.blackBeltClient && <>{candidacy.blackBeltClient}</>}
      
      <Spaces n={1}/>
      
      <h1 className={"results-v2__title"}>
        Infos a prendre:
      </h1>
      
      <Spaces n={0}/>
      
      {
        isLoading && <LoadingStyle2/>
      }
      
      {/*-----------------------------------------------------------------------------------------------*/}
      
      <div className={"black-belt-window__container--mini"}>
        Matching:
        <br/><br/>
        
        <div><span className={"black-belt-window__title"}>Culture fit:</span>
          <input className={"black-belt-window__input"} value={candidacy.blackBeltCulture}
                 onChange={(e) => {
                   dispatchCandidacy({
                     type: "UPDATE_CANDIDACY",
                     value: {blackBeltCulture: e.target.value}
                   })
                 }}/>
          Sur 5
        </div>
        
        <div><span className={"black-belt-window__title"}>Competences: </span>
          <input className={"black-belt-window__input"} value={candidacy.blackBeltHardSkills}
                 onChange={(e) => {
                   dispatchCandidacy({
                     type: "UPDATE_CANDIDACY",
                     value: {blackBeltHardSkills: e.target.value}
                   })
                 }}/>
          En %
        </div>
        
        <div><span className={"black-belt-window__title"}>Soft skills: </span>
          <input className={"black-belt-window__input"} value={candidacy.blackBeltSoftSkills}
                 onChange={(e) => {
                   dispatchCandidacy({
                     type: "UPDATE_CANDIDACY",
                     value: {blackBeltSoftSkills: e.target.value}
                   })
                 }}/>
          Sur 5
        </div>
      </div>
  
      <div className={"text--center"}>
        <button className={"black-belt-window__btn"} disabled={isSaving} onClick={() => saveBb()}> Sauvegarder
        </button>
      </div>
      
      {/*-----------------------------------------------------------------------------------------------*/}
      <div className={"black-belt-window__container--mini"}>
        <div> <span className={"black-belt-window__title"}>Feedback entretien blackBelt: </span>
          <br/>
          <textarea className={"black-belt-window__textarea"} value={candidacy.blackBeltFeedbackInterview}
                 onChange={(e) => {
                   dispatchCandidacy({
                   type: "UPDATE_CANDIDACY",
                   value: {blackBeltFeedbackInterview: e.target.value}
                 });
                 }}
          />
        </div>

      </div>
      <div className={"text--center"}>
        <button className={"black-belt-window__btn"} disabled={isSaving} onClick={() => saveBb()}> Sauvegarder
        </button>
      </div>
  
      {/*-----------------------------------------------------------------------------------------------*/}
      <div className={"black-belt-window__container--mini"}>
        <div> <span className={"black-belt-window__title"}>Feedback entretien blackBelt V2: </span>
          <br/>
          {candidacy.interview2?.map((item: any) => (<div>
            {item}
            <button className={"black-belt-window__btn"}
                    onClick={() => (dispatchCandidacy({type: "DELETE_INTERVIEW2", value: item}))}>Supprimer
            </button>
          </div>))}
          <input className={"black-belt-window__input"} value={interview2}
                 onChange={(e) => {setInterview2(e.target.value);}}
          />
          <button className={"black-belt-window__btn"}
                  onClick={() => {
                    dispatchCandidacy({type: "ADD_INTERVIEW2", value: interview2});
                    setInterview2("");
                  }}>Ajouter
          </button>
        </div>
  
      </div>
      <div className={"text--center"}>
        <button className={"black-belt-window__btn"} disabled={isSaving} onClick={() => saveBb()}> Sauvegarder
        </button>
      </div>
      
      {/*-----------------------------------------------------------------------------------------------*/}
      
      <div className={"black-belt-window__container--mini"}>
        
        <div><span className={"black-belt-window__title"}> Points forts du profil: </span>
          <br/>
          {candidacy.blackBeltImportant?.map((item: any) => (<div>
            {item}
            <button className={"black-belt-window__btn"}
                    onClick={() => (dispatchCandidacy({type: "DELETE_IMPORTANT", value: item}))}>Supprimer
            </button>
          </div>))}
          <input className={"black-belt-window__input"} value={blackBeltImportant}
                 onChange={(e) => {setBlackBeltImportant(e.target.value);}}
          />
          <button className={"black-belt-window__btn"}
                  onClick={() => {
                    dispatchCandidacy({type: "ADD_IMPORTANT", value: blackBeltImportant});
                    setBlackBeltImportant("");
                  }}>Ajouter
          </button>
        </div>
      </div>
  
      <div className={"text--center"}>
        <button className={"black-belt-window__btn"} disabled={isSaving} onClick={() => saveBb()}> Sauvegarder
        </button>
      </div>
      
      {/*-----------------------------------------------------------------------------------------------*/}
      
      <div className={"black-belt-window__container--mini"}>
        
        <span className={"black-belt-window__title"}> Disponibilite: </span>
        <input className={"black-belt-window__input"} value={candidacy.blackBeltAvailability}
               onChange={(e) => {
                 dispatchCandidacy({
                   type: "UPDATE_CANDIDACY",
                   value: {blackBeltAvailability: e.target.value}
                 })
               }}/>
        <br/>
        
        <div><span className={"black-belt-window__title"}>Informations:</span>
          <br/>
          {candidacy.blackBeltInformations?.map((item: any) => (<div>
            {item}
            <button className={"black-belt-window__btn"}
                    onClick={() => {dispatchCandidacy({type: "DELETE_INFORMATIONS", value: item});}}>Supprimer
            </button>
          </div>))}
          <input className={"black-belt-window__input"} value={blackBeltInformations}
                 onChange={(e) => {setBlackBeltInformations(e.target.value);}}
          />
          <button className={"black-belt-window__btn"}
                  onClick={() => {
                    dispatchCandidacy({type: "ADD_INFORMATIONS", value: blackBeltInformations});
                    setBlackBeltInformations("");
                  }}>Ajouter
          </button>
        </div>
      
      </div>
  
      <div className={"text--center"}>
        <button className={"black-belt-window__btn"} disabled={isSaving} onClick={() => saveBb()}> Sauvegarder
        </button>
      </div>
      
      {/*-----------------------------------------------------------------------------------------------*/}
      
      <div className={"black-belt-window__container--mini"}>
  
      <div><span className={"black-belt-window__title"}>Video:</span>
        
        {candidacy.blackBeltVideo && <div>
          <br/>
          <br/>
          <a href={candidacy.blackBeltVideo}><button>Télécharger la vidéo</button></a>
          <button onClick={() => {
            dispatchCandidacy({
              type: "UPDATE_CANDIDACY",
              value: {blackBeltVideo: ""}
            })
          }}>
            Supprimer la video
          </button>
          <br/>
        </div>}
        
        {!video.accepted && <div className={"text--center apply-V2__title"}>Insérer la vidéo</div>}
        {video.accepted && isSaving && <div className={"text--center apply-V2__title"}>Vidéo en cours d'envoi</div>}
        {video.accepted && isUplodaed && <div className={"text--center apply-V2__title"}>Vidéo envoyée ! N'oublie pas de sauvegarder</div>}
        
        {isSaving && <LoadingStyle2 />}
  
        {
          (!video.accepted) && <>
            <br/>
            <div className={"space-flex--center"}>
              <FileDrop square={false} name={"file"} cb={updateVideo}/>
            </div>
          </>
        }
      </div>

        <Spaces n={0}/>
        <div><span className={"black-belt-window__title"}>Lien loom: </span>
          <input className={"black-belt-window__input"} value={candidacy.blackBeltLoomLink}
                 onChange={(e) => {
                   dispatchCandidacy({
                     type: "UPDATE_CANDIDACY",
                     value: {blackBeltLoomLink: e.target.value}
                   })
                 }}/>

        </div>

      </div>
  
      <div className={"black-belt-window__container--mini"}>
  
      <div><span className={"black-belt-window__title"}>Next step pour le candidat: </span>
        <input className={"black-belt-window__input"} value={candidacy.blackBeltNextStep}
               onChange={(e) => {
                 dispatchCandidacy({
                   type: "UPDATE_CANDIDACY",
                   value: {blackBeltNextStep: e.target.value}
                 })
               }}/>
      
      </div>
      
      </div>
  
      <div className={"text--center"}>
        <button className={"black-belt-window__btn"} disabled={isSaving} onClick={() => saveBb()}> Sauvegarder
        </button>
      </div>

      <div className={"black-belt-window__container--mini"}>
      <table>
        <tr>
          <th>Evenement</th>
          <th>Date</th>
        </tr>
        <tr>
          <td>
            Creation de poste
          </td>
          <td>
            {candidacy.missionDateOfCreation ? format(new Date(candidacy.missionDateOfCreation), "dd-MM-yyyy") : ""}
          </td>
        </tr>
        <tr>
          <td>
            Candidature reçue
          </td>
          <td>
            {candidacy.candidacyDateOfCreation ? format(new Date(candidacy.candidacyDateOfCreation), "dd-MM-yyyy") : ""}
          </td>
        </tr>
        <tr>
          <td>
            Candidature envoyée au client
          </td>
          <td>
            <div className={"black-belt-window__row"}>
            {candidacy.sentToClient ? format(new Date(candidacy.sentToClient), "dd-MM-yyyy") : ""}
              {candidacy.sentToClient && <ButtonDeleteNew validation={true} onClick={()=>{
              dispatchCandidacy({
                type: "UPDATE_CANDIDACY",
                value: {sentToClient: ""}
              });}}/>}
            </div>
            <input defaultValue={candidacy.sentToClient} type={"Date"} className={""}
                   onChange={(e)=>{dispatchCandidacy({
                     type: "UPDATE_CANDIDACY",
                     value: {sentToClient: e.target.value}
                   })}}/>
          </td>
        </tr>
        <tr>
          <td>
            Candidature validée par le client (ou rejetée)
          </td>
          <td>
            <div className={"black-belt-window__row"}>
            {candidacy.clientAnswer ? format(new Date(candidacy.clientAnswer), "dd-MM-yyyy") : ""}
              {candidacy.clientAnswer &&<ButtonDeleteNew validation={true} onClick={()=>{
              dispatchCandidacy({
                type: "UPDATE_CANDIDACY",
                value: {clientAnswer: ""}
              });}}/>}
            </div>
            <input value={candidacy.clientAnswer} type={"Date"} className={""}
                   onChange={(e)=>{dispatchCandidacy({
                     type: "UPDATE_CANDIDACY",
                     value: {clientAnswer: e.target.value}
                   })}}/>

          </td>
        </tr>
        <tr>
          <td>
            Entretien BB
          </td>
          <td>
            <div className={"black-belt-window__row"}>
            {candidacy.blackBeltInterview ? format(new Date(candidacy.blackBeltInterview), "dd-MM-yyyy") : ""}
              {candidacy.blackBeltInterview && <ButtonDeleteNew validation={true} onClick={()=>{
                dispatchCandidacy({
                  type: "UPDATE_CANDIDACY",
                  value: {blackBeltInterview: ""}
                });}}/>}
            </div>
            <input value={candidacy.blackBeltInterview} type={"Date"} className={""}
                   onChange={(e)=>{dispatchCandidacy({
                     type: "UPDATE_CANDIDACY",
                     value: {blackBeltInterview: e.target.value}
                   })}}/>
          </td>
        </tr>
        <tr>
          <td>
            Entretien client
          </td>
          <td>
            <div className={"black-belt-window__row"}>
            {candidacy.clientInterview ? format(new Date(candidacy.clientInterview), "dd-MM-yyyy") : ""}
              {candidacy.clientInterview &&   <ButtonDeleteNew validation={true} onClick={()=>{
                dispatchCandidacy({
                  type: "UPDATE_CANDIDACY",
                  value: {clientInterview: ""}
                });}}/>}
            </div>
            <input value={candidacy.clientInterview} type={"Date"} className={""}
                   onChange={(e)=>{dispatchCandidacy({
                     type: "UPDATE_CANDIDACY",
                     value: {clientInterview: e.target.value}
                   })}}/>
          </td>
        </tr>
        <tr>
          <td>
            Promesse d'embauche
          </td>
          <td>
            <div className={"black-belt-window__row"}>
            {candidacy.jobOffer ? format(new Date(candidacy.jobOffer), "dd-MM-yyyy") : ""}
              {candidacy.jobOffer && <ButtonDeleteNew validation={true} onClick={()=>{
                dispatchCandidacy({
                  type: "UPDATE_CANDIDACY",
                  value: {jobOffer: ""}
                });}}/>}
            </div>
            <input value={candidacy.jobOffer} type={"Date"} className={""}
                   onChange={(e)=>{dispatchCandidacy({
                     type: "UPDATE_CANDIDACY",
                     value: {jobOffer: e.target.value}
                   })}}/>
          </td>
        </tr>
        <tr>
          <td>
            Onboarding
          </td>
          <td>
            <div className={"black-belt-window__row"}>
            {candidacy.onboarding ? format(new Date(candidacy.onboarding), "dd-MM-yyyy") : ""}
              {candidacy.onboarding &&  <ButtonDeleteNew validation={true} onClick={()=>{
              dispatchCandidacy({
                type: "UPDATE_CANDIDACY",
                value: {onboarding: ""}
              });}}/>}
            </div>
            <input value={candidacy.onboarding} type={"Date"} className={""}
                   onChange={(e)=>{dispatchCandidacy({
                     type: "UPDATE_CANDIDACY",
                     value: {onboarding: e.target.value}
                   })}}/>
          </td>
        </tr>
      </table>
      </div>
      
      <div className={"text--center"}>
        <button className={"black-belt-window__btn"} disabled={isSaving} onClick={() => saveBb()}> Sauvegarder
        </button>
      </div>

      <div className={"black-belt-window__container--mini"}>
        <span className={"black-belt-window__title"}>Onboarding: </span>
        <table>
          <tr>
            <td>Date d'entrée </td>
            <td> <div className={"black-belt-window__row"}>
              {candidacy.entryDate ? format(new Date(candidacy.entryDate), "dd-MM-yyyy") : ""}
              {candidacy.entryDate && <ButtonDeleteNew validation={true} onClick={()=>{
                dispatchCandidacy({
                  type: "UPDATE_CANDIDACY",
                  value: {entryDate: ""}
                });}}/>}
            </div>
              <input value={candidacy.entryDate} type={"Date"} className={""}
                     onChange={(e)=>{dispatchCandidacy({
                       type: "UPDATE_CANDIDACY",
                       value: {entryDate: e.target.value}
                     })}}/>
            </td>
          </tr>
          <tr>
            <td>Date fin de période d'essai initiale </td>
            <td> <div className={"black-belt-window__row"}>
              {candidacy.endOfInitialTrialPeriod ? format(new Date(candidacy.endOfInitialTrialPeriod), "dd-MM-yyyy") : ""}
              {candidacy.endOfInitialTrialPeriod && <ButtonDeleteNew validation={true} onClick={()=>{
                dispatchCandidacy({
                  type: "UPDATE_CANDIDACY",
                  value: {endOfInitialTrialPeriod: ""}
                });}}/>}
            </div>
              <input value={candidacy.endOfInitialTrialPeriod} type={"Date"} className={""}
                     onChange={(e)=>{dispatchCandidacy({
                       type: "UPDATE_CANDIDACY",
                       value: {endOfInitialTrialPeriod: e.target.value}
                     })}}/>
            </td>
          </tr>
          <tr>
            <td>Date de fin de période d'essai </td>
            <td> <div className={"black-belt-window__row"}>
              {candidacy.endOfTrialPeriod ? format(new Date(candidacy.endOfTrialPeriod), "dd-MM-yyyy") : ""}
              {candidacy.endOfTrialPeriod && <ButtonDeleteNew validation={true} onClick={()=>{
                dispatchCandidacy({
                  type: "UPDATE_CANDIDACY",
                  value: {endOfTrialPeriod: ""}
                });}}/>}
            </div>
              <input value={candidacy.endOfTrialPeriod} type={"Date"} className={""}
                     onChange={(e)=>{dispatchCandidacy({
                       type: "UPDATE_CANDIDACY",
                       value: {endOfTrialPeriod: e.target.value}
                     })}}/>
            </td>          </tr>
          <tr>
            <td>Commentaire interne </td>
            <td> <div className={"black-belt-window__row"}>
              {candidacy.internalCommentary ?? candidacy.internalCommentary}
              {candidacy.internalCommentary && <ButtonDeleteNew validation={true} onClick={()=>{
                dispatchCandidacy({
                  type: "UPDATE_CANDIDACY",
                  value: {internalCommentary: ""}
                });}}/>}
            </div>
              <input value={candidacy.internalCommentary} type={"text"} className={""}
                     onChange={(e)=>{dispatchCandidacy({
                       type: "UPDATE_CANDIDACY",
                       value: {internalCommentary: e.target.value}
                     })}}/>
            </td>
          </tr>

        </table>
      </div>

      <div className={"text--center"}>
        <button className={"black-belt-window__btn"} disabled={isSaving} onClick={() => saveBb()}> Sauvegarder
        </button>
      </div>
      
      {/*----------------------------------------------------*/}
  
      <div className={"black-belt-window__container--mini"}>
      
      <div><span className={"black-belt-window__title"}>Tchat: </span>
        <BlackBeltTchat blackBeltTchat={candidacy.blackBeltTchat}
                        candidacyId={props.candidate?.candidacy?._id}
                        dispatchCandidacy={dispatchCandidacy}/>
      </div>
      
      </div>
      
      {/*--------------------------------------------------------------------------*/}
      
      <div className={"black-belt-window__container--mini"}>
        <div><span className={"black-belt-window__title"}>Flash card générique (prospection):</span></div>
        <Spaces n={1}/>
        <div>
          Lien: {`https://black-belt.myshortlist.co/flashcard/${props.candidate?.candidacy?._id}`}
        </div>
      </div>
      
      <Spaces n={1}/>
      
      <Spaces n={2}/>
    </div>
  
  </>);
}
