
/**
 * Module to make cross-origin requests to the server
 *
 */

import axios from "axios";


/**
 * Function that adds the correct prefix from environment vars to route
 */
function getFullUrl(uri: string, display: boolean): string {
  try {
    display && console.log(`${process.env.REACT_APP_API_URL}${uri}`);
    return `${process.env.REACT_APP_API_URL}${uri}`
  } catch (error) {
    throw error;
  }
}

/**
 * Display fetched informations when not in production
 */
function displayInformationsReceived(uri: string, fetched: object) {

  try {
    if(process.env.REACT_APP_API_URL!=="https://www.api.myshortlist.co") {
      console.log(`Fetched at ${uri}:`,fetched);
    }
  } catch (error) {
    console.log("Couldn't log the fetched object");
  }
}

/**
 * Display sent informations when not in production
 */
function displayInformationsSent(uri: string, sent: object) {
  try {
    if(process.env.REACT_APP_API_URL!=="https://www.api.myshortlist.co") {
      console.log(`Sent at ${uri}:`,sent);
    }
  } catch (error) {
    console.log("Couldn't log the sent object");
  }
}

/**
 * Send a GET request to a specific endpoint
 * @param uri
 * @param headers
 * @param display
 */
export async function abstractGetFetch(uri: string, headers: any = {}, display: boolean = false): Promise<object> {
  try {
    display && displayInformationsSent(uri, headers);
    headers['Content-Type'] = 'application/json';
    const fetched: any = await fetch(getFullUrl(uri, display), {
      method: 'GET',
      credentials: 'include',
      headers: new Headers(headers),
    });
    if(fetched.status < 200 || fetched.status >= 300) {
      throw new Error("Error fetching data");
    }
    const fetchedJson: object = await fetched.json();
    display && displayInformationsReceived(uri, fetchedJson);
    return fetchedJson;
  } catch(error) {
    throw error;
  }
}

/**
 * Send a POST request to a specific endpoint
 * @param uri
 * @param body
 * @param display
 */
export async function abstractPostFetch(uri: string, body: any = {}, display: boolean = false): Promise<object> {
  try {
    display && displayInformationsSent(uri, body);
    const fetched: any = await fetch(getFullUrl(uri, display), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    });
    if (fetched.status < 200 || fetched.status >= 300) {
      throw new Error("Error fetching data");
    }
    const fetchedJson: object = await fetched.json();
    display && displayInformationsReceived(uri, fetchedJson);
    return fetchedJson;
  } catch(error) {
    throw error;
  }
}

/**
 * Send a PUT request to a specific endpoint
 * @param uri
 * @param body
 * @param display
 */
export async function abstractPutFetch(uri: string, body: any = {}, display: boolean = false): Promise<object> {
  try {
    display && displayInformationsSent(uri, body);

    const fetched: any = await fetch(getFullUrl(uri, display), {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
    });
    if (fetched.status < 200 || fetched.status >= 300) {
      throw new Error("Error fetching data");
    }
    const fetchedJson: object = await fetched.json();
    display && displayInformationsReceived(uri, fetchedJson);
    return fetchedJson;
  } catch(error) {
    throw error;
  }

}

/**
 * Send a specific file to a specific url via a POST request
 * @param uri
 * @param category
 * @param file
 * @param display
 */
export async function abstractPostFileFetch(uri: string, category: string, file: any, display: boolean = false): Promise<any> {
  try {

    let dataToSend: any = new FormData();
    dataToSend.append(category, file, file.name);

    const fetched: any = await axios.post(getFullUrl(uri, display), dataToSend, {
      withCredentials: true, headers: {
        'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
      }
    });

    if(fetched.status < 200 || fetched.status >= 300) {
      throw new Error("Error fetching data");
    }

    if(fetched.data.error) {
      throw new Error("Erreur dans l'envoi fichier");
    }

    return fetched;

  } catch (error) {
    throw error;
  }
}