import React, {useContext, useEffect, useRef, useState} from "react";
import Spaces from "../../../../../Spaces";
import {ButtonBack} from "../../../../../Buttons/ButtonBack";
import LoadingStyle2 from "../../../../../Loading/LoadingStyle2";

export default (props: any) => {


    return (<section className={`messages--v2__chat`}>
        <div>
            <Spaces n={0}/>
            <textarea className={"messages--v2__chat__message"} value={props.message} onChange={(e) => {
                if (e.target.value?.length < 120) {
                    props.setMessage(e.target.value);
                }
            }}>
      </textarea>
            <button disabled={!props.message} className={"message--v2__chat__button"} onClick={() => {
                props.sendMsg();
            }}>Envoyer
            </button>
        </div>
        <div className={"text--grey"}>{props.message.length} / 120</div>
    </section>);
}