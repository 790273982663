import WindowWrapper from "../../../../layouts/WindowLayout";
import React, {useContext} from "react";
import {useForm} from "use-formidable";
import Spaces from "../../Spaces";
import {useWizard} from "use-wizard";
import {devLog} from "../../../../utils/devLog/devLog";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../App/context/AppContext";

export {WindowAddTeam};

const WindowAddTeam = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [form, updateForm, formidable] = useForm({name: "", description: ""});
  
  const [step, wizard] = useWizard(["enterData", "pending", "error"]);
  
  const addTeam = () => {
    (async() => {
      try {
        
        notification(appContextV2.dispatchPanels, "Ajout de la nouvelle équipe", "information");
        wizard.goToStep("pending");
        
        const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/t/add`, {
          name: form.name || '',
          description: form.description || '',
        }, {withCredentials: true});
        
        if(fetched.data.status !== "ok") {
          appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
          return notification(appContextV2.dispatchPanels, "Erreur lors de l'ajout d'une nouvelle équipe", "error");
        }
        
        appContextV2.appPanels.window?.windowOptions?.data?.addTeamFront(
          fetched.data.data.teamId,
          form.name,
          form.description);
        notification(appContextV2.dispatchPanels, "La nouvelle équipe à été ajoutée avec succès", "success");
        
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        
      } catch (error) {
        notification(appContextV2.dispatchPanels, "Erreur lors de l'ajout d'une nouvelle équipe", "error");
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        devLog(error);
      }
    })();
  }
  
  if(step === "pending") {
    return (<WindowWrapper windowTitle={""}>
    </WindowWrapper>);
  }
  
  if(step === "error") {
    return (<WindowWrapper windowTitle={""} status={"ok"}>
      <Spaces n={1}/>
      Une erreur est survenue.
      <Spaces n={1}/>
    </WindowWrapper>);
  }
  
  return (<WindowWrapper windowTitle={""} status={"ok"}>
    <div className={"text--center text--magnified2x"}>Ajoute une nouvelle équipe</div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <input type={"text"} name={"name"} placeholder={"Nom de l'équipe"} onChange={updateForm({maximumLength: 15})}/>
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button disabled={!form.name} className={"btn-main"} onClick={() => addTeam()}>
        Créer
      </button>
    </div>
  
  </WindowWrapper>);
}