import React, {useContext, useEffect, useReducer, useState} from "react";
import Spaces from "../../components/sharedComponents/Spaces";
import {ButtonBigPlusV2} from "../../components/sharedComponents/Buttons/ButtonBigPlus";
import PageLayoutWithHeader from "../../layouts/PageLayoutWithHeader";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import fetchAmbassadors
  from "./componentsRecruiterAmbassadors/dependenciesRecruiterAmbasssadors/fetch/fetchAmbassadors";
import {RecruiterAmbassadorsEmpty} from "./componentsRecruiterAmbassadors/RecruiterAmbassadorsEmpty";
import {RecruiterAmbassador} from "./componentsRecruiterAmbassadors/RecruiterAmbassador";
import ambassadorsReducer from "./componentsRecruiterAmbassadors/ambassadorsReducer";
import ButtonShare from "../../components/sharedComponents/ButtonShare";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import {notification} from "../../utils/notification";
import {AmbassadorsStats} from "../../components/sharedComponents/Window/componentsWindow/componentsWA/AmbassadorsStats";
import {ReactPdf} from "../../components/sharedComponents/ReactPdf/ReactPdf";

export {RecruiterAmbassadors}



/**
 * --------------------------------------
 * Display and manage ambassadors
 * Primary-level page
 * --------------------------------------
 */
const RecruiterAmbassadors = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [ambassadors, dispatchAmbassadors] = useReducer(ambassadorsReducer, []);
  const [pageStatus, setPageStatus]: [any, any] = useState("asleep");
  const [token, setToken] = useState("");
  
  useEffect(() => {
    (async() => {
      try {
        const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/b/get/business/token`, {
          withCredentials: true
        })).data;
        if(status !== "ok") {
        }
        setToken(data);
      } catch(error) {
        devLog(error);
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    })();
  }, []);
  
  const url = `${window.location.origin}/ambassadors/subscription/${token}`;
  
  /** Initial ambassadors-fetch (of the current team only) */
  useEffect(() => {fetchAmbassadors(setPageStatus, dispatchAmbassadors)}, []);

  console.log("ambassadors",ambassadors);
  return (<PageLayoutWithHeader grey={true} title={"Ambassadeurs"} small={true} fullWidth={true} page={props.page}
                                status={pageStatus}>
    
    <h1 className={"text--center title--bold"}>Partagez votre lien d’inscription ambassadeurs <ButtonShare onClick={() => {
      appContextV2.dispatchPanels({
        type: "OPEN_WINDOW",
        value: {
          type: "ambassadorLinkCopied",
          data: null
        }
      });
      notification(appContextV2.dispatchPanels, "Lien copié", "success");
      navigator.clipboard.writeText(url);
    }} /></h1>
    
    <div className={"text--center text--grey"}> Partagez ce lien pour que les ambassadeurs s’inscrivent d’eux même.</div>
    
    <Spaces n={1}/>
    
    <AmbassadorsStats/>
  
    <Spaces n={1}/>
    
    {ambassadors?.length === 0 && <RecruiterAmbassadorsEmpty/>}
    
    {ambassadors?.length !== 0 && <section className={"ambassadors--v2"}>
      {ambassadors?.map((item: any, key: number) => <RecruiterAmbassador key={key}
                                                                         item={item}
                                                                         dispatchAmbassadors={dispatchAmbassadors}/>)}
    </section>}
    
    <Spaces n={2}/>
    
    <p className={"space-flex--center"}>
      <ButtonBigPlusV2 cb={() => {
        appContextV2.dispatchPanels({
          type: "OPEN_WINDOW",
          value: {
            type: "ambassador",
            data: {
              dispatchAmbassadors: dispatchAmbassadors,
              type: "add",
            }
          }
        });
      }} title={"Ajoute un ambassadeur"}/>
    </p>
    
    <Spaces n={3}/>

    {/*<ReactPdf/>*/}
  
  </PageLayoutWithHeader>);
}
