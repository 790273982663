import React from "react";

export {RecruiterPoolsEmpty};

const RecruiterPoolsEmpty = () => {
  
  return(<section className={"container white-container text--center"}>
      <span className={"text--magnified11x"}>
        Ajoute une communauté pour y placer des candidats potentiels.
      </span>
  </section>);
}