import React from "react";


export default function RecruiterMissionsEmpty (props: any) {
  
  return(<section className={"container white-container text--center"}>
      <span className={"text--magnified11x"}>
        Ajoute un poste pour commencer à recruter.
      </span>
  </section>);
}