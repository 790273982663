export default [
  {
    name: "personalFacebook",
    title: "Facebook personnel",
    iconUrl: "https://static.myshortlist.co/apps/facebook.svg",
    isAvailable: false,
    isActive: false,
    isPosted: false,
  },{
    name: "personalTwitter",
    title: "Twitter personnel",
    iconUrl: 'https://static.myshortlist.co/apps/twitter.svg',
    isAvailable: false,
    isActive: false,
    isPosted: false,
  },{
    name: "personalLinkedin",
    title: "LinkedIn personnel",
    iconUrl: "https://static.myshortlist.co/apps/linkedin.svg",
    isAvailable: false,
    isActive: false,
    isPosted: false,
  },
]