import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../App/context/AppContext";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return(<div className={"production-banner"}>
    Attention, vous êtes en mode {appContextV2.appSession.app.mode === "demo" && "démo"}{appContextV2.appSession.app.mode === "prototype" && "prototype"}{appContextV2.appSession.app.mode === "local" && "local"}.
  </div>);
}