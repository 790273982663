import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import {withRouter} from "react-router-dom";
import SubNavLinkGenerator from "./shared/SubNavLinkGenerator";

export function SubMenuCandidateMainContent(props: any) {


  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const currentPage: string = appContextV2.appNavigation.page;

  return (<>


    <SubNavLinkGenerator fr={"Mon profil"} en={"My profile"} uri={"/profile"} active={currentPage === "CandidateProfile"}
                           alt={"profil candidat"} icon={"https://static.myshortlist.co/btn-search.svg"} smaller={false}/>

    <SubNavLinkGenerator fr={"Mes candidatures"} en={"My candidacies"} uri={"/candidacies"} active={currentPage === "CandidateKanban"}
                         alt={"candidatures"} icon={"https://static.myshortlist.co/btn-list.svg"} smaller={false}/>

    <SubNavLinkGenerator fr={"Mes favoris"} en={"My favorites"} uri={"/favorites"} active={currentPage === "CandidateFavorites"}
                         alt={"favoris"} icon={"https://static.myshortlist.co/btn-list.svg"} smaller={false}/>

  </>);
}


export default withRouter(SubMenuCandidateMainContent);
