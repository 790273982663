import React from "react";

export {ElementMediaV2};

const ElementMediaV2 = (props: any) => {
  
  switch(props.item.type) {
    case 'image':
      return (
        <div className={"business-page__element--1--1"}>
          <img className={'business-page__element__image'} src={props.item.url} alt={"main-pic"} />
        </div>
      );
    case 'imagePortrait':
      return (
        <div className={"business-page__element--1--2"}>
          <img className={'business-page__element__image'} src={props.item.url} alt={"main-pic"} />
        </div>
      );
    case 'video':
      return (
        <div className={"business-page__element--1--1"}>
          <iframe width={"100%"} height={"100%"} src={  `https://www.youtube.com/embed/${props.item.url}`  } frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen> </iframe>
        </div>
      );
    
    case 'freeText':
      return (
        <div className={"business-page__element--1--free"}>
          <div className={"business-page__element__free-text"}>
            {
              !!props.item?.textContent?.title && <p className={"business-page__element__free-text__title"}>
                {props.item?.textContent?.title}
              </p>
            }
            {
              (!!props.item?.textContent?.title&&!!props.item?.textContent?.content ) && <p><br/></p>
            }
            {
              !!props.item?.textContent?.content &&  <p className={"business-page__element__free-text__content"}>
                {props.item?.textContent?.content}
              </p>
            }
          
          </div>
        </div>
      );
    
    default:
      return (<></>);
  }
}

