import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import PageLayout from "../../layouts/PageLayout";
import {Link} from "react-router-dom";
import {notification} from "../../utils/notification";
import axios from "axios";

/**
 * --------------------------------------------------------
 * Login page, takes a token and attempt to identify user
 */
export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  const [pageStatus, setPageStatus] = useState("pending");
  
  /** Fetcher */
  const [fetchStatus, setFetchStatus] = useState("asleep");
  const [fetched, setFetched] = useState({status: "", data: ""});
  
  useEffect(() => {
    (async() => {
      setFetchStatus("fetching");
      const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/session/sl/login`, {
        withCredentials: true,
        headers: {
          token: props.match.params.token || ""
        }
      });
      if(fetched.data.status !== "ok") {
        return setFetchStatus("error");
      }
      await setFetched(fetched.data);
      setFetchStatus("fetched");
    })();
  }, []);
  
  useEffect(() => {
    (async()=>{
      
      switch(fetchStatus) {
        
        case 'fetched':
          
          switch(fetched.status || "") {
            
            case 'ok':
              await appContextV2.fetchSession();
              props.history.push('/');
              break;
              
            case 'tokenExpired':
              notification(appContextV2.dispatchPanels, "Lien invalide", "error");
              return setPageStatus("tokenExpired");
  
            case 'noUser':
              notification(appContextV2.dispatchPanels, "Lien invalide", "error");
              return setPageStatus("noUser");
              
            case 'error':
            default:
              notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
              return setPageStatus("error");
          }
          break;
          
        case 'error':
          return setPageStatus("error");
          
        case 'fetching':
        case 'asleep':
        default:
      }
    })();
  }, [fetchStatus]);
  
  switch(pageStatus) {
    
    case 'pending':
      
      return (<PageLayout page={props.page} status={"ok"}>
      </PageLayout>);
  
    case 'tokenExpired':
      
      return (<section className={"full-page"}>
        <p className={"text--center"}>
          {language === 'FR' && <>Le lien utilisé n'est pas ou plus valide. Demandes-en un nouveau pour te connecter.</>}
          {language === 'EN' && <>The link you just used has expired. Ask for a new one to login.</>}
        </p>
        <p><br/></p>
        <p className={"text--center"}>
          <button className={"btn-main"} onClick={() => {
            appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
                type: "login",
                data: "",
              }});
          }}>
            {language === 'FR' && <>Renouveler le lien</>}
            {language === 'EN' && <>Renew the link</>}
          </button>
        </p>
      </section>);
  
    case 'noUser':
      return (<section className={"full-page"}>
        <p className={"text--center"}>
          {language === 'FR' && <>Action impossible, le lien n'est pas valide ou a expiré.</>}
          {language === 'EN' && <>Required account does not exist or expired link.</>}
        </p>
        <p><br/></p>
        <p className={"text--center"}>
          <Link to={"/"}>
            <button className={"btn-main"}>
              {language === 'FR' && <>Retour au site</>}
              {language === 'EN' && <>Back to website</>}
            </button>
          </Link>
        </p>
      </section>);
      
    case 'error':
      
      return (<section className={"full-page"}>
        <p className={"text--center"}>
          {language === 'FR' && <>Une erreur est survenue. N'hésitez pas à contacter le support.</>}
          {language === 'EN' && <>An error occured. Contact the support if it persists.</>}
        </p>
        <p><br/></p>
        <p className={"text--center"}>
          <Link to={"/"}>
            <button className={"btn-main"}>
              {language === 'FR' && <>Retour au site</>}
              {language === 'EN' && <>Back to website</>}
            </button>
          </Link>
        </p>
      </section>);
  }
};
