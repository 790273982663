import React, {useContext, useEffect, useState} from "react";
import Spaces from "../../../../Spaces";
import LoadingStyle2 from "../../../../Loading/LoadingStyle2";
import AppContext, {IAppV2Context} from "../../../../../App/context/AppContext";
import axios from "axios";
import {notification} from "../../../../../../utils/notification";
import {ButtonBigPlusV2} from "../../../../Buttons/ButtonBigPlus";
import {shortenText} from "../../../../../../utils/shortenText";
import {capitalizeMulti} from "../../../../../../utils/converters";
import {abstractPutFetch} from "../../../../../../utils/abstractFetch";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [isLoading, setIsLoading] = useState(false);
  
  const disabled = props.candidate?.candidate?.sharingLinks?.length > 4 || isLoading;
  
  const addNewLink = async() => {
    try {
      setIsLoading(true);
      const fetched = await axios.post(`${process.env.REACT_APP_API_URL}/v3/communities/can/mc/new/share`, {
        candidateId: props.candidate?.candidate?._id,
        candidacyId: props.candidate?.candidacy?._id,
      }, {
        withCredentials: true,
      });
      
      if(fetched.data.status !== "ok") {
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      
      setIsLoading(false);
      
      props.addShareFrontInWindow(fetched.data.data);
      
      return notification(appContextV2.dispatchPanels, "Succès", "success");
      
    } catch(error) {
      setIsLoading(false);
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const removeLink = async(linkId: string) => {
    try {
      setIsLoading(true);
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/can/mc/delete/share`, {
        candidateId: props.candidate?.candidate?._id,
        id: linkId,
      }, {
        withCredentials: true,
      });
      
      if(fetched.data.status !== "ok") {
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        
      }
      setIsLoading(false);
      props.deleteShareFrontInWindow(linkId);
      
      return notification(appContextV2.dispatchPanels, "Succès", "success");
      
    } catch(error) {
      setIsLoading(false);
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const isClientBlackBelt = appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io";
  
  return (<>
    <Spaces n={0}/>
    
    <h1 className={"results-v2__title"}>
      Partager la fiche du candidat
    </h1>
    
    <Spaces n={0}/>
    
    <h2 className={"text--grey text--center"}>
      Générez un lien pour partager le candidat
    </h2>
    
    <Spaces n={0}/>
    
    {
      isLoading && <LoadingStyle2/>
    }
    
    {props.candidate?.candidate?.sharingLinks?.length === 0 && <>  <Spaces n={0}/>
      <div className={"window-candidate-share__btn-plus"}>
        Vous n'avez pas encore de liens pour ce candidat
      </div>
      <Spaces n={0}/></>}
    
    {props.candidate?.candidate?.sharingLinks?.map((item: any, key: number) => (<>
      
      <div className={"white-container white-container--grey space-margin--small "}>
        <div className={"candidate-window_link-header"}>
          <div className={"candidate-window_link-header_name"}>
            
            <input className={"candidate-window_input"} placeholder={"nom"} value={item.tokenName} onChange={(e) => {
              if(e.target.value.length <= 100) {
                props.updateCandidateFrontTokenName(e.target.value, item._id)
              }
            }}/>
            
            <button className={"mission--V2__buttons__parameters"} title={"sauvegarder le nom"}
                    onClick={async() => {
                      try {
                        const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/communities/can/mc/share/new/name`, {
                          candidateId: props.candidate?.candidate?._id,
                          sharingLinkId: item._id,
                          name: item.tokenName,
                        }, {withCredentials: true});
                
                        if(fetched.data.status !== "ok") {
                          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                          return;
                        }
                
                        return notification(appContextV2.dispatchPanels, "Succès", "success");
                
                      } catch(error) {
                        console.log(error);
                      }
                    }}>
              <img src={"https://static.myshortlist.co/btn-save.svg"}/>
            </button>
          
          </div>
          
          <div>
            Ouverture(s):{item.openingCounter}
          </div>
        </div>
        
        <div className={"window-candidate_link-box text--minified2x"}>
          {shortenText(`${window.location.origin}/action/candidate/shared/${item.token}`, 45)}
          <button className={"mission--V2__buttons__parameters"} onClick={() => removeLink(item._id)}>
            <img src="https://static.myshortlist.co/btn-bin.svg" alt="supprimer"/>
          </button>
        </div>
        
        <div className={"window-candidate-share__btn-plus"}>
          <button className={"btn-secondary "} onClick={() => {
            notification(appContextV2.dispatchPanels, "Lien copié", "success");
              navigator.clipboard.writeText(`${window.location.origin}/action/candidate/shared/${item.token}`);
          }}>Copier le lien
          </button>
          
          <div>
            <button className={"btn-secondary"} onClick={() => {
              let copyArea = document.getElementById("signature");
              copyArea!.innerHTML = `<a style="color: blue; text-decoration: underline;" href="${window.location.origin}/action/candidate/shared/${item.token}">
                        Voir le profil de ${props?.candidate?.candidate?.firstName ?? ""} ${props?.candidate?.candidate?.lastName ?? ""}</a>`
              copyArea!.focus();
              document.execCommand("selectAll");
              document.execCommand("copy");
              notification(appContextV2.dispatchPanels, "Lien copié", "success");
            }}>Copier le lien formaté
            </button>
          </div>
  
          {isClientBlackBelt && <button className={"btn-secondary "} onClick={() => {
            notification(appContextV2.dispatchPanels, "Lien copié", "success");
            if(appContextV2.appSession.app.mode === "production") {
              navigator.clipboard.writeText(`https://black-belt.myshortlist.co/candidat/${item.token}`);
            } else {
              navigator.clipboard.writeText(`http://localhost:3001/candidat/${item.token}`);
            }
          }}>Copier le lien BB
          </button>}
  
          {isClientBlackBelt && <button className={"btn-secondary "} onClick={() => {
            notification(appContextV2.dispatchPanels, "Lien copié", "success");
            if(appContextV2.appSession.app.mode === "production") {
              let copyArea = document.getElementById("signature");
              copyArea!.innerHTML = `<a style="color: blue; text-decoration: underline;" href="https://black-belt.myshortlist.co/candidat/${item.token}">
                        Voir le profil de ${props?.candidate?.candidate?.firstName ?? ""} ${props?.candidate?.candidate?.lastName ?? ""}</a>`
              copyArea!.focus();
              document.execCommand("selectAll");
              document.execCommand("copy");
            } else {
              let copyArea = document.getElementById("signature");
              copyArea!.innerHTML = `<a style="color: blue; text-decoration: underline;" href="http://localhost:3001/candidat/${item.token}">
                        Voir le profil de ${props?.candidate?.candidate?.firstName ?? ""} ${props?.candidate?.candidate?.lastName ?? ""}</a>`
              copyArea!.focus();
              document.execCommand("selectAll");
              document.execCommand("copy");
            }
          }}>Copier le lien BB Formaté
          </button>}
          
        </div>
        <div className={" window-candidate-share__div-invisible"} id={"signature"} contentEditable={"true"}>a</div>
      </div>
      <Spaces n={0}/></>))}
    
    <div className={"window-candidate-share__btn-plus"}>
      <ButtonBigPlusV2 disabled={disabled} cb={() => addNewLink()} title={"Créer un nouveau lien"}/>
    </div>
  </>);
}
