import React, {useContext} from "react";
import AppContext, {IAppContext, IAppV2Context} from "../../../components/App/context/AppContext";
import CloseButton from "../../../components/sharedComponents/Buttons/CloseButton";
import RecruiterResearchEditorNewFormItemChoice from "./componentsRecruiterResearchEditorNewFormItem/RecruiterResearchEditorNewFormItemChoice";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const formId = appContextV2.appPanels?.editorPanel?.editorPanelOptions?.data?.formId;
  const addQuestion = appContextV2.appPanels?.editorPanel?.editorPanelOptions?.data?.addQuestion;
  
  
  switch(appContextV2.appPanels.editorPanel.editorPanelOptions.type) {
    
    case 'default':
      return(<BlackScreenItemWrapper>
        <RecruiterResearchEditorNewFormItemChoice formId={formId} addQuestion={addQuestion} />
      </BlackScreenItemWrapper>);
    
    default:
      return(<></>);
      
  }
  
}

 class RecruiterResearchEditorNewFormItem extends React.Component<any, any> {
  
  static contextType = AppContext;
  
  constructor(props: any) {
    super(props);
    this.state = {
      step: 'addEmpty',
      origin: '',
    };
  }
  
  componentDidMount(): void {
    this.setStep(this.context.appState.newItemOptions.type);
  }
  
  setStep = (step: string) => {
    this.setState({
      step: step
    });
  };
  
  render() {
    
    const addNewItem: Function = this.context.appState?.newItemOptions?.addNewItem;
    const sectionNumber: number = this.context.appState?.newItemOptions?.sectionNumber;
    const templates: any = this.context.appState.newItemOptions.templates;
    
    switch(this.state.step) {
      case 'addEmpty':
        return (<><BlackScreenItemWrapper>
          <RecruiterResearchEditorNewFormItemChoice setStep={this.setStep}/>
        </BlackScreenItemWrapper></>);
      case 'presets':
        return (<><BlackScreenItemWrapper>
          <section className={"editor-new-item__choices"}>
            
            {templates.map((item: any, key: any) => (<>
              <button className={"editor-new-item__choices__choice"} title={item.question} onClick={() => {
                // props.setStep("newMultipleChoiceItem")
                addNewItem(item, sectionNumber);
                this.context.toggleNewItemVisibility("close", {});
              }}>
                <span>{item.displayedName}</span>
                <img src={item.icon} alt={item.title}/>
              </button>
            </>))}
            
            <button className={"editor-new-item__choices__choice"} onClick={() => {
              this.context.toggleNewItemVisibility('close', {});
            }}>
              <span>Retour à l'éditeur</span>
              <img src={"https://static.myshortlist.co/btn-login.svg"} alt={"go back"}/>
            </button>
          </section>
        </BlackScreenItemWrapper></>);
    }
  }
}

export function BlackScreenItemWrapper(props: any) {
  return (<>
    <div className={"editor-new-item__screen animation-straight-fade-in"}>
      {props.children}
    </div>
  </>);
}

function NewItemWindow(props: any) {
  
  const appContext: IAppContext = useContext(AppContext) as IAppContext;
  
  return (<>
    <BlackScreenItemWrapper>
      <section className={"editor-new-item__window"}>
        <article className={"editor-new-item__window__header"}>
          <CloseButton eventOnClick={() => appContext.toggleNewItemVisibility("close", {})}/>
        </article>
        <article className={"editor-new-item__window__title"}>
          {props.title}
        </article>
        <p className={"editor-new-item__window__comment"}>{props.comment}</p>
        <p><br/></p>
        <hr/>
        <p><br/></p>
        {props.children}
        <p><br/></p>
      </section>
    </BlackScreenItemWrapper>
  </>);
}