import React from "react";
import PageLayout from "../PageLayout";
import {HeaderLayout} from "./HeaderLayout";

export interface PageProps {
  page: string,
  status: 'pending' | 'error' | 'forbidden' | 'ok',
  children: any,
  hideHeader?: boolean,
  fullWidth?: boolean,
  small?: boolean,
  title?: string,
  grey?: boolean,
  white?:boolean
}

export default (props: PageProps) => {
  
  const isPageFullWidth: boolean = !!props.fullWidth;
  
  return (
    <>
      
      <HeaderLayout isHeaderVisibleOnPage={!props.hideHeader}/>
      
      <PageLayout
                  grey={props.grey}
                  title={props.title}
                  small={props.small}
                  page={props.page}
                  fullWidth={isPageFullWidth}
                  status={props.status}>
        
        {props.children}
      
      </PageLayout>
    </>
  );
  
}
