import React, {useContext, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import Spaces from "../../../Spaces";
import {format} from "date-fns";

export default (props: {
  fieldToUpdate: string,
  modifier: Function,
  mission: any,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [valueAsSoonAsPossible, setValueAsSoonAsPossible] = useState(props.mission?.asSoonAsPossible);
  const [valueDuration, setValueDuration] = useState(props.mission?.duration);
  const [valuePermanent, setValuePermanent] = useState(props.mission?.permanent);
  const [valueFrom, setValueFrom] = useState(props.mission?.from);
  
  return (<>
    
    <div className={"white-container white-container--grey"}>
      
      <input type={"checkbox"} checked={valuePermanent} onClick={() => {
        props.modifier("permanent", !valuePermanent);
        setValuePermanent((ps: boolean) => !ps);
      }}/> Recrutement permanent (pas de date de prise de poste)
      
      {!valuePermanent && <>
        <Spaces n={0}/>
        <input type={"checkbox"} checked={valueAsSoonAsPossible} onClick={() => {
          props.modifier("asSoonAsPossible", !valueAsSoonAsPossible);
          props.modifier("from", format(new Date(), "yyyy-MM-dd"));
          setValueAsSoonAsPossible((ps: boolean) => !ps);
          setValueFrom(format(new Date(), "yyyy-MM-dd"));
        }}/> Dès que possible
      </>}
      
      {
        (!valuePermanent && !valueAsSoonAsPossible) && <>
          <Spaces n={0}/>
          <label>
            <span className={"text--minified2x"}>Date de prise de poste:</span><br/>
            <input className={"input--v2"} type={"date"} value={valueFrom} onChange={(e) => {
              props.modifier("from", e.target.value);
              setValueFrom(e.target.value);
            }}/>
          </label>
        </>
      }
    
    </div>
    
    {/*<Spaces n={0}/>*/}
    
    {/*<div className={"text--center"}>*/}
    {/*  <button className={"btn-secondary"} onClick={() => {*/}
    {/*    props.modifier("positionWanted", value);*/}
    {/*    notification(appContextV2.dispatchPanels, "Succès", "success");*/}
    {/*  }}>*/}
    {/*    Mettre à jour*/}
    {/*  </button>*/}
    {/*</div>*/}
  </>);
  
}