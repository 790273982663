import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import {isPhoneNumberValid} from "../../../../../utils/typeGuards";
import {cleanPhoneNumber} from "../../../../../utils/converters";
import Spaces from "../../../Spaces";
import {ButtonBack} from "../../../Buttons/ButtonBack";
import DisplayPhone from "../../../DisplayPhone";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  const language = appContextV2.appSession.app.language;
  
  const next = props.assistantContext.wizard.nextStep;
  const updateForm = props.assistantContext.updateForm;
  const assistantState = props.assistantContext.form;
  
  const isFirstNameEmpty = !assistantState.user.firstName;
  const isLastNameEmpty = !assistantState.user.lastName;
  const isPhoneEmpty = !assistantState.user.phone;
  const isPhoneCountryCodeEmpty = !assistantState.user.phoneCountryCode;
  const isRecruiterPositionEmpty = !assistantState.user.recruiterPosition;
  
  const doesBusinessAlreadyExist = appContextV2.appSession.sessionBusiness.businessName !== "" && appContextV2.appSession.sessionBusiness.businessName !== "Koala Cola";
  
  console.log('-------------------------------- ', appContextV2.appSession.sessionBusiness.businessName)
  
  const isButtonOk = !isFirstNameEmpty && !isLastNameEmpty;
  
  return (<>
    
    <ButtonBack back={props.assistantContext.wizard.previousStep}/>
    
    <Spaces n={0}/>
    
    <div className={"text--center text--magnified1x title--bold"}>
      {language === 'FR' && <>À propos de toi</>}
      {language !== 'FR' && <>About you</>}
    </div>
    
    <Spaces n={2}/>
    
    <form onSubmit={(e) => {
      e.preventDefault();
      if(isButtonOk) {
        if(doesBusinessAlreadyExist) {
          console.log("Business already exists, jumping the assistant");
          return props.assistantContext.wizard.goToStep("post");
        }
        return props.assistantContext.wizard.goToStep("businessIsAgency");
      }
    }}>
      
      <p className={"text--center"}>
        <input placeholder={"Prénom *"} value={assistantState?.user?.firstName} type={"text"} name={"user.firstName"}
               className={`animation-assistant-appear ${(!isFirstNameEmpty) ? "input--ok" : ""}`} onChange={updateForm({maximumLength: 20})}/>
      </p>
      
      <p className={"text--center"}>
        <input placeholder={"Nom de famille *"} value={assistantState?.user?.lastName} type={"text"} name={"user.lastName"}
               className={`animation-assistant-appear ${(!isLastNameEmpty) ? "input--ok" : ""}`} onChange={updateForm({maximumLength: 20})}/>
      </p>
      
      <p className={"text--center"}>
        {/*<DisplayPhone phone={assistantState?.user?.phone}*/}
        {/*              phoneCountryCode={assistantState?.user?.phoneCountryCode}*/}
        {/*              updatePhone={}*/}
        {/*              updatePhoneCountryCode={}/>*/}
        <input placeholder={"Numéro de téléphone"} value={assistantState?.user?.phone} type={"text"} name={"user.phone"}
               className={`animation-assistant-appear ${(!isPhoneEmpty) ? "input--ok" : ""}`} onChange={updateForm({toKeepNumbers: true, maximumLength: 18})}/>
      </p>
      
      <p className={"text--center"}>
        <input placeholder={"Poste"} type={"text"} value={assistantState?.user?.position} name={"user.recruiterPosition"}
               className={`animation-assistant-appear ${(!isRecruiterPositionEmpty) ? "input--ok" : ""}`} onChange={updateForm({maximumLength: 60})}/>
      </p>
      
      <Spaces n={1}/>
      
      <p className={"text--center"}>
        <button disabled={!isButtonOk} className={"btn-main"} onClick={() => {
          if(doesBusinessAlreadyExist) {
            console.log("Business already exists, jumping the assistant");
            return props.assistantContext.wizard.goToStep("post");
          }
          return props.assistantContext.wizard.goToStep("businessIsAgency");
        }}>
          {language === 'FR' && <>Étape suivante</>}
          {language !== 'FR' && <>Next step</>}
        </button>
      </p>
    
    </form>
  </>);
  
}
