import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import PageLayout from "../../layouts/PageLayout";
import {notification} from "../../utils/notification";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import Spaces from "../../components/sharedComponents/Spaces";
import isPasswordValid from "../../utils/isPasswordValid";

/**
 * --------------------------------------------------------
 * Login page, takes a token and attempt to identify user
 */
export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const [status, setStatus] = useState("");
  const [userId, setUserId] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
  const hasNumber = /\d/;
  
  useEffect(() => {
    (async() => {
      const {
        status,
        data
      } = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/session/change/password`, {
        withCredentials: true,
        headers: {
          token: props.match.params.token || ""
        }
      })).data;
      setStatus(status);
      setUserId(data);
    })();
  }, []);
  
  const updatePassword = async(previousPassword: string, newPassword: any) => {
    try {
      notification(appContextV2.dispatchPanels, "Envoi de la mise à jour...", "information");
      const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/u/my/password`, {
        checkPreviousPassword: false,
        previousPassword: previousPassword,
        newPassword: newPassword,
        userId: userId,
      }, {withCredentials: true})).data;
      if(fetched.status === "ok") {
        setStatus("changed")
        notification(appContextV2.dispatchPanels, "Donnée mise à jour avec succès", "success");
      } else {
        setStatus("error")
        notification(appContextV2.dispatchPanels, `Une erreur est survenue`, "error");
      }
    } catch(error) {
      devLog(error);
      setStatus("error")
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  if(status === 'tokenExpired' || status === 'noUser' || status === 'error') {
    return (<PageLayout page={props.page} status={"ok"}>
      <section className={"full-page"}>
        <p className={"text--center"}>
          Une erreur est survenue. Le lien utilisé n'est pas ou plus valide. Demandes-en un nouveau pour
          mettre a jour le mot de passe.
        </p>
        <p><br/></p>
        <p className={"text--center"}>
          <button className={"btn-main"} onClick={() => {
            appContextV2.dispatchPanels({
              type: "OPEN_WINDOW", value: {
                type: "login",
                data: "",
              }
            });
          }}>
            Renouveler le lien
          </button>
        </p>
      </section>
    </PageLayout>);
  }
  
  if(status === 'changed') {
    return (<PageLayout page={props.page} status={"ok"}>
      <section className={"full-page"}>
        <p className={"text--center"}>
          Votre mot de passe a bien été mis à jour !
        </p>
        <p><br/></p>
        <p className={"text--center"}>
          <button className={"btn-main"} onClick={() => {
            appContextV2.dispatchPanels({
              type: "OPEN_WINDOW", value: {
                type: "login",
                data: "",
              }
            });
          }}>
            Se connecter
          </button>
        </p>
      </section>
    </PageLayout>);
  }
  
  return (<PageLayout page={props.page} status={"ok"}>
    <section className={"full-page"}>
      
      <p className={"assistant-layout__title"}>
        Entre ton nouveau mot de passe
      </p>
      <Spaces n={0}/>
      <input placeholder={"Mot de passe"} type={"password"} value={newPassword}
             className={`input--v2`} onChange={(e) => setNewPassword(e.target.value)}/>
      <input placeholder={"Confirmation mot de passe"} type={"password"} value={newPasswordConfirmation}
             className={`input--v2`} onChange={(e) => setNewPasswordConfirmation(e.target.value)}/>
      
      <button
        disabled={!(isPasswordValid(newPassword) && newPassword === newPasswordConfirmation)}
        className={"btn-main"} onClick={async() => {
        await updatePassword("", newPassword);
        setNewPassword("");
        setNewPasswordConfirmation("");
      }}>
        Enregistrer
      </button>
      
      <Spaces n={0}/>
  
      {newPassword && !isPasswordValid(newPassword) && <div className={"text--center text--red"}>
        Le mot de passe doit contenir 8 caractères dont au moins 1 chiffre.
      </div>}
  
      {(newPassword && newPasswordConfirmation && (newPassword !== newPasswordConfirmation)) && <div className={"text--center text--red"}>
        Les mots de passe ne correspondent pas.
      </div>}
      
    </section>
  </PageLayout>);
  
}
