import {notification} from "../../../../../../utils/notification";

export default (args: { fetchedDataV2: any, dispatchDataV2: Function, dispatchPanels: Function }) => () => {
  
  if(args.fetchedDataV2.missionProfile?.customKanbanView.length >= 8) {
    return notification(args.dispatchPanels, "Le nombre maximum de colonnes est atteint", "warning");
  }
  
  args.dispatchDataV2({
    action: "ADD_COLUMN", value: {
      activateForm: false,
      activateMail: false,
      activatePool: false,
      activateAskCv: false,
      activateNotify: false,
      description: "",
      mailContent: "",
      mailDelay: 0,
      mailSubject: "",
      name: "Nouvelle colonne",
      poolId: "",
      _id: "",
    }
  });
  
  return;
  
}