import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../components/sharedComponents/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Le poste est-il en télétravail* ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container"}>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "remote", value: "yes"}});
                props.wizard.goToStep('lastAssistantStep');
              }}>
        Oui, le poste est en télétravail
      </button>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "remote", value: "no"}});
                props.wizard.goToStep('roaming');
              }}>
        Non, la présence du candidat est la norme
      </button>
    
    </div>
    
    <Spaces n={1}/>
  
    <div className={"info-box"}>
      Information COVID
      <Spaces n={0}/>
      Le télétravail dont il est question ici concerne le mode habituel de travail, et ne concerne pas le télétravail d'exception lié à la situation de confinement due au COVID. Si un jour de télétravail est proposé par semaine, vous pouvez aussi l'ajouter aux <button className={"btn--hollow modern-link"} onClick={()=>props.wizard.goToStep('advantages')}>avantages</button>.
    </div>
  
  </section>);
}