import React from "react";
import Spaces from "./Spaces";

export {NotOnSmartphones};

const NotOnSmartphones = (props: any) => {
  
  return(<div className={"hide-on-computers container text--center"}>
    <Spaces n={8}/>
    Cette page n'est pas disponible sur smartphone. Rendez-vous sur un ordinateur !
  </div>)
}