import React, {useContext, useEffect, useReducer, useState} from "react";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import PageLayoutWithHeader from "../../layouts/PageLayoutWithHeader";
import Spaces from "../../components/sharedComponents/Spaces";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import dataReducerCandidate from "../../components/sharedComponents/FirstLoginAssistants/componentsFirstLoginAssistant/componentsFirstLoginCandidate/dataReducerCandidate";
import PageLayout from "../../layouts/PageLayout";


export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const [candidate, dispatchCandidate]: [any, Function] = useReducer(dataReducerCandidate, {});
    const [newContract, setNewContract]=useState();
    console.log("candidate",candidate);

    useEffect(() => {
        (async function () {
            try {
                const {
                    status,
                    data
                } = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/get/candidate/profile`,
                    {withCredentials: true})).data;
                if (status === "ok") {
                    dispatchCandidate({type: "INITIALIZE_DATA", value: data});
                }
            } catch (error) {
                devLog(error);
            }
        })()
    }, []);

    return (
        <PageLayout offsetTop={true} page={props.page} status={"ok"} title={"Bienvenue !"}>
          
            {/*<div className={"text--center"}>*/}
            {/*    <img className={"candidate-profile__avatar"} src={candidate.avatar?.url}/>*/}
            {/*    <div className={"candidate-profile__name"}> {candidate.name?.firstName?.value?.toUpperCase()} {candidate.name?.lastName?.value?.toUpperCase()}</div>*/}
            {/*</div>*/}
            
            {/*<Spaces n={1}/>*/}
            {/*<div className={"text--center"}>*/}
            {/*    <div>*/}
            {/*        <div>Êtes vous à l'écoute du marché ?</div>*/}
            {/*        <button className={`candidate-profile__btn${candidate.isListeningToMarket?.value ? "candidate-profile__btn--selected":"candidate-profile__btn"}`}>Oui</button>*/}
            {/*        <button className={`candidate-profile__btn${candidate.isListeningToMarket?.value ? "candidate-profile__btn":"candidate-profile__btn--selected"}`}>Non</button>*/}
            {/*    </div>*/}
            {/*  */}
            {/*    <Spaces n={0}/>*/}
            
            {/*    <div>*/}
            {/*        <div>Êtes vous en poste actuellement ?</div>*/}
            {/*        <button className={`candidate-profile__btn ${candidate.isWorking? "candidate-profile__btn--selected":"candidate-profile__btn"}`} onClick={(e) => {*/}
            {/*           dispatchCandidate({type: "UPDATE_CANDIDATE_WORKING", value: true});*/}
            {/*        }}>*/}
            {/*            Oui*/}
            {/*        </button>*/}
            {/*        <button className={`candidate-profile__btn${candidate.isWorking? "candidate-profile__btn":"candidate-profile__btn--selected"}`} onClick={(e) => {*/}
            {/*            dispatchCandidate({type: "UPDATE_CANDIDATE_WORKING", value: false});*/}
            {/*        }}>*/}
            {/*            Non*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    <Spaces n={0}/>*/}
            
            {/*    <div>*/}
            {/*        <div>Êtes vous disponible immédiatement ?</div>*/}
            {/*        <button className={`${candidate.isAvailable? "":""}`}  onClick={(e) => {*/}
            {/*            dispatchCandidate({type: "UPDATE_CANDIDATE_AVAILABILITY", value: true});*/}
            {/*        }}>*/}
            {/*            Oui*/}
            {/*        </button>*/}
            {/*        <button className={`${candidate.isAvailable? "":""}`}  onClick={(e) => {*/}
            {/*            dispatchCandidate({type: "UPDATE_CANDIDATE_AVAILABILITY", value: false});*/}
            {/*        }}>*/}
            {/*            Non*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    <Spaces n={0}/>*/}
            
            {/*    <div>*/}
            {/*        <div>Activer le mode furtif</div>*/}
            {/*        <button>Oui</button>*/}
            {/*        <button>Non</button>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<Spaces n={0}/>*/}
            
            {/*<Spaces n={1}/>*/}
            
            {/*<div>*/}
            {/*    <div>Poste recherché :*/}
            {/*        <input value={candidate.positionWanted} type={"text"} onChange={(e) => {*/}
            {/*            dispatchCandidate({type: "UPDATE_CANDIDATE", value: {positionWanted: e.target.value}});}}/>*/}
            {/*    </div>*/}
            
            {/*    <div>Exprience à ce poste :*/}
            {/*        <input value={candidate.yearsOfExperience} type={"text"}/>*/}
            {/*    </div>*/}
            {/*    <div>Secteur favoris:*/}
            {/*        <input value={""}/>*/}
            {/*    </div>*/}
            {/*    <div>Contrats recherchés :*/}
            {/*            <button className={`${candidate.contracts?.includes("cdi") ? "":""}`} onClick={(e) => {*/}
            {/*                dispatchCandidate({type: "SWITCH_CONTRACT", value: "cdi"});*/}
            {/*            }}>*/}
            {/*                CDI*/}
            {/*            </button>*/}
            
            {/*            <button className={`${candidate.contracts?.includes("cdd") ? "":""}`} onClick={(e) => {*/}
            {/*                dispatchCandidate({type: "SWITCH_CONTRACT", value: "cdd"});*/}
            {/*            }}>*/}
            {/*                CDD*/}
            {/*            </button>*/}
            
            {/*            <button className={`${candidate.contracts?.includes("interim") ? "":""}`} onClick={(e) => {*/}
            {/*                dispatchCandidate({type: "SWITCH_CONTRACT", value: "interim"});*/}
            {/*            }}>*/}
            {/*                Interim*/}
            {/*            </button>*/}
            
            {/*            <button className={`${candidate.contracts?.includes("freelance") ? "":""}`} onClick={(e) => {*/}
            {/*                dispatchCandidate({type: "SWITCH_CONTRACT", value: "freelance"});*/}
            {/*            }}>*/}
            {/*                Freelance*/}
            {/*            </button>*/}
            
            {/*            <button className={`${candidate.contracts?.includes("stage") ? "":""}`} onClick={(e) => {*/}
            {/*                dispatchCandidate({type: "SWITCH_CONTRACT", value: "stage"});*/}
            {/*            }}>*/}
            {/*                Stage*/}
            {/*            </button>*/}
            
            {/*            <button className={`${candidate.contracts?.includes("alternance") ? "":""}`} onClick={(e) => {*/}
            {/*                dispatchCandidate({type: "SWITCH_CONTRACT", value: "alternance"});*/}
            {/*            }}>*/}
            {/*                Alternance*/}
            {/*            </button>*/}
            {/*    </div>*/}
            
            {/*    <div>Salaire minimum demandé (annuel brut) :*/}
            {/*        <input value={candidate.salaryMin} type={"number"} onChange={(e) => {*/}
            {/*            dispatchCandidate({type: "UPDATE_CANDIDATE", value: {salaryMin: e.target.value}});}}/>*/}
            {/*    </div>*/}
            {/*</div>*/}


        </PageLayout>);
}
