import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../App/context/AppContext";
import {notification} from "../../../../../../utils/notification";
import LoadingStyle2 from "../../../../Loading/LoadingStyle2";
import Spaces from "../../../../Spaces";
import axios from "axios";
import {devLog} from "../../../../../../utils/devLog/devLog";
import {format} from "date-fns";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [message, setMessage]: [any, any] = useState("");
  const [messages, setMessages]: [any, any] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  /** Load messages */
  useEffect(() => {
    (async() => {
      try {
        setIsLoading(true);
        const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/communities/pool/t/get/trigrams/on/candidate`, {
          timeout: 20_000,
          headers: {
            candidateid: props.candidate?.candidate?._id,
          },
          withCredentials: true
        });

        if(fetched.data.status !== "ok") {
          setIsLoading(false);
          return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
        setIsLoading(false);
        setMessages(fetched.data.data || []);
      } catch(error) {
        devLog(error);
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    })();
  }, []);

  // props?.inherited?.candidateId
  devLog(messages);
  
  /** Send messages */
  const sendMessage = async() => {
    try {
      setIsLoading(true);
      
      const fetched = await axios.post(`${process.env.REACT_APP_API_URL}/v3/communities/pool/t/new/trigram`, {
        candidateId: props.inherited.candidateId,
        message: message,
      }, {
        timeout: 20_000,
        withCredentials: true,
      });
      
      if(fetched.data.status !== "ok") {
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      devLog("savedMessage", fetched.data.data);
      
      // update front
      setMessages((ps: any) => ([...ps, {...fetched.data.data, isByMe: true}]));
      notification(appContextV2.dispatchPanels, "Commentaire ajouté avec succès", "success");
      setMessage("");
      setIsLoading(false);
    } catch(error) {
      setIsLoading(false);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  };
  
  const deleteTrigram = async(itemId: any) => {
    try {
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/t/delete/trigram`, {
        candidateId: props.inherited.candidateId,
        messageId: itemId,
      }, {
        timeout: 20_000,
        withCredentials: true,
      });
      setMessages((ps: any) => (ps.map((item: any, key: any) => ({
        ...item,
        body: item._id === itemId ? "Ce message a été supprimé" : item.body,
        deleted: item._id === itemId ? true : item.deleted,
      }))))
      notification(appContextV2.dispatchPanels, "Commentaire supprimé avec succès", "success");
    } catch(error) {
      throw (error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const isButtonActive: boolean = !!message && !isLoading;

  return (<div className={"text--grey container"}>

    {props.candidate?.candidacy?.importedFromAccount &&
    <div style={{padding:"20px"}}>Le candidat a ete importe du compte linkedin suivant: {props.candidate?.candidacy?.importedFromAccount} </div>
    }
    
    {
      isLoading && <div className={"space-flex--center"}>
        <Spaces n={0}/>
        <LoadingStyle2/>
        <Spaces n={0}/>
      </div>
    }
    
    
    {((!isLoading) && (messages.length === 0)) && <div className={"text--center"}>
      <Spaces n={4}/>
      Aucun message n'est enregistré pour ce candidat
      <Spaces n={4}/>
    </div>}
    
    <section className={"trigram-v2__messages"}>
      
      {[...messages || []].reverse().map((item: any, key: number) => (
        <article key={key}
                 className={`trigram-v2__messages__message ${(item.source === "human" || !item.source) ? "trigram-v2__messages__message--human" : ""}`}>
          <div className={"trigram-v2__messages__message__avatar"}>
            {item.recruiterTrigram?.toUpperCase()}
          </div>
          <div className={"trigram-v2__messages__message__body"}>
            <div className={"trigram-v2__messages__message__header"}>
              {item.dateOfCreation && (item.source === "human" || !item.source) && <>Message laissé
                par {item.from ? item.from : item.recruiterTrigram} le {format(new Date(item.dateOfCreationStamp || item.dateOfCreation || ""), "dd-MM-yyyy")}</>}
              {item.source === "machine" && <>Message auto-généré
                le {format(new Date(item.dateOfCreationStamp || item.dateOfCreation), "dd-MM-yyyy")}</>}
              {item.source === "retro" && <>Message enregistré
                le {format(new Date(item.dateOfCreationStamp || item.dateOfCreation), "dd-MM-yyyy")}</>}
              {((item.isByMe || appContextV2.appSession.session.userRole === "admin") && item.deleted !== true) &&
              <button className={"modern-link btn--hollow space-light"}
                      onClick={() => deleteTrigram(item._id)}> Supprimer </button>}
            </div>
            {
              item.deleted !== true && <>
                {(item.appreciation === "good" || item.appreciation ===5) &&
                <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-5.png"}/>}
                {(item.appreciation ===4) &&
                <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-4.png"}/>}
                {(item.appreciation === "neutral" || item.appreciation === 3)&&
                <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-3.png"}/>}
                {(item.appreciation ===2) &&
                <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-2.png"}/>}
                {(item.appreciation === "bad" || item.appreciation === 1)&&
                <img className={"trigram-emoji"} src={"https://static.myshortlist.co/img-rating-1.png"}/>}
              </>
            }
            
            {item.title ? item.title : ""}
  
            {item.body ? item.body?.split('\n').map(function(item: any) {
              return (
                <span className={"trigram-v2__messages__message__body--span"}>
              {item}
                  <br/>
              </span>
              )
            }) : ""}
          </div>
        </article>
      ))}
      
      <Spaces n={8}/>
    
    </section>
    
    <div className={"trigram-v2__specific-chat"}>
      <div className={"trigram-v2__specific-chat__send-bar"}>
        <textarea className={"trigram-v2__specific-chat__input"} value={message} onChange={(e) => {
          if(e.target.value.length < 350) {
            setMessage(e.target.value);
          }
        }}>
        </textarea>
        <button disabled={!isButtonActive} className={"trigram-v2__specific-chat__send"} onClick={() => {
          sendMessage();
        }}>
          Enregistrer
        </button>
      </div>
    </div>
  
  </div>);
}