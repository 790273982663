import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import WindowWrapper from "../../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {format} from "date-fns";

export default function(props: any) {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    const item = appContextV2.appPanels.window.windowOptions.data.item ?? {};
    const updateFront = appContextV2.appPanels.window.windowOptions.data.updateFront ?? {};

    const [parameters, setParameters] = useState({
        kuboSN: item.kuboSN ?? [],
        kuboSNv2: item.kuboSNv2 ?? [],
        kuboMessage: item.kuboMessage ?? '',
        kuboStatus: item.kuboStatus ?? '',
        kuboNotes: item.kuboNotes ?? ''
    });

    //const [kuboSN, setKuboSN] = useState("");
    const [kuboSNv2, setKuboSNv2]:any = useState({linkSN:"",accounts:[], launchDate:"", status:""});
    const [account, setAccount] = useState("");

    return (<WindowWrapper size={"big"} windowTitle={""} status={"ok"}>
        <>
            <article className={"editor-v2__section__title"}>Lancer des Kubos</article>
            <div className={"text--center"}>

                <div className={"window__block"}>
                    La référence du poste est {item.reference}. Utilisez cette fenêtre pour enregistrer les recherches à effectuer via Kubo.
                </div>

                <div className={"window__block"}>
                    <h2>Liste des recherches SN</h2>
                    <div>Les TAS entrent les liens de Sales Navigator où lon peut contacter les candidats.</div>
                    <Spaces n={0}/>
                    {parameters.kuboSN?.length === 0 && parameters.kuboSNv2?.length === 0 && <div>Aucun lien SN</div>}
                    {
                        parameters.kuboSNv2?.map((item: any) => <div style={{
                            backgroundColor: "white",
                            width: "90%",
                            padding: "5px 20px",
                            margin: "5px auto",
                            borderRadius: "10px",
                            display:"flex",
                            flexFlow:"column wrap",
                            justifyContent:"center",
                            alignItems:"center",
                        }} className={"sn"}>
                            <div style={{padding:"5px"}}>{item.launchDate ? format(new Date(item.launchDate), "dd-MM-yyyy") : "Pas de date"}</div>
                            <div style={{padding:"5px",
                            lineBreak:"anywhere"}}>
                                {item.linkSN}</div>
                            <div style={{padding:"5px", display:"flex",flexFlow:"row wrap", justifyContent:"center", alignItems:"center",
                            }}> Comptes:
                                {item.accounts.length===0 && "Aucun compte"}
                                {item.accounts.map((account:string)=><div style={{padding:"5px"}}>{account}</div>)}</div>
                            <button onClick={() => {
                            setParameters((ps: any) => ({
                                ...ps,
                                kuboSNv2: ps.kuboSNv2.filter((txt: string) => txt !== item)
                            }))
                        }}>
                            Retirer
                        </button>
                        </div>)
                    }
                    <Spaces n={0}/>
                    Liens versions V1
                    {
                        parameters.kuboSN?.map((item: string) => <div style={{
                            color:"grey",
                            // backgroundColor: "white",
                            width: "90%",
                            padding: "5px 20px",
                            margin: "5px auto",
                        }} className={"sn"}>
                            {item}
                        </div>)
                    }

                    <input style={{
                        width: "25%",
                        margin: "10px auto",
                        textAlign: "left",
                        borderRadius: '20px',
                        padding: "5px, 10px"
                    }} type={"date"} value={kuboSNv2.launchDate} onChange={(e) => {
                        const newLaunchDate=e.target.value;
                        setKuboSNv2((ps:any) => ({
                            ...ps,
                            launchDate: newLaunchDate,
                        }));
                    }} />

                    <input style={{
                        width: "40%",
                        margin: "10px auto",
                        textAlign: "left",
                        borderRadius: '20px',
                        padding: "5px, 10px"
                    }} type={"text"} placeholder={"Ici votre lien"} value={kuboSNv2.linkSN} onChange={(e) => {
                        const newLinkSn= e.target.value;
                        setKuboSNv2((ps:any) => ({
                            ...ps,
                            linkSN: newLinkSn,
                        }));
                    }} />

                    {kuboSNv2.accounts.map((account:string)=><div style={{padding:"2px"}}>{account}</div>)}
                    <input style={{
                        width: "20%",
                        margin: "10px auto",
                        textAlign: "left",
                        borderRadius: '20px',
                        padding: "5px, 10px"
                    }} type={"text"} placeholder={"compte relie"} value={account} onChange={(e) => {
                       setAccount(e.target.value);
                    }} />
                    <button onClick={ () =>{
                        setKuboSNv2((ps:any) => ({
                            ...ps,
                            accounts: [...ps.accounts,
                                account] ,
                        }));
                        setAccount("");
                    }}> + </button>


                    <br/>
                    <button disabled={!kuboSNv2} onClick={() => {
                        const newKuboSN = kuboSNv2;
                        setParameters((ps) => ({
                            ...ps,
                            kuboSNv2: [...ps.kuboSNv2, newKuboSN]
                        }));
                        setKuboSNv2({linkSN:"",accounts:[], launchDate:"", status:""});
                    }}>Ajouter</button>
                </div>

                <div className={"window__block"}>
                    <h2>Message de connexion</h2>
                    <Spaces n={0}/>
                    <div>Ce message sera transmis aux candidats sur linkedin.</div>
                    <Spaces n={0}/>
                    <textarea value={parameters.kuboMessage} style={{
                        width: '90%',
                        borderRadius: '20px',
                        height: '100px',
                        textAlign: 'left'
                    }} onChange={(e) => {
                        const m = e.target.value;
                        if (m.length <= 300) {
                            setParameters((ps: any) => ({
                                ...ps,
                                kuboMessage: m
                            }));
                        }
                    }}>
                    </textarea>
                    <div> {parameters.kuboMessage.length}/300 </div>
                </div>

                <div className={"window__block"}>
                    <h2>Status</h2>
                    <div>'Todo' correspond aux recherches prêtes à étre lancées. 'Done' correspond aux recherches qui sont prêtes à être récoltées sur linkedin.</div>
                    <select value={parameters.kuboStatus} onChange={(e) => {
                        const s = e.target.value;
                        setParameters((ps) => ({
                            ...ps,
                            kuboStatus: s
                        }))
                    }}>
                        <option value={""}>- - -</option>
                        <option value={"todo"}>Todo</option>
                        <option value={"done"}>Done</option>
                        <option value={"canceled"}>Annulé</option>
                    </select>
                </div>

                <div className={"window__block"}>
                    <h2>Notes</h2>
                    <div>Notes internes (exemple: liste des comptes LinkedIn sur lesquels les kubo ont travaillée).</div>
                    <Spaces n={0}/>
                    <textarea value={parameters.kuboNotes} style={{
                        width: '90%',
                        borderRadius: '20px',
                        height: '100px',
                        textAlign: 'left'
                    }} onChange={(e) => {
                        const m = e.target.value;
                            setParameters((ps: any) => ({
                                ...ps,
                                kuboNotes: m
                            }));
                    }}>
                    </textarea>
                </div>

                <div className={"window__block"}>
                    <button className={"btn-main"} onClick={async() => {
                        try {

                            const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/update/mission`, {
                                missionId: item.missionId,
                                kuboSN: parameters.kuboSN ?? [],
                                kuboSNv2: parameters.kuboSNv2 ?? [],
                                kuboMessage: parameters.kuboMessage ?? '',
                                kuboStatus: parameters.kuboStatus ?? '',
                                kuboNotes: parameters.kuboNotes ?? ''
                            }, {withCredentials: true});

                            // Update front
                            updateFront(item.missionId, {kuboSN: parameters.kuboSN})
                            updateFront(item.missionId, {kuboSNv2: parameters.kuboSNv2})
                            updateFront(item.missionId, {kuboMessage: parameters.kuboMessage})
                            updateFront(item.missionId, {kuboStatus: parameters.kuboStatus})
                            updateFront(item.missionId, {kuboNotes: parameters.kuboNotes})


                            notification(appContextV2.dispatchPanels, "Mise a jour KUBO", "success");


                        } catch(error) {
                            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                        }
                    }}>
                        Sauvegarder
                    </button>
                </div>

                <Spaces n={0}/>
            </div>
        </>
    </WindowWrapper>)

}