import React, {useEffect, useState} from "react";
import PageLayout from "../../../layouts/PageLayout";
import {Route, Switch} from "react-router-dom";
import FourHundredFour from "../../errors/FourHundredFour";
import BusinessPageProfile from "./componentsBusinessPage/old/BusinessPageProfile";
import BusinessPageJobs from "./componentsBusinessPage/old/BusinessPageJobs";
import queryString from 'query-string'
import BusinessPageApplyV3 from "./componentsBusinessPage/BusinessPageApplyV3";
import BusinessPageProfileV3 from "./componentsBusinessPage/BusinessPageProfileV3";
import BusinessPageJobsV3 from "./componentsBusinessPage/BusinessPageJobsV3";
import {devLog} from "../../../utils/devLog/devLog";
import {BusinessPageHeaderV3} from "./componentsBusinessPage/BusinessPageHeaderV3";
import {BusinessPageNavBarV3} from "./componentsBusinessPage/BusinessPageNavBarV3";
import fetchMissionPublicProfile from "./componentsBusinessPage/dependencies/fetchMissionPublicProfile";
import fetchBusinessPublicProfile from "./componentsBusinessPage/dependencies/fetchBusinessPublicProfile";
import processBusinessPageStatus from "./componentsBusinessPage/dependencies/processBusinessPageStatus";
import getUri from "./componentsBusinessPage/dependencies/getUri";
import BusinessPageApplyV3Success from "./componentsBusinessPage/BusinessPageApplyV3Success";

export {BusinessPage};

/**
 * --------------------------------------------
 * Page that handles business page
 * Primary-level page
 * --------------------------------------------
 */
const BusinessPage = (props: any) => {
  
  /** Gets data from the url */
  const [pageInfos, setPageInfo] = useState({
    pageType: (!!props.match.params.rank) ? "apply" : "profile",
    slugname: props.match.params.slugname,
    slugnumber: props.match.params.slugnumber,
    rank: props.match.params.rank,
    webOrigin: queryString.parse(props.location.search).o as string, // origin tracker
    webAmbassador: queryString.parse(props.location.search).a as string, // ambassador tracker
    webFromEmail: queryString.parse(props.location.search).f as string, // coming back from indeed tracker
    kid: queryString.parse(props.location.search).kid
  });

  /** Defines the fetchers and fetching status */
  const [business, setBusiness] = useState({});
  const [mission, setMission] = useState({});
  const [businessFetchStatus, setBusinessFetchStatus] = useState("asleep");
  const [missionFetchStatus, setMissionFetchStatus] = useState("asleep");
  const [pageStatus, setPageStatus]: ['asleep' | 'fetching' | 'fetched' | 'forbidden' | 'error', Function] = useState("asleep");
  
  /** Fetch business profile */
  useEffect(() => {fetchBusinessPublicProfile(pageInfos, setBusinessFetchStatus, setBusiness)}, []);
  devLog("business", business);
  
  /** Fetch specific mission if pertinent */
  useEffect(() => {fetchMissionPublicProfile(pageInfos, setMissionFetchStatus, setMission)}, []);
  devLog("mission", mission);
  
  /** Sets page status according to the type of page */
  useEffect(() => {processBusinessPageStatus(pageInfos, businessFetchStatus, missionFetchStatus, setPageStatus)},
    [businessFetchStatus, missionFetchStatus]);
  
  /** Get uri of current page */
  let uri = getUri(pageInfos);
  
  const assistantContext = {
    business: business,
    mission: mission,
    pageInfos: {
      ...pageInfos,
      uri,
    },
  };
  
  return (<PageLayout status={pageStatus} fullWidth={true} grey={false} page={props.page}>
    
    {/* -- Header -- */}
    <BusinessPageHeaderV3 {...assistantContext} />
    
    {/* -- NavBar -- */}
    <BusinessPageNavBarV3 {...assistantContext} />

    {/* -- Content -- */}
    <Switch>
      
      <Route exact path={`${uri}`}
             render={(componentProps) => <BusinessPageProfileV3 {...assistantContext} {...componentProps} subPage={"BusinessPageProfile"}/>}/>
      
      {/* -- ------------------------------------------------ Business profile -- */}
      <Route exact path={`${uri}/profile`}
             render={(componentProps) => <BusinessPageProfileV3 {...assistantContext} {...componentProps} subPage={"BusinessPageProfile"}/>}/>
      
       -- ------------------------------------------------ Apply
      <Route exact path={`${uri}/apply`}
             render={(componentProps) => <BusinessPageApplyV3 {...assistantContext} {...componentProps} subPage={"BusinessPageProfile"}/>}/>


      {/*/!* -- ------------------------------------------------ Apply *!/*/}
      <Route exact path={`${uri}/apply/success`}
             render={(componentProps) => <BusinessPageApplyV3Success {...assistantContext} {...componentProps} subPage={"BusinessPageProfile"}/>}/>
      
      {/* -- ------------------------------------------------ All opened positions -- */}
      <Route exact path={`${uri}/jobs`}
             render={(componentProps) => <BusinessPageJobsV3 {...assistantContext} {...componentProps} subPage={"BusinessPageJobs"}/>}/>
      
      {/* 404 ------------------------------------------------- */}
      <Route render={(componentProps) => <FourHundredFour {...componentProps} page="FourHundredFour" fullPage={false} setPage={false}/>}/>
    
    </Switch>
  
  </PageLayout>);
}
