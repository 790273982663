import AppContext, {IAppV2Context} from "../../../../../../App/context/AppContext";
import React, {useContext, useState} from "react";
import Spaces from "../../../../../Spaces";

export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const [languageCode, setLanguageCode]: any = useState();
    const [speak, setSpeak]: any = useState();
    const [read, setRead]: any = useState();
    const [write, setWrite]: any = useState();

    return (<>
        <div className={"black-belt-window__container--mini"}>
            Languages:
            <br/><br/>

            {props.infos.languages.map((item: any,key:number) => (<>
                <div></div>
                <div><span className={"black-belt-window__title"}>Language Code:</span>
                    <input className={"black-belt-window__input"} value={item.languageCode}
                           onChange={(e) => {
                               props.dispatchInfos({
                                   type: "UPDATE_ARRAY",
                                   value: {field:"languages",index:key, subfield:"languageCode" ,value: e.target.value}
                               })
                           }}/>
                </div>

                <div><span className={"black-belt-window__title"}>Parler:</span>
                    <input type={"checkbox"} className={"black-belt-window__input"} checked={item.speak}
                           onClick={(e) => {
                               if(item.speak) {
                                   props.dispatchInfos({
                                       type: "UPDATE_ARRAY",
                                       value: {field: "languages", index: key, subfield: "speak", value: false}
                                   })
                               }
                               else{
                                   props.dispatchInfos({
                                       type: "UPDATE_ARRAY",
                                       value: {field: "languages", index: key, subfield: "speak", value: true}
                                   })
                               }
                           }}/>
                </div>

                <div><span className={"black-belt-window__title"}>Lire:</span>
                    <input type={"checkbox"} className={"black-belt-window__input"} checked={item.read}
                           onClick={(e) => {
                               if(item.read) {
                                   props.dispatchInfos({
                                       type: "UPDATE_ARRAY",
                                       value: {field: "languages", index: key, subfield: "read", value: false}
                                   })
                               }
                               else{
                                   props.dispatchInfos({
                                       type: "UPDATE_ARRAY",
                                       value: {field: "languages", index: key, subfield: "read", value: true}
                                   })
                               }
                           }}/>
                </div>

                <div><span className={"black-belt-window__title"}>Ecrire:</span>
                    <input type={"checkbox"} className={"black-belt-window__input"} checked={item.write}
                           onClick={(e) => {
                               if(item.write) {
                                   props.dispatchInfos({
                                       type: "UPDATE_ARRAY",
                                       value: {field: "languages", index: key, subfield: "write", value: false}
                                   })
                               }
                               else{
                                   props.dispatchInfos({
                                       type: "UPDATE_ARRAY",
                                       value: {field: "languages", index: key, subfield: "write", value: true}
                                   })
                               }
                           }}/>
                </div>

                <Spaces n={1}/>
                <button  className={"message--v2__chat__button"} onClick={()=>{
                    props.dispatchInfos({
                        type: "REMOVE_ELEMENT_FROM_ARRAY",
                        value: {field:"languages",index:key}
                    })
                }}> Supprimer </button>
                <Spaces n={1}/>

            </>))}

            <div><span className={"black-belt-window__title"}>Language Code:</span>
                <input className={"black-belt-window__input"} value={languageCode}
                       onChange={(e) => {
                          setLanguageCode(e.target.value)
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Parler:</span>
                <input type={"checkbox"} className={"black-belt-window__input"} checked={speak}
                       onClick={(e) => {
                           if(speak) {
                              setSpeak(false)
                           }
                           else{
                              setSpeak(true)
                           }
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Lire:</span>
                <input type={"checkbox"} className={"black-belt-window__input"} checked={read}
                       onClick={(e) => {
                           if(read) {
                               setRead(true)
                           }
                           else{
                               setRead(false)
                           }
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Ecrire:</span>
                <input type={"checkbox"} className={"black-belt-window__input"} checked={write}
                       onClick={(e) => {
                           if(write) {
                              setWrite(true)
                           }
                           else{
                               setWrite(false)
                           }
                       }}/>
            </div>
            <Spaces n={1}/>

            <button  className={"message--v2__chat__button"} onClick={()=>{
                props.dispatchInfos({
                    type: "ADD_ELEMENT_TO_ARRAY",
                    value: {field:"languages",
                        value:{
                            languageCode:languageCode,
                            speak:speak,
                            read:read,
                            write:write,
                        }}
                })
                setLanguageCode("");
                setSpeak(false);
                setRead(false);
                setWrite(false);
            }}> Ajouter </button>
            <Spaces n={1}/>
        </div>


    </>);
}