import React, {useContext, useEffect, useState} from "react";
import {devLog} from "../../../../utils/devLog/devLog";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import Spaces from "../../../../components/sharedComponents/Spaces";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import getEditorPresets from "../../componentsRecruiterNewMission/dependencies/getEditorPresets";
import MissionFormEditorV2 from "../../recruiterSharedFormEditor/MissionFormEditorV2";
import {useForm} from "use-formidable";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Handles mission */
  const [mission, updateMission, formidable] = useForm({});
  const [fetchMissionStatus, setFetchMissionStatus]: ["asleep" | "fetching" | "fetched" | "error", Function] = useState("asleep");
  
  devLog("mission", mission);
  
  /** Handles presets */
  const [presetStatus, setPresetStatus] = useState("asleep");
  const [presets, setPresets, formidablePresets] = useForm({
    default: [],
    private: [],
    classical: [],
    technical: [],
  });
  devLog("presets", presets);
  
  /** Displays a loader for user actions (save) */
  const [isPageLoading, setPageLoading] = useState(false);
  
  /** Fetch the templates from the 3 categories */
  useEffect(() => {
    (async() => {
      const presets: any = await getEditorPresets(setPresetStatus, setPresets, props.mission?.positionWanted);
      // Sets default
      if(!!presets?.default) {
        formidablePresets.setForm(presets);
      }
    })();
  }, []);
  
  /** Fetch mission */
  useEffect(() => {
    (async() => {
      try {
        setFetchMissionStatus("fetching");
        const missionId = props.match.params.missionid;
        const fetched = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/mission`, {
          headers: {
            missionid: missionId
          },
          withCredentials: true
        }));
        
        if(fetched.data.status !== "ok") {
          return setFetchMissionStatus("error");
        }
  
        formidable.setForm(fetched.data.data);
        setFetchMissionStatus("fetched");
        
      } catch (error) {
        devLog(error);
        setFetchMissionStatus("error");
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    })();
  }, []);
  
  /** Strategy to update a mission */
  const updateM = async(documentUrl: string) => {
    try {
      setPageLoading(true);
  
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/update/mission`, {
        ...mission,
        missionId: mission._id,
        documentUrl: documentUrl,
      }, {withCredentials: true});
      
      if(fetched.data.status !== "ok") {
        setPageLoading(false);
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        return 'error';
      }
  
      notification(appContextV2.dispatchPanels, "Sauvegarde crée avec succès", "success");
      setPageLoading(false);
      return 'ok';
      
    } catch(error) {
      devLog(error);
      setPageLoading(false);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  return(<SubPageLayout status={fetchMissionStatus} subPage={props.subPage}>
    
    <Spaces n={2}/>
    
    <div className={"hide-on-smartphones"}>
      <MissionFormEditorV2 editorMode={"edition"}
                           isPageLoading={isPageLoading}
                           presets={presets}
                           form={mission}
                           setForm={updateMission}
                           timeStamps={{}}
                           wizard={{}}
                           setMissionTokens={()=>{}}
                           saveCallback={updateM}
                           setPresets={setPresets}
      />
    </div>
  
  </SubPageLayout>);
}