import moveFromIndexToIndex from "../../../../../utils/moveFromIndexToIndex";

type TState =string[];

export default (previousState: TState, action: { type: string, value: any }): any => {

    switch(action.type) {

        case 'SELECT_DATA':
            return ([
                ...previousState,
                action.value
            ]);

        case 'DESELECT_DATA':
            return ([
                ...previousState.filter((candidateId: any) => (candidateId !== action.value))
            ]);

        case 'DESELECT_ALL_DATA':
            return ([]);

        case 'SELECT_ALL_DATA':
            const allCandidatesId=action.value.map((shortlist:any)=>(shortlist.candidateId));
            return([
                ...allCandidatesId,
                ]);

        case 'SELECT_ALL_BLOCKED':
            const allBlockedCandidatesId=action.value.filter((item:any)=>(item.blockedByAlgorithm===true && item.kanbanColumn==="1")).map((shortlist:any)=>(shortlist.candidateId));
            return([
                ...allBlockedCandidatesId,
            ]);

        case 'SELECT_ALL_GRADES_LOWER_50':
            const allCandidatesIdWithGradeLower50=action.value.filter((item:any)=>(item.markGlobal<50 && item.kanbanColumn==="1")).map((shortlist:any)=>(shortlist.candidateId));
            return([
                ...allCandidatesIdWithGradeLower50,
            ]);

        case 'SELECT_ALL_GRADES_LOWER_70':
            const allCandidatesIdWithGradeLower70=action.value.filter((item:any)=>(item.markGlobal<70 && item.kanbanColumn==="1")).map((shortlist:any)=>(shortlist.candidateId));
            return([
                ...allCandidatesIdWithGradeLower70,
            ]);
        case 'SELECT_ALL_GRADES_LOWER_90':
            const allCandidatesIdWithGradeLower90=action.value.filter((item:any)=>(item.markGlobal<90 && item.kanbanColumn==="1")).map((shortlist:any)=>(shortlist.candidateId));
            return([
                ...allCandidatesIdWithGradeLower90,
            ]);
    
        case 'SELECT_ALL_DATA_INVERT':
            const invertedList = [];
            for(let candidate of action.value.shortlist) {
                if(!action.value.selected.includes(candidate.candidateId)) {
                    invertedList.push(candidate.candidateId);
                }
            }
            return(invertedList);
    
        case 'SELECT_ALL_DATA_NEW':
            return action.value.filter((item:any)=>(item.kanbanColumn === "1")).map((shortlist:any)=>(shortlist.candidateId));
    
        default:
            return previousState;
    }

}