import React from "react";

export {RecruiterProfilesBusinessAccount};

const RecruiterProfilesBusinessAccount = (props: {
  assistantGet: any,
  profile: any,
  updateSingleton: Function,
}) => {
  
  return (<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Données du compte</h1>
    <div>
      Nom de l'entreprise: {props.profile.name?.value}
    </div>
  </div>);
  
}