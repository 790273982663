import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";
import Spaces from "../../../Spaces";
import {withRouter} from "react-router-dom";
import {isEmailValid} from "../../../../../utils/typeGuards";

const WindowLoginPassword = (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");


    const connect = async () => {
        try {
            const {
                status,
                data
            } = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/session/connect/with/password`, {
                email: email,
                password: password,
            }, {
                withCredentials: true,
            })).data;

            if (status === 'ok') {
                props.history.push('/');
                window.location.reload();
            }

            if (status === 'noUser' || status === 'blocked') {
                return props.windowContext.wizard.goToStep("noUser");
            }

            if(status === 'noValidPassword' ){
                return props.windowContext.wizard.goToStep("noValidPassword");

            }
            if( status === 'blocked'){
                return props.windowContext.wizard.goToStep("error");
            }


        } catch (error) {
            devLog(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            return props.windowContext.wizard.goToStep("error");
        }
    }




    return (<>

            <p className={"assistant-layout__title"}>
                Entre ton email et ton mot de passe
            </p>
            <Spaces n={0}/>

            <div className={"assistant-layout__min-height"}>
                <article className={`assistant-layout--column assistant-layout--animated`}>
                    <div className={"div-center"}>
                        <input value={email} type={"text"} placeholder={"Email"}
                               className={`input--v2`}
                               onChange={(e) => {
                                   setEmail(e.target.value)
                               }}/>
                    </div>
                    {/*<Spaces n={0}/>*/}
                    <div className={"div-center"}>
                        <input value={password} type={"password"} placeholder={"Mot de passe"}
                               className={`input--v2`}
                               onChange={(e) => {
                                   setPassword(e.target.value)
                               }}/>
                    </div>
                </article>
            </div>

            <Spaces n={0}/>

            <div className={"assistant-layout__comment"}>
                <div className={"text--center"}>
                    <button className={"btn-main"} disabled={!(isEmailValid(email) && !!password)} onClick={async () => {
                        connect();
                    }}>
                        Se connecter
                    </button>
                </div>
            </div>

            <Spaces n={0}/>
            <div className={"div-center"}>
                <button className={" modern-link"} onClick={() => {
                    return props.windowContext.wizard.goToStep("newPassword")
                }}>
                    J'ai oublié mon mot de passe
                </button>
            </div>

            <Spaces n={0}/>
            <div className={"div-center"}>
                <button className={" modern-link"} onClick={() => {
                            return props.windowContext.wizard.goToStep("enterEmail")
                }}>
                    Connexion par mail
                </button>
            </div>
        </>
    );
}
export default withRouter (WindowLoginPassword);
