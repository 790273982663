import React from "react";
import BusinessPageProfileLinksV2 from "./old/BusinessPageProfileLinksV2";
import {capitalizeMulti} from "../../../../utils/converters";

export {BusinessPageHeaderV3}

const BusinessPageHeaderV3 = (props: any) => {
  
  const isBanDefined: boolean = !!props.business.publicBanUrl;
  
  return(<>
  
    <div className={"business-page__header"}>
      
      {isBanDefined && <img src={props.business.publicBanUrl} className={"business-page__header__picture"} />}
      
      {/*------------------------------------------- PCs view -----------------------------------*/}
      <section className={"business-page__header__content hide-on-smartphones"}>
        {
          !!props.business?.businessAvatar && <div>
            <img alt={"avatar"} src={props.business?.businessAvatar} className={"business-page__header__content__avatar"} />
          </div>
        }
        <div className={"business-page__header__content__text"}>
          <BusinessPageProfileLinksV2 businessProfile={props.business} />
          <div className={`business-page__header__content__text__title ${!isBanDefined ? "text--black":""}`}>
            {props.business?.businessName}
          </div>
          <div className={`business-page__header__content__text__detail ${!isBanDefined ? "text--black":"" }`}>
            {!!props.business?.isRecruitingAgency && <>Entité de recrutement, </>}
            {!!props.business?.sector && <><img src={"https://static.myshortlist.co/icon-tag.svg"} className={`business-page__small-icon ${isBanDefined ? "business-page__small-icon--inverted":""}`} /> {props.business?.sector}, </> }
            {<><img src={"https://static.myshortlist.co/icon-earth.svg"} className={`business-page__small-icon ${isBanDefined ? "business-page__small-icon--inverted":""}`} /> {props.business?.city} {capitalizeMulti(props.business?.country)}</>}
            {!!props.business.businessPageMention && <><img src={"https://static.myshortlist.co/btn-edit.svg"} className={`business-page__small-icon ${isBanDefined ? "business-page__small-icon--inverted":""}`} /> {props.business.businessPageMention}</>}
          </div>
        </div>
      </section>
      
      {/*------------------------------------------- Smartphones view -----------------------------------*/}
      <section className={"business-page__header--smartphones__content hide-on-computers"}>
        {
          !!props.business?.businessAvatar && <div>
            <img alt={"avatar"} src={props.business?.businessAvatar} className={"business-page__header--smartphones__content__avatar"} />
          </div>
        }
        <div className={"business-page__header--smartphones__content__title"}>
          {props.business?.businessName}
        </div>
        <div className={`business-page__header__content__text__detail ${!isBanDefined ? "text--black":"" }`}>
          {!!props.business?.isRecruitingAgency && <>Entité de recrutement, </>}
          {!!props.business?.sector && <><img src={"https://static.myshortlist.co/icon-tag.svg"} className={`business-page__small-icon ${isBanDefined ? "business-page__small-icon--inverted":""}`} /> {props.business?.sector}, </> }
          {<><img src={"https://static.myshortlist.co/icon-earth.svg"} className={`business-page__small-icon ${isBanDefined ? "business-page__small-icon--inverted":""}`} /> {props.business?.city} {capitalizeMulti(props.business?.country)}</>}
          {!!props.business.businessPageMention && <><img src={"https://static.myshortlist.co/btn-edit.svg"} className={`business-page__small-icon ${isBanDefined ? "business-page__small-icon--inverted":""}`} /> {props.business.businessPageMention}</>}
        </div>
        
      </section>
  
    </div>
    
  </>);
}