import NavBarLogoContent from "../NavBarLogoContent";
import React from "react";
import {SubMenuRecruiterProfilesContent} from "../../SubNavbarComponents/SubMenuRecruiterProfilesContent";

export function SubMenuRecruiterProfiles(props: any) {
  return (<>
    <div className={'hide-on-computers'}>
      <NavBarLogoContent/>
    </div>
    <ul className={"subnavbar-new hide-on-smartphones animation-straight-fade-in"}>
      <SubMenuRecruiterProfilesContent/>
    </ul>
  </>);
}

