import React, {useState} from 'react'
import ExperienceEditorNewExp from "./ExperienceEditorNewExp";
import ExperienceEditorVisualizeExp from "./ExperienceEditorVisualizeExp";
import ExperienceEditorEditExp from "./ExperienceEditorEditExp";


export default function ExperienceEditor(props: {
    value: any[];
    update: Function;
}) {
    const [editor, setEditor] = useState(false);
    const [newMission, setNewMission] = useState("");
    const [newKey, setNewKey] = useState("");

    const [editKey, setEditKey] = useState(-1);

    const defaultExperience = {
        company: "",
        position: "",
        companyDescription: "",
        candidateMissions: [],
        keys: []
    };

    const [currentExperience, setCurrentExperience] = useState(defaultExperience);

    return (<>
        {
            props.value.map((item, key) => {
                if (editKey === -1 || editKey !== key) {
                    return <ExperienceEditorVisualizeExp setEditKey={setEditKey}
                                                         setCurrentExperience={setCurrentExperience}
                                                         item={item} update={props.update} index={key}
                                                         editKey={editKey}
                                                         key={key}/>
                }

                return <ExperienceEditorEditExp setEditKey={setEditKey}
                                                setCurrentExperience={setCurrentExperience}
                                                item={item} update={props.update} index={key}
                                                key={key} setNewMission={setNewMission}
                                                newMission={newMission} setNewKey={setNewKey} newKey={newKey} defaultExperience={defaultExperience} currentExperience={currentExperience}/>

            })
        }

        {!editor && <button className={"profile2-btn-add"} onClick={() => setEditor(true)}>+</button>}
        {editor && <ExperienceEditorNewExp currentExperience={currentExperience} setEditor={setEditor}
                                           defaultExperience={defaultExperience}
                                           setCurrentExperience={setCurrentExperience} newKey={newKey}
                                           setNewKey={setNewKey} newMission={newMission} setNewMission={setNewMission}
                                           update={props.update}/>}
    </>);
}