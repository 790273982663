import React, {useContext, useEffect, useReducer, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {devLog} from "../../../../utils/devLog/devLog";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import dataReducerMission from "./dependenciesRecruiterMissionInfos/dataReducerMission";


export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
    const missionId = props.match.params.missionid;
    const [fetchMissionStatus, setFetchMissionStatus]: ["asleep" | "fetching" | "fetched" | "error", Function] = useState("asleep");
    const [mission, dispatchMission]: [any, Function] = useReducer(dataReducerMission, {});
    const url = `https://www.myshortlist.co/business/mission/${missionId}/kubo`;

    console.log("mission", mission);

    useEffect(() => {
        (async () => {
            try {
                setFetchMissionStatus("fetching");
                const missionId = props.match.params.missionid;
                const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/mission`, {
                    headers: {
                        missionid: missionId
                    },
                    withCredentials: true
                })).data;


                if (status !== "ok") {
                    return setFetchMissionStatus("error");
                }


                dispatchMission({type: "INITIALIZE_DATA", value: data});
                setFetchMissionStatus("fetched");

            } catch (error) {
                devLog(error);
                setFetchMissionStatus("error");
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        })();
    }, []);


    return (<SubPageLayout status={"ok"} subPage={props.subPage}>

        <div className={"container"}>
            <Spaces n={2}/>

            <h1 className={"page-title page-title--smaller"}> Lancer des Kubos </h1>
            <Spaces n={0}/>

            <div className={"window__block "}>
                <div className={"text--center"}>La référence du poste est {mission.reference}! Utilisez cette fenêtre
                    pour enregistrer les recherches à effectuer via Kubo.
                </div>
                <Spaces n={1}/>
                <h2 className={"text--center"}>Message de connexion</h2>
                <div className={"text--center"}>Ce message sera envoyé aux candidats sur linkedin.</div>
                <div className={"div--center"}>
                    <textarea value={mission.kuboMessage} style={{
                        width: '90%',
                        borderRadius: '20px',
                        height: '100px',
                        textAlign: 'left'
                    }} onChange={(e) => {
                        const m = e.target.value;
                        if (m.length <= 300) {
                            dispatchMission({type: "UPDATE_INFOS", value: {kuboMessage: m}})
                        }
                    }}>
                    </textarea>
                    <div className={"text--center"}> {mission.kuboMessage?.length}/300</div>


                {/*    <Spaces n={1}/>*/}
                {/*    <h2>Statut</h2>*/}
                {/*    <Spaces n={0}/>*/}
                {/*    <div className={"text--center"}>'Todo' correspond aux recherches prêtes à étre lancées. 'Done' correspond aux recherches qui sont prêtes à être récoltées sur linkedin.</div>*/}
                {/*    <select value={mission.kuboStatus} onChange={(e) => {*/}
                {/*        const s = e.target.value;*/}
                {/*        dispatchMission({type: "UPDATE_INFOS", value: {kuboStatus: s}})*/}
                {/*    }}>*/}
                {/*        <option value={""}>- Statut -</option>*/}
                {/*        <option value={"todo"}>Todo</option>*/}
                {/*        <option value={"done"}>Done</option>*/}
                {/*        <option value={"canceled"}>Annulé</option>*/}
                {/*    </select>*/}
                </div>
                <Spaces n={0}/>
                <div className={"div--center"}>
                    <button className={"btn-main "} onClick={async () => {
                        try {

                            const {
                                status,
                                data
                            } = await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/update/mission`, {
                                    missionId: missionId,
                                    kuboMessage: mission.kuboMessage ?? '',
                                    // kuboStatus:mission.kuboStatus ?? '',
                                },
                                {withCredentials: true});

                            notification(appContextV2.dispatchPanels, "Mise a jour KUBO", "success");


                        } catch (error) {
                            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                        }
                    }}>
                        Sauvegarder
                    </button>
                </div>

            </div>
                <div className={"window__block "}>

                <h2 className={"text--center"}>Lien de la page à mettre dans le tableau des kubos</h2>
                    <Spaces n={0}/>
                <div className={"div--center"}>
                    <button className={"btn-main"} onClick={() => {
                        let copyArea = document.getElementById("signature");
                        copyArea!.innerHTML = `<a style="color: blue; text-decoration: underline;" href="https://www.myshortlist.co/business/mission/${missionId}/kubo">
                        ${mission.positionWanted}</a>`
                        copyArea!.focus();
                        document.execCommand("selectAll");
                        document.execCommand("copy");
                        notification(appContextV2.dispatchPanels, "Lien copié", "success");
                    }}>Copier le lien formaté
                    </button>
                </div>
                <div className={" window-candidate-share__div-invisible"} id={"signature"} contentEditable={"true"}>a
                </div>


            </div>

        </div>





</SubPageLayout>
)
    ;
}

