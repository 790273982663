import React, {useContext, useEffect} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import Spaces from "../../../../components/sharedComponents/Spaces";
import BusinessPageDescription from "./componentsBusinessPageProfileV3/BusinessPageDescription";
import {ElementMediaV2} from "./componentsBusinessPageProfileV3/ElementMediaV2";
import {capitalizeSentence} from "../../../../utils/converters";
import getContract from "../../../../utils/getContract";
import getExperience from "../../../../utils/getExperience";
import getStarting from "../../../../utils/getStarting";
import {Link} from "react-router-dom";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<SubPageLayout status={"ok"} subPage={props.subPage}>
    
    <Spaces n={2}/>
    
    <div className={"business-page__grid--v2"}>
      
      <div className={"business-page__grid--v2__description"}>
        <BusinessPageDescription {...props}/>
      </div>
      
      <div className={"business-page__grid--v2__content"}>
        
        {props.pageInfos.pageType === "apply" && <div className={"business-page__element__apply"}>
          
          {!!props.mission.error && <>
            <div className={"business-page__element__apply__title"}>
            Désolé !
          </div>
            <div className={"business-page__element__apply__subtitle"}>
              Il semble que l'annonce n'existe pas, plus, ou n'a pas encore été publiée.
            </div>
            </>}
          
          {!props.mission.error && <>
            <div className={"business-page__element__apply__title"}>
              {capitalizeSentence(props.mission?.positionWanted)} H/F
            </div>
            <div className={"business-page__element__apply__subtitle"}>
              {getContract(props.mission?.contract)}, Expérience souhaitée de: {getExperience(props.mission?.yearsOfExperience)},
              <br/>
              {getStarting(props.mission.from, props.mission.asSoonAsPossible, props.mission.duration)}
            </div>
            <div className={"business-page__element__apply__link"}>
              <Link to={`/apply/${props.pageInfos.slugname}/${props.pageInfos.slugnumber}/${props.pageInfos.rank}/apply`}>
                <button className={"btn-main"}>
                  Voir les questions du recruteur
                </button>
              </Link>
            </div>
            <div>
              L'inscription n'est obligatoire pour postuler.
            </div>
          </>}

        </div>}
        
        {/*-------------------------    HEADER (3 first pictures)    ---------------------------------- */}
        
        <div className={"business-page__grid--v2__content__header"}>
          <div className={"business-page__element--2--2"}>
            <img className={'business-page__element__image'} src={props.business?.media1?.url} alt={"main-pic"}/>
          </div>
          
          <div className={"business-page__side-elements"}>
            
            <div className={"business-page__element--1--1"}>
              {
                !!props.business.media1?.url &&
                <img className={'business-page__element__image'} src={props.business.media2?.url} alt={"main-pic"}/>
              }
            </div>
            
            <div className={"business-page__element--1--1"}>
              {
                !!props.business.media1?.url &&
                <img className={'business-page__element__image'} src={props.business.media3?.url} alt={"main-pic"}/>
              }
            </div>
          
          </div>
        </div>
        
        {/*-------------------------    COLUMNS    ---------------------------------- */}
        
        <div className={"business-page__grid--v2__content__gallery"}>
          
          {/*-------------------------    COLUMN 1    ---------------------------------- */}
          
          <section className={"business-page__grid--v2__content__gallery__column"}>
            {
              props.business.mediasCol1?.length === 0 && <div className={"business-page__element--1--1"}>
              </div>
            }
            {
              props.business.mediasCol1?.map((item: any, key: number) => (<>
                <ElementMediaV2 key={key} item={item}/>
              </>))
            }
            <div className={"business-page__element__last-block"}>
              <div className={"business-page__element__last-block__white"}>
              </div>
            </div>
          </section>
          
          {/*/!*-------------------------    COLUMN 2    ---------------------------------- *!/*/}
          
          <section className={"business-page__grid--v2__content__gallery__column"}>
            {
              props.business.mediasCol2?.length === 0 && <div className={"business-page__element--1--1"}>
              </div>
            }
            {
              props.business.mediasCol2?.map((item: any, key: number) => (<>
                <ElementMediaV2 key={key} item={item}/>
              </>))
            }
            <div className={"business-page__element__last-block"}>
              <div className={"business-page__element__last-block__white"}>
              </div>
            </div>
          
          </section>
          
          {/*/!*-------------------------    COLUMN 3    ---------------------------------- *!/*/}
          
          <section className={"business-page__grid--v2__content__gallery__column"}>
            {
              props.business.mediasCol3?.length === 0 && <div className={"business-page__element--1--1"}>
              </div>
            }
            {
              props.business.mediasCol3?.map((item: any, key: number) => (<>
                <ElementMediaV2 key={key} item={item}/>
              </>))
            }
            <div className={"business-page__element__last-block"}>
              <div className={"business-page__element__last-block__white"}>
              </div>
            </div>
          </section>
        
        </div>
      
      </div>
    
    </div>
  
  </SubPageLayout>);
}