import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../components/sharedComponents/Buttons/ButtonBack";
import tickJob from "../dependencies/tickJob";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Delay page showing if this step must be jumped */
  const [display, setDisplay] = useState(false);
  
  useEffect(() => {
    const arePrivateJobsSet: boolean = !!props.jobs?.private && props.jobs?.private?.length !== 0;
    if(!arePrivateJobsSet) {
      // Trick used here instead of using props.wizard.nextStep(), so the "back" buttons works
      props.wizard.previousStep();
      props.wizard.goToStep("positionWantedCustom");
    } else {
      setDisplay(true);
    }
  }, []);
  
  if(!display) {
    return(<></>);
  }
  
  const demojobs: string[] = ["Assistant ressources humaines", "Gestionnaire ADV", "Responsable boutique", "Comptable fournisseurs", "Content manager"];
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Tu souhaites recruter une ou un:
    </div>
    
    <Spaces n={1}/>
    
    {
      appContextV2.appSession.app.mode !== "demo" && <div className={"assistant--v2-container"}>
        {props.jobs?.private?.map((item: string, key: number) => (
          <button key={key} className={"btn-assistant-main"}
                  onClick={() => {
                    tickJob(item, appContextV2.appSession.app.language);
                    props.updateForm()({target: {type: "custom", name: "positionWanted", value: item}});
                    props.wizard.goToStep("contract");
                  }}>
            {item}
          </button>
        ))}
        <button className={"btn-assistant-main"}
                onClick={() => props.wizard.nextStep()}>
          Autre
        </button>
      </div>
    }
    
    {
      appContextV2.appSession.app.mode?.toLowerCase() === "demo" && <div className={"assistant--v2-container"}>
        {demojobs.map((item: string, key: number) => (
          <button key={key} className={"btn-assistant-main"}
                  onClick={() => {
                    tickJob(item, appContextV2.appSession.app.language);
                    props.updateForm()({target: {type: "custom", name: "positionWanted", value: item}});
                    props.wizard.goToStep("contract");
                  }}>
            {item}
          </button>
        ))}
        <button className={"btn-assistant-main"}
                onClick={() => props.wizard.nextStep()}>
          Autre
        </button>
      </div>
    }
    
    
    
    <Spaces n={1}/>
    
    <div className={"text--center text--grey"}>
      La liste proposée est basée sur les habitudes de recrutement de vos équipes.<br/>Elles sont évolutives et vont s'affiner au cours du temps.
    </div>
  
  </section>);
}