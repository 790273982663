import AppContext, {IAppV2Context} from "../../../../../App/context/AppContext";
import React, {useContext, useEffect, useReducer} from "react";
import axios from "axios";
import {notification} from "../../../../../../utils/notification";
import dataReducerInfos from "./componentsCW2Mail/dataReducerInfos";
import CW2ParsingCVExperience from "./componentsCW2ParsingCV/CW2ParsingCVExperience";
import CW2ParsingCVEducation from "./componentsCW2ParsingCV/CW2ParsingCVEducation";
import CW2ParsingCVLanguage from "./componentsCW2ParsingCV/CW2ParsingCVLanguage";
import CW2ParsingCVSkill from "./componentsCW2ParsingCV/CW2ParsingCVSkill";

export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const [infos, dispatchInfos]: [any, Function] = useReducer(dataReducerInfos, {
        _id: "",
        fingerPrint: "",
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        location: {
            street: "",
            city: "",
            postalCode: 0,
            countryCode: "",
        },
        description: "",
        monthsOfExperience: 0,
        averageMonthsPerEmployer: 0,
        monthsOfManagementExperience: 0,
        experience: [],
        education: [],
        languages: [],
        skills: [],
    });

    console.log("infos",infos)

    useEffect(() => {
        (async () => {
            try {
                const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/parsing/resume`, {
                    timeout: 5000,
                    withCredentials: true,
                    headers: {
                        fingerprint: props.candidate?.candidate?.cvFingerPrint,
                    }
                })).data;

                if (status !== "ok") {
                    return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                }

                dispatchInfos({type: "INITIALIZE_DATA", value: data});
            } catch (error) {
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        })()
    }, []);

    /**
     * Save datas
     */
    async function saveInfos() {
        try {
            const {
                status,
                data
            } = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/parsing/resume`,
                {
                    fingerPrint: infos.fingerPrint,
                    infos: infos,
                }, {
                    timeout: 5000,
                    withCredentials: true,
                })).data;

            if (status !== "ok") {
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
            return notification(appContextV2.dispatchPanels, "Informations sauvegardées", "success");

        } catch (error) {
            console.log(error);
        }
    }

    return (<div>

        <div className={"black-belt-window__container--mini"}>
            Contact:
            <br/><br/>

            <div><span className={"black-belt-window__title"}>Prenom:</span>
                <input className={"black-belt-window__input"} value={infos.firstName}
                       onChange={(e) => {
                           dispatchInfos({
                               type: "UPDATE_INFOS",
                               value: {firstName: e.target.value}
                           })
                       }}/>
            </div>
            <div><span className={"black-belt-window__title"}>Nom:</span>
                <input className={"black-belt-window__input"} value={infos.lastName}
                       onChange={(e) => {
                           dispatchInfos({
                               type: "UPDATE_INFOS",
                               value: {lastName: e.target.value}
                           })
                       }}/>
            </div>
            <div><span className={"black-belt-window__title"}>Telephone:</span>
                <input className={"black-belt-window__input"} value={infos.phone}
                       onChange={(e) => {
                           dispatchInfos({
                               type: "UPDATE_INFOS",
                               value: {phone: e.target.value}
                           })
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Email:</span>
                <input className={"black-belt-window__input"} value={infos.email}
                       onChange={(e) => {
                           dispatchInfos({
                               type: "UPDATE_INFOS",
                               value: {email: e.target.value}
                           })
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Adresse:</span>
                <input className={"black-belt-window__input"} value={infos.street}
                       onChange={(e) => {
                           dispatchInfos({
                               type: "UPDATE_LOCATION",
                               value: {street: e.target.value}
                           })
                       }}/>
            </div>
            <div><span className={"black-belt-window__title"}>Ville:</span>
                <input className={"black-belt-window__input"} value={infos.city}
                       onChange={(e) => {
                           dispatchInfos({
                               type: "UPDATE_LOCATION",
                               value: {city: e.target.value}
                           })
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Pays:</span>
                <input className={"black-belt-window__input"} value={infos.countryCode}
                       onChange={(e) => {
                           dispatchInfos({
                               type: "UPDATE_LOCATION",
                               value: {countryCode: e.target.value}
                           })
                       }}/>
            </div>


        </div>

        <div className={"black-belt-window__container--mini"}>
            Resume:
            <br/><br/>

            <div><span className={"black-belt-window__title"}>Description:</span>
                <textarea className={"black-belt-window__textarea"} value={infos.description}
                          onChange={(e) => {
                              dispatchInfos({
                                  type: "UPDATE_INFOS",
                                  value: {description: e.target.value}
                              })
                          }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Nombre de mois d'experience:</span>
                <input className={"black-belt-window__input"} value={infos.monthsOfExperience}
                       onChange={(e) => {
                           dispatchInfos({
                               type: "UPDATE_INFOS",
                               value: {monthsOfExperience: e.target.value}
                           })
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Nombre de mois d'experience en management:</span>
                <input className={"black-belt-window__input"} value={infos.monthsOfManagementExperience}
                       onChange={(e) => {
                           dispatchInfos({
                               type: "UPDATE_INFOS",
                               value: {monthsOfManagementExperience: e.target.value}
                           })
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Nombre moyen de mois par employeur:</span>
                <input className={"black-belt-window__input"} value={infos.averageMonthsPerEmployer}
                       onChange={(e) => {
                           dispatchInfos({
                               type: "UPDATE_INFOS",
                               value: {averageMonthsPerEmployer: e.target.value}
                           })
                       }}/>
            </div>

        </div>


        <CW2ParsingCVExperience infos={infos} dispatchInfos={dispatchInfos}/>

        <CW2ParsingCVEducation infos={infos} dispatchInfos={dispatchInfos}/>

        <CW2ParsingCVLanguage infos={infos} dispatchInfos={dispatchInfos}/>

        <CW2ParsingCVSkill infos={infos} dispatchInfos={dispatchInfos}/>



        <div className={"black-belt-window__container--mini"}>
            <div className={"space-flex--center"}>
                <button className={"message--v2__chat__button"}
                        onClick={() => saveInfos()}>
                    Sauvegarder
                </button>
            </div>
        </div>


    </div>);
}