import { IAppV2Notifications} from "./IAppV2";

export {initialNotifications};

const initialNotifications: IAppV2Notifications = {
  recruiter: {
    notifications: [],
    newCandidaciesForMissions: []
  },
  candidate: {
    notifications: []
  }
}
