import React, {useContext, useEffect, useState} from "react";
import BPAV3DQIsAvailable from "./BPAV3DefaultQuestions/BPAV3DQIsAvailable";
import BPAV3DQCurrentSalary from "./BPAV3DefaultQuestions/BPAV3DQCurrentSalary";
import BPAV3DQYearsOfExperience from "./BPAV3DefaultQuestions/BPAV3DQYearsOfExperience";
import BPAV3DQTraining from "./BPAV3DefaultQuestions/BPAV3DQTraining";
import BPAV3DQLastCase from "./BPAV3DefaultQuestions/BPAV3DQLastCase";
import BPAV3DQSalaryMin from "./BPAV3DefaultQuestions/BPAV3DQSalaryMin";

export default (props: any) => {

    const [step, setStep] = useState(1);
    
    const nextStep = () => setStep((ps: number) => ps + 1);

    switch (step) {

        case 1:
            return (<BPAV3DQIsAvailable setStep={setStep}
                                        mission={props.mission}
                                        answer={props.answer}
                                        nextStep={nextStep}
                                        updateAnswer={props.updateAnswer}
            />);

        case 2:
            return (<BPAV3DQCurrentSalary setStep={setStep}
                                          mission={props.mission}
                                          answer={props.answer}
                                          nextStep={nextStep}
                                          updateAnswer={props.updateAnswer}
            />);

        case 3:
            return (<BPAV3DQSalaryMin setStep={setStep}
                                         mission={props.mission}
                                         answer={props.answer}
                                         nextStep={nextStep}
                                         updateAnswer={props.updateAnswer}
            />);

        case 4:
            return (<BPAV3DQYearsOfExperience setStep={setStep}
                                              mission={props.mission}
                                              answer={props.answer}
                                              nextStep={nextStep}
                                              updateAnswer={props.updateAnswer}
            />);

        case 5:
            return (<BPAV3DQTraining setStep={setStep}
                                     mission={props.mission}
                                     answer={props.answer}
                                     nextStep={nextStep}
                                     updateAnswer={props.updateAnswer}
            />);

        case 6:
            return (<BPAV3DQLastCase wizard={props.wizard}/>);


        default:
            return <div>Error</div>

    }
}