import React, {useEffect, useState} from "react";
import {devLog} from "../../../../utils/devLog/devLog";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import Spaces from "../../../../components/sharedComponents/Spaces";
import axios from "axios";
import {format, getDayOfYear, getYear} from "date-fns";
import {RadialChart} from "react-vis";
import Calendar from "../../../../components/sharedComponents/GithubCalendar";

export default (props: any) => {
  
  const missionId = props.match.params.missionid;
  const [data, setData]: any = useState({});
  const [dates, setDates] = useState([]);
  console.log("data", data);
  
  useEffect(() => {
    (async() => {
      try {
        
        const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/jobs/stats`, {
          headers: {
            missionid: missionId,
          },
          withCredentials: true,
        })).data;
        
        setData(data);
        
        setDates(data?.datesOfCandidacies?.map((item: any, key: any) => ({
          year: getYear(new Date(item.date)),
          rankOfTheDay: getDayOfYear(new Date(item.date)),
          number: item.nCandidacies,
        })));
        dates.filter((item: any) => (item.year === new Date().getFullYear()));
        
      } catch(error) {
        devLog(error);
      }
    })();
  }, []);
  
  return (<SubPageLayout status={"ok"} subPage={props.subPage}>
    
    <Spaces n={2}/>

    <h1 className={"page-title page-title--smaller"}> Statistiques du poste  </h1>

    <Spaces n={0}/>
    
    <div className={"editor-v2__section"}>
      <h1 className={"profile-edit__title"}>Métriques</h1>
      
      <div className={"missions-stats__metrics-div"}>
        <div className={"missions-stats__metrics-item"}>
          <div className={"missions-stats__metrics-item__title"}>Ouvertures</div>
          <div className={"missions-stats__metrics-item__number"}> {data.nViews ?? "-"} </div>
        </div>
        <div className={"missions-stats__metrics-item"}>
          <div className={"missions-stats__metrics-item__title"}>Candidatures</div>
          <div className={"missions-stats__metrics-item__number"}> {data.nCandidacies ?? "-"}</div>
        </div>
        <div className={"missions-stats__metrics-item"}>
          <div className={"missions-stats__metrics-item__title"}>Candidatures non traitées</div>
          <div className={"missions-stats__metrics-item__number"}> {data.nFirstColumn ?? "-"}</div>
        </div>
        <div className={"missions-stats__metrics-item"}>
          <div className={"missions-stats__metrics-item__title"}>Candidats recrutés</div>
          <div className={"missions-stats__metrics-item__number"}> {data.nRecruitments ?? "-"}</div>
        </div>
      </div>
      
      <div className={"missions-stats__metrics-div"}>
        <div className={"missions-stats__metrics-item"}>
          <div className={"missions-stats__metrics-item__title"}>Date de création</div>
          <div className={"missions-stats__metrics-item__date"}>
            {data.dateOfCreation ? format(new Date(data.dateOfCreation), "dd-MM-yyyy") : "-"}
          </div>
        </div>
        <div className={"missions-stats__metrics-item"}>
          <div className={"missions-stats__metrics-item__title"}>Dernière action</div>
          <div className={"missions-stats__metrics-item__date"}>
            {data.dateOfLastAction ? format(new Date(data.dateOfLastAction), "dd-MM-yyyy") : "-"}
          </div>
        </div>
        <div className={"missions-stats__metrics-item"}>
          <div className={"missions-stats__metrics-item__title"}>Temps moyen pour la première action</div>
          <div
            className={"missions-stats__metrics-item__date"}> {(Math.round(data.averageTimesForFirstAction)) ?? "-"} jours
          </div>
        </div>
        <div className={"missions-stats__metrics-item"}>
          <div className={"missions-stats__metrics-item__title"}>Temps moyen pour recruter</div>
          <div
            className={"missions-stats__metrics-item__date"}>{(Math.round(data.averageTimesToRecruit)) ?? "-"} jours
          </div>
        </div>
      </div>
      
      
      <div className={"missions-stats__metrics-div"}>
        <div className={"missions-stats__metrics-item"}>
          <div className={"missions-stats__metrics-item__title"}>Note moyenne</div>
          <div
            className={"missions-stats__metrics-item__number"}> {(Math.round((data.averageGrades) * 100) / 100) ?? "-"}</div>
        </div>
        <div className={"missions-stats__metrics-item"}>
          <div className={"missions-stats__metrics-item__title"}>Écart type</div>
          <div
            className={"missions-stats__metrics-item__number"}> {(Math.round((data.standardDeviation) * 100) / 100) ?? "-"}</div>
        </div>
        <div className={"missions-stats__metrics-item"}>
          <div className={"missions-stats__metrics-item__title"}>Pourcentage de bloquées</div>
          <div
            className={"missions-stats__metrics-item__number"}> {(Math.round((data.percentageOfBlockedByAlgorithm) * 10) / 10) ?? "-"} %
          </div>
        </div>
        <div className={"missions-stats__metrics-item"}>
          <div className={"missions-stats__metrics-item__title"}>Pourcentage de notes superieurés a 70%</div>
          <div
            className={"missions-stats__metrics-item__number"}> {(Math.round((data.percentageOfGradesHigherThan70) * 10) / 10) ?? "-"} %
          </div>
        </div>
      </div>
    </div>
    
    <Spaces n={1}/>
    
    <div className={"editor-v2__section"}>
      <h1 className={"profile-edit__title"}> Calendrier des candidatures </h1>
      <Spaces n={0}/>
      <Calendar dates={dates} dateOfTheDay={new Date()}/>
    </div>
    
    <Spaces n={1}/>
    
    {data.nCandidacies !== 0 && <div className={"editor-v2__section"}>
      <h1 className={"profile-edit__title"}> Provenance </h1>
      <div className={"missions-stats__origin-div"}>
        <div className={"missions-stats__origin-item"}>
          <h1> Plateformes de provenance </h1>
          <h1> ------------------------- </h1>
          
          <div className={"missions-stats__origin-information"}>
            <div> Linkedin: {data.percentageFromLinkedin ?? '0'} %</div>
            <div> Indeed: {data.percentageFromIndeed ?? '0'} %</div>
            <div> Facebook: {data.percentageFromFacebook ?? '0'} %</div>
            <div> Autre: {data.percentageFromOthers ?? '0'} %</div>
          </div>
          
          <Spaces n={0}/>
          {(data.radialChartWebOrigin ?? [])[0]?.angle && <div className={"ambassador--stats__radial-chart"}>
            <RadialChart
              data={data.radialChartWebOrigin ?? []}
              animation={true}
              radius={80}
              width={180}
              height={180}/>
          </div>}
          <Spaces n={0}/>
          <div className={"ambassador__legends"}>
            
            {!!(data.radialChartWebOrigin ?? [])[0]?.angle && (data.radialChartWebOrigin ?? [])[0]?.angle !== 0 &&
            <div className={"ambassador__legend ambassador-one"}>
              {(data.radialChartWebOrigin ?? [])[0]?.label ?? ""}
            </div>}
            
            {!!(data.radialChartWebOrigin ?? [])[1]?.angle && (data.radialChartWebOrigin ?? [])[1]?.angle !== 0 &&
            <div className={"ambassador__legend ambassador-two"}>
              {(data.radialChartWebOrigin ?? [])[1]?.label ?? ""}
            </div>}
            
            {!!(data.radialChartWebOrigin ?? [])[2]?.angle && (data.radialChartWebOrigin ?? [])[2]?.angle !== 0 &&
            <div className={"ambassador__legend ambassador-three"}>
              {(data.radialChartWebOrigin ?? [])[2]?.label ?? ""}
            </div>}
          
          </div>
        </div>
        
        <div className={"missions-stats__origin-item"}>
          <h1> Ambassadeurs de provenance </h1>
          <h1> ------------------------- </h1>
          {data?.webAmbassadors?.length !== 0 && <div className={"missions-stats__ambassador-information"}>
            {data?.webAmbassadors?.map((item: any, key: any) => (
              <div> {item.name} : {item.candidacies}</div>))}
          </div>}
          <Spaces n={0}/>
          {data.radialChartWebAmbassador && <div className={"ambassador--stats__radial-chart"}>
            <RadialChart
              data={data.radialChartWebAmbassador ?? []}
              animation={true}
              radius={80}
              width={180}
              height={180}/>
          </div>}
          <Spaces n={0}/>
          <div className={"ambassador__legends"}>
            
            {!!(data.radialChartWebAmbassador ?? [])[0]?.angle && (data.radialChartWebAmbassador ?? [])[0]?.angle !== 0 &&
            <div className={"ambassador__legend ambassador-one"}>
              {(data.radialChartWebAmbassador ?? [])[0]?.label ?? ""}
            </div>}
            
            {!!(data.radialChartWebAmbassador ?? [])[1]?.angle && (data.radialChartWebAmbassador ?? [])[1]?.angle !== 0 &&
            <div className={"ambassador__legend ambassador-three"}>
              {(data.radialChartWebAmbassador ?? [])[1]?.label ?? ""}
            </div>}
            
            {!!(data.radialChartWebAmbassador ?? [])[2]?.angle && (data.radialChartWebAmbassador ?? [])[2]?.angle !== 0 &&
            <div className={"ambassador__legend ambassador-two"}>
              {(data.radialChartWebAmbassador ?? [])[2]?.label ?? ""}
            </div>}
          
          </div>
        </div>
      </div>
    </div>}
    <Spaces n={1}/>
  
  </SubPageLayout>);
}

