/**
 * Return every word in a sentence capitalized
 * @param original
 */
export function capitalizeMulti(original: string): string {
  try {
    
    if(typeof original === "undefined") {
      return "";
    }
    
    const arrayOfName = original.trim().split(/([  ]|[-])/);
    
    const result = arrayOfName.map((value: string, index: number) => {
      return index % 2 == 0
        ? value.charAt(0).toUpperCase() + value.slice(1)
        : value;
    });
    
    return result.join('');
    
  } catch (error) {
    console.log(error.message);
    return "";
  }
}

/**
 * Capitalize only the first word, minimize all other letters or not
 * @param original
 */
export function capitalizeSentence(original: string, minimizeOthers: boolean = true) {
  try {
    
    if(!original) {
      // throw new Error("Enmpty string");
      return ""
    }
    
    return original.charAt(0).toUpperCase() + original.slice(1);
    
  } catch (error) {
    return "";
  }
}

/**
 * Anonymize a name
 * @param firstName
 * @param lastName
 */
export async function anonymizeName(firstName: string, lastName: string): Promise<string> {
  try {
    if(!firstName) {
      firstName = "N"
    }
    if(!lastName) {
      lastName = "N"
    }
    return `${firstName![0].toUpperCase() + firstName!.substring(1).toLowerCase()} ${lastName![0].toUpperCase()}.`;
  } catch (error) {
    return "Anonymous N.";
  }
}

/**
 * Clean a phone number
 */
export function cleanPhoneNumber(phone: string): string {
  try {
    return phone.replace(/\D+/g, '');
  } catch (error) {
    return phone;
  }
}

/**
 * Clean an email number
 */
export function cleanEmail(original: string): string {
  try {
    return original.toLowerCase().trim();
  } catch (error) {
    return original;
  }
}

/**
 * Gives a slightly better grade
 * Change it on https://www.mycurvefit.com/
 * @param grade
 */
export function gradeDistortion(grade: number): number {
  try {
    
    if(grade === 0) {return 0}
    if(grade === 5) {return 5}
    if(!grade) { throw new Error ('No grade received'); }
    
    let output: number;
    output = 1.694444 * grade - 0.1388889 * grade * grade;
    output = Math.floor(output * 100) / 100;
    
    return output;
    
  } catch (error) {
    
    return grade;
  }
}

/**
 * Anonymize an experience if user don't wan't to share it
 */
export async function anonymizeExperiences(experiences: any): Promise<any[]> {
  const answer: any[] = [];
  for (let experience of experiences) {
    answer.push({
      position: experience.position,
      from: experience.from,
      to: experience.to,
      verified: experience.verified,
    });
  }
  return answer;
}
