import AppContext, {IAppContext, IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {notification} from "../../../../utils/notification";
import {devLog} from "../../../../utils/devLog/devLog";
import axios from "axios";

function MenuDropDownLogout(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  const language: string = appContextV2.appSession.app.language;
  
  /** Fetcher */
  const [fetchStatus, setFetchStatus] = useState("asleep");
  const [fetched, setFetched] = useState({status: "", data: ""});
  
  const logout = async() => {
    try {
      setFetchStatus("fetching");
      
      const fetched = await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/session/sl/logout`, {withCredentials: true});
      
      if(fetched.data.status !== "ok") {
        return setFetchStatus("error");
      }
      
      await setFetched(fetched.data);
      setFetchStatus("fetched");
      
    } catch(error) {
      return setFetchStatus("error");
      devLog(error);
    }
  };
  
  useEffect(() => {
    (async() => {
      switch(fetchStatus) {
        
        case 'fetched':
          switch(fetched.status) {
            
            case 'ok':
              await appContextV2.fetchSession();
              props.history.push('/');
              await appContextV2.dispatchPanels({type: "CLOSE_ALL", value: null});
              notification(appContextV2.dispatchPanels, "Ta session est terminée. À très bientôt sur myshortlist.co !", "success");
              break;
            
            case 'error':
              notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
              break;
            
            default:
            
          }
          break;
        
        case 'error':
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
          break;
        
        case 'fetching':
        case 'asleep':
        default:
      }
    })();
  }, [fetchStatus]);
  
  return (
    <li>
      <button disabled={fetchStatus === "fetching"} className={`btn--hollow selected`} onClick={()=> logout()}>
        {!["fetching", "fetched"].includes(fetchStatus) && "Se déconnecter"}
        {["fetching", "fetched"].includes(fetchStatus) && "En cours ..."}
      </button>
    </li>
  );
}

export default withRouter(MenuDropDownLogout);