import React, {useContext, useEffect, useState} from "react";
import CloseButton from "../../../Buttons/CloseButton";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import Spaces from "../../../Spaces";
import {capitalizeMulti} from "../../../../../utils/converters";
import {notification} from "../../../../../utils/notification";
import LinkedInIcon from "../../../Buttons/LinkedinIcon";
import {devLog} from "../../../../../utils/devLog/devLog";
import axios from "axios";
import {format} from "date-fns";
import {isHighlighted} from "./dependencies/isHighlighted";
import getFormatedPhone from "../../../../../utils/getFormatedPhone";
import {Link} from "react-router-dom";
import SaveButtonComponent from "../../../SaveButtonComponent";
import {useForm} from "use-formidable";
import keepNumbers from "../../../../../utils/keepNumbers";
import displayNumber from "../../../../../utils/displayNumber";
import getFullUrl from "../../../../../utils/getFullUrl";

export default (props: {
    inherited: any,
    windowStatus: any,
    updateCandidateFrontInWindow: any,
    updateCandidacyFrontInWindow:any,
    toggleAvailability: any,
    candidate: any;
    setPage: any,
    index:any,
    setIndex: any,
    setIsIndexSet: any
}) => {
    
    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

    const [updatingProfile, setUpdatingProfile] = useState(false);
    const [hideGDPRWarning, setHideGDPRWarning] = useState(false);
    const [hideGDPREmail, setHideGDPREmail] = useState(false);
    const [columnId, setColumnId] = useState(props.inherited.columnId);

    const firstName: string = props.candidate?.candidate?.firstName ? props.candidate?.candidate?.firstName : props.inherited.basicData.firstName;
    const lastName: string = props.candidate?.candidate?.lastName ? props.candidate?.candidate?.lastName : props.inherited.basicData.lastName;

    const isCandidateLoaded = !!props.candidate?.candidate?._id;
    const isGDPRDateDefined = !!props.candidate?.candidate?.dateOfLastGDPRAcceptation;
    const isGDPRAcceptationOutdated = new Date(props.candidate?.candidate?.dateOfLastGDPRAcceptation).getTime() + 22 * 30.45 * 24 * 60 * 60 * 1000 < new Date().getTime();
    const didCandidateRefusedGDPR = props.candidate?.candidate?.candidateRefusedGDPR ?? false;
    const displayGDPRWarning = (!isGDPRDateDefined && isCandidateLoaded) || isGDPRAcceptationOutdated || didCandidateRefusedGDPR;
    const displayAskForConsent = props.candidate?.candidate?.dateOfLastAskedGDPR ? new Date(props.candidate?.candidate?.dateOfLastAskedGDPR).getTime() + 7 * 24 * 60 * 60 * 1000 < new Date().getTime() : true;
    const isNotClientRailenium = !(appContextV2.appSession.sessionBusiness.businessName === "Railenium") ;

    const [formAvatar, updateAvatar, formidableAvatar] = useForm({});


    
    /**
     * Set index
     */
    useEffect(() => {
        (async() => {
            if(props.inherited.category === "mission" || props.inherited.category === "search") {
                let i = -1;
                    for (let item of props.inherited.basicData.shortlist) {
                    i = i + 1;
                    if (item.candidateId === props.inherited.candidateId) {
                        props.setIndex(i);
                        props.setIsIndexSet(true);
                    }
                }
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                if (!!formAvatar.avatar && !!formAvatar.avatar[0]) {

                    if (formAvatar.avatar.length > 1) {
                        return notification(appContextV2.dispatchPanels, "Vous ne pouvez envoyer qu'un seul fichier à la fois", "warning");
                    }

                    if (!formidableAvatar.isFileMime(formAvatar.avatar[0], ["jpeg", "png", "gif"])) {
                        return notification(appContextV2.dispatchPanels, "Le format du fichier n'est pas valide. Vous pouvez essayer de le ré-enregistrer.", "warning");
                    }

                    if (!formidableAvatar.isFileSmallerThan(formAvatar.avatar[0], 500000)) {
                        return notification(appContextV2.dispatchPanels, "La taille du fichier est trop importante (maximum: 800ko).", "warning");
                    }

                    notification(appContextV2.dispatchPanels, "Traitement du fichier ...", "information");


                    let dataToSend: any = new FormData();
                    dataToSend.append("avatar", formAvatar.avatar[0], formAvatar.avatar[0].name);
                    
                    const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/upload/data/unique/candidate/avatar`, dataToSend, {
                        withCredentials: true, headers: {
                            'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
                            'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`,
                        }
                    });
                    
                    (props.inherited.frontUpdateCandidacy ?? (()=>{}))(props.candidate?.candidacy?._id, {avatarUrl: fetched.data.data});
                    props.updateCandidateFrontInWindow({avatarUrl: fetched.data.data});

                    const answer = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/candidate/singleton`, {
                        candidateId: props.inherited.candidateId,
                        poolId: appContextV2.appNavigation.poolId,
                        key: "avatarUrl",
                        value: fetched.data.data,
                    }, {
                        timeout: 5000,
                        withCredentials: true
                    });
                    if (answer.data.status !== "ok") {
                        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                    }
                    notification(appContextV2.dispatchPanels, "Donnée mise à jour avec succès", "success");
                }
            } catch (error) {
                devLog(error);
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        })();
    }, [formAvatar.avatar]);

    async function setCandidateConsent() {
        try {

            setHideGDPRWarning(true);

            const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/can/u/accepted/rgpd`,
                {
                    candidateId: props.candidate?.candidate?._id,
                },
                {
                    withCredentials: true
                });

            if (fetched.data?.status !== "ok") {
                setHideGDPRWarning(false);
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }

            return notification(appContextV2.dispatchPanels, "Succès de l'opération", "success");

        } catch (error) {

            setHideGDPRWarning(false);
            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    async function askCandidateConsent() { 
        try {

            setHideGDPRWarning(true);

            const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/can/u/ask/rgpd`,
                {
                    candidateId: props.candidate?.candidate?._id,
                },
                {withCredentials: true});

            if (fetched.data?.status !== "ok") {
                setHideGDPRWarning(false);
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
            setHideGDPRWarning(false);
            setHideGDPREmail(true);
            return notification(appContextV2.dispatchPanels, "Succès de l'opération", "success");

        } catch (error) {
            setHideGDPRWarning(false);
            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    const saveCurrentSalary = async (salary: number) => {
        try {

            const answer = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/candidate/singleton`, {
                candidateId: props.candidate?.candidate?._id,
                poolId: appContextV2.appNavigation.poolId,
                key: "currentSalary",
                value: salary,
            }, {
                timeout: 5000,
                withCredentials: true
            });
            if (answer.data.status !== "ok") {
                setUpdatingProfile(false);
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
            notification(appContextV2.dispatchPanels, "Donnée mise à jour avec succès", "success");
        } catch (error) {
            devLog(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }
    const saveMinSalary = async (salary: number) => {
        try {
            (props.inherited.frontUpdateCandidacy ?? (() => {}))(props.candidate?.candidacy?._id, {
                defaultAnswers: {
                    isWorking: props.candidate.candidacy.defaultAnswers?.isWorking,
                    isAvailable: props.candidate.candidacy.defaultAnswers?.isAvailable,
                    dateOfAvailability: props.candidate.candidacy.defaultAnswers?.dateOfAvailability,
                    currentSalary: props.candidate.candidacy.defaultAnswers?.currentSalary,
                    salaryMin: salary,
                    yearsOfExperience: props.candidate.candidacy.defaultAnswers?.yearsOfExperience,
                    training: props.candidate.candidacy.defaultAnswers?.training,
                }
            });
            const answer = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/candidate/singleton`, {
                candidateId: props.candidate?.candidate?._id,
                poolId: appContextV2.appNavigation.poolId,
                key: "salaryMin",
                value: salary,
            }, {
                timeout: 5000,
                withCredentials: true
            });
            if (answer.data.status !== "ok") {
                setUpdatingProfile(false);
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
            notification(appContextV2.dispatchPanels, "Donnée mise à jour avec succès", "success");
        } catch (error) {
            devLog(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");

        }
    }

    function cleanLinkedinUrl(url: string) {
        // in case an url is like "https://www.linkedin.comhttps://www.linkedin.com/in/ACoAACRXhJUBcNXxj5AsxSqWQx_OXNByuJvv33k", remove the first "https://www.linkedin.com"
        if (url.includes("https://www.linkedin.comhttps://www.linkedin.com")) {
            console.log('==== Cleaned Linkedin Url ====');
            url = url.replace("https://www.linkedin.comhttps://www.linkedin.com", "https://www.linkedin.com");
        }
        return url
    }

    return (<>

        <div className={"text--right"}>
            <CloseButton eventOnClick={() => appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null})}/>
        </div>

        <div className={"text--center"}>
            <div className={"window__candidate__avatar"}>
                {props.inherited.basicData?.avatarUrl && <img src={props.inherited.basicData?.avatarUrl}/>}
                {props.candidate.candidate.avatarUrl && <img src={props.candidate.candidate.avatarUrl}/>}
                {!props.inherited.basicData?.avatarUrl && <>{props.inherited.basicData.firstName ? (props.inherited.basicData.firstName || "N")[0] : "N"}{props.inherited.basicData.lastName ? (props.inherited.basicData.lastName[0] || "N") : "N"}</>}
            </div>
        </div>

        <Spaces n={0}/>

        <div className={"window-candidate-v2__switch"}>
            {/*{ props.inherited.basicData?.shortlist?.length !== 0 && typeof props.inherited.basicData?.shortlist !== "undefined" && <button className={"window-candidate-v2__btn-previous"} onClick={() => {*/}
            {/*    if(props.index === 0) {*/}
            {/*        props.setIndex((props.inherited.basicData?.shortlist.length) - 1);*/}
            {/*    } else {*/}
            {/*        props.setIndex((ps: number) => ps -= 1);*/}
            {/*    }*/}
            {/*}}>*/}
            {/*    <img className={""}*/}
            {/*         src={"https://static.myshortlist.co/btn-arrow-list.svg"}*/}
            {/*         alt={"toggle"}/>*/}
            {/*</button>}*/}

        <div
            className={`text--center window__candidate__name ${(isHighlighted(props.inherited.keywords, props.inherited.basicData.firstName) || isHighlighted(props.inherited.keywords, props.inherited.basicData.lastName) ? "profile--highlighted" : "")}`}>
            {capitalizeMulti(firstName)} {capitalizeMulti(lastName)}
        </div>
            {/*{ props.inherited.basicData?.shortlist?.length !== 0 && typeof props.inherited.basicData?.shortlist !== "undefined" && <button className={"window-candidate-v2__btn-next"} onClick={() => {*/}
            {/*    if(props.index === ((props.inherited.basicData?.shortlist.length) - 1)) {*/}
            {/*        props.setIndex(0);*/}
            {/*    } else {*/}
            {/*        props.setIndex((ps: number) => ps += 1);*/}
            {/*    }*/}
            {/*}}>*/}
            {/*    <img className={""}*/}
            {/*         src={"https://static.myshortlist.co/btn-arrow-list.svg"}*/}
            {/*         alt={"toggle"}/>*/}
            {/*</button>}*/}
        </div>

        <div className={"space-flex--left"}>

            {
                props.candidate?.candidate?.linkedin &&
                <a href={cleanLinkedinUrl(getFullUrl(props.candidate?.candidate?.linkedin))} target={"_blank"}>
                    <button className={"btn--hollow"} onClick={() => {
                        // navigator.clipboard.writeText(props.candidate?.candidate?.linkedin);
                        // notification(appContextV2.dispatchPanels, "Lien copié !", "success");
                    }}>
                        <LinkedInIcon/>
                    </button>
                </a>
            }
            {
                !props.candidate?.candidate?.linkedin && <button className={"btn--hollow hollow"} disabled={true}>
                    <LinkedInIcon/>
                </button>
            }
            <select className={"window__candidate__mark"} value={props.candidate?.candidate?.gradeByTeam}
                    onChange={async (e) => {
                        try {
                            // update front window
                            props.updateCandidateFrontInWindow({gradeByTeam: e.target.value});
                            // update front pool
                            props.inherited.frontUpdatePool.updateCandidatesBulk([props.candidate?.candidate?._id], {gradeByTeam: e.target.value});

                            // update front mission
                            const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/grade`, {
                                candidateId: props.candidate?.candidate?._id,
                                candidacyId:props.candidate?.candidacy?._id,
                                gradeByTeam: e.target.value,
                            }, {
                                timeout: 5000,
                                withCredentials: true
                            });
                            if (fetched.data.status !== "ok") {
                                setUpdatingProfile(false);
                                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                            }
                            window.location.reload();
                            notification(appContextV2.dispatchPanels, "Succès de la mise à jour", "success");

                        } catch (error) {
                            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                        }
                    }}>
                <option value={0}>★ --</option>
                <option value={1}>★ 1</option>
                <option value={2}>★ 2</option>
                <option value={3}>★ 3</option>
                <option value={4}>★ 4</option>
                <option value={5}>★ 5</option>
            </select>

            <button className={"window-candidate-v2__button-link"} onClick={() => {
                props.setPage("share");
            }}>
                <img src={"https://static.myshortlist.co/btn-linkink.svg"}/>
            </button>

            <div className={"window-candidate-v2__upload-image__button"} title={"Ajoute un avatar pour le candidat"}>

                <input type={"file"} name={"avatar"} id={"avatar"} className="input-camera" onChange={updateAvatar()}/>

                <label htmlFor={"avatar"} className={`window-candidate-v2__btn-camera animation-straight-fade-in space-medium`}>
                    <img src={"https://static.myshortlist.co/btn-camera.svg"} alt={"modify avatar"}/>
                </label>

            </div>

        </div>

        <Spaces n={0}/>
    
        {props.inherited.category === "mission" && <select disabled={false} className={"input--v2"} value={columnId ?? props.inherited.columnId}
                 onChange={async(e) => {
                     try {
                         console.log('azerty', e.target.value, props.inherited.candidateId, props.candidate?.candidacy?._id);
                         // update front window
                         setColumnId(e.target.value);
                         props.inherited.moveCandidacyFront( props.inherited.basicData.shortlist[props.index]._id, e.target.value);

                         await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/mat/cam/move/candidacy`, {
                             candidateId:props.candidate?.candidate?._id,
                             missionId: props.inherited.missionId,
                             toColumnId: e.target.value,
                             bypassActions: true,
                         }, {
                             timeout: 5000,
                             withCredentials: true
                         });
                         notification(appContextV2.dispatchPanels, "Succès de la mise à jour", "success");

                     } catch(error) {
                         notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                     }
                 }}>
            <option value={1}>Candidatures recues</option>
            {props.inherited?.basicData?.customKanbanView?.map((item: any) => {
                return (<option value={item?._id}>{item?.name}</option>);
            })}
            <option value={10}>Recrutements</option>
        </select>}

        <Spaces n={0}/>


        <div
            className={`text--center window__candidate__white-label cursor--pointer ${isHighlighted(props.inherited.keywords, props.candidate?.candidate?.email) ? "profile--highlighted" : ""}`}
            onClick={() => {
                if (props.candidate?.candidate?.email) {
                    navigator.clipboard.writeText(props.candidate?.candidate?.email);
                    notification(appContextV2.dispatchPanels, "Mail copié avec succès !", "success");
                }
            }}>
            {props.candidate?.candidate?.email}
        </div>
        <div
            className={`text--center window__candidate__white-label ${isHighlighted(props.inherited.keywords, props.candidate?.candidate?.phone) ? "profile--highlighted" : ""}`}>
            {props.candidate?.candidate?.phone ? getFormatedPhone(props.candidate?.candidate?.phone) : ""}
            {/*// <a href={`tel:${props.candidate?.candidate?.phone}`}>{props.candidate?.candidate?.phone}</a>}*/}
        </div>

        {isNotClientRailenium && <>
        <div className={"text--center text--minified2x"}>Rémunération actuelle br/an</div>
        {/*<div className={`text--center window__candidate__white-label-salary` ---------------------------------------}>*/}
            <div className={"window-candidate-v2__salary"}>
                <input type={"text"} className={"window-candidate-v2__input--invisible"}
                       value={/*displayNumber*/(props.candidate?.candidate?.currentSalary)} onChange={(e) => {
                    if (e.target.value.length < 15) {
                        props.updateCandidateFrontInWindow({currentSalary: keepNumbers(e.target.value)});
                    }
                }}/> K
                <SaveButtonComponent onClick={() => saveCurrentSalary(props.candidate?.candidate?.currentSalary)}/>
            </div>
        {/*</div>*/}
        <div className={"text--center text--minified2x"}>Prétentions salariales br/an</div>
        {/*<div className={`text--center window__candidate__white-label-salary`}>*/}
            <div className={"window-candidate-v2__salary"}>
                <input type={"text"} className={"window-candidate-v2__input--invisible"}
                       value={/*displayNumber*/(props.candidate?.candidate?.salaryMin)} onChange={(e) => {
                    if (e.target.value.length < 15) {
                        props.updateCandidateFrontInWindow({salaryMin: keepNumbers(e.target.value)});
                    }
                }}/> K
                <SaveButtonComponent onClick={() => saveMinSalary(props.candidate?.candidate?.salaryMin)}/>
            {/*</div>*/}
        </div>
            </>}

        <Spaces n={0}/>

        <div className={"text--center"}>
            <button disabled={updatingProfile}
                    title={"Clique pour déclarer que tu as eu un échange récent avec le candidat. Il passera en vert dans la communauté."}
                    className={"btn-secondary"} onClick={async () => {
                try {
                    setUpdatingProfile(true);
                    notification(appContextV2.dispatchPanels, "Mise à jour en cours ...", "information");
                    const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/last/communication`, {
                        candidateId: props.inherited.candidateId,
                        poolId: appContextV2.appNavigation.poolId,
                    }, {
                        timeout: 5000,
                        withCredentials: true
                    });
                    if (fetched.data.status !== "ok") {
                        setUpdatingProfile(false);
                        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                    }
                    setUpdatingProfile(false);
                    props.inherited.frontUpdatePool.updateCandidatesBulk([props.inherited.candidateId], {dateOfLastAction: new Date()});
                    notification(appContextV2.dispatchPanels, "Information mise à jour avec succès", "success");
                } catch (error) {
                    setUpdatingProfile(false);
                    devLog(error);
                    notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                }
            }}>
                Échange récent
            </button>
        </div>

        <Spaces n={0}/>

        <div className={"space-flex--space-between"}>
            <div>
                <span className={"text--minified2x"}>En poste:</span>
            </div>
            <label className={"switch-V2"}>

                <input disabled={updatingProfile} type={"checkbox"} className={"checkbox-on-off"}
                       checked={props.candidate?.candidate?.isWorking ?? false} onChange={async () => {
                    try {
                        setUpdatingProfile(true);
                        // update window front
                        props.updateCandidateFrontInWindow({isWorking: !props.candidate?.candidate?.isWorking});
                        notification(appContextV2.dispatchPanels, "Mise à jour en cours ...", "information");
                        const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/is/working`, {
                            candidateId: props.inherited.candidateId,
                            poolId: appContextV2.appNavigation.poolId,
                            value: !props.candidate?.candidate?.isWorking,
                        }, {
                            timeout: 5000,
                            withCredentials: true
                        });
                        if (fetched.data.status !== "ok") {
                            setUpdatingProfile(false);
                            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                        }
                        setUpdatingProfile(false);
                        // update front pool / mission
                        props.inherited.frontUpdatePool.updateCandidatesBulk([props.inherited.candidateId], {isWorking: !props.candidate?.candidate?.isListeningToMarket});
                        props.inherited.frontUpdateMission.updateCandidaciesBulk([props.candidate?.candidacy?._id], {isWorking: !props.candidate?.candidate?.isListeningToMarket});
                        // update front kanban
                        notification(appContextV2.dispatchPanels, "Information mise à jour avec succès", "success");
                    } catch (error) {
                        setUpdatingProfile(false);
                        devLog(error);
                        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                    }
                }}/>
                <span className="slider"> </span>
            </label>
        </div>

        <Spaces n={0}/>

        <div className={"space-flex--space-between"}>
            <div>
                <span className={"text--minified2x"}>Écoute le marché:</span>
            </div>
            <label className={"switch-V2"}>

                <input disabled={updatingProfile} type={"checkbox"} className={"checkbox-on-off"}
                       checked={props.candidate?.candidate?.isListeningToMarket} onChange={async () => {
                    try {
                        setUpdatingProfile(true);
                        // update window front
                        props.updateCandidateFrontInWindow({isListeningToMarket: !props.candidate?.candidate?.isListeningToMarket});
                        // props.toggleAvailability()
                        notification(appContextV2.dispatchPanels, "Mise à jour en cours ...", "information");
                        const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/listening/to/market`, {
                            candidateId: props.inherited.candidateId,
                            poolId: appContextV2.appNavigation.poolId,
                            value: !props.candidate?.candidate?.isListeningToMarket,
                            dateOfListeningToMarket: props.candidate?.candidate?.dateOfListeningToMarket,
                        }, {
                            timeout: 5000,
                            withCredentials: true
                        });
                        if (fetched.data.status !== "ok") {
                            setUpdatingProfile(false);
                            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                        }
                        setUpdatingProfile(false);
                        // update front pool / mission
                        props.inherited.frontUpdatePool.updateCandidatesBulk([props.inherited.candidateId], {isListeningToMarket: !props.candidate?.candidate?.isListeningToMarket});
                        props.inherited.frontUpdateMission.updateCandidaciesBulk([props.candidate?.candidacy?._id], {isListeningToMarket: !props.candidate?.candidate?.isListeningToMarket});
                        // update front kanban
                        notification(appContextV2.dispatchPanels, "Information mise à jour avec succès", "success");
                    } catch (error) {
                        setUpdatingProfile(false);
                        devLog(error);
                        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                    }
                }}/>
                <span className="slider"> </span>
            </label>
        </div>

        <Spaces n={0}/>

        <div className={"space-flex--space-between"}>
            <div>
                <span className={"text--minified2x"}>Dispo:</span>
            </div>
            <input disabled={false} type={"date"} onChange={async (e) => {
                try {
                    const dateToUpdate = e.target.value;
                    setUpdatingProfile(true);
                    // update window front
                    props.updateCandidateFrontInWindow({dateOfListeningToMarket: new Date(e.target.value)});
                    // props.toggleAvailability()
                    notification(appContextV2.dispatchPanels, "Mise à jour en cours ...", "information");
                    const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/candidate/singleton`, {
                        candidateId: props.inherited.candidateId,
                        poolId: appContextV2.appNavigation.poolId,
                        key: "dateOfListeningToMarket",
                        value: new Date(dateToUpdate),
                    }, {
                        timeout: 5000,
                        withCredentials: true
                    });
                    if (fetched.data.status !== "ok") {
                        setUpdatingProfile(false);
                        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                    }
                    setUpdatingProfile(false);
                    // update front pool / mission
                    props.inherited.frontUpdatePool.updateCandidatesBulk([props.inherited.candidateId], {dateOfListeningToMarket: new Date(dateToUpdate)});
                    props.inherited.frontUpdateMission.updateCandidaciesBulk([props.candidate?.candidacy?._id], {dateOfListeningToMarket: new Date(dateToUpdate)});
                    notification(appContextV2.dispatchPanels, "Information mise à jour avec succès", "success");
                } catch (error) {
                    setUpdatingProfile(false);
                    devLog(error);
                    notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                }
            }}
                   value={props.candidate?.candidate?.dateOfListeningToMarket ? format(new Date(props.candidate?.candidate?.dateOfListeningToMarket), "yyyy-MM-dd") : ""}
                   className={"input-light"}/>
        </div>

        <Spaces n={0}/>

        {
            (displayGDPRWarning && !hideGDPRWarning) && <>
                <div className={"gdpr-warning"}>
                    <div className={"gdpr-warning__title"}>RGPD</div>
                    {
                        !didCandidateRefusedGDPR && !isGDPRDateDefined && <>Ce candidat n'a pas encore accepté d'être
                            dans votre
                            communauté.</>
                    }
                    {
                        !didCandidateRefusedGDPR && isGDPRDateDefined && isGDPRAcceptationOutdated && <>Ce candidat n'a
                            pas
                            renouvelé son consentement.</>
                    }
                    {
                        didCandidateRefusedGDPR && <>Ce candidat a demandé à sortir de votre base.</>
                    }
                    {
                        props.candidate?.candidate?.dateOfLastAskedGDPR && <><br/>Date de la dernière
                            demande: {format(new Date(props.candidate?.candidate?.dateOfLastAskedGDPR), "dd-MM-yyyy")}</>
                    }
                    {
                        !didCandidateRefusedGDPR && <div className={"text--center"}>
                            <button className={"gdpr-warning__btn"} onClick={() => setCandidateConsent()}>
                                J'ai son consentement
                            </button>
                        </div>
                    }
                    {
                        displayAskForConsent && !hideGDPREmail && <div className={"text--center"}>
                            <button className={"gdpr-warning__btn"} onClick={() => askCandidateConsent()}>
                                Demander le consentement
                            </button>
                        </div>
                    }

                </div>
                <Spaces n={0}/>
            </>
        }
        {
            !(displayGDPRWarning && !hideGDPRWarning) && props.candidate?.candidate?.dateOfLastGDPRAcceptation &&
            <div className={"gdpr-warning gdpr-warning--blue"}>
                <div className={"gdpr-warning__title"}>RGPD</div>
                Le consentement du candidat devra être renouvelé dans {
                Math.floor(((new Date(props.candidate?.candidate?.dateOfLastGDPRAcceptation).getTime() + 24 * 30.45 * 24 * 60 * 60 * 1000) - (new Date().getTime())) / (1000 * 60 * 60 * 24 * 30))} mois.
            </div>
        }
        <div>
            <a href={`/business/controlCandidateData/${props.candidate?.candidate?._id}`} target={'_blank'}>Control data</a>
        </div>
    </>);

}
