import {useForm} from "use-formidable";
import {useState} from "react";

export {useAssistant};

const useAssistant = (initialState?: any) => {
  
  const [data, updateData, formidable] = useForm({});
  const [status, updateStatus]: ['asleep' | 'fetching' | 'fetched' | 'error' | 'forbidden', Function] = useState("asleep");
  
  const assistant = {
    asleep: () => updateStatus("asleep"),
    fetching: () => updateStatus("fetching"),
    fetched: () => updateStatus("fetched"),
    error: () => updateStatus("error"),
    ...formidable
  }
  
  return[data, status, updateData, assistant];
  
};