
interface IState {
    token: string,
    candidates: any[],
}

export default (previousState: IState, action: { type: string, value: any }): any => {

    switch(action.type) {

        case 'INITIALIZE_DATA':
            return {
                ...action.value
            }


        case 'ADD_TO_CANDIDATES':
                return {
                    ...previousState,
                    candidates: [...previousState.candidates,
                                 action.value]
                };


        case 'REMOVE_FROM_CANDIDATES':
            return {
                ...previousState,
                candidates: previousState.candidates.filter((item: any) => item.candidateId !== action.value._id)
            };

        default:
            return previousState;

    }

}
