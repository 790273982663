export default (missions: any, setDisplayedMissions: any, createdBy: any, title: any, olderThan: any, earlierThan: any, showHistory: boolean, status: string) => {
  
  const missionsCopy: any = [...missions];
  
  /** Defines which filters are active */
  const isCreatedByActive: boolean = !!createdBy;
  const isTitleByActive: boolean = !!title;
  const isOlderThanActive: boolean = !!olderThan;
  const isEarlierThanActive: boolean = !!earlierThan;
  const isShowHistoryActive: boolean = showHistory;
  const isStatusActive: boolean = !!status;
  
  /** Defines results for each filter */
  let idFilteredByCreator: string[] = [];
  let idFilteredByTitle: string[] = [];
  let idFilteredByOlderThan: string[] = [];
  let idFilteredByEarlierThan: string[] = [];
  let idFilteredByHistory: string[] = [];
  let idFilteredByStatus: string[] = [];
  
  /** Defines the result */
  let resultMissions: any = [];
    
    /** Get IDs of result for creator filter */
    (isCreatedByActive && (idFilteredByCreator = missionsCopy
      .filter((item: any) => (item.creatorTrigram?.toLowerCase().includes(createdBy.toLowerCase())))
      .map((item: any) => (item.missionId))
    ));
  
    /** Get IDs of result for title filter */
    (isTitleByActive && (idFilteredByTitle = missionsCopy
      .filter((item: any) => (item.positionWanted?.toLowerCase().includes(title.toLowerCase())))
      .map((item: any) => (item.missionId))
    ));
    
    /** Get IDs of result for older than filter */
    (isOlderThanActive && (idFilteredByOlderThan = missionsCopy
      .filter((item: any) => new Date(item.dateOfCreation).getTime() < (new Date().getTime() - olderThan * 24 * 60 * 60 * 1000))
      .map((item: any) => (item.missionId))
    ));

    /** Get IDs of result for earlier than filter */
    (isEarlierThanActive && (idFilteredByEarlierThan = missionsCopy
      .filter((item: any) => new Date(item.dateOfCreation).getTime() > (new Date().getTime() - earlierThan * 24 * 60 * 60 * 1000))
      .map((item: any) => (item.missionId))
    ));
  
  /** Get IDs of result for earlier than filter */
  (isStatusActive && (idFilteredByStatus = missionsCopy
    .filter((item: any) => item.status === status)
    .map((item: any) => (item.missionId))
  ));
    
    /** Get IDs of result for history filter */
    (!isShowHistoryActive && (idFilteredByHistory = missionsCopy
      .filter((item: any) => (["published", "draft", "collaboration"].includes(item.status)))
      .map((item: any) => (item.missionId))
    ));
    (isShowHistoryActive && (idFilteredByHistory = missionsCopy
      .filter((item: any) => (!["published", "draft", "collaboration"].includes(item.status)))
      .map((item: any) => (item.missionId))
    ));
    
    /** Add IDs to the result  */
    // For each ID, add it only if it matches active filters
    for(let mission of missionsCopy) {
    
      if(isCreatedByActive && !idFilteredByCreator.includes(mission.missionId)) {
        continue;
      }
      if(isTitleByActive && !idFilteredByTitle.includes(mission.missionId)) {
        continue;
      }
      if(isOlderThanActive && !idFilteredByOlderThan.includes(mission.missionId)) {
        continue;
      }
      if(isEarlierThanActive && !idFilteredByEarlierThan.includes(mission.missionId)) {
        continue;
      }
      if(isStatusActive && !idFilteredByStatus.includes(mission.missionId)) {
        continue;
      }
      // Specific for history, filter always active
      if(!idFilteredByHistory.includes(mission.missionId)) {
        continue;
      }
  
      resultMissions.push(mission);
    
    }

  // }
  
  /** Organize and display the result */
  const organizedPools: any = [
    ...resultMissions.filter((item: any) => item.pinned === true).sort((a: any, b: any) => (new Date(b.dateOfCreation).getTime() - new Date(a.dateOfCreation).getTime())),
    ...resultMissions.filter((item: any) => item.pinned !== true).sort((a: any, b: any) => (new Date(b.dateOfCreation).getTime() - new Date(a.dateOfCreation).getTime())),
  ];
  setDisplayedMissions(organizedPools);
  
}