import React from "react";

export {ProfileElementBooleanAutorisation};

const ProfileElementBooleanAutorisation = (props: {
  text: string,
  value: any,
  description: string,
  updateData: Function,
  nameToUpdateFront: string,
  nameToUpdateBack: string,
}) => {

  return(<div className={"profile-element__container"}>
    <div>
      {props.text}
      {!!props.description && <>
      <br/>
        <span className={"text--dark-grey text--minified1x"}>{props.description}</span>
      </>}
    </div>
    <div>
      <label className={"switch-V2"}>
        <input type={"checkbox"} className={"checkbox-on-off"} checked={props.value === 2} onChange={() => {
          props.updateData(props.nameToUpdateFront, props.nameToUpdateBack, props.value === 2 ? {authorisation: 1} : {authorisation: 2});
        }} />
        <span className="slider"> </span>
      </label>
    </div>
  </div>);
}