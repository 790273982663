import React, {useContext, useEffect, useReducer, useState} from "react";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import PageLayoutWithHeader from "../../layouts/PageLayoutWithHeader";
import {format} from "date-fns";
import missionsReducer from "./componentsRecruiterDashboard/missionsReducer";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import {notification} from "../../utils/notification";
import {Link} from "react-router-dom";
import filtersMissions from "./componentsRecruiterDashboard/filtersMissions";

export default (props: any) => {
    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
    const [missions, dispatchMissions] = useReducer(missionsReducer, []);
    const [nBin, setNBin] = useState(0);
    const [displayedMissions, setDisplayedMissions]: [any, any] = useState([]);
    const [filterByPriority, setFilterByPriority]: any = useState();
    const [filterByTas, setFilterByTas]: any = useState();
    const [filterByKam, setFilterByKam]: any = useState();
    const [filterByClient, setFilterByClient]: any = useState();
    const [filterByMonth, setFilterByMonth]: any = useState();
    const [filterByCvv, setFilterByCvv]: any = useState();
    const [clients, setClients]: any = useState();
    const [newTodos, setNewTodos]: any = useState([]);

    useEffect(() => {
        (async function () {
            try {
                const {
                    status,
                    data
                } = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/jobs/get/dashboard/missions`,
                    {withCredentials: true})).data;
                console.log(missions, data);
                if (status === "ok") {
                    dispatchMissions({type: "INITIALIZE", value: data});
                }
            } catch (error) {
                devLog(error);
            }
        })()
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/clients`, {
                    withCredentials: true
                })).data;
                console.log("data", data);
                if (status === "ok") {
                    setClients(data);
                }
            } catch (error) {

            }
        })();
    }, []);

    useEffect(() => {
        console.log("hit display");
        filtersMissions({
            missions,
            filterByPriority,
            filterByTas,
            filterByKam,
            filterByClient,
            filterByMonth,
            filterByCvv,
            setDisplayedMissions,
        });
    }, [missions, filterByTas, filterByKam, filterByClient, filterByMonth, filterByCvv, filterByPriority]);

    useEffect(() => {
        setNBin(0);
        for (let displayedMission of (displayedMissions ?? [])) {
            if (displayedMission.bin) setNBin(prevState => prevState + 1);
            console.log("nBin", nBin);
        }
    }, [displayedMissions, missions]);

    console.log("missions", missions);


    function getDays(date1: Date, date2: Date) {
        const oneDay = 1000 * 60 * 60 * 24;
        const d1Days = (date1.getTime() / oneDay) - 1;
        const d2Days = (date2.getTime() / oneDay);
        let days = (d2Days - d1Days);
        const weeks = (d2Days - d1Days) / 7;
        const day1 = date1.getDay();
        const day2 = date2.getDay();
        if (day1 == 0) {
            days--;
        } else if (day1 == 6) {
            days -= 2;
        }
        if (day2 == 0) {
            days -= 2;
        } else if (day2 == 6) {
            days--;
        }
        days -= weeks * 2;
        return (days);
    }

    async function deleteMission(missionId: string,) {
        try {
            const {
                status,
                data
            } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/jobs/delete/mission/dashboard`,
                    {missionId: missionId}, {withCredentials: true})
            ).data;
            console.log(missions, data);
            if (status === "ok") {
                dispatchMissions({type: "DELETE", value: missionId});
                return notification(appContextV2.dispatchPanels, "Mission supprimée", "success");
            }
            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");

        } catch (error) {
            devLog(error);
            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");

        }
    }

    async function saveMission(missionId: string, missionHelp: string, missionStartDate: Date, oldMission: boolean, missionNumberOfCall: number,
                               missionNumberOfCve: number, missionNumberOfCvv: number, missionToDo:any, missionForThisDay:boolean,missionTargetMonth:string) {
        try {
            const {status, data} = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/save/mission/dashboard`,
                {
                    missionId: missionId,
                    help: missionHelp,
                    startDate: missionStartDate,
                    oldMission: oldMission,
                    missionNumberOfCall: missionNumberOfCall,
                    missionNumberOfCve: missionNumberOfCve,
                    missionNumberOfCvv: missionNumberOfCvv,
                    toDo:missionToDo,
                    forThisDay:missionForThisDay,
                    targetMonth:missionTargetMonth,
                }, {withCredentials: true})).data;
            if (status === "ok") {
                return notification(appContextV2.dispatchPanels, "Mission sauvegardée", "success");
            }
            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        } catch (error) {
            devLog(error);
            return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");

        }
    }

    function color(type: string, mission: any) {
        if (type === "nCandidacies") {
            if (mission.nCandidacies <= 50) return "background-red grey-background";
            if (mission.nCandidacies > 50 && mission.nCandidacies < 100) return "background-orange grey-background";
            else return "dashboard--case grey-background";
        }
        if (type === "numberOfCall") {
            if (mission.numberOfCall < 10 && getDays(new Date(mission.dateOfCreation), new Date()) > 4) return "call-red";
            if (mission.numberOfCall < 10 && getDays(new Date(mission.dateOfCreation), new Date()) > 2) return "call-orange";
            else return "call--case";
        }
        if (type === "numberOfCve") {
            if (mission.numberOfCve < 3 && getDays(new Date(mission.dateOfCreation), new Date()) > 5) return "call-red";
            if (mission.numberOfCve < 3 && getDays(new Date(mission.dateOfCreation), new Date()) > 3) return "call-orange";
            else return "call--case";
        }

        if (type === "numberOfCvv") {
            if (mission.numberOfCvv < 3 && getDays(new Date(mission.dateOfCreation), new Date()) > 6) return "call-red";
            if (mission.numberOfCvv < 3 && getDays(new Date(mission.dateOfCreation), new Date()) > 4) return "call-orange";
            else return "call--case";
        }

        if (type === "time") {
            if ((Math.floor(getDays(new Date(mission.dateOfCreation), new Date()))) > 18) return "background-red grey-background";
            if ((Math.floor(getDays(new Date(mission.dateOfCreation), new Date()))) < 18) return "background-green grey-background";
            else return "dashboard--case grey-background";
        } else return "dashboard--case"
    }

    function oldMission(mission: any) {

        let days = getDays(new Date(mission.dateOfCreation), new Date(2022, 2, 10));
        const old = ((days - 21) >= 0);
        return old;
    }

    const trigrams = ["DHA", "MLE", "TBE", "JFR", "AEL"]


    return (<PageLayoutWithHeader grey={false} title={"Dashboard"} small={true} fullWidth={true} page={props.page}
                                  status={"ok"} hideHeader={false}>

        <div className={"dashboard-big-title"}>
            <div className={"dashboard-big-title-item"}>Nombre de postes ouverts: {displayedMissions?.length}</div>
            <div className={"dashboard-big-title-item"}> Nombre de recrutements: {nBin}</div>
            <div className={"dashboard-big-title-item"}> Target call: 10 - Target CVE: 5 - Target CVV: 3</div>
        </div>
        <br/>

        <table className={"dashboard--styled-table"}>
            <thead>
            <tr>
                <th className={"dashboard--title border-left"}>Date</th>
                <th className={"dashboard--title"}>Time</th>
                <th className={"dashboard--title"}>Poste</th>
                <th className={"dashboard--title"}>
                    <select className={'dashboard--filter'} value={filterByPriority}
                            onChange={(e) => setFilterByPriority(e.target.value)}>
                        <option value={""}>Priorité</option>
                        <option value={"A"}>A</option>
                        <option value={"B"}>B</option>
                        <option value={"C"}>C</option>
                    </select>
                </th>
                <th className={"dashboard--title"}>
                    <select className={'dashboard--filter'} value={filterByTas}
                            onChange={(e) => setFilterByTas(e.target.value)}>
                        <option value={""}>TAS</option>
                        {trigrams.map((t, i) => <option key={i} value={t.toLowerCase()}>{t}</option>)}
                    </select>
                </th>
                <th className={"dashboard--title"}>
                    <select className={'dashboard--filter'} value={filterByKam}
                            onChange={(e) => setFilterByKam(e.target.value)}>
                        <option value={""}>KAM</option>
                        {trigrams.map((t, i) => <option key={i} value={t.toLowerCase()}>{t}</option>)}
                    </select>
                </th>
                <th className={"dashboard--title"}>
                    <select className={"dashboard--filter"} value={filterByClient}
                            onChange={(e) => {
                                setFilterByClient(e.target.value)
                            }}>
                        <option value={""}>Client</option>
                        {clients?.map((item: any, key: any) => (
                            <option value={item.name}> {item.name} </option>
                        ))}
                    </select>
                </th>
                {/*<th className={"dashboard--title"}>*/}
                {/*    <select className={'dashboard--filter'} value={filterByMonth}*/}
                {/*            onChange={(e) => setFilterByMonth(e.target.value)}>*/}
                {/*        <option value={""}>Mois</option>*/}
                {/*        <option value={"Janvier"}>Janvier</option>*/}
                {/*        <option value={"Février"}>Février</option>*/}
                {/*        <option value={"Mars"}>Mars</option>*/}
                {/*        <option value={"Avril"}>Avril</option>*/}
                {/*        <option value={"Mai"}>Mai</option>*/}
                {/*        <option value={"Juin"}>Juin</option>*/}
                {/*        <option value={"Juillet"}>Juillet</option>*/}
                {/*        <option value={"Août"}>Août</option>*/}
                {/*        <option value={"Septembre"}>Septembre</option>*/}
                {/*        <option value={"Octobre"}>Octobre</option>*/}
                {/*        <option value={"Novembre"}>Novembre</option>*/}
                {/*        <option value={"Décembre"}>Décembre</option>*/}
                {/*    </select>*/}
                {/*</th>*/}
                <th className={"dashboard--title"}>Pipe</th>
                <th className={"dashboard--title"}>Package</th>
                <th className={"dashboard--ecl-title"}>To do</th>
                <th className={"dashboard--title"}>Save</th>
                <th className={"dashboard--title"}>Call</th>
                <th className={"dashboard--title"}>CVE</th>
                <th className={"dashboard--title"}>
                    <select className={'dashboard--filter'} value={filterByCvv}
                            onChange={(e) => setFilterByCvv(e.target.value)}>
                        <option value={""}>CVV</option>
                        <option value={"croissant"}>Tri ↑</option>
                        <option value={"décroissant"}>Tri ↓</option>

                    </select>
                </th>
                <th className={"dashboard--ecl-title"}>ECL</th>
                <th className={"dashboard--title"}>BIN</th>
                <th className={"dashboard--title"}>Date d'embauche</th>

                <th className={"dashboard--title border-right"}>Delete</th>
            </tr>
            </thead>
            <tbody>
            {displayedMissions?.map((mission: any, key:any) => {
                return (
                    <tr className={"dashboard--case"} key={mission._id}>
                        <td className={"dashboard--case border-left"}>
                            {format(new Date(mission.dateOfCreation), "dd/MM/yyyy")}
                        </td>
                        <td className={color("time", mission)}>
                            {Math.floor(getDays(new Date(mission.dateOfCreation), new Date()))}jour(s)
                        </td>

                        <td className={"dashboard--case"}>
                            <Link to={`/business/mission/${mission._id}`} className={`${mission.forThisDay? "modern-link-for-today" : "modern-link"}`}>
                                {mission.positionWanted}
                            </Link>
                            {!mission.forThisDay && <button className={"btn--transparent"} onClick={(e)=>{
                                dispatchMissions({
                                    type: "UPDATE",
                                    value: {
                                        missionId: mission._id,
                                        keyValues: {
                                            forThisDay: true,
                                        }
                                    }
                                });
                            }}>
                                <img className={"img--small"} src={"https://static.myshortlist.co/check.svg"}/>
                                </button>}

                            {mission.forThisDay && <button className={"btn--transparent"} onClick={(e)=>{
                                dispatchMissions({
                                    type: "UPDATE",
                                    value: {
                                        missionId: mission._id,
                                        keyValues: {
                                            forThisDay: false,
                                        }
                                    }
                                });
                            }}>
                                <img className={"img--small"} src={"https://static.myshortlist.co/cross.svg"}/>
                            </button>}
                        </td>
                        <td className={"dashboard--case  grey-background"}>{mission.priority?.toUpperCase()}</td>
                        <td className={"dashboard--case  "}>{mission.tas?.toUpperCase()}</td>
                        <td className={"dashboard--case grey-background"}>{mission.kam?.toUpperCase()}</td>
                        <td className={"dashboard--case "}>{mission.client}</td>
                        {/*<td className={"dashboard--case grey-background"}>*/}
                        {/*    <select className={"dashboard--select"} value={mission.targetMonth} onChange={(e) => {*/}
                        {/*        dispatchMissions({*/}
                        {/*            type: "UPDATE",*/}
                        {/*            value: {*/}
                        {/*                missionId: mission._id,*/}
                        {/*                keyValues: {*/}
                        {/*                    targetMonth: e.target.value,*/}
                        {/*                }*/}
                        {/*            }*/}
                        {/*        });*/}
                        {/*    }}>*/}
                        {/*        <option value={""}>-- Mois --</option>*/}
                        {/*        <option value={"Janvier"}>Janvier</option>*/}
                        {/*        <option value={"Février"}>Février</option>*/}
                        {/*        <option value={"Mars"}>Mars</option>*/}
                        {/*        <option value={"Avril"}>Avril</option>*/}
                        {/*        <option value={"Mai"}>Mai</option>*/}
                        {/*        <option value={"Juin"}>Juin</option>*/}
                        {/*        <option value={"Juillet"}>Juillet</option>*/}
                        {/*        <option value={"Août"}>Août</option>*/}
                        {/*        <option value={"Septembre"}>Septembre</option>*/}
                        {/*        <option value={"Octobre"}>Octobre</option>*/}
                        {/*        <option value={"Novembre"}>Novembre</option>*/}
                        {/*        <option value={"Décembre"}>Décembre</option>*/}

                        {/*    </select>*/}
                        {/*</td>*/}
                        <td className={color("nCandidacies", mission)}>{mission.nCandidacies} </td>
                        <td className={"dashboard--case "}>{(mission.salaryMin) / 1000}K-{(mission.salaryMax) / 1000}K</td>
                        <td className={"dashboard--case grey-background"}>
                            <ul>
                                {mission.toDo?.map((item: any, key: any) => <li>
                                    <img className={"img--very--small margin3"} src={"https://static.myshortlist.co/point.svg"}/>
                                {item}
                                    <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                    dispatchMissions({
                                    type: 'REMOVE_FROM_TO_DO',
                                    value: {
                                        missionId: mission._id,
                                        toDo: item,
                                    }
                                });
                                }}>
                                    <img className={"img--small"} src={"https://static.myshortlist.co/delete.svg"}/>
                                    </button>
                                    </li>)
                                }
                            </ul>
                            <input className={"dashboard--input"} value={newTodos[key]}
                                   onChange={(e) => {
                                       let toDos=[...newTodos];
                                       toDos[key]=e.target.value;
                                       setNewTodos(toDos);
                                   }}/>
                            <div>
                                <button disabled={!newTodos[key]} className={"profile2-btn-add profile2-btn-add--small"}
                                        onClick={(e) => {
                                            dispatchMissions({
                                                type: 'ADD_TO_TO_DO',
                                                value:{
                                                    missionId:mission._id,
                                                    toDo:newTodos[key]
                                                } ,
                                            });
                                            let toDos=[...newTodos];
                                            toDos[key]="";
                                            setNewTodos(toDos);
                                        }}> + Ajouter
                                </button>
                            </div>
                        </td>
                        <td className={"dashboard--case"}>
                            <button className={"btn--transparent"} onClick={() => {
                                saveMission(mission._id, mission.help, mission.startDate, oldMission(mission), mission.numberOfCall, mission.numberOfCve, mission.numberOfCvv, mission.toDo, mission.forThisDay, mission.targetMonth)
                            }}
                            >
                                <img className={"dashboard-button-delete"}
                                     src={"https://static.myshortlist.co/save.svg"}/>
                            </button>
                        </td>
                        <td className={"call-background"}>
                            {!oldMission(mission) &&
                                <div
                                    className={color("numberOfCall", mission)}> {mission.numberOfCall ? mission.numberOfCall : "-"} call(s) </div>}
                            {oldMission(mission) &&
                                <div><input type={"number"} value={mission.numberOfCall} className={"dashboard--input--small"}
                                            onChange={(e) => {
                                                dispatchMissions({
                                                    type: "UPDATE",
                                                    value: {
                                                        missionId: mission._id,
                                                        keyValues: {numberOfCall: e.target.value,}
                                                    }
                                                });
                                            }}/> call(s)</div>}
                        </td>

                        <td className={"cve-background"}>
                            {!oldMission(mission) && <div
                                className={color("numberOfCve", mission)}> {mission.numberOfCve ? mission.numberOfCve : "-"} cve </div>}
                            {oldMission(mission) &&
                                <div><input type={"number"} value={mission.numberOfCve} className={"dashboard--input--small"}
                                            onChange={(e) => {
                                                dispatchMissions({
                                                    type: "UPDATE",
                                                    value: {
                                                        missionId: mission._id,
                                                        keyValues: {numberOfCve: e.target.value,}
                                                    }
                                                });
                                            }}/> cve</div>}
                        </td>

                        <td className={"call-background"}>
                            {!oldMission(mission) && <div
                                className={color("numberOfCvv", mission)}> {mission.numberOfCvv ? mission.numberOfCvv : "-"} cvv </div>}
                            {oldMission(mission) &&
                                <div><input type={"number"} value={mission.numberOfCvv} className={"dashboard--input--small"}
                                            onChange={(e) => {
                                                dispatchMissions({
                                                    type: "UPDATE",
                                                    value: {
                                                        missionId: mission._id,
                                                        keyValues: {numberOfCvv: e.target.value,}
                                                    }
                                                });
                                            }}/> cvv</div>}
                        </td>

                        <td className={"dashboard--ecl"}>
                            <ul>{mission.ecl?.map((item: any) => {
                                return (<li><img className={"margin3"}
                                                 src={"https://static.myshortlist.co/point.svg"}/>{item}</li>)
                            })}
                            </ul>
                        </td>
                        <td className={"dashboard--case grey-background"}>{mission.bin}</td>
                        <td className={"dashboard--case"}><input className={"dashboard--input-date"} type={"date"}
                                                                 value={mission.startDate ? format(new Date(mission.startDate), "yyyy-MM-dd") : ''}
                                                                 onChange={(e) => {
                                                                     dispatchMissions({
                                                                         type: "UPDATE",
                                                                         value: {
                                                                             missionId: mission._id,
                                                                             keyValues: {
                                                                                 startDate: e.target.value,
                                                                             }
                                                                         }
                                                                     });
                                                                 }}/></td>

                        <td className={"dashboard--case  grey-background border-right"}>
                            <button className={"btn--transparent"} onClick={() => {
                                deleteMission(mission._id)
                            }}>
                                <img className={"dashboard-button-delete"}
                                     src={"https://static.myshortlist.co/delete.svg"}/>
                            </button>
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </table>


        <div className={"caption-and-title"}>
            <div className={"caption-title"}> Légende</div>
            <div className={"caption"}>
                <div>
                    <div className={"caption-red"}> {"Pipe < 50"}</div>
                    <div className={"caption-orange"}> {"50 < Pipe < 100"} </div>
                </div>
                <div>
                    <div className={"caption-red"}> {"call < 10 & J+4"}</div>
                    <div className={"caption-orange"}> {"call < 10 & J+2"}</div>
                </div>
                <div>
                    <div className={"caption-red"}> {"CVE < 3 & J+5"}</div>
                    <div className={"caption-orange"}> {"CVE < 3 & J+3"}</div>
                </div>
                <div>
                    <div className={"caption-red"}> {"CVV < 4 & J+6"}</div>
                    <div className={"caption-orange"}> {"CVV < 4 & J+4"}</div>
                </div>
                <div>
                    <div className={"caption-green"}> {"Lead Time < 18"}</div>
                    <div className={"caption-red"}> {"Lead Time > 18"}</div>
                </div>
            </div>
        </div>

    </PageLayoutWithHeader>);
}