import AppContext, {IAppV2Context} from "../../../components/App/context/AppContext";
import React, {useContext} from "react";
import {format} from "date-fns";
import Spaces from "../../../components/sharedComponents/Spaces";
import {capitalizeSentence} from "../../../utils/converters";
import PageLayout from "../../../layouts/PageLayout";
import {Loading} from "../../../components/sharedComponents/Loading/Loading";
import Routing from "../../../components/App/componentsSwitchAppStatus/Routing";
import FourHundredThree from "../../errors/FourHundredThree";
import {devLog} from "../../../utils/devLog/devLog";
import CW2DocumentDisplayer
    from "../../../components/sharedComponents/Window/componentsWindow/componentsCWV2/subWindows/CW2DocumentDisplayer";
import {ButtonBigPlusV2} from "../../../components/sharedComponents/Buttons/ButtonBigPlus";
import {FileDrop} from "../../../components/sharedComponents/Buttons/FileDrop";
import LoadingStyle2 from "../../../components/sharedComponents/Loading/LoadingStyle2";
import SCCDCv from "./ComponentsSCCandidateDetails/SCCDCv";
import SCCDProfile from "./ComponentsSCCandidateDetails/SCCDProfile";
import SCCDCandidacy from "./ComponentsSCCandidateDetails/SCCDCandidacy";
import SCCDTrigram from "./ComponentsSCCandidateDetails/SCCDTrigram";


export default function SCCandidateDetails(props: {
    candidate: any
    tab: string,
    token:string,
}) {

    switch(props.tab) {

        case 'cv':
            return (<SCCDCv {...props} />);

        case 'profile':
            return (<SCCDProfile {...props} />);

        case 'candidacy':
            return (<SCCDCandidacy {...props} />);

        case 'trigram':
            return (<SCCDTrigram {...props} />);

        default:
            return (<div>Cette page n'existe pas</div>)
    }
}
