import React, {useContext} from "react";
import Spaces from "../../../../../components/sharedComponents/Spaces";
import AllAvatar from "../../../../../components/sharedComponents/AllAvatar";
import BusinessPageProfileLinks from "../old/BusinessPageProfileLinks";
import AppContext, {IAppContext} from "../../../../../components/App/context/AppContext";

export default (props: any) => {
  
  const showPresentation: boolean = !!props.business?.presentation;
  const showCulture: boolean = !!props.business?.culture;
  const weWant: boolean = !!props.business?.weWant;
  
  return (<>
  
    <Spaces n={2}/>
    
    {
      showPresentation && <>
        <h1>
          Présentation
        </h1>
        <br/>
        <div>
          {props.business?.presentation?.split('\n').map(function(item: any) {
            return (
              <span>
              {item}
                <br/>
              </span>
            )
          })}
        </div>
        <br/><br/>
      </>
    }
    
    <Spaces n={0}/>
    
    {
      showCulture && <>
        <h1>
          Notre culture entreprise
        </h1>
        <br/>
        <div>
          {props.business?.culture?.split('\n').map(function(item: any) {
            return (
              <span>
              {item}
                <br/>
              </span>
            )
          })}
        </div>
        <br/><br/>
      </>
    }
    
    <Spaces n={0}/>
    
    {
      weWant && <>
        <h1>
          Nous recherchons
        </h1>
        <br/>
        <div>
          {props.business?.weWant?.split('\n').map(function(item: any) {
            return (
              <span>
              {item}
                <br/>
              </span>
            )
          })}

          <Spaces n={2}/>
          
          {/*--- todo*/}
          {/*<p className={"text--center"}>*/}
          {/*  <button className={"btn-candidacy"} onClick={() => {*/}
          {/*    appContext.toggleWindowVisibility(*/}
          {/*      {*/}
          {/*        type: "candidacyFromBusinessPage",*/}
          {/*        data: {*/}
          {/*          businessId: props.assistantContext?.assistantState?.fetched?.business?._id,*/}
          {/*        }*/}
          {/*      }, "open");*/}
          {/*  }}>*/}
          {/*    Candidature spontanée*/}
          {/*  </button>*/}
          {/*</p>*/}

        </div>
        <br/><br/>
      </>
    }
  
  </>);
  
}