import AssistantLayout from "../../../../../layouts/AssistantLayout";
import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import {ButtonWrapperV2} from "../../../../../layouts/ButtonWrapper";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";
import Spaces from "../../../Spaces";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  /** Fetcher */
  const [fetchStatus, setFetchStatus] = useState("asleep");
  const [fetched, setFetched] = useState({status: "", data: ""});
  
  const sendLink = async() => {
    try {
      setFetchStatus("fetching");
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/session/sl/generate/login/link`, {
        email: props.windowContext.email,
      }, {
        withCredentials: true,
      });
      await setFetched(fetched.data);
      setFetchStatus("fetched");
    } catch(error) {
      devLog(error);
      setFetchStatus("error");
    }
  }
  
  useEffect(() => {
    (() => {
      switch(fetchStatus) {
        case 'fetched':
          switch(fetched.status) {
            case 'ok':
              notification(appContextV2.dispatchPanels, "Consulte ta boite mail pour trouver ton lien magique", "success");
              return props.windowContext.wizard.goToStep("success");
            case 'noUser':
              notification(appContextV2.dispatchPanels, "L'utilisateur n'existe pas", "warning");
              return props.windowContext.wizard.goToStep("noUser");
            default:
              notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
              return props.windowContext.wizard.goToStep("error");
          }
        case 'error':
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
          return props.windowContext.wizard.goToStep("error");
        case 'fetching':
        case 'asleep':
        default:
      }
    })();
  }, [fetchStatus]);
  
  const isEmailOk: boolean = props.windowContext.formHandler.isEmail(props.windowContext.email);
  
  const assistantTitle: string = ((language: string) => {
    switch(language) {
      case 'FR':
        return "Entre ton email pour recevoir ton lien de connexion intelligent";
      case 'EN':
      default:
        return "Type your email to receive your smart-login link";
    }
  })(language);
  
  const assistantComment: any = (<div className={"text--center"}>
    <button className={"btn-main"} disabled={!(isEmailOk && fetchStatus === "asleep")} onClick={async() => {
      sendLink();
    }}>
      <ButtonWrapperV2 fetchingStatus={fetchStatus as 'asleep'|'fetching'|'fetched'|'error'}>
        {language === 'FR' ? "Envoyer le lien" : ""}
        {language === 'EN' ? "Send the link" : ""}
      </ButtonWrapperV2>
    </button>
  </div>);
  
  return (<>
    <AssistantLayout title={assistantTitle} comment={assistantComment} animated={true}>
      
      <form className={""} onSubmit={(e) => {
        e.preventDefault();
        if(isEmailOk) {
          sendLink();
        }
      }}>
        <input value={props.windowContext.email}
               className={`input--v2 ${isEmailOk ? "input--ok" : ""} ${(!isEmailOk && !!props.windowContext.email) ? "input--wrong" : ""}`} type={"email"} name={"email"}
               onChange={props.windowContext.updateForm()}/>
      </form>


    </AssistantLayout>
        <Spaces n={0}/>
        <div className={"div-center"}>
          <button className={" modern-link"} onClick={()=>
          {return props.windowContext.wizard.goToStep("enterPassword")}}>
            Connexion par mot de passe
          </button>
        </div>
</>
);
}

