export default (args: {setDisplayedUnsubscribedShortlist: Function}) => (unregisteredCandidacyId: string, firstName: string, lastName: string, comment: string) => {
  args.setDisplayedUnsubscribedShortlist((ps: any[]) => ps.map((item: any) => {
    if (item._id === unregisteredCandidacyId) {
      return {
        ...item,
        firstName,
        lastName,
        comment
      }
    }
    return item;
  }));
}