import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import {SecondaryNavBarWrapper} from "./shared/SecondaryNavBarWrapper";
import {SubMenuRecruiterParametersContent} from "../SubNavbarComponents/SubMenuRecruiterParametersContent";

export {SecondaryNavBarRecruiterParameters};

function SecondaryNavBarRecruiterParameters(props: any) {
  
  return (<div className={"hide-on-computers"}>
    <SecondaryNavBarWrapper content={<SubMenuRecruiterParametersContent/>}/>
  </div>);
}
