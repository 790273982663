import React, {useEffect, useState} from "react";
import PageLayout from "../../layouts/PageLayout";
import Spaces from "../../components/sharedComponents/Spaces";
import {useWizard} from "use-wizard";
import axios from "axios";
import {Link} from "react-router-dom";
import {devLog} from "../../utils/devLog/devLog";
import {useHistory} from "react-router";

const qs = require("query-string");

export {LinkedinApply};

const LinkedinApply = (props: any) => {
  
  const history = useHistory();
  
  const [step, wizard] = useWizard(["pending", "error", "ok", "tooManyApplies"]);
  
  const [data, setData]: [any, Function] = useState({});
  
  useEffect(() => {
    (async() => {
      try {
        const parsedUriParameters = qs.parse(props.location.search) || {};
        const answer = await axios.post(`${process.env.REACT_APP_API_URL}/v3/jobs/mat/ca/candidacy/linkedin`, parsedUriParameters, {
          withCredentials: true
        });
        setData(answer.data.data);
        wizard.goToStep(answer.data?.status);
      } catch(error) {
        
        devLog(error);
        wizard.goToStep("error");
      }
    })();
  }, []);
  
  useEffect(() => {
    if(step === "ok" && data.slugname) {
      
      const email = (data.email ?? "").replace('@', "%40");
      const hider = (data.hideButtonSubscribeToCandidates ?? false).toString();
      const mark = (data.globalMark ?? 0 ).toString();
      const bn = data.businessName ?? "";
      const hides = (data?.hideResult ?? true).toString();
      history.push(`/apply/${data.slugName}/${data.slugNumber}/${data.rank}/apply/success?email=${email}&hider=${hider}&mark=${mark}&bn=${bn}&hides=${hides}`);
      setTimeout(() => history.go(0), 10);
    }
  }, [step, data]);
  
  switch(step) {
    
    case 'pending':
      return (<PageLayout page={props.page} status={"ok"}>
        <Spaces n={10}/>
        <Spaces n={6}/>
      </PageLayout>);
    
    case 'ok':
      return(<>Spaces n={15}</>);
    
    case 'tooManyApplies':
      return (<PageLayout page={props.page} status={"ok"}>
        <Spaces n={10}/>
        <div className={"text--center"}>
          Vous avez déjà postulé pour ce poste. Il n'est pas possible de postuler une seconde fois.
          <Spaces n={1}/>
          <Link to={"/"}>
            <button className={"btn-secondary"}>Retour à l'accueil</button>
          </Link>
        </div>
        <Spaces n={3}/>
      </PageLayout>);
  
    case 'error':
    case 'errorNoCode':
    case 'errorNoState':
    case 'errorNoCandidacy':
      return (<PageLayout page={props.page} status={"ok"}>
        <Spaces n={10}/>
        <div className={"text--center"}>
          Il semble qu'une erreur soit survenue
          <Spaces n={1}/>
          <Link to={"/"}>
            <button className={"btn-secondary"}>Retour à l'accueil</button>
          </Link>
        </div>
        <Spaces n={1}/>
      </PageLayout>);
    
    default:
      return (<></>);
  }
  
}
