import React, {useEffect, useState} from "react";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {devLog} from "../../../../utils/devLog/devLog";

export {RecruiterProfilesBusinessDescription};

const RecruiterProfilesBusinessDescription = (props: {
  assistantGet: any,
  profile: any,
  updateSingleton: Function,
}) => {
  
  const [presentation, setPresentation] = useState(props.profile.presentation || "");
  const [culture, setCulture] = useState(props.profile.culture || "");
  const [weWant, setWeWant] = useState(props.profile.weWant || "");
  
  useEffect(() => {
    setPresentation(props.profile.presentation);
  }, [props.profile.presentation]);
  
  useEffect(() => {
    setCulture(props.profile.culture);
  }, [props.profile.culture]);
  
  useEffect(() => {
    setWeWant(props.profile.weWant);
  }, [props.profile.weWant]);
  
  return (<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Présentation publique de l'entreprise</h1>
    
    <div>Présentation de l'entreprise <span className={"text--grey"}>({1000 - presentation?.length})</span></div>
    <textarea className={"profile-edit__textarea"} value={presentation} onChange={(e) => {
      if(e.target.value.length <= 1000) {
        setPresentation(e.target.value);
      }
    }}>
    
    </textarea>
    
    <Spaces n={0}/>
    
    <div>Culture entreprise <span className={"text--grey"}>({1000 - culture?.length})</span></div>
    <textarea className={"profile-edit__textarea"} value={culture} onChange={(e) => {
      if(e.target.value.length <= 1000) {
        setCulture(e.target.value);
      }
    }}>
    
    </textarea>
    
    <Spaces n={0}/>
    
    <div>Les profils que nous recherchons <span className={"text--grey"}>({1000 - weWant?.length})</span></div>
    <textarea className={"profile-edit__textarea"} value={weWant} onChange={(e) => {
      if(e.target.value.length <= 1000) {
        setWeWant(e.target.value);
      }
    }}>
    
    </textarea>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      <button className={"btn-new-blue"} onClick={async() => {
        if(presentation !== props.profile.presentation) {
          await props.updateSingleton("presentation", "presentation", presentation);
        }
        if(culture !== props.profile.culture) {
          await props.updateSingleton("culture", "culture", culture);
        }
        if(weWant !== props.profile.weWant) {
          await props.updateSingleton("weWant", "weWant", weWant);
        }
        // todo: merge into one single fetch ...
      }}>
        Sauvegarder
      </button>
    </div>
  
  </div>);
  
}