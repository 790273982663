import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {notification} from "../../../../utils/notification";
import fetchAmbassadors
    from "../../componentsRecruiterAmbassadors/dependenciesRecruiterAmbasssadors/fetch/fetchAmbassadors";
import axios from "axios";

export default (props: any) => {

    const [tracking, setTracking] = useState("");
    const [ambassador, setAmbassador] = useState("");
    const [ambassadors, setAmbassadors] = useState([]);

    useEffect(() => {
        (async() => {
            const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/amb/m/get`,
              {withCredentials: true})).data;
            setAmbassadors(data);
        })();
    }, []);

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
    const urlWithTracking: string = `https://app.black-belt.io/apply/${appContextV2.appSession.sessionBusiness.profileLink.name}/${appContextV2.appSession.sessionBusiness.profileLink.number}/${props.missionTokens.rank}/apply${(!!tracking || !!ambassador) ? "?" : ""}${(!!tracking) ? `o=${tracking}` : ""}${(!!tracking && !!ambassador) ? "&" : ""}${(!!ambassador) ? `a=${ambassador}` : ""}`;

    return (<>

        <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>

            <div><span className={"text--bold text--magnified11x"}>Activez le tracking.</span> Vous pouvez générer un lien unique pour suivre la provenance des candidats
                et/ou activer la cooptation. Attention, ce lien est unique pour chaque plateforme et/ou ambassadeur.
            </div>

            <Spaces n={1}/>

            <div className={"text--center"}>
                <select className={"input--v2"} value={tracking} onChange={(e) => {setTracking(e.target.value)}}>
                    <option value={""}>Lien neutre (aucune plateforme)</option>
                    {["linkedin", "facebook", "twitter", "indeed", "monster", "whatsapp", "slack"].sort().map((item: any, key: number) => (
                        <option key={key} value={item}>
                            {item}
                        </option>))}
                </select>
                {
                    (!!ambassadors && ambassadors.length !== 0) && <select className={"input--v2"} value={ambassador} onChange={(e) => {setAmbassador(e.target.value)}}>
                        <option value={""}>Lien neutre (aucun ambassadeur)</option>
                        {ambassadors.map((item: any, key: number) => (<option key={key} value={item._id}>
                            {item.firstName} {item.lastName}
                        </option>))}
                    </select>
                }

                <Spaces n={1}/>

                <button className={"btn-secondary"} onClick={() => {
                    notification(appContextV2.dispatchPanels, "Lien copié", "success");
                    navigator.clipboard.writeText(urlWithTracking);
                }}>
                    Copier le lien avec tracking
                </button>

            </div>

        </article>

    </>);
}