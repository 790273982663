/**
 * Deprecated, it is only used to display landing pages
 */

import {Route} from "react-router-dom";
import React from "react";
import FourHundredThree from "../../../../pages/errors/FourHundredThree";

export const PublicRoute: any = (props: {exact: boolean, path: string, component: any, space: 'public'|'candidate'|'recruiter', key: number}) => {
  
  const isRouteAvailable: boolean = props.space === "public";
  
  return (
    <Route exact={props.exact}
           path={props.path}
           render={(componentProps)=>(isRouteAvailable ? React.cloneElement(
             props.component, { ...componentProps }) : <FourHundredThree page={"FourHundredThree"} fullPage={true} setPage={false}/>)}
    />
  );
};

export const CandidateRoute: any = (props: {exact: boolean, path: string, component: any, space: 'public'|'candidate'|'recruiter', key: number}) => {
  
  const isRouteAvailable: boolean = props.space === "candidate";
  
  return (
    <Route exact={props.exact}
           path={props.path}
           render={(componentProps)=>(isRouteAvailable ? React.cloneElement(
             props.component, { ...componentProps }) : <FourHundredThree page={"FourHundredThree"} fullPage={true} setPage={false}/>)}
    />
  );
};

export const RecruiterRoute: any = (props: {exact: boolean, path: string, component: any, space: 'public'|'candidate'|'recruiter', key: number}) => {
  
  const isRouteAvailable: boolean = props.space === "recruiter";
  
  return (
    <Route exact={props.exact}
           path={props.path}
           render={(componentProps)=>(isRouteAvailable ? React.cloneElement(
             props.component, { ...componentProps }) : <FourHundredThree page={"FourHundredThree"} fullPage={true} setPage={false}/>)}
    />
  );
};

export const SharedRoute: any = (props: {exact: boolean, path: string, component: any, space: 'public'|'candidate'|'recruiter', key: number}) => {
  
  return (
    <Route exact={props.exact}
           path={props.path}
           render={(componentProps)=>(
             React.cloneElement(props.component, { ...componentProps }))}
    />
  );
};