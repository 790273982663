import React, {useState} from "react";

export default (props: {
  nameDisplayed: string,
  nameToUpdateFront: string,
  nameToUpdateBack: string,
  description: string,
  value: any,
  updateData: Function,
  modifier: Function,
  validator: Function,
}) => {
  
  const [editorMode, setEditorMode] = useState(false);
  const [newValue, setNewValue] = useState("");
  
  return (<div>
    <div className={"space-flex--left"}>
      <div>
        {props.nameDisplayed}: {!editorMode && (props.value || "pas de donnée")}
      </div>
      
      {/*----- When not in editor mode */}
      {!editorMode && <button className={"btn-picto--minimalist2 btn-picto--minimalist2--small"} onClick={() => setEditorMode(true)}>
        <img src={"https://static.myshortlist.co/btn-pen.svg"}/>
      </button>}
      
      {/*----- When in editor mode */}
      {editorMode && <>
        <input type={"text"} value={newValue} className={"text-align"} onChange={(e) => setNewValue(props.modifier(e.target.value))}/>
        
        {/*----- Buttons */}
        <button disabled={!props.validator(newValue)} className={"btn-inline-dictionary"} onClick={async() => {
          await props.updateData(props.nameToUpdateFront, props.nameToUpdateBack, newValue);
          setNewValue("");
          setEditorMode(false);
        }}>
          Enregistrer
        </button>

        <button className={"btn-inline-dictionary"} onClick={() => {
          setNewValue("");
          setEditorMode(false);
        }}>
          Annuler
        </button>

      </>}
    </div>
    
    {
      !!props.description && <>
        <div className={"text--grey"}>{props.description}</div>
      </>
    }
  </div>);
}