import AppContext, {IAppV2Context} from "../../../../../../App/context/AppContext";
import React, {useContext, useState} from "react";
import Spaces from "../../../../../Spaces";

export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const [from,setFrom]:any=useState();
    const [to,setTo]:any=useState();
    const [employer,setEmployer]:any=useState();
    const [contract,setContract]:any=useState();
    const [positionName,setPositionName]:any=useState();
    const [description,setDescription]:any=useState();

    return (<>

        <div className={"black-belt-window__container--mini"}>
            Experience:
            <br/><br/>

            {props.infos.experience.map((item: any, key: number) => {
                return(<>
                    <div></div>
                    <div><span className={"black-belt-window__title"}>A partir de:</span>
                        <input className={"black-belt-window__input"} value={item.from}
                               onChange={(e) => {
                                   props.dispatchInfos({
                                       type: "UPDATE_ARRAY",
                                       value: {field:"experience",index:key, subfield:"from" ,value: e.target.value}
                                   })
                               }}/>
                    </div>

                    <div><span className={"black-belt-window__title"}>Jusqu'à:</span>
                        <input className={"black-belt-window__input"} value={item.to}
                               onChange={(e) => {
                                   props.dispatchInfos({
                                       type: "UPDATE_ARRAY",
                                       value: {field:"experience",index:key, subfield:"to" ,value: e.target.value}
                                   })
                               }}/>
                    </div>

                    <div><span className={"black-belt-window__title"}>Employeur:</span>
                        <input className={"black-belt-window__input"} value={item.employer}
                               onChange={(e) => {
                                   props.dispatchInfos({
                                       type: "UPDATE_ARRAY",
                                       value: {field:"experience",index:key, subfield:"employer" ,value: e.target.value}
                                   })
                               }}/>
                    </div>

                    <div><span className={"black-belt-window__title"}>Contrat:</span>
                        <input className={"black-belt-window__input"} value={item.contract}
                               onChange={(e) => {
                                   props.dispatchInfos({
                                       type: "UPDATE_ARRAY",
                                       value: {field:"experience",index:key, subfield:"contract" ,value: e.target.value}
                                   })
                               }}/>
                    </div>

                    <div><span className={"black-belt-window__title"}>Position:</span>
                        <input className={"black-belt-window__input"} value={item.position.positionName}
                               onChange={(e) => {
                                   props.dispatchInfos({
                                       type: "UPDATE_ARRAY",
                                       value: {field:"experience",index:key, subfield:"position" ,value: {positionName:e.target.value}}
                                   })
                               }}/>
                    </div>

                    <div><span className={"black-belt-window__title"}>Description:</span>
                        <textarea className={"black-belt-window__textarea"} value={item.description}
                                  onChange={(e) => {
                                      props.dispatchInfos({
                                          type: "UPDATE_ARRAY",
                                          value: {field:"experience",index:key, subfield:"description" ,value: e.target.value}
                                      })
                                  }}/>
                    </div>

                    <Spaces n={1}/>
                    <button  className={"message--v2__chat__button"} onClick={()=>{
                        props.dispatchInfos({
                            type: "REMOVE_ELEMENT_FROM_ARRAY",
                            value: {field:"experience",index:key}
                        })
                    }}> Supprimer </button>
                    <Spaces n={1}/>

                </>)})}

            <div><span className={"black-belt-window__title"}>A partir de:</span>
                <input className={"black-belt-window__input"} value={from}
                       onChange={(e) => {
                          setFrom(e.target.value);
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Jusqu'à:</span>
                <input className={"black-belt-window__input"} value={to}
                       onChange={(e) => {
                           setTo(e.target.value);
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Employeur:</span>
                <input className={"black-belt-window__input"} value={employer}
                       onChange={(e) => {
                           setEmployer(e.target.value);
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Contrat:</span>
                <input className={"black-belt-window__input"} value={contract}
                       onChange={(e) => {
                           setContract(e.target.value);
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Position:</span>
                <input className={"black-belt-window__input"} value={positionName}
                       onChange={(e) => {
                           setPositionName(e.target.value);
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Description:</span>
                <textarea className={"black-belt-window__textarea"} value={description}
                          onChange={(e) => {
                              setDescription(e.target.value);
                          }}/>
            </div>

            <Spaces n={1}/>
            <button  className={"message--v2__chat__button"} onClick={()=>{
                props.dispatchInfos({
                    type: "ADD_ELEMENT_TO_ARRAY",
                    value: {field:"experience",
                        value:{
                            from:from,
                            to:to,
                            employer:employer,
                            contract:contract,
                            position:{positionName:positionName},
                            description:description,
                        }}
                })
                setFrom("");
                setTo("");
                setEmployer("");
                setContract("");
                setPositionName("");
                setDescription("");
            }}> Ajouter </button>
            <Spaces n={1}/>

        </div>

    </>);
}