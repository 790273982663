import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../components/sharedComponents/Buttons/ButtonBack";
import {ButtonBigPlusV2} from "../../../../components/sharedComponents/Buttons/ButtonBigPlus";
import CloseButton from "../../../../components/sharedComponents/Buttons/CloseButton";
import tickAdvantage from "../dependencies/tickAdvantage";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Advantages manipulation happens here, when "next" is hit it saves to the form */
  const [advantages, setAdvantages]: [string[], Function] = useState([]);
  const [advantage, setAdvantage]: [string, Function] = useState("");
  
  /** Drag and drop */
  // todo
  
  useEffect(() => {
    setAdvantages(props.form.advantages || []);
  }, []);
  
  const remove = (index: number) => {
    setAdvantages((ps: any) => [
      ...[...ps].splice(0, index),
      ...[...ps].splice(index + 1, ps.length - 1 - index)
    ]);
  }
  const isButtonAvailable= (advantage.length===0)?true:false;

  return (<section>
    <Spaces n={0}/>
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Quelles sont les avantages offerts par l'entreprise ?
    </div>
    
    <Spaces n={1}/>
    
    {/*-------------------------------------------- Elements */}
    <section className={"assistant--v2-container assistant--v2-container--column"}>
      
      {advantages.length === 0 && <article className={"assistant--v2-container__label"}>
        Aucun avantage n'a été ajouté
      </article>}
      
      {advantages.map((item: any, key: number) => (<article key={key} className={"assistant--v2-container__label assistant--v2-container__label--color1"}>
        {item}
        <div className={"assistant--v2-container__label__buttons"}>
          <CloseButton eventOnClick={() => remove(key)}/>
        </div>
      </article>))}
    
    </section>
    
    <Spaces n={1}/>
    
    {/*--------------------------------------------- Control */}
    {
      advantages.length <= 10 && <div className={"assistant--v2-container--no-flex text--center"}>
        <input type={"text"} list={'advantages'} className={"assistant--v2-input assistant--v2-input--full-width"} name={"newAdvantage"}
               placeholder={"Ajoute un avantage"}
               value={advantage} onChange={(e) => {
          if(e.target.value.length < 200) {
            setAdvantage(e.target.value);
          }
        }}/>
        <datalist id={"advantages"}>
          {props.advantages.map((item: any, key: number) => (<option key={key}>{item}</option>))}
        </datalist>
        <div className={"space-flex--center"}>
          <ButtonBigPlusV2 cb={() => {
            tickAdvantage(advantage, appContextV2.appSession.app.language);
            if(!props.advantages.includes(advantage)) {
              props.setAdvantages((ps: any) => [...ps, advantage]);
            }
            setAdvantages((ps: any) => [...ps, advantage]);
            setAdvantage("");
          }} disabled={!advantage} title={"Ajoute un avantage"}/>
        </div>
      </div>
    }
    
    <Spaces n={0} />
    
    {/*---------------------------------------------- Next */}
    <div className={"text--center"}>
      <span className={`text--red text--minified3x ${!advantage && "invisible"}`}>Appuyer sur + pour ajouter cet avantage</span>
      <br/>
      <button className={"btn-assistant-main"}
              disabled={!isButtonAvailable}
              onClick={async() => {
                console.log("saving advantages", advantages)
                props.updateForm()({target: {type: "custom", name: "advantages", value: advantages}});
                props.wizard.nextStep();
              }}>
        Suivant
      </button>
    </div>
    
    {/*---------------------------------------------- Propositions */}
  
  </section>);
}