import AppContext, {IAppContext, IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext, useEffect, useState} from "react";
import WindowWrapper from "../../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {abstractPostFetch, abstractPutFetch} from "../../../../utils/abstractFetch";
import ButtonWrapper from "../../../../layouts/ButtonWrapper";
import {capitalizeMulti} from "../../../../utils/converters";
import {useForm} from "use-formidable";
import {devLog} from "../../../../utils/devLog/devLog";
import {notification} from "../../../../utils/notification";
import axios from "axios";
import {fetchPools} from "../../../../pages/privateRecruiter/componentsRecruiterPools/dependenciesRecruiterPools/fetch/fetchPools";
import LoadingStyle2 from "../../Loading/LoadingStyle2";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [fetchedCommunities, setFetchedCommunities] = useState([]);
  const [fetchedCandidates, setFetchedCandidates] = useState([]);
  const [fetchedStatus, setFetchedStatus] = useState("asleep");
  const [fetchedCandidateStatus, setFetchedCandidateStatus] = useState("asleep");
  
  const [selectedCommunity, setSelectedCommunity] = useState("");
  const [selectedCandidate, setSelectedCandidate] = useState("");
  
  useEffect(() => { fetchPools(setFetchedStatus, setFetchedCommunities)}, []);
  devLog("fetchedCommunities", fetchedCommunities);
  devLog("fetchedCandidates", fetchedCandidates);
  
  const fetchCandidates = async(poolId: string) => {
    try {
      setSelectedCommunity(poolId);
      setFetchedCandidateStatus("fetching");
      const fetched = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/candidates`, {
        headers: {
          poolid: poolId
        },
        withCredentials: true
      }));
      setFetchedCandidates(fetched.data.data);
      setFetchedCandidateStatus("fetched");
    } catch (error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const sendAnswer = async() => {
    try {
      setFetchedStatus("fetching");
      const fetched = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/jobs/mat/ca/import/candidate`, {
        missionId: appContextV2.appNavigation.missionId,
        candidateId: selectedCandidate,
      },{
        withCredentials: true,
      }));
      if(fetched.data.status !== "ok") {
        setFetchedStatus("asleep");
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      setFetchedStatus("fetched");
      appContextV2.appPanels.window.windowOptions.data.addCandidacyFront(fetched.data.data);
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      return notification(appContextV2.dispatchPanels, "Candidature importée", "success");
    } catch(error) {
      devLog(error);
      setFetchedStatus("asleep");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  return (<WindowWrapper size={"small"} status={fetchedStatus as 'asleep' | 'fetching' | 'fetched'} windowTitle={""}>
    <div className={"container"}>
      
      <div>Importe un candidat d'une communauté.</div>
      
      <Spaces n={0}/>
      
      <div className={"text--center"}>
        
        <select className={"input--v2"} value={selectedCommunity} name={"selectedCommunity"} onChange={(e) => {fetchCandidates(e.target.value);}}>
          <option value={""} disabled={true}>-- Sélectionne une communauté --</option>
          {fetchedCommunities.sort((a: any, b: any) => {
            if(a.name > b.name) return 1;
            return -1;
          }).map((item: any, key: number) => (<option value={item._id}>
            {item.name} ({item.number})
          </option>))}
        </select>
        
        <Spaces n={0}/>
        
        {fetchedCandidateStatus === "fetching" && <><LoadingStyle2/><Spaces n={1}/></>}
        
        {
          fetchedCandidateStatus === "fetched" && <>

            <select className={"input--v2"} value={selectedCandidate} name={"selectedCandidate"} onChange={(e) => {
              setSelectedCandidate(e.target.value);
            }}>
              <option value={""} disabled={true}>-- Sélectionne un candidat --</option>
              {fetchedCandidates?.sort((a: any, b: any) => {
                if(a.firstName > b.firstName) return 1;
                return -1;
              }).sort((a: any, b: any,) => (b.firstName - a.firstName)).map((item: any, key: number) => (<option value={item._id}>
                {item.firstName} {item.lastName}
              </option>))}
            </select>

            <Spaces n={1}/>
            
            <button disabled={!fetchedStatus} className={"btn-secondary"} onClick={() => sendAnswer()}>
              Importer ce candidat
            </button>
          
          </>
        }
      
      </div>
    
    </div>
  </WindowWrapper>);
}
