import AppContext, {IAppContext, IAppV2Context} from "../../components/App/context/AppContext";
import React, {useContext, useEffect} from "react";

export default function ErrorMaintenance(props: { page: string, fullPage: boolean, setPage: boolean }) {
  
  const appContext: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  useEffect(() => {
    if(props.setPage) {
      appContext.dispatchNavigation({type: "SET_PAGE", value: props.page});
    }
  }, []);
  
  return (
    <>
      <section className={`${props.fullPage ? "full-page" : "empty-page"}`}>
          L'équipe de maintenance opère des modifications. Nous nous excusons pour la gêne occasionnée.
      </section>
    </>
  );
}