import React, {useContext, useEffect, useReducer, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {devLog} from "../../../../utils/devLog/devLog";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import dataReducerProposition from "./dependenciesRecruiterMissionProposition/dataReducerProposition";


export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    const [proposition, dispatchProposition]: [any, Function] = useReducer(dataReducerProposition, {
        token: "",
        candidates: [],
    });

    const [candidacies, setCandidacies]: [any, Function] = useState([]);
    const [selectedCandidate, setSelectedCandidate]: [any, Function] = useState([]);


    const missionId = props.match.params.missionid;

    devLog("proposition", proposition);
    devLog("candidacies", candidacies);

    /** Fetch proposition */
    useEffect(() => {
        (async () => {
            try {

                const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/m/proposition`, {
                    headers: {
                        missionid: missionId
                    },
                    withCredentials: true
                })).data;

                dispatchProposition({type: "INITIALIZE_DATA", value: data.proposition});


            } catch (error) {
                devLog(error);
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {

                const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/jobs/mat/ca/shortlist`, {
                    headers: {
                        missionid: missionId
                    },
                    withCredentials: true
                })).data;
                setCandidacies(data?.shortlist);
                console.log(candidacies,candidacies);

            } catch (error) {
                devLog(error);
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
        })();
    }, []);

    const addCandidate = async () => {
        try {
            const {
                status,
                data
            } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/jobs/add/candidate/to/proposition`, {
                missionId: appContextV2.appNavigation.missionId,
                candidateId: selectedCandidate,
            }, {
                withCredentials: true,
            })).data;
            if (status !== "ok") {
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }

            console.log("add",data);
            let candidate = candidacies.find((item: any) => item.candidateId === selectedCandidate);
            dispatchProposition({type: "ADD_TO_CANDIDATES", value: data.candidate});
            return notification(appContextV2.dispatchPanels, "Candidat partage", "success");
        } catch (error) {
            devLog(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    const removeCandidate = async (candidateId:string) => {
        try {
            const {
                status,
                data
            } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/jobs/delete/candidate/from/proposition`, {
                missionId: appContextV2.appNavigation.missionId,
                candidateId: candidateId,
            }, {
                withCredentials: true,
            })).data;
            if (status !== "ok") {
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }
            console.log("Remove",data)
            dispatchProposition({type: "REMOVE_FROM_CANDIDATES", value: {_id:candidateId}});
            return notification(appContextV2.dispatchPanels, "Candidat partage", "success");
        } catch (error) {
            devLog(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }



    return (<SubPageLayout status={"ok"} subPage={props.subPage}>

        <div className={"container"}>
            <Spaces n={2}/>

            <h1 className={"page-title page-title--smaller"}> Proposition </h1>
            <Spaces n={0}/>

            <div className={"editor-v2__section"}>
                <div className={"text--center"}>
                    {(!proposition || proposition?.candidates?.length === 0) && "Il n'y a pas de proposition pour cette mission"}

                    {proposition?.candidates?.length !== 0 &&
                    <div>
                        <div>Vous avez partage ces candidats:</div>
                        {proposition?.candidates?.map((item: any) => (<div>
                            {item.name}
                            <button className={"btn-secondary"} onClick={() => {

                                if(appContextV2.appSession.app.mode === "production") {
                                    navigator.clipboard.writeText(`https://app.black-belt.io/card/${item._id}`);
                                } else {
                                    navigator.clipboard.writeText(`http://localhost:3000/card/${item._id}`);
                                }


                            }}>Copier le lien
                            </button>
                            <button className={"btn-secondary"} onClick={() => {

                                if(appContextV2.appSession.app.mode === "production") {
                                    navigator.clipboard.writeText(`https://app.black-belt.io/card/new/${item._id}`);
                                } else {
                                    navigator.clipboard.writeText(`http://localhost:3000/card/new/${item._id}`);
                                }
                            }}>Copier le lien anonyme
                            </button>
                            <button  className={"btn-secondary"}
                                    onClick={() => removeCandidate(item.candidateId)}>
                                Ne plus partager ce candidat
                            </button>
                        </div>))}
                    </div>
                    }
                </div>
            </div>

            <Spaces n={0}/>

            <div className={"editor-v2__section"}>

                <div className={"text--center"}>
                    <select className={"input--v2"} value={selectedCandidate} name={"selectedCandidate"}
                            onChange={(e) => {
                                setSelectedCandidate(e.target.value);
                            }}>
                        <option value={""} disabled={true}>-- Sélectionne une candidature --</option>
                        {candidacies?.sort((a: any, b: any) => {
                            if (a.firstName > b.firstName) return 1;
                            return -1;
                        }).map((item: any, key: number) => (<option value={item.candidateId}>
                            {item.firstName} {item.lastName}
                        </option>))}
                    </select>
                </div>

                <Spaces n={1}/>

                <div className={"text--center"}>
                    <button disabled={!selectedCandidate} className={"btn-secondary"} onClick={() => addCandidate()}>
                        Partager ce candidat avec l'entreprise
                    </button>
                </div>

            </div>

        </div>

    </SubPageLayout>);
}


// <button className={"btn-secondary "} onClick={() => {
//     notification(appContextV2.dispatchPanels, "Lien copié", "success");
//     if(appContextV2.appSession.app.mode === "production") {
//         let copyArea = document.getElementById("signature");
//         copyArea!.innerHTML = `<a style="color: blue; text-decoration: underline;" href="https://black-belt.myshortlist.co/candidat/${item.token}">
//                         Voir le profil de ${props?.candidate?.candidate?.firstName ?? ""} ${props?.candidate?.candidate?.lastName ?? ""}</a>`
//         copyArea!.focus();
//         document.execCommand("selectAll");
//         document.execCommand("copy");
//     } else {
//         let copyArea = document.getElementById("signature");
//         copyArea!.innerHTML = `<a style="color: blue; text-decoration: underline;" href="http://localhost:3001/candidat/${item.token}">
//                         Voir le profil de ${props?.candidate?.candidate?.firstName ?? ""} ${props?.candidate?.candidate?.lastName ?? ""}</a>`
//         copyArea!.focus();
//         document.execCommand("selectAll");
//         document.execCommand("copy");
//     }
// }}>Copier le lien BB Formaté
// </button>}