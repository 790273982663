import AppContext, {IAppV2Context} from "../../../../../../App/context/AppContext";
import React, {useContext, useState} from "react";
import Spaces from "../../../../../Spaces";

export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const [from,setFrom]:any=useState();
    const [to,setTo]:any=useState();
    const [schoolType,setSchoolType]:any=useState();
    const [degreeName,setDegreeName]:any=useState();
    const [major,setMajor]:any=useState();




    return (<>

        <div className={"black-belt-window__container--mini"}>
            Formation:
            <br/><br/>

            {props.infos.education.map((item: any,key:number) => (<>
                <div></div>
                <div><span className={"black-belt-window__title"}>A partir de:</span>
                    <input className={"black-belt-window__input"} value={item.from}
                           onChange={(e) => {
                               props.dispatchInfos({
                                   type: "UPDATE_ARRAY",
                                   value: {field:"education",index:key, subfield:"from" ,value: e.target.value}
                               })
                           }}/>
                </div>

                <div><span className={"black-belt-window__title"}>Jusqu'a:</span>
                    <input className={"black-belt-window__input"} value={item.to}
                           onChange={(e) => {
                               props.dispatchInfos({
                                   type: "UPDATE_ARRAY",
                                   value: {field:"education",index:key, subfield:"to" ,value: e.target.value}
                               })
                           }}/>
                </div>

                <div><span className={"black-belt-window__title"}>Ecole:</span>
                    <input className={"black-belt-window__input"} value={item.schoolType}
                           onChange={(e) => {
                               props.dispatchInfos({
                                   type: "UPDATE_ARRAY",
                                   value: {field:"education",index:key, subfield:"schoolType" ,value: e.target.value}
                               })
                           }}/>
                </div>

                <div><span className={"black-belt-window__title"}>Diplome:</span>
                    <input className={"black-belt-window__input"} value={item.degreeName}
                           onChange={(e) => {
                               props.dispatchInfos({
                                   type: "UPDATE_ARRAY",
                                   value: {field:"education",index:key, subfield:"degreeName" ,value: e.target.value}
                               })
                           }}/>
                </div>

                <div><span className={"black-belt-window__title"}>Major:</span>
                    <input className={"black-belt-window__input"} value={item.major}
                           onChange={(e) => {
                               props.dispatchInfos({
                                   type: "UPDATE_ARRAY",
                                   value: {field:"education",index:key, subfield:"major" ,value: e.target.value}
                               })
                           }}/>
                </div>


                <Spaces n={1}/>
                <button  className={"message--v2__chat__button"} onClick={()=>{
                    props.dispatchInfos({
                        type: "REMOVE_ELEMENT_FROM_ARRAY",
                        value: {field:"education",index:key}
                    })
                }}> Supprimer </button>
                <Spaces n={1}/>

            </>))}


            <div><span className={"black-belt-window__title"}>A partir de:</span>
                <input className={"black-belt-window__input"} value={from}
                       onChange={(e) => {
                          setFrom(e.target.value)
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Jusqu'a:</span>
                <input className={"black-belt-window__input"} value={to}
                       onChange={(e) => {
                          setTo(e.target.value)
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Ecole:</span>
                <input className={"black-belt-window__input"} value={schoolType}
                       onChange={(e) => {
                          setSchoolType(e.target.value)
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Diplome:</span>
                <input className={"black-belt-window__input"} value={degreeName}
                       onChange={(e) => {
                          setDegreeName(e.target.value)
                       }}/>
            </div>

            <div><span className={"black-belt-window__title"}>Major:</span>
                <input className={"black-belt-window__input"} value={major}
                       onChange={(e) => {
                        setMajor(e.target.value)
                       }}/>
            </div>

            <Spaces n={1}/>
            <button  className={"message--v2__chat__button"} onClick={()=>{
                props.dispatchInfos({
                    type: "ADD_ELEMENT_TO_ARRAY",
                    value: {field:"education",
                        value:{
                            from:from,
                            to:to,
                            schoolType:schoolType,
                            degreeName:degreeName,
                            major:major,
                        }}
                })
                setFrom("");
                setTo("");
                setSchoolType("");
                setDegreeName("");
                setMajor("");
            }}> Ajouter </button>
            <Spaces n={1}/>



        </div>
    </>);
}