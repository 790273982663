export default(input: any) => {
  try {
    
    if(typeof input === "number") return input.toString();
    
    if(typeof input !== "string") return input;
    
    let output = "";
    
    for(let c of input) {
      if(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].includes(c)) output += c;
    }
    
    return output;
    
  } catch(error) {
    return input;
  }
}