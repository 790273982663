import {IAppV2Navigation} from "../state/IAppV2";

export {navigationReducer};

export type TNaviationReducers =
  "SET_PAGE" |
  "SET_SUB_PAGE" |
  "SET_SCROLL" |
  "SET_MISSIONID" |
  "SET_POOLID";

const navigationReducer = (state: IAppV2Navigation, action: {
  type: TNaviationReducers,
  value: any,
}): IAppV2Navigation => {
  
  switch(action.type) {
    
    case 'SET_PAGE':
      if(action.value !== state.page) {
        return ({
          ...state,
          page: action.value,
        });
      }
      return (state);
    
    case 'SET_SUB_PAGE':
      if(action.value !== state.subPage) {
        return ({
          ...state,
          subPage: action.value,
        });
      }
      return (state);
  
    case 'SET_SCROLL':
        return ({
          ...state,
          scrolled: action.value,
        });
  
    case 'SET_MISSIONID':
      return ({
        ...state,
        missionId: action.value,
      });

    case 'SET_POOLID':
      return ({
        ...state,
        poolId: action.value,
      });
      
    default:
      throw new Error(`Type ${action.type} is not defined in navigationReducer`);
  }
  
}