import React from "react";
import {SecondaryNavBarWrapper} from "./shared/SecondaryNavBarWrapper";
import SubMenuRecruiterMainContent from "../SubNavbarComponents/SubMenuRecruiterMainContent";

export {SecondaryNavBarRecruiterMain};

function SecondaryNavBarRecruiterMain(props: any) {
  
  return (
    <div className={"hide-on-computers"}>
      <SecondaryNavBarWrapper content={<SubMenuRecruiterMainContent/>}/>
    </div>);
}
