import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import PageLayoutWithHeader from "../../layouts/PageLayoutWithHeader";
import Spaces from "../../components/sharedComponents/Spaces";


export default (props: any) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

    return (
        <PageLayoutWithHeader page={props.page} status={"ok"}>

            <div className={"text--center"}>
                CandidateFavorites
            </div>


        </PageLayoutWithHeader>);
}
