import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";

export default function(props: {
  currentStatus: string,
  reduce: Function,
  jobDesc: any,
  saveJobDesc: Function,
  areButtonsActive: boolean,
  publish: Function,
  form: any,


}) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const isClientBlackBelt = appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" ;
    const jobDescExists=!!(props.jobDesc.file) || !!(props.form.documentUrl);

    return (<section className={"editor-v2__actions"}>
    <button title={"Réduis toutes les questions pour y voir plus clair"}
            className={"editor-v2__actions__btn"}
            onClick={() => props.reduce()}>
      Tout réduire
    </button>
    <button disabled={!props.areButtonsActive} title={"Sauvegarde le poste sans le publier et laisse tes collègues y accéder"}
            className={"editor-v2__actions__btn"}
            onClick={async() => {
              if(!!props.jobDesc.file && !!props.jobDesc.file[0]) {
                let fileUrl = await props.saveJobDesc();
                console.log("file saved should be", fileUrl)
                props.publish("collaboration", fileUrl);
              } else {
                props.publish("collaboration", '');
              }
            }}>
      Mettre en collaboration
    </button>
    <button disabled={!props.areButtonsActive} title={"Sauvegarde le poste sans le publier ni le partager à tes collègues"}
            className={"editor-v2__actions__btn"}
            onClick={async() => {
              if(!!props.jobDesc.file && !!props.jobDesc.file[0]) {
                let fileUrl = await props.saveJobDesc();
                props.publish("draft", fileUrl);
              } else {
                props.publish("draft", '');
              }
            }}>
      Mettre en brouillon
    </button>
    
    <button disabled={!props.areButtonsActive || !appContextV2.appSession.session.isEmailVerified || (!jobDescExists && isClientBlackBelt)}
            title={"Publie le poste pour commencer à recevoir des candidatures"}
            className={"editor-v2__actions__btn"}
            onClick={async() => {
              if(!!props.jobDesc.file && !!props.jobDesc.file[0]) {
                let fileUrl = await props.saveJobDesc();
                props.publish("published", fileUrl);
              } else {
                props.publish("published", '');
              }
            }}>
      Publier
    </button>
  </section>);
}