import React from "react";
import DisplayTutorialSteps from "../../DisplayTutorialSteps";

export default (props: any) => {

    return (<div className={"window-tutorial__content"}>

        <div className={"window-tutorial__main-content animation-fade-in"}>
            <h1 className={"window-tutorial__title1"}>  Publiez votre annonce </h1>

            <p className={"window-tutorial__container"}><br/>
                Pour chaque annonce, un lien est généré pour que les candidats puissent postuler.<br/><br/>
                Ce lien peut être publié sur toutes vos plateformes favorites: LinkedIn, FaceBook, Twitter, Indeed, Pôle Emploi, Apec ...<br/><br/>
                N'hésitez pas à vous mettre à la place du candidat pour tester le questionnaire !<br/><br/>
            </p>
        </div>

        <div className={"window-tutorial__secondary-content"}>
            <DisplayTutorialSteps {...props}/>

            <div>
                <button className={"btn-secondary"} onClick={async (e) => props.hideTutorialForEver()}>
                    Ne plus afficher
                </button>

                <button className={"btn-secondary"} onClick={() => props.setStep((ps: number) => ps + 1)}>Suivant</button>
            </div>
        </div>

    </div>);
}

