import AppContext, {IAppContext, IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import {Link, withRouter} from "react-router-dom";
import MenuDropDownLogout from "./MenuDropDownLogout";
import DropDownLinkGenerator from "./shared/DropDownLinkGenerator";


export default function MenuRecruiterDropDownContent(props: any) {
    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    return (<>
      {appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" && <DropDownLinkGenerator uri={"/businesses/blackBelt"} active={true} fr={"BlackBelt"} en={"BlackBelt"} />}
      <DropDownLinkGenerator uri={"/business/profiles"} active={true} fr={"Profils"} en={"Profiles"} />
      {/*<DropDownLinkGenerator uri={"/business/calendar"} active={true} fr={"Calendrier"} en={"Calendar"} />*/}
      <DropDownLinkGenerator uri={"/business/parameters"} active={true} fr={"Paramètres"} en={"Parameters"} />
      <MenuDropDownLogout />
    </>
  );
}
