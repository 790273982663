import React, {useEffect} from "react"
import axios from "axios";
import queryString from "query-string";

export default (props: any) => {
    const [aiStatus, setAiStatus] =
        React.useState<"unknown" | "parsed" | "ready" | "notReady">("unknown");
    const [refresh, setRefresh] = React.useState(0)

    const adminMode = queryString.parse(window.location.search ?? "").admin === "true"

    useEffect(() => {
        if (!!props.candidate?.candidacy?.aiList && props.candidate?.candidacy?.aiList?.length > 0) {
            setAiStatus("parsed")
            return
        }
        if (!!props.candidate?.parsedIn || !!props.candidate?.parsedResume) {
            setAiStatus("ready")
            return
        }
        setAiStatus("notReady")
    }, [props.candidate, refresh])

    return <div className={"text--grey container"}>
        <div style={{padding: "20px 30px"}}>
            La fonctionnalité IA analyse l'adequation entre le profil du candidat et le poste, et vous guide sur des points a verifier avec le candidat.
        </div>

        <div style={{padding: "20px 30px"}}>
            {aiStatus === "unknown" && <>Analyse du status en cours</>}
            {aiStatus === "notReady" && <><AINotReady /><br/><br/></>}
            {aiStatus === "parsed" && <>{props.candidate.candidacy.aiList.map((ai: string) => <AiResult ai = {ai} />)} <br/><br/></>}
        </div>

        { (aiStatus === "ready" || aiStatus === "parsed") && <AIReady {...props} /> }

    </div>
}

function AIReady(props: any) {
    const [loading, setLoading] = React.useState<boolean>(false)
    async function parseAi() {
        setLoading(true)
        try {
            const answer = await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/ai/parse-candidates`, {
                missionId: props.candidate?.candidacy?.missionId,
                candidatesIds: [props.candidate?.candidate?._id]
            }, {
                withCredentials: true
            });
            if (answer.data.status !== "ok") {
                alert(answer.data.status)
            } else {
                alert(`Réouvre le candidat dans environ ${30} secondes`)
            }
        } catch (error) {
            console.error(error)
            alert("Une erreur est survenue")
        } finally {
            setLoading(false)
        }
    }
    return <>Analyser le candidat: <button disabled={loading} onClick={parseAi}>Analyzer</button>
    <br/><br/>
    </>
}

function AINotReady(props: any) {
    return <>Ce candidat ne peut actuellement pas être analysé</>
}

function AiResult(props: any) {
    const [jsonData, setJsonData] = React.useState<any>({})
    useEffect(() => {
        if (isJson(props.ai)) {
            setJsonData(JSON.parse(props.ai))
        }
    }, [])
    if (!props.ai) {
        return <>An error occurred</>
    } else if (isJson(props.ai)) {
        return <div>
            <hr/>
            <div>Note sur 100: {jsonData.grade}</div>
            <br/>
            <div>Poursuivre avec le candidat: {jsonData.go ? 'Oui' : 'Non'}</div>
            <br/>
            <div>Pourquoi ce choix: {jsonData.why}</div>
            <br/>
            <div>
                Points a verifier avec le candidat:
                {Array.isArray(jsonData.toCheck) && jsonData.toCheck?.map((item: any) => <div>- {item}</div>)}
                {!Array.isArray(jsonData.toCheck) && jsonData.toCheck}
            </div>
            <br/>
            <div>
                Red flags:
                {Array.isArray(jsonData.redFlags) && jsonData.redFlags?.map((item: any) => <div>- {item}</div>)}
                {!Array.isArray(jsonData.redFlags) && jsonData.redFlags}
            </div>
        </div>
    } else {
        return <><div><hr/>{props.ai}</div></>
    }
}

function isJson(str: string) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}