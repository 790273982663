import {devLog} from "../../../../utils/devLog/devLog";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import SubPageLayout from "../../../../layouts/SubPageLayout";
import {NotOnSmartphones} from "../../../../components/sharedComponents/NotOnSmartphones";
import MissionKanbanNav from "./componentsRecruiterMissionKanban/MissionKanbanNav";
import getContract from "../../../../utils/getContract";
import {capitalizeSentence} from "../../../../utils/converters";
import Spaces from "../../../../components/sharedComponents/Spaces";
import AbstractKanbanColumnV2 from "./componentsRecruiterMissionKanban/AbstractKanbanColumnV2";
import fnMoveUnsubscribedCandidacyFront
  from "./dependenciesRecruiterMissionKanban/frontUpdates/fnMoveUnsubscribedCandidacyFront";
import fnAddCandidacyFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnAddCandidacyFront";
import fnAddColumnFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnAddColumnFront";
import fnSetColumnId from "./dependenciesRecruiterMissionKanban/frontUpdates/fnSetColumnId";
import fnDeleteColumnFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnDeleteColumnFront";
import fnUpdateColumnFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnUpdateColumnFront";
import fnSetNewColumn from "./dependenciesRecruiterMissionKanban/frontUpdates/fnSetNewColumn";
import fnMoveColumnFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnMoveColumnFront";
import fnMoveCandidacyFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnMoveCandidacyFront";
import fnUpdateCandidaciesBulk from "./dependenciesRecruiterMissionKanban/frontUpdates/fnUpdateCandidaciesBulk";
import fnRemoveUnregisteredCandidacyFront
  from "./dependenciesRecruiterMissionKanban/frontUpdates/fnRemoveUnregisteredCandidacyFront";
import fnUpdateUnregisteredCandidacyFront
  from "./dependenciesRecruiterMissionKanban/frontUpdates/fnUpdateUnregisteredCandidacyFront";
import onDragCandidacy from "./dependenciesRecruiterMissionKanban/dragAndDrop/onDragCandidacy";
import onDropCandidacy from "./dependenciesRecruiterMissionKanban/dragAndDrop/onDropCandidacy";
import fetchShortlist from "./dependenciesRecruiterMissionKanban/fetchers/fetchShortlist";
import filterCandidacies from "./dependenciesRecruiterMissionKanban/filterCandidacies";
import dataReducerKanban from "./dependenciesRecruiterMissionKanban/dataReducerKanban";
import fnUpdateCandidacyFront from "./dependenciesRecruiterMissionKanban/frontUpdates/fnUpdateCandidacyFront";
import axios from "axios";
import dataReducerSelected from "./dependenciesRecruiterMissionKanban/dataReducerSelected";
import React, {useContext, useEffect, useReducer, useState} from "react";
import RecruiterMissionKanbanNavbar from "./componentsRecruiterMissionKanban/RecruiterMissionKanbanNavbar";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const missionId= props.match.params.missionid;

  /** Fetch shortlist, mission and business profiles */
  const [fetchStatus, setFetchStatus]: ["asleep" | "fetching" | "fetched" | "error", Function] = useState("asleep");
  
  /**
   * Temporary tests
   */
  const [businesses, setBusinesses] = useState([]);

  const [fetchedDataV2, dispatchDataV2]: [any, Function] = useReducer(dataReducerKanban, {
    businessProfile: {},
    missionProfile: {},
    shortlist: [],
    shortlistUnsubscribed: [],
  });
  const [selected, dispatchSelected] = useReducer(dataReducerSelected, []);
  const [displayedShortlist, setDisplayedShortlist]: [any, any] = useState([]);
  const [displayedUnsubscribedShortlist, setDisplayedUnsubscribedShortlist]: [any, any] = useState([]);
  devLog(displayedShortlist);
  
  /** Filters */
  const [filterByWords, setFilterByWords] = useState("");
  const [filterByYearsOfExperience, setFilterByYearsOfExperience]: any = useState();
  const [filterBySalaryMin, setFilterBySalaryMin]: any = useState();
  const [filterByDateOfAvailability, setFilterByDateOfAvailability]: any = useState();
  const [filterByTraining, setFilterByTraining] = useState("");
  const [filterByMark, setFilterByMark] = useState("");
  const areFiltersActive = !!filterByWords || !!filterByMark || !!filterByYearsOfExperience || !!filterBySalaryMin
    || !!filterByDateOfAvailability || !!filterByTraining;
  
  /** Dragged candidacy */
  const [dragged, setDragged] = useState("");
  const [draggedType, setDraggedType]: [string, Function] = useState("");
  const [draggedFromColumnId, setDraggedFromColumnId] = useState("");
  useEffect(() => {
    setDraggedFromColumnId(displayedShortlist?.find((item: any) => (item?._id === dragged))?.kanbanColumn);
  }, [dragged]);
  devLog(draggedFromColumnId);
  
  /** Used to disable buttons when actions are operated */
  const [isLoading, setIsLoading] = useState(false);
  
  /** Selection mode */
  const [selectionMode, setSelectionMode] = useState(false);
  
  /** Update displayed users according to filters */
  useEffect(() => {
    filterCandidacies({
      fetchedDataV2,
      filterByWords,
      filterByMark,
      filterByYearsOfExperience,
      filterBySalaryMin,
      filterByDateOfAvailability,
      filterByTraining,
      setDisplayedShortlist,
    });
  }, [fetchedDataV2.shortlist, filterByWords, filterByMark, filterByYearsOfExperience,
    filterBySalaryMin, filterByDateOfAvailability, filterByTraining]);
  
  /** Fetch shortlist and business + mission profiles */
  useEffect(() => {
    fetchShortlist({
      missionId: props.match.params.missionid,
      setFetchStatus,
      dispatchDataV2,
      setDisplayedUnsubscribedShortlist,
      dispatchPanels: appContextV2.dispatchPanels,
    });
  }, []);
  devLog("fetchedData", fetchedDataV2);
  
  useEffect(() => {
    (async function() {
      try {
        if(appContextV2.appNavigation.missionId) {
          const newCandidacies = appContextV2.appNotifications.recruiter?.newCandidaciesForMissions?.filter((item: any) => (item?.actionType === "newCandidacy" &&
            item?.seenByMe === false && item?.toMissionId === appContextV2.appNavigation.missionId)).length;
          if(newCandidacies !== 0) {
            appContextV2.dispatchNotifications({
              type: "UPDATE_SEEN_CANDIDACIES_FOR_MISSION",
              value: appContextV2.appNavigation.missionId
            });
            await axios.put(`${process.env.REACT_APP_API_URL}/v3/data/notifications/seen/new/candidacies`, {
              missionId: appContextV2.appNavigation.missionId,
            }, {withCredentials: true});
          }
        }
      } catch(error) {
        devLog(error);
      }
    })()
  }, [appContextV2.appNavigation.missionId]);
  
  /**
   * Temporary test: get all accounts
   */
  useEffect(() => {
    (async() => {
      try {
        const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/bb/clients`,
          {
            withCredentials: true
          })).data;
        if(status === "ok") {
          setBusinesses(data);
        }
      } catch(error) {
      
      }
    })();
  }, []);

  const frontUpdates = {
    addColumnFront: fnAddColumnFront({dispatchDataV2, fetchedDataV2, dispatchPanels: appContextV2.dispatchPanels}),
    setsColumnId: fnSetColumnId({dispatchDataV2}),
    deleteColumnFront: fnDeleteColumnFront({dispatchDataV2}),
    updateColumnFront: fnUpdateColumnFront({dispatchDataV2}),
    setNewColumns: fnSetNewColumn({dispatchDataV2}),
    moveColumnFront: fnMoveColumnFront({dispatchDataV2}),
    moveCandidacyFront: fnMoveCandidacyFront({dispatchDataV2}),
    addCandidacyFront: fnAddCandidacyFront({dispatchDataV2}),
    updateCandidaciesBulk: fnUpdateCandidaciesBulk({dispatchDataV2}),
    moveUnsubscribedCandidacyFront: fnMoveUnsubscribedCandidacyFront(setDisplayedUnsubscribedShortlist),
    removeUnregisteredCandidacyFront: fnRemoveUnregisteredCandidacyFront({setDisplayedUnsubscribedShortlist}),
    updateUnregisteredCandidacyFront: fnUpdateUnregisteredCandidacyFront({setDisplayedUnsubscribedShortlist}),
    updateCandidacyFront: fnUpdateCandidacyFront({dispatchDataV2}),
  };
  
  const drag = onDragCandidacy({
    setDraggedType,
    setDragged
  });
  
  const drop = onDropCandidacy({
    draggedType,
    displayedShortlist,
    dragged,
    fetchedDataV2,
    setIsLoading,
    frontUpdates,
    draggedFromColumnId,
    appContextV2,
  });

  console.log("missionId",fetchedDataV2.missionProfile?.missionId);
  
  return (<SubPageLayout status={fetchStatus} subPage={props.subPage}>
    
    <NotOnSmartphones/>

    <div className={"kanban-container"}>

      <section className={"kanban-template hide-on-smartphones"}>
        <article className={"kanban-template__body"}>

          <RecruiterMissionKanbanNavbar frontUpdates={frontUpdates}
                                        setFilterByWords={setFilterByWords}
                                        setFilterByMark={setFilterByMark}
                                        filterByWords={filterByWords}
                                        filterByMark={filterByMark}
                                        filterByYearsOfExperience={filterByYearsOfExperience}
                                        setFilterByYearsOfExperience={setFilterByYearsOfExperience}
                                        filterBySalaryMin={filterBySalaryMin}
                                        setFilterBySalaryMin={setFilterBySalaryMin}
                                        filterByDateOfAvailability={filterByDateOfAvailability}
                                        setFilterByDateOfAvailability={setFilterByDateOfAvailability}
                                        filterByTraining={filterByTraining}
                                        setFilterByTraining={setFilterByTraining}
                                        missionId={missionId}
          />
          <Spaces n={1}/>
          <div className={"kanban-job-title"}>
            Candidats au poste
            de: {capitalizeSentence(fetchedDataV2.missionProfile?.positionWanted)}, {getContract(fetchedDataV2.missionProfile?.contract)}
          </div>

          <Spaces n={0}/>


          <div className={"kanban"}>

            {/* -------------------- Basic column 1 ----------------------------*/}
            <AbstractKanbanColumnV2 columnId={"1"}
                                    columnData={{}}
                                    rank={1}
                                    draggedFromColumnId={draggedFromColumnId}
                                    keywords={filterByWords}
                                    areFiltersActive={areFiltersActive}
                                    setIsLoading={setIsLoading}
                                    title={"Candidatures reçues"}
                                    drag={drag}
                                    drop={drop}
                                    missionId={fetchedDataV2.missionProfile?.missionId}
                                    customKanbanView={fetchedDataV2.missionProfile?.customKanbanView}
                                    shortlist={displayedShortlist?.filter((item2: any) => (item2?.kanbanColumn === "1"))}
                                    shortlistUnsubscribed={displayedUnsubscribedShortlist?.filter((item2: any) => (!item2?.column || item2?.column === "1")) || []}
                                    frontUpdates={frontUpdates}
                                    selectionMode={selectionMode}
                                    setSelectionMode={setSelectionMode}
                                    selected={selected}
                                    dispatchSelected={dispatchSelected}

            />

            {/*/!* -------------------- Custom columns ----------------------------*!/*/}
            {fetchedDataV2.missionProfile?.customKanbanView?.map((item: any, key: number) => (
                <AbstractKanbanColumnV2 key={key}
                                        columnData={item}
                                        draggedFromColumnId={draggedFromColumnId}
                                        keywords={filterByWords}
                                        columnId={item?._id ?? ""}
                                        rank={key + 2}
                                        areFiltersActive={areFiltersActive}
                                        setIsLoading={setIsLoading}
                                        title={item?.name}
                                        drag={drag}
                                        drop={drop}
                                        missionId={fetchedDataV2.missionProfile?.missionId}
                                        customKanbanView={fetchedDataV2.missionProfile?.customKanbanView}
                                        shortlist={displayedShortlist?.filter((item2: any) => (item2?.kanbanColumn === item?._id))}
                                        shortlistUnsubscribed={displayedUnsubscribedShortlist?.filter((item2: any) => (item2?.column === item?._id)) || []}
                                        frontUpdates={frontUpdates}
                                        selectionMode={selectionMode}
                                        setSelectionMode={setSelectionMode}
                                        selected={selected}
                                        dispatchSelected={dispatchSelected}
                />))}

            {/*/!* -------------------- Basic column 10 ----------------------------*!/*/}
            <AbstractKanbanColumnV2 columnId={"10"}
                                    columnData={{}}
                                    draggedFromColumnId={draggedFromColumnId}
                                    areFiltersActive={areFiltersActive}
                                    keywords={filterByWords}
                                    setIsLoading={setIsLoading}
                                    rank={fetchedDataV2.missionProfile?.customKanbanView?.length + 2}
                                    title={"Recrutements"}
                                    drag={drag}
                                    drop={drop}
                                    missionId={fetchedDataV2.missionProfile?.missionId}
                                    customKanbanView={fetchedDataV2.missionProfile?.customKanbanView}
                                    shortlist={displayedShortlist?.filter((item2: any) => (item2?.kanbanColumn === "10"))}
                                    shortlistUnsubscribed={displayedUnsubscribedShortlist?.filter((item2: any) => (item2?.column === "10")) || []}
                                    frontUpdates={frontUpdates}
                                    selectionMode={selectionMode}
                                    setSelectionMode={setSelectionMode}
                                    selected={selected}
                                    dispatchSelected={dispatchSelected}
            />

          </div>

        </article>

        {/*<article className={"kanban-template__nav"}>*/}

          {/*------------------------------------------------------- Navbar ------------------------------------*/}
          {/*<MissionKanbanNav frontUpdates={frontUpdates}*/}
          {/*                  setIsLoading={setIsLoading}*/}
          {/*                  setFilterByWords={setFilterByWords}*/}
          {/*                  setFilterByMark={setFilterByMark}*/}
          {/*                  filterByWords={filterByWords}*/}
          {/*                  filterByMark={filterByMark}*/}
          {/*                  filterByYearsOfExperience={filterByYearsOfExperience}*/}
          {/*                  setFilterByYearsOfExperience={setFilterByYearsOfExperience}*/}
          {/*                  filterBySalaryMin={filterBySalaryMin}*/}
          {/*                  setFilterBySalaryMin={setFilterBySalaryMin}*/}
          {/*                  filterByDateOfAvailability={filterByDateOfAvailability}*/}
          {/*                  setFilterByDateOfAvailability={setFilterByDateOfAvailability}*/}
          {/*                  filterByTraining={filterByTraining}*/}
          {/*                  setFilterByTraining={setFilterByTraining}*/}
          {/*                  numberOfColumns={fetchedDataV2.missionProfile?.customKanbanView?.length}*/}
          {/*                  isLoading={isLoading}*/}
          {/*                  numberOfCandidates={fetchedDataV2?.shortlist?.length || 0}*/}
          {/*                  numberOfCandidates1={fetchedDataV2?.shortlist.filter((item: any) => (getGlobalMark(item?.markSkills, item?.markForm) <= 100 && getGlobalMark(item?.markSkills, item?.markForm) >= 90 && !item?.imported)).length || 0}*/}
          {/*                  numberOfCandidates2={fetchedDataV2?.shortlist.filter((item: any) => (getGlobalMark(item?.markSkills, item?.markForm) < 90 && getGlobalMark(item?.markSkills, item?.markForm) >= 70 && !item?.imported)).length || 0}*/}
          {/*                  numberOfCandidates3={fetchedDataV2?.shortlist.filter((item: any) => (getGlobalMark(item?.markSkills, item?.markForm) < 70 && getGlobalMark(item?.markSkills, item?.markForm) >= 50 && !item?.imported)).length || 0}*/}
          {/*                  numberOfCandidates4={fetchedDataV2?.shortlist.filter((item: any) => (getGlobalMark(item?.markSkills, item?.markForm) < 50 && !item?.imported)).length || 0}*/}
          {/*                  numberOfCandidatesImported={fetchedDataV2?.shortlist.filter((item: any) => item?.imported).length || 0}*/}
          {/*                  numberOfCandidatesBlocked={fetchedDataV2?.shortlist.filter((item: any) => (item?.blockedByAlgorithm || item?.status === "blocked")).length || 0}*/}
          {/*                  selectionMode={selectionMode}*/}
          {/*                  setSelectionMode={setSelectionMode}*/}
          {/*                  selected={selected}*/}
          {/*                  dispatchSelected={dispatchSelected}*/}
          {/*                  customKanbanView={fetchedDataV2.missionProfile?.customKanbanView}*/}
          {/*                  dispatchDataV2={dispatchDataV2}*/}
          {/*                  shortlist={displayedShortlist}*/}

          {/*/>*/}


        {/*</article>*/}

      </section>

    </div>
  
  </SubPageLayout>);
}
