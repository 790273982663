import React, {useEffect, useReducer, useState,} from "react";
import "./style/App.scss";
import {BrowserRouter as Router} from "react-router-dom";
import {AppProvider, IAppV2Context} from "./context/AppContext";
import {initialSession} from "./state/initialSession";
import {initialNavigation} from "./state/initialNavigation";
import {initialPanels} from "./state/initialPanels";
import {sessionReducer} from "./reducers/sessionReducer";
import {navigationReducer} from "./reducers/navigationReducer";
import {panelsReducer} from "./reducers/panelsReducer";
import welcomeConsole from "../../utils/dom/welcomeConsole";
import {escapeKeyListener} from "../../utils/listeners/escapeKeyListener";
import {SwitchAppStatus} from "./SwitchAppStatus";
import {devLog} from "../../utils/devLog/devLog";
import {fetchSession} from "./fetchers/fetchSession";
import setSession from "./fetchers/setSession";
import axios from "axios";
import {notificationsReducer} from "./reducers/notificationsReducer";
import {initialNotifications} from "./state/initialNotifications";

export {App};

/**
 * App is the wrapper for the whole application
 */
const App = (props: {}) => {
  
  useEffect(() => {
    welcomeConsole();
  }, []);
  
  /** ----- Index state */
  const [appSession, dispatchSession] = useReducer(sessionReducer, initialSession);
  const [appNavigation, dispatchNavigation] = useReducer(navigationReducer, initialNavigation);
  const [appPanels, dispatchPanels] = useReducer(panelsReducer, initialPanels);
  const [appNotifications, dispatchNotifications] = useReducer(notificationsReducer, initialNotifications);
  const [globalResearch, setGlobalResearch] = useState<string>("");
  
  /** ----- Session fetcher */
  const [sessionFetchedStatus, setSessionFetchedStatus] = useState("asleep");
  const [fetchedSession, setFetchedSession] = useState({});
  
  const [displayWelcome, setDisplayWelcome] = useState(true);
  
  /** ----- Fetches current session */
  useEffect(() => {
    fetchSession({dispatchPanels, setSessionFetchedStatus, setFetchedSession})();
  }, []);
  
  /** ----- Sets session */
  useEffect(() => {
    setSession({sessionFetchedStatus, fetchedSession, dispatchSession})
  }, [sessionFetchedStatus]);
  
  /** ---- Load parameters */
  useEffect(() => {
    (async function() {
      try {
        const answer = await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/session/s/parameters`, {withCredentials: true});
        dispatchSession({type: 'SET_PARAMETERS', value: answer.data});
      } catch(error) {
        devLog(error);
      }
    })()
  }, []);
  
  /** ---- Load notifications */
  useEffect(() => {
    (async function() {
      try {
        if(appSession.app.space === "recruiter") {
          const answer = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/data/notifications/business/all`, {withCredentials: true})).data;
          dispatchNotifications({type: 'SET_NOTIFICATIONS_RECRUITER', value: answer.data});
        }
      } catch(error) {
        devLog(error);
      }
    })();
  }, [appSession.app.space]);
  
  /** ---- Reload notifications (Not a good practise) */
  useEffect(() => {
    if(appSession.app.space === "recruiter") {
      const timer = setInterval(async() => {
        const answer = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/data/notifications/business/all`, {withCredentials: true})).data;
        dispatchNotifications({type: 'SET_NOTIFICATIONS_RECRUITER', value: answer.data});
      }, 5 * 60 * 1000);
      return () => {
        clearInterval(timer);
      }
    }
  }, [appSession.app.space]);
  
  useEffect(() => {
    document.title = `Recrutez mieux, plus vite`;
  }, []);
  
  /** ----- Add the escape key listener */
  useEffect(escapeKeyListener(() => {
    dispatchPanels({type: "CLOSING_WINDOW", value: null});
    const timer = setTimeout(() => {
      dispatchPanels({type: "CLOSE_WINDOW", value: null});
      clearTimeout(timer);
    }, 130);
  }), []);
  
  /** ----- Sets Index context */
  let contextValue: IAppV2Context = {
    appSession: appSession,
    appNavigation: appNavigation,
    appPanels: appPanels,
    appNotifications:appNotifications,
    dispatchNotifications:dispatchNotifications,
    globalResearch: globalResearch,
    setGlobalResearch: setGlobalResearch,
    dispatchSession: dispatchSession,
    dispatchNavigation: dispatchNavigation,
    dispatchPanels: dispatchPanels,
    fetchSession: fetchSession({dispatchPanels, setSessionFetchedStatus, setFetchedSession}),
    displayWelcome,
    setDisplayWelcome
  };
  
  devLog("contextValue", contextValue);
  
  return (<AppProvider value={contextValue}>
    <Router>

      <SwitchAppStatus/>

    </Router>
  </AppProvider>);
}
