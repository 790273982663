import {format} from "date-fns";

export function displayDate(args:{original: Date, forInput?:boolean}): any {
    try {
        if(!args.original) return '';
        let formatDate="dd/MM/yyyy";
        if(args.forInput) formatDate="yyyy/MM/dd";
        console.log('################### Format date', formatDate);
        return( format(new Date(args.original), formatDate));
    }
    catch (error) {
        return '';
    }
}
