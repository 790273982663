import React, {useContext} from 'react';
import AppContext, {IAppV2Context} from "../../App/context/AppContext";
import NavBarLogoContent from "./componentsNavBar/NavBarLogoContent";
import {SubMenuCandidatePc, SubMenuRecruiterPc} from "./componentsNavBar/NavBarSubNavPc";
import { NavBarWrapper } from './componentsNavBar/NavBarWrapper';
import {MenuDropDown} from "./componentsNavBar/MenuDropDown";
import {Link} from "react-router-dom";

export {NavBar};

/**
 * Main NavBar.
 */
const NavBar = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  /** Define pages with a minimalist NavBar */
  const pagesWithMinimalisticNavbar: any = ["Invite", "PublicApply", "BusinessPage", "PublicProfileUser",
    "PublicProfileBusiness", "CandidateAction", "CandidateFeedbackOnPlatform", "EvaluateCandidate"];
  
  /** Define pages with no NavBar */
  const pagesWithoutNavbar: any = ["Login", "CandidateAction", "LandingPage"];
  
  if(pagesWithMinimalisticNavbar.includes(appContextV2.appNavigation.page)) {
    const isUserIdentified: boolean = ["recruiter", "candidate"].includes(appContextV2.appSession.app.space);
    return (<NavBarWrapper>
      <div className={"business-page--v2__logo"}>
        <Link to={"/"}>
          <button className={"btn--hollow"}>
            <span className={`navbar__logo__image ${ "navbar__logo__image--grey" }`}>
              <span
                className={`navbar__logo__image__content ${"navbar__logo__image__content--red" }`}>ms</span>
            </span>
          </button>
        </Link>
        <div className={"business-page--v2__logo__content"}>
          <div className={"business-page--v2__logo__content__title"}>
            Myshortlist.co
          </div>
          <div className={"business-page--v2__logo__content__subtitle"}>
            Recrutez mieux, plus vite
          </div>
        </div>
      </div>
      
      <Link to="/">
        <button className={"btn-secondary"}>
          {isUserIdentified ? "Retour à ma page" : ""}
          {!isUserIdentified ? "Accès au site" : ""}
        </button>
      </Link>
    </NavBarWrapper>);
  }
  
  if(pagesWithoutNavbar.includes(appContextV2.appNavigation.page)) {
    return(<></>);
  }
  
  switch(appContextV2.appSession.app.space) {
    
    case 'public':
      return (<NavBarWrapper>
        <NavBarLogoContent/>
        <MenuDropDown/>
      </NavBarWrapper>);
      
    case 'candidate':
      return (<NavBarWrapper>
        <SubMenuCandidatePc/>
        <MenuDropDown/>
      </NavBarWrapper>);
      
    case 'recruiter':
      return (<NavBarWrapper>
        <SubMenuRecruiterPc/>
        <MenuDropDown/>
      </NavBarWrapper>);
      
    default:
      return(<></>);
  }
}


