export default (array: any, previousIndex: any, newIndex: any) => {
  const array2 = [...array];
  if (newIndex >= array2.length) {
    var k = newIndex - array2.length + 1;
    while (k--) {
      array2.push(undefined);
    }
  }
  array2.splice(newIndex, 0, array2.splice(previousIndex, 1)[0]);
  return array2;
};