import React, {useContext} from "react";
import AppContext, {IAppContext, IAppV2Context} from "../../App/context/AppContext";
import {Link} from "react-router-dom";

export default function LinkButton(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  const language: string = appContextV2.appSession.app.language;
  const space: string = appContextV2.appSession.app.space;
  
  const prefix: string = ((space: string) => {
    switch(space) {
      case 'candidate':
        return 'candidate';
      case 'recruiter':
        return 'business';
      default:
        return 'err';
    }
  })(space);
  
  const helpText: string = ((language: string, space: string) => {
    switch(language) {
      case 'FR':
        return "Partagez le lien magique de votre profil";
      default:
        return "Share your profile magic-link";
    }
  })(language, space);
  
  return (
    <Link to={`/${prefix}/link`}>
      {/*<button title={helpText} className={"btn-picto btn-picto--blue"} onClick={() => {*/}
      {/*  appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: {*/}
      {/*      type: "",*/}
      {/*      data: "",*/}
      {/*    }});*/}
      {/*}}>*/}
      {/*  <img src={"https://static.myshortlist.co/btn-linkink.svg"} alt={'link to your page'} className={""}/>*/}
      {/*</button>*/}
      <button className={"mission--V2__buttons__parameters mission--V2__buttons__parameters--white"}>
        <img src={"https://static.myshortlist.co/btn-linkink.svg"}/>
      </button>
    </Link>
  );
}