import React, {useContext, useEffect, useState} from "react";
import Spaces from "../../../../components/sharedComponents/Spaces";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import LoadingStyle2 from "../../../../components/sharedComponents/Loading/LoadingStyle2";
import {devLog} from "../../../../utils/devLog/devLog";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [ambassadors, setAmbassadors] = useState<any[]>([]);
  const [ambassadorsId, setAmbassadorsId] = useState<string[]>([]);
  const [reward, setReward] = useState<number>(200);
  const [sliderActivated, setSliderActivated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allowSave, setAllowSave] = useState<boolean>(false);
  
  useEffect(() => {
    (async() => {
      try {
          const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/get/all/ambassadors`, {
            headers: {
              rank: props.missionTokens.rank,
            },
            withCredentials: true,
          })).data;
          setAmbassadors(data.ambassadors ?? []);
          setReward(data.reward ?? 200);
          setSliderActivated(data.rewardActivated ?? false);
      } catch(error) {
        throw error;
      }
    })();
  }, []);
  
  devLog("ambassadors", ambassadors);
  
  const toggleAmbassadorSelected = (ambassadorId: string) => {
    setAllowSave(true);
    setAmbassadorsId((ps: string[]) => {
      if(ps.includes(ambassadorId)) {
        return ps.filter((id: string) => id !== ambassadorId);
      }
      return [...ps, ambassadorId];
    });
  }
  
  const isAmbassadorSelected = (ambassadorId: string) => ambassadorsId.includes(ambassadorId);
  
  async function addSelectedAmbassadors() {
    try {
      setIsLoading(true);
      setAllowSave(false);
      setAmbassadors(ambassadors.map((item: any, key: any) => {
        if(ambassadorsId.includes(item._id)) return {...item, alreadySent: true}
        return {...item}
      }));
      const {status, data} = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/add/selected/ambassadors`, {
          ambassadorsId: ambassadorsId,
          rank: props.missionTokens.rank,
          reward: reward
        },
        {withCredentials: true,})).data;
      
      if(status !== "ok") {
        setIsLoading(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        
      }
      
      setAmbassadorsId([]);
      setIsLoading(false);
      return notification(appContextV2.dispatchPanels, "Succès", "success");
      
    } catch(error) {
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const areAmbassadorSelectedAndDisabled = ambassadorsId.length !== 0 && !sliderActivated;
  
  const isButtonActive = !isLoading && allowSave && !areAmbassadorSelectedAndDisabled;
  
  return (
    <article className={`white-container ${!props.white ? "white-container--grey" : ""} `}>
      
      <div className={"ambassador-diffusion__header"}>
        <label className={"switch-V2 space-right"}>
          <input type={"checkbox"} className={"checkbox-on-off ambassador__slider"}
                 checked={sliderActivated}
                 onChange={() => {
                   if(!sliderActivated) setSliderActivated(true);
                   if(sliderActivated) setSliderActivated(false);
                   setAllowSave(true);
                 }}/>
          <span className={"slider"}> </span>
        </label>
        <div className={"text--bold text--magnified11x"}>Sollicitez vos ambassadeurs pour diffuser l'annonce</div>
      </div>
      
      <Spaces n={0}/>
      
      <div className={"text--center"}>
        <p className={"text--grey"}>Déterminez une récompense en euros. Si vous recrutez un candidat généré par un
          ambassadeur, vous vous engagez à récompenser l'ambassadeur à la hauteur de ce montant. Vous pouvez entrer '0' pour solliciter votre réseau sans récompense.</p>
        <br/>
        {sliderActivated &&
        <div className={"animation-fade-in"}><input className={"ambassador-diffusion__input"} type={"number"}
                                                    disabled={!sliderActivated}
                                                    value={reward} onChange={(e) => {
          const newReward = parseInt(e.target.value);
          setReward(newReward);
          setAllowSave(true);
        }}/> €</div>}
      </div>
      
      <Spaces n={0}/>
      
      {
        ambassadors.length === 0 && <>
          Ajoutez des ambassadeurs depuis votre page principale pour les sélectionner ici.
        </>
      }
      
      <div className={"text--center ambassador-container"}>
        <ul>
          {ambassadors.map((ambassador: any, key: any) => (
            <li key={key}>
              <article
                className={`ambassadors--v2__ambassador ambassadors--v2__ambassador--grey ${ambassador.alreadySent ? "ambassador--transparency" : ""}`}>
                <div className={"ambassadors--v2__ambassador__left"}>
                  <input checked={ambassador.alreadySent || isAmbassadorSelected(ambassador._id)} type={"checkbox"}
                         disabled={ambassador.alreadySent} className={"space-right"}
                         onClick={(e) => {
                           toggleAmbassadorSelected(ambassador._id);
                         }}/>
                  <div className={"ambassadors--v2__ambassador__avatar"}>
                    <img src={ambassador?.avatarUrl ? ambassador?.avatarUrl : "https://static.myshortlist.co/koala.png"}/>
                  </div>
                  <div className={"ambassadors--v2__ambassador__element"}>
                    <span
                      className={"text--magnified2x"}>{ambassador.firstName + " " + ambassador.lastName?.toUpperCase()}</span>
                  </div>
                </div>
                <div className={"ambassadors--v2__ambassador__right"}>
                  {ambassador.position && <div className={"ambassadors--v2__ambassador__element"}>
                    Poste : {ambassador.position}
                  </div>}
                </div>
              </article>
            </li>))}
        </ul>
      </div>
      
      <Spaces n={1}/>
      
      <div className={"text--center"}>
        <button disabled={!isButtonActive} className={"btn-secondary"} onClick={() => addSelectedAmbassadors()}>
          Enregistrer / Solliciter les ambassadeurs
        </button>
      </div>
      
      <div className={"text--center"}>
        {isLoading && <>
          <br/>
          <LoadingStyle2/>
        </>}
      </div>
    
    </article>);
  
}