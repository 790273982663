export default(pageInfos: any, businessFetchStatus: any, missionFetchStatus: any, setPageStatus: any) => {
  if(pageInfos.pageType === 'profile') {
    return setPageStatus(businessFetchStatus);
  }
  if(pageInfos.pageType === 'apply') {
    if(businessFetchStatus === 'fetched' && missionFetchStatus === 'fetched') {
      return setPageStatus('fetched');
    }
    if(["asleep", "fetching"].includes(businessFetchStatus) || ["asleep", "fetching"].includes(missionFetchStatus)) {
      return setPageStatus('fetching');
    }
    if(["error", "forbidden"].includes(businessFetchStatus) || ["error", "forbidden"].includes(missionFetchStatus)) {
      return setPageStatus('error');
    }
  }
}