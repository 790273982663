import React from "react";

export default function TextAreaCustom(props: {
    value: string;
    update: Function;
    keyName: string;
    updateCandidateFrontInWindow:any,
    updateCandidateExtendedProfileFrontInWindow:any
}) {
    return (<>
        <textarea placeholder={"Ajouter du texte..."} value={props.value} onChange={(e) =>{
            // props.updateCandidateFrontInWindow({[props.keyName]: props.value})
            props.update({
            type: 'UPDATE',
            value: {[props.keyName]: e.target.value
            }
        });
            props.updateCandidateExtendedProfileFrontInWindow({[props.keyName]: props.value});

        }}className={"profile2__block__input-area"}>
        </textarea>
    </>)
}