import getState from "./getState";

export {getLocation};

const getLocation = (remote: string, roaming: boolean, roamingPlace: string, location: any) => {

  if(remote === "yes") {
    return "Le poste est en télétravail";
  }
  
  if(roaming) {
    return roamingPlace;
  }
  
  if(!location) {
    return "";
  }
  
  if(!location.country && !location.postalCode) {
    return "Lieu non déterminé";
  }

  let temporaryLocation = "";

  if(location.country) temporaryLocation += location.country;
  if(location.state) temporaryLocation += ', ' + getState(location.state);
  if(location.city) temporaryLocation += ', ' + location.city;
  if(location.postalCode) temporaryLocation += ', ' + location.postalCode;

  return temporaryLocation;

  /*if(location.postalCode && !location.city && !location.state) {
    return `${location.country}, ${location.postalCode}`;
  }

  if(!location.city && !location.state) {
    return `${location.country}`;
  }

  if(!location.city && location.postalCode) {
    return `${location.country},${getState(location.state)}, ${location.postalCode}`;
  }

  if(!location.state && location.postalCode) {
    return `${location.country},${location.postalCode}, ${location.city}`;
  }

  if(!location.postalCode ) {
    return `${location.country},${getState(location.state)}, ${location.city}`;
  }
  
  return ` ${location.country}, ${getState(location.state)},${location.city}, ${location.postalCode}`;
  */
}


  //
  // getLocation = async () => {
  //   try {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(this.getCoordinates, this.handleLocationError);
  //     } else {
  //       alert("Geolocation is not supported by this browser");
  //     }
  //   } catch(error) {
  //     alert("Geolocation is not supported by this browser");
  //   }
  // };
  //
  // getCoordinates = async (position: any) => {
  //   try {
  //     this.setState({buttonStatus: 'pending'});
  //     const longitude: number = position.coords.longitude;
  //     const latitude: number = position.coords.latitude;
  //     const accuracy: number = position.coords.accuracy;
  //     const fetched: any = await abstractGetFetch("/ext/get/position", {longitude: longitude, latitude: latitude});
  //     if(fetched.status === 'ok') {
  //       await this.props.assistantContext.updateState("number", fetched.data.number);
  //       await this.props.assistantContext.updateState("street", fetched.data.street);
  //       await this.props.assistantContext.updateState("city", fetched.data.city);
  //       await this.props.assistantContext.updateState("state", fetched.data.state);
  //       await this.props.assistantContext.updateState("postalCode", fetched.data.postalCode);
  //       await this.props.assistantContext.updateState("country", fetched.data.country);
  //       await this.props.assistantContext.updateState("countryCode", fetched.data.countryCode);
  //       await this.props.assistantContext.updateState("longitude", fetched.data.longitude);
  //       await this.props.assistantContext.updateState("latitude", fetched.data.latitude);
  //       this.setState({buttonStatus: 'done'});
  //     } else {
  //       alert("Location information is unavailable.");
  //     }
  //   } catch(error) {
  //     console.log(error)
  //     alert("Geolocation is not supported by this browser (1)");
  //   }
  // };
  //
  // handleLocationError = (error: any) => {
  //   switch(error.code) {
  //     case error.PERMISSION_DENIED:
  //       alert("User denied the request for Geolocation.");
  //       break;
  //     case error.POSITION_UNAVAILABLE:
  //       alert("Location information is unavailable.");
  //       break;
  //     case error.TIMEOUT:
  //       alert("The request to get user location timed out.");
  //       break;
  //     case error.UNKNOWN_ERROR:
  //       alert("Location information is unavailable.");
  //       break;
  //     default:
  //       alert("Location information is unavailable.");
  //       break;
  //   }
  // };
