import { Widget } from '@typeform/embed-react';
import React from "react";
import PageLayoutWithHeader from "../../layouts/PageLayoutWithHeader";

export default (props: any) => {

    return (<PageLayoutWithHeader grey={false} hideHeader={false} small={true} fullWidth={true} page={props.page} status={"ok"}>
        <Widget id="piiYqaNk" style={{ width: '100vw' , height :'100vh'}} className="my-form"/>
    </PageLayoutWithHeader>);
}
