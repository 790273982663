import React from "react";

export default (props: any) => {


  return (
    <>
      <section className={'section-navigation'}>

        <div
          className={`section-navigation__button ${props.white ? "section-navigation__button--white " : " " }${props.category === 'remuneration' ? "section-navigation__button--selected" : ""}`}
          onClick={() => {
            props.setCategory('remuneration');

          }}
        > Questions préliminaires
        </div>
        <div
          className={`section-navigation__button ${props.white ? "section-navigation__button--white " : " " }${props.category === 'metier' ? "section-navigation__button--selected" : ""}`}
          onClick={() => {
            props.setCategory('metier');

          }}>
          Questions RH & Techniques
        </div>
        <div
          className={`section-navigation__button ${props.white ? "section-navigation__button--white " : " " }${props.category === 'details' ? "section-navigation__button--selected" : ""}`}
          onClick={() => {
            props.setCategory('details');
          }}> Détails du poste
        </div>
        <div
          className={`section-navigation__button ${props.white ? "section-navigation__button--white " : " " }${props.category === 'options' ? "section-navigation__button--selected" : ""}`}
          onClick={() => {
            props.setCategory('options');
          }}> Options du poste
        </div>

      </section>

    </>
  )
}