import React, {useContext, useEffect, useState} from "react";
import {format} from "date-fns";
import {Link} from "react-router-dom";
import {shortenText} from "../../../../utils/shortenText";

export default (props: {
  seenByMe: any;
  dateOfCreation: any;
  message: any;
  btnType: any;
  link?: any;
  btnContent: any;
  function?: any;
}) => {
  
  return (<div className={`navbar__notifications-container__window__notifications__line`}>
    
    <div
      className={`navbar__notifications-container__window__notifications__line__text ${(!props.seenByMe) ? "navbar__notifications-container__window__notifications__line__text--selected" : ""}`}
      title={props.message}>
      {!props.seenByMe && <>● </>}
      {props.seenByMe && <>○ </>}
      {props.dateOfCreation ? <span
        className={"navbar__notifications__label navbar__notifications__label--date"}>{format(new Date(props.dateOfCreation), "dd-MM-yyyy")}</span> : ""}
      {shortenText(props.message, 70)}
    </div>
    
    {props.btnType === "linkButton" &&
    <Link to={props.link}>
      <button className={"nav-bar__notifications__small-btn"}>
        {props.btnContent}
      </button>
    </Link>
    }
    {props.btnType === "button" &&
    <button className={"nav-bar__notifications__small-btn"}
            onClick={() => props.function()}
    > {props.btnContent} </button>
    }
  </div>)
}