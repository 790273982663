import React, {useContext, useState} from "react";

import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import Spaces from "../../../Spaces";
import CloseButton from "../../../Buttons/CloseButton";

export {AmbassadorForm};

const AmbassadorForm = (props: {
    ambassador:any,
    dispatchAmbassador:Function,
    isEmailRed:boolean,
    isEmailGreen:boolean,
    setUpdatedProfile:Function,
    updateAvatar:Function,
    displayedAvatar:string,
    displayTags:boolean,
}) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
    const [tagToAdd, setTagToAdd] = useState("");

    return(<>

        <div className={"text--center text--magnified2x"}>
            Informations sur l'ambassadeur
        </div>

        <Spaces n={1}/>

        <div className={"text--center"}>
            <img src={props.displayedAvatar} className={"avatar-display--middle"}/>
            <Spaces n={0}/>

            <div className={"space-flex--center"}>
                <div className={"header__upload-image__button"}>
                    <input type={"file"} name={"file"} id={"candidateAvatar"} className="input-camera"
                           onChange={props.updateAvatar()}/>
                    <label htmlFor={"candidateAvatar"} className={`btn-camera2 animation-straight-fade-in space-medium`}>
                        <img src={"https://static.myshortlist.co/btn-camera.svg"} alt={"modify avatar"}/>
                    </label>
                </div>
            </div>
        </div>

        <Spaces n={1}/>

        <div className={"ambassador-form__line"}>
            <div className={"ambassador__div"}>Prénom * :</div>
            <input className={"ambassador__input"} type={"text"} value={props.ambassador.firstName} onChange={(e) => {
                props.setUpdatedProfile(true);
                props.dispatchAmbassador({
                    type: "UPDATE_AMBASSADOR", value: {firstName: e.target.value}
                });
            }}/>
        </div>

        <div className={"ambassador-form__line"}>
            <div className={"ambassador__div"}>Nom:</div>
            <input className={"ambassador__input"} type={"text"} value={props.ambassador.lastName} onChange={(e) => {
                props.setUpdatedProfile(true);
                props.dispatchAmbassador({
                    type: "UPDATE_AMBASSADOR", value: {lastName: e.target.value}
                });
            }}/>
        </div>

        <div className={"ambassador-form__line"}>
            <div className={"ambassador__div"}> Email * :</div>
            <input
                className={`ambassador__input ${props.isEmailRed ? "ambassador__input-red" : ""} ${props.isEmailGreen ? "ambassador__input-green" : ""}`}
                type={"text"} value={props.ambassador.email} onChange={(e) => {
                props.setUpdatedProfile(true);
                props.dispatchAmbassador({
                    type: "UPDATE_AMBASSADOR", value: {email: e.target.value}
                });
            }}/>
        </div>

        <div className={"ambassador-form__line"}>
            <div className={"ambassador__div"}> Téléphone:</div>

            <select className={"ambassador__input"} value={props.ambassador.phone?.countryCode}
                    onChange={(e) => {
                        props.setUpdatedProfile(true);
                        props.dispatchAmbassador({
                            type: "UPDATE_AMBASSADOR_PHONE_COUNTRY_CODE", value: e.target.value
                        });
                    }}>
                <option value={"33"}>+33</option>
            </select>
            <input className={"ambassador__tel-value ambassador__input"} type={"text"} value={props.ambassador.phone?.value}
                   onChange={(e) => {
                       props.setUpdatedProfile(true);
                       props.dispatchAmbassador({
                           type: "UPDATE_AMBASSADOR_PHONE_VALUE", value: e.target.value
                       });
                   }}/>
        </div>

        <div className={"ambassador-form__line"}>
            <div className={"ambassador__div"}>Poste:</div>
            <input className={"ambassador__input"} type={"text"} value={props.ambassador.position} onChange={(e) => {
                props.setUpdatedProfile(true);
                props.dispatchAmbassador({
                    type: "UPDATE_AMBASSADOR", value: {position: e.target.value}
                });
            }}/>
        </div>

        <div className={"ambassador-form__line"}>
            <div className={"ambassador__div"}>Entreprise:</div>
            <input className={"ambassador__input"} type={"text"} value={props.ambassador.businessName} onChange={(e) => {
                props.setUpdatedProfile(true);
                props.dispatchAmbassador({
                    type: "UPDATE_AMBASSADOR", value: {businessName: e.target.value}
                });
            }}/>
        </div>

        {props.displayTags && <>
        <div className={"ambassador-form__line"}>
            <div className={"ambassador__div-tag"}>Tags:</div>
            <div className={"ambassador__tags"}>

                <input className={"ambassador__tag ambassador__input"} type={"text"} value={tagToAdd}
                       onChange={(e) => setTagToAdd(e.target.value)}/>
                <button disabled={!tagToAdd} className={"ambassador__tag-btn"}
                        onClick={(e) => {
                            props.setUpdatedProfile(true);
                            props.dispatchAmbassador({type: "ADD_AMBASSADOR_TAGS", value: tagToAdd});
                            setTagToAdd("");
                        }}
                > +
                </button>

            </div>
        </div>
        <div className={"ambassador-form__tags"}>
            {
                props.ambassador.tags?.map((item: any, key: any) => (<div className={"ambassador-form__tags__tag"} key={key}>
                        <div className={"space-flex--left"}>{item}
                            <CloseButton eventOnClick={() => {props.dispatchAmbassador({type: "DELETE_AMBASSADOR_TAGS", value: item})}}/>
                        </div>
                    </div>
                ))}
        </div>
            </>}

    </>);
}