import React, {useContext, useState} from "react";
import Spaces from "../../../../../components/sharedComponents/Spaces";
import AppContext, {IAppV2Context} from "../../../../../components/App/context/AppContext";
import axios from "axios";

export default (props: {

    frontUpdates: any,
    setFilterByWords: Function,
    setFilterByMark: Function,
    filterByWords: string,
    filterByMark: string,
    filterByYearsOfExperience: number,
    setFilterByYearsOfExperience: Function,
    filterBySalaryMin: number,
    setFilterBySalaryMin: Function,
    filterByDateOfAvailability: any,
    setFilterByDateOfAvailability: Function,
    filterByTraining: string,
    setFilterByTraining: Function,
    missionId:string,

}) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
    const isClientNotRaileniumOrAdminRailenium = (appContextV2.appSession.sessionBusiness.businessName !== "Railenium") ||
        (appContextV2.appSession.sessionBusiness.businessName === "Railenium"
            && appContextV2.appSession.session.userRole === "admin");
    const [disabledImportButton, setDisabledImportButton]=useState(false);


    return (<div>

        <div className={"row"}>
        <div className={"title--bold"}>
            Recherche
        </div>
        <input disabled={false} type={"text"} placeholder={"Nom, Prénom"} className={"green"}
               value={props.filterByWords} onChange={(e) => props.setFilterByWords(e.target.value)}/>
        <input disabled={false} type={"number"} placeholder={"Expérience supérieure à …"}
               className={"dark-yellow"}
               value={props.filterByYearsOfExperience}
               onChange={(e) => props.setFilterByYearsOfExperience(e.target.value)}/>
        {isClientNotRaileniumOrAdminRailenium && <input disabled={false} type={"number"} placeholder={"Prétention salariale max"}
                                                        className={"yellow"}
                                                        value={props.filterBySalaryMin} onChange={(e) => props.setFilterBySalaryMin(e.target.value)}/>}
        <input disabled={false} type={"date"} placeholder={"Disponible avant le …"} className={"orange"}
               value={props.filterByDateOfAvailability}
               onChange={(e) => props.setFilterByDateOfAvailability(e.target.value)}/>
        <input disabled={false} type={"text"} placeholder={"Niveau de formation"} className={"dark-orange"}
               value={props.filterByTraining} onChange={(e) => props.setFilterByTraining(e.target.value)}/>
        </div>
        <Spaces n={0}/>

        <div className={"title--bold"}>
            Import
        </div>
        <button disabled={false} title={"Importe un candidat d'une communauté"}
                className={"btn-secondary"}
                onClick={() => {
                    appContextV2.dispatchPanels({
                        type: "OPEN_WINDOW", value: {
                            type: "importFromCommunity",
                            data: {
                                missionId: appContextV2.appNavigation.missionId,
                                addCandidacyFront: props.frontUpdates.addCandidacyFront,
                            }
                        }
                    });
                }}>
            Importer d'une communauté
        </button>
        <button disabled={false} title={"Importe un candidat d'une autre recherche"}
                className={"btn-secondary"}
                onClick={() => {
                    appContextV2.dispatchPanels({
                        type: "OPEN_WINDOW", value: {
                            type: "importFromOtherResearch",
                            data: {
                                missionId: appContextV2.appNavigation.missionId,
                                addCandidacyFront: props.frontUpdates.addCandidacyFront,
                            }
                        }
                    });
                }}>
            Importer d'une recherche
        </button>

        {/*<button disabled={disabledImportButton} title={"Trier les candidatures de la premiere colonne"}*/}
        {/*        className={"btn-secondary"}*/}
        {/*        onClick={async () => {*/}
        {/*            try {*/}
        {/*                setDisabledImportButton(true);*/}
        {/*                const {status, data} = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/sort/candidacies/column`, {*/}
        {/*                    missionId: props.missionId,*/}
        {/*                },{withCredentials: true})).data;*/}
        {/*                console.log("data",data);*/}
        {/*                if(status === "ok") {*/}
        {/*                    setDisabledImportButton(false);*/}
        {/*                    window.location.reload();*/}
        {/*                }*/}
        {/*            } catch(error) {*/}
        {/*                setDisabledImportButton(true);*/}
        {/*            }*/}
        {/*        }}>*/}
        {/*    Boutton test ne pas toucher*/}
        {/*</button>*/}

    </div>)

}