import React, {useContext} from "react";
import AppContext, {IAppContext, IAppV2Context} from "../../../App/context/AppContext";
import WindowWrapper from "../../../../layouts/WindowLayout";
import RecruiterSharedMissionLink from "../../../../pages/privateRecruiter/recruiterSharedMissionLink/RecruiterSharedMissionLink";

export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  return(<WindowWrapper size={"big"} status={"ok"} windowTitle={""}>
  
    <div className={"container"}>
      <RecruiterSharedMissionLink
          missionId={appContextV2.appPanels.window.windowOptions.data.missionId}
          mission={{positionWanted:appContextV2.appPanels.window.windowOptions.data.positionWanted}}
          missionTokens={{
        rank: appContextV2.appPanels.window.windowOptions.data.rank,
        mailHandler: appContextV2.appPanels.window.windowOptions.data.mailHandler,


      }}/>
    </div>
    
  </WindowWrapper>);
}
