import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../components/App/context/AppContext";

export {RecruiterAmbassador};

const RecruiterAmbassador = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  return (<article className={"ambassadors--v2__ambassador"}>
    
    <div className={"ambassadors--v2__ambassador__left"}>
      
      <div className={"ambassadors--v2__ambassador__avatar"}>
        <img src={props.item?.avatarUrl ? props.item?.avatarUrl : "https://static.myshortlist.co/koala.png"}/>
      </div>
      
      <div className={"ambassadors--v2__ambassador__element"}>
        <span className={"text--magnified2x"}>{props.item?.firstName + " " + props.item?.lastName?.toUpperCase()}</span>
        {
          (props.item?.position || props.item.businessName) && <>
          - {props.item?.position ? props.item?.position : ''}
          </>
          
          // <span className={"text--minified11x text--grey"}>
          //   ({props.item?.position ? props.item?.position + ', ' : ''} {props.item?.businessName ? props.item?.businessName : ''})
          // </span>
  
        }
      </div>
    
    </div>
    
    <div className={"ambassadors--v2__ambassador__right"}>
      {/*<div className={"ambassadors--v2__ambassador__element"}>*/}
      {/*  candidats : {props.item?.numberOfCandidatesCollected ? props.item?.numberOfCandidatesCollected : "0"}*/}
      {/*</div>*/}
      {/*<div className={"ambassadors--v2__ambassador__element"}>*/}
      {/*  recrutements : {props.item?.numberOfRecruitmentsCollected ? props.item?.numberOfRecruitmentsCollected : "0"}*/}
      {/*</div>*/}
      <button className={"ambassadors--v2__ambassador__button"} onClick={() => {
        appContextV2.dispatchPanels({
          type: "OPEN_WINDOW",
          value: {
            type: "ambassador",
            data: {
              item: props.item,
              dispatchAmbassadors: props.dispatchAmbassadors,
              type: "update",
            }
          }
        });
      }}>
        Modifier
      </button>
    </div>
  
  </article>);
}