import React from "react";

export default (props: {
  candidateIndex: number,
  setCandidateIndex: Function,
  numberOfCandidates: number,
}) => {
  
  return (<>
    <div className={"space-flex--center"}>

      {props.numberOfCandidates!==1 && <button className={"button--next button--previous"} onClick={() => {
        if(props.candidateIndex === 0) {
          props.setCandidateIndex((props.numberOfCandidates) - 1);
        } else {
          props.setCandidateIndex((ps: number) => ps -= 1);
        }
      }}>
        <img className={""}
             src={"https://static.myshortlist.co/btn-arrow-list.svg"}
             alt={"toggle"}/>
      </button>}
      
      <h1 className={"profile-edit__title2"}>Vous avez {props.numberOfCandidates} profil(s) a évaluer</h1>

      {props.numberOfCandidates!==1 && <button className={"button--next"} onClick={() => {
        if(props.candidateIndex === ((props.numberOfCandidates) - 1)) {
          props.setCandidateIndex(0);
        } else {
          props.setCandidateIndex((ps: number) => ps += 1);
        }
      }}>
        <img className={"img-type"}
             src={"https://static.myshortlist.co/btn-arrow-list.svg"}
             alt={"toggle"}/>
      </button>}
    </div>
  </>);
}