import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import {ButtonBack} from "../../../Buttons/ButtonBack";
import Spaces from "../../../Spaces";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  const language = appContextV2.appSession.app.language;
  
  const next = props.assistantContext.wizard.nextStep;
  const updateForm = props.assistantContext.updateForm;
  const assistantState = props.assistantContext.form;
  
  const updateArray = (index: number, value: string) => {
    if(value.length < 50) {
      let newArray: any = [...assistantState.business.favouriteJobs];
      newArray[index] = value;
      newArray = newArray.filter((item: any,) => item !== "");
      if(newArray.length < 5) {
        newArray.push("");
      }
      updateForm()({target: {value: newArray, name: "business.favouriteJobs", type: "custom"}});
    }
  }
  
  return (<>
    <ButtonBack back={props.assistantContext.wizard.previousStep}/>
    <Spaces n={0}/>
    
    <div className={"text--center text--magnified1x title--bold"}>
      {language === 'FR' && <>À propos de vos habitudes de recrutement</>}
      {language !== 'FR' && <>About your habits</>}
    </div>
    
    <Spaces n={1}/>
    
    <p className={"text--center"}>
      Entre de 1 à 5 postes sur lesquels vous recrutez le plus.
    </p>
    
    <Spaces n={2}/>
    
    <form onSubmit={(e) => {
      next();
      e.preventDefault();
    }}>
      
      <p className={"text--center"}>
        {assistantState.business.favouriteJobs.map((item: string, key: number) => (
          <input placeholder={`Poste ${key + 1}`} key={key} value={assistantState.business.favouriteJobs[key]} type={"text"}
                 className={`animation-straight-fade-in`} onChange={(e) => {
            updateArray(key, e.target.value);
          }}/>
        ))}
      </p>
    
    </form>
    
    <Spaces n={3}/>
    
    <p className={"text--center"}>
      <button className={"btn-main"} onClick={() => {
        next();
      }}>
        {language === 'FR' && <>Dernière étape</>}
        {language !== 'FR' && <>Last step</>}
      </button>
    </p>
  
  </>);
  
}
