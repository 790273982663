import AssistantLayout from "../../../../../layouts/AssistantLayout";
import React, {useContext} from "react";
import AppContext, {IAppContext, IAppV2Context} from "../../../../App/context/AppContext";


export default function(props: any) {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
    const language: string = appContextV2.appSession.app.language;

    const assistantTitle2: string = ((language: string) => {
        switch(language) {
            case 'FR':
                return "Ton lien à été envoyé par mail";
            case 'EN':
            default:
                return "Link sent by email";
        }
    })(language);

    const assistantComment2: any = <p className={"text--center"}>
        <button className={"btn-main"} disabled={false} onClick={() => {
            appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: {}})
        }}>Ok</button>
    </p>;

    return (
        <AssistantLayout title={assistantTitle2} comment={assistantComment2} animated={true}>
            <p className={"text--center"}>
                {language === 'FR' ? "Un lien a été généré et envoyé par email. Clique dessus pour changer ton mot de passe. Il ne sera valide que 10 minutes." : ""}
                {language === 'EN' ? "A link was generated and sent to you via email. Click on it to change your password. It will be available for 10 minutes." : ""}
            </p>
        </AssistantLayout>
    );


}
