
export default {
  title: '',
  description: '',
  status: '',
  document: '',
  positionWanted: "",
  positionWantedCode: "",
  numberOfPositionsWanted: "1",
  contract: "",
  yearsOfExperience: 0,
  asSoonAsPossible: false,
  duration: "",
  permanent: false,
  from: "",
  to: "",
  renewalPossibility: false,
  salaryBy: 'monthly',
  salaryCurrency: 'euros',
  salaryMin: "",
  salaryMax: "",
  salaryVariable: 0,
  isJobFullTime: true,
  skills: [],
  advantages: [],
  remote: "no",
  location: {
    number: "",
    street: "",
    city: "",
    state: "",
    postalcode: "",
    country: "France",
    sup: "",
  },
  withoutCv: true,
  cvMandatory: false,
  linkedinMandatory:false,
  refsMandatory: false,
  defaultQuestions:{
    isWorking:false,
    isAvailable:false,
    yearsOfExperience: false,
    training:false,
    currentSalary:false,
    wantedSalary:false,
    isAvailabilityBlocking:false,
    blockingAvailabilityData:'',
    isSalaryBlocking :false,
    blockingSalaryData:'',
    isExperienceBlocking :false,
    blockingExperienceData :'',
  },
  form: [],
  form2: [],
  linkedToPools: [],
}


  // documentUrl: '',
  // token: '',
  // rank: '',
