import React from "react";
import Spaces from "../../../Spaces";
import {IColumnData} from "../WindowColumnOptionsV2";

export {WCONameV2};

const WCONameV2 = (props: {
  type: "kanban" | "preset",
  missionId: string,
  numberOfCandidates: number,
  columnData: IColumnData,
  updateColumnData: Function,
  saveColumnData: Function,
  switchColumnAction: Function,
  isWindowLoading: boolean,
  deleteColumn: Function
}) => {
  
  return (<>
    
    <article className={`white-container white-container--grey`}>
      <div>
        <span className={"text--bold text--magnified11x"}>Nom de la colonne:</span> Donne un nom significatif à la
        colonne
      </div>
      <Spaces n={0}/>
      <div className={"text--center"}>
        <input type={"text"} className={"input--v2"} name={"name"} value={props.columnData.name}
               onChange={(e) => {
                 if(e.target.value.length < 25) {
                   props.updateColumnData({name: e.target.value});
                 }
               }}/>
      </div>
      <Spaces n={0}/>
      <div className={"text--center"}>
        <button className={"btn-secondary"}
                onClick={() => props.saveColumnData({name: props.columnData.name})}>
          Enregistrer
        </button>
      </div>
    </article>
  
  </>);

}