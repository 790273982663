import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext, useEffect, useReducer, useState} from "react";
import WindowWrapper from "../../../../layouts/WindowLayout";
import Spaces from "../../Spaces";
import {useForm} from "use-formidable";
import {devLog} from "../../../../utils/devLog/devLog";
import {notification} from "../../../../utils/notification";
import axios from "axios";
import dataReducerAmbassador from "./componentsWA/dataReducerAmbassador";
import LoadingStyle2 from "../../Loading/LoadingStyle2";
import {AmbassadorForm} from "./componentsWA/AmbassadorForm";
import ButtonShare from "../../ButtonShare";

export default function (props: any) {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};

    const windowType: "add" | "update" | "subscription" = appContextV2.appPanels.window.windowOptions.data?.type;
    const dispatchAmbassadors = appContextV2.appPanels.window.windowOptions.data?.dispatchAmbassadors;
    const businessSlugName = appContextV2.appPanels.window.windowOptions.data?.businessSlugName;
    const businessSlugNumber = appContextV2.appPanels.window.windowOptions.data?.businessSlugNumber;
    const missionRank = appContextV2.appPanels.window.windowOptions.data?.missionRank;
    
    const [ambassadorUrl, setAmbassadorUrl] = useState("");

    let displayTags = (windowType === "add" || windowType === "update");

    const [ambassador, dispatchAmbassador] = useReducer(dataReducerAmbassador, (appContextV2.appPanels.window.windowOptions.data?.item ?? {}));
    const [deleteMode, setDeleteMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const [updatedProfile, setUpdatedProfile] = useState(false);
    const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);

    const [avatar, updateAvatar, formidable] = useForm({
        file: null,
        temporary: "https://static.myshortlist.co/koala.png",
        accepted: false,
    });

    useEffect((): any => {
        (() => {

            if (avatar.file) {

                if (avatar.file.length > 1) {
                    updateAvatar()({target: {type: "custom", name: "file", value: null}});
                    updateAvatar()({target: {type: "custom", name: "accepted", value: false}});
                    return notification(appContextV2.dispatchPanels, "Un seul fichier peut être envoyé à la fois", "error");
                }

                if (!formidable.isFileMime(avatar.file[0], ["jpeg", "png"])) {
                    updateAvatar()({target: {type: "custom", name: "file", value: null}});
                    updateAvatar()({target: {type: "custom", name: "accepted", value: false}});
                    return notification(appContextV2.dispatchPanels, "Le type du fichier n'est pas pris en charge", "error");
                }

                if (!formidable.isFileSmallerThan(avatar.file[0], 600_000)) {
                    updateAvatar()({target: {type: "custom", name: "file", value: null}});
                    updateAvatar()({target: {type: "custom", name: "accepted", value: false}});
                    return notification(appContextV2.dispatchPanels, "Le fichier est trop lourd (maximum 600ko)", "error");
                }

                updateAvatar()({target: {type: "custom", name: "accepted", value: true}});
                updateAvatar()({
                    target: {
                        type: "custom",
                        name: "temporary",
                        value: URL.createObjectURL(avatar.file[0])
                    }
                });
            }
        })();
    }, [avatar.file]);

    const sendAvatar = async () => {
        try {
            const dataToSend: any = new FormData();
            dataToSend.append("avatar", avatar.file[0], avatar.file[0].name);
            const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/upload/data/unique/candidate/avatar`, dataToSend, {
                withCredentials: true, timeout: 15000, headers: {
                    'accept': 'application/json', 'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${dataToSend._boundary}`
                }
            });
            if (fetched.data.status !== "ok") {
                return "";
            }
            devLog("avatar", fetched.data.data);
            return fetched.data.data;
        } catch (error) {
            devLog(error);
            return "";
        }
    }

    const addAmbassador = async () => {
        try {

            setIsLoading(true);
            let avatarUrl = "";
            if (avatar.accepted && !!avatar.file && avatar.file.length !== 0) {
                avatarUrl = await sendAvatar();
                dispatchAmbassador({type: "UPDATE_AMBASSADOR", value: {avatarUrl: avatarUrl}})
            }

            const {status, data} = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/amb/m/add`, {
                avatarUrl: avatarUrl ? avatarUrl : ambassador.avatarUrl ?? "https://static.myshortlist.co/koala.png",
                lastName: ambassador.lastName,
                firstName: ambassador.firstName,
                email: ambassador.email,
                phoneValue: ambassador.phone?.value,
                phoneCountryCode: ambassador.phone?.countryCode ?? "33",
                position: ambassador.position,
                businessName: ambassador.businessName,
                tags: ambassador.tag,
            }, {withCredentials: true})).data;

            if (status !== "ok") {
                setIsLoading(false);
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                return appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
            }

            if(windowType==="subscription") {
                const {status2, data2} = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/ambassador/add/contacted/model`,
                    {ambassadorId: data._id,
                        businessSlugName:businessSlugName,
                        businessSlugNumber:businessSlugNumber,
                        missionRank:missionRank,
                    },
                    {withCredentials: true})).data;
            }

            // Update front
            dispatchAmbassadors({
                type: "ADD",
                value: data
            });

            setIsLoading(false);
            notification(appContextV2.dispatchPanels, "Ambassadeur ajouté avec succès", "success");
            return appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});

        } catch (error) {
            console.log(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    const subscriptionAmbassador = async () => {
        try {

            setIsLoading(true);
            let avatarUrl = "";
            if (avatar.accepted && !!avatar.file && avatar.file.length !== 0) {
                avatarUrl = await sendAvatar();
                dispatchAmbassador({type: "UPDATE_AMBASSADOR", value: {avatarUrl: avatarUrl}});
            }

            const {
                status,
                data
            } = (await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/amb/m/subscription/from/mission`, {
                avatarUrl: avatarUrl,
                lastName: ambassador.lastName,
                firstName: ambassador.firstName,
                email: ambassador.email,
                phoneValue: ambassador.phone?.value,
                phoneCountryCode: ambassador.phone?.countryCode,
                position: ambassador.position,
                businessName: ambassador.businessName,
                tags: ambassador.tag,
                missionId: appContextV2.appPanels.window.windowOptions.data?.missionId,
            }, {withCredentials: true})).data;


            if (status !== "ok") {
                setIsLoading(false);
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                return appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
            }

            // Update front
            dispatchAmbassadors({
                type: "ADD",
                value: data
            });

            appContextV2.appPanels.window.windowOptions.data?.setAmbassadorId(data._id);
            setAmbassadorUrl(`${window.location.origin}/apply/${businessSlugName}/${businessSlugNumber}/${missionRank}/apply?a=${data._id}`);
            setIsLoading(false);
            notification(appContextV2.dispatchPanels, "Ambassadeur ajouté avec succès", "success");
            setSubscriptionSuccess(true);

        } catch (error) {
            console.log(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    const deleteAmbassador = async () => {
        try {

            setIsLoading(true);
            const {status, data} = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/amb/m/delete`, {
                ambassadorId: ambassador._id,
            }, {withCredentials: true})).data;

            if (status !== "ok") {
                setIsLoading(false);
                notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
                return appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
            }

            // Update front
            dispatchAmbassadors({
                type: "DELETE",
                value: ambassador._id,
            });

            setIsLoading(false);
            notification(appContextV2.dispatchPanels, "Suppression réussie", "success");
            return appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});

        } catch (error) {
            console.log(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    const updateAmbassador = async () => {
        try {

            setIsLoading(true);
            let avatarUrl = "";
            if (avatar.accepted && !!avatar.file && avatar.file.length !== 0) {
                avatarUrl = await sendAvatar();
                dispatchAmbassador({type: "UPDATE_AMBASSADOR", value: {avatarUrl: avatarUrl}})
            }

            const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/amb/m/update`, {
                ambassadorId: ambassador._id,
                avatarUrl: avatarUrl ? avatarUrl : ambassador.avatarUrl ?? "",
                lastName: ambassador.lastName,
                firstName: ambassador.firstName,
                email: ambassador.email,
                phoneValue: ambassador.phone?.value,
                phoneCountryCode: ambassador.phone?.countryCode ?? "33",
                position: ambassador.position,
                businessName: ambassador.businessName,
                tags: ambassador.tags,
            }, {withCredentials: true});

            if (fetched.data.status !== "ok") {
                setIsLoading(false);
                return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
            }

            // Update front
            dispatchAmbassadors({
                type: "UPDATE",
                value: {
                    ambassadorId: ambassador._id,
                    keyValues: {
                        ...ambassador,
                        avatarUrl: avatarUrl ? avatarUrl : ambassador.avatarUrl ?? "https://static.myshortlist.co/koala.png",
                    }
                }
            });

            setUpdatedProfile(false);
            setIsLoading(false);
            notification(appContextV2.dispatchPanels, "Mise à jour avec succès", "success");

        } catch (error) {
            console.log(error);
            notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        }
    }

    devLog("ambassador", ambassador)

    const regexPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isEmailValid = regexPattern.test((ambassador.email ?? "").toLowerCase().trim());
    const isEmailEmpty = (ambassador.email ?? "").length === 0;
    const isEmailRed = !isEmailEmpty && !isEmailValid;
    const isEmailGreen = isEmailValid;
    const isFirstNameEmpty = (ambassador.firstName ?? "").length === 0;

    const isButtonActive = !isLoading && isEmailGreen && !isFirstNameEmpty;

    const displayPreviewAvatar = avatar.accepted || (!ambassador.avatarUrl || ambassador.avatarUrl === "https://static.myshortlist.co/koala.png");
    const displayedAvatar = displayPreviewAvatar ? avatar.temporary : ambassador.avatarUrl;

    return (<WindowWrapper windowTitle={""} size={"small"} status={"ok"}>

        {!subscriptionSuccess && <><AmbassadorForm ambassador={ambassador}
                                                   dispatchAmbassador={dispatchAmbassador}
                                                   isEmailRed={isEmailRed}
                                                   isEmailGreen={isEmailGreen}
                                                   setUpdatedProfile={setUpdatedProfile}
                                                   updateAvatar={updateAvatar}
                                                   displayedAvatar={displayedAvatar}
                                                   displayTags={displayTags}/>


            <Spaces n={1}/>

            {appContextV2.appPanels.window.windowOptions.data?.type === "add" && <div className={"text--center"}>
                <button className={"btn-main"} disabled={!isButtonActive} onClick={() => addAmbassador()}>
                    Ajouter
                </button>
            </div>}

            {
                windowType === "update" && !deleteMode &&
                <div className={"text--center"}>
                    <button className={"btn-main"} onClick={() => {
                        setDeleteMode(true);
                    }} title={"Supprimer cet ambassadeur"}>
                        Supprimer
                    </button>
                    <button className={"btn-main"} disabled={!isButtonActive || !updatedProfile}
                            title={"Enregistre les modifications"}
                            onClick={() => updateAmbassador()}>
                        Enregistrer
                    </button>
                </div>}

            {
                windowType === "update" && deleteMode && <>
                    <div className={"text--center"}>
                        Êtes-vous sûr(e) de vouloir supprimer cet ambassadeur ?
                    </div>
                    <div className={"text--center"}>
                        <button className={"btn-main"} onClick={() => {
                            setDeleteMode(false);
                        }}>
                            Non
                        </button>
                        <button className={"btn-main"} onClick={() => deleteAmbassador()}>
                            Oui, supprimer
                        </button>
                    </div>
                </>}

            {
                windowType === "subscription" && <div className={"text--center"}>
                    <button className={"btn-main"} disabled={!isButtonActive} onClick={() => subscriptionAmbassador()}>
                        S'inscrire
                    </button>
                </div>}

            {
                isLoading &&
                <div className={"space-flex--center"}>
                    <LoadingStyle2/>
                </div>
            }
        </>}

        {
            subscriptionSuccess && <>
                <div> Bravo, vous êtes maintenant ambassadeur !
                    <br/>
                    Partagez votre lien à votre réseau et la plateforme identifiera automatiquement les candidats qui
                    postulent en votre nom.
                </div>
                <div className={"text--center"}>
                <ButtonShare onClick={() => {
                    notification(appContextV2.dispatchPanels, "Lien copié", "success");
                    navigator.clipboard.writeText(ambassadorUrl);
                }}/>
                </div>
            </>
        }
    </WindowWrapper>);
}
