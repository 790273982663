import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import AssistantLayout from "../../../../../layouts/AssistantLayout";
import {ButtonWrapperV2} from "../../../../../layouts/ButtonWrapper";
import {withRouter} from 'react-router-dom'
import Spaces from "../../../Spaces";
import {notification} from "../../../../../utils/notification";
import axios from "axios";

export default withRouter(WindowSubscribeSetTerms);

function WindowSubscribeSetTerms(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  /** Fetcher */
  const [fetchStatus, setFetchStatus] = useState("asleep");
  const [fetched, setFetched] = useState({status: "", data: ""});
  
  const subscribe = async() => {
    try {
      setFetchStatus("fetching");
      const fetched = await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/u/subscribe`, {
        ...props.windowContext.form,
      }, {
        withCredentials: true,
      });
      await setFetched(fetched.data);
      setFetchStatus("fetched");
    } catch (error) {
      setFetchStatus("error");
    }
  };
  
  useEffect(() => {
    (async () => {
      switch(fetchStatus) {
    
        case 'fetched':
          switch(fetched.status) {
        
            case 'ok':
              await appContextV2.fetchSession(`${process.env.REACT_APP_API_URL}/v3/accounts/session/s/whoami`, {
                method: "GET",
                credentials: "include",
              });
              props.history.push('/');
              await appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
              notification(appContextV2.dispatchPanels, "Bienvenue sur Myshortlist.co !", "success")
              break;
        
            case 'userAlreadyExists':
              notification(appContextV2.dispatchPanels, "L'utilisateur existe déjà", "warning")
              return props.windowContext.wizard.goToStep("alreadyExists");
  
            default:
              notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error")
              return props.windowContext.wizard.goToStep("error");
          }
          break;
    
        case 'error':
          notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error")
          return props.windowContext.wizard.goToStep("error");
    
        case 'fetching':
        case 'asleep':
        default:
      }
    })();
  }, [fetchStatus]);
  
  const areTermsAccepted: boolean = props.windowContext.form.areTermsAccepted;
  const isPolicyAccepted: boolean = props.windowContext.form.isPolicyAccepted;
  const isButtonTermsActive: boolean = areTermsAccepted && isPolicyAccepted && ["asleep"].includes(fetchStatus);
  
  const assistantComment: any = <div className={"text--center"}>
    <Spaces n={1}/>
    <button className={"btn-main"} disabled={!isButtonTermsActive} onClick={async() => {
      subscribe();
    }}>
      <ButtonWrapperV2 fetchingStatus={fetchStatus as 'asleep'|'fetched'|'fetching'|'error'}>
        {language === 'FR' ? "Terminer l'inscription" : ""}
        {language === 'EN' ? "Create the account" : ""}
      </ButtonWrapperV2>
    </button>
  </div>;
  
  return (<>
    {/*<ButtonBack back={props.windowContext.wizard.previousStep}/>*/}
  
    <AssistantLayout title={""} comment={assistantComment} animated={true} flexed={false}>
    
    <div className={"full-width"}>
      <input type={"checkbox"} name={"areTermsAccepted"} onChange={props.windowContext.updateForm()}/>
      <label>J'ai lu et j'accepte les <button className={"btn--hollow modern-link"} onClick={() => {
        props.history.push('/terms');
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      }}>Conditions Générales</button>
      </label>
    </div>
    
    <div>
      <Spaces n={0}/>
    </div>
    
    <div className={"full-width"}>
      <input type={"checkbox"} name={"isPolicyAccepted"} onChange={props.windowContext.updateForm()}/>
      <label>J'ai lu et j'accepte la <button className={"btn--hollow modern-link"} onClick={() => {
        props.history.push('/policy');
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      }}>Charte de Confidentialité</button></label>
    </div>
    
  </AssistantLayout>
  </>);
}
