import React from "react";

export {SecondaryNavBarWrapper};

/**
 * Layout for the secondary navbar. The display is completly different on smartphone and computer.
 * @param props : content, SHOULD contain li with 'hide-on-computers' and 'hide-on-smartphones'
 * @constructor
 */
function SecondaryNavBarWrapper(props: { content: any, }) {
  
  return (
    <>
      <SecondaryNavBarPc content={props.content}/>
      <SecondaryNavBarSmartphone content={props.content}/>
    </>
  );
}


function SecondaryNavBarPc(props: { content: any }) {
  return (<>
    <div className={"hide-on-smartphones"}>
      <nav className={`navbar-secondary navbar-secondary--pc hide-on-smartphones`}>
        <div className={"navbar-secondary--pc__container"}>
          
          <ul className={"navbar-secondary--pc__items"}>
            {props.content}
          </ul>
        </div>
      </nav>
    </div>
  </>);
}

function SecondaryNavBarSmartphone(props: { content: any }) {
  return (<>
    <nav className={`navbar-secondary navbar-secondary--smartphone hide-on-computers`}>
      <ul className={"navbar-secondary--smartphone__items"}>
        {props.content}
      </ul>
    </nav>
  </>);
}
