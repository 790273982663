import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import React, {useContext} from "react";
import RSMLAmbassadors from "./RSMLAmbassadors";
import Spaces from "../../../../components/sharedComponents/Spaces";
import RSMLRobopost from "./RSMLRobopost";
import RSMLMissionMultiposting from "./RSMLMissionMultiposting";
import RSMLTracking from "./RSMLTracking";
import RSMLMissionMail from "./RSMLMissionMail";

export default (props:any) => {

  switch (props.category){

    case 'ambassador':
      return   <>
      <RSMLAmbassadors {...props}/>
      <Spaces n={0}/>
      </>

    case 'multiposting':
      return  <>
        <RSMLRobopost {...props} />
      <Spaces n={0}/>
      <RSMLMissionMultiposting {...props}/>
      <Spaces n={0}/>
      </>

    case 'tracking':

      return  <>
        <RSMLTracking {...props}/>
      <Spaces n={0}/>
      </>

    case 'email':
      return   <>
      <RSMLMissionMail {...props}/>
      <Spaces n={0}/>
    </>

    default:
      return <></>;

}}