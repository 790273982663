
export default (args: {
    missions: any,
    filterByTas: any,
    filterByKam: any,
    filterByClient: any,
    filterByMonth:any,
    filterByCvv:any,
    setDisplayedMissions:any,
    filterByPriority:any,

}) => {

    const missionsCopy = [...args.missions];

    /** Defines which filters are active */
    const isFilterByTasActive: boolean = !!args.filterByTas;
    const isFilterByKamActive: boolean = !!args.filterByKam;
    const isFilterByClientActive: boolean = !!args.filterByClient;
    const isFilterByMonthActive: boolean = !!args.filterByMonth;
    const isFilterByCvvActive: boolean = !!args.filterByCvv;
    const isFilterByPriorityActive: boolean = !!args.filterByPriority;



    /** Defines results for each filter */
    let idFilteredByTas: string[] = [];
    let idFilteredByKam: string[] = [];
    let idFilteredByClient: string[] = [];
    let idFilteredByMonth: string[] = [];
    let idFilteredByPriority: string[] = [];

    /** Defines the result */
    let resultMissions: any = [];

    if (!isFilterByTasActive && !isFilterByKamActive && !isFilterByClientActive && !isFilterByMonthActive && !isFilterByCvvActive && !isFilterByPriorityActive) {
        resultMissions = missionsCopy;
    } else {

        /** Get IDs of result for Tas filter */
        (isFilterByTasActive && (idFilteredByTas = missionsCopy
                .filter((item: any) => (item?.tas?.toLowerCase()===args.filterByTas?.toLowerCase())
                ).map((item: any) => (item?._id))
        ));

        /** Get IDs of result for Kam filter */
        (isFilterByKamActive && (idFilteredByKam = missionsCopy
                .filter((item: any) => (item?.kam?.toLowerCase() === args.filterByKam.toLowerCase())
                ).map((item: any) => (item?._id))
        ));

        /** Get IDs of result for Client filter */
        (isFilterByClientActive && (idFilteredByClient = missionsCopy
                .filter((item: any) => (
                    item?.client?.toLowerCase() === args.filterByClient.toLowerCase())
                ).map((item: any) => (item?._id))
        ));

        /** Get IDs of result for Client filter */
        (isFilterByMonthActive && (idFilteredByMonth = missionsCopy
                .filter((item: any) => (
                    item?.targetMonth?.toLowerCase() === args.filterByMonth.toLowerCase())
                ).map((item: any) => (item?._id))
        ));

        /** Get IDs of result for Priority filter */
        (isFilterByPriorityActive && (idFilteredByPriority = missionsCopy
                .filter((item: any) => (
                    item?.priority?.toLowerCase() === args.filterByPriority.toLowerCase())
                ).map((item: any) => (item?._id))
        ));


        /** Add IDs to the result  */
        // For each ID, add it only if it matches active filters
        for (let mission of missionsCopy) {
            if (isFilterByTasActive && !idFilteredByTas.includes(mission?._id)) {
                continue;
            }
            if (isFilterByKamActive && !idFilteredByKam.includes(mission?._id)) {
                continue;
            }
            if (isFilterByClientActive && !idFilteredByClient.includes(mission?._id)) {
                continue;
            }
            if (isFilterByMonthActive && !idFilteredByMonth.includes(mission?._id)) {
                continue;
            }
            if (isFilterByPriorityActive && !idFilteredByPriority.includes(mission?._id)) {
                continue;
            }
            resultMissions.push(mission);
        }
    }

    console.log("resultMissions",resultMissions);

    let sortedShortlist;
    if(!isFilterByCvvActive ){
         sortedShortlist= [...resultMissions].sort((a: any, b: any) => (a.dateOfCreation - b.dateOfCreation));
    }
    if( isFilterByCvvActive && args.filterByCvv === "croissant"){
      sortedShortlist=[...resultMissions].sort((a: any, b: any) => ((a.numberOfCvv ?? 0) - (b.numberOfCvv ?? 0)));
    }
    if( isFilterByCvvActive && args.filterByCvv === "décroissant"){
        sortedShortlist=[...resultMissions].sort((a: any, b: any) => ((b.numberOfCvv ?? 0)- (a.numberOfCvv ?? 0)));

    }

    console.log("sortedShortlist",sortedShortlist);

    args.setDisplayedMissions(sortedShortlist);

}