import moveFromIndexToIndex from "../../../../utils/moveFromIndexToIndex";

type TState= any [];


export default (previousState: TState, action: { type: string, value: any }): any => {

    switch(action.type) {

        case 'INITIALIZE_DATA':
            return ([...action.value]);

        case 'ADD_COLUMN':
            return ([...previousState,{...action.value}]);

        case 'DELETE_COLUMN':
            return ([ ... previousState.filter((column: any) => column._id !== action.value)]
            );

        case 'UPDATE_COLUMN':
            return ([... previousState.map((column: any) => {
                        if(column._id !== action.value.columnId) return column;
                        return {
                            ...column,
                            ...action.value.keyValuePairs,
                        }
                    })]);

        case 'MOVE_COLUMN':
            const index: number = [...previousState].map((item: any) => (item._id)).indexOf(action.value.columnId);
            return([ ...previousState,
                moveFromIndexToIndex([...previousState], index, index + action.value.direction)
            ]);


        default:
            return previousState;
    }

}