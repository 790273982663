import React, {useContext} from "react";
import {ButtonBigPlusV2} from "../../../../components/sharedComponents/Buttons/ButtonBigPlus";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";

export {BusinessAddMember};

const BusinessAddMember = (props: {
  teamId: string,
  inviteTeamMemberFront: Function,
  teamName: string,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return(<div className={"teams__team__add-member"}>
    
    <ButtonBigPlusV2 cb={() => {
      appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
          type: "addMember",
          data: {
            teamId: props.teamId,
            inviteTeamMemberFront: props.inviteTeamMemberFront,
            teamName: props.teamName,
          }
        }});
    }} title={"Invite un nouveau membre"} />
    
    <div><br/></div>
    
    <div className={"text--grey text--center"}>Invite un nouveau membre</div>
  
  </div>);
}