import React, {useContext, useEffect, useReducer} from "react";
import Spaces from "../../../../components/sharedComponents/Spaces";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import axios from "axios";
import {devLog} from "../../../../utils/devLog/devLog";
import defaultKanbanReducer from "./defaultKanbanReducer";
import {IColumnData} from "../../../../components/sharedComponents/Window/componentsWindow/WindowColumnOptionsV2";
import {notification} from "../../../../utils/notification";

export {RecruiterParametersKanbanOptions}

const RecruiterParametersKanbanOptions = (props: {
  parameters: any,
  updateSingleton: Function,
  assistantGet: any,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const [customKanbanView, dispatchCustomKanbanView]: [any, Function] = useReducer(defaultKanbanReducer, []);
  
  useEffect(() => {
    (async function() {
      try {
        
        const answer = await axios.get(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/b/get/business/default/kanban`,
          {
            withCredentials: true
          });
        dispatchCustomKanbanView({
          type: "INITIALIZE_DATA", value:
          answer?.data?.data?.customKanbanView,
        });
        
      } catch(error) {
        devLog(error);
      }
    })()
  }, []);
  
  const addColumn = async() => {
    try {
      
      const answer = await axios.put(`${process.env.REACT_APP_API_URL}/v3/jobs/ent/b/put/business/default/kanban`,
        {},
        {withCredentials: true})
      dispatchCustomKanbanView({
        type: "ADD_COLUMN", value:
        answer?.data?.data,
      });
      notification(appContextV2.dispatchPanels, "Succès", "success");
      
    } catch(error) {
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "warning");
      devLog(error);
    }
  }
  
  const displayLighting = (item: IColumnData) => {
    const isEmailActive = (item.activateMail && item.mailSubject?.length !== 0 && item.mailContent?.length !== 0);
    const isPoolActive = (item.activatePool && item.poolId?.length !== 0);
    const isSmsActive = (item.activateSms && item.smsText?.length !== 0) || (item.activateAskCv);
    const isNotifyActive = (item.activateNotify && item.notifyEmails?.length !== 0);
    
    return isEmailActive || isPoolActive || isSmsActive || isNotifyActive;
  }
  
  return (<div className={"container white-container"}>
    <h1 className={"profile-edit__title"}>Colonnes par défaut</h1>
    
    <Spaces n={0}/>
    
    <div className={"text--grey text--center element-width"}>
      Chaque nouveau poste aura ces colonnes par défaut
    </div>
    
    <Spaces n={0}/>
    
    <div className={"div--center"}>
      <div className={"kanban-options__container"}>
        <div className={"text--center"}>Candidatures reçues</div>
      </div>
      
      {customKanbanView.map((item: any, key: number) => (
        
        <div className={"kanban-options__container animation-fade-in"}>
          <div className={"text--center"}>{item.name}</div>
          <div className={"kanban-options__buttons"}>
            <button title={"Ouvre les paramètres de cette colonne"}
                    onClick={() => {
                      appContextV2.dispatchPanels({
                        type: "OPEN_WINDOW", value: {
                          type: "columnOptionsV2",
                          data: {
                            columnData: item,
                            updateColumnFront: dispatchCustomKanbanView,
                            deleteColumnFront: dispatchCustomKanbanView,
                            type: "preset",
                    
                          }
                        }
                      })
                    }}
                    className={"mission--V2__buttons__parameters"}>
              <img src={"https://static.myshortlist.co/btn-parameters.svg"}/>
            </button>
          
          </div>
          
          {
            displayLighting(item) && <div title={"Une action est définie sur cette colonne"}
                                          className={"recruiter-parameters-kanban-options__action"}>
              <img src={"https://static.myshortlist.co/icon-lightning.svg"}/>
            </div>
          }
        
        </div>
      
      ))}
      
      <div className={"kanban-options__container"}>
        <div className={"text--center"}>Recrutements</div>
      </div>
      
      <Spaces n={0}/>
      
      <button className={"btn-plus--v2"} onClick={() => addColumn()}>
        <div className={"btn-plus--v2__plus"}> +</div>
      </button>
    </div>
  
  
  </div>);
}