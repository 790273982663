import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../components/App/context/AppContext";
import {displayDate} from "../../../utils/displayDate";
import getFullUrl from "../../../utils/getFullUrl";
import { format } from "date-fns";
import Highlighter from "react-highlight-words";

export default (props: {
    item: any;
    toggleSelected: Function;
    selected: string[];
    shortlist: any[];
    keywords: string[];
}) => {

    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

    const doesStringIncludeAnyElement = (text: string, el: string[]) => {
        for(let e of (el ??[]) ) {
            if(!e) continue;
            if(text?.toLowerCase().includes(e?.toLowerCase())) {
                return true;
            }
        }
        return false;
    }

    console.log(props.item)

    return (<div className={'advanced-research-result'}>
        <div className={'advanced-research-result__header'}>
            <div className={"space-right2x"}>
                <input type={"checkbox"} checked={props.selected.includes(props.item.candidateId)} onClick={() => props.toggleSelected(props.item.candidateId)} />
            </div>
            <div className={"research__results__result__avatar"}>
                {props.item?.avatarUrl && <img src={props.item?.avatarUrl}/>}
                {!props.item?.avatarUrl && <>{(props.item?.firstName || "N")[0] ? (props.item?.firstName || "N")[0].toUpperCase() : "N"}{(props.item?.lastName || "N")[0] ? (props.item?.lastName || "N")[0].toUpperCase() : "N"}</>}
            </div>
            <button onClick={() => {
                appContextV2.dispatchPanels({
                    type: "OPEN_WINDOW", value: {
                        type: "candidateWindowV2",
                        data: {
                            keywords: appContextV2.globalResearch,
                            category: "search",
                            candidateId: props.item.candidateId,
                            missionId: "",
                            basicData: {
                                firstName: props.item.firstName,
                                lastName: props.item.lastName,
                                avatarUrl: props.item.avatarUrl,
                                shortlist: props.shortlist
                            },
                            frontUpdatePool: {
                                updateCandidatesBulk: () => {
                                },
                                reloadCandidates: () => {
                                },
                                addCandidateFront: () => {
                                }
                            },
                            frontUpdateMission: {
                                updateCandidaciesBulk: () => {
                                },
                            },
                        }
                    }
                })
            }}
                className={"btn--hollow advanced-research-result__header__name"}>
                {props.item.firstName} {props.item.lastName} {!props.item.firstName && !props.item.lastName ? props.item.fullName : ""}
            </button>
            <div className={"space-left"}>⭐ {props.item?.gradeByTeam ? props.item?.gradeByTeam : "---"}</div>
            <div className={"text--grey text--minified3x text--align-left space-left"}>
                Parsé: {props.item.parsedIn} {props.item.parsedIn.includes('in') && props.item.lastScrapped ? `(${format(new Date(props.item.lastScrapped), "dd-MM-yyyy")})` : ''},
                Trouvé: {props.item.foundIn}
            </div>
            <div className={"text--grey text--minified3x text--align-left space-left"}>
                Dernière mise à jour le: {new Date(props.item.lastScrapped).toLocaleDateString('fr')}
            </div>
            <div className={"text--grey text--minified3x text--align-left space-left"}>
                <a href={`/business/controlCandidateData/${props.item.candidateId}`} target={"_blank"}>Details</a>
            </div>
        </div>
        <div className={"advanced-research-result__section"}>
            <div className={''}>
                {props.item.currentPosition ? props.item.currentPosition : ""} {props.item.currentPosition && props.item.currentCompany ? " | " : ""} {props.item.currentCompany ? props.item.currentCompany : ""}
            </div>
        </div>
        <div className={"advanced-research-result__section"}>
            <div className={'text--minified2x'}>
                <span className={"space-right"}>{props.item.phone ? <><i className="fas fa-phone space-right--small"></i> {props.item.phone}</> : ""}</span>
                {!props.item.email.includes('unknown') && <span className={"space-right"}>{props.item.email ? <><i
                  className="fas fa-envelope space-right--small"></i> {props.item.email}</> : ""}</span>}
                <span className={"space-right"}>{props.item.currentSalary ? <><img src={"https://static.myshortlist.co/euro.svg"} className="space-right--small img--small"></img> {props.item.currentSalary} K</> : ""}</span>
                <span className={"space-right"}>{props.item.linkedin ? <><i className="fab fa-linkedin space-right--small"></i> <a className={"modern-link text--minified2x"} href={getFullUrl(props.item.linkedin)} target={"_blank"}>Linkedin</a></> : ""}</span>
            </div>
        </div>
        <div className={"advanced-research-result__section"}>
            <div className={"advanced-research-result__section__left"}>
                Expérience
            </div>
            <div>
                {
                    (props.item.yearsOfExperience || props.item.experience?.length !== 0) &&
                    <div className={' text--minified2x text--align-left'}>
                        <div
                            className={'text--align-left text--minified2x'}>{props.item?.yearsOfExperience ? <>{props.item.yearsOfExperience} an(s)</> : ""}</div>

                        <div className={"advanced-research-result__list"}>
                        {props.item?.experience?.map((item2: any) => (<div
                            className={`text--align-left text--minified2x`}>
                            <Highlighter
                              textToHighlight={`- ${item2.position?.title} chez ${item2.employer?.company}`}
                              searchWords={props.keywords}
                              highlightClassName={"highlighted-text"}
                              autoEscape={true}
                            />
                            {item2?.tasks?.map((item3:any) => <div className={""}>
                                <div className={`text--align-left text--minified2x text--dark-grey ${doesStringIncludeAnyElement(item3, props?.keywords) ? 'text--bold' : ''}`}>
                                    <Highlighter
                                      textToHighlight={item3}
                                      searchWords={props.keywords}
                                      highlightClassName={"highlighted-text"}
                                      autoEscape={true}
                                    />
                                </div>
                            </div>)}
                        </div>))}
                        </div>

                    </div>

                }
            </div>
        </div>

        {
            props.item.kanbans && props.item.kanbans.length !== 0 &&
            <div className={"advanced-research-result__section"}>
                <div className={"advanced-research-result__section__left"}>
                    Kanbans
                </div>
                <div className={'text--align-left text--minified2x'}>
                    {props.item.kanbans?.map((item: any) => <div
                        className={"text--new-blue3 text--align-left text--minified2x"}><a
                        href={`https://myshortlist.co/business/mission/${item._id}`} target={"_blank"}>{item.name}</a>
                    </div>)}
                </div>
            </div>
        }

        {
            props.item.communities && props.item.communities.length !== 0 &&
            <div className={"advanced-research-result__section"}>
                <div className={"advanced-research-result__section__left"}>
                    Communautés
                </div>
                <div className={'text--align-lefty text--minified2x'}>
                    {props.item.communities?.map((item: any) => <div
                        className={"text--new-blue3 text--align-left text--minified2x"}><a
                        href={`https://myshortlist.co/business/community/${item._id}`} target={"_blank"}>{item.name}</a>
                    </div>)}
                </div>
            </div>
        }

        {
            <div className={"advanced-research-result__section hollow"}>
                <div className={"advanced-research-result__section__left"}>
                    Activité
                </div>
                <div>
                    {/*<div className={'text--align-left text--grey text--minified2x'}>*/}
                    {/*    Dernier échange: {displayDate({*/}
                    {/*    original: props.item.dateOfLastAction*/}
                    {/*})}*/}
                    {/*</div>*/}
                    <div className={'text--align-left text--grey text--minified2x'}>
                        Entrée dans la base: {displayDate({original: props.item.dateOfCreation})}
                    </div>
                </div>
            </div>
        }

    </div>);
}