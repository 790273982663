import React, {useContext, useState} from "react";
import ProfileElementText from "../../../../layouts/ProfileElementText";
import {notification} from "../../../../utils/notification";
import axios from "axios";
import {devLog} from "../../../../utils/devLog/devLog";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import isPasswordValid from "../../../../utils/isPasswordValid";

export {RecruiterProfilesUserAccount};

const RecruiterProfilesUserAccount = (props: {
  profile: any,
  updateSingleton: Function,
  assistantGet: any,
  updateDataGet:any,
}) => {

  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const [editorModePassword, setEditorModePassword] = useState(false);
  const [previousPassword, setPreviousPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

  const updatePassword = async( previousPassword: string, newPassword: any) => {
    try {
      notification(appContextV2.dispatchPanels, "Envoi de la mise à jour...", "information");
      props.updateDataGet()({target: {type: "custom", name: "password", value: newPassword}});
      const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/u/my/password`, {
        checkPreviousPassword:!!props.profile.password,
        previousPassword:previousPassword,
        newPassword:newPassword,
      }, {withCredentials: true})).data;
      if(fetched.status === "ok") {
        notification(appContextV2.dispatchPanels, "Donnée mise à jour avec succès", "success");
      } else {

        notification(appContextV2.dispatchPanels, `Une erreur est survenue:${fetched.status}`, "error");
      }
    } catch(error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }

  return(<>

    <div className={"container white-container"}>
      <h1 className={"profile-edit__title"}>Données du compte</h1>

      <div>
        Email: {props.profile.email?.value}
        <br/><br/>
        <ProfileElementText nameDisplayed={"Email de secours"}
                            nameToUpdateBack={"emailSecondary"}
                            nameToUpdateFront={"email.emailSecondary"}
                            description={""}
                            value={props.profile.email?.emailSecondary || "Aucun n'a été défini"}
                            updateData={props.updateSingleton}
                            validator={(v: any)=>{return props.assistantGet.isEmail(v)}}
                            modifier={(v: any)=>{return v.toLowerCase()}} />
        <br/>
        <ProfileElementText nameDisplayed={"Trigramme"}
                            nameToUpdateBack={"trigram"}
                            nameToUpdateFront={"trigram"}
                            description={"Le trigramme vous identifie auprès de votre équipe"}
                            value={props.profile.trigram}
                            updateData={props.updateSingleton}
                            validator={(v: any) => {
                              return props.assistantGet.isLengthUnder(v, 3) && props.assistantGet.isLengthAbove(v, 3)
                            }}
                            modifier={(v: any)=>{return v.toUpperCase()}} />
        <br/>


        <div>
          <div className={"space-flex--left"}>
            <div>
              Mot de passe: {!editorModePassword && !!props.profile.password && "Un mot de passe est enregistré"}
              {!editorModePassword && !props.profile.password && "Aucun mot de passe n'est enregistré"}
            </div>

            {!editorModePassword && <button className={"btn-picto--minimalist2 btn-picto--minimalist2--small"} onClick={() => setEditorModePassword(true)}>
              <img src={"https://static.myshortlist.co/btn-pen.svg"}/>
            </button>}

            {editorModePassword && !props.profile.password && <>
              <input placeholder={"Mot de passe"} type={"password"} value={newPassword} className={"text-align"} onChange={(e) => setNewPassword(e.target.value)}/>
              <input placeholder={"Confirmation mot de passe"} type={"password"} value={newPasswordConfirmation} className={"text-align"} onChange={(e) => setNewPasswordConfirmation(e.target.value)}/>

              {/*----- Buttons */}
              <button disabled={!(isPasswordValid(newPassword) && newPassword===newPasswordConfirmation)} className={"btn-inline-dictionary"} onClick={async() => {
                await updatePassword(previousPassword,newPassword);
                setNewPassword("");
                setNewPasswordConfirmation("");
                setEditorModePassword(false);
              }}>
                Enregistrer
              </button>

              <button className={"btn-inline-dictionary"} onClick={() => {
                setNewPassword("");
                setNewPasswordConfirmation("");
                setEditorModePassword(false);
              }}>
                Annuler
              </button>

            </>}

            {editorModePassword && !!props.profile.password && <>
              <input placeholder={"Ancien mot de passe"} type={"password"} value={previousPassword} className={"text-align"} onChange={(e) => setPreviousPassword(e.target.value)}/>
              <input placeholder={"Mot de passe"} type={"password"} value={newPassword} className={"text-align"} onChange={(e) => setNewPassword(e.target.value)}/>
              <input placeholder={"Confirmation mot de passe"} type={"password"} value={newPasswordConfirmation} className={"text-align"} onChange={(e) => setNewPasswordConfirmation(e.target.value)}/>

              {/*----- Buttons */}
              <button disabled={!(isPasswordValid(newPassword) && newPassword===newPasswordConfirmation)} className={"btn-inline-dictionary"} onClick={async() => {
                await updatePassword(previousPassword, newPassword);
                setNewPassword("");
                setNewPasswordConfirmation("");
                setPreviousPassword("");
                setEditorModePassword(false);
              }}>
                Enregistrer
              </button>

              <button className={"btn-inline-dictionary"} onClick={() => {
                setNewPassword("");
                setNewPasswordConfirmation("");
                setPreviousPassword("");
                setEditorModePassword(false);
              }}>
                Annuler
              </button>

            </>}
            
          </div>
  
          {editorModePassword && newPassword && !isPasswordValid(newPassword) && <div className={"text--red"}>
            Le mot de passe doit contenir 8 caractères dont au moins 1 chiffre.
          </div>}
  
          {(editorModePassword && newPassword && newPasswordConfirmation && (newPassword !== newPasswordConfirmation)) && <div className={"text--red"}>
            Les mots de passe ne correspondent pas.
          </div>}
          
        </div>
      </div>

    </div>

  </>);
}