import AppContext, {IAppV2Context} from "../../App/context/AppContext";
import React, {useContext} from "react";
import WindowLogin from "./componentsWindow/WindowLogin";
import WindowSubscribe from "./componentsWindow/WindowSubscribe";
import WindowManageMember from "./componentsWindow/WindowManageMember";
import WindowTrackingLink from "./componentsWindow/WindowTrackingLink";
import WindowColumnOptions from "./componentsWindow/WindowColumnOptions";
import WindowKanbanActionAlert from "./componentsWindow/WindowKanbanActionAlert";
import WindowPoolOptions from "./componentsWindow/WindowPoolOptions";
import WindowAddMediaToBusinessPage from "./componentsWindow/WindowAddMediaToBusinessPage";
import WindowExportCandidatesFromPoolToPool from "./componentsWindow/WindowExportCandidatesFromPoolToPool";
import {WindowAddTeam} from "./componentsWindow/WindowAddTeam";
import {WindowManageTeam} from "./componentsWindow/WindowManageTeam";
import {WindowAddMember} from "./componentsWindow/WindowAddMember";
import {WindowManageInvite} from "./WindowManageInvite";
import {WindowDeleteMission} from "./componentsWindow/WindowDeleteMission";
import {WindowRepportQuestion} from "./componentsWindow/WindowRepportQuestion";
import WindowCandidateWindowV2 from "./componentsWindow/WindowCandidateWindowV2";
import WindowImportFromCommunity from "./componentsWindow/WindowImportFromCommunity";
import WindowImportFromOtherResearch from "./componentsWindow/WindowImportFromOtherResearch";
import WindowCreateCandidateToPool from "./WindowCreateCandidateToPool";
import WindowImportXlsCsv from "./componentsWindow/WindowImportXlsCsv";
import {WindowUpdateMissionField} from "./componentsWindow/WindowUpdateMissionField";
import {WindowSendMailToSelectedCandidates} from "./componentsWindow/WindowSendMailToSelectedCandidates";
import {WindowAskForCandidatesAvailability} from "./componentsWindow/WindowAskForCandidatesAvailability";
import {WindowSendSMSToSelectedCandidates} from "./componentsWindow/WindowSendSMSToSelectedCandidates";
import {WindowCandidateNotRegisteredWindow} from "./componentsWindow/WindowCandidateNotRegisteredWindow";
import WindowExportCandidatesFromPoolToKanban from "./componentsWindow/WindowExportCandidatesFromPoolToKanban";
import {WindowOpenQuestionsPresets} from "./componentsWindow/componentsWUMF2/WindowOpenQuestionsPresets";
import WindowMissionOptions from "./componentsWindow/WindowMissionOptions";
import WindowFindLinkedinProfile from "./componentsWindow/WindowFindLinkedinProfile";
import WindowColumnOptionsV2 from "./componentsWindow/WindowColumnOptionsV2";
import WindowMassActions from "./componentsWindow/WindowMassActions";
import WindowAmbassador from "./componentsWindow/WindowAmbassador";
import WindowAmbassadorReward from "./componentsWindow/WindowAmbassadorReward";
import WindowApp from "./componentsWindow/WindowApp";
import WindowAmbassadorLinkCopied from "./componentsWindow/WindowAmbassadorLinkCopied";
import WindowAddBusinessBlackBelt from "../../BlackBeltComponents/WindowAddBusinessBlackBelt";
import WindowBusinessBlackBeltManagers from "../../BlackBeltComponents/WindowBusinessBlackBeltManagers";
import WindozExportCandidatesFromGlobalSearchToKanban
  from "./componentsWindow/WindozExportCandidatesFromGlobalSearchToKanban";
import WindowMissionKubo from "./componentsWindow/WindowMissionKubo";

export {Window};

const Window = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  switch(appContextV2.appPanels?.window?.windowOptions?.type) {
    
    /**
     * Login
     */
    case "loginChoice":
    case "login":
      return (<WindowLogin/>);
    case "subscribe":
      return (<WindowSubscribe/>);
    
    /**
     * Teams
     */
    case "addTeam":
      return (<WindowAddTeam/>);
    case "manageTeam":
      return (<WindowManageTeam/>);
    case "addMember":
      return (<WindowAddMember/>);
    case "manageMember":
      return (<WindowManageMember/>);
    case "manageInvite":
      return (<WindowManageInvite/>);
    
    /**
     * Missions
     */
    case 'magicMissionLink':
      return (<WindowTrackingLink/>);
    case 'updateMissionField':
      return (<WindowUpdateMissionField/>);
    // case 'savedEditor':
    //   return (<WindowSavedEditor/>);
    // case 'privateTemplates':
    //   return (<WindowPrivateTemplates/>);
    case "deleteMission":
      return (<WindowDeleteMission/>);
    case 'reportQuestion':
      return (<WindowRepportQuestion/>);
    case"openPresets":
      return (<WindowOpenQuestionsPresets/>)
    case"missionOptions":
      return (<WindowMissionOptions/>)
    case"missionKubo":
      return (<WindowMissionKubo/>)


    
    /**
     * Business Profiles
     */
    case 'addMediaToBusinessPage':
      return (<WindowAddMediaToBusinessPage/>);
    
    /**
     * Kanban && candidacies
     */
    case 'columnOptions':
      return (<WindowColumnOptions/>);
    case 'columnOptionsV2':
      return (<WindowColumnOptionsV2/>);
    case 'kanbanActionAlert':
      return (<WindowKanbanActionAlert/>);
    case 'importFromOtherResearch':
      return (<WindowImportFromOtherResearch/>);
    case 'importFromCommunity':
      return (<WindowImportFromCommunity/>);
    case 'massActions':
      return (<WindowMassActions/>);
    
    /**
     * Pools
     */
    case 'poolOptions':
      return (<WindowPoolOptions/>);
    case 'exportCandidatesFromPoolToPool':
      return (<WindowExportCandidatesFromPoolToPool/>);
    case 'exportCandidatesFromPoolToKanban':
      return (<WindowExportCandidatesFromPoolToKanban/>);
    case 'sendSMSToSelectedCandidates':
      return (<WindowSendSMSToSelectedCandidates/>);
    case 'sendMailToSelectedCandidates':
      return (<WindowSendMailToSelectedCandidates/>);
    case 'askForCandidatesAvailability':
      return (<WindowAskForCandidatesAvailability/>);
    case 'importXlsCsv':
      return (<WindowImportXlsCsv/>);
    case 'createNewCandidateToPool':
      return (<WindowCreateCandidateToPool/>)

      /**
       * Research
       */
    case 'exportCandidatesFromGlobalSearchToKanban':
      return (<WindozExportCandidatesFromGlobalSearchToKanban/>);
    
    /**
     * ------------------------------------------
     * ------------------------------------------
     * Candidate and candidacy windows V2
     */
    case 'candidateWindowV2':
      return (<WindowCandidateWindowV2/>);
    case 'candidateNotRegisteredWindow':
      return (<WindowCandidateNotRegisteredWindow/>);
    
    /**
     * Ambassadors
     */
    case 'ambassador':
      return (<WindowAmbassador/>);
    case 'ambassadorReward':
      return (<WindowAmbassadorReward/>);
    // case 'updateAmbassador':
    //   return (<WindowUpdateAmbassador/>);
    case 'ambassadorLinkCopied':
      return (<WindowAmbassadorLinkCopied/>);
    
    /**
     * Apps
     */
    case 'app':
      return (<WindowApp/>);
    
    /**
     * Free candidacy
     */
    case 'candidacyFromBusinessPage':
    // return (<WindowCandidacyFromBusinessPage/>); // todo
    // case 'viewFreeCandidates':
    //   return (<WindowViewFreeCandidates/>); // todo
    
    /**
     * Help
     */
    case 'findLinkedinProfile':
      return (<WindowFindLinkedinProfile/>);

    case 'addBusinessBlackBelt':
      return (<WindowAddBusinessBlackBelt/>);

    case 'businessBlackBeltManagers':
      return (<WindowBusinessBlackBeltManagers/>);


    default:
      return (<></>);
  }
  
}

