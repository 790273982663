import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../components/sharedComponents/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  useEffect(() => {
    if(props.form?.contract?.toLowerCase() === "cdi") {
      props.wizard.goToStep("salaryBy");
    }
  }, []);
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Durée en mois
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container"}>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "duration", value: 1}});
                props.wizard.goToStep('salaryBy');
              }}>
        1
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "duration", value: 2}});
                props.wizard.goToStep('salaryBy');
              }}>
        2
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "duration", value: 3}});
                props.wizard.goToStep('salaryBy');
              }}>
        3
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "duration", value: 4}});
                props.wizard.goToStep('salaryBy');
              }}>
        4
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "duration", value: 6}});
                props.wizard.goToStep('salaryBy');
              }}>
        6
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "duration", value: 10}});
                props.wizard.goToStep('salaryBy');
              }}>
        10
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.wizard.goToStep('durationCustom');
              }}>
        Autre
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "duration", value: 0}});
                props.wizard.goToStep('salaryBy');
              }}>
        Ne pas renseigner
      </button>
      
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center text--grey assistant--v2-container--no-flex"}>
    </div>
  
  </section>);
}