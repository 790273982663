import React from "react";
import PageLayout from "../../../layouts/PageLayout";
import Spaces from "../../../components/sharedComponents/Spaces";
import {Link} from "react-router-dom";


export default function ContactUs(props: {page: string}) {
  
  return (
    <>
      <PageLayout page={props.page} title={"Nous contacter"} small={true} status={"ok"} grey={true} fullWidth={true}>
      
        <Spaces n={2}/>
      
        <section className={"container white-container"}>
          Pour toute question ou pour tout problème technique, vous pouvez utiliser le widget en bas à droite de l'écran.
          <br/><br/>
          Le support n'est actuellement pas en temps réel. N'oubliez donc pas de nous laisser votre email.
          <br/><br/>
          Pour toute question relative à la sécurité ou aux questions de confidentialité, votre interlocuteur est:
          <br/><br/>
          <p className={"text--center"}>
            <span className={"text--bold"}>ezra@myshortlist.co</span>
          </p>
        </section>
      
        <Spaces n={1}/>
        
        <p className={"text--center"}>
          <Link to={"/"}>
            <button className={"btn-main"}>
              Retour à l'accueil
            </button>
          </Link>
        </p>
        
        <Spaces n={2}/>
        
      </PageLayout>
    </>
  );
}