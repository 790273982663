import React, {useContext, useEffect, useReducer} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import {SingleApp, IAppContent} from "../../../../components/App/SingleApp";
import {appsList} from "./applicationsList";
import appsReducer from "./appsReducer";
import axios from "axios";
import {personalAppsList} from "./personnalAppsList";
import {devLog} from "../../../../utils/devLog/devLog";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [apps, dispatchApps] = useReducer(appsReducer, appsList);
  const [personalApps, dispatchPersonalApps] = useReducer(appsReducer, personalAppsList);
  
  const orderedApps = [
    ...apps.filter((app: IAppContent) => app.isAvailable && app.isActive),
    ...apps.filter((app: IAppContent) => app.isAvailable && !app.isActive),
    ...apps.filter((app: IAppContent) => !app.isAvailable)
  ];
  
  const orderedPersonalApps = [
    ...personalApps.filter((app: IAppContent) => app.isAvailable && app.isActive),
    ...personalApps.filter((app: IAppContent) => app.isAvailable && !app.isActive),
    ...personalApps.filter((app: IAppContent) => !app.isAvailable)
  ];

  devLog('apps',apps);

  useEffect(() => {
    (
      async() => {
        const {data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/business/apps`, {
          withCredentials: true
        })).data;
        dispatchApps({type: 'FIRST_UPDATE', value: data?.businessApps ?? []});
        dispatchPersonalApps({type: 'FIRST_UPDATE', value: data?.userApps ?? []});
      })();
  }, []);
  
  return (
    <div className={"container white-container"}>
      <h1 className={"profile-edit__title"}>Market-place</h1>
      
      <div className={"text--grey text--center"}>
        Augmentez les fonctionnalités de la plateforme en installant des applications.
      </div>
      
      <div className={"section-apps__title"}>
        Applications entreprise
      </div>
      
      <section className={'section-apps'}>
        {orderedApps.map((app: IAppContent) => (<SingleApp app={app} dispatcher={dispatchApps}/>))}
      </section>
      
      <div className={"section-apps__title"}>
        Applications utilisateur
      </div>
      
      <section className={'section-apps'}>
        {orderedPersonalApps.map((app: IAppContent) => (<SingleApp app={app} dispatcher={dispatchPersonalApps}/>))}
      </section>
    
    </div>
  );
}