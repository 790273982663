import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import WindowWrapper from "../../../../layouts/WindowLayout";
import WindowLoginStrategy from "./componentsWL/WindowLoginStrategy";
import WindowLoginMail from "./componentsWL/WindowLoginMail";
import {abstractPostFetch, abstractPutFetch} from "../../../../utils/abstractFetch";
import WindowLoginNoUser from "./componentsWL/WindowLoginNoUser";
import WindowLoginError from "./componentsWL/WindowLoginError";
import WindowLoginSuccess from "./componentsWL/WindowLoginSuccess";
import WindowAddMemberMail from "./componentsWAM/WindowAddMemberMail";
import WindowAddMemberRole from "./componentsWAM/WindowAddMemberRole";
import WindowAddMemberError from "./componentsWAM/WindowAddMemberError";
import ButtonWrapper from "../../../../layouts/ButtonWrapper";
import ButtonCheck from "../../Buttons/ButtonCheck";
import {useWizard} from "use-wizard";
import Spaces from "../../Spaces";
import {notification} from "../../../../utils/notification";
import {devLog} from "../../../../utils/devLog/devLog";
import axios from "axios";

export default function (props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [step, wizard] = useWizard(["splash", "role", "warningDelete", "pending"]);
  
  const teamId: string = appContextV2.appPanels.window.windowOptions.data?.teamId;
  const memberId: string = appContextV2.appPanels.window.windowOptions.data?.memberId;
  const email: string = appContextV2.appPanels.window.windowOptions.data?.email;
  const firstName: string = appContextV2.appPanels.window.windowOptions.data?.firstName;
  const lastName: string = appContextV2.appPanels.window.windowOptions.data?.lastName;
  const role: string = appContextV2.appPanels.window.windowOptions.data?.role;
  const userToUpdateId: string = appContextV2.appPanels.window.windowOptions.data?.userToUpdateId;
  const password: string = appContextV2.appPanels.window.windowOptions.data?.password;
  
  const deleteUser = async() => {
    try {
  
      notification(appContextV2.dispatchPanels, "Suppression de l'utilisateur", "information");
      wizard.goToStep('pending');
      
      const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/t/member/delete`, {
        teamId: teamId,
        memberId: memberId,
      }, {withCredentials: true});

      if(fetched.data.status !== "ok") {
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }

      appContextV2.appPanels.window?.windowOptions?.data?.deleteMemberFront(memberId);
      notification(appContextV2.dispatchPanels, "Succès de la suppression", "success");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      
    
    } catch(error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
    }
  }
  
  const updateUser = async(role: 'admin' | 'user' | 'manager') => {
    try {
  
      notification(appContextV2.dispatchPanels, "Mise à jour de l'utilisateur", "information");
      wizard.goToStep('pending');
  
      const fetched: any = await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/t/member/update`, {
        teamId: teamId,
        memberId: memberId,
        userToUpdateId: userToUpdateId,
        role: role,
      }, {withCredentials: true});

      if(fetched.data.status !== "ok") {
        appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
  
      appContextV2.appPanels.window?.windowOptions?.data?.updateMemberFront(memberId, role);
      notification(appContextV2.dispatchPanels, "Succès de la mise à jour", "success");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      
    } catch(error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
    }
  }
  
  if(step === "pending") {
    return (<WindowWrapper windowTitle={""}>
    </WindowWrapper>);
  }
  
  if(step === "warningDelete") {
    return (<WindowWrapper windowTitle={""} status={"ok"}>
      <Spaces n={1}/>
      <div className={"text--center"}>
        Tu es sur le point de retirer {firstName} {lastName} de toutes les équipes. Poursuivre ?
      </div>
      <Spaces n={1}/>
      <div className={"text--center"}>
        <button className={"btn-main"} onClick={()=>{wizard.goToStep("splash")}}>
          Annuler
        </button>
        <button className={"btn-main"} onClick={()=>deleteUser()}>
          Retirer
        </button>
      </div>
    </WindowWrapper>);
  }
  
  if(step === "role") {
    return (<WindowWrapper windowTitle={""} status={"ok"}>
      <Spaces n={1}/>
      <div className={"text--center"}>
        Choisis le nouveau rôle de {firstName} {lastName}
      </div>
      <Spaces n={1}/>
      <div className={"text--center"}>
        {
          role !== "user" && <button className={"btn-main"} onClick={()=>{updateUser('user')}}>
            Utilisateur
          </button>
        }
        {
          role !== "manager" && <button className={"btn-main"} onClick={()=>{updateUser('manager')}}>
            Manager
          </button>
        }
        {
          role !== "admin" && <button className={"btn-main"} onClick={()=>{updateUser('admin')}}>
            Admin
          </button>
        }
      </div>
    </WindowWrapper>);
  }
  
  return(<WindowWrapper windowTitle={""} status={"ok"}>
  
    <div className={"text--center text--magnified2x"}>
      Modifier {firstName} {lastName}
    </div>
    <div className={"text--center"}>
      {role === "admin" && "Admin"}
      {role === "manager" && "Manager"}
      {role === "user" && "Utilisateur/trice"}
    </div>
    <Spaces n={1}/>
    <div className={"text--center"}>
      <span className={"text--magnified2x"}>{email}</span>
    </div>
    <Spaces n={1}/>
    <div className={"text--center"}>
      <button className={"btn-main"} onClick={()=>{wizard.goToStep("warningDelete")}}>
        Retirer
      </button>
      <button className={"btn-main"} onClick={()=>{wizard.goToStep("role")}}>
        Modifier son rôle
      </button>
    </div>
    <Spaces n={0}/>
    {!password && <div className={"text--center text--red"}>
      L'utilisateur / utilisatrice n'a pas défini de mot de passe.
    </div>}
    
  </WindowWrapper>);
}
