import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import AssistantLayout from "../../../../../layouts/AssistantLayout";


export default function (props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  const assistantTitle: string = ((language: string) => {
    switch(language) {
      case 'FR':
        return "Es-tu un recruteur ou un candidat ?";
      case 'EN':
      default:
        return "What kind of account do you wish to create ?";
    }
  })(language);
  
  const assistantComment: any = <p className={"text--center"}>
    <button className={"btn--hollow modern-link"} disabled={false} onClick={(e) => {
      e.preventDefault();
      appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
          type: "login",
          data: {
            email: props.form.email,
          }
        }});
    }}>
      {language === 'FR' ? "Tu es déjà inscrit ? Identifie-toi !" : ""}
      {language === 'EN' ? "I already have an account" : ""}
    </button>
  </p>;
  
  return(<AssistantLayout title={assistantTitle} comment={assistantComment} animated={true}>
  
    <button className={"btn-main"} key={1} onClick={async () => {
      props.windowContext.updateForm()({target: {type: "custom", name: "quality", value: "recruiter"}});
      props.windowContext.wizard.nextStep();
    }}>
      {language === 'FR' ? "Recruteur" : ""}
      {language === 'EN' ? "Recruiter" : ""}
    </button>
  
    <button className={"btn-main"} key={2} onClick={async () => {
      props.windowContext.updateForm()({target: {type: "custom", name: "quality", value: "candidate"}});
      props.windowContext.wizard.nextStep();
    }}>
      {language === 'FR' ? "Candidat" : ""}
      {language === 'EN' ? "Candidate" : ""}
    </button>

  </AssistantLayout>)
}