import React from "react";
import DisplayTutorialSteps from "../../DisplayTutorialSteps";

export default (props: any) => {

    return (<div className={"window-tutorial__content"}>

        <div className={"window-tutorial__main-content animation-fade-in"}>
            <h1 className={"window-tutorial__title1"}> Ouvrez un nouveau poste</h1>
            <p className={"window-tutorial__container"}>
                <br/>
                Cliquez en haut à gauche sur "nouveau poste" pour créer une nouvelle annonce.<br/><br/>
                L'ouverture d'un poste se fait en deux étapes:<br/><br/>

                {/*<img className={"window-tutorial__picture"}/><br/><br/>*/}

                Un <b className={"window-tutorial__b"}>assistant</b> vous accompagne pour définir votre besoin.<br/><br/>

                {/*<img className={"window-tutorial__picture"}/><br/><br/>*/}

                Un <b className={"window-tutorial__b"}>éditeur </b>vous permet de créer un formulaire auquel le candidat répondra question par question.<br/><br/>

                {/*<img className={"window-tutorial__picture"}/><br/><br/>*/}

                Les questions peuvent être des <b className={"window-tutorial__b"}> questions ouvertes</b>, des <b className={"window-tutorial__b"}>questions à choix unique</b> ou des <b className={"window-tutorial__b"}>questions à choix multiples</b>.<br/><br/>
                N'oubliez pas de <b className={"window-tutorial__b"}>noter vos réponses</b> en fonction de l'importance que vous leur attribuez. Cette notation vous permettra d'obtenir une liste organisée de candidatures.<br/><br/>
            </p>
        </div>

        <div className={"window-tutorial__secondary-content"}>
            <DisplayTutorialSteps {...props}/>

            <div>
                <button className={"btn-secondary"} onClick={async (e) => props.hideTutorialForEver()}>
                    Ne plus afficher
                </button>

                <button className={"btn-secondary"} onClick={() => props.setStep((ps: number) => ps + 1)}>Suivant</button>
            </div>
        </div>

    </div>);
}

