import React, { useContext, useEffect, useState } from "react";
import SplashScreenV3 from "./componentsLandingPageV3/SplashScreenV3";
import AppContext, {IAppV2Context} from "../../components/App/context/AppContext";
import Spaces from "../../components/sharedComponents/Spaces";
import LP2Kanban from "./componentsLandingPageV2/LP2Kanban";
import LP2Form from "./componentsLandingPageV2/LP2Form";
import LP2BusinessPage from "./componentsLandingPageV2/LP2BusinessPage";
import LP2Teams from "./componentsLandingPageV2/LP2Teams";
import LP3Adopters from "./componentsLandingPageV3/LP3Adopters";
import LandingPageComments from "./componentsLandingPage/LandingPageComments";
import LandingPageCommentsV3 from "./componentsLandingPageV3/LandingPageCommentsV3";
import LandingPageApps from "./componentsLandingPageV3/LandingPageApps";
import LP3Navbar from "./componentsLandingPageV3/LP3Navbar";
import WindowLogin from "../../components/sharedComponents/Window/componentsWindow/WindowLogin";
import { useWizard } from "use-wizard";
import axios from "axios";
import { devLog } from "../../utils/devLog/devLog";
import { notification } from "../../utils/notification";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  useEffect(() => {
    appContextV2.dispatchNavigation({type: "SET_PAGE", value: props.page});
  }, []);

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  })

  const buttonEnabled = loginData.email !== "" && loginData.password !== "";

  async function login() {
    try {
      if (!buttonEnabled) {
        return
      }
      const {
        status,
        data
      } = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/session/connect/with/password`, {
        email: loginData.email,
        password: loginData.password,
      }, {
        withCredentials: true,
      })).data;

      if (status === 'ok') {
        props.history.push('/');
        window.location.reload();
      }

      if (status === 'noUser' || status === 'blocked') {
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        return
      }

      if(status === 'noValidPassword' ){
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        return

      }
      if( status === 'blocked'){
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
        return
      }

    } catch (error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      return
    }
  }

  return (<>

    <div style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexFlow: "column",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <div className={"magic-font"}>
        Black-Belt
      </div>
      <div style={{
        display: "flex",
        flexFlow: "row",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <input style={{
          borderRadius: 30,
        }} type={'email'} placeholder={'email'} value={loginData.email} onChange={(e) => {
          const v = e.target.value
          setLoginData(ps => ({...ps, email: v}))}
        }/>
        <input style={{
          borderRadius: 30,
        }} type={'password'} placeholder={'password'} value={loginData.password} onChange={(e) => {
          const v = e.target.value
          setLoginData(ps => ({...ps, password: v}))}
        }/>
        <br/>
        <button disabled={!buttonEnabled} onClick={() => login()} className={'btn--hollow btn-bold'}>
          Go
        </button>
      </div>
    </div>
    
    {/*<LP3Navbar />*/}
    
    {/*<SplashScreenV3 />*/}

    {/*<Spaces n={3}/>*/}
    
    {/*<LP3Adopters />*/}

    {/*<Spaces n={2}/>*/}

    {/*<LandingPageCommentsV3 />*/}

    {/*<Spaces n={0}/>*/}

    {/*<LP2Kanban />*/}

    {/*<Spaces n={1}/>*/}
    
    {/*<LandingPageApps />*/}

    {/*<Spaces n={0}/>*/}

    {/*<LP2Form/>*/}

    {/*<Spaces n={0}/>*/}

    {/*<LP2BusinessPage />*/}

    {/*<Spaces n={0}/>*/}

    {/*<LP2Teams />*/}

    {/*<Spaces n={2}/>*/}
    
  </>);
}