import React, {useEffect, useState} from "react";
import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";
import LoadingStyle2 from "../../../Loading/LoadingStyle2";
import Spaces from "../../../Spaces";
import {AmbassadorsStatsItem} from "./AmbassadorsStatsItem";

export {AmbassadorsStats};

const AmbassadorsStats = (props: {}) => {
  
  const [pageStatus, setPageStatus] = useState("asleep");
  const [ambassadorsStats, setAmbassadorsStats]: any[] = useState({
    ambassadorsViews: [],
    ambassadorsCandidacies: [],
    ambassadorsRecruitments: []
  });
  
  useEffect(() => {
    (async function() {
      try {
        setPageStatus("pending");

        const {status, data} = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/data/stats/ambassadors`,
          {
            withCredentials: true
          })).data;

        if(status !== "ok") {
          setPageStatus("error");
          return;
        }

        const ambassadorsViews = data?.ambassadorsViews?.map((item: any, key: number) => ({
          angle: item.number,
          label: item.fullName,
          key: key
        })) ?? [];

        const ambassadorsCandidacies = data?.ambassadorsCandidacies?.map((item: any, key: number) => ({
          angle: item.number,
          label: item.fullName,
          key: key
        })) ?? [];

        const ambassadorsRecruitments = data?.ambassadorsRecruitments?.map((item: any, key: number) => ({
          angle: item.number,
          label: item.fullName,
          key: key
        })) ?? [];

        setAmbassadorsStats({
          ambassadorsViews,
          ambassadorsCandidacies,
          ambassadorsRecruitments,
        });

        setPageStatus("ok");
      } catch(error) {
        devLog(error);
      }
    })();
  }, []);
  
  devLog("ambassadorsStats", ambassadorsStats);
  
  const areStatsEmpty =
    ambassadorsStats?.ambassadorsViews.length === 0 &&
    ambassadorsStats?.ambassadorsCandidacies.length === 0 &&
    ambassadorsStats?.ambassadorsRecruitments.length === 0;
  
  return (
    <>
      {
        pageStatus === "pending" || pageStatus === "asleep" && <>
          <div className={"ambassador--stats__container"}>
            <div className={"text--center title--bold"}>Statistiques ambassadeurs</div>
            <Spaces n={1}/>
            <LoadingStyle2/>
            <Spaces n={2}/>
          </div>
        </>
      }
      
      {
        pageStatus === "error" && <>
          <div className={"ambassador--stats__container"}>
            <div className={"text--center title--bold"}>Statistiques ambassadeurs</div>
            <Spaces n={1}/>
            Les statistiques ambassadeur n'ont pas pu être chargées
          </div>
        </>
      }
      
      {
        pageStatus === "ok" && areStatsEmpty && <>
          <div className={"ambassador--stats__container"}>
            <div className={"text--center title--bold"}>Statistiques ambassadeurs</div>
            <Spaces n={1}/>
            Vous n'avez pas encore de statistiques
          </div>
        </>
      }
      
      {
        pageStatus === "ok" && !areStatsEmpty && <>
          <div className={"ambassador--stats__container"}>

            <div className={"text--center title--bold"}>Statistiques ambassadeurs</div>

            <Spaces n={1}/>

            <div className={"ambassador--stats__div"}>
              <AmbassadorsStatsItem title={"Vues"} data={ambassadorsStats?.ambassadorsViews}/>
              <AmbassadorsStatsItem title={"Candidatures"} data={ambassadorsStats?.ambassadorsCandidacies}/>
              <AmbassadorsStatsItem title={"Recrutements"} data={ambassadorsStats?.ambassadorsRecruitments}/>
            </div>

          </div>
        </>
      }
    </>
  );
}