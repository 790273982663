import AppContext, {IAppV2Context} from "../../../App/context/AppContext";
import React, {useContext} from "react";
import {MenuDropDownCandidatePc, MenuDropDownPublicPc, MenuDropDownRecruiterPc} from "./NavBarDropDownPc";
import {MenuDropDownSmartphones} from "./MenuDropDownSmartphones";

export {MenuDropDown};

function MenuDropDown(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;

  const teamIdsWithoutNavBar = [
      "62795122df7950847dba2d6c",
      "6058bb0b155da2cbee753608",
      "643c4b3f17868d370f1f02e1",
      "65134abf223ce13ac6aed71c"
  ]; //
  
  switch(appContextV2.appSession.app.space) {
    
    case "recruiter":
      return (<>
        {!teamIdsWithoutNavBar.includes(appContextV2.appSession.sessionBusiness.teamId) && <MenuDropDownRecruiterPc/>}
        {!teamIdsWithoutNavBar.includes(appContextV2.appSession.sessionBusiness.teamId) && <MenuDropDownSmartphones/>}
        </>);
    
    case "candidate":
      return (<>
        <MenuDropDownCandidatePc/>
        <MenuDropDownSmartphones/>
      </>);
    
    case 'public':
      return (<>
        <MenuDropDownPublicPc/>
        <MenuDropDownSmartphones/>
      </>);
  
    default:
      return(<></>);
  }
}