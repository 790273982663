import React, {useContext} from "react";
import {ButtonBigPlusV2} from "../../../../components/sharedComponents/Buttons/ButtonBigPlus";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";

export {BusinessAddTeam};

const BusinessAddTeam = (props: {
  addTeamFront: Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return(<div className={"teams__team teams__team__add-team"}>
    
    <ButtonBigPlusV2 cb={()=>{
      appContextV2.dispatchPanels( {
        type: "OPEN_WINDOW",
        value: {
          type: "addTeam",
          data: {
            addTeamFront: props.addTeamFront,
          }
        }
      });
    }} title={"Ajoute une nouvelle équipe"} />
    <div><br/></div>
    <div className={"text--center text--grey"}>
      Ajoute une nouvelle équipe.
    </div>
    
  </div>);
}