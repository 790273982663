import React from "react";
import {RadialChart} from "react-vis";
import Spaces from "../../../Spaces";

export {AmbassadorsStatsItem};

const AmbassadorsStatsItem = (props: { title: string, data: any }) => {
  
  const isEmpty = !props.data || props.data.length === 0 || props.data[0]?.angle === 0;
  
  return (
    <div className={"ambassador--stats__item animation-fade-in"}>
      <div className={"ambassador--stats__title"}>
        {props.title}
      </div>
      {!isEmpty && <>
        
        <Spaces n={0}/>
        
        <div className={"ambassador--stats__radial-chart"}>
          <RadialChart
            data={props.data ?? []}
            animation={true}
            radius={80}
            width={180}
            height={180}
            innerRadius={50}/>
        </div>
        
        {/*<Spaces n={0}/>*/}
        
        <div className={"ambassador__legends"}>
          
          {(props.data ?? [])[0] && <div className={"ambassador__legend ambassador-one"}>
            {(props.data ?? [])[0]?.label ?? ""}
          </div>}
          
          {(props.data ?? [])[1] && <div className={"ambassador__legend ambassador-two"}>
            {(props.data ?? [])[1]?.label ?? ""}
          </div>}
          
          {(props.data ?? [])[2] && <div className={"ambassador__legend ambassador-three"}>
            {(props.data ?? [])[2]?.label ?? ""}
          </div>}

        </div>
      </>}
      
      {
        isEmpty && <div className={"ambassador-no-stats"}>
          Pas de données
        </div>
      }
    
    </div>
  );
}