import React from "react";


export default function AssistantLayout(props: { children: any, title: string, comment: string, animated: boolean, minHeight?: boolean, flexed?: boolean }) {
  
  let flexed: boolean = true;
  let minHeight: boolean = true;
  
  if(typeof props.minHeight === "undefined") {
    minHeight = false;
  } else {
    minHeight = true;
  }
  
  if(typeof props.flexed === "undefined") {
    flexed = true;
  } else {
    flexed = props.flexed;
  }
  
  return (<>
      
      {!!props.title && <>
        <p className={"assistant-layout__title"}>{props.title}</p>
      </>
      }
      
      {!props.title && <>
        <p className={"assistant-layout__title"}> </p>
      </>
      }
      
      <div className={"assistant-layout__min-height"}>
        <article className={`${ flexed ? "assistant-layout" : "assistant-layout--column"} ${minHeight ? "assistant--min-height":""} ${props.animated ? "assistant-layout--animated" : ""}`}>
          {props.children}
        </article>
      </div>
      
      {/*<hr className={"hr-soft space-assistant-hr"}/>*/}
      
      {!!props.comment && <>
        <div className={"assistant-layout__comment"}>
          {props.comment}
        </div>
      </>
      }
      
    </>
  );
}