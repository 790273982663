import AppContext, {IAppV2Context} from "../App/context/AppContext";
import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {devLog} from "../../utils/devLog/devLog";
import Spaces from "./Spaces";

export default (props: any) => {

  const [feedbackCandidates, setFeedbackCandidates] = useState([]);
  
  useEffect(() => {
    (async function() {
      try {
        const answer = await axios.get(`${process.env.REACT_APP_API_URL}/v3/data/feedback/mat/cam/get/candidate/feedback`, {withCredentials: true});
        setFeedbackCandidates(answer.data.data);
      } catch(error) {
        devLog(error);
      }
    })()
  }, []);
  
  devLog("feedbackCandidates", feedbackCandidates);
  
  return (<div className={"display-feedback-on-platform"}>
    {feedbackCandidates?.map((item: any, key: number) => (
       <div className={"display-feedback-on-platform__item"} >
         <div className={"display-rating-component "}>
          <div
            className={item.answer1 === 1 ? "display-rating-component--white display-rating-component--selected" : "display-rating-component--white"}>
            <img className={"rating--component--btn--img"} src={"https://static.myshortlist.co/img-rating-1.png"}/>
          </div>
          
          <div
            className={item.answer1 === 2 ? "display-rating-component--white display-rating-component--selected" : "display-rating-component--white"}>
            <img className={"rating--component--btn--img"} src={"https://static.myshortlist.co/img-rating-2.png"}/>
          </div>
          
          <div
            className={item.answer1 === 3 ? "display-rating-component--white display-rating-component--selected" : "display-rating-component--white"}>
            <img className={"rating--component--btn--img"} src={"https://static.myshortlist.co/img-rating-3.png"}/>
          </div>
          
          <div
            className={item.answer1 === 4 ? "display-rating-component--white display-rating-component--selected" : "display-rating-component--white"}
          >
            <img className={"rating--component--btn--img"} src={"https://static.myshortlist.co/img-rating-4.png"}/>
          </div>
          
          <div
            className={item.answer1 === 5 ? "display-rating-component--white display-rating-component--selected" : "display-rating-component--white"}>
            <img className={"rating--component--btn--img"} src={"https://static.myshortlist.co/img-rating-5.png"}/>
          </div>
        </div>

         <Spaces n={0}/>
        
        <div className={"text--center"}> {item.answer4} </div>
</div>
    
    ))}
  </div>)
}
