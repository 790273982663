import React from "react";

export default (props: {
    onClick: Function
}) => {

    return (
        <div>
            <button className={"mission--V2__buttons__parameters"} onClick={()=>props.onClick()}>
                <img src={"https://static.myshortlist.co/btn-save.svg"}/>
            </button>
        </div>
    );
}