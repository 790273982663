import React from "react";

export default (props: {
  selected: number,
  setSelected: Function
}) => {
  
  return (
    <div className={"rating-component "}>
      <button
        className={props.selected === 1 ? "rating-component--white rating-component--selected" : "rating-component--white"}
        onClick={() => {
          props.setSelected(1);
        }}>
        <img className={"rating--component--btn--img"} src={"https://static.myshortlist.co/img-rating-1.png"}/>
      </button>
      
      <button
        className={props.selected === 2 ? "rating-component--white rating-component--selected" : "rating-component--white"}
        onClick={() => {
          props.setSelected(2);
        }}>
        <img className={"rating--component--btn--img"} src={"https://static.myshortlist.co/img-rating-2.png"}/>
      </button>
      
      <button
        className={props.selected === 3 ? "rating-component--white rating-component--selected" : "rating-component--white"}
        onClick={() => {
          props.setSelected(3);
        }}>
        <img className={"rating--component--btn--img"} src={"https://static.myshortlist.co/img-rating-3.png"}/>
      </button>
      
      <button
        className={props.selected === 4 ? "rating-component--white rating-component--selected" : "rating-component--white"}
        onClick={() => {
          props.setSelected(4);
        }}>
        <img className={"rating--component--btn--img"} src={"https://static.myshortlist.co/img-rating-4.png"}/>
      </button>
      
      <button
        className={props.selected === 5 ? "rating-component--white rating-component--selected" : "rating-component--white"}
        onClick={() => {
          props.setSelected(5);
        }}>
        <img className={"rating--component--btn--img"} src={"https://static.myshortlist.co/img-rating-5.png"}/>
      </button>
      
    </div>
  
  );
}