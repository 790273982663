////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// LI GENERATOR
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import AppContext, {IAppContext, IAppV2Context} from "../../../../App/context/AppContext";
import React, {useContext} from "react";
import {Link} from "react-router-dom";

export default function DropDownLinkGenerator(props: {
  active: boolean,
  uri: string,
  fr: string,
  en: string,
}) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  const language: string = appContextV2.appSession.app.language;
  
  return (
    <li>
      <Link to={props.uri}>
        <button className={`btn--hollow ${props.active ? "selected" : ""}`}
                onClick={() => {
                    if(!!appContextV2.appPanels.menuFromBottom.isMenuFromBottomVisible) {
                      appContextV2.dispatchPanels({type: "CLOSING_MENU_FROM_BOTTOM", value: null});
                      const timer = setTimeout(() => {
                        appContextV2.dispatchPanels({type: "CLOSE_MENU_FROM_BOTTOM", value: null});
                        clearTimeout(timer);
                      }, 125);
                    }
                  }
                }
        >
          {language === "FR" && <>{props.fr}</>}
          {language === "EN" && <>{props.en}</>}
        </button>
      </Link>
    </li>
  
  );
}
