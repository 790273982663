import {initialSession} from "../state/initialSession";
import {IAppV2Session} from "../state/IAppV2";
import React from "react";

export {sessionReducer};

export type {ISetSessionAction}

interface ISetSessionAction {
  type: string;
  value: any;
}

const sessionReducer = (previousState: IAppV2Session, action: ISetSessionAction): IAppV2Session => {

  switch (action.type) {

    case 'SET_SESSION':
      return ({
        ...previousState,
        app: {
          ...previousState.app,
          mode: action.value?.data?.mode,
          appStatus: "ok",
          space: action.value?.data?.space,
          allowSignup: action.value?.data?.allowSignup,
          displayTutorial: action.value?.data?.displayTutorial,

        },
        session: {
          ...previousState.session,
          firstName: action.value?.data?.firstName,
          lastName: action.value?.data?.lastName,
          trigram: action.value?.data?.trigram,
          userAvatar: action.value?.data?.userAvatar,
          ban: action.value?.data?.ban,
          profileLink: {
            ...previousState.session.profileLink,
            name: action.value?.data?.profileLink?.name,
            number: action.value?.data?.profileLink?.number,
          },
          userStatus: action.value?.data?.userStatus,
          userRole: action.value?.data?.userRole,
          isEmailVerified: action.value?.data?.isEmailVerified,
          isListeningToMarket: action.value?.data?.isListeningToMarket,
        },
        sessionBusiness: {
          ...previousState.sessionBusiness,
          businessName: action.value?.data?.businessName,
          openedMissions: action.value?.data?.openedMissions,
          creditForSms: action.value?.data?.creditForSms,
          parsing: action.value?.data?.parsing,
          isRecruitingAgency: action.value?.data?.isRecruitingAgency,
          profileLink: {
            ...previousState.session.profileLink,
            name: action.value?.data?.profileLink?.name,
            number: action.value?.data?.profileLink?.number,
          },
          plan: action.value?.data?.plan,
          businessAvatar: action.value?.data?.businessAvatar,
          teamId: action.value?.data?.teamId,
          teams: action.value?.data?.teams,
          members: action.value?.data?.members
        }
      });

    case 'INITIALIZE_SESSION':
      return ({
        ...previousState,
        ...initialSession,
        app: {
          ...previousState.app,
          mode: previousState.app.mode,
          appStatus: 'pending',
          language: previousState.app.language,
        },
        session: {
          ...previousState.session,
          userStatus: "",
        },
      });

    case "SET_SESSION_ERROR":
      return ({
        ...previousState,
        app: {
          ...previousState.app,
          appStatus: "error",
        }
      });

    case 'SET_LANGUAGE':
      const languages = ["FR", "EN"];
      if(!languages.includes(action.value as string)) {
        throw new Error('Language is not valid');
      }
      return ({
        ...previousState,
        app: {
          ...previousState.app,
          language: action.value as "FR" | "EN",
        }
      });

    case 'UPDATE_MISSIONS_NUMBER':
      return {
        ...previousState,
        sessionBusiness: {
          ...previousState.sessionBusiness,
          openedMissions: previousState.sessionBusiness.openedMissions + action.value,
        }
      };

    case 'RESET_MISSIONS_NUMBER':
      return {
        ...previousState,
        sessionBusiness: {
          ...previousState.sessionBusiness,
          openedMissions: action.value,
        }
      };

    case 'UPDATE_SESSION':
      return ({
        ...previousState,
        session: {
          ...previousState.session,
          [action.value.key]: action.value.value,
        }
      });

    case 'UPDATE_APP':
      return ({
        ...previousState,
        app: {
          ...previousState.app,
          [action.value.key]: action.value.value,
        }
      });

    case 'UPDATE_TEAM_ID':
      return ({
        ...previousState,
        sessionBusiness: {
          ...previousState.sessionBusiness,
          teamId: action.value,
        }
      });

    case 'UPDATE_SESSION_BUSINESS':
      return ({
        ...previousState,
        sessionBusiness: {
          ...previousState.sessionBusiness,
          [action.value.key]: action.value.value,
        }
      });

    case 'USE_SMS_CREDIT':
      return ({
        ...previousState,
        sessionBusiness: {
          ...previousState?.sessionBusiness,
          creditForSms: (previousState?.sessionBusiness?.creditForSms || 0) - action.value
        }
      });

    case 'CLOSE_TUTORIAL':
      return ({
        ...previousState,
        app: {
          ...previousState?.app,
          displayTutorial: false
        }
      });

    case 'OPEN_TUTORIAL':
      return ({
        ...previousState,
        app: {
          ...previousState?.app,
          displayTutorial: true
        }
      });

    case 'SET_PARAMETERS':
      return ({
        ...previousState,
        session: {
          ...previousState.session,
          displayCookies: action.value?.data?.displayCookies,
        }});


    case 'CLOSE_COOKIES':
      return ({
        ...previousState,
        session: {
          ...previousState?.session,
          displayCookies: false
        }
      });



    default:
      throw new Error(`Type ${action.type} is not defined in sessionReducer`);
  }

}