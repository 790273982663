import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../components/App/context/AppContext";
import SubPageLayout from "../../../../../layouts/SubPageLayout";
import Spaces from "../../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../../components/sharedComponents/Buttons/ButtonBack";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [skills, setSkills] = useState(props.mission?.skills?.map((item: any) => ({skill: item.skill, needed: true, selected: false,})) || []);
  
  const [showPage, setShowPage] = useState(false);
  
  useEffect(() => {
    if(skills.length === 0) {
      props.wizard.nextStep();
    } else {
      setShowPage(true);
    }
  }, []);
  
  const toggleSkill = (key: number) => {
    setSkills((ps: any) => {
      const newState = [...ps];
      newState[key].selected = !ps[key].selected;
      return newState;
    });
  }
  
  if(!showPage) {
    return (<><Spaces n={15}/></>)
  }
  
  return (<SubPageLayout status={"ok"} subPage={props.subPage}>
    
    <Spaces n={3}/>
    
    {/*<div className={"assistant--v2-container--no-flex"}>*/}
    {/*  <div className={"space-light"}>*/}
    {/*    <ButtonBack back={() => props.wizard.previousStep()}/>*/}
    {/*  </div>*/}
    {/*</div>*/}
    
    <div className={"assistant--v2-title"}>
      Quelles compétences de cette liste maîtrisez-vous ?
    </div>
    
    <Spaces n={1}/>
    
    <section className={"assistant--v2-container assistant--v2-container--column"}>
      
      {skills?.map((item: any, key: number) => (
        <article key={key} className={`assistant--v2-container__label cursor--pointer ${item.selected ? "assistant--v2-container__label--color2" : ""}`}
                 onClick={() => {toggleSkill(key)}}>
          {item.skill}
        </article>))}
    
    </section>
    
    <Spaces n={1}/>
    
    {/*---------------------------------------------- Next */}
    <div className={"text--center"}>
      <button className={"btn-assistant-main"}
              onClick={() => {
                const elapsedTime = Math.floor((new Date().getTime()) / 1000) - props.timeStart;
                // props.updateAnswer((ps: any) => ({
                //   ...ps,
                //   elapsedTime: elapsedTime,
                //   skills: skills,
                // }));
                props.updateAnswer()({target: {type: "custom", name: "elapsedTime", value: elapsedTime}});
                props.updateAnswer()({target: {type: "custom", name: "skills", value: skills}});
                props.wizard.nextStep();
              }}>
        Suivant
      </button>
    </div>
  
  
  </SubPageLayout>);
}