import React from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../components/sharedComponents/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const isButtonActive: boolean = !!props.form.location.postalCode && !!props.form.location.country && !!props.form.location.city;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Quel est le lieu du poste ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container--no-flex text--center assistant--v2--appear"}>

      <label className={"text--minified3x"}>Pays*<br/>
        <input type={"text"} className={"assistant--v2-input assistant--v2-input--center"} name={"location.country"}
               value={props.form.location.country} onChange={props.updateForm({maximumLength: 50})}/>
      </label>

  
      <Spaces n={0}/>

      <label className={"text--minified3x"}>Région<br/>
        <select aria-hidden={true} className={"assistant--v2-input assistant--v2-input--center"} name={"location.state"}
               value={props.form.location.state}  onChange={props.updateForm()}>
          <option value="" className="" data-select2-id="0"></option>
          <option value="FR.BNO" className="" data-select2-id="230">Basse Normandie</option>
          <option value="FR.ALS" className="" data-select2-id="214">Alsace</option>
          <option value="FR.AQU" className="" data-select2-id="216">Aquitaine</option>
          <option value="FR.BRE" className="" data-select2-id="218">Bretagne</option>
          <option value="FR.AUV" className="" data-select2-id="217">Auvergne</option>
          <option value="FR.BOU" className="" data-select2-id="219">Bourgogne</option>
          <option value="FR.CHA" className="" data-select2-id="221">Champagne-Ardennes</option>
          <option value="FR.CEN" className="" data-select2-id="220">Centre</option>
          <option value="FR.COR" className="" data-select2-id="222">Corse</option>
          <option value="FR.FCO" className="" data-select2-id="223">Franche-Comté</option>
          <option value="FR.HNO" className="" data-select2-id="224">Haute Normandie</option>
          <option value="FR.IDF" className="" data-select2-id="225">Ile-de-France</option>
          <option value="FR.LAN" className="" data-select2-id="226">Languedoc-Roussillon</option>
          <option value="FR.LIM" className="" data-select2-id="227">Limousin</option>
          <option value="FR.PLO" className="" data-select2-id="228">Pays de la Loire</option>
          <option value="FR.LOR" className="" data-select2-id="229">Lorraine</option>
          <option value="FR.MIP" className="" data-select2-id="231">Midi-Pyrénées</option>
          <option value="FR.NPC" className="" data-select2-id="232">Nord-Pas-de-Calais</option>
          <option value="FR.PIC" className="" data-select2-id="233">Picardie</option>
          <option value="FR.PCH" className="" data-select2-id="234">Poitou-Charentes</option>
          <option value="FR.PACA" className="" data-select2-id="235">Provence-Alpes-Côte-d'Azur</option>
          <option value="FR.RHA" className="" data-select2-id="236">Rhône-Alpes</option>
          <option value="FR.DT.GP" className="" data-select2-id="237">Guadeloupe</option>
          <option value="FR.DT.MQ" className="" data-select2-id="238">Martinique</option>
          <option value="FR.DT.RE" className="" data-select2-id="240">La Réunion</option>
          <option value="FR.DT.NC" className="" data-select2-id="239">Nouvelle-Calédonie</option>
          <option value="FR.DT.GF" className="" data-select2-id="241">Guyane</option>
          <option value="FR.DT.YT" className="" data-select2-id="242">Mayotte</option>
          <option value="FR.DT.PF" className="" data-select2-id="243">Polynésie française</option>
        </select>
      </label>

      <Spaces n={0}/>

      <label className={"text--minified3x"}>Rue<br/>
        <input type={"text"} className={"assistant--v2-input assistant--v2-input--center"} name={"location.street"}
               value={props.form.location.street} onChange={props.updateForm({maximumLength: 50})}/>
      </label>
      <Spaces n={0}/>


      <label className={"text--minified3x"}>Ville<br/>
        <input type={"text"} className={"assistant--v2-input assistant--v2-input--center"} name={"location.city"}
               value={props.form.location.city} onChange={props.updateForm({maximumLength: 50})}/>
      </label>
  
      <Spaces n={0}/>

      <label className={"text--minified3x"}>Code postal*<br/>
        <input type={"number"} className={"assistant--v2-input assistant--v2-input--center"} name={"location.postalCode"}
               value={props.form.location.postalCode} onChange={props.updateForm()}/>
      </label>
      
      <Spaces n={1}/>
      
      <button disabled={!isButtonActive} className={"btn-assistant-main"}
              onClick={async() => {
                props.wizard.goToStep("lastAssistantStep");
              }}>
        Accéder à la phase 2
      </button>
    
    </div>
  
  </section>);
}