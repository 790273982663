import React from "react";
import Spaces from "../../../../Spaces";

export default function(props: {
    item: any,
    index: any,
    update: any,
    setCurrentExperience: any,
    setEditKey: any
    //
    currentExperience: any;
    newMission: any;
    setNewMission: any;
    setNewKey: any;
    newKey: any;
    defaultExperience: any;
}) {

    const isMissionReady =
        props.currentExperience.position && props.currentExperience.company;

    return <>

        <div className={"profile2__newJob"}>

            <div className={"profile2__big-title text--center"}>
                Nouvelle experience
            </div>

            <article className={"profile2__newJob__line"}>
                <div className={"profile2__mini-title profile2__mini-title--box"}>Titre du poste:</div>
                <input className={"profile2__input--full"} value={props.currentExperience.position} onChange={(e) => {
                    const v = e.target.value;
                    props.setCurrentExperience((ps: any) => ({...ps, position: v}))
                }}/>
            </article>

            <article className={"profile2__newJob__line"}>
                <div className={"profile2__mini-title profile2__mini-title--box"}>Entreprise:</div>
                <input className={"profile2__input--full"} value={props.currentExperience.company} onChange={(e) => {
                    const v = e.target.value;
                    props.setCurrentExperience((ps: any) => ({...ps, company: v}))
                }}/>
            </article>

            <article className={"profile2__newJob__line"}>
                <span className={"profile2__mini-title"}>Description de l'entreprise:</span>
                <br/>
                <input className={"profile2__input--full"} value={props.currentExperience.companyDescription}
                       onChange={(e) => {
                           const v = e.target.value;
                           props.setCurrentExperience((ps: any) => ({...ps, companyDescription: v}))
                       }}/>
            </article>

            <article className={"profile2__newJob__line"}>
                <div className={'profile2__mini-title'}>
                    Missions du/de la candidat(e)
                </div>
                <ul>
                    {
                        props.currentExperience.candidateMissions.map((item: any, key: any) => <li>
                            {item}
                            <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                props.setCurrentExperience((ps: any) => {
                                    const length = ps.candidateMissions.length;
                                    if (key === 0) {
                                        return ps;
                                    }
                                    const expToCopy = ps.candidateMissions[key];
                                    const previousExpToCopy = ps.candidateMissions[key - 1];
                                    return {
                                        ...ps,
                                        candidateMissions: [
                                            ...([...ps.candidateMissions].slice(0, key - 1)),
                                            expToCopy,
                                            previousExpToCopy,
                                            ...([...ps.candidateMissions].slice(key + 1, length))
                                        ]
                                    }
                                });
                            }}>
                                up
                            </button>
                            <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                props.setCurrentExperience((ps: any) => {
                                    const length = ps.candidateMissions.length;
                                    if (key === length - 1) {
                                        return ps;
                                    }
                                    const expToCopy = ps.candidateMissions[key];
                                    const nextExpToCopy = ps.candidateMissions[key + 1];
                                    return {
                                        ...ps,
                                        candidateMissions: [
                                            ...([...ps.candidateMissions].slice(0, key)),
                                            nextExpToCopy,
                                            expToCopy,
                                            ...([...ps.candidateMissions].slice(key + 2, length))
                                        ]
                                    }
                                })
                            }}>
                                down
                            </button>
                            <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                props.setNewMission(item)
                                props.setCurrentExperience((ps: any) => ({
                                    ...ps,
                                    candidateMissions: ps.candidateMissions.filter((m: any) => m !== item)
                                }));
                            }}>
                                editer
                            </button>
                            <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                props.setCurrentExperience((ps: any) => ({
                                    ...ps,
                                    candidateMissions: ps.candidateMissions.filter((m: any) => m !== item)
                                }));
                            }}>
                                supprimer
                            </button>
                        </li>)
                    }
                </ul>
                <div>
                    <input className={"profile2__input--full"} value={props.newMission} onChange={(e) => {
                        props.setNewMission(e.target.value);
                    }}/>
                </div>
                <div>
                    <button disabled={!props.newMission} className={"profile2-btn-add profile2-btn-add--small"}
                            onClick={() => {
                                props.setCurrentExperience((ps: any) => ({
                                    ...ps,
                                    candidateMissions: [...ps.candidateMissions, props.newMission],
                                }));
                                props.setNewMission("")
                            }}>+ Ajouter la mission
                    </button>
                </div>
            </article>

            <article className={"profile2__newJob__line"}>
                <div className={'profile2__mini-title'}>
                    Elements cles & Resultats
                </div>
                <div>
                    {
                        props.currentExperience.keys.map((item: any, key: any) => <div>
                            - {item}
                            <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                props.setCurrentExperience((ps: any) => {
                                    const length = ps.keys.length;
                                    if (key === 0) {
                                        return ps;
                                    }
                                    const expToCopy = ps.keys[key];
                                    const previousExpToCopy = ps.keys[key - 1];
                                    return {
                                        ...ps,
                                        keys: [
                                            ...([...ps.keys].slice(0, key - 1)),
                                            expToCopy,
                                            previousExpToCopy,
                                            ...([...ps.keys].slice(key + 1, length))
                                        ]
                                    }
                                });
                            }}>
                                up
                            </button>
                            <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                props.setCurrentExperience((ps: any) => {
                                    const length = ps.keys.length;
                                    if (key === length - 1) {
                                        return ps;
                                    }
                                    const expToCopy = ps.keys[key];
                                    const nextExpToCopy = ps.keys[key + 1];
                                    return {
                                        ...ps,
                                        keys: [
                                            ...([...ps.keys].slice(0, key)),
                                            nextExpToCopy,
                                            expToCopy,
                                            ...([...ps.keys].slice(key + 2, length))
                                        ]
                                    }
                                });
                            }}>
                                down
                            </button>
                            <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                props.setNewKey(item)
                                props.setCurrentExperience((ps: any) => ({
                                    ...ps,
                                    keys: ps.keys.filter((m: any) => m !== item)
                                }));
                            }}>
                                editer
                            </button>
                            <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                                props.setCurrentExperience((ps: any) => ({
                                    ...ps,
                                    keys: ps.keys.filter((m: any) => m !== item)
                                }));
                            }}>
                                supprimer
                            </button>
                        </div>)
                    }
                </div>
                <div>
                    <input className={"profile2__input--full"} value={props.newKey} onChange={(e) => {
                        props.setNewKey(e.target.value)
                    }}/>
                </div>
                <div>
                    <button disabled={!props.newKey} className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                        props.setCurrentExperience((ps: any) => ({
                            ...ps,
                            keys: [...ps.keys, props.newKey],
                        }));
                        props.setNewKey("")
                    }}>+ Ajouter l'element cle
                    </button>
                </div>
            </article>

            {!props.currentExperience.position && <div className={'profile2-warning'}>
                Pas de titre de poste
            </div>}
            {!props.currentExperience.position && <div className={'profile2-warning'}>
                Pas d'entreprise
            </div>}
            {props.newMission && <div className={'profile2-warning'}>
                Une mission n'a pas ete ajoutee
            </div>}
            {props.newKey && <div className={'profile2-warning'}>
                Une cle/resultat n'a pas ete ajoutee
            </div>}

            <Spaces n={0}/>

            <div className={"text--center"}>
                <button className={"profile2-btn-add profile2-btn-add--small"} onClick={() => {
                    props.setNewMission("");
                    props.setNewKey("")
                    props.setCurrentExperience(props.defaultExperience);
                    props.setEditKey(-1)
                }}>
                    Annuler
                </button>
                <button disabled={!isMissionReady} className={"profile2-btn-add profile2-btn-add--small"}
                        onClick={() => {
                            props.update({
                                type: "SWITCH_EXPERIENCE",
                                value: {
                                    index: props.index,
                                    experience: {...props.currentExperience}
                                }
                            });
                            setTimeout(() => {
                                props.setNewMission("");
                                props.setNewKey("")
                                props.setCurrentExperience(props.defaultExperience);
                                props.setEditKey(-1);
                            }, 200);
                        }}>
                    Sauvegarder
                </button>
            </div>

        </div>

    </>
}