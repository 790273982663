import React from "react";
import EditorQuestion from "./EditorQuestion";
import Spaces from "../../../../components/sharedComponents/Spaces";
import EditorAddQuestion from "./EditorAddQuestion";

export default (props:any) => {

  return (<>
    <section className={"editor-v2__section"}>

      <article className={"editor-v2__section__title"}>Questions RH & Techniques</article>

      {/*{props.editorMode === "creation" && displayHelper && <div className={"editor-v2__question-guide"}>*/}
      {/*    <div className={"editor-v2__question-guide--button"}><CloseButton eventOnClick={() => {*/}
      {/*        setDisplayHelper(false);*/}
      {/*    }}/></div>*/}

      {/*    <h1 className={"editor-v2__section__question__titles"}>Ajoutez des questions pour trier les candidatures*/}
      {/*        plus*/}
      {/*        rapidement !</h1>*/}
      {/*    <p>*/}
      {/*        Pour gagner du temps, ajoutez toutes les questions que vous avez l'habitude de poser en entretien.*/}
      {/*        Pour chaque question à choix unique ou à choix multiple, vous pouvez ajouter des choix parmis*/}
      {/*        lesquels le*/}
      {/*        candidat pourra faire son choix.*/}
      {/*        Vous pouvez attirbuer un coefficient d'importance à chaque réponse que vous proposez au candidat.*/}
      {/*        Vous pouvez*/}
      {/*        également définir une question comme 'rédibitoire' (vous recevrez quand même la candidature).*/}
      {/*        L'algorithme vous poposera alors les meilleurs candidats pour le poste !*/}

      {/*    </p>*/}
      {/*</div>}*/}

      {props.form.form?.length === 0 &&
      <div className={"editor-v2__question__warning--empty"}>Attention, votre questionnaire est vide ! Les
          candidats
          pourront postuler mais vous perdez l'avantage du tri automatique.</div>}


      {props.form?.form?.map((item: any, key: number) => (<EditorQuestion {...props}
                                                                          item={item}
                                                                          index={key}
                                                                          key={key}
                                                                          id={"form"}
                                                                          drag={props.drag}
                                                                          drop={props.drop}
                                                                          toggleExtended={props.toggleExtendedFront}
                                                                          updateQuestion={props.updateQuestionFront}
                                                                          deleteQuestion={props.deleteQuestionFront}
                                                                          positionWanted={props.form.positionWanted}/>))}

      <div>

        <Spaces n={2}/>

        {props.form?.form?.length < 20 &&
        <EditorAddQuestion {...props} addQuestion={props.addQuestion} dropOnEmpty={props.dropOnEmptyFront} id={"form"}
                           openPreset={props.openPreset}/>}

      </div>

    </section>

    <Spaces n={0}/>
  </>)
}