
export {getCandidateListeningToMarket};

function getCandidateListeningToMarket(isListeningToMarket: boolean, dateOfListeningToMarket: any, isWorking: any) {
  
  if(!dateOfListeningToMarket) {
    return "";
  }
  
  if(!isListeningToMarket) {
    return "";
  }

  if(isWorking) {
    return "";
  }
  
  const dateOfListeningToMarketTime = Math.floor(new Date(dateOfListeningToMarket).getTime() / 1000);
  
  const now = Math.floor((new Date().getTime()) / 1000);
  
  const didAvailabilityDatePassed = now - dateOfListeningToMarketTime >= 0;

  const distance = Math.abs(now - dateOfListeningToMarketTime);

  if(!didAvailabilityDatePassed && distance > 2 * 24 * 60 * 60) return "";

  if(!didAvailabilityDatePassed && distance <= 2 * 24 * 60 * 60) return "pool-template__candidate__avatar__available";

  if(didAvailabilityDatePassed && distance < 30 * 24 * 60 * 60) return "pool-template__candidate__avatar__available";
  
  return "";
}