import {ClassType, FunctionComponent} from "react";
import Terms from "../pages/shared/informationPages/Terms";
import Policy from "../pages/shared/informationPages/Policy";
import AboutUs from "../pages/shared/informationPages/AboutUs";
import ContactUs from "../pages/shared/informationPages/ContactUs";
import HelpCenter from "../pages/shared/informationPages/HelpCenter";
import Login from "../pages/shared/Login";
import Invite from "../pages/shared/Invite";
import {BusinessPage} from "../pages/shared/PublicProfilesAndApply/BusinessPage";
import CandidateFeedbackOnPlatform from "../pages/shared/CandidateFeedbackOnPlatform";
import CandidateMatchs from "../pages/privateCandidate/CandidateMatchs";
import PageLink from "../pages/shared/PageLink";
import RecruiterNewMission from "../pages/privateRecruiter/RecruiterNewMissionV1";
import RecruiterCalendar from "../pages/privateRecruiter/RecruiterCalendar";
import RecruiterPools from "../pages/privateRecruiter/RecruiterPools";
import {RecruiterAmbassadors} from "../pages/privateRecruiter/RecruiterAmbassadors";
import RecruiterProfiles from "../pages/privateRecruiter/RecruiterProfiles";
import RecruiterParameters from "../pages/privateRecruiter/RecruiterParameters";
import Prices from "../pages/public/Prices";
import {RecruiterMissions} from "../pages/privateRecruiter/RecruiterMissions";
import {RecruiterLinks} from "../pages/privateRecruiter/RecruiterLinks";
import RecruiterPoolPageRouter from "../pages/privateRecruiter/componentsRecruiterPools/RecruiterPoolPageRouter";
import RecruiterMissionPageRouter from "../pages/privateRecruiter/componentsRecruiterMissions/componentsRMissionPageRouter/RecruiterMissionPageRouter";
import {RecruiterResearch} from "../pages/privateRecruiter/RecruiterResearch";
import {CandidateAction} from "../pages/shared/CandidateAction";
import {LinkedinApply} from "../pages/shared/LinkedinApply";
import {EvaluateCandidate} from "../pages/shared/EvaluateCandidate";
import FeedbacksFromCandidates from "../pages/shared/FeedbacksFromCandidates";
import {AllCandidatesEvaluations} from "../pages/shared/PublicProfilesAndApply/AllCandidatesEvaluations";
import {SharedCandidate} from "../pages/shared/SharedCandidate";
import AmbassadorSubscription from "../pages/shared/AmbassadorSubscription";
import LandingPageV3 from "../pages/public/LandingPageV3";
import AmbassadorDashboard from "../pages/public/AmbassadorDashboard";
import CandidateProfile from "../pages/privateCandidate/CandidateProfile";
import CandidateKanban from "../pages/privateCandidate/CandidateKanban";
import CandidateFavorites from "../pages/privateCandidate/CandidateFavorites";
import CandidateSubscribe from "../pages/shared/CandidateSubscribe";
import BusinessBlackBelt from "../components/BlackBeltComponents/BusinessBlackBelt";
import {RecruiterResearchAdvanced} from "../pages/privateRecruiter/RecruiterResearchAdvanced";
import newPassword from "../pages/shared/newPassword";
import {CandidateInterestedUpdate} from "../pages/shared/CandidateInterestedUpdate";
import RecruiterNewMissionV1AndV2 from "../pages/privateRecruiter/RecruiterNewMissionV1AndV2";
import RecruiterDashboard from "../pages/privateRecruiter/RecruiterDashboard";
import RecruiterControlParsing from "../pages/privateRecruiter/RecruiterControlParsing";
import RecruiterControlCandidateData from "../pages/privateRecruiter/RecruiterControlCandidateData";

export {routes};

export interface IRoute {
  path: string;
  page: string;
  component: FunctionComponent<any> | ClassType<any, any, any>;
  exact: boolean;
}

interface IRoutes {
  public: IRoute[];
  shared: IRoute[];
  candidates: IRoute[],
  recruiters: IRoute[],
}

const routes: IRoutes = {
  public: [],
  shared: [],
  candidates: [],
  recruiters: [],
};

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/* ===== SHARED PAGES =========================================== */

/* ----- Routes regarding casual front navigation --------------------------- */
routes.shared.push({exact: true, path: '/terms', page: 'Terms', component: Terms});
routes.shared.push({exact: true, path: '/policy', page: 'Policy', component: Policy});
routes.shared.push({exact: true, path: '/aboutus', page: 'AboutUs', component: AboutUs});
routes.shared.push({exact: true, path: '/contact', page: 'ContactUs', component: ContactUs});
routes.shared.push({exact: true, path: '/helpcenter', page: 'HelpCenter', component: HelpCenter});
routes.shared.push({exact: true, path: '/login/:token', page: 'Login', component: Login});
routes.shared.push({exact: true, path: '/new/password/:token', page: 'newPassword', component: newPassword});
routes.shared.push({exact: true, path: '/invite/:token', page: 'Invite', component: Invite});
routes.shared.push({exact: true, path: '/ambassadors/subscription/:token', page: 'AmbassadorSubscription', component: AmbassadorSubscription});

/* ----- Routes regarding business & personal pages access --------------------------- */
routes.shared.push({exact: false, path: '/corpo/:slugname/:slugnumber', page: 'BusinessPage', component: BusinessPage});
routes.shared.push({exact: false, path: '/apply/:slugname/:slugnumber/:rank', page: 'BusinessPage', component: BusinessPage});

/* ----- Routes regarding candidate actions --------------------------- */
routes.shared.push({exact: true, path: '/candidate/action/:action/:token', page: 'CandidateAction', component: CandidateAction});
routes.shared.push({exact: true, path: '/candidate/feedback/:token', page: 'CandidateFeedbackOnPlatform', component: CandidateFeedbackOnPlatform});
routes.shared.push({exact: true, path: '/oAuth/v2/linkedin/apply', page: 'LinkedinApply', component: LinkedinApply});
routes.shared.push({exact: true, path: '/recruiter/action/evaluate/:token', page: 'EvaluateCandidate', component: EvaluateCandidate});
routes.shared.push({exact: true, path: '/action/candidate/shared/:token', page: 'SharedCandidate', component: SharedCandidate});
routes.shared.push({exact: true, path: '/interested/:cid/:status', page: 'CandidateInterestedUpdate', component: CandidateInterestedUpdate});

routes.shared.push({exact: true, path: '/recruiter/action/allEvaluations/:token', page: 'AllCandidatesEvaluations', component: AllCandidatesEvaluations});
routes.shared.push({exact: true, path: '/feedback', page: 'FeedbacksFromCandidates', component: FeedbacksFromCandidates});

routes.shared.push({exact: true, path: '/ambassador/:token', page: 'AmbassadorDashboard', component: AmbassadorDashboard});

routes.shared.push({exact: true, path: '/subscribe/:kid', page: 'CandidateSubscribe', component: CandidateSubscribe});

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/* ===== PUBLIC SPACE PAGES ============================================== */

/* ----- Routes --------------------------- */
routes.public.push({exact: true, path: '/', page: 'LandingPage', component: LandingPageV3});
routes.public.push({exact: true, path: '/prices', page: 'Prices', component: Prices});

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/* ===== CANDIDATE SPACE PAGES =========================================== */
routes.public.push({exact: true, path: '/', page: 'CandidateMatchs', component: CandidateMatchs});

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/* ===== RECRUITER SPACE PAGES =========================================== */

/* ----- Main Routes (first-level) --------------------------- */
// routes.recruiters.push({exact: true, path: '/business/new/job', page: 'RecruiterNewMission', component: RecruiterNewMission});
routes.recruiters.push({exact: true, path: '/business/new/job', page: 'RecruiterNewMission', component: RecruiterNewMissionV1AndV2});
routes.recruiters.push({exact: true, path: '/business/jobs', page: 'RecruiterMissions', component: RecruiterMissions});
routes.recruiters.push({exact: true, path: '/business/calendar', page: 'RecruiterCalendar', component: RecruiterCalendar});
routes.recruiters.push({exact: true, path: '/business/communities', page: 'RecruiterPools', component: RecruiterPools});
routes.recruiters.push({exact: true, path: '/business/ambassadors', page: 'RecruiterAmbassadors', component: RecruiterAmbassadors});
routes.recruiters.push({exact: true, path: '/business/link', page: 'PageLink', component: RecruiterLinks});
routes.recruiters.push({exact: true, path: '/business/research', page: 'RecruiterResearch', component: RecruiterResearch});
routes.recruiters.push({exact: true, path: '/business/research/advanced', page: 'RecruiterResearchAdvanced', component: RecruiterResearchAdvanced});
routes.recruiters.push({exact: true, path: '/business/dashboard', page: 'RecruiterDashboard', component: RecruiterDashboard});
routes.recruiters.push({exact: true, path: '/business/controlParsing', page: 'RecruiterControlParsing', component: RecruiterControlParsing});
routes.recruiters.push({exact: true, path: '/business/controlCandidateData/:id', page: 'RecruiterControlParsing', component: RecruiterControlCandidateData});

/* ----- Parameters and options --------------------------- */
routes.recruiters.push({exact: false, path: '/business/profiles', page: 'RecruiterProfiles', component: RecruiterProfiles});
routes.recruiters.push({exact: false, path: '/businesses/blackBelt', page: 'BusinessBlackBelt', component: BusinessBlackBelt});
routes.recruiters.push({exact: false, path: '/business/parameters', page: 'RecruiterParameters', component: RecruiterParameters});

/* ----- Specific routes to a mission --------------------------- */
routes.recruiters.push({exact: false, path: '/business/mission/:missionid', page: 'RecruiterMissionPageRouter', component: RecruiterMissionPageRouter});

/* ----- Specific routes to a pool --------------------------- */
routes.recruiters.push({exact: false, path: '/business/community/:poolid', page: 'RecruiterPoolPageRouter', component: RecruiterPoolPageRouter});

/////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////
/* ===== CANDIDATE SPACE PAGES =========================================== */

/* ----- Main Routes (first-level) --------------------------- */
routes.candidates.push({exact: true, path: '/profile', page: 'CandidateProfile', component: CandidateProfile});
routes.candidates.push({exact: true, path: '/candidacies', page: 'CandidateKanban', component: CandidateKanban});
routes.candidates.push({exact: true, path: '/favorites', page: 'CandidateFavorites', component: CandidateFavorites});

