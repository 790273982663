import React from "react";

export default (props: {
    imgUrl: string;
    title: string;
    onClick: Function;
    alt?: string;
}) => {

    return(<>
        <button title={props.title} className={"mission--V2__buttons__parameters"} onClick={() => props.onClick()}>
            <img src={props.imgUrl} alt={props.alt ?? "img"}/>
        </button>
    </>);
}