import React, {useContext, useState} from "react";
import axios from "axios";
import {notification} from "../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../components/App/context/AppContext";
import Spaces from "../../../components/sharedComponents/Spaces";
import ECEGrade from "./componentsECEvaluation/ECEGrade";
import ECEMessage from "./componentsECEvaluation/ECEMessage";

export default (props: {
  setCandidatesToEvaluate: any;
  candidateToEvaluate: any;
  evaluationStep: number;
  setEvaluationStep: Function;
  setEvaluation: Function;
  evaluation: {
    appreciation: number;
    comment: string;
  };
  resetEvaluation: Function;
  setCandidateIndex: Function;
}) => {
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  const [sendStatus, setSendStatus]: any = useState("asleep");
  
  const sendEvaluation = async() => {
    try {
      setSendStatus("fetching");
      await axios.post(`${process.env.REACT_APP_API_URL}/v3/jobs/mat/cam/save/candidate/evaluation`, {
        evaluationId: props.candidateToEvaluate?.evaluationId,
        appreciation: props.evaluation.appreciation,
        comment: props.evaluation.comment,
      }, {withCredentials: true});
      
      setSendStatus("fetched");
      notification(appContextV2.dispatchPanels, "Succès de l'envoi du commentaire", "success-long");
      props.setCandidatesToEvaluate((ps: any) => ({
          ...ps,
          data: {
            ...ps.data,
            candidatesToEvaluate: ps.data.candidatesToEvaluate.filter((item: any) => item.evaluationId !== props.candidateToEvaluate.evaluationId)
          }
        }
      
      ));
      
      props.resetEvaluation();
      props.setCandidateIndex(0);
      window.scrollTo(0, 0);
      
    } catch(error) {
      setSendStatus("error");
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      throw error;
    }
  }
  
  return (<section className={"candidate-evaluate__container "}>
  
    <h1 className={"profile-edit__title"}>Évaluation du profil</h1>
    
    <Spaces n={1} />
    
    <ECESwitchEvaluationSteps {...props} sendStatus={sendStatus} sendEvaluation={sendEvaluation}/>
  
    <Spaces n={1} />
  
  </section>);
  
}

function ECESwitchEvaluationSteps(props: any) {
  
  switch(props.evaluationStep) {
    
    case 1:
      return (<ECEGrade {...props} sendStatus={props.sendStatus} sendEvaluation={props.sendEvaluation} />);
    
    case 2:
      return (<ECEMessage {...props} sendStatus={props.sendStatus} sendEvaluation={props.sendEvaluation} />);
    
    default:
      return (<><Spaces n={5}/>Cette page n'existe pas<Spaces n={3}/></>);
  }
}