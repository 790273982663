import React, {useContext} from "react";
import {ButtonBigPlusV2} from "../../../../../components/sharedComponents/Buttons/ButtonBigPlus";
import AppContext, {IAppV2Context} from "../../../../../components/App/context/AppContext";

export {LastElementInColumn};

const LastElementInColumn = (props: {
  positionKey: string,
  updateProfileFront: Function,
  dropOnLast: Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return(<div className={"profile-editor-V2__columns__element profile-editor-V2__columns__element--last-in-column"}
              onDragOver={(e) => {e.preventDefault();}}
              onDrop={() => {props.dropOnLast(props.positionKey)}}>
    <ButtonBigPlusV2 cb={()=>{
      appContextV2.dispatchPanels({type: "OPEN_WINDOW",
        value: {
          type: "addMediaToBusinessPage",
          data: {
            positionKey: props.positionKey,
            updateProfileFront: props.updateProfileFront,
          }
        }
      });
    }} title={`Ajoute un élément à la colonne`} />
  </div>);
}