import React, {useContext, useState} from "react";
import {isEmailValid} from "../../../../../utils/typeGuards";
import {isHighlighted} from "../componentsCWV2/dependencies/isHighlighted";
import {devLog} from "../../../../../utils/devLog/devLog";
import {notification} from "../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import axios from "axios";
import {shortenText} from "../../../../../utils/shortenText";

interface IEditableCanddiatePoolProfileComponent {
  candidateId: any,
  value: any,
  keywords: string,
  displayedKey: any,
  keyToUpdate: string,
  editable?: boolean,
  updateCandidateFrontInWindow: Function,
}

export default (props: IEditableCanddiatePoolProfileComponent) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context || {};
  
  const [isLoading, setIsLoading] = useState(false);
  const [editorMode, setEditorMode] = useState(false);
  const [value, setValue] = useState(props.value ?? "");
  
  const isTypeNumber: boolean = props.keyToUpdate === "salaryMin" || props.keyToUpdate === "salaryVariableMin" || props.keyToUpdate === "salaryMax" || props.keyToUpdate === "currentSalary" || props.keyToUpdate === "currentVariableSalary" || props.keyToUpdate === "yearsOfExperience"
      || props.keyToUpdate === "global"|| props.keyToUpdate === "culture" || props.keyToUpdate === "hardSkills" || props.keyToUpdate === "softSkills"|| props.keyToUpdate === "creativity"|| props.keyToUpdate === "autonomy" ;

  let isValueEmail: boolean = props.keyToUpdate === "email";
  let emailIsValid: boolean = isEmailValid(value);
  
  let isValueOk: boolean = false;
  if(!isValueEmail || (isValueEmail && (emailIsValid))) {
    isValueOk = true;
  }
  
  const updateValue = async() => {
    try {
      
      setIsLoading(true);
      notification(appContextV2.dispatchPanels, "Mise à jour en cours", "information");
  
      props.updateCandidateFrontInWindow({[props.keyToUpdate]: value});
  
      const fetched = await axios.put(`${process.env.REACT_APP_API_URL}/v3/communities/pool/cm/update/candidate/singleton`, {
        candidateId: props.candidateId,
        key: props.keyToUpdate,
        value: value,
      }, {
        timeout: 20_000,
        withCredentials: true
      });
      if(fetched.data.status !== "ok") {
        setIsLoading(false);
        setEditorMode(false);
        return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
      
      notification(appContextV2.dispatchPanels, "Mise à jour avec succès", "success");
  
      // Update kanban if needed
      setValue("");
      setIsLoading(false);
      setEditorMode(false);
      
    } catch(error) {
      devLog(error);
      setEditorMode(false)
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  return (<div className={'all-text--small'}>
    <div className={`text-left space-flex--left ${isHighlighted(props.keywords, props?.value) ? "profile--highlighted" : ""}`}>
      {
        props.editable && <button className={"btn-picto--minimalist2 btn-picto--minimalist2--small"} onClick={() => {
          setEditorMode((ps: boolean) => !ps);
        }}>
          <img src={"https://static.myshortlist.co/btn-pen.svg"}/>
        </button>
      }
  
      {props.displayedKey}: {shortenText((props.value), 50)} {props.keyToUpdate === "salaryMin" || props.keyToUpdate === "salaryVariableMin" || props.keyToUpdate === "salaryMax" || props.keyToUpdate === "currentSalary" || props.keyToUpdate === "currentVariableSalary" ? 'K' : ''}
      { props.keyToUpdate === "global"||props.keyToUpdate === "culture" || props.keyToUpdate === "hardSkills" || props.keyToUpdate === "softSkills"|| props.keyToUpdate === "creativity"|| props.keyToUpdate === "autonomy" ? "%" :""}
    </div>
    {
      editorMode &&
      <div>

        {!isTypeNumber && <input className={"input--left profile2-date"} type={"text"} value={value ? value : ""} onChange={(e) => {setValue(e.target?.value)}}/>}
        {isTypeNumber && <input className={"input--left profile2-date"} type={"number"} value={value ? value : ""} onChange={(e) => {setValue(e.target.value)}}/>}

        <button disabled={!isValueOk || isLoading} className={"btn--hollow modern-link--grey space-light"} onClick={() => updateValue()}>
          Valider
        </button>

        <button disabled={isLoading} className={"btn--hollow modern-link--grey space-light"} onClick={() => {
          setEditorMode(false);
        }}>
          Annuler
        </button>
      </div>
    }
  </div>);
  
}
