import AssistantLayout from "../../../../../layouts/AssistantLayout";
import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";


export default function(props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  const language: string = appContextV2.appSession.app.language;
  
  const assistantTitle1: string = ((language: string) => {
    switch(language) {
      case 'FR':
        return "Le compte n'a pas été trouvé";
      case 'EN':
      default:
        return "The user already exists";
    }
  })(language);
  
  const assistantComment1: any = <p className={"text--center"}>
    <button className={"btn-main"} disabled={false} onClick={(e) => {
      e.preventDefault();
      appContextV2.dispatchPanels({type: "OPEN_WINDOW", value: {
          type: "subscribe",
          data: {
            email: props.windowContext.email || "",
          }
        }});
    }}>
      Créer un compte
    </button>
  </p>;
  
  return(<>
    <AssistantLayout title={assistantTitle1} comment={assistantComment1} animated={true}>
      <div className={"text--center"}>
        Aucun compte n'est associé à cet email.<br/>Veux-tu en créer un ?
      </div>
    </AssistantLayout>
  </>);
}
