import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";

export {BusinessMember};

const BusinessMember = (props: {
  item: any,
  teamId: string,
  deleteMemberFront: Function,
  updateMemberFront: Function,
  drag: Function,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<div className={"teams__team__member"}
               onClick={() => {
                 appContextV2.dispatchPanels({
                   type: "OPEN_WINDOW", value: {
                     type: "manageMember",
                     data: {
                       memberId: props.item._id,
                       teamId: props.teamId,
                       email: props.item.email,
                       firstName: props.item.firstName,
                       lastName: props.item.lastName,
                       role: props.item.role,
                       userToUpdateId: props.item.userId,
                       deleteMemberFront: props.deleteMemberFront,
                       updateMemberFront: props.updateMemberFront,
                       password: props.item.password
                     }
                   }
                 });
               }}
               draggable={true}
               onDragStart={(e) => {
                 e.dataTransfer.setData('text/plain', '');
                 props.drag("member", props.item._id, props.teamId);
               }}
               onDragOver={(e) => {e.preventDefault();}}>
    
    <div className={"teams__team__member__avatar"}>
      {props.item?.avatar ? <img src={props.item.avatar}/> : <></>}
    </div>
    
    <div className={"teams__team__member__content"}>
      <div className={"teams__team__member__content__title"}>
        {props.item?.firstName} {props.item?.lastName}
      </div>
      <div className={"teams__team__member__content__email"}>
        {props?.item?.email}
      </div>
      <div className={"teams__team__member__content__role"}>
        {props.item?.role === "admin" && "Admin"}
        {props.item?.role === "manager" && "Manager"}
        {props.item?.role === "user" && "Utilisateur"}
      </div>
    </div>
  
  </div>)
}