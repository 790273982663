import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";

export default function(props: {
  currentStatus: string,
  reduce: Function,
  jobDesc: any,
  form: any,
  saveJobDesc: Function,
  areButtonsActive: boolean,
  publish: Function,
  duplicate: Function,
}) {
    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
    const isClientBlackBelt = appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" ;
    const jobDescExists=!!(props.jobDesc.file) || !!(props.form.documentUrl);
  
  return (<section className={"editor-v2__actions"}>
    <button title={"Réduis toutes les questions pour y voir plus clair"}
            className={"editor-v2__actions__btn"}
            onClick={() => props.reduce()}>
      Tout réduire
    </button>
    <button
        disabled={!props.areButtonsActive}
            title={"La possibilité de dupliquer un poste revient très vite"}
            className={"editor-v2__actions__btn"}
            onClick={async() => {
              if(!!props.jobDesc.file && !!props.jobDesc.file[0]) {
                let fileUrl = await props.saveJobDesc();
                await props.publish(fileUrl);
                return props.duplicate();
              } else {
                await props.publish(props.form.documentUrl ? props.form.documentUrl : '');
                return props.duplicate();
              }
            }}>
      Sauvegarder & Dupliquer
    </button>
    <button
        // disabled={!props.areButtonsActive || (!jobDescExists && isClientBlackBelt)}
        disabled={!props.areButtonsActive}
            title={"Publie le poste pour commencer à recevoir des candidatures"}
            className={"editor-v2__actions__btn"}
            onClick={async() => {
              if(!!props.jobDesc.file && !!props.jobDesc.file[0]) {
                let fileUrl = await props.saveJobDesc();
                props.publish(fileUrl);
              } else {
                props.publish(props.form.documentUrl ? props.form.documentUrl : '');
              }
            }}>
      Enregistrer
    </button>
  </section>);
}