import React from "react";

export {FileDrop};

const FileDrop = (props: {
  square: boolean,
  cb: any,
  name: string,
  
}) => {
  
  return(<>
  <div className={`file-drag-box ${!props.square && "file-drag-box--unsquared"}`}>
    <input type={"file"} name={props.name} className={`file-drag-input ${!props.square && "file-drag-input--unsquared"}`} id={"inputfile"} onChange={props.cb()}/>
    <label htmlFor={"inputfile"} className={"file-drag"} title={"ajoute une image"}>
      <img src={"https://static.myshortlist.co/clip.svg"}/>
    </label>
    <div>Ajouter un fichier</div>
  </div>
  </>);
}