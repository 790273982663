export default (previousState: any, action: {
    type: string;
    value: any;
}) => {

    switch(action.type) {

        case 'INITIALIZE':
            return [...action.value];

        case 'ADD':
            return [...previousState, action.value];

        case 'DELETE':
            return previousState.filter((item: any) => item._id !== action.value);
    }

}