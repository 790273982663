
/**
 * Test if the value is a phone number (10 numbers)
 * @param value
 */
export function isEmailValid(email: any): email is string {
  try {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
      email,
    );
  } catch (error) {
    return false;
  }
}

/**
 * Test if the value is a phone number (10 numbers)
 * @param value
 */
export function isPhoneNumberValid(phone: any): phone is string {
  try {
    return /^\d{10}$/.test(phone);
  } catch (error) {
    return false
  }
}