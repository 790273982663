import React, {useContext} from "react";
import {useForm} from "use-formidable";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import {notification} from "../../../../../utils/notification";
import axios from "axios";
import {devLog} from "../../../../../utils/devLog/devLog";
import Spaces from "../../../Spaces";

export {WAMTBPText};

const WAMTBPText = (props: any) => {
  
  
  const [form, updateForm, formidable] = useForm({title: "", content: ""});
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const addTextElement = async() => {
    try {
      
      notification(appContextV2.dispatchPanels, "Traitement et analyse de l'élément", "information");
      
      props.windowContext.wizard.goToStep("pending");
      
      const dataToSend2: any = {
        key: props.windowContext.form.key,
        type: props.windowContext.form.type,
        title: form.title,
        content: form.content,
      };
      
      const fetched = await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/bm/business/media/v2/add`, dataToSend2, {withCredentials: true});
      
      if(fetched.data.status !== "ok") {
        throw new Error('Une erreur est survenue');
      }
      
      // update front
      console.log("update front")
      const updateProfileFront = appContextV2.appPanels.window.windowOptions.data.updateProfileFront;
      updateProfileFront(props.windowContext.form.key, {
        type: props.windowContext.form.type,
        url: "",
        textContent: {
          title: form.title,
          content: form.content
        }
      });
      
      notification(appContextV2.dispatchPanels, "Champ texte ajouté avec succès", "success");
      
      appContextV2.dispatchPanels({type: "CLOSE_WINDOW", value: null});
      
    } catch(error) {
      
      devLog(error);
      props.windowContext.wizard.goToStep("error");
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error")
    }
  }
  
  return (<>
    
    <div className={"text--center"}>
      Ajoute du texte
      <br/>
      <span className={"text--grey"}>Il est possible de ne mettre qu'un titre ou qu'un contenu pour jouer avec la taille des éléments</span>
    </div>
    
    <Spaces n={1}/>
    
    <div className={"text--center"}>
      
      Titre
      <br/>
      <input type={"text"} name={"title"} value={form.title} onChange={updateForm({
        maximumLength: 100,
      })}/>
  
      <br/>
      <br/>
      Contenu
      <br/>
      <textarea className={""} name={"content"} value={form.content} onChange={updateForm({
        maximumLength: 300,
      })}>
      
      </textarea>
      
      <Spaces n={0}/>
      
      <button className={"btn-main"} disabled={!form.title && !form.content} onClick={()=>addTextElement()}>
        Ajouter
      </button>
    
    </div>
    
  </>);
};