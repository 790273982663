import React from "react";
import Spaces from "../../../components/sharedComponents/Spaces";
import {CASAvailable} from "./componentsCandidateActionStrategy/CASAvailable";
import {CASUnavailable} from "./componentsCandidateActionStrategy/CASUnavailable";
import {CASDeny} from "./componentsCandidateActionStrategy/CASDeny";
import {CASAuthorize} from "./componentsCandidateActionStrategy/CASAuthorize";

export {CandidateActionStrategy};

const CandidateActionStrategy = (props: any) => {

  switch (props.action) {

    case 'available':
      return (<CASAvailable {...props}/>);

    case 'unavailable':
      return (<CASUnavailable {...props}/>);

    // Authorize GDPR
    case 'authorize':
      return (<CASAuthorize {...props}/>);

    // Deny GDPR
    case 'deny':
      return (<CASDeny {...props}/>);

    default:
      return (<>
        <Spaces n={2}/>
        <div className={"text--magnified11x text--center"}>Le lien ne semble pas valide</div>
        <Spaces n={1}/>
      </>);
  }

}