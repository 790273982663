
interface IState {
    firstName:  string,
    lastName:  string,
    phone: string,
    phoneCountryCode:string,
    positionWanted: string,
    yearsOfExperience: number,
    contracts: string[],
    salaryMin:number,
    isWorking:boolean,
    isAvailable:boolean,
    dateOfAvailability:Date,
    location: {
        city: string,
        postalCode:number,
        state: string,
        country: string,
    },
}

export default (previousState: IState, action: { type: string, value: any }): any => {

    switch(action.type) {

        case 'INITIALIZE_DATA':
            return ({
                ...previousState,
                ...action.value
            });


        case 'UPDATE_CANDIDATE':
            return ({
                ...previousState,
                ...action.value,
            });

        case 'SWITCH_CONTRACT':
            if(previousState.contracts.includes(action.value)){
                return ({
                    ...previousState,
                    contracts: previousState.contracts?.filter((item: any) => item !== action.value)
                });
            }
           else {
                return ({
                    ...previousState,
                    contracts: [...previousState.contracts,
                        action.value
                    ]
                });
            }



        case "UPDATE_CANDIDATE_WORKING":
            return ({
                ...previousState,
                isWorking:  action.value,

            });

        case "UPDATE_CANDIDATE_AVAILABILITY":
            return ({
                ...previousState,
                isAvailable:  action.value,

            });

        case 'UPDATE_LOCATION':
            return ({
                ...previousState,
                location:{
                    ...previousState.location,
                    ...action.value,
                }

            });

        default:
            return previousState;
    }

}