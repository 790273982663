import React, {useContext, useEffect} from "react";
import AppContext, {IAppContext, IAppV2Context} from "../../../components/App/context/AppContext";
import SubPageLayout from "../../../layouts/SubPageLayout";
import Spaces from "../../../components/sharedComponents/Spaces";
import {useAssistant} from "../../../utils/useAssistant/useAssistant";
import axios from "axios";
import {devLog} from "../../../utils/devLog/devLog";
import {notification} from "../../../utils/notification";
import {RecruiterProfilesUserAccount} from "./componentsRPUser/RecruiterProfilesUserAccount";
import {RecruiterProfilesBusinessMedias} from "./componentsRPBusiness/RecruiterProfilesBusinessMedias";
import {RecruiterProfilesBusinessPublicHeader} from "./componentsRPBusiness/RecruiterProfilesBusinessPublicHeader";
import {RecruiterProfilesBusinessDescription} from "./componentsRPBusiness/RecruiterProfilesBusinessDescription";
import {RecruiterProfilesBusinessSocials} from "./componentsRPBusiness/RecruiterProfilesBusinessSocials";
import {RecruiterProfilesBusinessAccount} from "./componentsRPBusiness/RecruiterProfilesBusinessAccount";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [profile, statusGet, updateDataGet, assistantGet] = useAssistant();
  
  useEffect(() => {
    (async() => {
      try {
        assistantGet.fetching();
        const fetched = (await axios.get(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/b/get/my/business/profile`, {withCredentials: true})).data;
        if(fetched.status === "ok") {
          assistantGet.fetched();
          assistantGet.setForm(fetched.data);
        } else {
          assistantGet.error();
        }
      } catch (error) {
        devLog(error);
        assistantGet.error();
      }
    })()
  }, []);
  
  // devLog("get", profile);
  
  const updateSingleton = async(nameToUpdateFront: string, nameToUpdateBack: string, newValue: any) => {
    try {
      notification(appContextV2.dispatchPanels, "Envoi de la mise à jour...", "information");
      updateDataGet()({target: {type: "custom", name: nameToUpdateFront, value: newValue}});
      const fetched = (await axios.put(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/b/update/business`, {
        [nameToUpdateBack]: newValue,
      }, {withCredentials: true})).data;
      if(fetched.status === "ok") {
        notification(appContextV2.dispatchPanels, "Donnée mise à jour avec succès", "success");
      } else {
        notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
      }
    } catch (error) {
      devLog(error);
      notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
  }
  
  const updateProfileFront = (entry: string, value: any,) => {
    
    console.log("-----------", entry, value)
  
    switch(entry) {
      
      // Replace existing media
      case 'media1':
      case 'media2':
      case 'media3':
        return updateDataGet()({target: {type: "custom", name: entry, value: value}});
      
      // Appends a component at the end of the column
      case 'column1':
        return updateDataGet()({target: {type: "custom", name: "mediasCol1", value: [...(profile.mediasCol1 || []), value]}});
      case 'column2':
        return updateDataGet()({target: {type: "custom", name: "mediasCol2", value: [...(profile.mediasCol2 || []), value]}});
      case 'column3':
        return updateDataGet()({target: {type: "custom", name: "mediasCol3", value: [...(profile.mediasCol3 || []), value]}});
      
      // Replace existing column
      case 'mediasCol1':
        return updateDataGet()({target: {type: "custom", name: "mediasCol1", value: value}});
      case 'mediasCol2':
        return updateDataGet()({target: {type: "custom", name: "mediasCol2", value: value}});
      case 'mediasCol3':
        return updateDataGet()({target: {type: "custom", name: "mediasCol3", value: value}});
        
      case 'delete1':
        return updateDataGet()({target: {type: "custom", name: "mediasCol1", value: profile.mediasCol1?.filter((item: any) => (item._id !== value))}});
      case 'delete2':
        return updateDataGet()({target: {type: "custom", name: "mediasCol2", value: profile.mediasCol2?.filter((item: any) => (item._id !== value))}});
      case 'delete3':
        return updateDataGet()({target: {type: "custom", name: "mediasCol3", value: profile.mediasCol3?.filter((item: any) => (item._id !== value))}});
    }
  }
  
  return (<SubPageLayout status={statusGet} subPage={props.subPage}>
    
    {/*<Spaces n={2}/>*/}
    
    <RecruiterProfilesBusinessAccount assistantGet={assistantGet} profile={profile} updateSingleton={updateSingleton}/>
    
    <Spaces n={1}/>
    
    <RecruiterProfilesBusinessDescription assistantGet={assistantGet} profile={profile} updateSingleton={updateSingleton}/>
    
    <Spaces n={1}/>
    
    <RecruiterProfilesBusinessSocials assistantGet={assistantGet} profile={profile} updateSingleton={updateSingleton}/>
    
    <Spaces n={1}/>
    
    <RecruiterProfilesBusinessPublicHeader assistantGet={assistantGet} profile={profile} updateSingleton={updateSingleton}/>
    
    <Spaces n={1}/>
    
    <RecruiterProfilesBusinessMedias assistantGet={assistantGet} updateProfileFront={updateProfileFront} profile={profile} updateSingleton={updateSingleton}/>
  
  </SubPageLayout>);
}

