import React, {useContext} from "react";

/**
 *  @deprecated
 */

export {ButtonThreeDots};

const ButtonThreeDots = (props: {cb: Function, title: string}) => {
  
  return(<button className={"btn-plus--v2"} title={props.title} onClick={() => props.cb()}>
    <div className={"btn-plus--v2__plus"}>
      ···
    </div>
  </button>);
}

