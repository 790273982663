import React from "react";
import AppContext from "../../../../App/context/AppContext";
import {capitalizeMulti} from "../../../../../utils/converters";
import undefinedHelmetString from "../../../../../utils/undefinedHelmetString";
import Spaces from "../../../Spaces";

export default (props: any) => {
  
  const next = props.assistantContext.wizard.nextStep;
  
  return (<>
    
    <Spaces n={0} />
    
    <div className={"text--center text--magnified1x title--bold"}>
      Bienvenue sur myshortlist.co !
    </div>
    
    <Spaces n={2} />
    
    <p className={"text--center"}>
      <img className={"avatar-display--middle"} src={"https://static.myshortlist.co/avatar-aurelie.jpg"} alt={"Aurélie"} />
    </p>
    
    <p><br/></p>
    
    <p className={"text--center"}>
      Bonjour et bienvenue sur myshortlist.co. Laisse-moi t'accompagner dans la finalisation de ton inscription.
    </p>
    
    <p><br/></p>
    
    <p className={"text--center"}>
      <button className={"btn-main"} onClick={()=>{
        next();
      }}>
        Lancer l'assistant
      </button>
    </p>
  
  </>);

}