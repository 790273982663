import React, {useContext} from "react";
import {getDay} from "date-fns";

interface IDates {
  rankOfTheDay: number;
  number: number;
}

/**
 * Refactoring pour la V2:
 *    - année écoulée ou année en cours
 *    - passer dates avec les dates et pas avec les rankOfTheDay
 */
export default (props: { dates: IDates[], dateOfTheDay: Date }) => {
  
  const firstDay = getDay(new Date(`01/01/${new Date().getFullYear()}`));
  const daysOffset: any[] = [];
  for(let i = 0; i < firstDay; i++) {
    daysOffset[i] = 0;
  }
  
  // daysOfYear[1] = number of items on Jan 1st
  const daysOfYear: number[] = [];
  
  for(let i = 0; i < 365; i++) {
    daysOfYear[i] = 0;
  }
  
  for(let item of props.dates) {
    if(item && item.rankOfTheDay && item.number) {
      daysOfYear[item.rankOfTheDay] = item.number;
    }
  }
  
  const max = Math.max(...daysOfYear);
  const step1 = (max / 4);
  const step2 = (max / 4) * 2;
  const step3 = (max / 4) * 3;
  
  const getCellColor = (nItems: number | undefined) => {
    
    if(!nItems) return "missions-stats__calendar-item--color0"
    
    if(max === 0) return "missions-stats__calendar-item--color0";
    
    if(nItems >= step3) return "missions-stats__calendar-item--color4";
    
    if(nItems >= step2 && nItems < step3) return "missions-stats__calendar-item--color3";
    
    if(nItems >= step1 && nItems < step2) return "missions-stats__calendar-item--color2";
    
    if(nItems > 0 && nItems < step1) return "missions-stats__calendar-item--color1";
    
    return "missions-stats__calendar-item--color0";
  }
  
  return (<>
    <div className={"calendar-container"}>
      <div className={"flex-row missions-stats__calendar-months"}>
        <div className={"missions-stats__calendar-month"}>janv.</div>
        <div className={"missions-stats__calendar-month"}>févr.</div>
        <div className={"missions-stats__calendar-month"}>mars</div>
        <div className={"missions-stats__calendar-month"}>avr.</div>
        <div className={"missions-stats__calendar-month"}>mai</div>
        <div className={"missions-stats__calendar-month"}>juin</div>
        <div className={"missions-stats__calendar-month"}>juill.</div>
        <div className={"missions-stats__calendar-month"}>août</div>
        <div className={"missions-stats__calendar-month"}>sept.</div>
        <div className={"missions-stats__calendar-month"}>oct.</div>
        <div className={"missions-stats__calendar-month"}>nov.</div>
        <div className={"missions-stats__calendar-month"}>déc.</div>
      </div>
      
      <div className={"flex-row"}>
        <div className={"missions-stats__calendar-legend"}>
          <div className={"missions-stats__calendar-day"}>lun.</div>
          <div className={"missions-stats__calendar-day"}>mer.</div>
          <div className={"missions-stats__calendar-day"}>ven.</div>
        </div>
        
        
        <div className={"missions-stats__calendar"}>
          
          {daysOffset.map((item: any, key: any) => (<div className={`missions-stats__calendar-item missions-stats__calendar-item--invisible`}></div>))}
          
          {daysOfYear.map((item: any, key: any) => (
            <div title={`n = ${item ?? 0}`} className={`missions-stats__calendar-item ${getCellColor(item)}`}>
            </div>))}
        
        </div>
      
      </div>
    </div>
    
    <div className={"flex-row missions-stats__legend-colors"}>
      <div className={"missions-stats__calendar-day"}>moins</div>
      <div className={"missions-stats__calendar-item missions-stats__calendar-item--color0"}></div>
      <div className={"missions-stats__calendar-item missions-stats__calendar-item--color1"}></div>
      <div className={"missions-stats__calendar-item missions-stats__calendar-item--color2"}></div>
      <div className={"missions-stats__calendar-item missions-stats__calendar-item--color3"}></div>
      <div className={"missions-stats__calendar-item missions-stats__calendar-item--color4"}></div>
      <div className={"missions-stats__calendar-day"}>plus</div>
    </div>
  </>);
}