import React, {useState} from "react";
import Spaces from "../../../Spaces";

export {WKAAMail};

const WKAAMail = (props: {
  columnData: any,
  updateColumnData: Function,
  white: boolean,
}) => {
  
  const [mailEditorMode, setMailEditorMode] = useState(false);
  
  return(<>
  
    <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>
  
      <div className={"text--center profile-edit__title"}>
        Email
      </div>
      
      <Spaces n={0}/>
    
      <input disabled={!mailEditorMode} className={"input--v2 input--v2--full-width"}
             value={props.columnData.mailSubject}
             placeholder={"Sujet"}
             name={"mailSubject"}
             onChange={props.updateColumnData({maximumLength: 100})}/>
      <br/><br/>
      <textarea disabled={!mailEditorMode} className={"textarea--v2 textarea--v2--full-width"}
                value={props.columnData.mailContent}
                placeholder={"Contenu du mail (celui-ci commencera automatiquement par \"Bonjour \"nom_du_candidat\"\" et se terminera par le prénom de l'opérateur qui a effectué l'action)"}
                name={"mailContent"}
                onChange={props.updateColumnData({maximumLength: 500})}>
            </textarea>
      <div className={"text--center text--grey"}>
        {(props.columnData.mailContent || "").length} caractères sur 500
      </div>
    
      <Spaces n={0}/>
      <div className={"text--center"}>Le mail commencera toujours par "Bonjour nom_du_candidat" et se terminera par le prénom de la personne qui effectue l'action.</div>
  
      <Spaces n={0}/>
      
      {/*----------------------------------------- Modify default mail*/}
      <div className={"text--center"}>
        <button disabled={!!mailEditorMode} className={"btn--grey-art"} onClick={() => {
          setMailEditorMode(true);
        }}>
          Personnaliser l'email
        </button>
      </div>
      
    </article>
    
  </>);
}