import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "../../../components/App/context/AppContext";
import SubPageLayout from "../../../layouts/SubPageLayout";
import Spaces from "../../../components/sharedComponents/Spaces";
import RPSPlan from "./componentsRecruiterParametersSubscription/RPSPlan";
import RPSMarketPlace from "./componentsRecruiterParametersSubscription/RPSMarketPlace";

export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<SubPageLayout status={"ok"} subPage={props.subPage}>

    <RPSPlan/>

    <Spaces n={0}/>

    <RPSMarketPlace/>

  </SubPageLayout>);
}
