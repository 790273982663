import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../../../components/App/context/AppContext";
import Spaces from "../../../../../../components/sharedComponents/Spaces";

export default (props: any) => {
    
    useEffect(() => {
        (() => {
            props.wizard.nextStep();
        })();
    }, []);

    return(<><Spaces n={15}/></>);
}