import React from "react";
import {format} from "date-fns";

export {CW2WhatsappMessage};
const CW2WhatsappMessage = (props: any) => {

    console.log("item",props.item, "messages.length",props.messages.length,"index", props.index )

    return (<>


        {((props.index===0 && props.item.type === "candidate") || (props.item.type === "candidate" && ((props?.messages ?? [])[props?.index-1] ??{}).type === "black-belt")) && <div className="msg">
            <div className="bubble">
                <div className="txt">
                    <span className="name">{props.item.from}</span>
                    <span
                        className="timestamp">{props.item.dateOfCreation ? format(new Date(props.item.dateOfCreation), "dd.MM.yyyy H:mm") : ""}</span>
                    {props.item.status !== "deleted"  && <span className="message">{props.item.message}</span>}
                    {props.item.status === "deleted"  && <span className="message">Ce message a été supprimé</span>}
                </div>
                <div className="bubble-arrow"></div>
            </div>
        </div>
        }

        {props.index!==0 && ((props?.messages ?? [])[props?.index-1] ??{}).type === "candidate" && props.item.type==="candidate" &&
            <div className="msg">
            <div className="bubble follow">
                <div className="txt">
                    <span className="timestamp">{props.item.dateOfCreation ? format(new Date(props.item.dateOfCreation), "dd.MM.yyyy H:mm") : ""}</span>
                    {props.item.status !== "deleted"  && <span className="message follow">{props.item.message}</span>}
                    {props.item.status === "deleted"  && <span className="message follow">Ce message a été supprimé</span>}
                </div>
            </div>
        </div>}

        {((props.index===0 && props.item.type === "black-belt") || (props.item.type === "black-belt" && ((props?.messages ?? [])[props?.index-1] ??{}).type === "candidate")) && <div className="msg">
            <div className="bubble alt">
                <div className="txt">
                    <span className="name alt">{props.item.from}</span>
                    <span className="timestamp">{props.item.dateOfCreation ? format(new Date(props.item.dateOfCreation), "dd.MM.yyyy H:mm") : ""}</span>
                    <p className="message">{props.item.message}</p>
                    <span className="status">
                        {props.item.status.toLowerCase() === "sent" && <img src={"https://static.myshortlist.co/sent.svg"}/>}
                        {props.item.status.toLowerCase() === "delivered" && <img src={"https://static.myshortlist.co/delivered.svg"}/>}
                        {props.item.status.toLowerCase() === "read" && <img src={"https://static.myshortlist.co/read.svg"}/>}
                        {props.item.status.toLowerCase() === "failed" && <img className={"img--small"} src={"https://static.myshortlist.co/failed.svg"}/>}
                    </span>

                </div>
                <div className="bubble-arrow alt"></div>
            </div>
        </div>
        }


         {props.index!==0 && ((props?.messages ?? [])[props?.index-1] ??{}).type === "black-belt" && props.item.type==="black-belt" &&
             <div className="msg">
                 <div className="bubble altfollow">
                     <div className="txt">
                         <span className="timestamp">{props.item.dateOfCreation ? format(new Date(props.item.dateOfCreation), "dd.MM.yyyy H:mm") : ""}</span>
                         <p className="message follow">
                             {props.item.message}
                         </p>
                         <span className="status">
                             {props.item.status.toLowerCase() === "sent" && <img src={"https://static.myshortlist.co/sent.svg"}/>}
                             {props.item.status.toLowerCase() === "delivered" && <img src={"https://static.myshortlist.co/delivered.svg"}/>}
                             {props.item.status.toLowerCase() === "read" && <img src={"https://static.myshortlist.co/read.svg"}/>}
                             {props.item.status.toLowerCase() === "failed" && <img className={"img--small"} src={"https://static.myshortlist.co/failed.svg"}/>}
                         </span>
                     </div>
                 </div>
             </div>
         }


    </>);
}