import React, {useContext, useEffect, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {notification} from "../../../../utils/notification";

export default (props: any) => {
    const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
    //const url: string = `https://app.black-belt.io/apply/${appContextV2.appSession.sessionBusiness.profileLink.name}/${appContextV2.appSession.sessionBusiness.profileLink.number}/${props.missionTokens.rank}/apply`;
    const url: string =`https://black-belt.typeform.com/to/tCq2McRK#poste=${props.mission?.positionWanted?.split(" ").join("+")}&missionid=${props.missionId}`
    return(<>

        <article className={`white-container ${!props.white ? "white-container--grey" : ""}`}>

            <div>
                <span className={"text--bold text--magnified11x"}>Copiez le lien.</span> Les candidats pourront postuler depuis un smartphone, une tablette ou un
                ordinateur en suivant ce lien.
            </div>

            <Spaces n={1}/>

            <div className={"text--center"}>
                {appContextV2.appSession.sessionBusiness.businessName !== "Black-belt.io" &&
                    <div className={`link-box2 ${!props.white ? "link-box2--white" : ""}`}>
                        {`https://app.black-belt.io/apply/${appContextV2.appSession.sessionBusiness.profileLink.name}/${appContextV2.appSession.sessionBusiness.profileLink.number}/${props.missionTokens.rank}/apply`}
                        <button className={"btn-secondary btn-secondary--small btn-secondary--space-left"} onClick={() => {
                            notification(appContextV2.dispatchPanels, "Lien copié", "success");
                            navigator.clipboard.writeText(`https://app.black-belt.io/apply/${appContextV2.appSession.sessionBusiness.profileLink.name}/${appContextV2.appSession.sessionBusiness.profileLink.number}/${props.missionTokens.rank}/apply`);
                        }}>
                            Copier le lien
                        </button>
                    </div>
                }
                {
                    appContextV2.appSession.sessionBusiness.businessName === "Black-belt.io" &&
                    <div className={`link-box2 ${!props.white ? "link-box2--white" : ""}`}>
                        {`https://black-belt.typeform.com/to/tCq2McRK#poste=${props.mission?.positionWanted?.split(" ").join("+")}&missionid=${props.missionId}`}
                        <button className={"btn-secondary btn-secondary--small btn-secondary--space-left"} onClick={() => {
                            notification(appContextV2.dispatchPanels, "Lien copié", "success");
                            navigator.clipboard.writeText(`https://black-belt.typeform.com/to/tCq2McRK#poste=${props.mission?.positionWanted?.split(" ").join("+")}&missionid=${props.missionId}`);
                        }}>
                            Copier le lien
                        </button>
                    </div>
                }


            </div>

            {/*<Spaces n={1}/>*/}
            
            {/*<div className={"text--center"}>*/}
            {/*    <button className={"btn-secondary"} onClick={() => {*/}
            {/*        notification(appContextV2.dispatchPanels, "Lien copié", "success");*/}
            {/*        navigator.clipboard.writeText(url);*/}
            {/*    }}>*/}
            {/*        Copier le lien*/}
            {/*    </button>*/}
            {/*</div>*/}

        </article>

    </>);
}