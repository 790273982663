import React, {useContext} from "react";

/**
 *  @deprecated
 */
export default function(props: {onClick: Function, squares?: boolean, disabled?: boolean}) {return(<>Deprecated</>)}

export {ButtonBigPlusV2};

const ButtonBigPlusV2 = (props: {cb: Function, title: string, disabled?: boolean}) => {
  
  return(<button className={"btn-plus--v2"} disabled={!!props.disabled} title={props.title} onClick={() => props.cb()}>
    <div className={"btn-plus--v2__plus"}>
      +
    </div>
  </button>);
}

