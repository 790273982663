import React from "react";

export default (props: {
  filters: any,
  
}) => {
  
  return (<div className={"pool-template__research"}>
    
    <input className={"pool-navbar-top__main"}
           value={props.filters.filterByWords}
           placeholder={"Recherche unique (nom, prénom, mail, poste, mobilité, compétences, langues ...)"}
           onChange={(e) => {
             props.filters.setFilterByWords(e.target.value);
           }}
    />
    
    <div className={"pool-navbar-top__bottom"}>
      
      <div className={"pool-navbar-top__bloc"}>
      <span>
        Date de disponibilité
      </span>
        <input type={"date"}
               value={props.filters.filterByAvailableFrom}
               className={"pool-navbar-top__date"}
               onChange={(e) => {
                 props.filters.setFilterByAvailability(e.target.value);
               }}
        />
      </div>
      
      <div className={"pool-navbar-top__bloc"}>
      <span>
        Dernier échange
      </span>
        <div className={"pool-navbar-top__white-section"}>
          <button className={`pool_last-exchange-buttons pool_last-exchange-buttons--old5 ${props.filters.filterByLastExchange === "12" && "pool_last-exchange-buttons pool_last-exchange-buttons--selected"}`}
                  onClick={() => {
                    if(props.filters.filterByLastExchange === "12") {
                      props.filters.setFilterByLastExchange("");
                    } else {
                      props.filters.setFilterByLastExchange("12")
                    }
                  }}
          >
            1 an et +
          </button>
          <button className={`pool_last-exchange-buttons pool_last-exchange-buttons--old4 ${props.filters.filterByLastExchange === "6" && "pool_last-exchange-buttons pool_last-exchange-buttons--selected"}`}
                  onClick={() => {
                    if(props.filters.filterByLastExchange === "6") {
                      props.filters.setFilterByLastExchange("");
                    } else {
                      props.filters.setFilterByLastExchange("6")
                    }
                  }}
          >
            6 mois à 1 an
          </button>
          <button className={`pool_last-exchange-buttons pool_last-exchange-buttons--old3 ${props.filters.filterByLastExchange === "3" && "pool_last-exchange-buttons pool_last-exchange-buttons--selected"}`}
                  onClick={() => {
                    if(props.filters.filterByLastExchange === "3") {
                      props.filters.setFilterByLastExchange("");
                    } else {
                      props.filters.setFilterByLastExchange("3")
                    }
                  }}
          >
            3 à 6 mois
          </button>
          <button className={`pool_last-exchange-buttons pool_last-exchange-buttons--old2 ${props.filters.filterByLastExchange === "1" && "pool_last-exchange-buttons pool_last-exchange-buttons--selected"}`}
                  onClick={() => {
                    if(props.filters.filterByLastExchange === "1") {
                      props.filters.setFilterByLastExchange("");
                    } else {
                      props.filters.setFilterByLastExchange("1")
                    }
                  }}
          >
            1 à 3 mois
          </button>
          <button className={`pool_last-exchange-buttons pool_last-exchange-buttons--old1 ${props.filters.filterByLastExchange === "0" && "pool_last-exchange-buttons pool_last-exchange-buttons--selected"}`}
                  onClick={() => {
                    if(props.filters.filterByLastExchange === "0") {
                      props.filters.setFilterByLastExchange("");
                    } else {
                      props.filters.setFilterByLastExchange("0")
                    }
                  }}
          >
            0 à 1 mois
          </button>
        </div>
      </div>
      
      <div className={"pool-navbar-top__bloc"}>
      <span>
        Archives
      </span>
        <div className={"pool-navbar-top__white-section"}>
          <div className={"space-flex--left"}>
            <label className={"switch-V2"}>
              <input type={"checkbox"} checked={props.filters.showRemovedCandidates} className={"checkbox-on-off"}
                     onChange={() => {
                       props.filters.setRemovedCandidates(!props.filters.showRemovedCandidates)
                     }}/>
              <span className="slider"> </span>
            </label>
          </div>
        </div>
      </div>
      
      <div className={"pool-navbar-top__bloc"} onClick={() => {props.filters.resetFilters()}}>
      <span>
        Remettre à zéro
      </span>
        <button className={"mission--V2__buttons__main"}>
          Réinitialiser
        </button>
      </div>
    
    
    </div>
  
  
  </div>)
}