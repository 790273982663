import React from "react";
import PageLayout from "../../../layouts/PageLayout";
import Spaces from "../../../components/sharedComponents/Spaces";

export {InviteExpired};

const InviteExpired = (props: {loadingStatus: 'pending' | 'error' | 'forbidden' | 'ok' | 'asleep' | 'fetching' | 'fetched', page: string}) => {
  
  return (<PageLayout page={props.page} status={props.loadingStatus}>
    <Spaces n={15}/>
    <div className={"text--center"}>
      Le token a expiré.
      <br/><br/>
      Demandez à votre équipe qu'elle vous renvoie une invitation.
    </div>
    <Spaces n={8}/>
  </PageLayout>);
}