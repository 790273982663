import React from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../components/sharedComponents/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const isSalaryMinSet: boolean = !!props.form.salaryMin;
  const isSalaryMaxSet: boolean = !!props.form.salaryMax;
  const isSalaryConsistent: boolean = parseInt(props.form.salaryMax) >= parseInt(props.form.salaryMin);
  
  const isButtonActive: boolean = isSalaryMinSet && isSalaryMaxSet && isSalaryConsistent;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      {props.form.salaryBy !== "budget" && <>Quelle est la rémunération {props.form.salaryBy === "hourly" && "horaire"} {props.form.salaryBy === "monthly" && "mensuelle"} {props.form.salaryBy === "annually" && "annuelle"} fixe proposée (en euros) ?</>}
      {props.form.salaryBy === "budget" && <>Quel est le budget alloué (en euros) ?</>}
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container--no-flex text--center assistant--v2--appear"}>
      
      <div className={"text--minified2x"}>Tranche minimum:</div>
      <input type={"text"} className={"assistant--v2-input assistant--v2-input--center"} name={"salaryMin"}
             value={props.form.salaryMin ? props.formidable.displayerSpacesToThousands(props.form.salaryMin) : ""} onChange={props.updateForm({toKeepNumbers: true, maximumLength: 8})}/>
      
      <Spaces n={0}/>
  
      <div className={"text--minified2x"}>Tranche maximum:</div>
      <input type={"text"} className={"assistant--v2-input assistant--v2-input--center"} name={"salaryMax"}
             value={props.form.salaryMax ? props.formidable.displayerSpacesToThousands(props.form.salaryMax) : ""} onChange={props.updateForm({toKeepNumbers: true, maximumLength: 8})}/>
      
      <Spaces n={1}/>
      
      <button disabled={!isButtonActive} className={"btn-assistant-main"}
              onClick={async() => {
                
                props.wizard.goToStep("advantages");
              }}>
        Suivant
      </button>
    
    </div>
  
  </section>);
}