
export {getNewMissionTitle};

const getNewMissionTitle =  (step: any) => {
  
  switch(step) {
    
    case 'assistantStart':
    case 'positionWanted':
    case 'positionWantedCustom':
    case 'contract':
    case 'contractCustom':
    case 'fullTime':
    case 'experience':
    case 'skills':
    case 'asSoonAsPossible':
    case 'dateCustom':
    case 'duration':
    case 'durationCustom':
    case 'renewal':
    case 'salaryBy':
    case 'salary':
    case 'salaryIn':
    case 'salaryVar':
    case 'advantages':
    case 'withCv':
    case 'cVMandatory':
    case 'remote':
    case 'roaming':
    case 'roamingPlace':
    case 'useSavedLocation':
    case 'location':
    case 'lastAssistantStep':
      
      return "Nouveau poste";

    case 'editor':
      return "Édition du formulaire";
      
    case 'success':
      return "Succès";
      
    case 'error':
      return "Erreur";
      
    default:
      return "Nouveau poste";
      
  }
};