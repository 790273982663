export default(previousState: any, action: {type: string, value: any}) => {

  switch(action.type) {

    case 'FIRST_UPDATE':

      const appNames = action.value?.map((item: any) => item.name) ?? [];

      return previousState.map((app: any) => {

        if(!appNames.includes(app.name)) return app;

        const appFromServer = action.value?.find((item: any) => item.name === app.name) ?? "";

        return {
          ...app ,
          isActive: !!appFromServer,
          credit: appFromServer.credit ?? 0,
        }
      });
  }
  
}