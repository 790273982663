import React, {useContext} from "react";
import AppContext, {IAppV2Context} from "./context/AppContext";
import Spaces from "../sharedComponents/Spaces";
import AppSwitch from "../sharedComponents/AppSwitch";

export interface IAppContent {
  name: string,
  iconUrl: string,
  title: string,
  description: string,
  credit: number,
  isActive: boolean,
  isAvailable: boolean,
  displayCredit: boolean,
  isClickable:boolean
}

export {SingleApp};

const SingleApp = (props: {
  app: IAppContent;
  dispatcher: Function;
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (
    <article className={`section-apps__app ${props.app.isAvailable ? "" : "section-apps__app--unavailable" }`  }
             onClick={() => {
               if(props.app.isAvailable && props.app.isClickable) {
                 appContextV2.dispatchPanels({
                   type: "OPEN_WINDOW",
                   value: {
                     type: "app",
                     data: {
                       app: props.app,
                       dispatcher: props.dispatcher
                     }
                   }
                 });
               }
             }}>
      
      <Spaces n={0}/>
      
      <img src={props.app.iconUrl}/>
      
      <div className={"section-apps__app__slider--top-left"}>
        <AppSwitch active={props.app.isActive && props.app.isAvailable} size={'small'}/>
      </div>
      
      <h1> {props.app.title}</h1>
      
      <desc>
        {props.app.description}
      </desc>
      
      {props.app.displayCredit && <span className={'credit'}>Crédits : {props.app.credit} € </span>}
    </article>
  )
}


