import React, {useContext, useEffect, useState} from "react";
import Spaces from "../../../../components/sharedComponents/Spaces";
import axios from "axios";
import {notification} from "../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import BPAV3SuccessResult from "./componentsBusinessPageApplyV3/componentsBPAV3Success/BPAV3SuccessResult";
import LoadingStyle2 from "../../../../components/sharedComponents/Loading/LoadingStyle2";
const qs = require("query-string");

const BPAV3S = (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [sending, setSending] = useState(false);
  const [subscribed, seSubscribed] = useState(false);
  const [displayPage, setDisplayPage] = useState(false);
  
  const parsedQuery = qs.parse(props.location.search) || {};
  
  const email = parsedQuery.email?.replace('%40', '@') ?? "";
  const hider = parsedQuery.hider === "true";
  const mark = parseInt(parsedQuery.mark ?? "0");
  const bn = parsedQuery.bn ?? "";
  const hides = parsedQuery.hides === "true";
  
  useEffect(() => {
    setTimeout(() => setDisplayPage(true), 200);
  }, []);
  
  const subscribe = async() => {
    setSending(true);
    const fetched: any = await axios.post(`${process.env.REACT_APP_API_URL}/v3/accounts/ent/u/new/user/applying`, {
      email: email,
      areTermsAccepted: true,
      isPolicyAccepted: true,
    }, {
      withCredentials: true,
      timeout: 70000,
    });
    
    if(fetched.data.status !== "ok") {
      setSending(false);
      return notification(appContextV2.dispatchPanels, "Une erreur est survenue", "error");
    }
    
    setSending(false);
    seSubscribed(true);
    notification(appContextV2.dispatchPanels, "Inscription réussie", "success");
  }
  
  if(!displayPage) return (<><Spaces n={15}/></>);
  
  return (<>
  
    <Spaces n={2}/>
  
    <div className={"apply-V2__title text--new-blue"}>
      Candidature enregistrée avec succès !
    </div>
  
    <Spaces n={1}/>
  
    {!hider && <><BPAV3SuccessResult globalMark={mark}/><Spaces n={1}/></>}
  
    {
      !hides && !subscribed && <>
        <section className={"container text--center"}>
  
          <article className={"white-container white-container--grey"}>
            Vous pouvez maintenant fermer cette fenêtre.
            <Spaces n={0}/>
            {
              bn !== "Omega-intérim IDF" && <>Pour recevoir des offres similaires, n'hésitez pas à vous inscrire sur la plateforme.</>
            }
          </article>
        </section>
        <Spaces n={1}/>
        <div className={"text--center"}>
          {
            bn !== "Omega-intérim IDF" && <button className={"btn-secondary"} onClick={() => subscribe()}>
              Je m'inscris pour recevoir d'autres offres
            </button>
          }
        </div>
      </>
    }
  
    {
      !hides && subscribed && <>
        <section className={"container text--center"}>

          <article className={"white-container white-container--grey"}>
            Vous êtes bien inscrit sur la plateforme. Vous pouvez maintenant fermer cette fenêtre.
          </article>
        </section>
      </>
    }
  
    {
      hides && <div className={"text--center"}>
        Merci d'avoir posté votre candidature, vous pouvez maintenant fermer cette fenêtre.
      </div>
    }
  
    <div className={"text--center"}>
      {sending && <>
        <br/>
        <LoadingStyle2/>
      </>}
    </div>
  </>);
}

export default (BPAV3S);