import React, {useContext, useState} from "react";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";
import Spaces from "../../../Spaces";
import {format} from "date-fns";

export default (props: {
  fieldToUpdate: string,
  modifier: Function,
  mission: any,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [salaryMin, setSalaryMin] = useState(props.mission?.salaryMin);
  const [salaryMax, setSalaryMax] = useState(props.mission?.salaryMax);
  const [salaryBy, setSalaryBy] = useState(props.mission?.salaryBy);
  const [salaryCurrency, setSalaryCurrency] = useState(props.mission?.salaryCurrency);
  
  const [hideSalary, setHideSalary] = useState(!props.mission?.salaryMin && !props.mission?.salaryMax);
  
  return (<>
    
    <div className={"white-container white-container--grey"}>
      
      <input type={"checkbox"} checked={hideSalary} onClick={() => {
        // props.modifier("permanent", !valuePermanent);
        setHideSalary((ps: boolean) => !ps);
        props.modifier("salaryMin", 0);
        props.modifier("salaryMax", 0);
        props.modifier("salaryBy", "");
        setSalaryMin(0);
        setSalaryMax(0);
        setSalaryBy("");
      }}/> Ne pas renseigner le salaire
      
      {
        !hideSalary && <>
          <Spaces n={0}/>
          <label>
            <span className={"text--minified2x"}>Récurrence du salaire:</span><br/>
            <div className={"text--center"}>
              <select className={"input--v2"} value={salaryBy} onChange={(e) => {
                setSalaryBy(e.target.value);
                props.modifier("salaryBy", e.target.value);
              }}>
                <option disabled={true} value={""}>Récurrence du salaire</option>
                <option value={"hourly"}>Horaire</option>
                <option value={"monthly"}>Mensuel</option>
                <option value={"annually"}>Annuel</option>
                <option value={"budget"}>Budget</option>
              </select>
            </div>
          </label>
        </>
      }
      
      {
        (!hideSalary && !!salaryBy) && <>
          <Spaces n={0}/>
          <label>
            <span className={"text--minified2x"}>Salaire minimum (euros):</span><br/>
            <input className={"input--v2"} type={"number"} value={salaryMin} onChange={(e) => {
              setSalaryMin(e.target.value);
              props.modifier("salaryMin", e.target.value);
            }}/>
          </label>
        </>
      }
      
      {
        (!hideSalary && !!salaryBy) && <>
          <Spaces n={0}/>
          <label>
            <span className={"text--minified2x"}>Salaire maximum (euros):</span><br/>
            <input className={"input--v2"} type={"number"} value={salaryMax} onChange={(e) => {
              setSalaryMax(e.target.value);
              props.modifier("salaryMax", e.target.value);
            }}/>
          </label>
        </>
      }
    
    </div>
  
  </>);
  
}