import React, {useContext, useEffect} from "react";
import AppContext, {IAppV2Context} from "../../../components/App/context/AppContext";
import { Switch, Route } from "react-router-dom";
import FourHundredFour from "../../errors/FourHundredFour";
import RecruiterPoolPeople from "./componentsRecruiterPoolPageRouter/RecruiterPoolPeople";
import PageLayout from "../../../layouts/PageLayout";

/**
 * --------------------------------------
 * Displays all pages for a specific pool
 * Secondary-level page
 * --------------------------------------
 */
export default (props: any) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  useEffect(() => {
    appContextV2.dispatchNavigation({type: "SET_POOLID", value: props.match.params.poolid});
  }, []);
  
  return(<PageLayout small={true} grey={false} page={props.page} title={""} fullWidth={true} status={"ok"}>
    
    <Switch>
      
      <Route exact path="/business/community/:poolid" render={(componentProps) => <RecruiterPoolPeople {...componentProps} subPage={"RecruiterPoolPeople"} />}/>
      
      <Route exact path="/business/community/:poolid/people" render={(componentProps) => <RecruiterPoolPeople {...componentProps} subPage={"RecruiterPoolPeople"} />}/>
      
      {/* 404 ------------------------------------------------- */}
      <Route render={(componentProps) => <FourHundredFour {...componentProps} page="FourHundredFour" fullPage={false} setPage={false}/>}/>
    
    </Switch>
  
  </PageLayout>);
}