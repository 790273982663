
import React, {useContext} from "react";
import AppContext, {IAppContext, IAppV2Context} from "../../../../components/App/context/AppContext";

export default function RecruiterResearchEditorNewFormItemChoice (props: any) {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return(<>
      <section className={"editor-new-item__choices"}>
        
        <button className={"editor-new-item__choices__choice"} title={"Le candidat pourra répondre librement à la question posée"} onClick={()=>{
          props.addQuestion(props.formId, {
            type: "freeText",
            question: "",
            mandatory: "true",
            extended: true,
          });
          appContextV2.dispatchPanels({type: 'CLOSE_EDITOR_PANEL', value: null})}}>
          <span>Réponses en texte libre</span>
          <img src={"https://static.myshortlist.co/btn-text.svg"} alt={"text"} />
        </button>
        
        <button className={"editor-new-item__choices__choice"} title={"Le candidat devra choisir une unique réponse dans la liste"} onClick={()=>{
          props.addQuestion(props.formId, {
            type: "singleChoice",
            question: "",
            blocking: "false",
            mandatory: "true",
            importance: 3,
            answers: [
              {
                answer: "",
                answerData: "0",
              }
            ],
            extended: true,
          });
          appContextV2.dispatchPanels({type: 'CLOSE_EDITOR_PANEL', value: null})}}>
          <span>Question à choix unique</span>
          <img src={"https://static.myshortlist.co/btn-radio.svg"} alt={"single choice"} />
        </button>
        
        <button className={"editor-new-item__choices__choice"} title={"Le candidat pourra choisir plusieurs réponses dans la liste"} onClick={()=>{
          // props.setStep("newMultipleChoiceItem")
          props.addQuestion(props.formId, {
            type: "multipleChoice",
            question: "",
            blocking: "false",
            mandatory: "true",
            importance: 3,
            answers: [
              {
                answer: "",
                answerData: "0",
              }
            ],
            extended: true,
          });
          appContextV2.dispatchPanels({type: 'CLOSE_EDITOR_PANEL', value: null})}}>
          <span>Question à choix multiples</span>
          <img src={"https://static.myshortlist.co/btn-checkbox.svg"} alt={"multiple choice"} />
        </button>
        
        <button className={"editor-new-item__choices__choice"} onClick={()=>{
          appContextV2.dispatchPanels({type: 'CLOSE_EDITOR_PANEL', value: null})}}>
          <span>Retour à l'éditeur</span>
          <img src={"https://static.myshortlist.co/btn-login.svg"} alt={"go back"} />
        </button>
        
      </section>
  </>);
}