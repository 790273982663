import React, {useContext, useState} from "react";
import Spaces from "../../../Spaces";
import {notification} from "../../../../../utils/notification";
import AppContext, {IAppV2Context} from "../../../../App/context/AppContext";

export default (props: {
  fieldToUpdate: string,
  modifier: Function,
  mission: any,
}) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  const [value, setValue] = useState(!["INTERIM", "CDD", "CDI", "STAGE", "PRO", "ALTERNANCE", "APPRENTISSAGE", "FREELANCE"].includes(props.mission?.contract?.toUpperCase()) ? props.mission?.contract : "");
  
  const [customMode, setCustomMode] = useState(!["INTERIM", "CDD", "CDI", "STAGE", "PRO", "ALTERNANCE", "APPRENTISSAGE", "FREELANCE"].includes(props.mission?.contract?.toUpperCase()));
  
  return (<>
    
    <div className={"assistant--v2-container"}>
      
      {
        appContextV2.appSession.sessionBusiness.isRecruitingAgency &&
        <button disabled={customMode} className={"btn-secondary"} onClick={() => {
          props.modifier("contract", "INTERIM");
          notification(appContextV2.dispatchPanels, "Succès", "success");
        }}>
          Intérim
        </button>
      }
      
      <button disabled={customMode} className={"btn-secondary"} onClick={() => {
        props.modifier("contract", "CDD");
        notification(appContextV2.dispatchPanels, "Succès", "success");
      }}>
        CDD
      </button>
      
      <button disabled={customMode} className={"btn-secondary"} onClick={() => {
        props.modifier("contract", "CDI");
        notification(appContextV2.dispatchPanels, "Succès", "success");
      }}>
        CDI
      </button>
      
      <button disabled={customMode} className={"btn-secondary"} onClick={() => {
        props.modifier("contract", "STAGE");
        notification(appContextV2.dispatchPanels, "Succès", "success");
      }}>
        Stage
      </button>
      
      <button disabled={customMode} className={"btn-secondary"} onClick={() => {
        props.modifier("contract", "PRO");
        notification(appContextV2.dispatchPanels, "Succès", "success");
      }}>
        Contrat pro
      </button>
      
      <button disabled={customMode} className={"btn-secondary"} onClick={() => {
        props.modifier("contract", "ALTERNANCE");
        notification(appContextV2.dispatchPanels, "Succès", "success");
      }}>
        Alternance
      </button>
      
      <button disabled={customMode} className={"btn-secondary"} onClick={() => {
        props.modifier("contract", "APPRENTISSAGE");
        notification(appContextV2.dispatchPanels, "Succès", "success");
      }}>
        Apprentissage
      </button>
      
      <button disabled={customMode} className={"btn-secondary"} onClick={() => {
        props.modifier("contract", "FREELANCE");
        notification(appContextV2.dispatchPanels, "Succès", "success");
      }}>
        Freelance
      </button>
    
    </div>
    
    <Spaces n={0}/>
    
    <input type={"checkbox"} checked={customMode} onClick={() => setCustomMode((ps: boolean) => !ps)}/> Autre
  
    <Spaces n={0}/>
    
    {/* ------------------------------- Mode custom */}
    
    {
      customMode && <>
        <div className={"white-container white-container--grey"}>
          <input className={"input--v2"} type={"text"} value={value} onChange={(e) => {
            (e.target.value.length < 50) && (setValue(e.target.value));
          }}/>
        </div>
        
        <Spaces n={0}/>

        <div className={"text--center"}>
          <button disabled={value.length === 0} className={"btn-secondary"} onClick={() => {
            props.modifier("contract", value);
            notification(appContextV2.dispatchPanels, "Succès", "success");
          }}>
            Mettre à jour
          </button>
        </div>

      </>}
  
  </>);
  
}
