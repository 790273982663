import React, {useContext, useEffect, useState} from "react";
import {IAssistantContext} from "../dependencies/interfaces/IAssistantContext";
import AppContext, {IAppV2Context} from "../../../../components/App/context/AppContext";
import Spaces from "../../../../components/sharedComponents/Spaces";
import {ButtonBack} from "../../../../components/sharedComponents/Buttons/ButtonBack";

export default (props: IAssistantContext) => {
  
  const appContextV2: IAppV2Context = useContext(AppContext) as IAppV2Context;
  
  return (<section>
    
    <Spaces n={0}/>
    
    <div className={"assistant--v2-container--no-flex"}>
      <div className={"space-light"}>
        <ButtonBack back={() => props.wizard.previousStep()}/>
      </div>
    </div>
    
    <div className={"assistant--v2-title"}>
      Un profil linkedin est-il obligatoire pour postuler ?
    </div>
    
    <Spaces n={1}/>
    
    <div className={"assistant--v2-container"}>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "linkedinMandatory", value: true}});
                props.updateForm()({target: {type: "custom", name: "withoutCv", value: true}});
                props.updateForm()({target: {type: "custom", name: "cvMandatory", value: false}});
                props.wizard.nextStep();
              }}>
        Un profil LinkedIn est obligatoire
      </button>
      
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "linkedinMandatory", value: false}});
                props.updateForm()({target: {type: "custom", name: "withoutCv", value: false}});
                props.updateForm()({target: {type: "custom", name: "cvMandatory", value: true}});
                props.wizard.nextStep();
              }}>
        Un CV est obligatoire
      </button>
  
      <button className={"btn-assistant-main"}
              onClick={() => {
                props.updateForm()({target: {type: "custom", name: "linkedinMandatory", value: false}});
                props.updateForm()({target: {type: "custom", name: "withoutCv", value: true}});
                props.updateForm()({target: {type: "custom", name: "cvMandatory", value: false}});
                props.wizard.nextStep();
              }}>
        Aucun n'est obligatoire
      </button>
    
    </div>
  
    <Spaces n={1}/>
    
    <div className={"info-box"}>
      Myshortlist.co conseille le recrutement "sans CV" !
      <Spaces n={0}/>
      Pourquoi ?
      <Spaces n={0}/>
      <ul className={"new-ul"}>
        <li>Il est possible de poser les questions aux candidats sur leur parcours directement dans le formulaire,</li>
        <li>Beaucoup de candidats ouvrent nos liens sur leur smartphones, et n'ont donc pas de CV sous la main,</li>
        <li>Il est possible de demander leur CV à une petite sélection de candidats après qu'ils soient entrés dans le process !</li>
      </ul>
    </div>
    
    <Spaces n={1}/>
  
  
  </section>);
}